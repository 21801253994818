import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { DashboardCountersDto } from "app/dtos/02-TAR/Dashboard/DashboardCountersDto";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";

export default class DashboardService {
    // GET Dashboard Counters PENDING & COMPLETE
    public static async GetCounters(paginationParams?: PaginationParams) {
        return FetchService.get<DashboardCountersDto>({
            url: `${ApiPaths.TAR_API}/Dashboard/NewCounters`,
            paginationParams,
        });
    }

    public static async GetSporadicTasks(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<TaskInstanceModel>>({
            url: `${ApiPaths.TAR_API}/Dashboard/SporadicTasks`,
            paginationParams,
        });
    }

    public static async InsertSporadicTask(taskId: number) {
        return FetchService.post<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/Dashboard/SporadicTasks/${taskId}`,
        });
    }
}
