import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { TaskReviewUser } from "app/models/02-TAR/TaskReview";
import { ApiPaths } from "app/shared/Constants";
import { CompanyTaskToReview } from "app/dtos/01-SEG/Company/CompanyTaskToReview";

export default class TaskReviewService {
    // GET Supervised Users by SupervisorId
    public static async GetTaskReviewUserList(companyId: number, supervisorId: number, extraParams?: string) {
        return FetchService.get<PaginatedResult<TaskReviewUser>>({
            url: `${ApiPaths.TAR_API}/TaskReviewCheck/GetSupervisedUsers?CompanyId=${companyId}&supervisorId=${supervisorId}&${extraParams}`,
        });
    }

    // GET GET Pending Tasks to Review from Company
    public static async GetCompanyPendingTasksToReview(extraParams?: string) {
        return FetchService.get<CompanyTaskToReview[]>({
            url: `${ApiPaths.TAR_API}/TaskReviewCheck/GetCompanyPendingTasksToReview`,
            paginationParams: { extraParams },
        });
    }

    // POST Insert all TaskReviews by userId where deadlinedate > now
    public static async InsertTaskReviewsByUserId(userId: number) {
        return FetchService.post({
            url: `${ApiPaths.TAR_API}/TaskReviewCheck/${userId}`,
        });
    }

    // POST Delete all TaskReviews by userId where deadlinedate > now
    public static async DeleteTaskReviewsByUserId(userId: number) {
        return FetchService.delete({
            url: `${ApiPaths.TAR_API}/TaskReviewCheck/${userId}`,
        });
    }
}
