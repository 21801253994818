import { FC } from "react";
import Pagination from "app/components_v2/Pagination/Pagination";
import { AuditPillSkelleton } from "app/components_v2/__skelletons/AuditPillSkelleton/AuditPillSkelleton";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { AuditPill } from "../AuditPill/AuditPill";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faCheckDouble } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { AuditGridTranslation } from "app/translation/translationKeys";
import { PaginationDefaults } from "app/shared/Constants";

type AuditPillListProps = {
    isLoading: boolean;
    data: AuditInstanceModel[];
    pageIndex: number;
    pageSize: number;
    total: number;
    onChangePageIndex: (pageindex: number) => void;
    handleNavigate: (auditInstance: AuditInstanceModel) => void;
    currentTab: number;
};

export const AuditPillList: FC<AuditPillListProps> = ({
    data,
    handleNavigate,
    isLoading,
    onChangePageIndex,
    pageIndex,
    pageSize,
    total,
    currentTab,
}) => {
    const { t } = useTranslation();
    const emptyTitle: Record<number, string> = {
        0: t(AuditGridTranslation.EMPTY_LIST_OPEN),
        1: t(AuditGridTranslation.EMPTY_LIST_CLOSED),
    };

    if (isLoading) return <AuditPillSkelleton quantity={PaginationDefaults.PAGE_SIZE} oneRow={currentTab !== 0} />;

    if (!data.length)
        return (
            <div className="fullHeight">
                <TableError icon={faCheckDouble} title={emptyTitle[currentTab]} />
            </div>
        );

    return (
        <>
            {data.map((auditinstance, i) => {
                return (
                    <AuditPill
                        key={i}
                        auditInstance={auditinstance}
                        onPillClick={() => handleNavigate(auditinstance)}
                    />
                );
            })}
            {total / pageSize >= 1 && (
                <>
                    <Pagination
                        onClick={(pageIndex) => {
                            onChangePageIndex && onChangePageIndex(pageIndex);
                        }}
                        pageIndex={pageIndex || 1}
                        total={total}
                        pageSize={pageSize}
                        variant="appcc"
                    />
                </>
            )}
        </>
    );
};
