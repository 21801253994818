import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { WizardTaskContext } from "app/state/context/wizardTaskContext";

export const WizardTaskProvider: FC<ProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <WizardTaskContext.Provider value={{ isOpen, open: () => setIsOpen(true), close: () => setIsOpen(false) }}>
            {children}
        </WizardTaskContext.Provider>
    );
};
