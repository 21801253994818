import { FC, useState } from "react";
import {
    INITIAL_BASE_TASK_SORT_FIELD,
    INITIAL_BASE_TASK_SORT_DIRECTION,
    INITIAL_BASE_TASK_SF_VALUES,
} from "../../constants/baseTaskConstants";
import { BaseTaskGridContext, BaseTaskGridSFModel } from "../context/BaseTaskContext";
import { ProviderProps } from "app/state/Providers/types";
import { SortedTypeModel } from "app/components_v2/Table/types";

export const BaseTaskGridProvider: FC<ProviderProps> = ({ children }) => {
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>(INITIAL_BASE_TASK_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_BASE_TASK_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] =
        useState<BaseTaskGridSFModel>(INITIAL_BASE_TASK_SF_VALUES);

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<BaseTaskGridSFModel>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    return (
        <BaseTaskGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                sortDirection,
                sortField,
                onSortChange,
                onSFChange,
                secondaryFilterValues,
            }}
        >
            {children}
        </BaseTaskGridContext.Provider>
    );
};
