import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";
import { getInitials } from "app/helpers/getInitials";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { AssetTypeTranslations, TranslationErrors } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useEffect, useState } from "react";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import AssetTypeService from "app/services/05-QUA/AssetTypeService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type IUseAssetFormInfo = {
    isEditPage: boolean;
    onInputChange: (fields: Partial<AssetModelFormValues>) => void;
    assetFields: AssetFieldInsertModel[];
};

type GroupedAssetType = {
    id: number;
    name: string;
    defaultRanges: [{ description: string; min: number; max: number; type: string }];
};

export const useAssetFormInfo = ({ assetFields, isEditPage, onInputChange }: IUseAssetFormInfo) => {
    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [iscompanyLoading, setIscompanyLoading] = useState<boolean>(true);

    const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeModel[]>([]);
    const [isAssetTypeLoading, setIsAssetTypeLoading] = useState<boolean>(true);

    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionModel[]>([]);
    const [isPlanLoading, setIsPlanLoading] = useState<boolean>(true);

    const getCompany = async () => {
        setIscompanyLoading(true);
        const { status, data } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (!status()) {
            setCompanyOptions([]);
            handleToast({
                title: getErrorMessage(
                    translateCustomerTypeKeys(TranslationErrors.COMPANIES_FAILED_LOAD_DATA, {
                        firstReplace: "PLURAL",
                        isGenderSensitive: true,
                    })
                ),
                type: "alert",
                variant: "danger",
            });
            setIscompanyLoading(false);
            return;
        }

        setCompanyOptions(data.map(({ label, value }) => ({ label, value, initials: getInitials(label) })));
        setIscompanyLoading(false);
    };

    const getAssetType = async () => {
        setIsAssetTypeLoading(true);
        const { data, status, getParsedError } = await AssetTypeService.GetList();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsAssetTypeLoading(false);
            return;
        }
        setAssetTypeOptions(data);
        setIsAssetTypeLoading(false);
    };

    const getPlans = async () => {
        setIsPlanLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsPlanLoading(false);
            return;
        }
        setPlanAPPCCOptions(data);
        setIsPlanLoading(false);
    };

    const transformAndGroupByName = (assetTypeOptions: AssetTypeModel[]): GroupedAssetType[] => {
        const grouped: Record<string, GroupedAssetType> = {};

        assetTypeOptions.forEach((item) => {
            const range = {
                description: item.description,
                min: item.min ?? 0,
                max: item.max ?? 0,
                type: item.type,
            };
            if (!grouped[item.name])
                grouped[item.name] = {
                    id: item.id,
                    name: item.name,
                    defaultRanges: [range],
                };
            else grouped[item.name].defaultRanges.push(range);
        });

        return Object.values(grouped);
    };

    const onChangeAssetType = ({ value }: OptionModel) => {
        const newAssetTypeId = Number(value);

        const selectedAssetType = assetTypeOptions.find(({ id }) => id === newAssetTypeId);
        if (!selectedAssetType) return;

        const { name } = selectedAssetType;

        if (name === AssetTypeTranslations.OTHER_OPTION) {
            onInputChange({ fK_AssetType: newAssetTypeId });
            return;
        }

        const groupedAssetTypeOptions = transformAndGroupByName(assetTypeOptions);
        const selectedGroupedAssetType = groupedAssetTypeOptions.find((item) => item.id === selectedAssetType.id);
        const defaultAssetFields: AssetFieldInsertModel[] =
            selectedGroupedAssetType?.defaultRanges.map((assetField) => {
                return {
                    id: v4(),
                    isDeleted: false,
                    assetDynamicField: assetField.type === "STRING" ? "TEXT" : "NUMBER",
                    assetFieldRange: {
                        fK_MeasurementUnit: 0,
                        id: 0,
                        max: assetField.max || 0,
                        min: assetField.min || 0,
                        unit: "",
                    },
                    label: assetField.description,
                    isHidden: false,
                };
            }) ?? [];

        onInputChange({
            fK_AssetType: newAssetTypeId,
            assetFields: !!assetFields.length ? (isEditPage ? assetFields : defaultAssetFields) : defaultAssetFields,
        });
    };

    useEffect(() => {
        getCompany();
        getAssetType();
        getPlans();
    }, []);

    return {
        companyOptions,
        iscompanyLoading,
        groupedAssetTypeOptions: transformAndGroupByName(assetTypeOptions),
        isAssetTypeLoading,
        planAPPCCOptions,
        isPlanLoading,
        onChangeAssetType,
    };
};
