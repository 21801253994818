import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useBreadcrumbs } from "../state/BreadcrumbsProvider";
import { useDateRange } from "../Hooks/useDateRange";
import { useNavigateToInitialScreen } from "app/hooks/useNavigateToInitialScreen";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import AnalyticsService from "app/services/03-REP/AnalyticsService";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { buildParams } from "../helpers/helpers";
import { SecScreen } from "app/shared/Constants";
import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";
import { UserType } from "app/shared/types/UserTypes";
import { RankingDetailProps } from "../types";
import Spinner from "app/components_v2/Spinner/Spinner";
import { DateRangePicker } from "app/components_v2/__inputs";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { SupervisorValorationDetail } from "app/components_v2/ValorationDetail/SupervisorValorationDetail";
import AnalistLayout from "app/components_v2/Layout/AnalistLayout/AnalistLayout";

const SupervisorDetailPage: FC<RankingDetailProps> = ({ startDate, endDate, onDateChange }) => {
    const { t } = useTranslation();
    const { supervisorId } = useParams();
    const session = useSession();
    const today = new Date(new Date().toISOString());
    const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
    const [dataChart, setDataChart] = useState<AnalistSupervisorDetailModel>();
    const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
    const [isHeaderLoading, setIsHeaderLoading] = useState<boolean>(true);
    const { navigateToInitialScreen } = useNavigateToInitialScreen();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { startDateInput, endDateInput, onChange } = useDateRange({
        initialStartDate: startDate || new Date(new Date(oneWeekAgo).toISOString()),
        initialEndDate: endDate || new Date(),
        onDateChange: ({ startDate, endDate }) => {
            onDateChange({ startDate: startDate, endDate: endDate });
        },
    });

    const fetchUserData = useCallback(async () => {
        if (!supervisorId) return;
        const params = buildParams({
            startDateInput: startDateInput,
            endDateInput: endDateInput,
        });
        setIsLoading(true);
        const { status, data } = await AnalyticsService.GetSupervisorDetail(supervisorId, {
            extraParams: params.toString(),
        });

        if (status()) setDataChart(data);

        setIsLoading(false);
        setIsHeaderLoading(false);
    }, [supervisorId, startDateInput, endDateInput, AnalyticsService]);

    useEffect(() => {
        dataChart?.entityHeaderInfoModel?.name && updateBreadcrumbs({ name: dataChart?.entityHeaderInfoModel?.name });
    }, [dataChart]);

    useEffect(() => {
        if (!hasPermissionToView(session, SecScreen.COMPANY_RANKING)) navigateToInitialScreen();

        endDateInput && fetchUserData();
    }, [fetchUserData]);

    const handleInputChange = (dates: Date[]) => onChange(dates);

    return (
        <>
            {dataChart ? (
                <AnalistLayout
                    breadCrums={breadcrumbs}
                    avatar={{
                        avatarName: dataChart?.entityHeaderInfoModel?.name,
                        colorId: Number(supervisorId),
                        img: dataChart?.entityHeaderInfoModel?.photoURL,
                    }}
                    rating={{
                        starRatingAVG: dataChart?.entityHeaderInfoModel?.avg || 0,
                        total: dataChart?.entityHeaderInfoModel?.totalTasks || 0,
                        trend: Number((dataChart?.entityHeaderInfoModel?.trend || 0).toFixed(0)),
                    }}
                    isLoading={isHeaderLoading}
                    userType={dataChart.entityHeaderInfoModel.jobDescription as UserType}
                    widthVariant="small"
                >
                    <div className="supervisorDetailPage">
                        <div className="supervisorDetailPage__date--100">
                            <div className="supervisorDetailPage__date__buttons">
                                <div className="maxWidth250">
                                    <DateRangePicker
                                        onChange={handleInputChange}
                                        startDate={startDateInput}
                                        endDate={endDateInput}
                                        showMoreOptions
                                        range
                                        disableFutureDates
                                        showToday={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <div className="supervisorDetailPage__chart fullHeight">
                            <Spinner />
                        </div>
                    ) : dataChart ? (
                        <div className="supervisorDetailPage__chart fullHeight">
                            <SupervisorValorationDetail data={dataChart} />
                        </div>
                    ) : (
                        <TableError
                            icon={faUserGroup}
                            title={t(TranslationCommon.NO_DATA_FOR_YOUR_SEARCH)}
                            description={t(TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH)}
                        />
                    )}
                </AnalistLayout>
            ) : (
                <div className="fullHeight flex--center">
                    <Spinner />
                </div>
            )}
        </>
    );
};
export default SupervisorDetailPage;
