import { FC } from "react";
import { SquareButton } from "app/components_v2/__buttons/SquareButton/SquareButton";
import { FullScreenQrSelectedOption } from "../types";
import { faKeyboard, faQrcode } from "@fortawesome/pro-regular-svg-icons";
import { ScanQRTranslations } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type FullScreenQrModalHeaderProps = {
    selectedOption: FullScreenQrSelectedOption;
    title?: string;
    onSelect: (selectedOption: FullScreenQrSelectedOption) => void;
};

export const FullScreenQrModalHeader: FC<FullScreenQrModalHeaderProps> = ({ selectedOption, title, onSelect }) => {
    const { t } = useTranslation();
    return (
        <div className="fullScreenQrModalHeader">
            <h1 className="fullScreenQrModalHeader__title">{title || t(ScanQRTranslations.QR_SCANNER_MODAL_TITLE)}</h1>
            <div className="fullScreenQrModalHeader__options">
                <SquareButton isFocused={selectedOption === "SCAN"} onClick={() => onSelect("SCAN")} icon={faQrcode}>
                    {t(ScanQRTranslations.QR_SCANNER_MODAL_SCAN)}
                </SquareButton>
                <SquareButton
                    isFocused={selectedOption === "MANUAL"}
                    onClick={() => onSelect("MANUAL")}
                    icon={faKeyboard}
                >
                    {t(ScanQRTranslations.QR_SCANNER_MODAL_MANUAL_SCAN)}
                </SquareButton>
            </div>
        </div>
    );
};
