import { FC } from "react";
import { useTranslation } from "react-i18next";
import { getDayMonthFormatedWithYear } from "../../../../../../../../helpers/Date.utilities";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { taskEnds } from "app/models/02-TAR/TaskWizard";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";

type TaskFinishedInfoProps = {
    finish: taskEnds;
    taskStart: string;
    taskType: TaskType;
};

export const TaskFinishedInfo: FC<TaskFinishedInfoProps> = ({ finish, taskStart, taskType }) => {
    const { t } = useTranslation();
    const isTaskNormal = taskType !== "SPORADIC";
    return (
        <div className="taskFinishedInfo">
            <span>
                {t(TranslationCommon.START)}: {getDayMonthFormatedWithYear(taskStart)}
            </span>
            {finish.checked === "never" && isTaskNormal && (
                <span>
                    {t(TranslationKeys.END)}: {` ${t(TranslationKeys.NEVER)}`}
                </span>
            )}
            {finish.checked === "el" && isTaskNormal && (
                <span>
                    {t(TranslationKeys.END)}: {getDayMonthFormatedWithYear(`${finish.value}`)}
                </span>
            )}
            {finish.checked === "after" && isTaskNormal && (
                <span>{`${t(TranslationKeys.FINISH_IN)} ${finish.value} ${
                    finish.value === 1
                        ? t([TranslationKeys.REPETITION])
                        : t([TranslationKeys.REPETITIONS]).toLocaleLowerCase()
                }`}</span>
            )}
        </div>
    );
};
