import { useNavigate } from "react-router-dom";
import { useSession } from "../useSession";
import OnBoardingSubstepInstanceService from "app/services/01-SEG/OnBoardingSubstepInstanceService";
import { PrivatePaths } from "app/shared/Constants";
import { useEffect } from "react";
import { useUserType } from "../useUserType";

export const useOnBoardingAccessPage = (subStep: string) => {
    const session = useSession();
    const nav = useNavigate();
    const { isAdmin } = useUserType();

    const isOnBoarding = session?.isOnBoarding;

    const getSubStep = async () => {
        const { data, status: statusSr } = await OnBoardingSubstepInstanceService.GetByName(subStep);
        if (!statusSr()) return;

        const { status } = data;
        if (status !== "BLOCKED") return;

        nav(`/${PrivatePaths.ON_BOARDING}`);
    };

    useEffect(() => {
        if (!isOnBoarding || isAdmin) return;
        getSubStep();
    }, []);
};
