import { BreadcrumbModel } from "../BreadCrumbs";
import { FC } from "react";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { SelectOptions } from "app/components_v2/__inputs";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";

type PlanAPPCCGridHeaderProps = {
    title: string;
    onChange: (value: string) => void;
    planOptions: OptionModel[];
    selectedValue: string;
};

export const PlanAPPCCGridHeader: FC<PlanAPPCCGridHeaderProps> = ({ onChange, planOptions, selectedValue, title }) => {
    const { t } = useTranslation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;

    const breadCrums: BreadcrumbModel[] = [
        { name: t(TranslationTitles.PLAN_APPCC_PAGE_TITLE), to: `/${PrivatePaths.PLAN_APPCC}` },
    ];

    return (
        <TabHeader
            paddingSize="sm"
            classNameToScrollTop=".customContainer"
            breadCrums={hasPermissionToView(session, SecScreen.PLAN_APPCC) && !isOnBoarding ? breadCrums : undefined}
            variant="appcc"
        >
            <div className="planAPPCCGridHeader">
                <h1 className="planAPPCCGridHeader__title">{title}</h1>
                <div className="planAPPCCGridHeader__select">
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => onChange(value)}
                        options={planOptions}
                        placeholder={t(TranslationKeys.ALL_PLANS)}
                        selectedValue={selectedValue !== "-1" ? selectedValue : undefined}
                        isSearchable
                        inverted
                    />
                </div>
            </div>
        </TabHeader>
    );
};
