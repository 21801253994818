import { FC } from "react";
import { TaskPillWithStarsSkelletonMobile } from "./Mobile/TaskPillWithStarsSkelletonMobile";
import { TaskPillWithStarsSkelletonDesktop } from "./Desktop/TaskPillWithStarsSkelletonDesktop/TaskPillWithStarsSkelletonDesktop";

type Props = {
    quantity?: number;
    hasImage?: boolean;
    variant?: "mobile" | "desktop";
};

const TaskPillWithStarsSkelleton: FC<Props> = ({
    quantity = 1,
    hasImage = true,
    variant = "desktop",
}) => {
    return (
        <div className="taskpillListskelleton">
            {Array.from({ length: quantity }).map((_, index) =>
                variant === "mobile" ? (
                    <TaskPillWithStarsSkelletonMobile key={index} hasImage={hasImage} />
                ) : (
                    <TaskPillWithStarsSkelletonDesktop key={index} hasImage={hasImage} />
                )
            )}
        </div>
    );
};

export default TaskPillWithStarsSkelleton;
