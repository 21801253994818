import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { RowPositionModel } from "app/components_v2/Table/types";
import { CorrectiveMeasureModel } from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";
import { hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { FC } from "react";

type MobileCorrectiveMeasureRowProps = {
    row: CorrectiveMeasureModel;
    rowPosition: RowPositionModel;
    onDelete: () => void;
    onEdit: () => void;
};

export const MobileCorrectiveMeasureRow: FC<MobileCorrectiveMeasureRowProps> = ({
    onDelete,
    onEdit,
    row,
    rowPosition,
}) => {
    const { description, planAPPCC } = row;
    return (
        <div className={`mobileCorrectiveMeasureRow--${rowPosition}`}>
            <div className="mobileCorrectiveMeasureRow__header">
                <p className="mobileCorrectiveMeasureRow__description">{description}</p>
                <p className="mobileCorrectiveMeasureRow__plan">{planAPPCC.name}</p>
            </div>
            <div className="mobileCorrectiveMeasureRow__actions">
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: onEdit,
                            hidden: !hasPermissionToEdit(SecScreen.CORR_MEASURE_MANAGER),
                        },
                        {
                            icon: faTrash,
                            onClick: onDelete,
                            hidden: !hasPermissionToDelete(SecScreen.CORR_MEASURE_MANAGER),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
