import { FC, FormEvent, useState } from "react";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { StepOneProps } from "./models/StepOneModel";
import { useTranslation } from "react-i18next";
import {
    BaseTaskTranslations,
    TaskTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { useStepOne } from "./hooks";
import { Input, SelectOptions, TextArea } from "app/components_v2/__inputs";
import { useTaskData } from "../../EditTask/Components/TaskData/hooks/useTaskData";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";

export const StepOne: FC<StepOneProps> = ({
    taskTitle,
    taskDesc,
    belongsToAPPCC,
    onChange,
    fK_PlanAPPCC,
    next,
    fK_BaseTask,
    disabledFields,
    hasPlan,
}) => {
    const { t } = useTranslation();

    const { validate, validations } = useStepOne({
        fK_BaseTask,
        belongsToAPPCC,
        next,
        fK_PlanAPPCC,
        taskDesc,
        taskTitle,
    });

    const { disabledTaskDesc, disabledTaskTitle } = disabledFields;
    const { planAPPCCOptions } = useTaskData(!!hasPlan);

    return (
        <WizardForm validate={validate}>
            <Input
                onChange={(value) => onChange({ taskTitle: value })}
                placeholder={t(TranslationKeys.PLACEHOLDER_TASK_TITLE)}
                value={taskTitle}
                label={t(TranslationKeys.TASK_NAME_LABEL)}
                errorMessage={validations.taskTitle}
                readonly={disabledTaskTitle}
            />
            <TextArea
                placeholder={t(TranslationKeys.PLACEHOLDER_TASK_DESCRIPTION)}
                onChange={(newValue) => onChange({ taskDesc: newValue })}
                label={`${t(TranslationKeys.TASK_DESCRIPTION)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                text={taskDesc}
                maxLength={150}
                readonly={disabledTaskDesc}
            />
            {hasPlan && fK_BaseTask && (
                <ReadOnlyInput
                    value={planAPPCCOptions.find(({ value }) => value === fK_PlanAPPCC)?.label || ""}
                    label={t(BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL)}
                    placeholder={t(t(TranslationKeys.SELECT_A_PLAN))}
                    type="select"
                />
            )}
            {hasPlan && !fK_BaseTask && (
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => onChange({ fK_PlanAPPCC: value })}
                    options={planAPPCCOptions}
                    label={t(TaskTranslations.TASK_APPCC_PLACEHOLDER)}
                    selectedValue={fK_PlanAPPCC !== "-1" ? fK_PlanAPPCC : undefined}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    isOptional
                />
            )}
        </WizardForm>
    );
};
