import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

type DayOfMonthProps = {
    repeatEvery: number;
    text: string;
};

export const DayOfMonth: FC<DayOfMonthProps> = ({ repeatEvery, text }) => {
    const { t } = useTranslation();
    return (
        <>
            <p className="stepFivePlaceHolderInput">
                {t(TranslationKeys.REPEAT_EVERY)}{" "}
                {repeatEvery <= 1
                    ? t(TranslationKeys.MONTH).toLocaleLowerCase()
                    : `${repeatEvery} ${t(TranslationKeys.MONTHS).toLocaleLowerCase()}`}{" "}
                {text.toLowerCase()}{" "}
            </p>
        </>
    );
};
