import { FC, SetStateAction } from "react";
import { getAvatarInitials } from "app/helpers/ProfileHelper/profileHelper";
import { EntityPendingTasksFormValues } from "../models/EntityPendingTasksFormValues";
import { DateRangePicker } from "app/components_v2/__inputs";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";

type EntityPendingTasksCardProps = {
    data: EntityPendingTasksFormValues;
    entitySetter: (value: SetStateAction<EntityPendingTasksFormValues[]>) => void;
    min: string;
    max: string;
    onSelectEntity: (id: string, entitySetter: (value: SetStateAction<EntityPendingTasksFormValues[]>) => void) => void;
    onChangeDateRange: (
        id: string,
        dates: Date[],
        entitySetter: (value: SetStateAction<EntityPendingTasksFormValues[]>) => void
    ) => void;
};

export const EntityPendingTasksCard: FC<EntityPendingTasksCardProps> = ({
    data,
    entitySetter,
    min,
    max,
    onSelectEntity,
    onChangeDateRange,
}) => {
    const { id, dbId, name, logoURL, startDate, endDate, checked } = data;

    return (
        <WhiteBox key={id}>
            <div className="entityPendingTasksCard">
                <div className="entityPendingTasksCard__company">
                    <CheckBox onChange={() => onSelectEntity(id, entitySetter)} checked={checked} />
                    <Avatar
                        colorId={dbId}
                        avatarName={name}
                        img={logoURL}
                        name={name}
                        nameInitials={getAvatarInitials(name)}
                        clamp
                    />
                </div>
                <div className="entityPendingTasksCard__dates">
                    <DateRangePicker
                        range
                        onChange={(dates) => onChangeDateRange(id, dates, entitySetter)}
                        startDate={new Date(startDate)}
                        endDate={endDate ? new Date(endDate) : undefined}
                        min={new Date(min)}
                        max={new Date(max)}
                        disabled={!checked}
                    />
                </div>
            </div>
        </WhiteBox>
    );
};
