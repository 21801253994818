import { SortedTypeModel } from "app/components_v2/Table/types";
import { TaskGridSecondaryFilterModel } from "../models/taskGridSecondaryFilterModel";
import { TaskCountersDto } from "app/dtos/02-TAR/Task/TaskCountersDto";

export const INITIAL_TASK_GRID_SORT_FIELD = "TaskScheduler.TypeCode";
export const INITIAL_TASK_GRID_SORT_DIRECTION: SortedTypeModel = "asc";
export const INITIAL_TASK_GRID_SF_VALUES: TaskGridSecondaryFilterModel = {
    responsable: {
        assignedTo: "all",
        text: "",
        value: "-1",
    },
    workingPositionDepartments: {
        text: "",
        value: "-1",
    },
    isRecurrent: "-1",
    detail: {
        text: "",
        value: "-1",
    },
    frequency: undefined,
    fromHour: undefined,
    toHour: undefined,
    isPhotoRequired: false,
    isDataRequired: false,
    isCritical: false,
    baseTask: "-1",
    taskType: "-1",
    classifications: [],
};

export const INITIAL_TASK_GRID_COUNTERS: TaskCountersDto = {
    all: 0,
    subteamsCounter: 0,
    teamsCounter: 0,
    userCounter: 0,
    workingPositionCounter: 0,
};
