import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { BaseTaskTypeModel } from "app/models/02-TAR/BaseTask/BaseTaskTypeModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";

export default class BaseTaskTypeService {
    // GET Single BaseTaskType
    public static async GetOne(id: number) {
        return FetchService.get<BaseTaskTypeModel>({
            url: `${ApiPaths.TAR_API}/BaseTaskType/${id}`,
        });
    }

    // GET BaseTaskType List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<BaseTaskTypeModel>>({
            url: `${ApiPaths.TAR_API}/BaseTaskType`,
            paginationParams: params,
        });
    }

    // POST BaseTaskType
    public static async Save(body: BaseTaskTypeModel) {
        return FetchService.post<BaseTaskTypeModel>({
            url: `${ApiPaths.TAR_API}/BaseTaskType`,
            body,
        });
    }

    // PUT BaseTaskType
    public static async Edit(id: number, body: BaseTaskTypeModel) {
        return FetchService.put<BaseTaskTypeModel>({
            url: `${ApiPaths.TAR_API}/BaseTaskType/${id}`,
            body,
        });
    }

    // DELETE BaseTaskType
    public static async Delete(id: number) {
        return FetchService.delete<BaseTaskTypeModel>({
            url: `${ApiPaths.TAR_API}/BaseTaskType/${id}`,
        });
    }
}
