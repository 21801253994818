import { FC, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { useEffectAfterFirstRender } from "app/hooks/useEffectAfterFirstRender";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";

type TaskCheckListItemProps = {
    name: string;
    onSelect: (value: boolean | null) => void;
    isActive: boolean | null;
    readOnly?: boolean;
    index?: number;
};

export const TaskCheckListItem: FC<TaskCheckListItemProps> = ({ name, onSelect, isActive, readOnly, index }) => {
    const [isActiveGroup, setIsActiveGroup] = useState<boolean | null>(isActive);
    const debouncedValue = useDebounce<boolean | null>(isActiveGroup, 500);

    const handleSelect = () => {
        if (readOnly) return;
        onSelect(debouncedValue);
    };

    useEffectAfterFirstRender(() => handleSelect(), [debouncedValue]);

    return (
        <div className="taskCheckListItem">
            <div className="taskCheckListItem__header">
                <label
                    className={`taskCheckListItem__numberLabel ${
                        isActiveGroup ? "isChecked" : isActiveGroup === false ? "isUnChecked" : ""
                    }`}
                >
                    {`${index}.`}
                </label>
                <label
                    className={`taskCheckListItem__label ${
                        isActiveGroup ? "isChecked" : isActiveGroup === false ? "isUnChecked" : ""
                    }`}
                >
                    {name}
                </label>
            </div>
            <div className="taskCheckListItem__checkboxes">
                <CheckBox
                    checked={isActiveGroup === true}
                    onChange={() => !readOnly && setIsActiveGroup((prev) => (prev === true ? null : true))}
                    color="green"
                    rounded
                    readonly={readOnly}
                    size="xl"
                    theme="light"
                    innerIcon={faCheck}
                />
                <CheckBox
                    checked={isActiveGroup === false}
                    onChange={() => !readOnly && setIsActiveGroup((prev) => (prev === false ? null : false))}
                    color="red"
                    rounded
                    readonly={readOnly}
                    size="xl"
                    theme="light"
                    innerIcon={faXmark}
                />
            </div>
        </div>
    );
};
