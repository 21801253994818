import { useFetchErrors } from "../useFetchErrors";
import { useErrorManager } from "../ErrorHandler/useErrorManager";
import { ProfileService } from "app/services";

export const useUpdateLanguage = () => {
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();

    const handleChangeLanguage = async (language: string) => {
        const { status, getParsedError } = await ProfileService.UpdateLanguage(language);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) return;
    };

    return { handleChangeLanguage };
};
