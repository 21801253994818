import { Actions } from "app/state/actions";
import { useContext, useEffect, FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TranslationKeys } from "app/translation/translationKeys";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { PostLoginContext } from "../state/context/PostLoginContext";
import { PrivatePaths } from "app/shared/Constants";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { useSession } from "app/hooks";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";

type StepProps = {
    next: () => void;
    departments: UsrDepartmentModel[];
    subDepartments: UsrSubDepartmentModel[];
};

export const StepThree: FC<StepProps> = ({ subDepartments, departments }) => {
    const { t } = useTranslation();
    const session = useSession();
    const dispatch = useDispatch();
    const nav = useNavigate();
    const { workingCompany, workingDepartment, workingSubDepartment } = useContext(PostLoginContext);

    const saveSession = (wSubDep?: UsrSubDepartmentModel) => {
        if (!session || !workingCompany) return;
        setWorkingEntity({
            workingCompany,
            workingDepartment,
            workingSubDepartment: wSubDep,
        });

        dispatch(
            Actions.changeCompany({
                workingCompany,
                workingDepartment,
                workingSubDepartment: wSubDep,
            })
        );
    };
    // Handle department select
    const handleSubdepartmentSelect = (subDepartment?: UsrSubDepartmentModel) => {
        saveSession(subDepartment);

        dispatch(Actions.switchWizardMode(false));
        nav(`/${PrivatePaths.DASHBOARD}`);
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubdepartmentSelect(undefined);
    };

    const isWorkingSubdepartmentActive = (id: number) => {
        const sessionSubdepartment = session?.workingSubDepartment?.subdepartmentId;
        if (!workingSubDepartment) return false;
        if (workingSubDepartment.subdepartmentId === id && !sessionSubdepartment) return true;
        if (!sessionSubdepartment) return false;
        return sessionSubdepartment === id;
    };

    useEffect(() => {
        if (!subDepartments.length) handleSubdepartmentSelect(undefined);
    }, []);

    return (
        <WizardForm validate={validate}>
            <div className="selectDepartmentWizard__children">
                <h1 className="selectDepartmentWizard__children__title">
                    {departments.length > 1
                        ? t(TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENT_TITLE)
                        : t(TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENTS_TITLE)}
                </h1>
                {subDepartments && !!subDepartments.length && (
                    <div className="selectDepartmentWizard__children__options">
                        {subDepartments.map((subDepartment, i) => (
                            <OptionCompany
                                name={subDepartment.subdepartmentName}
                                onClick={() => handleSubdepartmentSelect(subDepartment)}
                                isActive={isWorkingSubdepartmentActive(subDepartment.subdepartmentId)}
                                key={i}
                            />
                        ))}
                    </div>
                )}
            </div>
        </WizardForm>
    );
};
