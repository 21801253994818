export const wizards = {
    company: {
        wizardCompanyName: "What is the name of the new company?",
        wizardCompanyLogo: "Attach the logo (in PNG or JPG)",
        wizardCompanyType: "What type of company is it?",
        wizardCompanyBelongsTo: "It belongs to any existent group?",
        wizardCompanyCif: "What is the CIF?",
        wizardCompanyFiscalName: "What is the fiscal name?",
        wizardCompanyWhereIs: "Where is?",
        wizardCompanyHowToContact: "How do we contact?",
        wizardCompanyInventoryFreq: "What is the inventory frequency?",

        wizardCompanyWantToClone:
            "Do you want to clone the department structure of any of the existing companies?",
        wizardCompanySelectToInclude: "Select the ones you want to include.",
        wizardCompanyWhichOf: "From which of them?",
        wizardCompanyNoDepartments: "The selected company does not have departments yet",
        wizardCompanyNoSubdepartments: "This department does not have subdepartments yet",
        wizardCompanyWantToCloneTasks:
            "Do you want to also clone the tasks of the departments and subdepartments that you selected?",
        wizardCompanyImportUserStruct: "Do you want to import the user structure?",
        wizardCompanyImportUserStructInstructions:
            "Select the users you want to include in the new company. Click on the icon to see them individually.",
        wizardCompanySureToCreate:
            "Are you sure you want to create the company {name} by cloning these items?",
        wizardCompanySureToCreateNoItems: "Are you sure you want to create the company {name}?",
        wizardCompanyCongrats: "Congratulations, you have created the company {name} successfully!",
        wizardEditInternalOrg: "Edit the internal organization",
        wizardCompanyAtLeastOne: "Select at least one department with users.",
        wizardCompanyStepThreeInfo:
            "Please select a company in the previous step to execute this action.",
        wizardCompanyMarkSubdepartment: "Mark the subdepartment to manage users",
        noDepartmentsMessage: "The selected company does not yet have departments.",
        noSubDepartmentsMessage: "This department does not have subdepartments.",
        wizardCompanySelectCompany: "Please select a company",
        wizardCompanySelectOneDepartment: "Select at least one department.",
    },
    license: {
        wizardInstanceName: "What is the name of the new instance?",
        wizardInstanceNamePlaceholder: "Enter the name of the instance",
        wizardInstanceMainContact: "Who is the main contact?",
        wizardInstanceStepTwoTitle:
            "What modules and functionalities do you want to enable for this instance?",
        wizardInstancePrimaryAdmin: "Who is the primary administrator for this account?",
        wizardInstanceEmail: "What is his/her email address?",
        wizardInstanceDocument: "What is his/her document number?",
        wizardInstanceConfirmation: "Are you sure you want to register instance",
    },
    role: {
        wizardRoleName: "What's the name of the new role?",
        wizardRoleCopyPermissions: "Do you want to copy the permissions of an existing role?",
        wizardRoleSelectToClone: "Which existing role do you want to use as a base?",
        wizardRoleSelectRole: "Select a role to continue or choose no",
        wizardRoleSelectPermission: "You must choose at least one permission.",
        wizardRoleConfirmCreation: "Are you sure you want to create the role",
        wizardTemporaryRoleUserSelect: "To which user do you want to grant temporary permissions?",
        wizardTemporaryRoleDatePicker: "From which day to which day?",
        wizardTemporaryRoleConfirm:
            "Are you sure you want to grant temporary permissions to {0} from {1} to {2}?",
    },
    department: {
        selectCompanyTitle: "Which company are you working on today?",
        selectDepartmentTitle: "Which department?",
        selectSubdepartmentTitle: "Which subdepartment?",
        chooseCompany: "- Select company -",
        chooseDepartment: "- Select department -",
        chooseSubDepartment: "- Select subdepartment -",
        confirmConfig: "Confirm you want to work in {0} {1} {2}?",
    },
};
