import { clearLocalStorage } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { SessionService } from "app/services";
import { CookieKey, PublicPaths } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import { TranslationModals } from "app/translation/translationKeys";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToast } from "./Toast/useToast";
import { useSystemUser } from "./useSystemUser";
import { useUserType } from "./useUserType";

export const useLogOut = () => {
    const dispatch = useDispatch();
    const { handleToast } = useToast();
    const { handleSystemUser } = useSystemUser();
    const { isAdmin } = useUserType();
    const nav = useNavigate();

    const handleLogOut = async () => {
        const logoutSr = await SessionService.LogOut();

        if (logoutSr.status() || logoutSr.httpCodeStatus === 401) {
            isAdmin && handleSystemUser(null);
            document.cookie = `${CookieKey.REFRESH_TOKEN}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
            dispatch(Actions.resetAppState());
            clearLocalStorage();
            nav(PublicPaths.LOGIN);
        } else handleToast({ title: t(TranslationModals.TOAST_GENERIC_ERROR), variant: "danger" });
    };

    return handleLogOut;
};
