import { FC } from "react";
import { EditImageAction, EditImageCropperActions, EditImageFormsAction } from "../types";
import { EditImageFooterDraw } from "../EditImageFooterDraw/EditImageFooterDraw";
import { EditImageFooterForms } from "../EditImageFooterForms/EditImageFooterForms";
import { EditImageFooterCropper } from "../EditImageFooterCropper/EditImageFooterCropper";

type EditImageFooterProps = {
    currentAction: EditImageAction | null;
    onChangeStroke: (stroke: number) => void;

    currentForm: EditImageFormsAction | null;
    onChangeForm: (form: EditImageFormsAction) => void;

    onSelectCropperAction: (action: EditImageCropperActions) => void;
};

export const EditImageFooter: FC<EditImageFooterProps> = ({
    currentAction,
    onChangeStroke,
    currentForm,
    onChangeForm,
    onSelectCropperAction,
}) => {
    return (
        <div className="editImageFooter">
            {currentAction === "DRAW" && (
                <EditImageFooterDraw currentAction={currentAction} onChangeStroke={onChangeStroke} />
            )}

            {currentAction === "FORMS" && (
                <EditImageFooterForms currentForm={currentForm} onChangeForm={onChangeForm} />
            )}

            {currentAction === "CROPPER" && <EditImageFooterCropper onSelect={onSelectCropperAction} />}
        </div>
    );
};
