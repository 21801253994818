import { useEffect, useState } from "react";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { isEqual } from "lodash";
import { useWorkingPosition } from "./hooks/useWorkingPosition";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { useDeleteWorkingPosition } from "./hooks/useDeleteWorkingPosition";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { useTitle } from "app/hooks";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { TextArea } from "app/components_v2/__inputs/TextArea/TextArea";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type IGetDescriptionDeleteModal = {
    isActiveWp: boolean;
    isRelatedWithTasks: boolean;
};

export const WorkingPositionForm = () => {
    const { t } = useTranslation();
    const { workingPositionId } = useParams();
    const nav = useNavigate();
    const { translateCompany } = useCustomerTypeTranslation();

    const isEditPage = !!workingPositionId;

    useTitle(
        t(
            isEditPage
                ? TranslationTitles.EDIT_WORKING_POSITION_PAGE_TITLE
                : TranslationTitles.NEW_WORKING_POSITION_PAGE_TITLE
        )
    );

    const title = isEditPage
        ? t(TranslationKeys.WORKING_POSITION_EDIT_TITLE)
        : t(TranslationKeys.WORKING_POSITION_NEW_TITLE);

    const {
        formValuesCopy,
        formvalues,
        isActive,
        isLoading,
        setIsActive,
        handleInputChange,
        errorFormValues,
        isSaving,
        handleSubmit,
        companyOptions,
        workingPositionOptions,
    } = useWorkingPosition();

    const { errorCode, errorName, errorCompanyId } = errorFormValues;
    const { fK_QrCode, name, description, companyId } = formvalues;
    const { onDelete, isDeleteModalOpen, isDeleting, setIsDeleteModalOpen } =
        useDeleteWorkingPosition(workingPositionId);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    const handleCancelModal = () => {
        handleInputChange({ ...formValuesCopy });
        setIsCancelModalOpen(false);
    };

    const handleDelete = async () => {
        const deletedSuccess = await onDelete({ isActive });
        if (deletedSuccess) {
            setIsActive((prev) => !prev);
            handleInputChange({ isRelatedWithTasks: false, fK_QrCode: "" });
        }
    };

    const getDeleteModalDescription = ({ isActiveWp, isRelatedWithTasks }: IGetDescriptionDeleteModal): string => {
        if (!isActiveWp) return t(TranslationKeys.WORKING_POSITION_ENABLE_DESCRIPTION);
        if (isRelatedWithTasks) return t(TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION);
        return t(TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION_NO_RELATED);
    };

    const goToPrint = () => {
        const searchParams = new URLSearchParams();
        const companyName = companyOptions.find((c) => c.value === String(formValuesCopy.companyId))?.label;
        const qrCode = workingPositionOptions.find((qr) => qr.value === String(formValuesCopy.fK_QrCode))?.label;

        searchParams.append("companyName", companyName || "");
        searchParams.append("qrName", formValuesCopy.name);
        searchParams.append("qrCode", String(qrCode));
        searchParams.append("companyId", String(formValuesCopy.companyId));
        searchParams.append("qrId", String(workingPositionId));

        nav(`/${PrivatePaths.QR}/print/?${searchParams.toString()}`);
    };

    useEffect(() => {
        if (!isEditPage && !hasPermissionToAdd(SecScreen.QR)) nav(PrivatePaths.QR);
    }, []);

    return (
        <>
            {isDeleteModalOpen && (
                <ConfirmModal
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={handleDelete}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={
                        isActive
                            ? t(TranslationKeys.WORKING_POSITION_DISABLE_TITLE)
                            : t(TranslationKeys.WORKING_POSITION_ENABLE_TITLE)
                    }
                    description={getDeleteModalDescription({
                        isActiveWp: isActive,
                        isRelatedWithTasks: !!formvalues.isRelatedWithTasks,
                    })}
                    isLoading={isDeleting}
                />
            )}
            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={() => setIsCancelModalOpen(false)}
                    onConfirm={handleCancelModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                />
            )}
            <FormLayout
                isLoading={isLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: isActive
                        ? t(TranslationKeys.WORKING_POSITION_DEACTIVE)
                        : t(TranslationKeys.WORKING_POSITION_ACTIVE),
                    showDeleteButton: hasPermissionToDelete(SecScreen.QR) && isEditPage && isActive,
                    type: "disable",
                    badge: { isVisible: isEditPage },
                    isActive: isActive,
                    isLoading: isLoading,
                    onClick: () => setIsDeleteModalOpen(true),
                    title: title,
                    secondaryButton:
                        isActive && isEditPage
                            ? { icon: faPrint, label: t(TranslationKeys.PRINT_QR), onClickSecondaryButton: goToPrint }
                            : undefined,
                }}
                footer={
                    ((isEditPage && hasPermissionToEdit(SecScreen.QR)) ||
                        (!isEditPage && hasPermissionToAdd(SecScreen.QR))) && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "workingPositionId",
                                type: "submit",
                                disabled: (isEditPage && !hasPermissionToEdit(SecScreen.QR)) || !isActive,
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: () => setIsCancelModalOpen(true),
                                buttonType: "tertiary",
                                disabled:
                                    (isEditPage && !hasPermissionToEdit(SecScreen.QR)) ||
                                    isEqual(formvalues, formValuesCopy) ||
                                    !isActive,
                            }}
                            loading={isSaving}
                        />
                    )
                }
            >
                <form
                    className="workingPositionForm"
                    id="workingPositionId"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <WhiteBox>
                        <div className="workingPositionForm__body">
                            <div className="workingPositionForm__inputs">
                                <Input
                                    onChange={(newValue) => handleInputChange({ name: newValue })}
                                    value={name}
                                    label={t(TranslationKeys.WORKING_POSITION_NAME_LABEL)}
                                    placeholder={t(TranslationKeys.WORKING_POSITION_NAME_PLACEHOLDER)}
                                    errorMessage={errorName}
                                    disabled={(isEditPage && !hasPermissionToEdit(SecScreen.QR)) || !isActive}
                                />
                                <SelectOptions
                                    isMulti={false}
                                    onChange={({ value }) => handleInputChange({ fK_QrCode: value })}
                                    selectedValue={fK_QrCode !== "-1" ? fK_QrCode : undefined}
                                    options={workingPositionOptions}
                                    label={t(TranslationKeys.WORKING_POSITION_CODE_LABEL)}
                                    placeholder={t(TranslationKeys.WORKING_POSITION_CODE_PLACEHOLDER)}
                                    errorMessage={errorCode}
                                    disabled={(isEditPage && !hasPermissionToEdit(SecScreen.QR)) || !isActive}
                                />
                                <SelectOptions
                                    isMulti={false}
                                    onChange={({ value }) => handleInputChange({ companyId: Number(value) })}
                                    options={companyOptions}
                                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                                    selectedValue={String(companyId)}
                                    label={translateCompany("SINGULAR")}
                                    errorMessage={errorCompanyId}
                                    disabled={(isEditPage && !hasPermissionToEdit(SecScreen.QR)) || !isActive}
                                />
                            </div>
                            <TextArea
                                onChange={(newValue) => handleInputChange({ description: newValue })}
                                label={`${t(TranslationKeys.WORKING_POSITION_DESCRIPTION_LABEL)} (${t(
                                    TranslationKeys.INPUT_OPTIONAL
                                )})`}
                                text={description || ""}
                                placeholder={t(TranslationKeys.WORKING_POSITION_DESCRIPTION_PLACEHOLDER)}
                                disabled={(isEditPage && !hasPermissionToEdit(SecScreen.QR)) || !isActive}
                            />
                        </div>
                    </WhiteBox>
                </form>
            </FormLayout>
        </>
    );
};
