export const AVATAR_COLORS: string[] = [
    "orange",
    "blue",
    "yellow",
    "purple",
    "green",
    "orangeDark",
    "blueLight",
    "greenLight",
    "fuchsia",
    "blueDark",
];
