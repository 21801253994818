import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    BaseAuditGroupCheckListItemTranslations,
    TranslationCommon,
    TranslationModals,
} from "app/translation/translationKeys";
import { confirmButtonOptions, headerModalOptions } from "../constants/BaseAuditGroupCheckListItemConstants";
import { ActionType } from "app/models/FormComponentsModel";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { BaseAuditGroupCheckListItemTypeModal } from "./BaseAuditGroupCheckListItemTypeModal/BaseAuditGroupCheckListItemTypeModal";
import { Input, SelectOptions } from "app/components_v2/__inputs";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { useBaseAuditGroupCheckListItemModal } from "./hooks/useBaseAuditGroupCheckListItemModal";
import { BaseAuditGroupCheckListItemErrorsModel } from "../models/BaseAuditGroupCheckListItemGridModels";
import Spinner from "app/components_v2/Spinner/Spinner";

type BaseAuditGroupCheckListItemModalProps = {
    type: ActionType;
    onSubmit: (type: ActionType, item: BaseAuditGroupCheckListItemModel) => void;
    onClose: () => void;
    isLoading: boolean;
    baseAuditGroupCheckListItemErrors: BaseAuditGroupCheckListItemErrorsModel;
    baseAuditGroupCheckListItem?: BaseAuditGroupCheckListItemModel;
};

const formId = "baseAuditGroupCheckListItemForm";

export const BaseAuditGroupCheckListItemModal: FC<BaseAuditGroupCheckListItemModalProps> = ({
    type,
    onSubmit,
    onClose,
    isLoading,
    baseAuditGroupCheckListItemErrors,
    baseAuditGroupCheckListItem,
}) => {
    const { t } = useTranslation();
    const {
        showDeleteModal,
        baseAuditGroupCheckListItemType,
        handleDelete,
        setShowDeleteModal,
        isLoadingModal,
        showEditModal,
        handleSubmitBaseAuditGroupCheckListItem,
        setShowEditModal,
        handleChange,
        baseAuditGroupCheckListItemTypeOptions,
        selectActions,
        selectLastItems,
        ponderationLevelOptions,
        internalData,
        nameError: baseAuditGroupCheckListItemTypeNameError,
        handleClose,
    } = useBaseAuditGroupCheckListItemModal(baseAuditGroupCheckListItem);

    const { nameError, fK_BaseAuditGroupCheckListItemTypeError, ponderationLevelError } =
        baseAuditGroupCheckListItemErrors;

    return (
        <GenericModal
            header={{
                title: t(headerModalOptions[type]),
                onClose: onClose,
                variant: "appcc",
            }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(confirmButtonOptions[type]),
                        type: "submit",
                        form: formId,
                        variant: "appcc",
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        onClick: onClose,
                        variant: "appcc",
                        buttonType: "tertiary",
                    }}
                    loading={isLoading}
                />
            }
            size="md"
        >
            {showDeleteModal && (
                <ConfirmModal
                    title={t(TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_TITLE).replace(
                        "{0}",
                        baseAuditGroupCheckListItemType?.name ?? ""
                    )}
                    description={t(TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_DESCRIPTION)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onConfirm={handleDelete}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => handleClose(setShowDeleteModal)}
                    isLoading={isLoadingModal}
                    type="delete"
                    variant="appcc"
                />
            )}
            {showEditModal && (
                <BaseAuditGroupCheckListItemTypeModal
                    type={!!baseAuditGroupCheckListItemType ? "edit" : "create"}
                    onSubmit={handleSubmitBaseAuditGroupCheckListItem}
                    onClose={() => handleClose(setShowEditModal)}
                    isLoading={isLoadingModal}
                    baseAuditGroupCheckListItemType={baseAuditGroupCheckListItemType}
                    baseAuditGroupCheckListItemTypeNameError={baseAuditGroupCheckListItemTypeNameError}
                />
            )}
            <form
                className="baseAuditGroupCheckListItemModal"
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(type, internalData);
                }}
                id={formId}
            >
                {isLoading ? (
                    <div className="baseAuditGroupCheckListItemModal__spinner">
                        <Spinner variant="appcc" />
                    </div>
                ) : (
                    <>
                        <Input
                            label={t(
                                BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL
                            )}
                            placeholder={t(
                                BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL
                            )}
                            value={internalData.name}
                            onChange={(value) => handleChange({ name: value })}
                            errorMessage={nameError}
                        />
                        <SelectWithActions
                            label={t(BaseAuditGroupCheckListItemTranslations.TYPE_LABEL)}
                            placeholder={t(BaseAuditGroupCheckListItemTranslations.TYPE_LABEL)}
                            selectedValue={internalData.fK_BaseAuditGroupCheckListItemType.toString()}
                            options={baseAuditGroupCheckListItemTypeOptions}
                            onChange={({ value }) =>
                                handleChange({ fK_BaseAuditGroupCheckListItemType: Number(value) })
                            }
                            actions={selectActions}
                            lastItems={selectLastItems}
                            errorMessage={fK_BaseAuditGroupCheckListItemTypeError}
                        />
                        <SelectOptions
                            label={t(BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL)}
                            placeholder={t(BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL)}
                            selectedValue={internalData.ponderationLevel.toString()}
                            options={ponderationLevelOptions}
                            onChange={({ value }) => handleChange({ ponderationLevel: Number(value) })}
                            isMulti={false}
                            errorMessage={ponderationLevelError}
                        />
                    </>
                )}
            </form>
        </GenericModal>
    );
};
