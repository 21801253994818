import { OnBoardingSubstepInstancesModel } from "app/models/01-SEG/Onboarding/OnBoardingSubstepInstancesModel";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ON_BOARDING_STEPS_PATHS } from "../constants/OnBoardingStepsConstants";
import { getOnBoardingStatusInfo } from "../helpers/getOnBoardingStatusInfo";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "app/components_v2/Icon/Icon";
import { useUserType } from "app/hooks/useUserType";
import { OnBoardingSubStepModal } from "../OnBoardingModals/OnBoardingSubStepModal/OnBoardingSubStepModal";
import { useOnBoardingStepModal } from "app/hooks/OnBoarding/useOnBoardingStepModal";
import { OnBoardingStepIcon } from "../OnBoardingStepIcon/OnBoardingStepIcon";
import { OnBoardingStepsTranslation } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type OnBoardingSubStepItemProps = {
    item: OnBoardingSubstepInstancesModel;
    onCompleteSubStep: () => void;
};

export const OnBoardingSubStepItem: FC<OnBoardingSubStepItemProps> = ({ item, onCompleteSubStep }) => {
    const { t } = useTranslation();
    const { isAdmin } = useUserType();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const { completeSubStep, isLoading } = useOnBoardingStepModal({
        onCompleteSubStep: () => {
            setIsModalVisible(false);
            onCompleteSubStep();
        },
    });

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const { status, name, id } = item;
    const { title, icon } = getOnBoardingStatusInfo(status);

    const onClose = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {isModalVisible && (
                <OnBoardingSubStepModal
                    name={translateCustomerTypeKeys(name, { firstReplace: "PLURAL" })}
                    onComplete={() => completeSubStep(id)}
                    isLoading={isLoading}
                    onClose={onClose}
                />
            )}
            <div className="onBoardingSubStepItem">
                <OnBoardingStepIcon
                    title={`${translateCustomerTypeKeys(name, { firstReplace: "PLURAL" })} - ${t(title)}`}
                    icon={icon}
                    status={status}
                    type="substep"
                    name={translateCustomerTypeKeys(name, { firstReplace: "PLURAL" })}
                />
                {status === "BLOCKED" && !isAdmin ? (
                    <p className="onBoardingSubStepItem__blockedName">
                        {translateCustomerTypeKeys(name, { firstReplace: "PLURAL" })}
                    </p>
                ) : (
                    <Link className="onBoardingSubStepItem__name" to={ON_BOARDING_STEPS_PATHS[name]}>
                        {translateCustomerTypeKeys(name, { firstReplace: "PLURAL" })}
                    </Link>
                )}
                {status === "IN_PROGRESS" && !isAdmin && (
                    <Icon
                        icon={faCheck}
                        theme="light"
                        onClick={() => setIsModalVisible(true)}
                        text={t(OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP)}
                        size="xxs"
                    />
                )}
            </div>
        </>
    );
};
