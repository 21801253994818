import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { createContext } from "react";

export type IssueStatusState = OpenCloseStatusCode | "BOTH";

export type IssueGridContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    currentTab: number;
    onChangeCurrentTab: (tab: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    secondaryFilterValues: IssueGridSFModel;
    onSFChange: (sfValues: Partial<IssueGridSFModel>) => void;
    selectCompany: SelectCompanyModel;
    onCompanyChange: (value: Partial<SelectCompanyModel>) => void;
};

export type IssueGridSFModel = {
    startDate?: Date;
    endDate?: Date;
    senderUserId?: string;
    query?: string;
    issueStatusState: IssueStatusState;
    assetId?: string;
    auditInstanceId?: string;
    issueClassificationId?: string;
};

export const IssueGridContext = createContext<IssueGridContextModel>({} as IssueGridContextModel);
