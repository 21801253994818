import { useEffect, useState } from "react";
import { ComponentStatus } from "app/models/FormComponentsModel";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";

export const useTaskInstance = (taskInstanceId: number | undefined, onClose?: () => void) => {
    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [taskInstance, setTaskInstance] = useState<TaskInstanceModel>();

    const fetchTaskInstanceData = async () => {
        setStatus("loading");
        if (taskInstanceId) {
            const sr = await TaskInstanceService.GetOne(taskInstanceId);
            if (sr.status()) {
                setTaskInstance(sr.data);
                setStatus("complete");
            } else {
                console.error("Could not retrieve task instances");
                setStatus("errored");
                onClose && onClose();
            }
        }
    };

    useEffect(() => {
        fetchTaskInstanceData();
    }, [taskInstanceId]);

    return {
        taskInstance,
        setTaskInstance,
        status,
    };
};
