import { FC } from "react";
import { AppRoutes } from "./app/routes";
import "./styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const App: FC = () => {
    return <AppRoutes />;
};

export default App;
