enum StarType {
    Yellow = "yellow",
    Half = "half",
    Gray = "gray",
}

function generateStars(num: number, maxStars: number): StarType[] {
    let roundedNum = num;

    if (roundedNum <= 0.3) roundedNum = 0;
    else if (roundedNum <= 0.8) roundedNum = 0.5;
    else if (roundedNum <= 1.3) roundedNum = 1;
    else if (roundedNum <= 1.8) roundedNum = 1.5;
    else if (roundedNum <= 2.3) roundedNum = 2;
    else if (roundedNum <= 2.8) roundedNum = 2.5;
    else if (roundedNum <= 3.3) roundedNum = 3;
    else if (roundedNum <= 3.8) roundedNum = 3.5;
    else if (roundedNum <= 4.3) roundedNum = 4;
    else if (roundedNum <= 4.8) roundedNum = 4.5;
    else roundedNum = 5;

    const yellowStars = Math.floor(roundedNum);
    const hasHalfStar = roundedNum % 1 !== 0;
    const starArray: StarType[] = [];

    if (maxStars === 1) {
        starArray.push(StarType.Yellow);
        return starArray;
    }

    for (let i = 0; i < yellowStars; i++) {
        starArray.push(StarType.Yellow);
    }

    if (hasHalfStar) {
        starArray.push(StarType.Half);
    }

    const grayStars = maxStars - starArray.length;
    for (let i = 0; i < grayStars; i++) {
        starArray.push(StarType.Gray);
    }

    return starArray;
}

type StarRatingProps = {
    rating: number;
    maxStars?: number;
};

const StarRatingComponent: React.FC<StarRatingProps> = ({ rating, maxStars = 5 }) => {
    const starTypes = generateStars(rating, maxStars);

    function renderStars(starType: StarType, key: number): JSX.Element {
        let svgPath = "";
        switch (starType) {
            case StarType.Yellow:
                svgPath = "/star-gold-full.svg";
                break;
            case StarType.Half:
                svgPath = "/star-gold-half.svg";
                break;
            case StarType.Gray:
                svgPath = "/star-gray.svg";
                break;
            default:
                svgPath = "";
        }

        return <img className="starRating__star" src={svgPath} alt={starType} key={key} />;
    }

    return <div className="starRating">{starTypes.map((starType, index) => renderStars(starType, index))}</div>;
};

export default StarRatingComponent;
