import { faFilterCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import SideBar from "../__modals/base/SideBar/SideBar";
import { SecondaryFilterFooter } from "./SecondaryFilterFooter/SecondaryFilterFooter";
import { SecondaryFilterProps } from "./types";
import { Button } from "../__buttons/Button/Button";

export const SecondaryFilter: FC<SecondaryFilterProps> = ({
    isOpen,
    children,
    showResetFilter,
    title,
    variant = "primary",
    onSave,
    onCancel,
    onClickOutside,
    onReset,
}) => {
    const { t } = useTranslation();
    return (
        <SideBar
            open={isOpen}
            onBlur={() => {
                onClickOutside && onClickOutside();
            }}
            zIndexUp
        >
            <div className="secondaryFilter">
                <p className={`secondaryFilter__header secondaryFilter__header--${variant}`}>
                    {title || t(TranslationCommon.FILTERS)}
                </p>

                <div className="secondaryFilter__body">
                    <div className="secondaryFilter__body__filter">
                        <Button
                            type="link"
                            isDisabled={!showResetFilter}
                            onClick={onReset}
                            text={t(TranslationKeys.SECONDARY_FILTER_CLEAR_FILTERS)}
                            iconLeft={faFilterCircleXmark}
                            variant={variant}
                        />
                    </div>

                    {children}
                </div>
                <SecondaryFilterFooter onCancel={onCancel} onSave={onSave} variant={variant} />
            </div>
        </SideBar>
    );
};
