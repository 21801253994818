import { FC } from "react";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SidebarItemProps = {
    icon: IconProp;
    onClick: () => void;
    text: string;
    to: string;
};

export const SidebarItem: FC<SidebarItemProps> = ({ icon, onClick, text, to }) => {
    return (
        <Link className="sidebarItem" onClick={onClick} to={to}>
            <div className="sidebarItem__sidebarIcon">
                <FontAwesomeIcon className="sidebarItem__icon" icon={icon} />
            </div>
            <p className="sidebarItem__link">{text}</p>
        </Link>
    );
};
