import { FC } from "react";
import { AvatarstackModel } from "./types";
import { Avatar } from "../Avatar/Avatar";
import { AvatarSize } from "../Avatar/types";

type AvatarStackProps = {
    avatars: AvatarstackModel[];
    size?: AvatarSize;
};

export const AvatarStack: FC<AvatarStackProps> = ({ avatars, size }) => {
    const avatarsSliced = [...avatars].slice(0, 2);

    const getLength = () => {
        const avatarLenght = avatars.length - 2;
        return avatarLenght >= 9 ? 9 : avatarLenght;
    };

    return (
        <div className="avatarStack">
            {avatars.length >= 3 && (
                <div className={`avatarStack__avatar--${size}`}>
                    <Avatar colorId={3} avatarName={`+ ${getLength()}`} size={size} border />
                </div>
            )}
            {avatarsSliced.reverse().map(({ id, name, profilePictureUrl }) => (
                <div className={`avatarStack__avatar--${size}`} key={id}>
                    <Avatar colorId={id} avatarName={name} img={profilePictureUrl} size={size} border />
                </div>
            ))}
        </div>
    );
};
