import { faFile, faFilm, faImage, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatFileSize } from "app/helpers/formatFileSize";
import { TranslationModals } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NotificationIcon } from "../NotificationIcon/NotificationIcon";
import Spinner from "../Spinner/Spinner";

type Props = {
    handleDeleteImage: () => void;
    handleEditImage?: () => void;
    name: string;
    size: number;
    type: string;
    error?: boolean;
    disabled?: boolean;
    loading?: boolean;
    editable?: boolean;
};

export const ImagePreview: FC<Props> = ({
    handleDeleteImage,
    handleEditImage,
    name,
    size,
    type,
    disabled,
    error,
    loading,
    editable,
}) => {
    const { t } = useTranslation();
    const fileTypeImage = () => {
        if (type === "image") return faImage;
        if (type === "audio") return faMicrophone;
        if (type === "video") return faFilm;
        else return faFile;
    };

    return (
        <div
            className={`ImagePreview ${error ? "ImagePreview--error" : ""} ${
                disabled ? "ImagePreview--disabled" : ""
            } ${loading ? "ImagePreview--loading" : ""}`}
        >
            <div className="ImagePreview__flex">
                <NotificationIcon icon={fileTypeImage()} variant={error ? "danger" : loading ? "gray" : "primary"} />
                <div className="ImagePreview__texts">
                    <div
                        className={`ImagePreview__texts__firstLine  ${
                            error ? "ImagePreview__texts__firstLine--error" : ""
                        }
                        ${loading ? "ImagePreview__texts__firstLine--loading" : ""}
                        `}
                    >
                        <p className="ImagePreview__texts__firstLine__text text_clamp text_clamp-1 ">
                            {error ? t(TranslationModals.TOAST_GENERIC_ERROR) : name}
                        </p>
                        {!error && !disabled && !loading && (
                            <FontAwesomeIcon className="ImagePreview__texts__firstLine__icon" icon={faCircleCheck} />
                        )}
                    </div>
                    {!!size && (
                        <p className={`ImagePreview__texts__firstLine__subtext${error ? "--error" : ""}`}>
                            {formatFileSize(size)}
                        </p>
                    )}
                </div>
            </div>
            {disabled ? null : loading ? (
                <Spinner />
            ) : (
                <div className="ImagePreview__iconContainer">
                    {!error && editable && (
                        <FontAwesomeIcon className={`ImagePreview__icon`} onClick={handleEditImage} icon={faPen} />
                    )}
                    <FontAwesomeIcon
                        className={`ImagePreview__icon${error ? "--error" : ""}`}
                        onClick={handleDeleteImage}
                        icon={faTrash}
                    />
                </div>
            )}
        </div>
    );
};
