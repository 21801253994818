import { SortedTypeModel } from "app/components_v2/Table/types";
import { useToast } from "app/hooks/Toast/useToast";
import { usePlanAPPCC } from "app/hooks/usePlanAPPCC";
import {
    CorrectiveMeasureInsertModel,
    CorrectiveMeasureModel,
} from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";
import CorrectiveMeasureService from "app/services/05-QUA/CorrectiveMeasureService";
import { TranslationModals } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    INITIAL_CORR_MESS,
    INITIAL_CORR_MESS_DELETE_MODAL,
    INITIAL_CORR_MESS_FILTER_DATA,
    INITIAL_CORR_MESS_MODAL,
} from "../../constants/constants";
import { CorrectiveMeasureDeleteModalType, CorrectiveMeasureFilterData, CorrectiveMeasureModalType } from "../../types";
import { fillExtraparams } from "../helpers/fillExtraparams";
import { PaginationDefaults } from "app/shared/Constants";
import { useSession } from "app/hooks";

type IFetch = {
    sfData?: CorrectiveMeasureFilterData;
    pi?: number;
    sortF?: string;
    sortD?: SortedTypeModel;
    companyId?: string;
};

export const useCorrectiveMeasure = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getPlans, onChangeUrlParams, planOptions, urlParams } = usePlanAPPCC();
    const session = useSession();
    const language = session?.user.language;

    const [data, setData] = useState<CorrectiveMeasureModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterData, setFilterData] = useState<CorrectiveMeasureFilterData>(INITIAL_CORR_MESS_FILTER_DATA);
    const [sortField, setSortField] = useState<string>("id");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [correctiveMeasureModal, setCorrectiveMeasureModal] =
        useState<CorrectiveMeasureModalType>(INITIAL_CORR_MESS_MODAL);
    const [deleteModal, setDeleteModal] = useState<CorrectiveMeasureDeleteModalType>(INITIAL_CORR_MESS_DELETE_MODAL);
    const [isDeleteModalLoading, setIsDeleteModalLoading] = useState<boolean>(false);

    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!INITIAL_CORR_MESS_FILTER_DATA.query.length);

    const getData = async ({ pi, sfData, sortD, sortF }: IFetch) => {
        setIsLoading(true);
        const filterDataValues = sfData ? sfData : filterData;
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const planId = urlParams.get("planId") || "-1";

        const sr = await CorrectiveMeasureService.GetList({
            query: filterDataValues.query,
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexFetch,
            sortDirection: sortD || sortDirection,
            sortField: sortF || sortField,
            extraParams: fillExtraparams({ planId }),
        });

        if (!sr.status()) {
            handleToast({ title: sr.error, variant: "danger" });
            setIsLoading(false);
            setData([]);
            setTotal(0);
            return;
        }
        setData(sr.data.list);
        setTotal(sr.data.count);
        setIsLoading(false);
    };

    const onSFChange = (values: CorrectiveMeasureFilterData) => setFilterData((prev) => ({ ...prev, ...values }));

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSearch = () => {
        getData({ pi: 0 });
        setPageIndex(1);
    };

    const handleSortChange = (sortF: string, sortD: SortedTypeModel) => {
        onSortChange(sortF, sortD);
        getData({ sortF, sortD });
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getData({ pi: pi - 1 });
    };

    const handleCleanFetch = () => {
        onSFChange({ query: "" });
        setShowSearchInput(false);
        getData({ sfData: { ...filterData, query: "" }, pi: 0 });
        setPageIndex(1);
    };

    const handleOpenCreateModal = () => {
        const planId = urlParams.get("planId");
        const selectedCorrMess: CorrectiveMeasureInsertModel = {
            ...INITIAL_CORR_MESS,
            fK_PlanAPPCC: planId !== "-1" ? Number(planId) : 0,
        };
        setCorrectiveMeasureModal({ isOpen: true, selectedCorrMess, type: "create" });
    };

    const handleOpenEditModal = (selectedCorrMess: CorrectiveMeasureInsertModel) => {
        setCorrectiveMeasureModal({ isOpen: true, selectedCorrMess: selectedCorrMess, type: "edit" });
    };

    const handleOpenDeleteModal = (selectedCorrMess: CorrectiveMeasureInsertModel) => {
        setDeleteModal({ isOpen: true, selectedCorrMess });
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal({ isOpen: false, selectedCorrMess: INITIAL_CORR_MESS });
    };

    const handleDeleteCorrMess = async () => {
        setIsDeleteModalLoading(true);
        const { status } = await CorrectiveMeasureService.Delete(deleteModal.selectedCorrMess.id);
        if (!status()) {
            handleToast({
                title: t(TranslationModals.CORRECTIVE_MEASSURE_FAILED_DELETE),
                variant: "danger",
                type: "alert",
            });
            setIsDeleteModalLoading(false);
            return;
        }

        handleCloseDeleteModal();
        setIsDeleteModalLoading(false);
        if (data.length === 1) {
            handleCleanFetch();
            return;
        }
        getData({});
    };

    const handleSaveCreateModel = () => {
        setCorrectiveMeasureModal(INITIAL_CORR_MESS_MODAL);
        getData({});
    };

    const handlePlanChange = (value: string) => {
        urlParams.set("planId", value);
        onChangeUrlParams(urlParams, { replace: true });
        handleCleanFetch();
    };

    useEffect(() => {
        getData({});
    }, []);

    useEffect(() => {
        getPlans();
    }, [language]);

    return {
        data,
        isLoading,
        filterData,
        sortField,
        sortDirection,
        pageIndex,
        total,
        correctiveMeasureModal,
        deleteModal,
        isDeleteModalLoading,
        showSearchInput,
        handleOpenEditModal,
        handleOpenDeleteModal,
        handleSaveCreateModel,
        handleSortChange,
        handleOpenCreateModal,
        handleDeleteCorrMess,
        onChangeCorrectiveMeasure: setCorrectiveMeasureModal,
        handleCloseDeleteModal,
        onChangePageIndex,
        setShowSearchInput,
        handleCleanFetch,
        onSFChange,
        onSearch,
        handlePlanChange,
        planOptions,
        urlParams,
    };
};
