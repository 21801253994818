import { useEffect, useState } from "react";
import MaintenanceService from "app/services/00-LOGIN/MaintenanceService";

export const useMaintenance = () => {
    const [isAppUnderMaintenance, setIsAppUnderMaintenance] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async () => {
        setIsLoading(true);
        const { data, status } = await MaintenanceService.GetMaintenanceStatusAsync();

        if (!status()) {
            setIsAppUnderMaintenance(true);
            setIsLoading(false);
            return;
        }
        setIsAppUnderMaintenance(data.isAppUnderMaintenance);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return { isAppUnderMaintenance, isLoading };
};
