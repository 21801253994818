import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { TranslationTitles } from "app/translation/translationKeys";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { RecoverPassword } from "./RecoverPasswordForm/RecoverPassword";
import { PublicPaths } from "app/shared/Constants";

export const RecoverPasswordPage = () => {
    const { t } = useTranslation();
    useTitle(t(TranslationTitles.RECOVER_PAGE_TITLE));

    return (
        <AuthLayout>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<RecoverPassword />}></Route>
            </Routes>
        </AuthLayout>
    );
};
