import { UserModel } from "app/models/01-SEG/User/UserModel";
import { UserPageFormValues } from "app/pages/01-SEG/Users/types";

export const UserModelToUserPageForm = ({
    userCode,
    email,
    initialScreenCode,
    lastName,
    firstName,
    username,
    userRoleModelList,
    companies,
    userType,
    jobDescription,
    automaticActivationDate,
}: UserModel): UserPageFormValues => {
    return {
        userCode,
        email,
        initialScreen: initialScreenCode || "",
        lastName,
        name: firstName,
        userRoleModelList: userRoleModelList || [],
        username,
        userCompanyList:
            companies?.map(({ companyName, companyId }) => ({
                label: companyName,
                value: String(companyId),
            })) || [],
        userType,
        fk_JobDescription: jobDescription?.id !== undefined ? String(jobDescription?.id) : undefined,
        isAutomaticActivationOpen: !!automaticActivationDate,
        automaticActivationDate: automaticActivationDate,
    };
};
