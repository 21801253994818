import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { faClone } from "@fortawesome/pro-light-svg-icons";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { Badge } from "app/components_v2/Badge/Badge";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { MobileTaskRowHeaderProps } from "../type";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { ResponsibleAvatar } from "../../ResponsibleAvatar/ResponsibleAvatar";

export const MobileTaskRowHeader: FC<MobileTaskRowHeaderProps> = ({
    colorId,
    name,
    isChecked,
    onChangeCheckbox,
    img,
    badgeVariant,
    badgeTitle,
    data,
    selectedTab,
}) => {
    const nav = useNavigate();
    const { id } = data;
    return (
        <div className="mobileTaskRowHeader">
            <div className="mobileTaskRowHeader__userInfo">
                {isChecked !== undefined && onChangeCheckbox && (
                    <CheckBox checked={isChecked} onChange={onChangeCheckbox} />
                )}
                <ResponsibleAvatar record={data} tab={selectedTab} />
                <p className="mobileTaskRowHeader__userInfo__userName">{name}</p>
            </div>
            <div className="mobileTaskRowHeader__actions">
                <Badge title={badgeTitle} variant={badgeVariant} />
                <CellIcons
                    icons={[
                        {
                            icon: faClone,
                            onClick: (e) => {
                                e.stopPropagation();
                                nav(`duplicate/${id}`);
                            },
                            hidden: !hasPermissionToAdd(SecScreen.TASK),
                        },
                        {
                            icon: faPen,
                            onClick: (e) => {
                                e.stopPropagation();
                                nav(`edit/${id}`);
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};
