import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { NewPasswordValidator } from "app/components_v2/NewPasswordValidator/NewPasswordValidator";
import { setIsActivationPending } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { useSession } from "app/hooks";
import { useValidateResetPassword } from "app/hooks/Login/Validations/useValidateResetPassword";
import { useToast } from "app/hooks/Toast/useToast";
import { RestablishPasswordRequestModel } from "app/models/00-LOGIN/Profile/RestablishPasswordRequestModel";
import { ProfileService } from "app/services";
import { CookieKey, PublicPaths } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

type FormValues = {
    password: string;
    confirmPassword: string;
};

type ErrorFormValues = {
    errorPassword: string;
    errorConfirmPassword: string;
};

const initialFormValues: FormValues = {
    password: "",
    confirmPassword: "",
};

const initialErrorFormValues: ErrorFormValues = {
    errorPassword: "",
    errorConfirmPassword: "",
};

type ResetPasswordProps = {
    isNewPassword?: boolean;
};

const ResetPassword: FC<ResetPasswordProps> = ({ isNewPassword }) => {
    const nav = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { checkPassword } = useValidateResetPassword();
    const { handleToast } = useToast();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const session = useSession();

    const token = searchParams.get("t");

    const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
    const [errorFormValues, setErrorFormValues] = useState<ErrorFormValues>(initialErrorFormValues);

    const { password, confirmPassword } = formValues;

    const handleInputChange = (values: Partial<FormValues>) => setFormValues((prev) => ({ ...prev, ...values }));

    const handleErrorsChange = (values: Partial<ErrorFormValues>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const onFinish = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!token && !isNewPassword) return nav(PublicPaths.LOGIN);
        if (validateForm()) return;

        const values: RestablishPasswordRequestModel = {
            email: "",
            password: password,
            token: token ? decodeURI(token) : "",
        };

        const service = isNewPassword
            ? ProfileService.NewPassword(values)
            : // TODO: Parlar-ho amb el toni per eliminar-ho.
            location.pathname.includes("activate")
            ? ProfileService.ActivateAccount(values)
            : ProfileService.ChangePassword(values);

        setIsLoading(true);

        const sr = await service;
        if (!sr.status()) {
            setIsLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                variant: "danger",
                type: "alert",
            });
            return;
        }

        isNewPassword && updateSession();
        isNewPassword && nav(PublicPaths.LOGIN, { state: { from: location.pathname } });
        if (!!token) nav(PublicPaths.LOGIN, { state: { from: location.pathname } });
    };

    const updateSession = () => {
        setIsActivationPending(false);
        dispatch(Actions.updateIsActivationPending(false));
    };

    const validateForm = () => {
        setErrorFormValues(initialErrorFormValues);
        if (!validatePassword()) return true;

        return false;
    };

    const validatePassword = () => {
        if (!password) {
            handleErrorsChange({
                errorPassword: t(TranslationErrors.PASS_ADMIN_INVALID),
            });
            return false;
        }

        const { isErrored, errorMessage } = checkPassword(password);
        if (isErrored) {
            handleErrorsChange({
                errorPassword: errorMessage,
            });
            return false;
        }

        if (confirmPassword !== password) {
            handleErrorsChange({ errorConfirmPassword: t(TranslationErrors.PASSWORDS_NOT_MATCH) });
            return false;
        }

        if (password.includes("*") || password.includes("'")) {
            handleErrorsChange({
                errorPassword: "",
            });
            handleToast({
                title: t(TranslationErrors.PASSWORD_CONTAINS_ASTERISK),
                variant: "danger",
                type: "alert",
            });
            return false;
        }

        handleErrorsChange({
            errorPassword: "",
        });
        return true;
    };

    useEffect(() => {
        if ((!session?.isActivationPending && isNewPassword) || (!session?.token && !token)) {
            return nav(PublicPaths.LOGIN);
        }
    }, []);

    return (
        <form onSubmit={onFinish} className="loginInputForm">
            {!!token && (
                <Link to={`${PublicPaths.LOGIN}`} className="selectProfiles__addUser">
                    <FontAwesomeIcon icon={faArrowLeft} className="selectProfiles__addUser__icon" />
                    {t(TranslationKeys.LOGIN)}
                </Link>
            )}
            <h5 className="resetPasswordInputForm__title">
                {isNewPassword ? t(TranslationKeys.NEW_PASSWORD) : t(TranslationKeys.RESET_PASSWORD_TITLE)}
            </h5>
            <div className="resetPasswordInputForm__container">
                <div className="resetPasswordInputForm__inputs">
                    <div className="resetPasswordInputForm__password">
                        <Input
                            onChange={(newValue) => handleInputChange({ password: newValue })}
                            value={password}
                            label={t(TranslationKeys.RESET_PASSWORD)}
                            type="password"
                            placeholder={t(TranslationKeys.RESET_PASSWORD_PLACEHOLDER)}
                        />
                        <NewPasswordValidator inputValue={password} />
                    </div>
                    <Input
                        onChange={(newValue) => handleInputChange({ confirmPassword: newValue })}
                        value={confirmPassword}
                        label={t(TranslationKeys.RESET_PASSWORD_CONFIRM_PASSWORD)}
                        type="password"
                        placeholder={t(TranslationKeys.RESET_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD)}
                        errorMessage={errorFormValues.errorConfirmPassword}
                    />
                </div>
                <Button text={t(TranslationCommon.SUBMIT)} isLoading={isLoading} buttonType="submit" />
            </div>
        </form>
    );
};

export default ResetPassword;
