import { FC } from "react";
import { ChatBotMessage } from "./ChatBotMessage/ChatBotMessage";
import { ChatBotInput } from "./ChatBotInput/ChatBotInput";
import { useChatBot } from "./hooks/useChatBot";

const ChatBotGPT: FC = () => {
    const {
        containerRef,
        input,
        isDisabled,
        isPreviousMessageSameOwner,
        messages,
        onClickButton,
        sendUserMessage,
        onChangeInput,
    } = useChatBot();

    return (
        <div className="chatbotGPT">
            <div className="chatbotGPT__conversation" ref={containerRef}>
                {messages
                    .filter(({ isHidden }) => !isHidden)
                    .map((message, i) => (
                        <ChatBotMessage
                            message={message}
                            key={message.id}
                            isPreviousMessageSameOwner={isPreviousMessageSameOwner(i)}
                            onClickIcon={(actionKey) => onClickButton(actionKey, i)}
                        />
                    ))}
            </div>
            <ChatBotInput message={input} onChange={onChangeInput} onSubmit={sendUserMessage} isDisabled={isDisabled} />
        </div>
    );
};

export default ChatBotGPT;
