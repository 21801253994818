import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { AriaLabels } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type IconSelectProps = {
    icon: IconProp;
    handleChange: (value: OptionsSearch) => void;
    options: OptionsSearch[];
    currentSelection: OptionsSearch;
    showOptions: (value: boolean) => void;
};

export const IconSelect: FC<IconSelectProps> = ({ icon, handleChange, options, currentSelection, showOptions }) => {
    const { t } = useTranslation();

    const [showOptionsComponent, setShowOptionsComponent] = useState(false);

    const handleSelect = (lang: OptionsSearch) => {
        setShowOptionsComponent(false);
        handleChange(lang);
    };

    const handleShow = () => {
        showOptions(!showOptionsComponent);
        setShowOptionsComponent(!showOptionsComponent);
    };

    return (
        <>
            {showOptionsComponent && (
                <div className={`iconSelect__options`}>
                    {options?.map(
                        (item) =>
                            item.label !== currentSelection?.label && (
                                <p
                                    className="iconSelect__options__item"
                                    key={item.value}
                                    onClick={() => handleSelect(item)}
                                >
                                    {item.label}
                                </p>
                            )
                    )}
                </div>
            )}
            <FontAwesomeIcon icon={icon} />

            <div className={`iconSelect__select `} onClick={handleShow}>
                <p>{currentSelection?.label}</p>
                <FontAwesomeIcon
                    icon={showOptionsComponent ? faChevronDown : faChevronUp}
                    title={showOptionsComponent ? t(AriaLabels.AL_CHEVRON_DOWN) : t(AriaLabels.AL_CHEVRON_UP)}
                />
            </div>
        </>
    );
};
