import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "app/components_v2/__buttons/Button/Button";
import AudioRecorder from "app/components_v2/AudioRecorder/AudioRecorder";

export const AuditoRecorderSection: FC<SectionsShowProp> = ({ show }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioBase64, setAudioBase64] = useState<string | null>(null);

    const handleAcceptAudio = () => {
        if (audioBase64) {
            console.log("Audio accepted:", audioBase64);
            setAudioBase64(null);
        }
    };

    return (
        <Section title="Audio Recorder" show={show}>
            <AudioRecorder
                disabled={false}
                audioBase64={audioBase64}
                setAudioBase64={setAudioBase64}
                isRecording={isRecording}
                setIsRecording={setIsRecording}
            />
            {audioBase64 && <Button onClick={handleAcceptAudio} iconRight={faCheck} size="xs" />}
        </Section>
    );
};
