import { FC, useEffect, useState } from "react";
import { RadioButton } from "../RadioButton";
import { v4 } from "uuid";
import { RadioChangeValues, RadioGroupProps, RadioState } from "../radioTypes";

export const RadioGroup: FC<RadioGroupProps> = ({
    onChange,
    options,
    name,
    defaultValue,
    direction = "row",
}) => {
    const [radioValues, setRadioValues] = useState<RadioState[]>([]);

    const handleChange = ({ id, label, value }: RadioChangeValues) => {
        onChange({ label, value });
        setRadioValues((prev) =>
            prev.map((radio) =>
                radio.id === id ? { ...radio, checked: true } : { ...radio, checked: false }
            )
        );
    };

    useEffect(() => {
        const radioOptions = options.map((option) => ({
            ...option,
            id: v4(),
            checked: option.value === defaultValue,
        }));
        setRadioValues(radioOptions);
    }, []);

    return (
        <div className={`radioGroup ${direction}`}>
            {radioValues.map((radio) => {
                const { label, sublabel, value, disabled, id } = radio;
                return (
                    <RadioButton
                        key={id}
                        name={name}
                        checked={
                            radioValues.find(({ value: radioValue }) => value === radioValue)
                                ?.checked || false
                        }
                        disabled={disabled}
                        onChange={() => {
                            !disabled && handleChange(radio);
                        }}
                        htmlFor={id}
                        label={label}
                        sublabel={sublabel}
                    />
                );
            })}
        </div>
    );
};
