import {
    AnalistBarChartModel,
    AnalistDetailEntityBarChartModel,
} from "app/models/03-REP/Analytics/AnalistEntityDetailBarChartModel";

export const omitEmptyDays = (
    barChartData: AnalistDetailEntityBarChartModel | null
): AnalistDetailEntityBarChartModel | null => {
    barChartData;

    if (barChartData == null) return null;

    const originalAnalistBarchart = barChartData.analistBarChart;
    const originalAnalistLabels = barChartData.labels;

    const labels: string[] = [];
    const analistBarChart: AnalistBarChartModel[] = originalAnalistBarchart.map((d) => ({
        taskStatus: d.taskStatus,
        chartValues: [],
    }));

    const length = originalAnalistBarchart[0].chartValues.length;
    const numberOfLines = originalAnalistBarchart.length;

    for (let i = 0; i < length; i++) {
        let allZero = true;
        for (let j = 0; j < numberOfLines; j++) {
            if (originalAnalistBarchart[j].chartValues[i] !== 0) {
                allZero = false;
                break;
            }
        }
        if (!allZero) {
            originalAnalistBarchart.forEach((d, index) => {
                analistBarChart[index].chartValues.push(d.chartValues[i]);
            });
            labels.push(originalAnalistLabels[i]);
        }
    }
    return {
        analistBarChart,
        labels,
    };
};
