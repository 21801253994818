import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Tag } from "app/components_v2/Tag/Tag";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";

export const TagSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Tag" show={show}>
            <Tag message="1289471894.jpg" />
            <Tag message="1289471894.jpg" leftIcon={faListCheck} />
            <Tag message="1289471894.jpg" onClose={() => {}} />
            <Tag message="1289471894.jpg" leftIcon={faListCheck} onClose={() => {}} />
            <Tag message="1289471894.jpg" variant="white" />
            <Tag message="1289471894.jpg" variant="white" leftIcon={faListCheck} />
            <Tag message="1289471894.jpg" variant="white" onClose={() => {}} />
            <Tag
                message="1289471894.jpg"
                variant="white"
                leftIcon={faListCheck}
                onClose={() => {}}
            />
            <Tag
                message="1289471894.jpg"
                leftIcon={faListCheck}
                onClose={() => {}}
                size="md"
                chatMode="mine"
            />
            <Tag
                message="1289471894.jpg"
                leftIcon={faListCheck}
                onClose={() => {}}
                size="md"
                chatMode="other"
            />

            <Tag
                message={"Usuario de prueba"}
                onClose={() => console.log("first")}
                avatar={{
                    id: "2",
                    initials: "up",
                    name: "Usuario de prueba",
                }}
                width="auto"
                variant="red"
            />
            <Tag
                message={"Usuario de prueba"}
                onClose={() => console.log("first")}
                avatar={{
                    id: "2",
                    initials: "up",
                    name: "Usuario de prueba",
                }}
                width="auto"
                variant="green"
            />
        </Section>
    );
};
