import { FC } from "react";
import { IssueListModel } from "app/models/05-QUA/IssueModels/IssueListModel";
import { MobileRowHeader } from "app/pages/05-QUA/Issue/IssueGrid/components/MobileIssueRow/MobileRowHeader/MobileRowHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTriangleExclamation, faListCheck } from "@fortawesome/pro-regular-svg-icons";

type AuditIssuePillProps = {
    issue: IssueListModel;
    onPillClick: () => void;
};
export const AuditIssuePill: FC<AuditIssuePillProps> = ({ issue, onPillClick }) => {
    const {
        actualStatus,
        openedByUserId,
        openedByUserName,
        openedByProfilePictureUrl,
        closedByUserId,
        closedByUserName,
        closedByProfilePictureUrl,
        description,
        closeDate,
        openDate,
        isCritical,
        hasPhoto,
        fK_Task,
    } = issue;

    return (
        <div className="auditIssuePill" onClick={onPillClick}>
            <MobileRowHeader
                actualStatus={actualStatus}
                colorId={closedByUserId || openedByUserId}
                name={closedByUserName || openedByUserName}
                openDate={closeDate || openDate}
                profilePictureUrl={closedByProfilePictureUrl || openedByProfilePictureUrl || ""}
            />
            <div className="auditIssuePill__body">
                <p className="auditIssuePill__body__description">{description}</p>
                {(!!isCritical || hasPhoto || fK_Task) && (
                    <div className="auditIssuePill__body__icons">
                        {!!isCritical && (
                            <div className="auditIssuePill__body__icon">
                                <FontAwesomeIcon
                                    className="auditIssuePill__body__icon__svg"
                                    icon={faTriangleExclamation}
                                />
                            </div>
                        )}
                        {!!hasPhoto && (
                            <div className="auditIssuePill__body__icon">
                                <FontAwesomeIcon className="auditIssuePill__body__icon__svg" icon={faCamera} />
                            </div>
                        )}
                        {!!fK_Task && (
                            <div className="auditIssuePill__body__icon">
                                <FontAwesomeIcon className="auditIssuePill__body__icon__svg" icon={faListCheck} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
