import { OptionModel } from "app/models/02-TAR/OptionModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class RepSelectorService {
    // GET List of my supervisors and my analists
    public static async GetSupervisorAndAnalyst(parms: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.REP_API}/RepSelector/GetSupervisorAndAnalyst`,
            paginationParams: parms,
        });
    }
}
