import { FC, ReactNode } from "react";
import { Tab } from "../Tab";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

export type TabPropsReduced = {
    text?: string;
    leftCount?: number;
    rightCount?: number;
    type?: "dark" | "transparent";
    onClick: (current: number) => void;
    children?: ReactNode;
    hidden?: boolean;
    size?: "xs" | "md";
    fullWidth?: boolean;
};

export type TabsProps = {
    tabs: TabPropsReduced[];
    currentTab?: number;
    hideChildren?: boolean;
    isLoading?: boolean;
    height?: 40 | 50;
    width?: "full" | "auto";
    variant?: TabHeaderVariants;
};

export const Tabs: FC<TabsProps> = ({
    tabs,
    currentTab,
    hideChildren,
    isLoading = false,
    height = 40,
    width = "full",
    variant = "primary",
}) => {
    return (
        <div className={`tabs tabs--${width}`}>
            <div className="tabs__container">
                {tabs.map((tabProps, key) => {
                    return (
                        !tabProps.hidden && (
                            <Tab
                                key={key}
                                {...tabProps}
                                id={key}
                                current={key === currentTab}
                                isLoading={isLoading}
                                height={height}
                                type={variant === "appcc" ? "transparent" : tabProps.type}
                            />
                        )
                    );
                })}
            </div>
            {!hideChildren && currentTab !== undefined && !!tabs[currentTab].children && (
                <div className="tabs__content">{tabs[currentTab].children}</div>
            )}
        </div>
    );
};
