import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { TranslationErrors } from "app/translation/translationKeys";
import { IssueStatusState } from "../state/context/issueGridContext";

export const getIssueTableErrorCode = (issueType: IssueTypeCodeModel, issueStatusState: IssueStatusState) => {
    if (issueType === "TASK" && issueStatusState === "BOTH") return TranslationErrors.TABLE_ERROR_ISSUE_TASK;
    if (issueType === "TASK" && issueStatusState === "OPEN") return TranslationErrors.TABLE_ERROR_ISSUE_TASK_OPEN;
    if (issueType === "TASK" && issueStatusState === "CLOSED") return TranslationErrors.TABLE_ERROR_ISSUE_TASK_CLOSE;

    if (issueType === "TASK_ASSET" && issueStatusState === "BOTH")
        return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET;
    if (issueType === "TASK_ASSET" && issueStatusState === "OPEN")
        return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_OPEN;
    if (issueType === "TASK_ASSET" && issueStatusState === "CLOSED")
        return TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE;

    if (issueType === "GENERIC" && issueStatusState === "BOTH") return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC;
    if (issueType === "GENERIC" && issueStatusState === "OPEN") return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_OPEN;
    if (issueType === "GENERIC" && issueStatusState === "CLOSED")
        return TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_CLOSE;

    if (issueType === "AUDIT" && issueStatusState === "BOTH") return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT;
    if (issueType === "AUDIT" && issueStatusState === "OPEN") return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_OPEN;
    return TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_CLOSE;
};
