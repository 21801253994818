import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { Step2 } from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const StepTwoInfo: FC<Step2> = ({
    DepartmentForTask,
    SubDepartementForTask,
    companyForTask,
    userForTask,
    workingPositionForTask,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    return (
        <>
            <div>
                <span className="stepFiveLabel">
                    {translateCustomerTypeKeys(TranslationKeys.COMPANY_TASK, { firstReplace: "SINGULAR" })}
                </span>
                <div className="stepFivePlaceHolderInput">{companyForTask.label}</div>
            </div>
            {userForTask.value.length ? (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_ASIGNED_TO)}</span>
                    <div className="stepFivePlaceHolderInput">{userForTask.label}</div>
                    {DepartmentForTask.value.length && (
                        <div>
                            <span className="stepFiveLabel">
                                {t(TranslationKeys.TASK_ASIGNED_TO_USER_IN_DEPARTMENT)}
                            </span>
                            <div className="stepFivePlaceHolderInput">{DepartmentForTask.label}</div>
                        </div>
                    )}
                </div>
            ) : DepartmentForTask.value.length ? (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_ASIGNED_TO_DEPARTMENT)}</span>
                    <div className="stepFivePlaceHolderInput">{DepartmentForTask.label}</div>
                </div>
            ) : null}

            {SubDepartementForTask.value.length ? (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_ASIGNED_TO_SUBDEPARTMENT)}</span>
                    <div className="stepFivePlaceHolderInput">{SubDepartementForTask.label}</div>
                </div>
            ) : null}
            {workingPositionForTask.value.length ? (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_ASIGNED_TO_WORKING_POSITION)}</span>
                    <div className="stepFivePlaceHolderInput">{workingPositionForTask.label}</div>
                </div>
            ) : null}
        </>
    );
};
