import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { CloseCompanyTranslations, DepartmentTranslations, TranslationCommon } from "app/translation/translationKeys";
import { DateRange } from "app/models/utilities";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { CloseRangeDates } from "app/components_v2/CloseRangeDates/CloseRangeDates";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Label } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";

type CloseDepartmentModalProps = {
    closeDates?: DateRange[];
    isLoading: boolean;
    onClose: () => void;
    onClosingDates: (dates: Date[] | undefined[], index: number) => void;
    onAddNewRangeHour: () => void;
    onDeleteNewRangeHour: (index: number) => void;
    onSubmit: () => void;
};

export const CloseDepartmentModal: FC<CloseDepartmentModalProps> = ({
    closeDates,
    isLoading,
    onClose,
    onClosingDates,
    onAddNewRangeHour,
    onDeleteNewRangeHour,
    onSubmit,
}) => {
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showIsClosedModal, setShowIsClosedModal] = useState<boolean>(false);
    const [closeDatesCopy] = useState<DateRange[] | undefined>(cloneDeep(closeDates));

    const modalTitle: Record<OpenCloseStatusCode, string> = {
        OPEN: t(DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_TITLE),
        CLOSED: t(DepartmentTranslations.CLOSE_DEPARTMENT),
    };

    const confirmModalTitle: Record<OpenCloseStatusCode, string> = {
        OPEN: t(DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_TITLE),
        CLOSED: t(DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_TITLE),
    };

    const confirmModalDescription: Record<OpenCloseStatusCode, string> = {
        OPEN: t(DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_DESCRIPTION),
        CLOSED: t(DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_DESCRIPTION),
    };

    const saveButtonLabel: Record<OpenCloseStatusCode, string> = {
        OPEN: t(DepartmentTranslations.CLOSED_DEPARTMENT_OPEN),
        CLOSED: t(TranslationCommon.CLOSE),
    };

    const isClosedDepartment: OpenCloseStatusCode = !!closeDates?.length ? "CLOSED" : "OPEN";

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) return;
        setShowIsClosedModal(true);
    };

    const validate = (): boolean => {
        let check = false;

        let error = errorMessage;
        error = "";

        const closeDepartments = closeDates?.every(({ "0": closeDate, "1": openDate }) => !closeDate && !openDate)
            ? null
            : closeDates;

        if (!closeDates?.length && !closeDatesCopy?.length) {
            check = true;
            error = t(DepartmentTranslations.CLOSED_DEPARTMENT_ERROR_NO_CLOSE_DATE);
        }

        if (
            closeDepartments != null &&
            closeDepartments.every(({ "0": closeDate, "1": openDate }) => !!closeDate && !!openDate) &&
            hasOverlappingRanges(closeDepartments)
        ) {
            check = true;
            error = t(CloseCompanyTranslations.OVERLAPING_DATES_NOT_PERMITTED);
        }
        setErrorMessage(error);

        return check;
    };

    const onConfirmSubmit = () => {
        onSubmit();
        setShowIsClosedModal(false);
    };

    return (
        <>
            {showIsClosedModal && (
                <ConfirmModal
                    title={confirmModalTitle[isClosedDepartment]}
                    description={confirmModalDescription[isClosedDepartment]}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={onConfirmSubmit}
                    onClose={() => setShowIsClosedModal(false)}
                />
            )}
            <GenericModal
                header={{ title: modalTitle[isClosedDepartment], onClose }}
                size="sm"
                portal
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: saveButtonLabel[isClosedDepartment],
                            form: "closeDepartmentModal",
                            type: "submit",
                            fullWidth: true,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            onClick: onClose,
                            buttonType: "tertiary",
                            fullWidth: true,
                        }}
                        loading={isLoading}
                    />
                }
            >
                <form onSubmit={handleSubmit} className="closeDepartmentModal" id="closeDepartmentModal">
                    <Label label={t(DepartmentTranslations.CLOSE_DEPARTMENT_DATES)} />
                    <CloseRangeDates
                        closeDates={closeDates}
                        onClosingDates={onClosingDates}
                        onAddNewRangeHour={onAddNewRangeHour}
                        onDeleteNewRangeHour={onDeleteNewRangeHour}
                        errorMessage={errorMessage}
                    />
                </form>
            </GenericModal>
        </>
    );
};
