import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { FC, ReactNode } from "react";

type TableNoTabHeaderProps = {
    title: string;
    rightNode: ReactNode;
    variant?: TabHeaderVariants;
};

export const TableNoTabHeader: FC<TableNoTabHeaderProps> = ({ title, rightNode, variant = "primary" }) => {
    return (
        <div className={`tableNoTabHeader tableNoTabHeader--${variant}`}>
            <h1 className="tableNoTabHeader__title">{title}</h1>
            {rightNode}
        </div>
    );
};
