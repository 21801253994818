import { Routes, Route, Navigate } from "react-router-dom";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";
import { AutomaticRecordGrid } from "./AutomaticRecordGrid/AutomaticRecordGrid";

export const AutomaticRecordPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route
                path={""}
                element={<PrivateRoute component={AutomaticRecordGrid} viewCode={SecScreen.AUTOMATIC_RECORD} />}
            />
        </Routes>
    );
};
