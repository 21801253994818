export const checkAddedDeletedBetweenTwoArraysOfNumbers = (
    originales: number[],
    modificados: number[]
) => {
    const added: number[] = [];
    const deleted: number[] = [];

    modificados.forEach(function (id) {
        if (!originales.includes(id)) {
            added.push(id);
        }
    });

    originales.forEach(function (id) {
        if (!modificados.includes(id)) {
            deleted.push(id);
        }
    });

    return { added, deleted };
};
