import { CorrectiveMeasureInsertModel } from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";
import { CorrectiveMeasureDeleteModalType, CorrectiveMeasureFilterData, CorrectiveMeasureModalType } from "../types";

export const INITIAL_CORR_MESS_FILTER_DATA: CorrectiveMeasureFilterData = {
    query: "",
};

export const INITIAL_CORR_MESS: CorrectiveMeasureInsertModel = {
    id: 0,
    description: "",
    fK_PlanAPPCC: 0,
};

export const INITIAL_CORR_MESS_MODAL: CorrectiveMeasureModalType = {
    isOpen: false,
    selectedCorrMess: INITIAL_CORR_MESS,
    type: "create",
};

export const INITIAL_CORR_MESS_DELETE_MODAL: CorrectiveMeasureDeleteModalType = {
    isOpen: false,
    selectedCorrMess: INITIAL_CORR_MESS,
};
