import { AnalistDataBlocks, AnalistSecondaryFilterModel } from "../AnalistDetail/types";

export const INITIAL_ANALIST_SECONDARY_FILTER: AnalistSecondaryFilterModel = {
    taskType: "all",
    workingPosition: "all",
};

export const INITIAL_ANALIST_EMPTY_DATA_BLOCKS: AnalistDataBlocks = {
    actualStatus: false,
    entityHeaderInfoModel: false,
    analistEntityDetailDonutModel: false,
    analistEntityDetailPercentageStarsModel: false,
    analistEntityDetailLineChartDataModel: false,
    analistEntityDetailRankingModel: false,
    taskList: false,
};
