import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "usehooks-ts";
import { Modal } from "../__modals/base/Modal/Modal";
import { useTranslation } from "react-i18next";
import { AriaLabels } from "app/translation/translationKeys";

type PopoverProps = {
    children: ReactNode;
    target: Element | null;
    scrollTarget?: Element | null;
    position?: PopoverPosition;
    separation?: number;
    showBlur?: boolean;
    zIndexUp?: boolean;
    onClickOutside?: (event: MouseEvent | TouchEvent) => void;
    onResize?: (e: UIEvent) => void;
    onScroll?: (e: Event) => void;
    className?: string;
    portal?: boolean;
};
export type PopoverPosition = "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "bottomCenter";
type PopoverCoords = {
    x: number;
    y: number;
};

export const Popover: FC<PopoverProps> = ({
    children,
    target,
    position = "bottomLeft",
    separation = 8,
    scrollTarget,
    onClickOutside,
    showBlur,
    onResize,
    zIndexUp,
    onScroll,
    className = "",
    portal = true,
}) => {
    const { t } = useTranslation();

    const popoverElement = useRef<HTMLDivElement | null>(null);
    const clickedElement = target?.getBoundingClientRect();

    const [coordenades, setCoordenades] = useState<PopoverCoords>({ x: 0, y: 0 });
    const [initialScroll, setInitialScroll] = useState<PopoverCoords>({ x: 0, y: 0 });

    useOnClickOutside(popoverElement, (e) => onClickOutside && onClickOutside(e));

    const getCoordenades = () => {
        if (!position) return;
        const coords: Record<PopoverPosition, PopoverCoords> = {
            bottomLeft: getBottomLeftCoordenades(),
            bottomRight: getBottomRightCoordenades(),
            topLeft: getTopLeftCoordenades(),
            topRight: getTopRightCoordenades(),
            bottomCenter: getBottomCenterCoordenades(),
        };
        const popoverCoords = coords[position];
        setInitialScroll(popoverCoords);
        setCoordenades(popoverCoords);
    };

    const getBottomLeftCoordenades = (): PopoverCoords => {
        const x = clickedElement?.x || 0;
        const y = (clickedElement?.y || 0) + (clickedElement?.height || 0) + separation;
        return { x, y };
    };

    const getTopLeftCoordenades = (): PopoverCoords => {
        const x =
            (clickedElement?.left || 0) - (popoverElement.current?.clientWidth || 0) + (clickedElement?.width || 0);
        const y = (clickedElement?.y || 0) - separation - (popoverElement.current?.clientHeight || 0);
        return { x, y };
    };

    const getTopRightCoordenades = (): PopoverCoords => {
        const x = clickedElement?.left || 0;
        const y = (clickedElement?.y || 0) - separation - (popoverElement.current?.clientHeight || 0);
        return { x, y };
    };

    const getBottomRightCoordenades = (): PopoverCoords => {
        const x = (clickedElement?.x || 0) - (popoverElement.current?.clientWidth || 0) + (clickedElement?.width || 0);
        const y = (clickedElement?.y || 0) + (clickedElement?.height || 0) + separation;
        return { x, y };
    };

    const getBottomCenterCoordenades = (): PopoverCoords => {
        const x =
            (clickedElement?.x || 0) -
            (popoverElement.current?.clientWidth || 0) / 2 +
            (clickedElement?.width || 0) / 2;
        const y = (clickedElement?.y || 0) + (clickedElement?.height || 0) + separation;
        return { x, y };
    };

    useLayoutEffect(() => {
        getCoordenades();
    }, []);

    useEffect(() => {
        const handleScroll = (e: Event) => {
            const menuTop = initialScroll.y - (scrollTarget?.scrollTop || 0);
            const menuX = initialScroll.x - (scrollTarget?.scrollLeft || 0);
            setCoordenades({ y: menuTop, x: menuX });
            onScroll && onScroll(e);
        };

        scrollTarget?.addEventListener("scroll", handleScroll);

        return () => {
            scrollTarget?.removeEventListener("scroll", handleScroll);
        };
    }, [initialScroll]);

    useEffect(() => {
        window.addEventListener("resize", (e) => onResize && onResize(e));
        return () => window.removeEventListener("resize", (e) => onResize && onResize(e));
    }, []);

    const popoverContent = (
        <>
            {showBlur && <Modal showBlur={false} className="mobileFooterModal__container" children />}
            <div
                className={`popover ${zIndexUp ? "zIndexUp" : ""} ${className}`}
                style={{
                    transform: `translate(${coordenades.x}px, ${coordenades.y}px)`,
                }}
                ref={popoverElement}
                aria-label={t(AriaLabels.AL_POPOVER)}
            >
                {children}
            </div>
        </>
    );
    return portal ? createPortal(popoverContent, document.body) : popoverContent;
};
