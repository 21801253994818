import { Avatar } from "app/components_v2/Avatar/Avatar";
import { FC } from "react";
import { AlertPillType } from "../types";
import { Badge } from "app/components_v2/Badge/Badge";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { getAlertPillHeaderDate } from "../helpers/getAlertPillHeaderDate";
import { AvatarStack } from "app/components_v2/AvatarStack/AvatarStack";

type secondaryAvatarProp = {
    receiverProfilePictureURL?: string;
    receiverUserName: string;
    receiverUserId: number;
};

export type AlertPillHeaderProps = {
    multipleAvatars?: secondaryAvatarProp[];
    profilePictureUrl?: string;
    fullName?: string;
    userId?: number;
    type: AlertPillType;
    date: Date;
    labelBadge?: string;
};

export const AlertPillHeader: FC<AlertPillHeaderProps> = ({
    multipleAvatars,
    fullName,
    profilePictureUrl,
    userId,
    type,
    date,
    labelBadge,
}) => {
    const singleAvatarName = (multipleAvatars?.length === 1 ? multipleAvatars[0].receiverUserName : fullName) || "";
    const singleAvatarId = (multipleAvatars?.length === 1 ? multipleAvatars[0].receiverUserId : userId) || 0;
    const singleAvatarProfilePictureUrl =
        (multipleAvatars?.length === 1 ? multipleAvatars[0].receiverProfilePictureURL : profilePictureUrl) || "";

    return (
        <div className="alertPillHeader">
            <div className="alertPillHeader__assigned">
                {multipleAvatars && multipleAvatars?.length >= 2 ? (
                    <AvatarStack
                        avatars={multipleAvatars.map(
                            ({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                                id: receiverUserId,
                                name: receiverUserName,
                                profilePictureUrl: receiverProfilePictureURL,
                            })
                        )}
                        size="xs"
                    />
                ) : (
                    <Avatar
                        avatarName={singleAvatarName}
                        colorId={singleAvatarId}
                        img={singleAvatarProfilePictureUrl}
                        size="xs"
                        name={singleAvatarName}
                    />
                )}
            </div>
            {type === "programmed" ? (
                <Badge
                    title={getAlertPillHeaderDate(date)}
                    icon={faClock}
                    iconPosition="left"
                    variant="grey"
                    ariaLabel={labelBadge}
                />
            ) : (
                <p className="alertPillHeader__date">{getAlertPillHeaderDate(date)}</p>
            )}
        </div>
    );
};
