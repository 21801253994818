import { useState } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";

export const useQrs = () => {
    const [qrs, setQrs] = useState<OptionModel[]>([]);

    const getQrs = async (companyId: number) => {
        const { status, data } = await TarSelectorService.GetWorkingPostions({
            extraParams: `CompanyId=${companyId}`,
        });

        if (!status()) {
            setQrs([]);
            return;
        }

        setQrs(data);
    };
    return { qrs, getQrs };
};
