import {
    AnalistTranslation,
    AriaLabels,
    IssueTranslation,
    AssetTranslations,
    AuditGridTranslation,
    TranslationCommon,
    TranslationErrors,
    TranslationFAQs,
    TranslationKeys,
    TranslationModals,
    TranslationModules,
    TranslationTitles,
    AuditTranslation,
    CloseCompanyTranslations,
    AnalistRankingTranslations,
    TaskInstanceTranslations,
    TaskTranslations,
    BaseAuditGroupCheckListItemTranslations,
    BaseAuditGroupCheckListItemTypeTranslations,
    ScanQRTranslations,
    AuditGroupCheckListItemTranslations,
    AuditInstanceTranslations,
    AuditGroupCheckListInstanceTranslations,
    ReviewTaskTranslations,
    NewUserTabsTranslation,
    OnBoardingStepsTranslation,
    AutomaticRecordTranslations,
    BaseTaskTranslations,
    AuditFilterTranslations,
    ChatBotGPTTranslations,
    CompanyTranslations,
    RoleCollapsableTranslations,
    SensorModalTranslations,
    EmailDistributionListTranslations,
    TaskPillTranslations,
    TranscriptionTranslations,
    TaskHistoryTranslations,
    IconTitleTranslations,
    AlertModalTranslations,
    DragFileTranslations,
    PatchNotesTranslations,
    WorkingPositionTranslations,
    DepartmentTranslations,
    NotificationsTranslations,
    UserTypeTranslations,
    WizardLicenseTranslations,
    PendingTasksCompanyDepartmentTranslations,
    CustomerTypeTranslations,
    TaskTemporalityReadOnlyTranslations,
    AssetTypeTranslations,
    AssetDataTypeTranslations,
} from "./translationKeys";
import { wizards } from "./wizards.en";

export const en = {
    translation: {
        /* WIZARDS */
        ...wizards.company,
        ...wizards.department,
        ...wizards.license,
        ...wizards.role,

        /* MODULES */
        [TranslationModules.TAR_REGISTRY]: "Worker",
        [TranslationModules.TAR_PLAN]: "Task Planning",
        [TranslationModules.TAR_REVISION]: "Supervisor",
        [TranslationModules.REPORT]: "Reports",
        [TranslationModules.ANALYTICS]: "Analist",

        /* PERMISSIONS */
        // TAR_REVISION
        [TranslationTitles.PERMISSION_TITLE_TASK_PHOTO]: "Review Tasks",
        [TranslationTitles.PERMISSION_TITLE_FEEDBACK_TASKS]: "My Ratings",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_RECENT]: "Recent",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_HIGHER]: "Higher",
        [TranslationKeys.FEEDBACK_MODAL_ORDER_LOWER]: "Lower",

        // TITLES
        [TranslationTitles.PERMISSION_TITLE_ADMIN]: "Administrator",
        [TranslationTitles.PERMISSION_TITLE_ANALYST]: "Analyst",
        [TranslationTitles.PERMISSION_TITLE_SUPERVISOR]: "Supervisor",
        [TranslationTitles.PERMISSION_TITLE_EMPLOYEE]: "Employee",
        [TranslationTitles.PERMISSION_TITLE_ALERTS_NOTIFICATIONS]: "Alerts and Notifications",
        [TranslationTitles.PERMISSION_TITLE_TAR_MANAGEMENT]: "Task Management",
        [TranslationTitles.PERMISSION_TITLE_ISSUE_MANAGEMENT]: "Issue Management",

        [TranslationTitles.PERMISSION_TITLE_ACCES_CONFIG]: "Access to Configuration",
        [TranslationTitles.PERMISSION_TITLE_CENTERS_MANAGEMENT]: "{0} Management",
        [TranslationTitles.PERMISSION_TITLE_ROLE_MANAGEMENT]: "Role Management",
        [TranslationTitles.PERMISSION_TITLE_TEAM_MANAGEMENT]: "Team Management",
        [TranslationTitles.PERMISSION_TITLE_TEMPORARY_ROLES]: "Temporary Roles",
        [TranslationTitles.PERMISSION_TITLE_USER_MANAGEMENT]: "User Management",
        [TranslationTitles.PERMISSION_TITLE_BASE_TASK_MANAGEMENT]: "Base Task Management",
        [TranslationTitles.PERMISSION_TITLE_COMPANY_RANKING]: "{0} Analysis",
        [TranslationTitles.PERMISSION_TITLE_TASK_HISTORY]: "Task History",
        [TranslationTitles.PERMISSION_TITLE_TASK_REVIEW]: "Task Review",
        [TranslationTitles.PERMISSION_TITLE_MY_VALORATION]: "My Valoration",
        [TranslationTitles.PERMISSION_TITLE_RESET_TASK]: "Reset Tasks",
        [TranslationTitles.PERMISSION_TITLE_ALERTS]: "Alerts",
        [TranslationTitles.PERMISSION_TITLE_SYSTEM_NOTIFICATION]: "System Notifications",
        [TranslationTitles.PERMISSION_TITLE_ASSETS]: "Assets Management",
        [TranslationTitles.PERMISSION_TITLE_QR]: "QR Management",
        [TranslationTitles.PERMISSION_TITLE_REASIGN_TASK]: "Reassign Tasks",
        [TranslationTitles.PERMISSION_TITLE_TASK]: "Tasks Management",
        [TranslationTitles.PERMISSION_TITLE_ATTACH_FILES]: "Attach Files",
        [TranslationTitles.PERMISSION_TITLE_CLOSE_ISSUE]: "Close Issues",
        [TranslationTitles.PERMISSION_TITLE_OPEN_TASK_ISSUE]: "Open Task Issues",
        [TranslationTitles.PERMISSION_TITLE_OPEN_MANUAL_ISSUE]: "Open Manual Issues",
        [TranslationTitles.PERMISSION_TITLE_REOPEN_ISSUE]: "Reopen Issues",
        [TranslationTitles.PERMISSION_TITLE_ISSUE]: "Issue Management",
        [TranslationTitles.PERMISSION_TITLE_ALLOW_GALERY_PHOTO]: "Allow Gallery Photo",
        [TranslationTitles.PERMISSION_TITLE_JOB_DESC_MANAGER]: "User Category Manager",
        [TranslationTitles.PERMISSION_TITLE_REGISTRY_HISTORY]: "Registry Historic",
        [TranslationTitles.PERMISSION_TITLE_SPORADIC_TASK]: "Task without Defined Date",

        /* HACCP Module */
        [TranslationTitles.PERMISSION_TITLE_PLAN_APPCC]: "HACCP Module",
        [TranslationTitles.PERMISSION_TITLE_APPCC_MODULE]: "Plan Manager",
        [TranslationTitles.PERMISSION_TITLE_BASE_TASK_APPCC]: "Base Record Manager",
        [TranslationTitles.PERMISSION_TITLE_REGISTRY_MANAGEMENT]: "Record Manager",
        [TranslationTitles.PERMISSION_TITLE_ASSET_PAGE_PERMISSION]: "Asset Manager",
        [TranslationTitles.PERMISSION_TITLE_CORR_MEASURE_MANAGER]: "Corrective Measures Manager",

        /* Audit Templates */
        [TranslationTitles.PERMISSION_TITLE_AUDIT_MANAGEMENT]: "Audit Templates",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_GROUP_MANAGEMENT]: "Audit Templates - Groupings",
        [TranslationTitles.PERMISSION_TITLE_AG_CHECKLIST_MANAGEMENT]: "Audit Templates - Checklists",
        [TranslationTitles.PERMISSION_TITLE_AGCL_ITEM_MANAGEMENT]: "Audit Templates - Checklist Items",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_MANAG]: "Audits",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_NC]: "Audits - Review Non-Conformities",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INST_REPORT]: "Audits - Audit Report",
        [TranslationTitles.PERMISSION_TITLE_AUDIT_INSTANCE_DEL_NC]: "Audits - Delete Non-Conformities",

        /* Automatic Records */
        [TranslationTitles.PERMISSION_TITLE_AUTOMATIC_RECORD]: "Automatic Records",

        /* Email DistributionList */
        [TranslationTitles.PERMISSION_TITLE_EMAIL_DISTRIBUTION_LIST]: "Distribution List Manager",

        /* Close Company & Department */
        [TranslationTitles.PERMISSION_TITLE_CLOSE_COMPANY_DEPT]: "Close {0} and Teams",

        // DOC TITLES
        [TranslationKeys.APPCC_LABEL]: "Critical Task",

        // PAGE TITLES
        [TranslationTitles.LOGIN_PAGE_TITLE]: "Login",
        [TranslationTitles.RECOVER_PAGE_TITLE]: "Password Recovery",
        [TranslationTitles.RESET_PASSWORD_PAGE_TITLE]: "Password Change",
        [TranslationTitles.ACTIVATE_ACCOUNT_PAGE_TITLE]: "Account Activation",
        [TranslationTitles.SELECT_PROFILE_PAGE_TITLE]: "Select an Account",
        [TranslationTitles.SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE]: "Select Team/Subteam",
        [TranslationTitles.GROUP_COMPANY_PAGE_TITLE]: "{0} Group Manager",
        [TranslationTitles.COMPANY_EDIT_PAGE]: "Edit {0}",
        [TranslationTitles.DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE]: "Team and Subteam Manager",
        [TranslationTitles.LICENSES_PAGE_TITLE]: "License Manager",
        [TranslationTitles.MANAGE_LICENSE_PAGE_TITLE]: "License Administration",
        [TranslationTitles.MY_PROFILE_PAGE_TITLE]: "My Profile",
        [TranslationTitles.ROLES_PAGE_TITLE]: "Roles Manager",
        [TranslationTitles.EDIT_ROLE_PAGE_TITLE]: "Edit Role Permissions",
        [TranslationTitles.TEMPORARY_ROLES_PAGE_TITLE]: "Temporary Roles Manager",
        [TranslationTitles.USERS_PAGE_TITLE]: "Users Manager",
        [TranslationTitles.NEW_USER_PAGE_TITLE]: "New User",
        [TranslationTitles.EDIT_USER_PAGE_TITLE]: "Edit User",
        [TranslationTitles.USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE]: "User {0} and Teams",
        [TranslationTitles.DASHBOARD_PAGE_TITLE]: "Tasks - {0}",
        [TranslationTitles.TASK_DETAIL_TITLE]: "Task Detail",
        [TranslationTitles.SCAN_QR_PAGE_TITLE]: "QR Tasks",
        [TranslationTitles.REVIEW_TASK_PAGE_TITLE]: "Task Review - {0}",
        [TranslationTitles.REVIEW_WORKER_PAGE_TITLE]: "Employee Task Review",
        [TranslationTitles.FEEDBACK_PAGE_TITLE]: "Feedback Review",
        [TranslationTitles.FEEDBACK_DETAIL_TITLE]: "Task Feedback Detail",
        [TranslationTitles.TASK_PAGE_TITLE]: "Task Manager",
        [TranslationTitles.NEW_TASK_PAGE_TITLE]: "New Task",
        [TranslationTitles.DUPLICATE_TASK_PAGE_TITLE]: "Duplicate Task",
        [TranslationTitles.EDIT_TASK_PAGE_TITLE]: "Edit Task",
        [TranslationTitles.TASK_HISTORY_PAGE_TITLE]: "Task History",
        [TranslationTitles.REGISTRY_HISTORY_PAGE_TITLE]: "Registry History",
        [TranslationTitles.ALERTS_PAGE_TITLE]: "Alerts Manager",
        [TranslationTitles.INCOMING_ALERTS_TITLE]: "Incoming Alerts",
        [TranslationTitles.PRIVACY_POLICY_PAGE_TITLE]: "Privacy Policy",
        [TranslationTitles.WORKING_POSITION_PAGE_TITLE]: "QR Codes Manager",
        [TranslationTitles.NEW_WORKING_POSITION_PAGE_TITLE]: "New QR Code",
        [TranslationTitles.EDIT_WORKING_POSITION_PAGE_TITLE]: "Edit QR Code",
        [TranslationTitles.PRINT_QR_PAGE_TITLE]: "QR Code Printing",
        [TranslationTitles.BASE_TASK_PAGE_TITLE]: "Base Task Manager",
        [TranslationTitles.NEW_BASE_TASK_PAGE_TITLE]: "New Base Task",
        [TranslationTitles.EDIT_BASE_TASK_PAGE_TITLE]: "Edit Base Task",
        [TranslationTitles.GLOBAL_COMPANY_RANKING_PAGE_TITLE]: "Global {0} Ranking",
        [TranslationTitles.GLOBAL_DEPARTMENT_RANKING_PAGE_TITLE]: "Global Team Ranking",
        [TranslationTitles.GLOBAL_USER_RANKING_PAGE_TITLE]: "Global User Ranking",
        [TranslationTitles.USER_BY_DEPARTMENT_RANKING_PAGE_TITLE]: "User Ranking by Department",
        [TranslationTitles.ANALYTICS_PAGE_TITLE]: "{0} Analytics",
        [TranslationTitles.DEPARTMENT_RANKING_PAGE_TITLE]: "Team Ranking",
        [TranslationTitles.COMPANY_DETAIL_PAGE_TITLE]: "{0} Detail",
        [TranslationTitles.DEPARTMENT_DETAIL_PAGE_TITLE]: "Team Detail",
        [TranslationTitles.USER_DETAIL_PAGE_TITLE]: "User Detail",
        [TranslationTitles.DETAIL_PAGE_TITLE]: "Entity Detail",
        [TranslationTitles.ASSET_PAGE_TITLE]: "Asset Manager",
        [TranslationTitles.NEW_ASSET_PAGE_TITLE]: "New Asset",
        [TranslationTitles.EDIT_ASSET_PAGE_TITLE]: "Edit Asset",
        [TranslationTitles.AUDIT_PAGE_TITLE]: "Audit Manager",
        [TranslationTitles.NEW_AUDIT_PAGE_TITLE]: "New Audit",
        [TranslationTitles.COMPLETED_AUDIT_PAGE_TITLE]: "Finish Audit",
        [TranslationTitles.REVIEWED_AUDIT_PAGE_TITLE]: "Close Audit",
        [TranslationTitles.RESOLVE_AUDIT_PAGE_TITLE]: "Conduct Audit",
        [TranslationTitles.REVIEW_AUDIT_PAGE_TITLE]: "Review Audit",
        [TranslationTitles.AUDIT_TEMPLATE_PAGE_TITLE]: "Audit Template Manager",
        [TranslationTitles.CORRECTIVE_MEASURES_PAGE_TITLE]: "Corrective Measures Manager",
        [TranslationTitles.ISSUE_PAGE_TITLE]: "Issue Manager",
        [TranslationTitles.NEW_ISSUE_PAGE_TITLE]: "New Issue",
        [TranslationTitles.EDIT_ISSUE_PAGE_TITLE]: "Edit Issue",
        [TranslationTitles.PLAN_APPCC_PAGE_TITLE]: "HACCP Plan Manager",
        [TranslationTitles.TASK_APPCC_PAGE_TITLE]: "HACCP Records Manager",
        [TranslationTitles.NEW_TASK_APPCC_PAGE_TITLE]: "New Record APPCC",
        [TranslationTitles.DUPLICATE_TASK_APPCC_PAGE_TITLE]: "Duplicate Record APPCC",
        [TranslationTitles.EDIT_TASK_APPCC_PAGE_TITLE]: "Edit Record APPCC",
        [TranslationTitles.FAQS_PAGE_TITLE]: "Frequent Asked Questions",
        [TranslationTitles.EDIT_ISSUE_TITLE]: "Edit Issue",
        [TranslationTitles.BASE_AUDIT_GROUP_CHECKLIST_ITEM]: "Base Items",
        [TranslationTitles.AUTOMATIC_RECORD_PAGE_TITLE]: "Automatic Records",
        [TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE]: "Distribution List",
        [TranslationTitles.PATCH_NOTES_TITLE]: "Patch Notes",

        // COMMON
        // Permission Patents
        [TranslationCommon.ALL_PATENT]: "All",
        [TranslationCommon.ADD_PATENT]: "Add",
        [TranslationCommon.VIEW_PATENT]: "View",
        [TranslationCommon.MODIFY_PATENT]: "Modify",
        [TranslationCommon.DELETE_PATENT]: "Delete",

        [TranslationKeys.UPLOAD_FILE]: "Upload File",
        [TranslationKeys.USERS]: "Users",
        [TranslationKeys.ADD_USERS]: "Add Users",
        [TranslationKeys.RECORDING]: "Recording",
        [TranslationKeys.SEEN]: "Seen",
        [TranslationCommon.OPEN]: "Open",
        [TranslationCommon.CLOSED]: "Closed",
        [TranslationCommon.START_DATE_LABEL]: "Start Date",
        [TranslationCommon.SELECT_ALL]: "Select All",
        [TranslationCommon.ADD_ALL]: "Add All",
        [TranslationCommon.DATA]: "Data",
        [TranslationCommon.YES]: "Yes",
        [TranslationCommon.NO]: "No",
        [TranslationCommon.START]: "Start",
        [TranslationCommon.NEXT]: "Next",
        [TranslationCommon.PREVIOUS]: "Previous",
        [TranslationCommon.EXIT]: "Exit",
        [TranslationCommon.PRINT]: "Print",
        [TranslationCommon.UPLOADING_FILE]: "Uploading File...",
        [TranslationCommon.TRANSCRIPTING]: "Transcripting",
        [TranslationKeys.END]: "End",
        [TranslationKeys.DAYS]: "Days",
        [TranslationKeys.DAY]: "Day",
        [TranslationKeys.EVERY]: "Every",
        [TranslationCommon.ADD]: "Add",
        [TranslationCommon.NEW]: "New",
        [TranslationCommon.NEW_M]: "New",
        [TranslationCommon.SEARCH]: "Search",
        [TranslationCommon.CHANGE_COMPANY]: "Change {0}",
        [TranslationCommon.FILTER]: "Filter",
        [TranslationCommon.SUBMIT]: "Submit",
        [TranslationCommon.SAVE]: "Save",
        [TranslationCommon.CREATE]: "Create",
        [TranslationCommon.ACCEPT]: "Accept",
        [TranslationCommon.CANCEL]: "Cancel",
        [TranslationCommon.CONFIRM]: "Confirm",
        [TranslationCommon.LANGUAGE]: "Language",
        [TranslationCommon.OMIT]: "Omit",
        [TranslationCommon.CLOSE]: "Close",
        [TranslationKeys.TOTAL_RECORDS]: "Total Records",
        [TranslationCommon.NO_RESULTS]: "Oops! No results for your search",
        [TranslationKeys.NO_DEPARTMENTS]: "Oops! No teams found",
        [TranslationKeys.NO_SUBDEPARTMENTS]: "Oops! No subteams found",
        [TranslationCommon.LOGOUT]: "Logout",
        [TranslationCommon.COMPLETE]: "Complete",

        [TranslationKeys.IF_NOT_USERCODE_USE_DNI]:
            "If you do not have an employee code, use your ID card (DNI) or foreigner identification number (NIE).",
        [TranslationKeys.NO_VALORATION]: "No Valorations",
        [TranslationKeys.NO_VALORATION_SUBTITLE]:
            "Create a task for your teams to view the statistics of your management.",
        [TranslationKeys.NO_COMMENTS]: "No Comments",
        [TranslationKeys.NO_COMMENTS_SUBTITLE]: "Add a comment to a task to view the statistics of your management.",

        [TranslationCommon.ACCESS_DENIED]: "Access Denied",
        [TranslationCommon.GO_BACK]: "Go Back",
        [TranslationCommon.RELOAD]: "Reload",
        [TranslationCommon.ARE_YOU_SURE]: "Are you sure? This action cannot be undone.",
        [TranslationKeys.TO_DASHBOARD]: "Go to Dashboard.",
        [TranslationKeys.FORGOT_PASSWORD]: "Forgot Password?",
        [TranslationKeys.FORGOT_PASSWORD_TITLE]: "Please enter your email, a recovery link will be sent to your inbox.",
        [TranslationKeys.RESET_PASSWORD_TITLE]: "Reset Password",
        [TranslationCommon.EDIT]: "Edit",
        [TranslationCommon.DELETE]: "Delete",
        [TranslationCommon.FILTERS]: "Filters",
        [TranslationCommon.DETAILS]: "Details",
        [TranslationKeys.DELETE_DEPARTMENT]: "Delete Team",
        [TranslationKeys.DELETE_MESSAGE]: "You will delete",
        [TranslationKeys.CLONE_MESSAGE]: "You will clone",
        [TranslationKeys.MESSAGE]: "Comment",
        [TranslationKeys.DELETE_DEPARTMENT_TEXT]: "Do you want to delete the Team",
        [TranslationKeys.EDIT_DEPARTMENT]: "Edit Team",
        [TranslationKeys.ADD_USER]: "Add User",
        [TranslationKeys.ADD_USER_TEXT]: "Add User to Team",
        [TranslationKeys.ADD_SUBDEPARTMENT]: "Add Subteams",
        [TranslationKeys.EDIT_SUBDEPARTMENT]: "Edit Subteams",
        [TranslationKeys.DELETE_SUBDEPARTMENT]: "Delete Subteams",
        [TranslationKeys.SELECT_SUBDEPARTMENTS]: "Select Subteams",
        [TranslationKeys.SUBDEPARTMENT_NAME]: "Subteam Name",
        [TranslationKeys.SELECT_USERS]: "Select one or more users",
        [TranslationKeys.SELECT_USER]: "Select a user",
        [TranslationKeys.DRAG_FILES]: "Drag the image here",
        [TranslationKeys.UPLOAD_PROFILE_PICTURE]: "UPLOAD PROFILE PICTURE",
        [TranslationKeys.INPUT_VALID_EMAIL]: "Please enter a valid email",
        [TranslationCommon.INPUT_NOT_EMPTY]: "This field is required",
        [TranslationCommon.INVALID_NIF]: "The format of the TIN is not valid. Ej: X1234567L",
        [TranslationKeys.INPUT_NOT_EMPTY_POLICY]: "You must accept our privacy policy.",
        [TranslationKeys.INPUT_MAX_CHARACTERS]: "Must be maximum {0} characters.",
        [TranslationKeys.INPUT_MINIMUN]: "must be at least {0} characters",
        [TranslationKeys.INPUT_RANGE]: "must be a number between {0} and {1}",
        [TranslationKeys.INPUT_LENGTH]: "must be between {0} and {1} characters",
        [TranslationKeys.INPUT_FIXED_LENGTH]: "must be {0} characters",
        [TranslationModals.SUCCESS_SAVE]: "Success saving!",
        [TranslationModals.FAILED_SAVE]: "Failed to save!",
        [TranslationModals.SUCCESS_EDIT]: "Success editing!",
        [TranslationModals.FAILED_EDIT]: "Failed to edit!",
        [TranslationModals.SUCCESS_DELETE]: "Success deleting!",
        [TranslationCommon.FAILED_DATA_LOADED]: "Error loading data!",
        [TranslationKeys.SELECT_INSTANCE]: "Select an instance",
        [TranslationKeys.PLEASE_SELECT_INSTANCE]: "Please select an instance",
        [TranslationKeys.USER_MADE_ACTION]: "User {0} on {1} performed action {2} on {3}",
        [TranslationCommon.ENTITY_HAS_CHILDREN]: "Entity {0} has {1} associated and cannot be deleted",
        [TranslationCommon.ACTION_CANNOT_BE_COMPLETED]: "Action cannot be completed",
        [TranslationKeys.MANAGE_USERS]: "Manage Users",
        [TranslationKeys.MODIFY_NAME]: "Modify Name",
        [TranslationKeys.ROLE_NAME]: "Role Name",
        [TranslationKeys.EDIT_ROLE]: "Edit Role",
        [TranslationCommon.SELECT_OPTION]: "Select an option",
        [TranslationCommon.NO_MORE_OPTIONS]: "No more options",
        [TranslationCommon.NOT_FOUND]: "Sorry, the page you are looking for does not exist or has been moved.",
        [TranslationCommon.SOMETHING_WENT_WRONG]: "Sorry, an unexpected error occurred.",
        [TranslationKeys.DASHBOARD_PLACEHOLDER_TEXT]: "Click on a task to view its details",
        [TranslationCommon.VIEW]: "View",
        [TranslationCommon.MODIFY]: "Modify",
        [TranslationCommon.TOTAL_ACCESS]: "Total Access",
        [TranslationKeys.EDIT_PROFILE_PICTURE]: "Edit Profile Picture",
        [TranslationKeys.EDIT_LOGO]: "Edit Logo",
        [TranslationKeys.IMPERSONATE]: "Impersonate",
        [TranslationKeys.IMPERSONATING_TO]: "Impersonating to",
        [TranslationKeys.PERSONALIZATION]: "Personalization",
        [TranslationKeys.PERSONALIZED]: "Personalized",
        [TranslationKeys.EDITING_SUBDEPARTMENT_MODAL_TEXT]: "Editing Subteams {0} of Team {1}",
        [TranslationKeys.NO_USERS_TEXT]: "There are no available users to be added",
        [TranslationKeys.ALL_ROLES_ADDED]: "All available roles have been added",
        [TranslationErrors.INVALID_CHARACTERS]: "The username cannot contain spaces or special characters",
        [TranslationKeys.DUPLICATE_USERNAME]: "The username is already registered",
        [TranslationKeys.CSV_DOWNLOAD]: "Download .csv",
        [TranslationKeys.CSV_DOWNLOAD_ROWS]: "Number of records you want to export",
        [TranslationKeys.CSV_EXPORT]: "Export csv",
        [TranslationKeys.ROWS]: "Records",
        [TranslationKeys.ROW_PAGES]: "Pages",
        [TranslationKeys.ROW_FROM_THE_PAGE]: "From page",
        [TranslationKeys.EXPORT_ALL]: "Export All",
        [TranslationKeys.MAX_RECORDS]: "(maximum {0} records)",
        [TranslationKeys.FROM_CURRENT_PAGE]: "From current page",
        [TranslationKeys.ROW_AMOUNT]: "Number of records",
        [TranslationKeys.MAX_CSV_ROWS]: "{0} Max records",
        [TranslationKeys.DOWNLOAD]: "Download",
        [TranslationKeys.INSTANCE_NAME]: "Instance Name",
        [TranslationKeys.CONTACT]: "Contact",
        [TranslationKeys.MANAGE]: "Manage",
        [TranslationKeys.MAIN_CONTACT]: "Main Contact",
        [TranslationKeys.NO_GROUPS_TEXT]: "No {0} or Group of {1} has been created yet",
        [TranslationKeys.NO_GROUPS_TEXT_F]: "No {0} or Group of {1} has been created yet",
        [TranslationKeys.EMPTY_DEPARTMENT]: "No subteams have been created for this Team yet",
        [TranslationKeys.ENTER_USERNAME]: "Jperez",
        [TranslationCommon.STATUS]: "Status",
        [TranslationKeys.PRIMARY_ADMINISTRATOR]: "Primary Administrator",
        [TranslationKeys.CONFIRMATION]: "Confirmation",
        [TranslationKeys.INSERT_ROLE_NAME]: "Enter role name",
        [TranslationKeys.PLACEHOLDER_ROLE_NAME]: "Administrator",
        [TranslationKeys.TEMPORARY]: "Temporary",
        [TranslationKeys.REFRESHING_MODULES]: "Refreshing modules...",
        [TranslationKeys.REFRESH]: "Refresh",
        [TranslationKeys.REFRESH_COUNTDOWN]: "Modules will refresh in {0} seconds.",
        [TranslationKeys.INVALID_PHONE]: "Incorrect phone format",
        [TranslationKeys.ORDER_BY_STARS]: "Sort By:",
        [TranslationCommon.ORDER_BY_TABLE]: "Ordered By:",
        [TranslationCommon.ASCENDENT]: "Ascending",
        [TranslationCommon.DESCENDENT]: "Descending",
        [TranslationKeys.PRIVACY_POLICY]: "Privacy Policy",
        [TranslationKeys.PRIVACY_POLICY_ACCEPT]: "I have read and accept the",
        [TranslationKeys.WORKING_POSITION]: "QR Codes",
        [TranslationKeys.PRIVACY_POLICY_TEXT]:
            "We inform you that we have a privacy policy that protects your personal data. Your privacy is important to us, and we guarantee the confidentiality of the information you share with us.",
        [TranslationCommon.IMAGE_UPLOAD_FAILED]: "Unable to upload images",
        [TranslationCommon.IMAGE_UPLOAD_SUCCEED]: "Images uploaded correctly",

        /* ----------------WIZARD COMPANY START----------------- */
        [TranslationKeys.WIZARD_COMPANY_NAME]: "Name of the {0}",
        [TranslationKeys.WIZARD_COMPANY_NAME_F]: "Name of the {0}",
        [TranslationKeys.WIZARD_COMPANY_LOGO]: "Logo (PNG or JPG)",
        [TranslationKeys.WIZARD_COMPANY_BELONGS_TO]: "Group of {0}",
        [TranslationKeys.WIZARD_COMPANY_CIF]: "CIF",
        [TranslationKeys.WIZARD_COMPANY_FISCAL_NAME]: "Fiscal Name",
        [TranslationKeys.WIZARD_COMPANY_WHERE_IS]: "Address",
        [TranslationKeys.WIZARD_COMPANY_HOW_TO_CONTACT]: "How do we contact you?",
        [TranslationKeys.WIARD_COMPANY_INVENTORY_FREQ]: "What is the inventory frequency?",
        [TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE]:
            "Do you want to clone the structure of Teams from any existing {0}?",
        [TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE_F]:
            "Do you want to clone the structure of Teams from any existing {0}?",
        [TranslationKeys.WIZARD_COMPANY_SELECT_TO_INCLUDE]: "Select the ones you want to include.",
        [TranslationKeys.WIZARD_COMPANY_WICH_OF]: "Which of them?",
        [TranslationKeys.WIZARD_COMPANY_NO_SUBDEPARTMENTS]: "This Team does not have Subteams yet",
        [TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE_TASK]:
            "Do you also want to clone the tasks from the selected Teams and Subteams?",
        [TranslationKeys.WIZARD_COMPANY_IMPORT_USER_STRUCT]: "Do you want to import the user structure?",
        [TranslationKeys.WIZARD_EDIT_INTERNAL_ORG]: "Edit internal organization",
        [TranslationKeys.WIZARD_COMPANY_AT_LEAST_ONE]: "Select at least one Team with users.",
        [TranslationKeys.WIZARD_COMPANY_MARK_SUBDEPARTMENTS]: "Mark the Subteams to manage users",
        [TranslationKeys.NO_SUBDEPARTMENT_MESSAGE]: "This Team does not have Subteams.",
        [TranslationKeys.WIZARD_COMPANY_SELECT_COMPANY]: "Please select a {0}",
        [TranslationKeys.WIZARD_COMPANY_SELECT_COMPANY_F]: "Please select a {0}",
        [TranslationKeys.WIZARD_COMPANY_SELECT_ONE_DEPARTMENT]: "Select at least one Team.",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_NAME]: "Name of the {0}",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_NAME_F]: "Name of the {0}",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_CIF]: "A12345678",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_FISCAL_NAME]: "4Link S.L.",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_CITY]: "City",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_DIRECTION]: "Address",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_EMAIL]: "email@4link.app",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_PHONE]: "123456789",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_POSTAL_CODE]: "Zip Code",
        [TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_LOGO]: "Attach Logo",

        [TranslationErrors.WIZARD_COMPANY_ERROR_NAME]: "The name of the {0} must not be empty!",
        [TranslationErrors.WIZARD_COMPANY_ERROR_NAME_F]: "The name of the {0} must not be empty!",
        [TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_COMPANY]: "Select an Option",
        [TranslationErrors.WIZARD_COMPANY_ERROR_SELECTED_COMPANY]: "Choose the {0} you wish to clone",
        [TranslationErrors.WIZARD_COMPANY_ERROR_SELECTED_COMPANY_F]: "Choose the {0} you wish to clone",
        [TranslationErrors.WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT]: "You must select at least one Team!",
        [TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_USER]: "Select an Option",

        [TranslationKeys.WIZARD_COMPANY_CHECK_LAST_STEP]:
            "Check the data of the new {0} {2}, which belongs to {3} group.",
        [TranslationKeys.WIZARD_COMPANY_CHECK_LAST_STEP_F]:
            "Check the data of the new {0} {2}, which belongs to {3} group.",
        [TranslationKeys.WIZARD_COMPANY_CLONE_LAST_STEP]: "The following equipment structure will be cloned:",

        /* ----------------WIZARD COMPANY END----------------- */

        /* ----------------WIZARD INSTANCE START----------------- */

        [TranslationKeys.WIZARD_INSTANCE_NAME]: "What is the name of the new instance?",
        [TranslationKeys.WIZARD_INSTANCE_PLACEHOLDER]: "Enter the name of the instance",
        [TranslationKeys.WIZARD_INSTANCE_MAIN_CONTACT]: "Who is the main contact?",
        [TranslationKeys.WIZARD_INSTANCE_STEP_TWO_TITLE]:
            "What modules and functionalities do you want to enable for this instance?",
        [TranslationKeys.WIZARD_INSTANCE_PRIMARY_ADMIN]: "Who is the primary administrator of this account?",
        [TranslationKeys.WIZARD_INSTANCE_EMAIL]: "What is their email?",
        [TranslationKeys.WIZARD_INSTANCE_DOCUMENT]: "What is their document number?",
        [TranslationKeys.WIZARD_INSTANCE_CONFIRMATION]: "Do you confirm that you want to create the instance?",

        /* ----------------WIZARD INSTANCE END----------------- */

        /* ----------------WIZARD ROLE START----------------- */

        [TranslationKeys.WIZARD_ROLE_NAME]: "Name of the new role",
        [TranslationKeys.WIZARD_ROLE_PLACEHOLDER_NAME]: "Name of the new role",
        [TranslationKeys.WIZARD_ROLE_COPY_PERMISSIONS]: "Do you want to copy permissions from an existing role?",
        [TranslationKeys.WIZARD_ROLE_SELECT_TO_CLONE]: "Which existing role do you want to use as a base?",
        [TranslationKeys.WIZARD_ROLE_SELECT_ROLE]: "Select a role to copy its permissions",
        [TranslationKeys.WIZARD_ROLE_SELECT_PERSMISION]: "You must choose at least one permission.",
        [TranslationKeys.WIZARD_ROLE_CONFIRM_CREATION]: "Review and confirm the permissions before creating the role",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_USER_SELECT]:
            "To which user do you want to grant temporary permissions?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_DATE_PICKER]: "From which day to which day?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_CONFIRM]:
            "Do you confirm that you want to grant temporary permissions to {0} from {1} to {2}?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_SELECT_DATES]: "From which day to which day?",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_USER]: "User",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_PLACEHOLDER_USER]: "USer",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_ACTIVE_PERMISIONS]: "The following permissions will be activated",
        [TranslationKeys.WIZARD_ROLE_ADD_PATENT]: "You must select at least one option",
        [TranslationKeys.WIZARD_TEMPORARY_ROLE_FOR_USER]: "for the User",
        [TranslationKeys.WIZARD_ROLES_FAILED_SAVE]: "Role could not be created",
        /* ----------------WIZARD ROLE END----------------- */

        /* ----------------WIZARD ROLE START----------------- */
        [TranslationKeys.WIZARD_COMPANY_TITLE]: "Where will you work today?",
        [TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR]: "Which {0} do you want to supervise?",

        [TranslationKeys.SELECT_DEPARTMENT_TITLE]: "Which Team?",
        [TranslationKeys.SELECT_SUBDEPARTMENT_TITLE]: "Which Subteams?",
        [TranslationKeys.CHOOSE_DEPARTMENT]: "- Select Team -",
        [TranslationKeys.CHOOSE_SUBDEPARTMENT]: "- Select Subteams -",
        /* ----------------WIZARD ROLE END----------------- */

        // USERS
        [TranslationKeys.CANNOT_DELETE_SUPERVISOR_STATE]: "You could not be removed as a supervisor",
        [TranslationKeys.CANNOT_ADD_SUPERVISOR_STATE]: "You could not be added as a supervisor",
        [TranslationKeys.ACTIVE_TAB]: "Active",
        [TranslationKeys.INACTIVE_TAB]: "Inactive",
        [TranslationKeys.USER_GRID_INACTIVE_WITH_DATE_TAB]: "Inactive with date",
        [TranslationErrors.INVALID_LANGUAGE]: "Invalid Language",

        // DASHBOARD TASK KEYS
        [TranslationKeys.MY_TASKS]: "My Tasks",
        [TranslationKeys.SHARED_TASKS]: "Shared",
        [TranslationKeys.MY_DEPARTMENTS]: "My Teams",
        [TranslationKeys.PENDING]: "Pending",
        [TranslationKeys.SEE_ALL]: "See All",
        [TranslationKeys.TASKS_DONE_COUNTERS]: "Completed",
        [TranslationModals.SUCCESS_EDIT_TITLE_TOAST]: "Data saved successfully",
        [TranslationModals.SUCCESS_EDIT_SUBTITLE_TOAST]: "Tasks from now on have been updated successfully",
        [TranslationKeys.EDIT_TASK]: "Edit Task",
        [TranslationKeys.DUPLICATE_TASK]: "Duplicate Task",
        [TranslationKeys.DELETE_TASK]: "Delete Task",
        [TranslationKeys.EDIT_REGISTER]: "Edit Register",
        [TranslationKeys.DUPLICATE_REGISTER]: "Duplicate Register",
        [TranslationKeys.DELETE_REGISTER]: "Delete Register",
        [TranslationKeys.NEW_REGISTER]: "New Register",
        [TranslationKeys.SEVEN_DAYS]: "7 days",
        [TranslationKeys.LAST_SEVEN_DAYS]: "Last 7 days",
        [TranslationKeys.LAST_THIRTY_DAYS]: "Last 30 days",
        [TranslationKeys.NEAR_END]: "Due Soon",
        [TranslationKeys.OUT_OF_TIME]: "Overdue",
        [TranslationKeys.NOT_FINISHED]: "Not Completed",
        [TranslationKeys.IN_TIME]: "Completed",
        [TranslationKeys.TIMED_OUT]: "Expired",
        [TranslationKeys.SHARED_TIMED_OUT]: "Shared Expired",
        [TranslationKeys.SHARED_TIMED_OUT_TASKS]: "Shared Expired Tasks",
        [TranslationKeys.TIME_PAST]: "{0} ago",
        [TranslationKeys.REQUEST_DATE]: "Requested Date",
        [TranslationKeys.DELIVERY_DATE]: "Delivery Date",
        [TranslationKeys.WELL_DONE_TASK]: "Well Done",
        [TranslationKeys.WORSE_DONE_TASK]: "Poorly Done",
        [TranslationKeys.PIC_ASSOCIATED_TASK]: "Photo Required",
        [TranslationKeys.REPORTED_DATA_TASK]: "Data Required",
        [TranslationKeys.APPLY_FILTER]: "Apply Filters",
        [TranslationKeys.TASK_FILTER_USER]: "Assigned User",
        [TranslationKeys.TASK_FILTER_DEPARTMENT]: "Teams",
        [TranslationKeys.TASK_FILTER_SUBDEPARTMENT]: "Subteams",
        [TranslationKeys.TASK_FILTER_WORKING_POSITION]: "QR",
        [TranslationKeys.TASK_FILTER_DATA]: "Data",
        [TranslationKeys.TASK_FILTER_PLAN]: "Critical Task",
        [TranslationKeys.TASK_FILTER_STATUS]: "Status",
        [TranslationKeys.TASK_FILTER_EVALUATION]: "Rating",
        [TranslationKeys.MY_TASKS_FILTER]: "Personal Tasks Filter",
        [TranslationKeys.SHARED_TASKS_FILTER]: "Shared Tasks Filter",
        [TranslationKeys.HI_USER]: "Hi {0}!",
        [TranslationKeys.HI]: "Hi",
        [TranslationKeys.SHOW_ALL]: "Show All",
        [TranslationKeys.DASHBOARD_SUBTITLE]: "Filter the tasks you want to view",
        [TranslationKeys.DASHBOARD_STAR_RATING_SUPERVIR_TEXT]: "Average rating of your teams",
        [TranslationKeys.RESET_FILTERS]: "Reset Filters",
        [TranslationKeys.TERMINATE]: "Finish",
        [TranslationKeys.TASK_USER_DEP_LIST]: "My Tasks and Team",
        [TranslationKeys.COMPLETED_CHECKBOX]: "Completed",
        [TranslationKeys.CRITICAL_CHECKBOX]: "Critical Task",
        [TranslationKeys.CRITICAL_CHECKBOXES]: "Critical Tasks",
        [TranslationKeys.QR_CHECKBOX]: "QR Tasks",
        [TranslationKeys.TASK_FILTER_LAST_SEVEN_DAYS]: "Non-Completed Task List Filter",
        [TranslationKeys.OPEN_TASK_DETAIL]: "Open Detail",
        [TranslationKeys.TASKS_OF]: "Tasks of",
        [TranslationKeys.TASKS_WITH_COMENTS]: "Task with comments",
        [TranslationKeys.TASKS_OF_DEPARTMENT]: "Team's Tasks",
        [TranslationKeys.TASKS_OF_SUBDEPARTMENT]: "Subteam's Tasks",
        [TranslationKeys.DEPARTMENT_TASKS]: "Team/Subteam Tasks",
        [TranslationKeys.SCHEDULED_ALERT]: "Scheduled Alert",
        [TranslationKeys.SUCCESS_ALERT_SENT]: "Alert Sent Successfully",
        [TranslationKeys.SUCCESS_ALERT_INFO]: "You can review these alerts on your profile",
        [TranslationKeys.SUCCESS_ALERT_INFO_SUPERVISOR]: "You can review these alerts on the alert scheduling screen",
        [TranslationKeys.FAILED_ALERT_SENT]: "There was an Error",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_TITLE]: "Are you sure you want to restart the task?",
        [TranslationKeys.TASK_DETAIL_TEMPERATURE_TITLE]: "Temperatures",
        [TranslationKeys.TASK_DETAIL_DATA_TO_REPORT_TITLE]: "Data logging",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_DESC]:
            "The task will return to its initial state, uploaded photos will be deleted, and if the expiration time has passed, it will be marked as overdue.",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_OPEN]: "Yes, restart the task",
        [TranslationModals.REOPENING_TASK_CONFIRM_TEXT]: "Restarting...",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK_BTN_CLOSE]: "No, leave it as it is",
        [TranslationKeys.TASK_DETAIL_REOPEN_TASK]: "Reset Task",

        [TranslationKeys.TASK_FIELDS_INCOMPLETE]: "There are still fields to complete!",
        [TranslationKeys.TASK_IS_BEING_COMPLETED]: "Completing Task...",
        [TranslationKeys.TASK_COMPLETED_SUCCESSFULLY]: "Task completed successfully!",
        [TranslationKeys.TASK_DETAIL_PHOTO_EXAMPLE]: "Photo Example",
        [TranslationKeys.TASK_DETAIL_PHOTO_GALERY]: "Gallery Photo",
        [TranslationKeys.TASK_DETAIL_PHOTO_INSTANT]: "Photo taken on the spot",
        [TranslationKeys.TASK_DETAIL_CHECKLIST]: "Checklist",
        [TranslationKeys.TASK_ACTIVE_BETWEEN]: "Active from {1}h to {2}h",
        [TranslationKeys.TASK_DUE]: "Due at",
        [TranslationKeys.TASK_TIMED_OUT]: "Overdue at",
        [TranslationKeys.TASK_DONE]: "Completed",
        [TranslationKeys.DRAG_FILE_UPLOAD_PHOTO]: "Upload Photo Example",
        [TranslationKeys.UPLOAD_PHOTO]: "Upload Photo",
        [TranslationKeys.NO_DEPARTMENTS_FOR_YOUR_SEARCH]: "There are no departments for your search.",
        [TranslationKeys.NO_USERS_FOR_YOUR_SEARCH]: "There are no users for your search.",
        [TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH]: "There are no Tasks for your search.",

        // DASHBOARD TASKS PLACEHOLDERS LIST

        [TranslationKeys.DASHBOARD_ALERT_PLACEHOLDER]: "At this moment, there are no messages to read.",
        [TranslationKeys.DASHBOARD_FEEDBACK_PLACEHOLDER]: "At this moment, there are no tasks to display.",

        // REVIEW TASKS

        [TranslationKeys.PENDING_TASKS_1]: "Pending ",
        [TranslationKeys.PENDING_TASKS_2]: "tasks",
        [TranslationKeys.IS_CRITICAL]: "Critical",
        [TranslationKeys.IS_NOT_CRITICAL]: "Non-critical",
        [TranslationKeys.TOTAL_TASKS]: "Total tasks",
        [TranslationKeys.FEEDBACK]: "Feedback",
        [TranslationKeys.REVIEW_WITH_PHOTO]: "With photo",
        [TranslationKeys.REVIEW_WITHOUT_PHOTO]: "Without photo",
        [TranslationKeys.REVIEW_TIMED_OUT]: "Timed out",
        [TranslationKeys.REVIEW_COMPLETED]: "Completed",
        [TranslationKeys.WORKERS]: "Workers",
        [TranslationKeys.TEAMS]: "Teams",

        // FEEDBACK PAGE

        [TranslationErrors.FEEDBACK_COUNTERS_ERROR]: "There was an error with the feedback counters",

        // TASK PILL KEYS

        [TranslationKeys.START_DATE]: "Start Date: ",
        [TranslationKeys.END_DATE]: "End Date: ",

        // USER PROFILE
        [TranslationKeys.MY_PROFILE]: "My Profile",
        [TranslationTitles.GENERAL_INFO_TITLE]: "General Information",
        [TranslationTitles.COMPANIES_AND_DEPARTMENTS_TITLE]: "{0} and Teams",
        [TranslationErrors.INVALID_DAY_CODE]: "The Day is Invalid",

        // COMPANY
        [TranslationKeys.DELETE_COMPANY_TITLE]: "Delete",
        [TranslationModals.DELETE_TASK_TITLE]: "Delete Task",
        [TranslationModals.DELETE_TASK_DESCRIPTION]:
            "The following Tasks are to be deleted. This action is irreversible.",
        [TranslationModals.DELETE_REGISTRY_DESCRIPTION]:
            "The following Registries are to be deleted. This action is irreversible.",

        [TranslationTitles.GROUP_COMPANY_TITLE]: "Group of {0}",
        [TranslationKeys.NEW_GROUP_COMPANY]: "New Group",
        [TranslationKeys.NEW_COMPANY]: "New {0}",
        [TranslationKeys.NEW_COMPANY_F]: "New {0}",
        [CustomerTypeTranslations.COMPANIES]: "Working Centers",

        // DEPARTMENTS
        [TranslationTitles.DEPARTMENT_TITLE]: "Teams",
        [TranslationKeys.SELECT_DEPARTMENT]: "Select a Team / Subteams",
        [TranslationKeys.ALL_DEPTS_ADDED]: "All available departments have been added.",
        [TranslationKeys.ASSIGN_DEPARTMENT]: "Assign Team",
        [TranslationKeys.NEW_DEPARTMENT]: "New Team",

        // SUBDEPARTMENTS
        [TranslationKeys.SELECT_SUBDEPARTMENT]: "Select Subteam",
        [TranslationKeys.ASSIGN_SUBDEPARTMENT]: "Assign Subteams",
        [TranslationKeys.NO_DEPARTMENTS_ASSIGNED]: "No Assigned Teams",
        [TranslationKeys.DEPARTMENTS]: "Teams",
        [TranslationKeys.SUBDEPARTMENTS]: "Subteams",
        [TranslationErrors.SUBDEPARTMENT_NOT_EXIST]: "Subteam does not Exist",

        // USER
        [TranslationKeys.NEW_USER]: "New User",
        [TranslationKeys.USER_GENERAL_INFO_HELPER]: "Edit the user's general information.",
        [TranslationKeys.USER_ROLES_HELPER]: "Edit the user's roles.",

        [TranslationKeys.ENABLED_MODULES]: "Enabled Modules",
        [TranslationTitles.TEMPORARY_ROLES_TITLE]: "Temporary Permissions",

        /* -----------------TAR----------------- */
        [TranslationCommon.TODAY]: "Today",
        [TranslationCommon.YESTERDAY]: "Yesterday",

        // REASIGN TASKS
        [TranslationKeys.REASIGN_PLACEHOLDER]: "In the future, you will be able to reassign tasks here",

        // REVIEW TASKS
        [TranslationKeys.REVIEW_PLACEHOLDER]: "Select a user/Team/Subteams to review their tasks here",

        // TASK FEEDBACK
        [TranslationKeys.FEEDBACK_PLACEHOLDER]: "In the future, you will be able to provide feedback on tasks here",

        // CRUD TASKS
        [TranslationKeys.TASK_PAGE_PLACEHOLDER]:
            "Here you can view all tasks, as well as create, edit, and delete them",
        [TranslationKeys.NEW_TASK]: "New Task",

        // TASK HISTORY
        [TranslationKeys.HISTORY_PLACEHOLDER]: "Here you can view the history of all tasks to date",

        // CALENDAR
        [TranslationKeys.CALENDAR_PLACEHOLDER]: "In the future, you will be able to view the calendar here",

        // APPCC_PLAN
        [TranslationKeys.APPCC_PAGE_PLACEHOLDER]:
            "In the future, you will be able to view the list of Critical Tasks here",

        // INCIDENT HISTORY
        [TranslationKeys.INCIDENCE_HISTORY_PLACEHOLDER]:
            "In the future, you will be able to see the history of task incidents here",

        // TASKS FULFILLMENT
        [TranslationKeys.TASK_FULFILLMENT_PLACEHOLDER]: "In the future, you will be able to view task fulfillment here",

        // TASKS FULFILLMENT DETAIL
        [TranslationKeys.TASK_FULFILLMENT_DET_PLACEHOLDER]:
            "In the future, you will be able to view detailed task fulfillment here",

        // TASKS DETAIL PLACEHOLDER
        [TranslationKeys.TASK_DETAIL_PLACEHOLDER]: "Click on a task to view its details",
        [TranslationKeys.TASK_FEEDBACK_PLACEHOLDER]: "In this section, you can view feedback provided by supervisors.",
        [TranslationKeys.TASK_ALERT_PLACEHOLDER]: "In this section, you can view sent and received alerts.",

        /* ---------------TAR END--------------- */

        /* --------------ENTITIES--------------- */
        [TranslationKeys.USER]: "User",
        [TranslationKeys.ROLES]: "Roles",

        //USER
        [TranslationKeys.LABEL_USERNAME]: "Username",
        [TranslationKeys.LABEL_FIRSTNAME]: "First Name",
        [TranslationKeys.LABEL_LASTNAME]: "Last Name",
        [TranslationKeys.LABEL_USER_CODE]: "Worker Code",
        [TranslationKeys.LABEL_EMAIL]: "Email",
        [TranslationKeys.LABEL_JOB_DESCRIPTION]: "Job description",
        [TranslationKeys.PLACEHOLDER_USERNAME]: "Username",
        [TranslationKeys.PLACEHOLDER_FIRSTNAME]: "First Name",
        [TranslationKeys.PLACEHOLDER_LASTNAME]: "Last Name",
        [TranslationKeys.PLACEHOLDER_USER_CODE]: "Worker Code",
        [TranslationKeys.PLACEHOLDER_EMAIL]: "Email",
        [TranslationKeys.PLACEHOLDER_JOB_DESCRIPTION]: "Job description",
        [TranslationKeys.LABEL_DEPARTMENT_NAME]: "Team",
        [TranslationKeys.LABEL_INACTIVE_SINCE]: "Inactive since",
        [TranslationKeys.DEPARTMENT_NAME]: "Departments Name",
        [TranslationKeys.PLACEHOLDER_DEPARTMENT_NAME]: "Departments Name",
        [TranslationKeys.LABEL_SUBDEPARTMENT_NAME]: "Subteam",
        [TranslationKeys.LABEL_ROLE]: "Role",
        [TranslationKeys.LABEL_INITIAL_VIEW]: "Initial View",
        [TranslationKeys.LABEL_ROLE_SELECTION]: "Select a Role",
        [TranslationKeys.LABEL_COMPANY_SELECTION]: "Select a {0}",
        [TranslationKeys.LABEL_COMPANY_SELECTION_F]: "Select a {0}",
        [TranslationKeys.LABEL_COMPANY_AUTOMATIC_ACTIVATION]: "Activation Date",
        [TranslationKeys.LABEL_SELECT]: "Select an Option",
        [TranslationKeys.LABEL_PASSWORD_TYPE]: "Password Type",
        [TranslationKeys.LABEL_PIN]: "PIN",
        [TranslationKeys.LABEL_PASSWORD]: "Password",
        [TranslationKeys.LABEL_PROFILE_PICTURE]: "Profile Picture",
        [TranslationKeys.LABEL_SUPERVISOR]: "Supervisor",
        [TranslationKeys.LABEL_NOTIFICATION_SCHEDULE]: "Notification Schedule",
        [TranslationKeys.LABEL_USER_TYPE]: "Select UserType",
        [TranslationKeys.NOTIFICATION_SCHEDULE]: "Schedule Alert",
        [TranslationKeys.USER_TYPE]: "User Type",
        [TranslationKeys.ALERT_USER_SELECT_PLACEHOLDER]: "Select one or more Users",
        [TranslationKeys.ALERT_DEP_SELECT_PLACEHOLDER]: "Select one or more Teams",

        [TranslationKeys.LABEL_MIN_NOTIF_HOUR]: "Start Time",
        [TranslationKeys.LABEL_MAX_NOTIF_HOUR]: "End Time",

        //COMPANY
        [TranslationKeys.LABEL_NAME]: "4Link",
        [TranslationKeys.LABEL_PHONE]: "Phone",
        [TranslationKeys.PLACEHOLDER_PHONE]: "+34 123 456 789",
        [TranslationKeys.LABEL_CUSTOMERINSTANCEID]: "Customer Instance ID",
        [TranslationKeys.LABEL_CITY]: "City",
        [TranslationKeys.LABEL_LEGAL_NAME]: "Legal Name",
        [TranslationKeys.PLACEHOLDER_LEGAL_NAME]: "4Link SL.",
        [TranslationKeys.LABEL_ADDRESS]: "Address",
        [TranslationKeys.PLACEHOLDER_ADDRESS]: "Pol. Ind. Francolí",
        [TranslationKeys.PLACEHOLDER_CITY]: "Tarragona",
        [TranslationKeys.LABEL_ZIP_CODE]: "Zip Code",
        [TranslationKeys.PLACEHOLDER_ZIP_CODE]: "43006",
        [TranslationKeys.LABEL_LOGO]: "Logo",

        //ROLE
        [TranslationKeys.TITLE_PERMISSIONS]: "Permissions",
        [TranslationKeys.TITLE_BASE_ROLE]: "Base Role",
        [TranslationKeys.TITLE_FROM]: "From",
        [TranslationKeys.TITLE_TO]: "To",
        [TranslationKeys.TITLE_ACTIVE]: "Active",
        [TranslationKeys.TITLE_INACTIVE]: "Inactive",
        [TranslationKeys.INACTIVE_SINCE]: "Inactive since {1}",
        [TranslationKeys.ROLE_DETAIL_MODAL_SUCCESS_ADD_MESSAGE]: "The role has been added to the user",
        [TranslationKeys.ROLE_ADDED_SUCCESSFULLY]: "Role Edited Successfully",
        [TranslationKeys.EDIT_ROLE_FORM_NAME]: "Role",
        [TranslationKeys.EDIT_ROLE_FORM_PLACEHOLDER_NAME]: "Role",
        [TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_NAME]: "Role",
        [TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_PLACEHOLDER_NAME]: "Role",

        //MOVEMENT_LOG
        [TranslationKeys.TITLE_DATE]: "Date",
        [TranslationKeys.TITLE_TIME]: "Time",

        //InstanceConfig
        [TranslationKeys.TITLE]: "Title",

        [TranslationKeys.TITLE_LOGO_SMALL_BASE_64]: "Small Logo",

        /* ----------------TITLES START----------------- */
        [TranslationCommon.ACTIVES]: "Assets",
        [TranslationKeys.TASK_NAME_LABEL]: "Task Title",
        [TranslationKeys.CODE]: "Code",
        [TranslationKeys.CODES]: "Codes",

        /* ----------------TASK HISTORY START----------------- */
        [TranslationKeys.NO_TASK_TO_SHOW]: "There are no tasks to show",

        /* ----------------TASK WIZARD START----------------- */
        [TranslationKeys.INVALID_TIME_FORMAT]: "Invalid time format",

        [TranslationKeys.INVALID_HOURS_SAME_HOURS]: "Invalid hours, they must be different",
        [TranslationKeys.MINIMUM_VALUE]: "The minimum value is {1}",

        [TranslationKeys.SELECT_ONE_DAY]: "Select at least one day",

        [TranslationErrors.SELECT_AT_LEAST_ONE_OPTION]: "Select at least one option",

        [TranslationKeys.TASK_DESCRIPTION]: "Task description",
        [TranslationKeys.INPUT_OPTIONAL]: "Optional",
        [TranslationKeys.BELONGNS_APPCC_PLAN]: "Does it belong to an Critical Task?",
        [TranslationKeys.COMPANY_BELONGS]: "{0} it belongs to",
        [TranslationKeys.COMPANY_BELONGS_F]: "{0} it belongs to",
        [TranslationKeys.ASIGN_EMPLOYEE_DEPARTMENT]: "Assign employee or responsible {0}",
        [TranslationKeys.EMPLOYEE]: "Employee",
        [TranslationKeys.DEPARTMENT]: "Teams",
        [TranslationKeys.DATA_TO_REPORT]: "Is there any data to report?",
        [TranslationKeys.DATAS_TO_REPORT]: "Data to report",
        [TranslationKeys.ADD_DATA_TO_REPORT]: "Add data to report",
        [TranslationKeys.ADD_ITEM_TO_CHECKLIST]: "Add Checklist Item",
        [TranslationKeys.ADD_OPTION]: "Add Option",
        [TranslationKeys.TASK_END_LABEL]: "On",
        [TranslationKeys.PHOTOGRAPHIC_REGISTER]: "Photographic register required?",
        [TranslationKeys.CRITICAL_TASK]: "Is it a Critical Task?",
        [TranslationKeys.EXAMPLE_PHOTO]: "Do you want to add an example photo?",
        [TranslationKeys.PLACEHOLDER_TASK_TITLE]: "Register freezer temperature",
        [TranslationKeys.PLACEHOLDER_TASK_DESCRIPTION]: "Task description",
        [TranslationKeys.PLACEHOLDER_REPORT_DATA]: "Report",
        [TranslationKeys.PLACEHOLDER_CHECKLIST]: "Write your subtask here",
        [TranslationKeys.PLACEHOLDER_WIZARD_TASK_START]: "Select a Date",
        [TranslationKeys.TASK_START]: "Activation Task Day",
        [TranslationKeys.TASK_START_HOURS]: "At",
        [TranslationKeys.TASK_START_HOUR]: "At",
        [TranslationKeys.TASK_START_HOUR_MOBILE]: "Until",
        [TranslationKeys.TASK_ENDS_HOUR_MOBILE]: "Until",
        [TranslationModals.FAILED_SAVE_TASK_TITLE]: "Error saving the task",
        [TranslationModals.FAILED_SAVE_TASK_DESCRIPTION]: "Please check all required fields and try again.",
        [TranslationKeys.TASK_OUT_OF_TIME_HOUR_MOBILE]: "Completed",
        [TranslationKeys.TASK_COMPLETED_HOUR_MOBILE]: "Completed",
        [TranslationKeys.TASK_TIMED_OUT_HOUR_MOBILE]: "Timed out",
        [TranslationKeys.TASKINSTANCE_COMPLETED_OUT_OF_TIME_TEXT]: "Completed:",

        [TranslationKeys.TASKINSTANCE_LIMIT_DATE]: "Limit Date:",
        [TranslationKeys.TASKINSTANCE_START_HOUR]: "Start Hour:",

        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_NUMBER]: "Enter the Temperature (ºC)",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_TEXT]: "Explain...",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT]: "Choose an Option",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_OPTION]: "Option",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE]: "Specify Date of Registration",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_HOUR]: "Specify Registration Time",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_AND_HOUR]: "Indicate Date and Time of Registration",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE]: "Specify Date Range",
        [TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD]: "Add New Dynamic Field",

        [TranslationKeys.TASK_COMPLETED]: "Completed",
        [TranslationKeys.TASK_COMPLETED_OUT_OF_TIME]: "Out of time",
        [TranslationKeys.TASK_PENDING]: "Pending",
        [TranslationKeys.TASK_DISABLED]: "Disabled",
        [TranslationKeys.TASK_NEAR_TO_END]: "Near To End",
        [TranslationKeys.TASK_NOT_COMPLETED]: "Expired",

        [TranslationKeys.RECURRENT_TASK_CHECKBOX]: "Is it a recurring task?",
        [TranslationKeys.EVERY_DAY]: "Every day",
        [TranslationKeys.EVERY_WEEK_DAY]: "Every week on {1}",
        [TranslationKeys.EVERY_MONTH_DAY]: "Every month on the {1}",
        [TranslationKeys.EVERY_YEAR_DAY_MONTH]: "Annually on {1} of {2}",
        [TranslationKeys.EVERY_LABORAL_DAYS]: "Every workday (Monday to Friday)",
        [TranslationKeys.WEEK]: "Week",
        [TranslationKeys.WEEKS]: "Weeks",
        [TranslationKeys.EVERY_WEEK]: "Every week",
        [TranslationKeys.MONTH]: "Month",
        [TranslationKeys.MONTHS]: "Months",
        [TranslationKeys.YEAR]: "Year",
        [TranslationKeys.YEARS]: "Years",
        [TranslationKeys.SPECIFIC_DAY]: "On the {1}",
        [TranslationKeys.FIRST_DAY_WEEK]: "On the first {1}",
        [TranslationKeys.SECOND_DAY_WEEK]: "On the second {1}",
        [TranslationKeys.THIRD_DAY_WEEK]: "On the third {1}",
        [TranslationKeys.FOURTH_DAY_WEEK]: "On the fourth {1}",
        [TranslationKeys.FIFTH_DAY_WEEK]: "On the fifth {1}",

        [TranslationKeys.EDIT_DEPARTMENTS]: "Edit Teams",
        [TranslationKeys.EDIT_SUBDEPARTMENTS]: "Edit subteams",

        [TranslationKeys.AFTER]: "After",
        [TranslationKeys.NEVER]: "Never",
        [TranslationKeys.REPETITIONS]: "Repetitions",
        [TranslationKeys.REPETITION]: "repetition",
        [TranslationKeys.REPEAT_EVERY]: "Repeat every",

        [TranslationKeys.WIZARD_HEADER_TASK_RECORD_INFO]: "Record Information",
        [TranslationKeys.WIZARD_HEADER_TASK_DATE]: "Date",
        [TranslationKeys.WIZARD_HEADER_TASK_FINISH]: "Finish",
        [TranslationKeys.WIZARD_HEADER_TASK_REFERENCE]: "Reference",

        [TranslationKeys.WIZARD_SELECT_CUSTOM]: "Customize Periodicity",
        [TranslationKeys.CREATING_TASKS]: "Creating tasks...",
        [TranslationKeys.FAILED_GENERATING_TASKS]: "Failed to generate tasks",
        [TranslationKeys.TASKS_CREATED]: "Tasks created successfully",
        [TranslationKeys.ACTION_NO_REVERSIBLE]: "This action is not reversible, and all data will be lost.",

        [TranslationKeys.DATA_TO_REPORT_NO]: "None",
        [TranslationKeys.DATA_TO_REPORT_EXTRA_DATA]: "Extra data",
        [TranslationKeys.DATA_TO_REPORT_MANUAL]: "Manual Data to Report",
        [TranslationKeys.DATA_TO_REPORT_ASSET]: "Link Assets",
        [TranslationKeys.DATA_TO_REPORT_RANGE_REGISTER]: "Link Registration with Range",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS]: "Dynamic Fields",
        [TranslationKeys.CHECKLIST]: "Subtasks",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATE]: "Dynamic Date Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATERANGE]: "Dynamic Date Range Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATETIME]: "Dynamic Date and Time Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_DROPDOWN]: "Dynamic Dropdown Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_NUMBER]: "Dynamic Number Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_TEXT]: "Dynamic Text Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_TIME]: "Dynamic Time Field",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_REQUIRED]: "Required",
        [TranslationKeys.DATA_TO_REPORT_DYNAMIC_OPTION_TITLE]: "Options",

        //---------------------------------------------------------------------------------------------------------------------------------

        [TranslationKeys.WEEK_MONDAY]: "Monday",
        [TranslationKeys.WEEK_TUESDAY]: "Tuesday",
        [TranslationKeys.WEEK_WEDNESDAY]: "Wednesday",
        [TranslationKeys.WEEK_THURSDAY]: "Thursday",
        [TranslationKeys.WEEK_FRIDAY]: "Friday",
        [TranslationKeys.WEEK_SATURDAY]: "Saturday",
        [TranslationKeys.WEEK_SUNDAY]: "Sunday",

        [TranslationKeys.MONTH_NAME_JANUARY]: "January",
        [TranslationKeys.MONTH_NAME_FEBRAURY]: "February",
        [TranslationKeys.MONTH_NAME_MARCH]: "March",
        [TranslationKeys.MONTH_NAME_APRIL]: "April",
        [TranslationKeys.MONTH_NAME_MAY]: "May",
        [TranslationKeys.MONTH_NAME_JUNE]: "June",
        [TranslationKeys.MONTH_NAME_JULY]: "July",
        [TranslationKeys.MONTH_NAME_AUGUST]: "August",
        [TranslationKeys.MONTH_NAME_SEPTEMBER]: "September",
        [TranslationKeys.MONTH_NAME_OCTOBER]: "October",
        [TranslationKeys.MONTH_NAME_NOVEMBER]: "November",
        [TranslationKeys.MONTH_NAME_DECEMBER]: "December",

        [TranslationKeys.MONTH_NAME_JANUARY_SHORT]: "Jan",
        [TranslationKeys.MONTH_NAME_FEBRAURY_SHORT]: "Feb",
        [TranslationKeys.MONTH_NAME_MARCH_SHORT]: "Mar",
        [TranslationKeys.MONTH_NAME_APRIL_SHORT]: "Apr",
        [TranslationKeys.MONTH_NAME_MAY_SHORT]: "May",
        [TranslationKeys.MONTH_NAME_JUNE_SHORT]: "Jun",
        [TranslationKeys.MONTH_NAME_JULY_SHORT]: "Jul",
        [TranslationKeys.MONTH_NAME_SEPTEMBER_SHORT]: "Sep",
        [TranslationKeys.MONTH_NAME_OCTOBER_SHORT]: "Oct",
        [TranslationKeys.MONTH_NAME_NOVEMBER_SHORT]: "Nov",
        [TranslationKeys.MONTH_NAME_DECEMBER_SHORT]: "Dec",

        //---------------------------------------------------------------------------------------------------------------------------------

        [TranslationKeys.COMPANY_RESPONSIBLE_TITLE]: "{0} and Responsible",
        [TranslationKeys.DATA_REPORT_TITLE]: "Data to Report",
        [TranslationKeys.CHECKLIST_TITLE]: "Checklist of Subtasks",
        [TranslationKeys.DYNAMIC_FIELDS_TITLE]: "Dynamic Fields",
        [TranslationKeys.DATE_START_REPETITIONS_TITLE]: "Start Date and Repetitions",
        [TranslationKeys.VERIFY_INFORMATION_TITLE]: "Verify Information",

        [TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER]: "M",
        [TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER]: "T",
        [TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER]: "W",
        [TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER]: "T",
        [TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER]: "F",
        [TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER]: "S",
        [TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER]: "S",

        [TranslationKeys.PLAN_APPCC_LABEL]: "Critical Task",
        [TranslationKeys.COMPANY_TASK]: "{0} Task",
        [TranslationKeys.TASK_ASIGNED_TO]: "Task Assigned to",
        [TranslationKeys.TASK_ASIGNED_TO_USER_IN_DEPARTMENT]: "From Team",
        [TranslationKeys.TASK_ASIGNED_TO_DEPARTMENT]: "Task Assigned to Team",
        [TranslationKeys.TASK_ASIGNED_TO_SUBDEPARTMENT]: "Task Assigned to Subteam",
        [TranslationKeys.TASK_ASIGNED_TO_WORKING_POSITION]: "Task Assigned to QR Code",
        [TranslationKeys.TASK_REASIGNED_TO]: "Task Reassigned to",
        [TranslationKeys.CANCEL_REASIGNATION]: "Cancel Reassignment",
        [TranslationErrors.ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED]: "Reassignment cancellation failed",

        [TranslationKeys.IMAGE_FOR_REPORT]: "Image is required for the report",
        [TranslationKeys.IS_A_CRITICAL_TASK]: "Is this an APPC task?",
        [TranslationKeys.HOURS_OUT_OF_RANGE_TITLE]: "2-day time range alert!",
        [TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION]:
            "You have scheduled a task that spans the change of day. It will automatically adjust to start one day and end the next.",

        [TranslationKeys.FULL_DATE]: "{1} {2}, {3}", // e.g., August 10, 2022
        [TranslationKeys.FINISH_IN]: "Finish in",

        [TranslationKeys.TASK_REPEAT_EVERY_X_WEEKS]: "The task repeats every {1} weeks on",
        [TranslationKeys.TASK_REPEAT_EVERY_X_WEEK]: "The task repeats every week on",
        [TranslationKeys.TASK_TEMPORALITY]: "Task Temporality",
        [TranslationKeys.IN_THE_HOUSR]: "In the hours",
        [TranslationKeys.TASK_REPEAT]: "Task Repeats",
        [TranslationKeys.TASK_REPEAT_EVERY]: "Task repeats every",
        [TranslationErrors.DEPARTMENT_IS_REQUIRED]: "Select Team",
        [TranslationErrors.MAX_DEPARTMENTS_ALLOWED_ERROR]: "Team limit reached",
        [TranslationErrors.MAX_DEPARTMENTS_ALLOWED_ERROR_DESCRIPTION]: "No more than 5 teams can be created per {0}.",
        [TranslationKeys.MUST_SELECT_USER_OR_QR_TITLE]: "The selected {0} works with QR Codes.",
        [TranslationKeys.MUST_SELECT_USER_OR_QR_TITLE_F]: "The selected {0} works with QR Codes.",
        [TranslationKeys.MUST_SELECT_USER_OR_QR_BODY]: " It is mandatory to assign the task to a User or QR Code.",

        /* ----------------TASK WIZARD END----------------- */

        /* ----------------EDIT_TASK START----------------- */
        [TranslationKeys.TASK_DATA]: "Details",
        [TranslationKeys.DATA_TO_REPORT_ATTACHED]: "Data to report and attached",
        [TranslationKeys.TASK_ENDS]: "The task ends",
        [TranslationKeys.DATA_TO_REPORT_EDIT]: "Edit Data to Report",
        [TranslationKeys.UPLOAD_TYPE]: "PNG or JPG (max 5)",
        [TranslationKeys.CLICK_TO_UPLOAD]: "Click or Drop to upload a photo",
        [TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY]: "Checklist Item cannot be empty",
        [TranslationErrors.EMPTY_TASK_ID_LIST]: "The Task IDs List cannot be empty",

        /* ----------------EDIT_TASK END----------------- */

        /* ----------------TASK_PAGE START----------------- */
        [TranslationKeys.DONT_HAVE_PLAN_ASSIGNED]: "Does not have a plan assigned",
        [TranslationKeys.RESPONSABLE]: "Responsible",
        [TranslationKeys.DONT_HAVE_USER_ASSIGNED]: "Does not have a user assigned",
        [TranslationKeys.DETAIL]: "Detail",
        [TranslationKeys.FREQUENCY]: "Frequency",
        [TranslationKeys.WITH_RECURRENCE]: "With recurrence",
        [TranslationKeys.WITH_NO_RECURRENCE]: "Without recurrence",
        [TranslationKeys.COMPILANCE]: "Compliance",
        [TranslationKeys.DUPLICATE]: "Duplicate",
        [TranslationKeys.RECURRENT]: "Recurrent",
        [TranslationKeys.HOURS]: "Hours",
        [TranslationKeys.ACTIONS]: "Actions",
        [TranslationKeys.UNIQUE]: "Unique",
        [TranslationKeys.DAILY]: "Daily",
        [TranslationKeys.WEEKLY]: "Weekly",
        [TranslationKeys.MONTHLY]: "Monthly",
        [TranslationKeys.ANNUALY]: "Annually",
        [TranslationKeys.TASK_PAGE_REGISTRY_TITLE]: "Registry Manager",
        [TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE]: "Registries",
        [TranslationKeys.TASK_PAGE_REGISTRY_SUBTITLE_SINGULAR]: "Registry",
        [TranslationKeys.TASK_PAGE_TASK_SUBTITLE]: "Task",
        [TranslationModals.REGENERATING_TASKS_TITLE]: "Regenerating Tasks...",
        [TranslationModals.GENERATING_TASKS_TITLE]: "Generating Tasks...",
        [TranslationModals.GENERATING_TASKS_DESCRIPTION]: "Changes are being applied to all tasks as of {0} {1}.",

        /* ----------------TASK_PAGE END----------------- */

        /* ----------------HITORY_TASK_PAGE START----------------- */
        [TranslationKeys.DEADLINE]: "Deadline",
        [TranslationKeys.TASK_HISTORY_TITLE]: "Task History",
        [TranslationKeys.MOB_TASK_HISTORY_TITLE]: "Historical Record",
        [TranslationKeys.TASK_HISTORY_COMPLETED]: "Completed",
        [TranslationKeys.TASK_HISTORY_ASIGNED]: "Start",
        [TranslationKeys.TASK_HISTORY_FINISHED_BY]: "Completion",
        [TranslationKeys.TASK_HISTORY_STATUS]: "Status",
        [TranslationKeys.TASK_HISTORY_VALUATION]: "Valuation",
        [TranslationKeys.TASK_NO_PLAN]: "No plan",
        [TranslationKeys.TASK_STATUS_PENDING]: "Pending",
        [TranslationKeys.TASK_STATUS_COMPLETED]: "Completed",
        [TranslationKeys.TASK_STATUS_OUT_OF_TIME]: "Out of time",
        [TranslationKeys.TASK_STATUS_UNKNOWN]: "Unknown",
        [TranslationKeys.ALL_TIME_DATES]: "All Dates",

        /* ----------------HITORY_TASK_PAGE END----------------- */

        /* ----------------REVIEW_TASK_PAGE START----------------- */
        [TranslationKeys.NO_TASKS_PERFOMED]: "No tasks performed",
        [TranslationKeys.NO_TASKS_TO_PERFOM]: "No tasks to perform",
        [TranslationKeys.MARK_ALL_AS_VIEWED]: "Mark all as viewed",

        /* ----------------REVIEW_TASK_PAGE END----------------- */

        /* ----------------NEW_NOTIFICATION START----------------- */
        [TranslationKeys.TO]: "To",
        [TranslationKeys.FROM]: "From",
        [TranslationKeys.SENT]: "Sent",
        [TranslationKeys.READED]: "Read",
        [TranslationKeys.NOT_READED]: "Not Readed",
        [TranslationKeys.RECEIVED]: "Received",
        [TranslationKeys.PROGRAMMED]: "Scheduled",
        [TranslationKeys.QR_PROGRAMMED]: "Scheduled With QR",
        [TranslationKeys.WRITE_HERE]: "Write here",
        [TranslationKeys.NEW_NOTIFICATION]: "New notification",
        [TranslationKeys.SEND_TO]: "Send to",
        [TranslationKeys.FILL_THIS_FIELD]: "You must fill in this field",
        [TranslationKeys.SCHEDULE_SEND]: "Schedule Send",
        [TranslationKeys.SCHEDULE_SEND_NEXT_DAY_MORNING]: " Next day at 8:00 am",
        [TranslationKeys.SCHEDULE_SEND_NEXT_DAY_AFTERNOON]: " Next day at 4:00 pm",
        [TranslationKeys.SCHEDULE_SEND_NEXT_MONDAY]: " Next Monday at 8:00 am",
        [TranslationKeys.FILES]: "Files",
        [TranslationKeys.FILE]: "File",
        [TranslationKeys.IMAGE]: "Image",
        [TranslationKeys.IMAGES]: "Images",
        [TranslationKeys.AUDIO]: "Audio",
        [TranslationKeys.AUDIOS]: "Audios",
        [TranslationKeys.VIDEO]: "Video",
        [TranslationKeys.VIDEOS]: "videos",
        [TranslationKeys.ALERT_TEXTAREA_PLACEHOLDER]: "Write or record your alert.",

        /* ----------------NEW_NOTIFICATION END----------------- */

        /* ----------------NOTIFICATIONS START----------------- */
        [TranslationKeys.NOTIFICATIONS]: "Notifications",
        [TranslationKeys.NOTIFICATION_DAYS_DESCRIPTION]: "Configure your preferences for receiving notifications.",
        [TranslationKeys.MARK_AS_VIEWED]: "Mark as viewed",
        [TranslationKeys.SYSTEM_NOTIFICATION]: "System",
        [TranslationKeys.RECEIVED_NOTIFICATION]: "Received",
        [TranslationKeys.NO_NOTIFICATION]: "No alerts to view",
        [TranslationKeys.NEW_ALERT]: "New Alert",
        [TranslationKeys.REVIEW_LATER]: "Review later",
        [TranslationKeys.MARK_AS_READED]: "Mark as read",
        [TranslationKeys.SINGLE_ALERT]: "Alert",

        /* ----------------NOTIFICATIONS END----------------- */

        /* ----------------VALIDATE_TIME START----------------- */
        [TranslationKeys.NO_TIME_SELECTED]: "No time selected",
        [TranslationKeys.TIME_EMPTY]: "Time is empty",
        [TranslationKeys.MAX_TIME_EMPTY]: "Max time is empty",
        [TranslationKeys.TIME_SMALL]: "Time must be smaller",
        [TranslationKeys.SAME_TIMES]: "The times are the same",
        [TranslationKeys.OVERLAP_TIME]: "Date ranges overlap",

        /* ----------------VALIDATE_TIME END----------------- */

        /* ----------------TIME START----------------- */
        [TranslationCommon.TIME_FROM]: "From",
        [TranslationCommon.TIME_TO]: "To",

        /* ----------------TIME END----------------- */

        /* ----------------ASSET_PAGE START----------------- */
        [TranslationKeys.TEMPERATURE]: "Temperature",
        [TranslationKeys.DESCRIPTION]: "Description",
        [TranslationKeys.ASSET_TYPE]: "Type",
        [TranslationKeys.ASSET_TYPE_COLUMN]: "Source",
        [TranslationKeys.ASSET_PAGE]: "Asset",
        [TranslationKeys.NEW_ASSET]: "New Asset",
        [TranslationKeys.NAME]: "Name",
        [TranslationKeys.ASSET_ID]: "Asset Id",
        [TranslationKeys.ASSET_MIN]: "Minimum Registration Accepted",
        [TranslationKeys.ASSET_MAX]: "Maximum Registration Accepted",
        [TranslationKeys.ASSET_LINK_TO_SENSOR]: "Link Sensor",
        [TranslationKeys.EDIT_ASSET]: "Edit Asset",
        [TranslationKeys.ASSET_NAME_PLACEHOLDER]: "Name",
        [TranslationKeys.ASSET_DESCRIPTION_PLACEHOLDER]: "Description",
        [TranslationKeys.ASSET_SELECT_OPTION]: "Select an Asset",
        [TranslationKeys.RANGE_REGISTER_SELECT_OPTION]: "Select a Registration with Range",
        [TranslationKeys.ASSET_DELETE_MODAL_TITLE]: "Are you sure you want to remove the asset?",
        [TranslationKeys.ASSET_DELETE_MODAL_SUBTITLE]: "Cannot be recovered once deleted",
        [TranslationKeys.SUCCESS_ACTIVE_ASSET]: "Successfully activated",
        [TranslationKeys.FAILED_ACTIVE_ASSET]: "Failed to activate",
        [TranslationKeys.SUCCESS_DEACTIVE_ASSET]: "Successfully disabled",
        [TranslationKeys.FAILED_DEACTIVE_ASSET]: "Failed to deactivate",
        [TranslationKeys.ASSET_MODAL_TITLE_ACTIVATE]: 'The asset "{0}" will be activated',
        [TranslationKeys.ASSET_MODAL_TITLE_DEACTIVATE]: 'The asset "{0}" will be deactivated',
        [TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE]:
            "When deactivating this Subteam, it will automatically disengage from all tasks. We recommend reviewing the tasks before deactivating it. This action is irreversible.",
        [TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED]:
            "This Asset is not linked to any task. It can be deactivated without affecting any existing task.",

        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_ASSETID_ASSET]: "Sensor Code",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_MIN_ASSET]: "Minimum Registration Accepted",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_MAX_ASSET]: "Maximum Registration Accepted",
        [TranslationKeys.ASSET_UNIT_OF_MEASURE]: "Unit of Measurement",
        [TranslationKeys.ASSET_LABEL]: "Title",
        [TranslationKeys.ASSET_PLACEHOLDER_LABEL]: "Título",
        [TranslationKeys.ASSET_OPTION_NUMERIC]: "Numeric",
        [TranslationKeys.ASSET_OPTION_TEXT]: "Text",
        [TranslationKeys.ASSET_NUMBER_LABEL]: "Which Data Has to Be Registered?",
        [TranslationKeys.ASSET_TEXT_LABEL]: "Which Data Has to Be Registered?",

        /* ----------------ASSET_PAGE END----------------- */

        /* ----------------ASSET_VALIDATIONS START----------------- */
        [TranslationErrors.ASSET_NAME_REQUIRED]: "Name is required",
        [TranslationErrors.ASSET_MIN_TEMP_REQUIRED]: "Minimum temperature is required",
        [TranslationErrors.ASSET_MAX_TEMP_REQUIRED]: "Maximum temperature is required",
        [TranslationErrors.ASSET_MIN_TEMP_BIGGER_THAN_MAX]: "Minimum temperature must be smaller than maximum",
        [TranslationErrors.ASSET_MIN_MAX_LENGTH_ASSETID]: "The Asset Id must be 8 characters long.",
        [TranslationErrors.ASSET_MIN_MAX_LENGTH_SENSOR_SERIAL_NUMBER]: "The Sensor Code must be 8 characters long.",
        [TranslationErrors.ASSET_ID_ALREADY_IN_USE]: "The Sensor Code is already in use",
        [TranslationErrors.SENSOR_NUMBER_INVALID]: "The Sensor Code is Invalid",

        /* ----------------MULTI TASK HOUR START----------------- */
        [TranslationKeys.ADD_HOUR]: "Add deadline",
        [TranslationKeys.ADD_RANGE_HOUR]: "Add hour range",
        /* ----------------MULTI TASK HOUR END----------------- */

        /* ----------------RELOAD_INSTANCES START----------------- */
        [TranslationKeys.RELOAD_INSTANCES]: "Reload instances",
        /* ----------------RELOAD_INSTANCES END----------------- */

        /* ----------------INPUT_PLACEHOLDERS START----------------- */
        [TranslationKeys.INPUT_PLACEHOLDER_EMAIL]: "name@4link.app",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_USERNAME_MY_PROFILE]: "Jperez",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_EMIAL_MY_PROFILE]: "jperez@4link.app",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_FIRSTNAME_MY_PROFILE]: "Juan",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_LASTNAME_MY_PROFILE]: "Perez",
        [TranslationKeys.INPUT_TEXT_PLACEHOLDER_DOCUMENT_NUMBER_MY_PROFILE]: "12345678A",

        [TranslationKeys.MAX_30_MB]: "30 MB Max.",

        /* ----------------TASK_DETAIL START----------------- */
        [TranslationKeys.COMPLETE_TASK]: "Complete",
        [TranslationKeys.THREE_STAR_MESSAGE]: "For ratings below 3 stars, you must provide a comment to the worker.",
        [TranslationKeys.FEEDBACK_TITLE_RATING_TWO_STARS]: "What is the reason for this very low rating?",
        [TranslationKeys.FEEDBACK_BODY_RATING_TWO_STARS]: "The task is poorly performed because...",
        [TranslationKeys.FEEDBACK_TITLE_RATING]: "Do you want to add any comments to your rating?",
        [TranslationKeys.FEEDBACK_BODY_RATING]: "The task is well performed because...",
        [TranslationKeys.WITH_REVIEW]: "With review",
        [TranslationKeys.REVIEWS]: "Reviews",
        [TranslationKeys.WITH_REVIEWS]: "With Reviews",
        [TranslationKeys.WITH_COMMENTS]: "With Comments",
        [TranslationKeys.WITH_NO_REVIEWS]: "Without Reviews",
        [TranslationKeys.WITHOUT_REVIEW]: "Without review",
        [TranslationKeys.DATA_OUT_OF_RANGE]: "There is data out of range",
        [TranslationKeys.DATA_OUT_OF_RANGE_BODY]: "Are you sure it is correct?",
        [TranslationModals.INACTIVE_CHECKLIST_ITEMS_TITLE]: "Checklist items have been left unchecked",
        [TranslationModals.INACTIVE_CHECKLIST_ITEMS_DESCRIPTION]:
            "Are you sure you want to complete the task without answering them?",
        [TranslationKeys.COULD_NOT_CHECK_SUBTASK]: "This subtask could not be marked",
        [TranslationKeys.DELETE_IMAGE_MODAL_TITLE]: "Delete Message :",
        [TranslationKeys.TASK_COMMENTS_MAX_250_CHARACTERS]: "The maximum number of characters is 250",
        [TranslationKeys.OPEN_ANOTHER_ISSUE]: "Open Another Issue",
        /* ----------------TASK_DETAIL END----------------- */

        /* ----------------ADMIN ERROR_MESSAGES START----------------- */
        [TranslationKeys.REVIEW_TASK_COMPLETED_OUT_OF_TIME]: "Completed Out of Time",
        [TranslationKeys.REVIEW_TASK_COMPLETED_IN_TIME]: "Completed On Time",
        [TranslationKeys.REVIEW_TASK_OFF_TIME]: "Out of time",
        [TranslationKeys.REVIEW_TASK_UNSEEN]: "Unseen",
        /* ----------------ADMIN ERROR_MESSAGES END----------------- */
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_VALORATION]: "Task Evaluation",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS]: "Your Completed Tasks",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_COMMENTS]: "Comment Management",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_TYPES]: "Manual Evaluation Types",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TEAMS]: "Your Teams Rating",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TEAM_AVG]: "Your Teams don't have any rating",
        /* ----------------ERROR_MESSAGES END----------------- */
        [TranslationErrors.USERNAME_ALREADY_REGISTERED]: "Username already registered.",
        [TranslationErrors.USER_EMAIL_ALREADY_REGISTERED]: "Email already registered.",
        /* ---------------- ERROR_MESSAGES END----------------- */

        /* ----------------TASK_COMMENTS----------------- */
        [TranslationKeys.TASK_COMMENTS_INFO_1]: "These comments are used to resolve doubts.",
        [TranslationKeys.TASK_COMMENTS_INFO_2]:
            "Any comment sent here will be automatically notified to your supervisor.",
        [TranslationKeys.TASK_COMMENTS_TITLE]: "Comments",
        [TranslationKeys.TASK_COMMENTS_PLACEHOLDER]: "No comments",
        [TranslationKeys.INSERT_MESSAGE_FAILED]: "Error inserting the comment into the database",
        /* ----------------TASK_COMMENTS END----------------- */
        //------------------------------------------------------------------------------------------------------------------------------TRADUCEME!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        /* ----------------TASKINSTANCE_STATUS START----------------- */
        [TranslationKeys.PENDING_OUT_OF_TIME_TASKI]: "Expired",
        [TranslationKeys.PENDING_TO_EXPIRE_TASKI]: "About to expire",
        [TranslationKeys.DONE_TASKI]: "Completed",
        [TranslationErrors.STATUS_CODE_INVALID]: "Task Status is Invalid",
        /* ----------------TASKINSTANCE_STATUS END----------------- */

        /* ----------------EMPTY_LIST_TASKINSTANCES START----------------- */
        [TranslationKeys.EMPTY_LIST_TASKI]: "There are no tasks to display.",
        [TranslationKeys.EMPTY_LIST_TASK_REVIEW]: "There are no tasks to review.",
        /* ----------------EMPTY_LIST_TASKINSTANCES END----------------- */

        /*--------------------Valoration POPUP--START------------------------------*/
        [TranslationKeys.LOW_EVALUATION_REASON]: "What is the reason for this very low rating review?",
        [TranslationKeys.ADD_EVALUATION_COMMENT]: "Do you want to add any review to your rating?",
        [TranslationKeys.TASK_POORLY_DONE]: "The task is poorly done by...",
        [TranslationKeys.TASK_DONE_DONE]: "The task is well done by...",
        [TranslationKeys.MESSAGE_IS_REQUIRED]: "Comment",
        [TranslationKeys.VALORATE_WITH_NO_MESSAGE]: "Rate Only",
        [TranslationKeys.SEND_MESSAGE]: "Send Comment",
        [TranslationKeys.VALORATE_TASK]: "Rate",
        [TranslationKeys.VALORATION_COMMENTS]: "Review",
        [TranslationKeys.VALORATION_TEXTAREA_PLACEHOLDER]: "Write or record your review.",
        /*--------------------Valoration POPUP--END------------------------------*/

        /*--------------------MY-PROFILE-PAGE--START------------------------------*/
        [TranslationKeys.MY_DATA]: "My Data",
        [TranslationKeys.MY_ALERTS]: "Alerts",
        [TranslationKeys.MY_CONFIGURATION]: "Configuration",
        [TranslationKeys.NOTIFICATIONS_LANGUAGE]: "Notifications and Language",

        [TranslationKeys.DO_NOT_DISTURB]: "Do Not Disturb Mode",
        [TranslationKeys.DO_NOT_DISTURB_SCHEDULED_DESCRIPTION]:
            "Enable this option to set when you want to receive notifications.",
        [TranslationKeys.DO_NOT_DISTURB_DESCRIPTION]:
            "Enable this option to stop receiving notifications on your phone.",
        [TranslationKeys.DO_NOT_DISTURB_SCHEDULED]: "Schedule Notification Hours",
        [TranslationKeys.SELECT_YOUR_LANGUAGE]: "Select your language for the entire application.",
        [TranslationErrors.TIME_RANGE_ERROR]: "Start time cannot be greater than end time!",
        [TranslationErrors.START_TIME_NULL_OR_EMPTY]: "Start time cannot be null or empty",
        [TranslationErrors.END_TIME_NULL_OR_EMPTY]: "End time cannot be null or empty",
        /*--------------------MY-PROFILE-PAGE--END------------------------------*/

        /*--------------------REVIEW-WORKER-PROFILE--START------------------------------*/
        [TranslationKeys.NOT_REVIEWED]: "Not Reviewed",
        [TranslationKeys.EVALUATED]: "Evaluated",
        /*--------------------REVIEW-WORKER-PROFILE--END------------------------------*/
        [TranslationKeys.WORKER_VALORATION_SECTION_TASK]: "You have no tasks",
        [TranslationKeys.WORKER_VALORATION_SECTION_TASK_SUBTITLE]: "You have no tasks for the selected dates",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_SUBTITLE]: "There are no valuations",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_DATE]: "For the selected dates, your teams have no tasks",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_SUBTITLE]: "You have no tasks",
        [TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE]: "For the Selected Dates There Are No Tasks",

        /*--------------------UserReview--START------------------------------*/
        [TranslationKeys.BETTER_THAN_LAST_MONTH]: "{1} better than last month",
        [TranslationKeys.WORSE_THAN_LAST_MONTH]: "{1} worse than last month",
        [TranslationKeys.SAME_THAN_LAST_MONTH]: "Same as last month",
        /*--------------------UserReview--END------------------------------*/

        [TranslationErrors.GENERIC_ERROR]: "There was an error during execution",
        [TranslationKeys.GO_TO_MODULE]: "Go to Module",
        [TranslationKeys.SECURITY]: "Security",
        [TranslationKeys.TASKS]: "Tasks",
        [TranslationKeys.TASK_IS_DISABLED]: "You still Can't Complete this Task",

        /*--------------------AUTH START-------------------------------*/
        [TranslationKeys.BACK_TO_LOGIN]: "Back to Login",
        [TranslationKeys.PASSWORD]: "Password",
        [TranslationKeys.NEW_PASSWORD]: "New Password",
        [TranslationKeys.RECOVER_PASSWORD]: "Recover Password",
        [TranslationKeys.PASSWORD_CONFIRM]: "Confirm Password",
        [TranslationKeys.CHECK_EMAIL]:
            "Check your email, we have sent you the necessary instructions to recover your password.",
        [TranslationKeys.USERNAME]: "Username",
        [TranslationKeys.EMAIL]: "Email",
        [TranslationKeys.PLEASE_INPUT]: "Please enter your",
        [TranslationErrors.PASS_ADMIN_INVALID]:
            "Enter a password between 6 to 12 characters, containing at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        [TranslationErrors.PASSWORDS_NOT_MATCH]: "Passwords do not match.",
        [TranslationErrors.LOGIN_INVALID_CREDENTIALS]: "Incorrect username or password",
        [TranslationKeys.LOGIN]: "Log In",
        [TranslationKeys.LOGIN_TITLE]: "Login",
        [TranslationKeys.LOGIN_CHANGE_PROFILE]: "Change Profile",
        [TranslationKeys.EMPTY_LOGIN_USERNAME]: "Enter your username",
        [TranslationKeys.EMPTY_LOGIN_PASSWORD]: "Enter a password",
        [TranslationModals.RESTABLISH_PASSWORD_TITLE_TOAST]: "Check your email",
        [TranslationModals.RESTABLISH_PASSWORD_SUBTITLE_TOAST]:
            "We have sent you the necessary instructions to recover your password",
        [TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE]: "Please enter a valid email address",
        /*--------------------AUTH END-------------------------------*/

        /*--------------------ERROR MESSAGES START-------------------------------*/
        [TranslationErrors.NO_COMPANY_ASSIGNED]:
            "To access the application, you must be assigned to at least one {0}. Please contact your administrator.",
        [TranslationErrors.NO_COMPANY_ASSIGNED_F]:
            "To access the application, you must be assigned to at least one {0}. Please contact your administrator.",
        [TranslationErrors.APP_FAILED_TOKEN]: "Please close the application and then reopen it.",
        [TranslationErrors.NO_PERMISSIONS_TITLE]: "Employee without permissions",
        [TranslationErrors.NO_PERMISSIONS_SUBTITLE]:
            "This employee does not have permissions to use the application, please contact your administrator to activate.",
        [TranslationErrors.ERROR_UNDER_MAINTENIANCE_SUBTITLE]:
            "Our website is currently undergoing scheduled maintenance. We apologize for the inconvenience and thank you for your patience.",
        [TranslationErrors.ERROR_UNDER_MAINTENIANCE_TITLE]: "Site Under Maintenance",
        [TranslationErrors.NO_COMPANY_ASSIGNED_TITLE]: "You have no {0} assigned",
        [TranslationErrors.NO_COMPANY_ASSIGNED_TITLE_F]: "You have no {0} assigned",
        [TranslationErrors.PAGE_NOT_FOUND_TITLE]: "Page Not Found",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE]: "An unexpected error has occurred",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_IS_OBLIGATORY]: "Value is mandatory",
        [TranslationErrors.GENERIC_ERROR_MESSAGE_VALUES_MUST_BE_GREATER_THAN_ZERO]: "Value must be greater than 0",
        [TranslationErrors.INVALID_PHONE_NUMBER]: "The phone number is not valid",
        [TranslationErrors.INITIALS_CANNOT_BE_EMPTY]: "Initials required.",
        [TranslationErrors.INITIALS_MAX_LENGTH]: "Initials must have 2 characters",
        [TranslationErrors.INITIALS_INVALID_FORMAT]: "Initials must have alphabetical characters",
        [TranslationErrors.INITIALS_ALREADY_USED]: "The initials are already in use.",
        /*--------------------ERROR MESSAGES END-------------------------------*/

        /*--------------------TABLE START-------------------------------*/
        [TranslationKeys.TABLE_NO_DATA]: "No data available",
        [TranslationKeys.TABLE_EXPORT_TO_CSV]: "Export to CSV",
        /*--------------------TABLE END-------------------------------*/

        /*--------------------PAGINATION START-------------------------------*/
        [TranslationKeys.PAGINATION_DE]: "of",
        /*--------------------PAGINATION END-------------------------------*/

        /*--------------------RECONNECTING START-------------------------------*/
        [TranslationKeys.RECONNECTING]: "Reconnecting...",
        /*--------------------RECONNECTING START-------------------------------*/

        /*-------------------- TOASTERS -------------------------------*/
        [TranslationModals.TOAST_GENERIC_ERROR]: "An error occurred.",
        [TranslationModals.TOAST_SUCCESS_SAVE]: "Saved successfully.",
        [TranslationModals.TOAST_QR_ERROR]: "This QR is Not Valid for this Application.",
        [TranslationModals.TOAST_PHOTO_NOT_ALLOWED_TITLE]: "Invalid media file.",
        [TranslationModals.TOAST_PHOTO_NOT_ALLOWED_SUBTITLE]: "Only images are allowed.",

        /* --------------------COMPANIES START-------------------- */
        [TranslationKeys.NO_ALERTS_RECEIVED]: "There are no received alerts",
        [TranslationKeys.NO_ALERTS_SENT]: "There are no sent alerts",
        [TranslationKeys.NO_ALERTS_PROGRAMMED]: "There are no programmed alerts",
        [TranslationKeys.USER_DEACTIVATED]: "Worker deactivated",
        [TranslationKeys.USER_ACTIVATED]: "Worker activated",
        /* --------------------COMPANIES END-------------------- */

        [TranslationModals.TOAST_SUCCESS_TASK]: "The task has been completed successfully.",
        [TranslationModals.TOAST_WARNING_PHOTO_REQUIRED]: "To complete the task, you need to upload a photo.",
        [TranslationModals.TOAST_WARNING_SELECT_OPTION]: "Please select an option:",
        [TranslationModals.TOAST_SUCCESS_EDIT]: "Edited successfully.",
        [TranslationModals.TOAST_ERROR_LOADING_PHOTO]: "Error loading the photo",
        [TranslationModals.TOAST_WARNING_PHOTO_ALREADY_UPLOADED]: "You have already updated this profile photo!",
        [TranslationModals.TOAST_ERROR_LOGIN]: "Incorrect username or password",
        [TranslationModals.TOAST_ERROR_USER_NOT_FOUND_LOGIN]: "User does not exist",
        [TranslationModals.TOAST_ALERT_SENDED]: "Alert sent successfully",
        [TranslationModals.TOAST_ERROR_COMPANY_GROUP]: "Failed to retrieve {0} groups",
        [TranslationModals.TOAST_ERROR_COMPANY]: "Failed to retrieve {0}",
        [TranslationModals.TOAST_ERROR_COMPANY_F]: "Failed to retrieve {0}",
        [TranslationModals.TOAST_ERROR_DEPARTMENT]: "Failed to retrieve departments",
        [TranslationModals.TOAST_ERROR_SUBDEPARTMENT]: "Failed to retrieve sub-departments",
        [TranslationModals.TOAST_PHOTO_ERROR_TITLE]: "There Was an Error Accessing the Camera.",
        [TranslationModals.TOAST_PHOTO_ERROR_SUBTITLE]:
            "Please Make Sure That the Camera is Connected and That you Have Given Permission for Its Use.",

        /*--------------------RECONNECTING END-------------------------------*/

        /*--------------------TABLE EMPTY STATE START-------------------------------*/
        [TranslationKeys.TABLE_EMPTY_STATE_TASK]: "Create a task to manage users in your teams.",
        [TranslationKeys.REVIEW_QUALITY_1_STAR_NO_REV]: "1 star without feedback",
        [TranslationKeys.REVIEW_QUALITY_3_STAR_NO_REV]: "2 y 3 without feedback",
        [TranslationKeys.REVIEW_QUALITY_HIGH_REVIEW]: "4 y 5 wioth or without feedback",
        [TranslationKeys.REVIEW_QUALITY_LOW_REVIEW]: "1, 2, 3 with feedback",
        [TranslationKeys.FAST_REVIEW]: "Manual",
        [TranslationKeys.SLOW_REVIEW]: "Out of Time +24h",
        [TranslationKeys.NO_REVIEW]: "Automatic",
        [TranslationKeys.FAST_COMMENT]: "Respond quickly",
        [TranslationKeys.SLOW_COMMENT]: "Long time to respond",
        [TranslationKeys.NO_COMMENT]: "No response",
        [TranslationKeys.TASKS_WITH_FEEDBACK]: "Tasks with feedback",

        /*--------------------TABLE EMPTY STATE END-------------------------------*/

        /*--------------------TABLE COLS START-------------------------------*/
        [TranslationKeys.TABLE_USERCODE]: "User Code",
        [TranslationKeys.TABLE_AREA]: "Area",
        /*--------------------TABLE COLS END-------------------------------*/

        /*--------------------SECONDARY FILTER START-------------------------------*/
        [TranslationKeys.SECONDARY_FILTER_CLEAR_FILTERS]: "Clear Filters",
        [TranslationKeys.PENDING_STATUS]: "Pending",
        [TranslationKeys.NEAR_END_STATUS]: "About to Expire",
        [TranslationKeys.TIMED_OUT_STATUS]: "Expired",
        [TranslationKeys.IN_TIME_STATUS]: "On Time",
        [TranslationKeys.OUT_OF_TIME_STATUS]: "Out of Time",
        [TranslationKeys.STATUS_CODE]: "Task Status",
        /*--------------------SECONDARY FILTER END-------------------------------*/

        /*--------------------TASK HISTORY START-------------------------------*/
        [TranslationKeys.CANNOT_FETCH]: "Could not fetch {0}",
        /*--------------------TASK HISTORY END-------------------------------*/

        /*--------------------REASIGN TASKS START-------------------------------*/
        [TranslationKeys.REASIGN_TASK_TITLE]: "Reassign Tasks",
        [TranslationKeys.REASIGN_TASK_STATUS_PERMANENT]: "Permanent",
        [TranslationKeys.REASIGN_TASK_STATUS_TEMPORAL]: "Temporary",
        [TranslationErrors.REASIGN_TASK_DATES_ERROR_MESSAGE]: "Start date must be less than end date",
        [TranslationKeys.REASIGN_TASK_TO]: "Reassign to",
        [TranslationKeys.REASIGN_TASK_CHANGE_Will_BE]: "The change will be",
        [TranslationKeys.REASIGN_TASK_START_DATE_PLACEHOLDER]: "Select a Date",
        [TranslationKeys.REASIGN_TASK_START_DATE_LABEL]: "From",
        [TranslationKeys.REASIGN_TASK_END_DATE_PLACEHOLDER]: "Select a Date",
        [TranslationKeys.REASIGN_TASK_END_DATE_LABEL]: "To",
        [TranslationKeys.REASIGN_TASK]: "Reassign",
        [TranslationKeys.REASIGN_TASK_SELECT_USER]: "Select a responsible person",
        [TranslationKeys.REASIGN_TASK_SELECT_DEPARTMENT]: "Select a Team",
        [TranslationKeys.REASIGN_TASK_SELECT_SUBDEPARTMENT]: "Select a Subteam",
        /*--------------------REASIGN TASKS END-------------------------------*/

        /*--------------------EDIT COMPANY FORM START-------------------------------*/
        [TranslationKeys.EDIT_FORM_COMPANY_NAME]: "{0} Name",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_NAME]: "{0} Name",
        [TranslationKeys.EDIT_FORM_COMPANY_ADDRESS]: "Address",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_ADDRESS]: "Address",
        [TranslationKeys.EDIT_FORM_COMPANY_CITY]: "City",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_CITY]: "City",
        [TranslationKeys.EDIT_FORM_COMPANY_NIF]: "Tax Identification Number (TIN)",
        [TranslationKeys.EDIT_FORM_COMPANY_MAIL]: "Email",
        [TranslationKeys.EDIT_FORM_COMPANY_POSTAL_CODE]: "Postal Code",
        [TranslationKeys.EDIT_FORM_COMPANY_PLACEHOLDER_POSTAL_CODE]: "Postal Code",
        [TranslationKeys.EDIT_FORM_COMPANY_GROUP]: "Group of {0}",
        [TranslationKeys.EDIT_COMPANY_TAB_INFO]: "Information",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY]:
            "When deactivating this {0}, you will be unlinked from all Tasks automatically. We recommend reviewing the Tasks before deactivating it. This action is irreversible.",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_F]:
            "When deactivating this {0}, you will be unlinked from all Tasks automatically. We recommend reviewing the Tasks before deactivating it. This action is irreversible.",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED]:
            "This {0} is not linked to any Task. It can be deactivated without affecting any existing Task.",
        [TranslationKeys.EDIT_COMPANY_DESCRIPTION_DELETE_COMPANY_NO_RELATED_F]:
            "This {0} is not linked to any Task. It can be deactivated without affecting any existing Task.",
        [TranslationKeys.EDIT_COMPANY_TAB_INTERNAL_ORG]: "Internal Organization",
        [TranslationKeys.UNDO_CHANGES]: "Undo Changes",
        [TranslationKeys.EDIT_COMPANY_GROUP_TITLE]: "Edit Group of {0}",

        [TranslationKeys.COMPANY_CLOSED]: "{0} Closed",
        [TranslationKeys.COMPANY_CLOSED_F]: "{0} Closed",
        [TranslationKeys.COMPANY_OPEN]: "{0} Open",
        [TranslationKeys.COMPANY_OPEN_F]: "{0} Open",
        /*--------------------EDIT COMPANY FORM END-------------------------------*/

        /*--------------------EDIT USER FORM START-------------------------------*/
        [TranslationKeys.EDIT_FORM_USER_DEACTIVATE]: "Deactivate",
        [TranslationKeys.EDIT_FORM_USER_ACTIVATE]: "Activate",
        [TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE]:
            "By deactivating a user, all tasks associated with this user will become shared. This action will not be reversible.",
        [TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE_NO_RELATED_TASK]:
            "This user is not linked to any task. It can be deactivated without affecting any existing task.",
        [TranslationKeys.EDIT_FORM_USER_WILL_ACTIVATE]: "The user will be activated",
        [TranslationKeys.EDIT_FORM_USER_DEACTIVATE_QUESTION]: "Are you sure you want to deactivate the user?",
        [TranslationKeys.EDIT_FORM_USER_ACTIVATE_QUESTION]: "Are you sure you want to activate the user?",
        [TranslationModals.EDIT_FORM_USER_TITLE_TOAST]: "Password has been successfully updated",
        [TranslationModals.EDIT_FORM_USER_TITLE_MODAL]: "The password will be reset",
        [TranslationModals.EDIT_FORM_USER_DESCRIPTION_MODAL]:
            "The password will be changed and your ID will be assigned by default.",
        [TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_TITLE]: "Add All Centers",
        [TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_DESCRIPTION]:
            "You are about to automatically assign all the centres. Are you sure?",
        [TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE]: "Add All Centers and Teams",
        [TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION]:
            "You are about to automatically assign all the centres and teams. Are you sure?",

        // USER ERRORS
        [TranslationErrors.USERNAME_INVALID_CHARACTERS]: "Invalid Username",
        [TranslationErrors.USERNAME_IS_REQUIRED]: "The Username is Required",
        [TranslationErrors.EMAIL_IS_REQUIRED]: "The Email is Required",
        [TranslationErrors.EMAIL_IS_INVALID]: "The Email is Invalid",
        [TranslationErrors.FIRSTNAME_IS_REQUIRED]: "The First Name is Required",
        [TranslationErrors.LASTNAME_IS_REQUIRED]: "The Last Name is Required",
        [TranslationErrors.USERCODE_IS_REQUIRED]: "The User Code is Required",
        [TranslationErrors.PROFILEPICTUREID_IS_INVALID]: "The Profile Picture has an Invalid Format",
        [TranslationErrors.USERCOMPANIES_0_IS_INVALID]: "The First {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_1_IS_INVALID]: "The Second {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_2_IS_INVALID]: "The Third {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_3_IS_INVALID]: "The Fourth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_4_IS_INVALID]: "The Fifth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_5_IS_INVALID]: "The Sixth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_6_IS_INVALID]: "The Seventh {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_7_IS_INVALID]: "The Eighth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_8_IS_INVALID]: "The Ninth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_9_IS_INVALID]: "The Tenth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_0_IS_INVALID_F]: "The First {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_1_IS_INVALID_F]: "The Second {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_2_IS_INVALID_F]: "The Third {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_3_IS_INVALID_F]: "The Fourth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_4_IS_INVALID_F]: "The Fifth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_5_IS_INVALID_F]: "The Sixth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_6_IS_INVALID_F]: "The Seventh {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_7_IS_INVALID_F]: "The Eighth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_8_IS_INVALID_F]: "The Ninth {0} is Invalid",
        [TranslationErrors.USERCOMPANIES_9_IS_INVALID_F]: "The Tenth {0} is Invalid",
        /*--------------------EDIT USER FORM END-------------------------------*/

        /* ----------------------PENDING ALERTS START-------------------------- */
        [TranslationKeys.THERE_ARE_NO_MORE_ALERTS]: "There are no more alerts to see",
        /* ----------------------PENDING ALERTS START-------------------------- */

        /*--------------------CREATE TASK START-------------------------------*/
        [TranslationKeys.CANCEL_REASIGNATION_TITLE]: "Do you want to Cancel Reassignment?",
        /*--------------------CREATE TASK END-------------------------------*/

        /*--------------------LICENSE WIZARD START-------------------------------*/
        [TranslationKeys.WIZARD_LICENSE_TITLE]: "Create Instance",
        [TranslationKeys.WIZARD_LICENSE_TITLE_DATA]: "Instance Data",
        [TranslationKeys.WIZARD_LICENSE_TITLE_MODULES]: "Modules and Features",
        [TranslationKeys.WIZARD_LICENSE_TITLE_ADMIN]: "Administrator's Data",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_NAME]: "Contact Name",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_NAME]: "Contact Name",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_EMAIL]: "Contact Email",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_EMAIL]: "Contact Email",
        [TranslationKeys.WIZARD_LICENSE_CONTACT_PHONE]: "Contact Phone",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_PHONE]: "Contact Phone",
        [TranslationKeys.WIZARD_LICENSE_INITIALS]: "QR Initials",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_INITIALS]: "QR Initials",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_NAME]: "Administrator's Name",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_NAME]: "Administrator's Name",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_LASTNAME]: "Administrator's Last Name",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_LASTNAME]: "Administrator's Last Name",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_USERNAME]: "Administrator's Username",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_USERNAME]: "Administrator's Username",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_EMAIL]: "Administrator's Email",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_EMAIL]: "Administrator's Email",
        [TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_DOCUMENT_NUMBER]: "Administrator's Document Number",
        [TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_DOCUMENT_NUMBER]: "Administrator's Document Number",
        [TranslationKeys.WIZARD_LICENSE_PHONE_NUMBER]: "Phone Number",
        [TranslationKeys.WIZARD_LICENSE_NAME_AND_LASTNAME]: "Name and Last Name",
        [TranslationKeys.WIZARD_LICENSE_EMIAL]: "Email",
        [TranslationKeys.WIZARD_LICENSE_DOCUMENT_NUMBER]: "Document Number",
        [TranslationErrors.INVALID_CUSTOMER_TYPE]: "Invalid Customer Type",
        [WizardLicenseTranslations.RESTAURANT]: "Restaurant",
        [WizardLicenseTranslations.HOTEL]: "Hotel",
        [WizardLicenseTranslations.PROPERTY_MANAGEMENT]: "Property Management",
        [WizardLicenseTranslations.WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL]: "Select Customer Type",
        /*--------------------LICENSE WIZARD END-------------------------------*/

        /*--------------------GROUP COMPANY START-------------------------------*/
        [TranslationKeys.GROUP_COMPANY_DELETE_TITLE]: "The group {0} will be deleted",
        [TranslationKeys.GROUP_COMPANY_FORM_NAME_LABEL]: "Group Name",
        [TranslationKeys.GROUP_COMPANY_FORM_NAME_PLACEHOLDER]: "Main {0} Ltd.",
        /*--------------------GROUP COMPANY END-------------------------------*/

        /**--------------------WIZARD SELECT Team START-------------------------------*/
        [TranslationKeys.INSTANCE_FORM_LABEL_NAME]: "Instance Name",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_NAME]: "Admin name",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_EMAIL]: "Admin Email",
        [TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_PHONE]: "Admin Phone Number",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_NAME]: "Instance Name",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME]: "Admin name",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL]: "Admin Email",
        [TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE]: "Admin Phone Number",

        /**--------------------WIZARD SELECT Team END-------------------------------*/

        /**--------------------WIZARD SELECT Team START-------------------------------*/
        [TranslationKeys.WELCOME_COMPANY]: "Welcome",
        [TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE]: "What {0} are you going to work at today?",
        [TranslationKeys.SELCT_DEPARTMENT_DEPARTMENT_TITLE]: "In which team?",
        [TranslationKeys.SELCT_DEPARTMENTS_DEPARTMENT_TITLE]: "What team are you going to work at today?",
        [TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENT_TITLE]: "In which subdepartment?",
        [TranslationKeys.SELCT_DEPARTMENT_SUBDEPARTMENTS_TITLE]: "What subteam are you going to work at today?",
        /**--------------------WIZARD SELECT Team END-------------------------------*/

        /*--------------------INSTABCE PAGE FORM START-------------------------------*/
        [TranslationKeys.RANKING_TITLE_USER]: "Ranking of Users",
        [TranslationKeys.RANKING_ASC_NAME]: "Ascending Name",
        [TranslationKeys.RANKING_DESC_NAME]: "Descending Name",
        [TranslationKeys.RANKING_ASC_STARS]: "Highest",
        [TranslationKeys.RANKING_DESC_STARS]: "Lowest",
        [TranslationKeys.RANKING_COMPLETED_OUT_OF_TIME]: "Completed Out of Time",
        [TranslationKeys.STARS]: "Stars",
        [TranslationKeys.STAR]: "Star",
        [TranslationKeys.TASKS_VALORATION]: "Tasks Valorations",

        [TranslationKeys.RANKING_NOT_COMPLETED]: "Not task Completed",
        [TranslationTitles.RANKING_COMPANY_TITLE]: "{0} Ranking",
        [TranslationKeys.RANKING_DEPARTMENTS]: "Teams Ranking ",

        [TranslationTitles.RANKING_DEPARTMENT_TITLE]: "{1} Ranking",
        [TranslationTitles.RANKING_USERS_BY_DEPARTMENT_TITLE]: "{1} Ranking",
        /*--------------------INSTABCE PAGE FORM END-------------------------------*/
        /*--------------------ERROR MESSAGES FETCH START-------------------------------*/
        [TranslationErrors.TASK_INSTANCE_ALREADY_COMPLETED]: "The task already completed",
        [TranslationErrors.TASK_INSTANCE_ALREADY_REOPENED]: "The task already reopened",
        [TranslationErrors.WORKING_POSITION_ALREADY_EXIST]: "The code already exists",
        [TranslationErrors.WORKING_POSITION_ENTITY_NOT_FOUND]: "QR Code not found",
        [TranslationErrors.WORKING_POSITION_TASKS_EMPTY]: "No Tasks found for Today",
        [TranslationErrors.DEPARTMENT_NOT_EXIST]: "The Team does Not Exist",
        [TranslationErrors.TASKS_ALREADY_IMPORTED]: "This QR tasks have already been imported",
        [TranslationErrors.COMPANIES_FAILED_LOAD_DATA]: "It has not been possible to recover the {0}.",
        [TranslationErrors.COMPANIES_FAILED_LOAD_DATA_F]: "It has not been possible to recover the {0}.",
        [TranslationErrors.WORKING_POSITION_FAILED_LOAD_DATA]: "It has not been possible to recover the QR.",
        [TranslationErrors.WORKING_POSITION_ALREADY_SELECTED]: "The QR Code has already been selected",
        [TranslationErrors.PASSWORD_CONTAINS_ASTERISK]: `The password must not contain asterisks (*) or inverted commas (').`,
        [TranslationErrors.TASK_INSTANCE_NOT_YOURS]: `The Task is not yours, it has already been Modified by another Employee.`,
        [TranslationErrors.TASK_INSTANCE_NOT_EXIST]: `The Task does not exist`,
        [TranslationErrors.TASK_INSTANCE_CHECKLIST_NOT_YOURS]: `The Task is not yours, it has already been Modified by another Employee.`,
        [TranslationErrors.TASK_INSTANCE_CHECKLIST_FAILED]: `This checklist could not be checked`,
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NULL_OR_EMPTY]: "To finish the task you need to attach a picture!",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_FOUND]: "The Photo has not been found",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_MAX_FILES]: "No more than 5 photos can be uploaded",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NO_PHOTOS]: "Photo could not be uploaded",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_MINE]:
            "The Task is not yours, it has already been Modified by another Employee",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_TASK_ALREADY_COMPLETED]: "The task has been completed",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_FAILED_UPLOADING]: "Some photos could not be uploaded",
        [TranslationErrors.TASK_INSTANCE_PHOTOS_NOT_EXIST]: "Photo not found",
        /*--------------------ERROR MESSAGES FETCH END-------------------------------*/

        /*--------------------WORKING POSITION START-------------------------------*/
        [TranslationKeys.WORKING_POSITION_EDIT_TITLE]: "Edit QR Code",
        [TranslationKeys.WORKING_POSITION_NEW_TITLE]: "New QR Code",
        [TranslationKeys.WORKING_POSITION_DISABLE_TITLE]: "Are you sure you want to deactivate the QR Code?",
        [TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION]:
            "All Tasks assigned to this QR will be assigned to the Team.",
        [TranslationKeys.WORKING_POSITION_DISABLE_DESCRIPTION_NO_RELATED]:
            "This QR is not linked to any task. It can be deactivated without affecting any existing task.",
        [TranslationKeys.WORKING_POSITION_ENABLE_TITLE]: "Are you sure you want to activate the QR Code?",
        [TranslationKeys.WORKING_POSITION_ENABLE_DESCRIPTION]: "The QR Code will be activated",
        [TranslationKeys.WORKING_POSITION_ENABLE]: "Activated",
        [TranslationKeys.WORKING_POSITION_DISABLE]: "Deactivated",
        [TranslationKeys.WORKING_POSITION_ACTIVE]: "Activate",
        [TranslationKeys.WORKING_POSITION_DEACTIVE]: "Deactivate",
        [TranslationKeys.WORKING_POSITION_NAME_LABEL]: "Name",
        [TranslationKeys.WORKING_POSITION_NAME_PLACEHOLDER]: "Name",
        [TranslationKeys.WORKING_POSITION_CODE_LABEL]: "Code",
        [TranslationKeys.WORKING_POSITION_CODE_PLACEHOLDER]: "Code",
        [TranslationKeys.WORKING_POSITION_DESCRIPTION_LABEL]: "Description",
        [TranslationKeys.WORKING_POSITION_DESCRIPTION_PLACEHOLDER]: "Description",
        [TranslationKeys.WORKING_POSITION_FAILED_ACTIVATE]: "Failed to Activate",
        [TranslationKeys.WORKING_POSITION_FAILED_DEACTIVATE]: "Failed to Deactivate",
        [TranslationKeys.WORKING_POSITION_SUCCESS_ACTIVATE]: "Successfully activated",
        [TranslationKeys.WORKING_POSITION_SUCCESS_DEACTIVATE]: "Successfully deactivated",
        [TranslationKeys.WORKING_POSITION_ALL]: "All QR Codes",
        [TranslationKeys.PRINT_QR]: "Print QR",
        [TranslationCommon.YES_CHANGE]: "Yes, change it",
        [TranslationCommon.NO_GOBACK]: "No, go back",
        /*--------------------WORKING POSITION END-------------------------------*/

        /*--------------------USER SCAN QR START-------------------------------*/
        [TranslationModals.QR_CHECKER_MODAL_TITLE]: "You are sure you want to select the Team {1}",
        [TranslationModals.QR_CHECKER_MODAL_DESCRIPTION]: "You are sure you want to select the Team {1}",
        [TranslationKeys.QR_CHECKER_TITLE]: "What team will you be working on today?",
        /*--------------------USER SCAN QR END-------------------------------*/

        /*--------------------ANALIST-------------------------------*/
        [TranslationKeys.RANKING]: "Ranking",
        [TranslationKeys.GLOBAL_RANKING_COMPANIES]: "{0} Ranking",

        [TranslationKeys.GLOBAL_RANKING_DEPARTMENTS]: "Team Global Ranking",
        [TranslationKeys.SEE_RANKING_DEPARTMENTS]: "See Team Ranking",
        [TranslationKeys.SEE_GLOBAL_RANKING_DEPARTMENTS]: "See Global Center Ranking",

        [TranslationKeys.GLOBAL_RANKING_USERS]: "Global Employee Ranking",
        [TranslationKeys.SEE_RANKING_USERS]: "See Employee Ranking",
        [TranslationKeys.SEE_GLOBAL_RANKING_USERS]: "See Employee Global Ranking",

        [TranslationKeys.ANALIST_TITLE_DONUT]: "Cumplimiento de Tareas",
        [TranslationKeys.ANALIST_TITLE_DONUT_DEPARTMENTLIST]: "Equipos con Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_DONUT_USERLIST]: "Usuarios con Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_DONUT_TASKLIST]: "Tareas {1}",
        [TranslationKeys.ANALIST_TITLE_STARS]: "Valoración de Tareas",
        [TranslationKeys.ANALIST_TITLE_LINECHART]: "Evolución del {1}",
        [TranslationKeys.ANALIST_TITLE_TASKLIST]: "Tareas con {1}",

        [TranslationErrors.ANALIST_DETAIL_ERROR]: "Error while loading {0} detail",
        [TranslationErrors.ANALIST_TASK_COUNTERS_ERROR]: "Error while loading task counters",
        [TranslationErrors.ANALIST_DETAIL_LIST_ERROR]: "Error while loading task list",

        [TranslationKeys.SUBDEPARTMENT]: "Subteams",

        [TranslationKeys.ANALIST_SHARED_TIMED_OUT]: "Shared Timed Out",
        [TranslationKeys.ANALIST_TIMED_OUT]: "Timed Out",
        [TranslationKeys.ANALIST_OUT_OF_TIME]: "Out of Time",
        [TranslationKeys.ANALIST_IN_TIME]: "In Time",

        [TranslationKeys.ANALIST_DISABLED_USER_TITLE]: "Usser Without Data",
        [TranslationKeys.ANALIST_DISABLED_USER_BODY]: "The selected user has no Data yet.",

        [TranslationKeys.ANALIST_TASKLIST_COMMENTS_TAB]: "Comments",
        [TranslationKeys.ANALIST_TASKLIST_FEEDBACK_TAB]: "Reviews",
        /*--------------------ANALIST END-------------------------------*/

        /*--------------------Common START-------------------------------*/
        [TranslationCommon.SKIP]: "Skip",
        [TranslationKeys.ATTACHED_FILES]: "Attached Files",
        [TranslationKeys.COMMENTS_OR_QUESTIONS]: "Do you want to leave a comment?",

        /*--------------------Common END-------------------------------*/

        /*--------------------Menu sidebar START-------------------------------*/
        [TranslationKeys.MENU_SIDEBAR_REPORTS]: "Reports",
        [TranslationKeys.MENU_SIDEBAR_TASK_VALORATION]: "Task valuation",
        /*--------------------Menu sidebar END-------------------------------*/

        /*--------------------Edit company START-------------------------------*/
        [TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT]:
            "When deactivating this Subteam, it will automatically disengage from all tasks. We recommend reviewing the tasks before deactivating it. This action is irreversible.",
        [TranslationKeys.EDIT_COMPANY_DELETE_SUBDEPARTMENT_NO_RELATED]:
            "This Subteam is not linked to any task. It can be deactivated without affecting any existing task.",
        [TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT]:
            "When deactivating this Team, it will automatically disengage from all tasks. We recommend reviewing the tasks before deactivating it. This action is irreversible.",
        [TranslationKeys.EDIT_COMPANY_DELETE_DEPARTMENT_NO_RELATED]:
            "This Team is not linked to any task. It can be deactivated without affecting any existing task.",
        [TranslationKeys.WORKS_WITH_QR]: "Add QRs?",
        /*--------------------Edit company END-------------------------------*/
        // titles
        [TranslationTitles.RECEIVED_ALERTS_TITLE]: "Alertas Recibidas",
        [TranslationKeys.PLANIFICATION_NEW_TASK]: "Create Tasks",
        // titles end

        /* --------------------MENUS-------------------- */
        /* HEADER */
        [TranslationTitles.MY_TASKS_HEADER_TITLE]: "My Tasks",
        [TranslationTitles.HEADER_REVIEW_TASKS_TITLE]: "Review",
        [TranslationTitles.HEADER_REVIEW_WORKERS_TITLE]: "Ranking",
        [TranslationTitles.HEADER_PLAN_APPCC_TITLE]: "HACCP Plans",
        [TranslationTitles.HEADER_ON_BOARDING_TITLE]: "Onboarding",

        // MULTICOMPANY MENU
        [TranslationTitles.COMPANIES_MAINTENANCE_TITLE]: "{0} and Teams",
        [TranslationTitles.ROLES_MAINTENANCE_TITLE]: "Roles and Permissions",
        [TranslationTitles.ROLES_TEMPORARY_TITLE]: "Roles and Temporary Permissions",
        [TranslationTitles.USERS_TITLE]: "Users",
        [TranslationKeys.ASSETS]: "Assets",
        [TranslationTitles.WORKING_POSITION_TITLE]: "QR Code",
        /* HEADER END */

        /* SIDEBAR */
        [TranslationTitles.CONFIGURATION_TITLE]: "Configuration",

        [TranslationKeys.MENU_SIDEBAR_TASK_MANAGER]: "Task Manager",
        [TranslationKeys.MENU_SIDEBAR_NEW_TASK_WIZARD_TITLE]: "Guided Task Creation",
        [TranslationTitles.SCAN_QR_TASK_TITLE]: "Scan Tasks",
        [TranslationTitles.SCAN_QR_SENSOR_TITLE]: "Scan Sensor",

        [TranslationKeys.MENU_SIDEBAR_TASK_REVIEW]: "Task Supervision",
        [TranslationTitles.REVIEW_TASKS_TITLE]: "Review Tasks",
        [TranslationKeys.MENU_SIDEBAR_USER_RANKING]: "Ranking of Users",
        [TranslationTitles.REVIEW_FEEDBACK_TITLE]: "My Ratings",

        [TranslationKeys.MENU_SIDEBAR_COMUNICATION]: "Communication",
        [TranslationKeys.MENU_SIDEBAR_ALERTS]: "Alerts",
        [TranslationKeys.MENU_SIDEBAR_NEW_REGISTRY]: "Guided Registry Creation",

        [TranslationKeys.MENU_SIDEBAR_CONTROL_PANEL]: "Control Panel",
        [TranslationKeys.MENU_SIDEBAR_APPCC_PLAN]: "HACCP Manager",
        [TranslationKeys.MENU_SIDEBAR_AUDIT]: "Audits",
        /* SIDEBAR END */

        // OLD
        [TranslationTitles.LICENSES_TITLE]: "Licenses",
        /* --------------------MENUS END-------------------- */

        /* --------------------RESET PASSWORD START-------------------- */
        [TranslationErrors.RESET_PASSWORD_ERROR_LENGTH_MESSAGE]: "Between 6 and 12 Characters",
        [TranslationErrors.RESET_PASSWORD_ERROR_CAPITAL_LETTER_AND_MINUSCULE_MESSAGE]:
            "1 Capital Letter and 1 minuscule.",
        [TranslationErrors.RESET_PASSWORD_ERROR_NUMBER_MESSAGE]: "1 Number",
        [TranslationErrors.RESET_PASSWORD_ERROR_SPECIAL_CHARACTER_MESSAGE]: "1 Special Character",
        /* --------------------RESET PASSWORD END-------------------- */

        /* --------------------SELECT PROFILE START-------------------- */
        [TranslationKeys.SELECT_PROFILE_TITLE]: "Choose your profile",
        [TranslationKeys.SELECT_PROFILE_ADD_NEW_PROFILE]: "Add new profile",
        /* --------------------SELECT PROFILE END-------------------- */

        /* --------------------COMPANIES START-------------------- */
        [TranslationModals.COMPANIES_ADD_DELETE_USERS_TITLE]: "Are you sure you want to add and remove selected users?",
        [TranslationModals.COMPANIES_ADD_DELETE_USERS_DESCRIPTION]:
            "When deleting a user from team, all tasks associated with this user will be shared. This action will not be reversible.",
        [TranslationModals.COMPANIES_ADD_USERS_TITLE]: "Are you sure you want to add these users?",
        [TranslationModals.COMPANIES_DELETE_USERS_TITLE]: "Are you sure you want to delete the selected users?",
        [TranslationModals.COMPANIES_DELETE_USERS_DESCRIPTION]:
            "When deleting a user from team, all tasks associated with this user will be shared. This action will not be reversible.",
        /* --------------------COMPANIES END-------------------- */

        /* --------------------ARIA LABLES START-------------------- */

        /* --------------------RANKING PILL COUNTER START-------------------- */
        [AriaLabels.AL_RANKING_PILL_COUNTER_ALARM]: "Alarm",
        [AriaLabels.AL_RANKING_PILL_COUNTER_DEFAULT_ALARM]: "Alarm with no tasks",
        [AriaLabels.AL_RANKING_PILL_COUNTER_ERROR]: "Error",
        [AriaLabels.AL_RANKING_PILL_COUNTER_SUCCESS]: "Success",
        [AriaLabels.AL_RANKING_PILL_COUNTER_CROSS]: "Cross",
        [AriaLabels.AL_RANKING_PILL_COUNTER_EQUALS]: "Icon",
        [AriaLabels.AL_RANKING_PILL_COUNTER_TOTAL]: "Total",
        [AriaLabels.AL_RANKING_PILL_COUNTERS_TOTAL]: "Total Tasks",
        [AriaLabels.AL_RANKING_PILL_INFO_AVATAR_DESCRIPTION]: "User Description",
        [AriaLabels.AL_COLLAPSABLE_TITLE]: "Collapsable Title",
        [AriaLabels.AL_COLLAPSABLE_TITLE_ICON]: "Icon Open Close Collapsable",
        /* --------------------RANKING PILL COUNTER END-------------------- */

        /* --------------------ASSETS END-------------------- */
        [TranslationModals.ASSET_FAILED_TITLE]: "Failed to save asset",
        [TranslationModals.ASSET_FAILED_DESCRIPTION]: "Please check all required fields and try again.",
        [TranslationKeys.ASSET_DYNAMIC_ASSETS_TITLE]: "Dynamic Fields",
        [TranslationKeys.ASSET_ADD_DYNAMIC_FIELD_PLACEHOLDER]: "Add Dynamic Field",
        /* --------------------ASSETS END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [TranslationKeys.ISSUE_COUNT]: "Consecutive Issues",
        [TranslationKeys.ISSUE_COUNT_TOTAL]: "Total Issues",
        [TranslationKeys.MOBILE_ISSUE_COUNT]: "Consec. Issues",
        [TranslationKeys.MOBILE_ISSUE_COUNT_TOTAL]: "Tot. Issues",
        [TranslationKeys.ISSUE_TYPE_TASK]: "Task",
        [TranslationKeys.ISSUE_TYPE_ASSET]: "Asset",
        [TranslationKeys.ISSUE_TYPE_GENERIC]: "Common",
        [TranslationKeys.ISSUE_TYPE_AUDIT]: "Audit",
        [TranslationKeys.ISSUE_TYPE_TASK_TAB]: "Tasks",
        [TranslationKeys.ISSUE_TYPE_ASSET_TAB]: "Assets",
        [TranslationKeys.ISSUE_TYPE_GENERIC_TAB]: "Common",
        [TranslationKeys.ISSUE_TYPE_AUDIT_TAB]: "Audits",
        [AriaLabels.AL_ISSUE_COUNT]: "Number of Consecutive Issues for this Asset",
        [AriaLabels.AL_ISSUE_COUNT_TOTAL]: "Number of Total Issues for this Asset",

        [TranslationKeys.OPEN_ISSUE]: "Open Issue",
        [TranslationErrors.ERROR_MESSAGE_MAX_10_COR_MES]: "You can only add up to a maximum of 10 Corrective Actions.",
        [TranslationErrors.ERROR_MESSAGE_MAX_20_SUPERVISORS]:
            "You can only add up to a maximum of 20 supervisors / analysts.",
        [TranslationErrors.ERROR_MESSAGE_ISSUE_NOT_CREATED]: "The Issue Could Not be Created",
        [TranslationKeys.ISSUE_CREATED_SUCCESSFULLY]: "Issue Created",
        [TranslationModals.ISSUE_IMAGE_LOADING_TITLE]: "Uploading Images...",
        [TranslationModals.ISSUE_IMAGE_LOADING_DESCRIPTION]: "The issue is being generated, please wait.",
        [TranslationKeys.PROBLEM_DETECTED_LABEL]: "What is the reason for this deviation?",
        [TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER]: "Explain the Problem that has been Detected.",
        [TranslationKeys.ISSUE_PROBLEM_DETECTED_LABEL]: "What problem has been detected?",
        [TranslationKeys.ATTACH_FILES_LABEL]: "Attached Documents",
        [TranslationKeys.CORRECTIVE_MEASSURE_LABEL]: "Corrective Meassures (Optional)",
        [TranslationKeys.CORRECTIVE_MEASSURE_PLACEHOLDER]: "What Corrective Measures Should Be Implemented?",
        [TranslationKeys.ISSUE_ASSET_LABEL]: "Linked Asset",
        [TranslationKeys.SUPERVISOR_ISSUE_LABEL]: "Send Notice (min 1, max 20)",
        [TranslationKeys.SUPERVISOR_ISSUE_PLACEHOLDER]: "Who do you Want to Notify?",
        [TranslationKeys.CREATE_MANUAL_ISSUE_TITLE]: "Open New Issue",
        [TranslationKeys.SEND_MANUAL_ISSUE]: "Send Issue",
        [TranslationKeys.SEND_AND_COMPLETE_MANUAL_ISSUE]: "Send and Complete",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE]: "Out of Range Data has Been Recorded ",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_2]: "{1} Times in a Row",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_3]: " For the Asset ",
        [TranslationKeys.ISSUE_ASSET_STEP_TITLE_4]: '"{0}"',
        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE]: "{0}: {1} {2}",
        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE_SINGULAR]: "Out of Range Record.",
        [TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE_PLURAL]: "Out of Range Records:",
        [TranslationKeys.ISSUE_ASSET_LAST_STEP_TITLE]: "Select Responsibles to Send the Issue Notification.",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK]: "There Are no Issues Linked to Tasks",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_OPEN]: "There Are no Open Issues Linked to Tasks",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_CLOSE]: "There Are no Closed Issues Linked to Tasks",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET]: "There Are no Issues Linked to Assets",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_OPEN]: "There Are no Open Issues Linked to Assets",
        [TranslationErrors.TABLE_ERROR_ISSUE_TASK_ASSET_CLOSE]: "There Are no Closed Issues Linked to Assets",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC]: "There Are no Issues",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_OPEN]: "There Are no Open Issues",
        [TranslationErrors.TABLE_ERROR_ISSUE_GENERIC_CLOSE]: "There Are no Closed Issues",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT]: "There Are no Issues Linked to Audits",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_OPEN]: "There Are no Open Issues Linked to Audits",
        [TranslationErrors.TABLE_ERROR_ISSUE_AUDIT_CLOSE]: "There Are no Closed Issues Linked to Audits",
        [TranslationModals.ISSUES_TOAST_FAILED_LOAD_DATA]: "Failed Load Issues",
        [TranslationKeys.ISSUE_ID]: "ID",
        [TranslationKeys.ISSUE_DESCRIPTION]: "Description",
        [TranslationKeys.ISSUE_OPEN_DATE]: "Open Date",
        [TranslationKeys.ISSUE_OPEN_BY]: "Opened By",
        [TranslationKeys.ISSUE_ACTUAL_STATUS]: "Status",
        [TranslationKeys.ISSUE_STATUS_OPEN]: "Open",
        [TranslationKeys.ISSUE_STATUS_CLOSE]: "Closed",
        [TranslationKeys.ISSUE_STATUS_OPEN_AND_CLOSE]: "Open y Closed",
        [TranslationKeys.ISSUE_STATE]: "Issue State",
        [TranslationKeys.ISSUES]: "Issues",
        [TranslationKeys.ISSUE_MENU_TITLE]: "Issue Manager",
        /* --------------------ISSUE END-------------------- */

        /* --------------------BASE TASK START-------------------- */
        [TranslationTitles.BASE_TASK_TITLE]: "Base Tasks Manager",
        [TranslationTitles.BASE_TASK_REGISTRY_TITLE]: "Base Registries Manager",
        [TranslationModals.BASE_TASK_TOAST_FAILED_LOAD_DATA]: "Error loading base tasks",
        [TranslationModals.TOAST_SAVE_APPCC_BASE_TASK]: "HACCP Record for the Base Task Created Successfully",
        [TranslationModals.TOAST_SAVE_BASE_TASK]: "Base Task Created Successfully",
        [TranslationModals.TOAST_EDIT_APPCC_BASE_TASK]: "HACCP Record for the Base Task Updated Successfully",
        [TranslationModals.TOAST_EDIT_BASE_TASK]: "Base Task Updated Successfully",
        [BaseTaskTranslations.BASE_TASK_ID]: "Id",
        [BaseTaskTranslations.BASE_TASK_NAME]: "Detail",
        [BaseTaskTranslations.BASE_TASK_DESCRIPTION]: "Description",
        [BaseTaskTranslations.BASE_TASKS]: "Tasks",
        [BaseTaskTranslations.BASE_TASKS_APPCC]: "HACCP Records",
        [BaseTaskTranslations.BASE_TASK_TYPE_LABEL]: "Clasification",
        [BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL]: "HACCP Plan",
        [BaseTaskTranslations.NEW_APPCC_BASE_TASK]: "New Base HACCP Record",
        [BaseTaskTranslations.NEW_BASE_TASK]: "New Base Task",
        [BaseTaskTranslations.EDIT_APPCC_BASE_TASK]: "Edit Base HACCP Record",
        [BaseTaskTranslations.EDIT_BASE_TASK]: "Edit Base Task",
        [BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_TITLE]: "All Associated Tasks will be Modified",
        [BaseTaskTranslations.MODAL_BASE_TASK_UPDATE_ASSOCIATED_TASKS_BODY]:
            "All linked task data will be overwritten with this new standard (The specific example photos of each task are the only thing that will not be overwritten)",
        [BaseTaskTranslations.BASE_TASK_NOT_ALLOWED_DATA]: "Do Not Allow Any Data",
        [BaseTaskTranslations.BASE_TASK_ADD_CHECKLIST]: "Add Checklist",
        [BaseTaskTranslations.BASE_TASK_ADD_DYNAMIC_FIELDS]: "Add Dynamic Fields",
        [BaseTaskTranslations.BASE_TASK_ASSETS_IS_REQUIRED]: "Force Link Assets",
        [BaseTaskTranslations.BASE_TASK_RANGE_REGISTER_IS_REQUIRED]: "Force Link Registration with Range",
        [BaseTaskTranslations.BASE_TASK_ALLOW_ANY_DATA]: "Allow any type of data?",
        [BaseTaskTranslations.BASE_TASKS_REPORT_TYPE_LABEL]: "Data Types",
        [BaseTaskTranslations.BASE_TASKS_TYPE_LABEL]: "Base Task Clasifications",
        [BaseTaskTranslations.BASE_TASK_ALL_REPORT_TYPES_LABEL]: "All Data",
        [BaseTaskTranslations.BASE_TASK_NONE_REPORT_TYPES_LABEL]: "Any Data",
        [BaseTaskTranslations.BASE_TASK_DYNAMIC_FIELD_REPORT_TYPES_LABEL]: "Dynamic Fields",
        [BaseTaskTranslations.BASE_TASK_CHECKLIST_REPORT_TYPES_LABEL]: "Checklist",
        [BaseTaskTranslations.BASE_TASK_ASSET_REPORT_TYPES_LABEL]: "Linked Assets",
        [BaseTaskTranslations.BASE_TASKS_IS_PHOTO_REQUIRED_LABEL]: "¿Is Photo Required?",
        [BaseTaskTranslations.BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL]: "Nº Associatad Tasks",
        [BaseTaskTranslations.BASE_TASK_SEARCHING_TASKS]: "Searching Similar Tasks...",
        [BaseTaskTranslations.BASE_TASK_SEARCHING_REGISTRIES]: "Searching Similar Registries...",
        [BaseTaskTranslations.BASE_TASK_SIMILAR_TASKS]: "Similar Tasks Founded:",
        [BaseTaskTranslations.BASE_TASK_SIMILAR_REGISTRIES]: "Similar Registries Founded:",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_TITLE]: "Duplicate task title detected",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_TASK_DESCRIPTION]:
            "At least one task with a similar title has been found. We recommend you to check the existing titles to avoid duplication or continue if you are sure to create this new task.",

        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_TITLE]: "Title of the duplicate registry detected",
        [BaseTaskTranslations.BASE_TASK_SAME_TITLE_REGISTRY_DESCRIPTION]:
            "At least one registry with a similar title has been found. We recommend that you check existing titles to avoid duplication or continue if you are sure you want to create this new registry.",
        [BaseTaskTranslations.BASE_TASK_START_DATE_LABEL]: "Periodicity Day",
        [BaseTaskTranslations.BASE_TASK_HINT_TITLE]:
            "It is used to set the default frequency you wish to establish when creating a task.",
        [BaseTaskTranslations.BASE_TASK_HINT_EXAMPLE]: "Ex.: Every Thursday, every month on the 4th...",
        /* --------------------BASE TASK END-------------------- */

        /* --------------------BASE TASK TYPE START-------------------- */
        [TranslationKeys.BASE_TASK_SAVE_TITLE]: "Save Base Task Clasification",
        [TranslationKeys.BASE_TASK_EDIT_TITLE]: "Edit Base Task Clasification",
        [TranslationErrors.BASE_TASK_TYPE_NAME_ERROR]: "Name cannot be empty.",
        [TranslationErrors.BASE_TASK_TYPE_NOT_FOUND]: "Base Task Clasification not found.",
        [TranslationModals.BASE_TASK_TYPE_SAVED_SUCCESSFULLY]: "Base Task Clasification Created Successfully",
        [TranslationModals.BASE_TASK_TYPE_EDITED_SUCCESSFULLY]: "Base Task Clasification Edited Successfully",
        [TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_TITLE]: "Delete Base Task Clasification",
        [TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_DESCRIPTION]:
            "You are going to Delete the Base Task Clasification {0}, are you sure?",
        [TranslationKeys.BASE_TASK_TYPE_ADD_NEW_TYPE_TITLE]: "Add New Base Task Clasification",
        [TranslationModals.BASE_TASK_TYPE_DELETED_SUCCESSFULLY]: "Base Task Clasification Deleted Successfully.",
        [TranslationModals.CANNOT_DELETE_BASE_TASK_TYPE_TITLE]: "Cannot be Deleted",
        [TranslationModals.CANNOT_DELETE_BASE_TASK_TYPE_IN_USE]:
            "To Delete the Base Task Clasification, it must first be Unassigned from All Tasks. Alternatively, you can Edit it.",
        /* --------------------BASE TASK TYPE END-------------------- */

        /* --------------------JOB DESCRIPTION START-------------------- */
        [TranslationErrors.JOB_DESCRIPTION_NOT_FOUND]: "The Category Does Not Exist",
        [TranslationErrors.JOB_DESCRIPTION_ALREADY_EXIST]: "The Category Already Exists",
        [TranslationKeys.JOB_DESCRIPTION_CREATE_NEW_TITLE]: "Add New Category",
        [TranslationKeys.JOB_DESCRIPTION_EDIT_TITLE]: "Edit Category",
        [TranslationKeys.JOB_DESCRIPTION]: "Category",
        [TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_TITLE]: "Delete Category",
        [TranslationModals.JOB_DESCRIPTION_DELETE_MODAL_DESCRIPTION]:
            "You are going to remove the category {0}. It is an irreversible action. Are you sure?",
        [TranslationErrors.ERROR_DELETING_JOB_DESCRIPTION]: "Category Could Not Be Deleted",
        [TranslationKeys.ADD_NEW_JOB_DESCRIPTION]: "Add New Category",
        /* --------------------JOB DESCRIPTION END-------------------- */

        /* --------------------DRAGFILE START-------------------- */
        [TranslationKeys.DRAG_FILE_MAKE_PHOTO]: "Take a Photo",
        [TranslationKeys.DRAG_FILE_GALLERY]: "Gallery",
        /* --------------------DRAGFILE END-------------------- */

        /* --------------------LOGIN START-------------------- */
        [TranslationKeys.LOGGIN_USERNAME]: "Username",
        [TranslationKeys.LOGGIN_PLACEHOLDER_USERNAME]: "Username",
        [TranslationKeys.LOGGIN_PASSWORD]: "Password",
        [TranslationKeys.LOGGIN_PLACEHOLDER_PASSWORD]: "Password",
        [TranslationKeys.RECOVER_PASSWORD_EMAIL]: "Email",
        [TranslationKeys.RECOVER_PASSWORD_PLACEHOLDER_EMAIL]: "Email",
        [TranslationKeys.RESET_PASSWORD]: "New Password",
        [TranslationKeys.RESET_PASSWORD_PLACEHOLDER]: "Password",
        [TranslationKeys.RESET_PASSWORD_CONFIRM_PASSWORD]: "Confirm Password",
        [TranslationKeys.RESET_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD]: "Confirm Password",
        /* --------------------LOGIN END-------------------- */

        /* --------------------PLACEHOLDER PAGE START-------------------- */
        [TranslationKeys.SITE_UNDER_CONSTRUCTION]: "Site Under Construction",
        /* --------------------PLACEHOLDER PAGE END-------------------- */

        /* --------------------PLANAPPCC START-------------------- */
        [TranslationKeys.PLAN_DELETE]: "Delete Plan",
        [TranslationKeys.PLAN_EDIT]: "Edit Plan",
        [TranslationKeys.PLAN_CREATE]: "Create Plan",
        [TranslationErrors.PLAN_NOT_FOUND]: "Plan Not Found",
        [TranslationKeys.PLAN_ID]: "Id",
        [TranslationKeys.PLAN_NAME]: "Name",
        [TranslationKeys.PLAN_NAME_TASK_COUNT]: "Linked Registries",
        [TranslationKeys.PLAN_NAME_ASSET_COUNT]: "Linked Assets",
        [TranslationKeys.PLAN_NAME_CORRECTIVE_MEASSURE_COUNT]: "Corrective Meassures",
        [TranslationErrors.PLANS_NOT_FOUND]: "No Plans Have Been Found",
        [TranslationKeys.PLANS]: "Plans",
        [TranslationModals.PLANS_FAILED_EDIT]: "The Plan Could Not be Edited",
        [TranslationModals.PLANS_SUCCESS_EDIT]: "The Plan has Been Correctly Updated",
        [TranslationModals.PLANS_FAILED_CREATE]: "The Plan Could Not be Created",
        [TranslationModals.PLANS_SUCCESS_CREATE]: "The Plan has Been Correctly Created",
        [TranslationModals.PLANS_SUCCESS_DELETE]: "The Plan has Been Correctly Deleted",
        [TranslationKeys.EDIT_FORM_PLAN_NAME_LABEL]: "Plan Name",
        [TranslationKeys.EDIT_FORM_PLAN_NAME_PLACEHOLDER]: "Plan Name",
        [TranslationModals.PLANS_DELETE_MODAL_TITLE]: 'The "{0}" Plan Will Be Deleted.',
        [TranslationModals.PLANS_DELETE_MODAL_DESCRIPTION]:
            "Deleting a plan Will Delete all linked Registries and Assets as well as the Corrective measures of the Plan. This action is irreversible.",
        /* --------------------PLANAPPCC END-------------------- */

        /* ---------------------AUDIT_PAGE START--------------------------------- */
        // AUDIT TEMPLATE
        [TranslationTitles.AUDIT_TEMPLATE_TITLE]: "Audit Templates",
        [TranslationTitles.AUDIT_GRID_TITLE]: "Audits",
        [TranslationKeys.ADD_AUDIT]: "New Template",
        [TranslationKeys.AUDIT_NAME_LABEL]: "Template Name",
        [TranslationErrors.AUDIT_NOT_FOUND]: "Template Not Found",
        [TranslationModals.DELETE_AUDIT_TITLE]: 'The Template "{0}" will be deleted',
        [TranslationModals.DELETE_AUDIT_BODY]:
            "This action will permanently delete the groupings and checklists associated with this template.",
        [TranslationKeys.NEW_AUDIT]: "Create New Audit Template",
        [TranslationKeys.EDIT_AUDIT]: "Edit Audit Template",
        [TranslationKeys.AUDIT_LIST_EMPTY_TITLE]: "There are no Audit Templates",
        [TranslationKeys.AUDIT_LIST_EMPTY_BODY]: "Create a New Template to facilitate an Audit.",
        [TranslationModals.AUDIT_SAVE_TOAST]: "Template Saved Successfully",
        [TranslationModals.AUDIT_EDIT_TOAST]: "Template Updated Successfully",
        [TranslationModals.AUDIT_DELETE_TOAST]: "Template Deleted Successfully",

        // AUDIT GROUP
        [TranslationKeys.AUDIT_GROUP_NAME_LABEL]: "Checklist Group Name",
        [TranslationErrors.AUDIT_GROUP_NOT_FOUND]: "Group Not Found",
        [TranslationModals.DELETE_AUDIT_GROUP_TITLE]: 'The Group "{0}" will be deleted.',
        [TranslationModals.DELETE_AUDIT_GROUP_BODY]:
            "This action will permanently delete the checklists associated within this template.",
        [TranslationKeys.ADD_AUDIT_GROUP]: "New Group",
        [TranslationKeys.NEW_AUDIT_GROUP]: "Create New Group",
        [TranslationKeys.EDIT_AUDIT_GROUP]: "Edit Group",
        [TranslationModals.AUDIT_GROUP_SAVE_TOAST]: "Group Saved Successfully",
        [TranslationModals.AUDIT_GROUP_EDIT_TOAST]: "Group Updated Successfully",
        [TranslationModals.AUDIT_GROUP_DELETE_TOAST]: "Group Deleted Successfully",
        [TranslationKeys.AUDIT_GROUP_EMPTY_TITLE]: "There are no Groupings",
        [TranslationKeys.AUDIT_GROUP_EMPTY_BODY]: "Create a New Grouping to facilitate Grouping within an Audit.",

        // AUDIT GROUP CHECKLIST
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL]: "Checklist Name",
        [TranslationKeys.NEW_AUDIT_GROUP_CHECKLIST]: "New Checklist",
        [TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST]: "Edit Checklist",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME]: "Checklists",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_NAME_ERROR]: "Name cannot be empty.",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_SUBMIT_ERROR_TITLE]:
            "Something went wrong while updating the checklist.",
        [TranslationKeys.AUDIT_GROUP_CHECKLISTS]: "Checklists",
        [TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_TITLE]: 'The Checklist "{0}" will be deleted',
        [TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_BODY]:
            "This action will permanently delete the items within the checklist.",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_SAVE_TOAST]: "Checklist Saved Successfully",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_EDIT_TOAST]: "Checklist Updated Successfully",
        [TranslationModals.AUDIT_GROUP_CHECKLIST_DELETE_TOAST]: "Checklist Deleted Successfully",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_TITLE]: "There are no checklists",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_BODY]:
            "Create a New Checklist to facilitate the resolution of an Audit",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_ITEM_COUNT]: "Cantidad",

        // AUDIT GROUP CHECKLIST ITEM
        [TranslationKeys.ADD_AUDIT_GROUP_CHECKLIST_ITEM]: "New Checklist Item",
        [TranslationErrors.AUDIT_GROUP_CHECKLIST_ITEM_ERROR]: "Select an Item.",
        /* ---------------------AUDIT_PAGE END--------------------------------- */

        /* --------------------PLANAPPCC STAR-------------------- */
        [TranslationKeys.BASE_TASK_MODAL_TITLE]: "Select a Base Task",
        [TranslationKeys.BASE_TASK_MODAL_REGISTRY_TITLE]: "Select a Base Registry HACCP",
        [TranslationKeys.BASE_TASK_MODAL_IS_APPCC]: "Is it a HACCP Task?",
        [TranslationKeys.BASE_TASK_MODAL_IS_TASK_TYPE_LABEL]: "Select Task Clasification",
        [TranslationKeys.BASE_TASK_MODAL_TASKS_LABEL]: "Select a Base Task",
        [TranslationKeys.BASE_TASK_MODAL_CREATE_NEW]: "Can't Find the Task? Create One!",
        [TranslationErrors.BASE_TASK_NOT_FOUND]: "Task Not Found",
        [TranslationKeys.BASE_TASK_LABEL]: "Base Tasks",
        /* --------------------PLANAPPCC END-------------------- */

        /* --------------------CORRECTIVE MEASURES START-------------------- */
        [TranslationKeys.CORRECTIVE_MEASSURE_MEASURE]: "Measures",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_CREATE]: "Crete Corrective Measure",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_TITLE_EDIT]: "Crear Corrective Measure",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_LABEL]: "Corrective Measure Name",
        [TranslationKeys.CORRECTIVE_MEASSURE_MODAL_PLACEHOLDER]: "Corrective Measure Name",
        [TranslationKeys.CORRECTIVE_MEASSURE_DESCRIPTION]: "Description",
        [TranslationKeys.CORRECTIVE_MEASSURE_PLAN]: "Plan HACCP",
        [TranslationKeys.CORRECTIVE_MEASSURE_ID]: "Id",
        [TranslationModals.CORRECTIVE_MEASSURE_FAILED_DELETE]: "Failed Deleting Corrective Measure",
        [TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_DESCRIPTION]:
            "Are you sure you want to Remove the Corrective Measure? This action is irreversible.",
        [TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_TITLE]: "Delete {0}",
        [AriaLabels.AL_QR_COMPANY_PHOTO]: "{0} Photo",
        [AriaLabels.AL_QR_COMPANY_PHOTO_F]: "{0} Photo",
        [AriaLabels.AL_QR_FORULINK_PHOTO]: "4Link Logo",
        [AriaLabels.AL_POPOVER]: "Popover",
        [AriaLabels.AL_ALERT_PILL_READED_BADGE]: "Alert readed",
        [AriaLabels.AL_ALERT_PILL_SENDED_BADGE]: "Alert sended",
        /* --------------------CORRECTIVE MEASURES END-------------------- */

        /* --------------------AUDIT START-------------------- */
        [TranslationKeys.AUDIT]: "Audit",
        [TranslationKeys.START_AUDIT]: "Start Audit",
        [TranslationKeys.START_AUDIT_MODAL_TITLE]: "Start Audit",
        [TranslationKeys.START_AUDIT_MODAL_AUDIT_LABEL]: "Audit",
        [TranslationKeys.AUDIT_NOT_EXIST]: "Audit Does not Exist",
        [TranslationKeys.AUDIT_CHECKLIST_INSTANC_NOT_EXIST]: "No Checklists found",
        [TranslationKeys.AUDIT_TOTAL]: "Total",
        [TranslationErrors.AUDIT_CHECKLIST_ERROR_FORM_VALUES]: "Complete all the items in the checklist to finish it.",
        [TranslationKeys.COMPLETE_CHECKLIST]: "Complete the Checklist",
        [TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE]: "Error Updating",
        [TranslationKeys.AUDIT_CHECKLIST_ITEM_REQUIRED]: "Required",
        [TranslationModals.AUDIT_CHECKLIST_MODAL_TITLE]: "Incomplete Checklist",
        [TranslationModals.AUDIT_CHECKLIST_MODAL_DESCRIPTION]:
            "All items will be automatically marked as 'Not Applicable' for this checklist.",
        [TranslationModals.AUDIT_CHECKLIST_FAILED_COMPLETE]: "No se ha podido completar el checklist",
        [TranslationModals.AUDIT_CHECKLIST_COMPLETED]: "Checklist completed.",
        [TranslationKeys.AUDIT_STATUS]: "Status",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED]: "Completed",
        [TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS]: "Pending",
        [TranslationKeys.AUDIT_ID]: "Id",
        [TranslationKeys.AUDIT_OPENED_BY]: "Auditor",
        [TranslationKeys.AUDIT_OPENED_DATE]: "Opened Date",
        [TranslationKeys.AUDIT_CLOSED_BY]: "Responsible Closure",
        [TranslationKeys.AUDIT_CLOSED_DATE]: "Closed Date",
        [TranslationKeys.AUDIT_CLOSED]: "Closed",
        [TranslationKeys.AUDIT_OPEN]: "Open",
        [TranslationKeys.AUDITS]: "Audits",
        [TranslationKeys.AUDIT_OPEN_TAB]: "Open",
        [TranslationKeys.AUDIT_ALL_CHECKLIST_REQUIRED]: "You have to complete all checklists",
        [TranslationKeys.AUDIT_FAILED_COMPLETE]: "Failed to Complete the audit",
        [TranslationKeys.AUDIT_SUCCESS_COMPLETE]: "Audit completed",
        [TranslationKeys.AUDIT_FINISH]: "Finish Audit",
        [TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_TITLE]: "Finish Audit",
        [TranslationKeys.AUDIT_FINISH_CONFIRM_MODAL_SUBTITLE]:
            "Once the audit has been completed, it cannot be modified. Make sure that all checklists and non-conformities have been filled in correctly.",
        [TranslationKeys.AUDIT_OPEN_DAY]: "Open on {0}h",
        [TranslationKeys.AUDIT_CLOSED_DAY]: "Closed on {0}h",
        [IssueTranslation.CREATING_AUDIT_TITLE_TOAST]: "Saving Audit...",
        [IssueTranslation.CREATING_AUDIT_DESCRIPTION_TOAST]:
            "The audit is being saved and the issue are being generated.",
        [IssueTranslation.DELETE_NON_CONFORMITY_TITLE]: "Eliminate Non-Conformity",
        [IssueTranslation.DELETE_NON_CONFORMITY_DESCRIPTION]:
            "The Non-Conformity associated with this checklist will be removed. If this Non-Conformity has a Task associated it will be deleted too. This action is irreversible.",
        /* --------------------AUDIT END-------------------- */

        /* --------------------MEASUREMENT UNIT START-------------------- */
        [TranslationKeys.ADD_MEASUREMENT_UNIT]: "Add New Measurement Unit",
        [TranslationKeys.SAVE_MEASUREMENT_UNIT_TITLE]: "Save Measurement Unit",
        [TranslationKeys.EDIT_MEASUREMENT_UNIT_TITLE]: "Edit Measurement Unit",
        [TranslationErrors.MEASUREMENT_UNIT_CANNOT_BE_NULL]: "Measurement Unit cannot be empty.",
        [TranslationModals.MEASUREMENT_UNIT_SAVED_TOAST]: "Measurement Unit Saved Successfully",
        [TranslationModals.MEASUREMENT_UNIT_EDITED_TOAST]: "Measurement Unit Edited Successfully",
        [TranslationModals.MEASUREMENT_UNIT_DELETE_TITLE]: "Delete Measurement Unit",
        [TranslationModals.MEASUREMENT_UNIT_DELETE_DESCRIPTION]:
            "You are going to Delete the Measurement Unit {0}, are you sure?",
        [TranslationModals.MEASUREMENT_UNIT_DELETED_TOAST]: "Measurement Unit Deleted Successfully",
        [TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_TOAST_TITLE]: "Cannot be Deleted",
        [TranslationModals.CANNOT_DELETE_MEASUREMENT_UNIT_IN_USE]:
            "To delete this Measurement Unit, you must unlink it from all Assets. Alternatively, you can rename it.",
        /* --------------------MEASUREMENT UNIT END-------------------- */

        /* --------------------ALL THING END-------------------- */
        [TranslationKeys.ALL_TASKS]: "All Tasks",
        [TranslationKeys.ALL_QRS]: "Todos los QRs",
        [TranslationKeys.ALL_PLANS]: "All Plans",
        [TranslationKeys.ALL_COMPANIES]: "All {0}",
        /* --------------------ALL THING END-------------------- */

        /* --------------------FAQS START-------------------- */
        [TranslationFAQs.FAQ_1_TITLE]: "What should I do if I forget my password?",
        [TranslationFAQs.FAQ_1_DESCRIPTION]:
            "If you forget your password, click on 'Forgot your password?' on the login screen. Enter your email address and we will send you a link to reset your password.",
        [TranslationFAQs.FAQ_2_TITLE]: "What should I do if the app takes a long time to load my task list?",
        [TranslationFAQs.FAQ_2_DESCRIPTION]:
            "Sometimes the server may be overloaded with requests. Try closing and reopening the app. If the problem persists, contact the app administrator.",
        [TranslationFAQs.FAQ_3_TITLE]: "How do I contact technical support?",
        [TranslationFAQs.FAQ_3_DESCRIPTION]:
            "If you need additional help, you can contact the technical support team by sending an email to soporte@4link.app.",
        /* --------------------FAQS END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_TITLE]: "Explanation of the issue",
        [IssueTranslation.ISSUE_BODY_CHECKLIST_ITEM_TITLE]: "Ckecklist Point causing Non-Conformity",
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_NAME]: "Asset Name",
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_RANGE_REGISTER_NAME]: "Registration with Range",
        [IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_RANGE]: "Expected Range",
        [IssueTranslation.ISSUE_BODY_RESPONSIBLE_TITLE]: "Notified Responsibles",
        [IssueTranslation.ISSUE_BODY_ATTACHMENT_TITLE]: "Attached Files",
        [IssueTranslation.ISSUE_BODY_ATTACHMENT_ADD_DOCUMENT]: "Add Files",
        [IssueTranslation.ISSUE_HISTORY_TITLE]: "History",
        [IssueTranslation.ISSUE_HISTORY_OPEN]: "Opened on {0}",
        [IssueTranslation.ISSUE_HISTORY_CLOSE]: "Closed on {0}",
        [IssueTranslation.ISSUE_HISTORY_REOPEN_ISSUE]: "Reopen Issue",
        [IssueTranslation.ISSUE_HISTORY_CLOSE_ISSUE]: "Close Issue",
        [IssueTranslation.ISSUE_HISTORY_COMMENT_PLACEHOLDER]: "Explain Why...",
        [IssueTranslation.ISSUE_TITLE_AUDIT]: "Audit Issue #",
        [IssueTranslation.ISSUE_TITLE_GENERIC]: "Generic Issue #",
        [IssueTranslation.ISSUE_TITLE_TASK]: "Task Issue #",
        [IssueTranslation.ISSUE_TITLE_ASSET]: "Issue #{0} - Asset #{1}",
        [IssueTranslation.ISSUE_BODY_GO_TO_TASK]: "View task details",
        [IssueTranslation.ISSUE_SAVE_CONFIRM_BUTTON]: "Save Issue",
        [IssueTranslation.ISSUE_MODAL_AUDIT_CREATE_TASK]: "Create Task (Optional)",
        [IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_TITLE]: "Delete Non-Conformity Task",
        [IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_DESCRIPTION]:
            "If you delete this Non-Conformity Task, the associated Non-Conformity will also be deleted. This action is irreversible.",
        [IssueTranslation.ISSUE_CLASSIFICATION]: "Classification",
        [IssueTranslation.DELETE_ISSUE_CLASSIFICATION_TITLE]: "Delete Classification",
        [IssueTranslation.DELETE_ISSUE_CLASSIFICATION_DESCRIPTION]:
            "You are going to remove the {0} Classification. This is an irreversible action. Are you sure?",
        [IssueTranslation.ADD_NEW_ISSUE_CLASSIFICATION]: "Add New Classification",
        [IssueTranslation.ERROR_DELETING_ISSUE_CLASSIFICATION]: "Error Deleting a Classification",
        [IssueTranslation.NEW_ISSUE_CLASSIFICATION]: "New Classification",
        [IssueTranslation.EDIT_ISSUE_CLASSIFICATION]: "Edit Classification",

        /* --------------------ISSUE END-------------------- */
        /* --------------------AUDIT GRID START-------------------- */
        [AuditGridTranslation.EMPTY_LIST_OPEN]: "There Are No Open Audits",
        [AuditGridTranslation.EMPTY_LIST_CLOSED]: "There Are No Closed Audits",
        /* --------------------AUDIT GRID END-------------------- */

        /* -------------------ASSET TYPES START------------------ */
        [AssetTypeTranslations.OTHER_OPTION]: "Other",
        [AssetTypeTranslations.FRIDGE]: "Fridge",
        [AssetTypeTranslations.FREEZER]: "Freezer",
        [AssetTypeTranslations.SWIMMING_POOL]: "Swimming Pool",
        [AssetTypeTranslations.ELEVATOR]: "Elevator",
        /* -------------------ASSET TYPES END------------------ */

        /* -------------------ASSET DATA TYPES START------------------ */
        [AssetDataTypeTranslations.TEMPERATURE_OPTION]: "Temperature",
        [AssetDataTypeTranslations.PH]: "PH",
        /* -------------------ASSET DATA TYPES END------------------ */

        /* --------------------ASSET START-------------------- */
        [AssetTranslations.ASSET_INFO_TITLE]: "Information",
        [AssetTranslations.IS_ASSET_NOT_WORKING]: "¿Is Asset Not Working?",
        [AssetTranslations.ASSET_NOT_WORKING_QUESTION]: "Not Working?",
        [AssetTranslations.CANNOT_BE_REPAIRED]: "Cannot Register Repair.",
        [AssetTranslations.ASSET_REPARATION_TITLE]: "Asset Repair",
        [AssetTranslations.ASSET_REPARATION_DESCRIPTION]:
            "You are about to confirm this asset has been repaired. Do you want to proceed and register this repair?",
        [AssetTranslations.ASSET_NOT_WORKING]: "Broken Down",
        [AssetTranslations.ASSET_REPAIRED]: "Operational",
        [AssetTranslations.ASSET_LINKED_TO_A_SENSOR]: "Linked to Sensor",
        [AssetTranslations.ASSET_CONSECUTIVE_ISSUES_LABEL]: "Consec. Issues",
        [AssetTranslations.WITHOUT_SENSOR]: "Without Sensor",
        [AssetTranslations.WITH_SENSOR]: "With Sensor",
        [AssetTranslations.DEACTIVATED]: "Deactivated",
        [AssetTranslations.ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL]: "View Last Records",
        [AssetTranslations.ASSET_SENSOR_TITLE]: "Sensor Information",
        [AssetTranslations.ASSET_SENSOR_NO_DATA]: "The sensor has not recorded any data.",
        [AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_TITLE]: "Detected Sensor with Registries",
        [AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_DESCRIPTION]:
            "When saving the Sensor Code {0}, {1} previous records will be linked to Asset {2}. This action is irreversible. Are you sure?",
        [AssetTranslations.ASSET_NOT_FOUND]: "Asset Not Found",
        /* --------------------ASSET END-------------------- */

        /* --------------------ANALIST START-------------------- */
        [AnalistTranslation.TOTAL_TASKS_NUMBER]: "Total Number of Tasks",
        /* --------------------ANALIST END-------------------- */

        /* --------------------ISSUE START-------------------- */
        [IssueTranslation.ISSUE_MODAL_AUDIT_TITLE]: "Non-Conformity",
        [IssueTranslation.ISSUE_MODAL_AUDIT_NON_CONFORMITY]: "Critical Issue",
        [IssueTranslation.AUDIT_CODE]: "Audit Code",
        /* --------------------ISSUE END-------------------- */

        /* --------------------AUDIT START-------------------- */
        [AuditTranslation.AUDIT_TITLE]: "Audit",
        [AuditTranslation.AUDIT_NON_CONFORMITY]: "Non-Conformities",
        [AuditTranslation.AUDIT_REPORT]: "Report",
        [AuditTranslation.AUDIT_FINISH_REVIEW]: "Finish Review",
        [AuditTranslation.AUDIT_CLOSE_AUDIT]: "Close Audit",
        [AuditTranslation.AUDIT_IN_PROGRESS]: "In Progress",
        [AuditTranslation.AUDIT_IN_REVIEW]: "In Review",
        [AuditTranslation.AUDIT_REVIEWED]: "Reviewed",
        [AuditTranslation.AUDIT_REVIEWED_SINGULAR]: "Reviewed",
        [AuditTranslation.AUDIT_IN_PROGRESS_TAB]: "Opened",
        [AuditTranslation.AUDIT_CLOSED_TAB]: "Closed",
        [AuditTranslation.AUDIT_FAILED_CLOSE]: "Failed Clossing the Audit",
        [AuditTranslation.AUDIT_FAILED_FINISH_REVIEW]: "Review could not be completed",
        [AuditTranslation.AUDIT_FINISH_MODAL_TITLE]: "Finish Review",
        [AuditTranslation.AUDIT_FINISH_MODAL_DESCRIPTION]:
            "Once Non-Conformities have been reviewed and closed, they cannot be modified until the Auditor's supervision. Ensure that all issues have been closed correctly.",
        [AuditTranslation.AUDIT_CLOSE_MODAL_TITLE]: "Approve Review",
        [AuditTranslation.AUDIT_CLOSE_MODAL_DESCRIPTION]:
            "Once the review has been approved, the audit cannot be modified. This action is irreversible.",
        [AuditTranslation.AUDIT_ISSUE_MODAL_TITLE]: "Review Non-Conformity",
        [AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY_BUTTON]: "Delete Non-Conformity",
        [AuditTranslation.AUDIT_ISSUE_DELETE_NON_CONFORMITY]:
            "The item associated with this non-conformity will be marked as OK.",
        [AuditTranslation.AUDIT_ISSUE_PLACEHOLDER]: "Congratulations! This audit has no non-conformities.",
        [AuditTranslation.AUDIT_ALL_ISSUES_HAS_TO_BE_CLOSED]: "All issues should be closed",
        [AuditTranslation.AUDIT_IN_PROGRESS_FILTER_BUTTON]: "In Progress",
        [AuditTranslation.AUDIT_DONE_FILTER_BUTTON]: "Done",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DEFAULT]: "Auditor",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_ASC]: "Auditor Asc.",
        [AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DESC]: "Auditor Desc.",
        /* --------------------AUDIT END-------------------- */

        /* --------------------CLOSE COMPANY START-------------------- */
        [CloseCompanyTranslations.CLOSE_COMPANIES_MODAL_TITLE]: "Status of {0} Updated",
        [CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_TITLE]: "New Openings",
        [CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_TITLE]: "Closed",
        [CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_LABEL]: "Closed:",
        [CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_LABEL]: "New Opening",
        [CloseCompanyTranslations.ADD_NEW_CLOSE_DATE_RANGE]: "Add Closing Dates",
        [CloseCompanyTranslations.OVERLAPING_DATES_NOT_PERMITTED]: "Ranges are overlaping.",
        [CloseCompanyTranslations.CLOSE_COMPANY]: "Do you want to Close the {0}?",
        [CloseCompanyTranslations.CLOSE_COMPANY_F]: "Do you want to Close the {0}?",
        [CloseCompanyTranslations.COMPANY_CLOSED_DAYS]: "Closed ({1})",
        /* --------------------CLOSE COMPANY END-------------------- */

        /* --------------------RANKING START-------------------- */
        [AnalistRankingTranslations.JOB_DESCRIPTION_LABEL]: "Category",
        /* --------------------RANKING END-------------------- */

        /* --------------------TASK INSTANCE START-------------------- */
        [TaskInstanceTranslations.CANNOT_REOPEN_TASK_INSTANCE]:
            "Cannot Reopen a Task Completed in a Different Day than Today",
        /* --------------------TASK INSTANCE END-------------------- */

        /* --------------------TASK START-------------------- */
        [TaskTranslations.IS_TASK_SPORADIC]: "Is it a Task without Defined Date?",
        [TaskTranslations.TASK_TYPE_NORMAL]: "Normal",
        [TaskTranslations.TASK_TYPE_AUDIT]: "Audit",
        [TaskTranslations.TASK_TYPE_TITLE]: "Task Type",
        [TaskTranslations.TASK_ASSIGNED_TITLE]: "Assignment",
        [TaskTranslations.TASK_DATE_TITLE]: "Date",
        [TaskTranslations.TASK_ATTATHCMENTS_TITLE]: "Attachment",
        [TaskTranslations.TASK_APPCC_LABEL]: "HACCP Plan",
        [TaskTranslations.TASK_APPCC_PLACEHOLDER]: "Select a Plan",
        [TaskTranslations.TASK_ALL]: "All",
        [TaskTranslations.TASK_ALL_COLUMN]: "Assignment",
        [TaskTranslations.TASK_SAVE_CONFIRM_BUTTON]: "Save Task",
        [TaskTranslations.CREATE_TASK_LABEL]: "Create Task",
        [TaskTranslations.TASK_TEMPORALITY_PERIODICAL]: "Periodical",
        [TaskTranslations.TASK_TEMPORALITY_ONE_DAY]: "Only Once",
        [TaskTranslations.TASK_TEMPORALITY_SPORADIC]: "No Defined Date",
        [TaskTranslations.TASK_TEMPORALITY_RANGE_DATES]: "With Date Range",
        [TaskTranslations.ADD_TASK_TEMPORALITY_RANGE_DATES]: "Add New Date Range",
        [TaskTranslations.DATE_OVERLAPING]: "The Dates Overlaps",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_DAY_SINGULAR]: "{0} time per day",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_WEEK_SINGULAR]: "{0} time per week",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_MONTH_SINGULAR]: "{0} time per month",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_YEAR_SINGULAR]: "{0} time per year",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_DAY_PLURAL]: "{0} times per day",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_WEEK_PLURAL]: "{0} times per week",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_MONTH_PLURAL]: "{0} times per month",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_YEAR_PLURAL]: "{0} times per year",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_NEVER_ENDS]: "The task never ends",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_SPECIFIC_DAY]: "The task ends on {0} {1}, {2}",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_TIMES]: "The task ends after {0} times",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_HOURS_TITLE]: "Time at which the task starts",
        [TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_DATE_RANGE_TITLE]: "Selected Date Ranges",
        /* --------------------TASK END-------------------- */

        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM START-------------------- */
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_ID_LABEL]: "Id",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_NAME_LABEL]: "Description",
        [BaseAuditGroupCheckListItemTranslations.TYPE_LABEL]: "Classification",
        [BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL]: "Default Ponderation Level",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_GRID_TITLE]: "Base Checklist Items",
        [BaseAuditGroupCheckListItemTranslations.CELL_BUTTONS_LABEL]: "Actions",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_TITLE]: "The Base Checklist Item {0} will be Deleted",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_DESCRIPTION]:
            "This action involves deleting this checklist item from all audit templates and cannot be undone. Are you sure?",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_DELETE_SUCCESS_TOAST]:
            "The Base Checklist Item has been Successfully Deleted",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_SAVE]:
            "The Base Checklist Item has been Successfully Created",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SUCCESS_EDIT]:
            "The Base Checklist Item has been Successfully Edited",
        [BaseAuditGroupCheckListItemTranslations.NAME_IS_REQUIRED]: "The Name cannot be empty.",
        [BaseAuditGroupCheckListItemTranslations.FK_BASEAUDITGROUPCHECKLISTITEMTYPE_IS_REQUIRED]:
            "Select the Checklist Item Clasification.",
        [BaseAuditGroupCheckListItemTranslations.PONDERATIONLEVEL_IS_REQUIRED]: "Ponderation Level is required.",
        [BaseAuditGroupCheckListItemTranslations.PONDERATION_RANGE_ERROR]: "The Weighting must be between 0 and 10.",
        [BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL]: "Items",
        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM END-------------------- */

        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM TYPE START-------------------- */
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_ADD_TITLE]:
            "Add New Checklist Item Clasification",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_SUCCESS_TOAST]:
            "The Checklist Item Clasification has been Successfully Deleted",
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL]: "Name",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_SAVE_SUCCESS_TOAST]:
            "The Checklist Item Clasification has been Successfully Created",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_EDIT_SUCCESS_TOAST]:
            "The Checklist Item Clasification has been Successfully Edited",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_TITLE]:
            "The Checklist Item Clasification {0} Will Be Deleted",
        [TranslationModals.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_DELETE_DESCRIPTION]:
            "This action involves deleting this checklist item Clasification from all audit templates and cannot be undone. Are you sure?",
        [TranslationModals.CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE]:
            "To delete the Checklist Item Clasification, it must first be unassigned from all Checklist Items. Alternatively, you can edit the name.",
        [TranslationModals.CANNOT_DELETE_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_IN_USE_TITLE_TOAST]:
            "Cannot Delete Checklist Item Clasification",
        [TranslationModals.BASE_TASK_COMPLETE_ALL_TASK]:
            "Complete the following fields to start the creation of the task:",
        [TranslationModals.BASE_TASK_COMPLETE_ALL_REGISTRY]:
            "Complete the following fields to start the creation of the registry",
        [BaseAuditGroupCheckListItemTypeTranslations.NAME_IS_REQUIRED]: "The Name cannot be empty.",
        [BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_ALL_TYPES]: "All",
        /* --------------------BASE AUDIT GROUP CHECKLIST ITEM TYPE END-------------------- */

        /* --------------------QR SCANNER START-------------------- */
        [ScanQRTranslations.QR_SCANNER_SCANNING]: "Scanning",
        [ScanQRTranslations.QR_SCANNER_START_SCANNING]: "Start scanning",
        [ScanQRTranslations.QR_SCANNER_STOP_SCANNING]: "Stop scanning",
        [ScanQRTranslations.QR_SCANNER_MODAL_TITLE]: "Scan the QR or enter it manually",
        [ScanQRTranslations.QR_SCANNER_MODAL_ALTERNATIVE_TITLE]: "Write the QR code manually",
        [ScanQRTranslations.QR_SCANNER_MODAL_SCAN]: "SCAN",
        [ScanQRTranslations.QR_SCANNER_MODAL_MANUAL_SCAN]: "ENTER",
        [ScanQRTranslations.QR_SCANNER_MODAL_CLOSE_SCANNER]: "Close Scanner",
        /* --------------------QR SCANNER END-------------------- */

        /* -------------------- AUDIT GROUP CHECKLIST ITEM START-------------------- */
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL]: "Weighting",
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_TITLE_LABEL]:
            "Changes Detected",
        [AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_DESCRIPTION_LABEL]:
            "You have made changes and are about to discard them, are you sure?",
        /* -------------------- AUDIT GROUP CHECKLIST ITEM END-------------------- */

        /* -------------------- AUDIT INSTANCE START-------------------- */
        [AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_ERROR]: "There was an error fetching the data.",
        [AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_BARCHART_TITLE]: "Audit History",
        [AuditInstanceTranslations.EMPTY_BAR_CHART_TITLE]: "No Audit History",
        [AuditInstanceTranslations.EMPTY_BAR_CHART_DESCRIPTION]:
            "The Audit History does not exist or contains Audits that do not apply.",

        /* -------------------- AUDIT INSTANCE END-------------------- */

        /* -------------------- AUDIT GROUP CHECKLIST INSTANCE START-------------------- */
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NAME]: "Name",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE]: "No Results",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION]:
            "There is no Audit Report",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION_FILTER]:
            "There are no Items from the selected Classification",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_GRADE]: "Grade",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_VALORATION]: "Valoration",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE]: "Not Applicable",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT]: "Non-Conformities",
        [AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT_SMALL]: "NC",
        /* -------------------- AUDIT GROUP CHECKLIST INSTANCE END-------------------- */

        /* -------------------- REVIEW TASK START-------------------- */
        [ReviewTaskTranslations.ALL_DATA_TO_REPORT]: "All",
        [ReviewTaskTranslations.NO_DATA_TO_REPORT]: "Only tasks without data",
        [ReviewTaskTranslations.WITH_DATA_TO_REPORT]: "Only tasks with data",
        [ReviewTaskTranslations.WITH_CHECKLIST_TO_REPORT]: "Only tasks with checklist",
        [ReviewTaskTranslations.WITH_ASSET_TO_REPORT]: "Only tasks with assets",
        [ReviewTaskTranslations.LINKED_DATA]: "Linked Data",
        /* -------------------- REVIEW TASK END-------------------- */

        /* -------------------- New User Tabs START-------------------- */
        [NewUserTabsTranslation.RESET_PASSWORD_USER]: "Reset Password",
        [NewUserTabsTranslation.USER_DATA_TITLE]: "User Data",
        [NewUserTabsTranslation.USER_PERMISSIONS_TITLE]: "Permissions",
        [NewUserTabsTranslation.ASSIGNED_COMPANIES_TITLE]: "Assigned {0}",
        /* -------------------- New User Tabs END-------------------- */

        /* --------------------OnBoarding Steps START-------------------- */
        [OnBoardingStepsTranslation.ONBOARDING_INITIAL_CONFIG]: "Initial Configuration",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_COMPANY]: "Creation of {0} and Teams",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_ROLES]: "Permission Setup",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_USERS]: "User Creation",
        [OnBoardingStepsTranslation.ONBOARDING_ASSIGN_USERS_TO_TEAMS]: "Assign Users to Teams",
        [OnBoardingStepsTranslation.ONBOARDING_APPCC_CONFIG]: "APPCC Module Configuration",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_PLANS]: "Plan Creation",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_CORRECTIVE_MEASURES]: "Creation of Corrective Measures",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_ASSETS]: "Asset Registration",
        [OnBoardingStepsTranslation.ONBOARDING_BASETASK_CONFIG]: "Base Task Configuration",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_BASETASK]: "Creation of Operational Base Task",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_APPCC_BASETASK]: "Creation of APPCC Base Records",
        [OnBoardingStepsTranslation.ONBOARDING_TASK_CONFIG]: "Task Assignment to Each {0}",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_QR]: "QR Code Creation",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_TASK]: "Creation of Operational Tasks",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_APPCC_TASK]: "Creation of APPCC Records",
        [OnBoardingStepsTranslation.ONBOARDING_AUDIT_CONFIG]: "Audit Configuration",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_BASE_AUDIT_ITEMS]: "Creation of Base Audit Items",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_AUDIT_TEMPLATE]: "Creation of Audit Templates",
        [OnBoardingStepsTranslation.ONBOARDING_CREATE_DISTRIBUTION_LIST]: "Creation of Distribution List",
        [OnBoardingStepsTranslation.ONBOARDING_TITLE]: "Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_TITLE]: "Unlock step {0}",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_TITLE]: "Complete step {0}",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK]: "Unlock",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE]: "Complete",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_STEP]: "Send",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP]: "Complete",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_STEP]: "Aprove",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_STEP]: "Unlock",
        [OnBoardingStepsTranslation.ONBOARDING_UNLOCK_DESCRIPTION]:
            "Step {0} will be unlocked for the administrator to continue configuring 4Link",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_DESCRIPTION]:
            "The step will move to review. We will send an email to our reviewer to conduct the corresponding evaluation.",
        [OnBoardingStepsTranslation.ONBOARDING_COMPLETE_SUBSTEP_DESCRIPTION]:
            "Are you sure you want to complete this sub-step? By doing so, you will unlock the next one, and this step will be marked as Completed.",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_CORRECT]: "Correct",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_INCORRECT]: "Incorrect",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW]: "Send Review",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_TITLE]: "Send Review of {0}",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_COMMENT]: "Comment",
        [OnBoardingStepsTranslation.ONBOARDING_REVIEW_SEND_REVIEW_OPTION]: "What did you think of this step?",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_BLOCKED]:
            "The step is blocked. To unlock it, the reviewer must mark the previous step as valid.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_DONE]: "The step has been completed.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_IN_PROGRESS]: "The step is in progress.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_STEP_REVIEW]:
            "The step is under review. We will notify you by email when the review is complete and the step status has changed.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_BLOCKED]:
            "The sub-step is blocked. To unlock it, you must complete the previous sub-step.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_DONE]: "The sub-step has been completed.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_IN_PROGRESS]: "The sub-step is in progress.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_MODAL_SUBSTEP_REVIEW]:
            "The sub-step is under review. We will notify you when the review is complete.",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_BLOCKED]: "Blocked",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_IN_PROGRESS]: "In Progress",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_REVIEW]: "Under Review",
        [OnBoardingStepsTranslation.ONBOARDING_STATUS_DONE]: "Completed",
        [OnBoardingStepsTranslation.ONBOARDING_FINISH_TITLE]: "Finish Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_FINISH_DESCRIPTION]:
            "The step will move to review. We will send an email to our reviewer to conduct the corresponding evaluation.",
        [OnBoardingStepsTranslation.ONBOARDING_SENDING_EMAIL_TITLE]: "Sendign Email",
        [OnBoardingStepsTranslation.ONBOARDING_EMAIL_SENDED_TITLE]: "Email Sended Correctly",
        [OnBoardingStepsTranslation.ONBOARDING_RESET]: "Reset",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_TITLE]: "Reset Onboarding",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_DESCRIPTION]:
            "The Onboarding will be reset to the initial state. This action is irreversible.",
        [OnBoardingStepsTranslation.ONBOARDING_RESET_TOAST_SUCCESS_TITLE]: "Onboarding Restarted",

        [TranslationErrors.ONBOARDING_SUBSTEP_INSTANCE_NOT_FOUND]: "Substep not found",
        [TranslationErrors.ONBOARDING_SUBSTEP_INSTANCE_INVALID_STATUS]: "The status is invalid",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_NOT_FOUND]: "Step not found",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_INVALID_STATUS]: "The status is invalid",
        [TranslationErrors.ONBOARDING_STEP_INSTANCE_COMMENT_CANNOT_BE_EMPTY]: "Comment is required",
        [TranslationErrors.ONBOARDING_SUBSTEPS_NOT_COMPLETED]: "All substeps must be completed to complete the step",
        [TranslationErrors.ONBOARDING_FAILED_FETCH]: "Failed to fetch steps",
        [TranslationErrors.ONBOARDING_FAILED_COMPLETE]: "Failed to Complete",
        [TranslationErrors.ONBOARDING_FAILED_REVIEW]: "Failed to Review",
        [TranslationErrors.ONBOARDING_FAILED_SEND]: "Failed to Send",
        [TranslationErrors.ONBOARDING_FAILED_IN_PROGRESS]: "Failed to Unlock",

        [TranslationErrors.SENSOR_NOT_EXIST]: "The Sensor Not Exist",
        /* --------------------OnBoarding Steps END-------------------- */

        /* -------------------- AUTOMATIC RECORDS START-------------------- */
        [AutomaticRecordTranslations.AUTOMATIC_RECORD_ASSET_LABEL]: "Asset",
        [AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL]: "Sensor Code",
        [AutomaticRecordTranslations.GATEWAY_SERIAL_NUMBER_SMALL_LABEL]: "Gateway No.",
        [AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_SMALL_LABEL]: "Sensor No.",
        [AutomaticRecordTranslations.TEMP_LABEL]: "Temperature",
        [AutomaticRecordTranslations.BATTERY_LEVEL_LABEL]: "Battery",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL]: "Signal",
        [AutomaticRecordTranslations.DATE_TIME_ENTRY_LABEL]: "Date",
        [AutomaticRecordTranslations.BATTERY_LEVEL_LOW_LABEL]: "Low",
        [AutomaticRecordTranslations.BATTERY_LEVEL_MEDIUM_LABEL]: "Medium",
        [AutomaticRecordTranslations.BATTERY_LEVEL_HIGH_LABEL]: "High",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_LOW_LABEL]: "Low",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_MEDIUM_LABEL]: "Medium",
        [AutomaticRecordTranslations.SIGNAL_LEVEL_HIGH_LABEL]: "High",
        [AutomaticRecordTranslations.REGISTRY_NUMBER]: "Registries",
        /* -------------------- AUTOMATIC RECORDS END-------------------- */

        /* -------------------- AUDIT FILTER START-------------------- */
        [AuditFilterTranslations.AUDIT_FILTER_START_DATE]: "Opening Date",
        [AuditFilterTranslations.AUDIT_FILTER_CLOSED_DATE]: "Closing Date",
        /* -------------------- AUDIT FILTER END-------------------- */

        /* -------------------- CHAT BOT GPT START-------------------- */
        [ChatBotGPTTranslations.CHAT_BOT_GTP_LOADING_MESSAGE]: "Loading...",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_ERROR_MESSAGE]: "Something went wrong",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_INPUT_PLACEHOLDER]: "Write your message here",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_IA_PRESENTATION_MESSAGE]:
            "Hi, I'm LaIA, your personal assistant. How can I help you?",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_USER_OPTION]: "Users",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_TASK_OPTION]: "Tasks",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_APPCC_OPTION]: "APPCC Plan",
        [ChatBotGPTTranslations.CHAT_BOT_GTP_WORKER_TEXT]: "Please note that I am an user with employee role.",
        /* -------------------- CHAT BOT GPT END-------------------- */

        /* -------------------- COMPANY START-------------------- */
        [CompanyTranslations.EDIT_COMPANY_DETAIL_TITLE]: "{0} Data",
        [CompanyTranslations.EDIT_COMPANY_DETAIL_TITLE_F]: "{0} Data",
        [CompanyTranslations.EDIT_COMPANY_OPTIONS_TITLE]: "Close {0}",
        [CompanyTranslations.COMPANY_STATUS]: "Status",
        [TranslationErrors.MAX_EMAIL_LENGTH_REACHED]: "There are too many contact emails.",
        [TranslationErrors.INVALID_EMAIL]: "There is an email with an incorrect format.",
        /* -------------------- COMPANY END-------------------- */

        /* -------------------- ROLE COLLAPSABLE START-------------------- */
        [RoleCollapsableTranslations.ROLE_COLLAPSABLE_MARK_ALL]: "Select all",
        /* -------------------- ROLE COLLAPSABLE END-------------------- */

        /* -------------------- SENSOR MODAL START-------------------- */
        [SensorModalTranslations.SENSOR_NOT_LINKED_TITLE]: "New Sensor ({0})",
        [SensorModalTranslations.SENSOR_NOT_LINKED_PLACEHOLDER_TITLE]: "Sensor not found",
        [SensorModalTranslations.SENSOR_NOT_LINKED_DESCRIPTION]: "The sensor code is not registered in the system.",
        [SensorModalTranslations.SENSOR_NOT_LINKED_CREATE_ASSET]: "Register",
        [SensorModalTranslations.SENSOR_NOT_LINKED_REPLACE]: "Replace with an Existing one",
        [SensorModalTranslations.SENSOR_TOAST_LOADING]: "Retrieving the Sensor",
        [SensorModalTranslations.SENSOR_LINKED_TITLE]: "Sensor ({0})",
        [SensorModalTranslations.SENSOR_LINKED_REPLACE]: "Replace",
        [SensorModalTranslations.SENSOR_LINKED_DELETE_ASSET]: "Deactivate",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_NAME]: "Name",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_DESCRIPTION]: "Descriptions",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_TYPE]: "Asset Type",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_PLAN]: "HACCP Name",
        [SensorModalTranslations.SENSOR_LINKED_ASSET_NOT_WORKING]: "Is Asset Not Working?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_TITLE]: "Sensor Exchange",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_TITLE]: "New Sensor",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_DESCRIPTION]:
            "Both scan codes are linked to an asset. The sensors will be exchanged with each other. Are you sure?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_DESCRIPTION]:
            "The second sensor ({0}) will be linked to the Asset of the first sensor. The first sensor will be unlinked. Are you sure?",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON]: "Exchange",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_CONFIRM_BUTTON]: "Link",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE]: "Exchanged",
        [SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_SUCCESS_REPLACE_TITLE]: "Exchanged",
        [SensorModalTranslations.SENSOR_SAME_QR_ERROR_TITLE]: "Sensors have to be different",
        [SensorModalTranslations.SENSOR_QR_SCANNER_TITLE]: "Scan the Sensor or enter it manually",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_TITLE]: "Both Codes without Asset",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_DESCRIPTION]:
            "To exchange sensors, at least one must be linked to an Asset.",
        [SensorModalTranslations.SENSOR_NOT_LINKED_QR_SCANNER_TITLE]: "Scan New Sensor",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_TITLE]: "New Sensor",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_DESCRIPTION]:
            "The first sensor will be linked to the Asset of the second sensor ({0}). The second sensor will be unlinked. Are you sure?",
        [SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_LINKED_CONFIRM_BUTTON]: "Exchanged",
        /* -------------------- SENSOR MODAL END-------------------- */

        /* -------------------- EDIT IMAGE START-------------------- */
        [AriaLabels.AL_EDIT_IMAGE_RECTANGLE]: "Shape - Rectangle",
        [AriaLabels.AL_EDIT_IMAGE_CIRCLE]: "Shape - Circle",
        [AriaLabels.AL_EDIT_IMAGE_UNDO]: "Action - Delete all",
        [AriaLabels.AL_EDIT_IMAGE_MIRROR]: "Action - Mirror Mode",
        [AriaLabels.AL_EDIT_IMAGE_ROTATE]: "Action - Rotate",
        [AriaLabels.AL_EDIT_IMAGE_CROPPER]: "Action - Start to Crop",
        [AriaLabels.AL_EDIT_IMAGE_FORMS]: "Action - Drawing Shapes",
        [AriaLabels.AL_EDIT_IMAGE_DRAW]: "Action - Doodle Drawing",
        [AriaLabels.AL_EDIT_IMAGE_CROP]: "Action - Crop",
        [AriaLabels.AL_EDIT_IMAGE_CANCEL_CROP]: "Action - Don't Crop",
        /* -------------------- EDIT IMAGE END-------------------- */

        /* -------------------- AVATAR START-------------------- */
        [AriaLabels.AL_AVATAR_PROFILE_PICTURE]: "User profile picture",
        /* -------------------- AVATAR END-------------------- */

        /* -------------------- ICON SELECT START-------------------- */
        [AriaLabels.AL_CHEVRON_UP]: "Open Selector",
        [AriaLabels.AL_CHEVRON_DOWN]: "Close Selector",
        /* -------------------- ICON SELECT END-------------------- */

        /* -------------------- EMAIL DISTRIBUTION LIST START-------------------- */
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_SPORADIC_TASK]:
            "Weekly Summary of Tasks without Defined Date",
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_AUDIT_REPORT]: "Audit Report Copy",
        [EmailDistributionListTranslations.DISTRIBUTION_LIST_PATCH_NOTES]: "Patch Notes",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL]: "Functionality",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_COMPANY_LABEL]:
            "From which {0} do you want to send notifications?",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_USER_COUNT_LABEL]: "Nº Users",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE]:
            "Edit Distribution List Users",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE]:
            "Add Users to Receive Notification",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SAVE_SUCCESS]:
            "The Users of the Distribution List have been Successfully Edited",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_SUCCESS]:
            "The Distribution List have been Successfully Saved",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_TYPE_LABEL]: "Select The Distribution List",
        [TranslationErrors.EMAIL_DISTRIBUTION_LIST_NOT_FOUND]: "Distribution List Not Found",
        [TranslationErrors.EMAIL_DISTRIBUTION_LIST_ALREADY_ADDED]: "Distribution List Already Created",
        [TranslationErrors.MAX_USERIDLIST_EXCEDED]: "Distribution List Exceded Limit of 20 Users",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_TITLE_LABEL]: "Delete Distribution List",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL]:
            "You are about to delete this Distribution List, are you sure?",
        [EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SUCCESS_DELETE]:
            "The Distribution List has been successfully deleted",
        /* -------------------- EMAIL DISTRIBUTION LIST END-------------------- */

        /* -------------------- TASK PILL START-------------------- */
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES]: "{0} min late",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR]: "{0} h {1} min late",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_EARLY]: "{1} min earlier",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_EARLY]: "{0} h {1} min earlier",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_SINGULAR]:
            "1 day {0} h {1} min late",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_PLURAL]:
            "{0} days {0} h {1} min late",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_SINGULAR]:
            "1 day {0} h {1} min earlier",
        [TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_EARLY_PLURAL]:
            "{0} days {0} h {1} min earlier",
        [TaskPillTranslations.TASK_PILL_DATE_RANGE_PENDING_BADGE]: "Date Range",
        /* -------------------- TASK PILL END-------------------- */

        /* -------------------- TRANSCRIPTION START-------------------- */
        [TranscriptionTranslations.BROWSER_NOT_COMPATIBLE]: "The browser is not supported.",
        [TranscriptionTranslations.ERROR_STARTING_TRANSCRIPTING]: "Unable to start recording your voice",
        /* -------------------- TRANSCRIPTION END-------------------- */

        /* -------------------- PREVIEW CHECKLIST MODAL START-------------------- */
        [TranslationModals.PREVIEW_CHECKLIST_TITLE]: "Checklist Preview",
        /* -------------------- PREVIEW CHECKLIST MODAL END-------------------- */

        /* -------------------- TASK HISTORY START-------------------- */
        [TaskHistoryTranslations.TASK_HISTORY_LIST]: "List",
        [TaskHistoryTranslations.TASK_HISTORY_TABLE]: "Table",
        /* -------------------- TASK HISTORY END-------------------- */

        /* -------------------- STAR PERCENTAGE START-------------------- */
        [AriaLabels.AL_STAR_PERCENTAGE_OVERLAY]: "Overlay",
        /* -------------------- STAR PERCENTAGE END-------------------- */

        /* -------------------- STAR VALORATION START-------------------- */
        [IconTitleTranslations.IT_STAR]: "Star {0}",
        /* -------------------- STAR VALORATION END-------------------- */

        /* -------------------- QR LABEL START-------------------- */
        [AlertModalTranslations.QR_LABEL]: "Qr",
        [AlertModalTranslations.SELECT_HOUR_QR]: "Select a Date",
        /* -------------------- QR LABEL END-------------------- */

        /* -------------------- DRAG FILE START-------------------- */
        [DragFileTranslations.INVALID_FILE_TYPE]: "Invalid File Format",
        /* -------------------- DRAG FILE END-------------------- */

        /* -------------------- PATCH NOTES START-------------------- */
        [PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL]: "Version No.",
        [PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL]: "Summary Title",
        [PatchNotesTranslations.PATCH_NOTES_PUBLISH_DATE_LABEL]: "Publish Date",
        [PatchNotesTranslations.PATCH_NOTES_CUSTOMERS_COUNT_LABEL]: "Clients",
        [PatchNotesTranslations.PATCH_NOTE_DELETE_TITLE_LABEL]: "Delete Patch Notes for Version {0}",
        [PatchNotesTranslations.PATCH_NOTE_DELETE_DESCRIPTION_LABEL]: "This action is irreversible, are you sure?",
        [PatchNotesTranslations.PATCH_NOTES_CREATE_MODAL_TITLE]: "New Patch Notes Version",
        [PatchNotesTranslations.PATCH_NOTE_CREATED_SUCCESSFULLY]: "Patch Notes Version Created Successfully",
        [PatchNotesTranslations.PATCH_NOTES_EDIT_MODAL_TITLE]: "Edit Patch Notes Version",
        [PatchNotesTranslations.PATCH_NOTE_EDITED_SUCCESSFULLY]: "Patch Notes Version Edited Successfully",
        [PatchNotesTranslations.PATCH_NOTE_CUSTOMER_INSTANCES_LABEL]: "Clients to Notify",
        [PatchNotesTranslations.PATCH_NOTES_NOTE_LABEL]: "List of Improvements and New Features",
        [PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_TITLE_LABEL]: "Changes Detected",
        [PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_DESCRIPTION_LABEL]:
            "You are about to discard the changes you made, are you sure?",
        [PatchNotesTranslations.PATCH_NOTES_SEND_SUCCESSFULLY]: "Patch Notes Sent Successfully",
        [TranslationErrors.PATCH_NOTE_VERSION_DESCRIPTION_NOT_FOUND]: "Patch Notes not found",
        [PatchNotesTranslations.PATCH_NOTE_SEND_TITLE_LABEL]: "Send Patch Notes",
        [PatchNotesTranslations.PATCH_NOTE_SEND_DESCRIPTION_LABEL]:
            "You are about to send the Patch Notes to the Clients shown below. Are you sure?",
        [PatchNotesTranslations.PATCH_NOTES_ADD_NOTE_LABEL]: "Add Improvement/New Feature",
        [PatchNotesTranslations.SEND_PATCH_NOTES_SELECTOR_USER_TYPE_LABEL]: "Select User Type",
        /* -------------------- PATCH NOTES END-------------------- */

        /* -------------------- WORKING POSITION START-------------------- */
        [WorkingPositionTranslations.WORKING_POSITION_TASK_COUNT]: "Nº Task Associated",
        [WorkingPositionTranslations.WORKING_POSITION_APPCC_COUNT]: "Nº HACCP Associated",
        /* -------------------- WORKING POSITION END-------------------- */

        /* -------------------- DEPARTMENT START-------------------- */
        [DepartmentTranslations.DEPARTMENT_DELETE_MODAL_TITLE]: "The team {0} will be deleted.",
        [DepartmentTranslations.CLOSE_DEPARTMENT]: "Close Team",
        [DepartmentTranslations.CLOSED_DEPARTMENT_SUCCESSFULLY]: "Team Closed Successfully",
        [DepartmentTranslations.CLOSE_DEPARTMENT_DATES]: "Close Date/s",
        [DepartmentTranslations.DEPARTMENT_STATUS]: "Status",
        [DepartmentTranslations.DEPARTMENTS_TITLE]: "Teams",
        [DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_TITLE]: "Open Team",
        [DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_TITLE]: "Close Team",
        [DepartmentTranslations.CLOSED_DEPARTMENT_OPEN_DESCRIPTION]: "The Team will be opened, are you sure?",
        [DepartmentTranslations.CLOSED_DEPARTMENT_CLOSED_DESCRIPTION]: "The Team will be closed, are you sure?",
        [DepartmentTranslations.OPEN_DEPARTMENT_SUCCESSFULLY]: "The Team has been opened successfully",
        [DepartmentTranslations.CLOSED_DEPARTMENT_ERROR_NO_CLOSE_DATE]: "Please provide at least a closing date.",
        [DepartmentTranslations.CLOSED_DEPARTMENT_OPEN]: "Open",
        /* -------------------- DEPARTMENT END-------------------- */

        /* -------------------- NOTIFICATION START-------------------- */
        [NotificationsTranslations.NOTIFICATION_WHICH_COMPANY_LABEL]: "{0} Filter",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_USER]: "Which Users do you want to notify?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_DEPARTMENT]: "Which Teams do you want to notify?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_COMPANY]: "Which {0} do you want to notify?",
        [NotificationsTranslations.NOTIFICATION_ASSIGN_QR]: "Which QRs do you want to notify?",
        /* -------------------- NOTIFICATION END-------------------- */

        /* -------------------- USER_TYPE START-------------------- */
        [UserTypeTranslations.WORKER]: "Worker",
        [UserTypeTranslations.SUPERVISOR]: "Supervisor",
        [UserTypeTranslations.ANALIST]: "Analist",
        [UserTypeTranslations.ADMIN]: "Administrator",
        /* -------------------- USER_TYPE END-------------------- */

        /* -------------------- PENDINGTASKSCOMPANYDEPARTMENT START -------------------- */
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_TITLE]:
            "Close {0} and/or Teams",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION]:
            "The following {0} and/or Teams have days with no tasks completed",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION_F]:
            "The following {0} and/or Teams have days with no tasks completed",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE]:
            "Yes, Close the Selected Ones",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE_F]:
            "Yes, Close the Selected Ones",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED]:
            "No, None Were Closed",
        [PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED_F]:
            "No, None Were Closed",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_CLOSED_TITLE]: "Close Selected {0} and/or Teams",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_CLOSED_DESCRIPTION]:
            "The selected {0} and/or Teams will be closed. The rest will be marked as open. This action is irreversible, are you sure?",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_CLOSED_DESCRIPTION_F]:
            "The selected {0} and/or Teams will be closed. The rest will be marked as open. This action is irreversible, are you sure?",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_TITLE]: "All Open",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_TITLE_F]: "All Open",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION]:
            "All {0} and/or Teams will be marked as open for the displayed dates. This action is irreversible, are you sure?",
        [PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION_F]:
            "All {0} and/or Teams will be marked as open for the displayed dates. This action is irreversible, are you sure?",
        [PendingTasksCompanyDepartmentTranslations.SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS]:
            "The {0} and/or Teams have been Successfully Closed",
        [PendingTasksCompanyDepartmentTranslations.SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS_F]:
            "The {0} and/or Teams have been Successfully Closed",
        [PendingTasksCompanyDepartmentTranslations.SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS]:
            "The {0} and/or Teams have been Successfully marked as Open",
        [PendingTasksCompanyDepartmentTranslations.SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS_F]:
            "The {0} and/or Teams have been Successfully marked as Open",
        [PendingTasksCompanyDepartmentTranslations.COMPANY_COLLAPSABLE_TITLE]: "{0} - Mark if They Were Closed",
        [PendingTasksCompanyDepartmentTranslations.COMPANY_COLLAPSABLE_TITLE_F]: "{0} - Mark if They Were Closed",
        [PendingTasksCompanyDepartmentTranslations.DEPARTMENT_COLLAPSABLE_TITLE]: "Teams - Mark if They Were Closed",
        /* -------------------- PENDINGTASKSCOMPANYDEPARTMENT END -------------------- */

        /* -------------------- CUSTOMER TYPE START-------------------- */
        [CustomerTypeTranslations.COMPANY]: "Workplace",
        [CustomerTypeTranslations.COMMUNITY]: "Community",
        /* -------------------- CUSTOMER TYPE END-------------------- */
    },
};
