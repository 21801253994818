import { FC } from "react";
import { Navigate, Route, Routes } from "react-router";
import { PublicPaths } from "app/shared/Constants";
import { ReviewTask } from "./ReviewTask";
import { ReviewWorker } from "./ReviewWorker/ReviewWorker";
import { ReviewTaskProvider } from "./state/provider/ReviewTaskProvider";

export const ReviewTasksPage: FC = () => {
    return (
        <ReviewTaskProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={"/"} />} />
                <Route path="" element={<ReviewTask />} />
                <Route path={"/:userId"} element={<ReviewWorker />} />
                <Route path="/task/:taskInstanceId" element={<ReviewTask />} />
            </Routes>
        </ReviewTaskProvider>
    );
};
