import React, { FC } from "react";

type TabSkelletonProps = {
    quantity: number;
};
export const TabSkelleton: FC<TabSkelletonProps> = ({ quantity }) => {
    return (
        <div className="tabs">
            <div className="tabs__container">
                {Array.from({ length: quantity }).map((_, i) => (
                    <div className="tabskeleton" key={i}>
                        <div className="tabskeleton__text"></div>
                        {i === 0 && <div className="tabskeleton__underline"></div>}
                    </div>
                ))}
            </div>
        </div>
    );
};
