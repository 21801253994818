import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { createContext } from "react";
import { IssueModalAuditInfo, IssueModalOptions } from "./models/type";

export type IssueModalContextModel = {
    isOpen: boolean;
    issueType: IssueTypeCodeModel;
    taskInstanceId: number | null;
    assetId?: number;
    assetNotWorking: boolean | null;
    auditInfo: IssueModalAuditInfo | null;
    auditInstanceId: number | null;
    auditGroupChecklistInstanceId: number | null;
    companyId: number | null;
    open: (options: IssueModalOptions) => void;
    fillValues: (options: IssueModalOptions) => void;
    reset: () => void;
};
export const IssueModalContext = createContext<IssueModalContextModel>({} as IssueModalContextModel);
