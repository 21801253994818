import { faSmile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { emojis, mapEmojiToString } from "app/helpers/Emojis/emojisHelper";
import { TranslationKeys } from "app/translation/translationKeys";
import { useEffect, useState, useRef, FC } from "react";
import { useTranslation } from "react-i18next";

type EmojiInputProps = {
    onSubmit: () => void;
    onChangeValue: (value: string) => void;
    sendValue: (value: string) => void;
    setIsFocusedProp: (value: boolean) => void;
    isFocusedProp: boolean;
    doRefresh?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    upsideDownEmojis?: boolean;
    short?: boolean;
    focusOnSendMessage?: boolean;
    inputFocus?: boolean;
    showTextArea?: boolean;
    disabled?: boolean;
};

export const EmojisInput: FC<EmojiInputProps> = ({
    onSubmit,
    onChangeValue,
    doRefresh,
    maxLength,
    sendValue,
    autoFocus,
    upsideDownEmojis,
    short,
    focusOnSendMessage,
    inputFocus,
    setIsFocusedProp,
    isFocusedProp,
    showTextArea,
    disabled,
}) => {
    const { t } = useTranslation();

    const [value, setValue] = useState("");
    const [showEmojis, setShowEmojis] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) setShowEmojis(false);
    };

    const handleInputChange = (value: string) => {
        const outputValue = mapEmojiToString(value);
        setValue(outputValue);
        sendValue(outputValue);
        onChangeValue(outputValue);
    };

    const handleEmoji = (emoji: number) => handleInputChange(`${value} ${String.fromCodePoint(emoji)}`);

    const handleKeyPress = () => {
        onSubmit();
        setShowEmojis(false);
        setValue("");
        sendValue("");
    };

    useEffect(() => {
        setIsFocused(isFocusedProp);
    }, [isFocusedProp]);

    useEffect(() => {
        setValue("");
        setShowEmojis(false);
    }, [doRefresh]);

    useEffect(() => {
        if (focusOnSendMessage && inputRef.current) inputRef.current.focus();
    }, [inputFocus]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className={`emojisInput${disabled ? "--disabled" : ""}`} ref={wrapperRef}>
                {showEmojis && (
                    <div
                        onMouseDown={(e) => e.preventDefault()}
                        className={`emojisInput__container${upsideDownEmojis ? "--upsideDown" : ""} ${
                            short ? "shortEmojis" : ""
                        }`}
                    >
                        {emojis.map((emoji) => (
                            <p key={emoji} className="emojisInput__container__emoji" onClick={() => handleEmoji(emoji)}>
                                {String.fromCodePoint(emoji)}
                            </p>
                        ))}
                    </div>
                )}
                <FontAwesomeIcon
                    onClick={() => !disabled && setShowEmojis(!showEmojis)}
                    className={`emojisInput__emojiIcon${disabled ? "--disabled" : ""}`}
                    icon={faSmile}
                />
                {showTextArea ? (
                    <textarea
                        ref={textareaRef}
                        autoFocus={autoFocus}
                        id="test-id"
                        className={`emojisInput__input`}
                        onFocus={() => {
                            setShowEmojis(false);
                            setIsFocused(true);
                        }}
                        onChange={(e) => handleInputChange(e.target.value)}
                        value={value}
                        onKeyDown={(event) => {
                            event.key === "Enter" && handleKeyPress();
                        }}
                        maxLength={maxLength}
                        autoComplete="off"
                        placeholder={t(TranslationKeys.MESSAGE)}
                    />
                ) : (
                    <input
                        ref={inputRef}
                        autoFocus={autoFocus}
                        id="test-id"
                        className={`emojisInput__input`}
                        onFocus={() => {
                            setShowEmojis(false);
                            setIsFocused(true);
                        }}
                        onChange={(e) => handleInputChange(e.target.value)}
                        value={value}
                        onKeyDown={(event) => {
                            event.key === "Enter" && handleKeyPress();
                        }}
                        maxLength={maxLength}
                        autoComplete="off"
                        type="text"
                        placeholder={t(TranslationKeys.MESSAGE)}
                        disabled={disabled}
                    />
                )}
            </div>
        </>
    );
};
