import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { createContext } from "react";

export type IPostLoginContext = {
    workingCompany?: UserCompanyModel;
    workingDepartment?: UsrDepartmentModel;
    workingSubDepartment?: UsrSubDepartmentModel;
    setStepOne: (value: UserCompanyModel) => void;
    setStepTwo: (value: UsrDepartmentModel) => void;
    setStepThree: (value: UsrSubDepartmentModel) => void;
};

export const PostLoginContext = createContext<IPostLoginContext>({
    workingCompany: {
        companyId: 0,
        companyName: "",
    },
    workingDepartment: {
        departmentId: 0,
        departmentName: "",
    },
    workingSubDepartment: {
        subdepartmentId: 0,
        subdepartmentName: "",
    },
    setStepOne: () => {},
    setStepTwo: () => {},
    setStepThree: () => {},
});
