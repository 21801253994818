import { useState } from "react";
import { INITIAL_USER_INFO_VALUES } from "../constants/dashboardConstants";
import { DashboardUserInfo } from "../NewDashboard/types";
import { useUserType } from "app/hooks/useUserType";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { DashboardFilterData } from "app/components_v2/__filters/DashboardFilter/types";
import DashboardCardService from "app/services/03-REP/DashboardCardService";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { isUserTypeAnalist, isUserTypeSupervisorOrAnalist } from "app/helpers/userTypeCheck";
import { useSession } from "app/hooks";

export const useDashboardUserInfo = () => {
    const { isSupervisorOrAnalist, isAnalist } = useUserType();
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();

    const [isUserInfoLoading, setIsUserInfoLoading] = useState<boolean>(true);
    const [userInfo, setUserInfo] = useState<DashboardUserInfo>({
        ...INITIAL_USER_INFO_VALUES,
        isSupervisor: isSupervisorOrAnalist,
        isAnalist: isAnalist,
    });

    const fetchHeaderInfo = async (value: DashboardFilterData, currentTab: number) => {
        if (currentTab === 0) {
            await fetchUserInfo(value);
            return;
        }
        await fetchDepSubdepInfo(value);
    };

    const fetchUserInfo = async (value: DashboardFilterData) => {
        if (value.isAllChecked) {
            await fetchCompanyInfo(value.company ? Number(value.company) : 0);
            return;
        }
        setIsUserInfoLoading(true);
        const userId = value.user || 0;
        const { data, status } = await DashboardCardService.GetUserById(userId);

        if (!status()) {
            setIsUserInfoLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const { userType, entityHeaderInfoModel } = data;
        const { name, avg, totalTasks, trend, photoURL } = entityHeaderInfoModel;
        setUserInfo({
            fullName: name,
            starRatingAVG: avg || 0,
            total: totalTasks || 0,
            trend: trend || 0,
            userId: Number(userId),
            avatarImg: photoURL,
            isLoading: false,
            isSupervisor: isUserTypeSupervisorOrAnalist(userType),
            text: isUserTypeAnalist(userType) ? t(TranslationKeys.DASHBOARD_STAR_RATING_SUPERVIR_TEXT) : "",
            isCompany: false,
            areStarsClickable: true,
        });
        setIsUserInfoLoading(false);
    };

    const fetchDepSubdepInfo = async (value: DashboardFilterData) => {
        if (value.isAllChecked) {
            await fetchCompanyInfo(value.company ? Number(value.company) : 0);
            return;
        }

        if (value.subdepartment && value.subdepartment !== "-1" && isSupervisorOrAnalist) {
            await fetchSubdepartmentInfo(value);
            return;
        }
        if (value.department && value.department !== "-1" && isSupervisorOrAnalist) {
            await fetchDepartmentInfo(value);
            return;
        }

        await fetchUserInfo({ ...value, user: session?.user.id ? String(session?.user.id) : "0" });

        return;
    };

    const fetchDepartmentInfo = async (value: DashboardFilterData) => {
        setIsUserInfoLoading(true);
        const departmentId = value.department || 0;

        const { data, status } = await DashboardCardService.GetDepartmentById(departmentId);

        if (!status()) {
            setIsUserInfoLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const { entityHeaderInfoModel } = data;
        const { name, avg, totalTasks, trend, photoURL } = entityHeaderInfoModel;
        setUserInfo({
            fullName: name,
            starRatingAVG: avg || 0,
            total: totalTasks || 0,
            trend: trend || 0,
            userId: Number(departmentId),
            avatarImg: photoURL,
            isLoading: false,
            isSupervisor: false,
            text: "",
            isCompany: false,
            areStarsClickable: false,
        });
        setIsUserInfoLoading(false);
    };

    const fetchSubdepartmentInfo = async (value: DashboardFilterData) => {
        setIsUserInfoLoading(true);
        const subdepartmentId = value.subdepartment || 0;

        const { data, status } = await DashboardCardService.GetSubdepartmentById(subdepartmentId);

        if (!status()) {
            setIsUserInfoLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const { entityHeaderInfoModel } = data;
        const { name, avg, totalTasks, trend, photoURL } = entityHeaderInfoModel;
        setUserInfo({
            fullName: name,
            starRatingAVG: avg || 0,
            total: totalTasks || 0,
            trend: trend || 0,
            userId: Number(subdepartmentId),
            avatarImg: photoURL,
            isLoading: false,
            isSupervisor: false,
            text: "",
            isCompany: false,
            areStarsClickable: false,
        });
        setIsUserInfoLoading(false);
    };

    const fetchCompanyInfo = async (companyId: number) => {
        setIsUserInfoLoading(true);
        const { data, status } = await DashboardCardService.GetCompanyById(companyId, {
            extraParams: "asignedToDepartmentId=-1&asignedToSubdepartmentId=-1&asignedToUserId=-1",
        });

        if (!status()) {
            setIsUserInfoLoading(false);
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const { entityHeaderInfoModel } = data;
        const { name, avg, totalTasks, trend, photoURL } = entityHeaderInfoModel;
        setUserInfo({
            fullName: name,
            starRatingAVG: avg || 0,
            total: totalTasks || 0,
            trend: trend || 0,
            userId: companyId,
            avatarImg: photoURL,
            isLoading: false,
            isSupervisor: false,
            text: "",
            isCompany: true,
            areStarsClickable: false,
        });

        setIsUserInfoLoading(false);
    };

    return {
        fetchHeaderInfo,
        isUserInfoLoading,
        userInfo,
    };
};
