import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { APPCC_VARIANT_ROUTES } from "app/shared/Constants";
import { useLocation } from "react-router-dom";

export const useVariant = () => {
    const { pathname } = useLocation();

    const getVariant = (): TabHeaderVariants => {
        return APPCC_VARIANT_ROUTES.includes(`/${pathname.split("/")[1]}`) ? "appcc" : "primary";
    };

    return { variant: getVariant() };
};
