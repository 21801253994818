import { FC, useState } from "react";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { ColumnsType } from "app/components_v2/Table/types";
import { useTranslation } from "react-i18next";
import { AuditGroupCheckListInstanceTranslations, TranslationKeys } from "app/translation/translationKeys";
import { AuditGroupCheckListItemInstanceValues, AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { ProgressBar } from "app/components_v2/ProgressBar/ProgressBar";
import { AuditChecklistModal } from "../AuditChecklistModal/AuditChecklistModal";
import { Badge } from "app/components_v2/Badge/Badge";
import { AuditGroupMobile } from "../AuditGroupMobile/AuditGroupMobile";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

type AuditGroupCheckListProps = {
    auditGroupCheckListInstances: AuditGroupCheckListInstanceModel[];
    name: string;
    onChange: (values: AuditGroupCheckListInstanceModel) => void;
    actualStatus: AuditInstanceStatus;
    auditInstanceId: number;
    fetchEditAuditHeaderData?: () => Promise<void>;
};

export const AuditGroupCheckList: FC<AuditGroupCheckListProps> = ({
    auditGroupCheckListInstances,
    name,
    onChange,
    actualStatus,
    auditInstanceId,
    fetchEditAuditHeaderData,
}) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [selectedRow, setSelectedRow] = useState<AuditGroupCheckListInstanceModel | null>(null);

    const columns: ColumnsType<AuditGroupCheckListInstanceModel>[] = [
        {
            label: t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NAME),
            className: "auditGroupChecklist__name",
            dataIndex: t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NAME),
            render: ({ name }) => <CellTitle title={name} />,
        },
        {
            label: t(TranslationKeys.AUDIT_TOTAL),
            className: "auditGroupChecklist__total",
            dataIndex: "checkListItemCount",
            alignCenter: true,
            render: ({ auditGroupCheckListItemInstances, totalCheckedItems }) => {
                const percentage = (totalCheckedItems * 100) / auditGroupCheckListItemInstances.length;
                return (
                    <div className="auditGroupChecklist__progressbar">
                        <ProgressBar color="green" percentage={percentage} size="sm" />
                        <p className="auditGroupChecklist__progressbar__total">
                            {totalCheckedItems} / {auditGroupCheckListItemInstances.length}
                        </p>
                    </div>
                );
            },
        },
        {
            label: t(TranslationKeys.AUDIT_STATUS),
            className: "auditGroupChecklist__status",
            dataIndex: "name",
            alignCenter: true,
            render: (record) => {
                const isCompleted = record.status === "COMPLETED";
                const isAllItemsNA = record.auditGroupCheckListItemInstances.every(({ value }) => value === "NA");

                return (
                    <div className="auditGroupChecklist__status__container">
                        <Badge
                            title={
                                isCompleted
                                    ? isAllItemsNA
                                        ? t(
                                              AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE
                                          )
                                        : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED)
                                    : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS)
                            }
                            variant={isCompleted ? (isAllItemsNA ? "grey" : "green") : "blue"}
                        />
                    </div>
                );
            },
        },
        {
            label: t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT),
            className: "auditGroupChecklist__issueCount",
            dataIndex: "issueCount",
            alignCenter: true,
            render: ({ auditGroupCheckListItemInstances }) => {
                const issueCount = auditGroupCheckListItemInstances.filter(({ value }) => value === "KO").length;
                return (
                    <div className="auditGroupChecklist__issueCount__count">
                        <CellTitle title={issueCount} bolder />
                    </div>
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            className: "auditGroupChecklist__actions",
            dataIndex: "actions",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faListCheck,
                            onClick: () => setSelectedRow(record),
                        },
                    ]}
                />
            ),
        },
    ];

    const handleChecklistChange = (
        itemId: number,
        selectedValue: AuditGroupCheckListItemInstanceValues,
        fk_task: number | null
    ) => {
        if (!selectedRow?.id) return;

        const selectedChecklist = auditGroupCheckListInstances.find(({ id }) => id === selectedRow.id);

        if (!selectedChecklist) return;

        const selectedItem = selectedChecklist.auditGroupCheckListItemInstances.find((x) => x.id === itemId);
        const totalCheckedItems =
            selectedItem?.value === "" && selectedValue !== ""
                ? selectedChecklist.totalCheckedItems + 1
                : selectedChecklist.totalCheckedItems;

        onChange({
            ...selectedChecklist,
            auditGroupCheckListItemInstances: selectedChecklist.auditGroupCheckListItemInstances.map((item) =>
                item.id === itemId
                    ? { ...item, value: selectedValue, fK_Task: selectedValue === "KO" ? fk_task : null }
                    : { ...item }
            ),
            totalCheckedItems,
        });
    };

    const handleCompleteAllChecklist = (value: AuditGroupCheckListItemInstanceValues) => {
        if (!selectedRow?.id) return;

        const selectedChecklist = auditGroupCheckListInstances.find(({ id }) => id === selectedRow.id);

        if (!selectedChecklist) return;
        onChange({
            ...selectedChecklist,
            auditGroupCheckListItemInstances: selectedChecklist.auditGroupCheckListItemInstances.map((item) =>
                item.value === "" ? { ...item, value } : item
            ),
            totalCheckedItems: selectedChecklist.auditGroupCheckListItemInstances.length,
            status: "COMPLETED",
        });
        setSelectedRow(null);
        fetchEditAuditHeaderData && fetchEditAuditHeaderData();
    };

    const handleComplete = (checklistId: number) => {
        if (!selectedRow?.id) return;
        const selectedChecklist = auditGroupCheckListInstances.find(({ id }) => id === checklistId);
        if (!selectedChecklist) return;
        onChange({ ...selectedChecklist, status: "COMPLETED" });
        setSelectedRow(null);
        fetchEditAuditHeaderData && fetchEditAuditHeaderData();
    };

    const handleCheckListModalClose = () => {
        fetchEditAuditHeaderData && fetchEditAuditHeaderData();
        setSelectedRow(null);
    };

    return (
        <>
            {selectedRow && (
                <AuditChecklistModal
                    checklistId={selectedRow.id}
                    onClose={handleCheckListModalClose}
                    groupTitle={name}
                    checklistTitle={selectedRow.name}
                    onChange={handleChecklistChange}
                    onCheckAllChecklist={handleCompleteAllChecklist}
                    readonly={actualStatus !== "IN_PROGRESS" || !hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG)}
                    allowComplete={
                        actualStatus === "IN_PROGRESS" &&
                        selectedRow.status === "IN_PROGRESS" &&
                        hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG)
                    }
                    onComplete={handleComplete}
                    auditInstanceId={auditInstanceId}
                    auditGroupName={name}
                />
            )}
            <TableCollapsable
                title={name}
                cols={columns}
                data={auditGroupCheckListInstances}
                variant="appcc"
                placeholder={
                    <TableError
                        icon={faListCheck}
                        title={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_TITLE)}
                        description={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_BODY)}
                    />
                }
                isOpen={isOpen}
                onClickCollapsable={() => setIsOpen(!isOpen)}
                onDoubleClick={setSelectedRow}
                fitHeight
                mobileBody={(row, _i, rowPosition) => (
                    <AuditGroupMobile
                        onClick={() => setSelectedRow(row)}
                        row={row}
                        rowPosition={rowPosition}
                        key={row.id}
                    />
                )}
            />
        </>
    );
};
