import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { FC, ReactNode } from "react";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { useTranslation } from "react-i18next";
import { TaskTranslations } from "app/translation/translationKeys";
import { TaskTemporalityTitleDisabled } from "../TaskTemporalityTitleDisabled/TaskTemporalityTitleDisabled";

type TaskTemporalitySporadicDisabledProps = {
    taskStart: string;
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalitySporadicDisabled: FC<TaskTemporalitySporadicDisabledProps> = ({
    customError,
    taskStart,
    hint,
    isDisabled,
    min,
    taskstartLabel,
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <div className="taskTemporalitySporadicDisabled">
            <TaskTemporalityTitleDisabled title={t(TaskTranslations.TASK_TEMPORALITY_SPORADIC)} />
            <TaskStart
                onChange={onChange}
                taskStart={taskStart}
                customError={customError.taskStart}
                disabled={isDisabled}
                min={min}
                label={taskstartLabel}
                hint={hint}
            />
        </div>
    );
};
