import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import FetchService from "../Fetch/FetchService";
import { PlanAPPCCModel } from "app/models/05-QUA/PlanAPPCCModels/PlanAPPCCModels";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PlanAPPCCWithCountersDto } from "app/dtos/05-QUA/PlanAPPCC/PlanAPPCCWithCountersDto";

export default class PlanAPPCCService {
    public static async GetOne(id: number) {
        return FetchService.get<PlanAPPCCModel>({
            url: `${ApiPaths.QUA_API}/plans/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<PlanAPPCCModel>>({
            url: `${ApiPaths.QUA_API}/plans`,
            paginationParams,
        });
    }

    public static async GetDataWithCounters(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<PlanAPPCCWithCountersDto>>({
            url: `${ApiPaths.QUA_API}/plans/GetPlanListWithCountersAsync`,
            paginationParams,
        });
    }

    public static async Update({ id, name }: PlanAPPCCModel) {
        return FetchService.put<PlanAPPCCModel>({
            url: `${ApiPaths.QUA_API}/plans/${id}`,
            body: { id, name },
        });
    }

    public static async Create(name: string) {
        return FetchService.post<PlanAPPCCModel>({
            url: `${ApiPaths.QUA_API}/plans`,
            body: { name },
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete<PlanAPPCCModel>({
            url: `${ApiPaths.QUA_API}/plans/${id}`,
        });
    }

    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/plans/export`,
            paginationParams,
            csvExport: true,
        });
    }
}
