import { FC } from "react";
import { LegendItem } from "../SupervisorAnalist/Legend/LegendItem/LegendItem";
import { CustomLegendItem } from "./types";

type CustomLegendProps = {
    items: CustomLegendItem[];
};

const CustomLegend: FC<CustomLegendProps> = ({ items }) => {
    return (
        <div className="customLegend">
            {items.map(({ color, label, count, percentage, rounded }, index) => (
                <div key={index} className="customLegend__item">
                    <LegendItem
                        color={color}
                        label={label}
                        count={count}
                        percentage={percentage ? percentage : undefined}
                        rounded={rounded}
                    />
                </div>
            ))}
        </div>
    );
};

export default CustomLegend;
