import PublicRoute from "app/routes/PublicRoute";
import { PublicPaths } from "app/shared/Constants";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LoginPage } from "./Login/LoginPage";
import { RecoverPasswordPage } from "./RecoverPassword/RecoverPasswordPage";
import { ResetPasswordPage } from "./ResetPassword/ResetPasswordPage";
import { SelectProfilesPage } from "./SelectProfiles/SelectProfilesPage";
import { useEffect } from "react";
import { getUsersLogin } from "app/helpers/BrowserStorage/LocalStorageHandler";

export const AuthPage = () => {
    const nav = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes(PublicPaths.CHANGE_PASSWORD)) return;
        const usersIds: number[] = getUsersLogin();
        if (!usersIds.length) return;
        nav(`${PublicPaths.SELECT_PROFILE}`);
    }, []);

    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={PublicPaths.LOGIN} />} />
            <Route path={`${PublicPaths.LOGIN}/*`} element={<PublicRoute component={LoginPage} />} />
            <Route
                path={`${PublicPaths.RECOVER_PASSWORD}/*`}
                element={<PublicRoute component={RecoverPasswordPage} />}
            />
            <Route path={`${PublicPaths.CHANGE_PASSWORD}/*`} element={<PublicRoute component={ResetPasswordPage} />} />
            <Route path={`${PublicPaths.ACTIVATE_ACCOUNT}/*`} element={<PublicRoute component={ResetPasswordPage} />} />
            <Route path={`${PublicPaths.SELECT_PROFILE}/*`} element={<PublicRoute component={SelectProfilesPage} />} />
        </Routes>
    );
};
