import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

type DayOfYearProps = {
    repeatEvery: number;
    text: string;
};
export const DayOfYear: FC<DayOfYearProps> = ({ repeatEvery, text }) => {
    const { t } = useTranslation();
    return (
        <>
            <p className="stepFivePlaceHolderInput">
                {t(TranslationKeys.TASK_REPEAT_EVERY)}{" "}
                {repeatEvery <= 1
                    ? t(TranslationKeys.YEAR).toLocaleLowerCase()
                    : `${repeatEvery} ${t(TranslationKeys.YEARS).toLocaleLowerCase()}`}{" "}
                {text.toLowerCase()}{" "}
            </p>
        </>
    );
};
