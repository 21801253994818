import { FC } from "react";
import { ErrorFormValuesNotificationCompanyModel, NotificationModalAssignedTo } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect } from "app/components_v2/__inputs";
import { NotificationsTranslations } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type NotificationsCompanyModalProps = {
    companyList: number[];
    companyOptions: OptionModel[];
    errorFormValues: ErrorFormValuesNotificationCompanyModel;
    onSelect: (values: Pick<NotificationModalAssignedTo, "companyList">) => void;
};

export const NotificationsCompanyModal: FC<NotificationsCompanyModalProps> = ({
    companyList,
    companyOptions,
    errorFormValues,
    onSelect,
}) => {
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const { errorCompany } = errorFormValues;

    const handleDeleteListItems = (companyId: string) => {
        onSelect({ companyList: companyList.filter((companyListId) => companyListId !== Number(companyId)) });
    };
    return (
        <div className="notificationsCompanyModal">
            <OptionsOutsideSelect
                label={translateCustomerTypeKeys(NotificationsTranslations.NOTIFICATION_ASSIGN_COMPANY, {
                    firstReplace: "PLURAL",
                })}
                options={companyOptions}
                autoInitials
                onChange={(companies) => onSelect({ companyList: companies.map(({ value }) => Number(value)) })}
                values={companyOptions ? companyOptions.filter(({ value }) => companyList.includes(Number(value))) : []}
                onDeleteItem={handleDeleteListItems}
                isMulti={true}
                errorMessage={errorCompany}
                classNames={{ inputClassName: "notificationsCompanyModal__company" }}
            />
        </div>
    );
};
