import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import PercentageStars from "app/components_v2/PercentageStars/PercentageStars";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { AnalistEntityDetailPercentageStarsModel } from "app/models/03-REP/Analytics/AnalistEntityDetailPercentageStarsModel";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AnalistDetailStarsProps = {
    isLoading?: boolean;
    starsPercentageData?: AnalistEntityDetailPercentageStarsModel;
    hasNoData: boolean;
};
export const AnalistDetailStars: FC<AnalistDetailStarsProps> = ({
    isLoading = false,
    starsPercentageData,
    hasNoData,
}) => {
    const { t } = useTranslation();

    return hasNoData ? (
        <TableError icon={faListCheck} title={t(TranslationCommon.NO_DATA_FOR_YOUR_SEARCH)} />
    ) : isLoading ? (
        <div className="spinnerContainer">
            <Spinner />
        </div>
    ) : (
        <PercentageStars
            trend={starsPercentageData?.trend || 0}
            valoration={Number(starsPercentageData?.avg?.toFixed(1))}
            total={starsPercentageData?.totalTasks || 0}
            stars={[
                starsPercentageData?.fiveStarsPercentage || 0,
                starsPercentageData?.fourStarsPercentage || 0,
                starsPercentageData?.threeStarsPercentage || 0,
                starsPercentageData?.twoStarsPercentage || 0,
                starsPercentageData?.oneStarsPercentage || 0,
            ]}
        />
    );
};
