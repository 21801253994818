import { useSession } from "app/hooks";
import { ErrorResponse } from "app/models/ServiceResponse/ErrorResponse";
import { PrivatePaths } from "app/shared/Constants";
import { useNavigate } from "react-router";

type IRedirectToCompanyRankingPage = {
    status: boolean;
    errorResponse: ErrorResponse;
};

export const useRedirectRanking = () => {
    const session = useSession();
    const nav = useNavigate();

    const redirectToCompanyRankingPage = ({ errorResponse, status }: IRedirectToCompanyRankingPage) => {
        const isNotFoundError = errorResponse?.errors ? errorResponse.errors[0].errorCode === "404" : false;

        if (!status && isNotFoundError && session?.workingCompany?.companyId) {
            const navigationUrl =
                session.user.companies?.length === 1
                    ? `/${PrivatePaths.COMPANY_RANKING}/company/${session.workingCompany.companyId}`
                    : `/${PrivatePaths.COMPANY_RANKING}`;
            nav(navigationUrl);
            return true;
        }

        return false;
    };

    return redirectToCompanyRankingPage;
};
