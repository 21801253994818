import { FC } from "react";
import { Link, To } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PlanAPPCCCountersProps = {
    to: To;
    title: string;
    readonly: boolean;
};

export const PlanAPPCCCounters: FC<PlanAPPCCCountersProps> = ({ title, to, readonly }) => {
    if (readonly)
        return (
            <div className="planAPPCCCounters--readonly">
                <p className="planAPPCCCounters__title planAPPCCCounters__title--readonly">{title}</p>
            </div>
        );

    return (
        <Link className="planAPPCCCounters" to={to}>
            <p className="planAPPCCCounters__title">{title}</p>
            <div className="planAPPCCCounters__icon">
                <FontAwesomeIcon className="planAPPCCCounters__icon__svg" icon={faInfoCircle} />
            </div>
        </Link>
    );
};
