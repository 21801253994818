import { FC } from "react";
import { useTitle } from "app/hooks/CustomHooks";
import { useTranslation } from "react-i18next";
import { TranslationTitles } from "app/translation/translationKeys";
import PrivateRoute from "app/routes/PrivateRoute";
import { Navigate, Route, Routes } from "react-router";
import { PublicPaths, SecScreen } from "../../../shared/Constants";
import { TaskHistoryGrid } from "./TaskHistoryGrid/TaskHistoryGrid";
import { TaskHistoryGridProvider } from "./state/provider/TaskHistoryGridProvider";

export const TaskHistoryPage: FC = () => {
    const { t } = useTranslation();
    useTitle(t(TranslationTitles.TASK_HISTORY_PAGE_TITLE));

    return (
        <TaskHistoryGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />

                <Route
                    path=""
                    element={<PrivateRoute component={TaskHistoryGrid} viewCode={SecScreen.TASK_HISTORY} />}
                ></Route>
            </Routes>
        </TaskHistoryGridProvider>
    );
};

export default TaskHistoryPage;
