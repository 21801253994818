import { getDateFormattedSelects } from "app/helpers";

type IProps = {
    companyId: string;
    openDate?: Date;
    openDate2?: Date;
    closedDate?: Date;
    closedDate2?: Date;
};
export const fillCounterExtraparams = ({ companyId, closedDate2, closedDate, openDate2, openDate }: IProps) => {
    const extraparams = new URLSearchParams();
    if (companyId !== "-1") extraparams.append("CompanyId", companyId);
    if (!!closedDate) extraparams.append("closedDate", getDateFormattedSelects(closedDate));
    if (!!closedDate2) extraparams.append("closedDate2", getDateFormattedSelects(closedDate2));
    if (!!openDate) extraparams.append("openDate", getDateFormattedSelects(openDate));
    if (!!openDate2) extraparams.append("openDate2", getDateFormattedSelects(openDate2));

    return extraparams.toString();
};
