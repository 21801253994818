import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { FC } from "react";

type ChecklistItemReadOnlyProps = {
    labels: string[];
};

export const ChecklistItemReadOnly: FC<ChecklistItemReadOnlyProps> = ({ labels }) => {
    return (
        <div className="checklistItemReadOnly">
            {labels.map((label, i) => (
                <li key={i}>
                    <ReadOnlyInput value={label} />
                </li>
            ))}
        </div>
    );
};
