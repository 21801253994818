import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";

export default class GroupCompanyService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<GroupCompanyModel>>({
            url: `${ApiPaths.SEG_API}/groupCompanies`,
            paginationParams: paginationParams,
        });
    }

    public static async GetOne(groupId: number | string, instanceId: number) {
        return FetchService.get<GroupCompanyModel>({
            url: `${ApiPaths.SEG_API}/groupCompanies/${groupId}?customerInstanceId=${instanceId}`,
        });
    }

    public static async Save(values: GroupCompanyModel) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.post<GroupCompanyModel>({
            url: `${ApiPaths.SEG_API}/groupCompanies`,
            body: body,
        });
    }

    public static async Edit(values: GroupCompanyModel, groupId: number | string) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.put<GroupCompanyModel>({
            url: `${ApiPaths.SEG_API}/groupCompanies/${groupId}`,
            body: body,
        });
    }

    public static async Delete(groupId: number) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/groupCompanies/${groupId}`,
        });
    }
}
