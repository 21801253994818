import { useEffect, useState } from "react";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import {
    IOnReasign,
    OnCheckChangeValues,
    OnSelectValues,
    ReasignDatesModel,
    ReasignInputErrorModel,
    ReasignValues,
} from "./types";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";
import { REASIG_MODAL_BUTTON_GROUP } from "./constants";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

const INITIAL_SELECTED_VALUE: OptionsSearch = { label: "", value: "" };

const INITIAL_INPUT_ERROR_VALUES: ReasignInputErrorModel = {
    errorInputValue: "",
    errorStartDate: "",
    errorEndDate: "",
};

type IUseReasignModal = {
    onReasign: (value: IOnReasign) => void;
};

export const useReasignModal = ({ onReasign }: IUseReasignModal) => {
    const session = useSession();
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const [values, setValues] = useState<ReasignValues[]>([
        {
            checked: false,
            id: "DEPT",
            placeholder: t(TranslationKeys.REASIGN_TASK_SELECT_DEPARTMENT),
            selectedValue: INITIAL_SELECTED_VALUE,
            required: false,
            hidden: false,
        },
        {
            checked: true,
            id: "USER",
            placeholder: t(TranslationKeys.REASIGN_TASK_SELECT_USER),
            selectedValue: INITIAL_SELECTED_VALUE,
            required: true,
            hidden: true,
        },
        {
            checked: false,
            id: "SUBDEPT",
            placeholder: t(TranslationKeys.REASIGN_TASK_SELECT_SUBDEPARTMENT),
            selectedValue: INITIAL_SELECTED_VALUE,
            required: true,
            hidden: true,
        },
    ]);

    const [departmentOptions, setDepartmentOptions] = useState<OptionModel[]>([]);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [subdepartmentsOptions, setSubdepartmentsOptions] = useState<OptionModel[]>([]);

    const [selectorValue, setselectorValue] = useState<string>(REASIG_MODAL_BUTTON_GROUP.PERMANENT);
    const [dates, setDates] = useState<ReasignDatesModel>({
        startDate: undefined,
        endDate: undefined,
        hideEndDate: true,
    });
    const [inputError, setInputError] = useState<ReasignInputErrorModel>(INITIAL_INPUT_ERROR_VALUES);

    const ITEM_GROUP: ButtonGroupItem[] = [
        { label: t(TranslationKeys.REASIGN_TASK_STATUS_PERMANENT), value: REASIG_MODAL_BUTTON_GROUP.PERMANENT },
        { label: t(TranslationKeys.REASIGN_TASK_STATUS_TEMPORAL), value: REASIG_MODAL_BUTTON_GROUP.TEMPORAL },
    ];

    const fetchDeptData = async () => {
        const { data, status } = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&companyId=${session?.workingCompany?.companyId}`,
        });

        if (!status()) {
            setDepartmentOptions([]);
        }
        setDepartmentOptions(data);
    };

    const fetchUserSubdept = async (departmentId: number) => {
        const userPromise = SegSelectorService.GetUserNamesWithIds({
            extraParams: `departmentId=${departmentId}`,
        });
        const subdepartamentPromise = SegSelectorService.GetSubDepartmentNamesWithIds({
            extraParams: `departmentId=${departmentId}`,
        });

        Promise.all([userPromise, subdepartamentPromise]).then(([userSr, subdepartmentSr]) => {
            if (!userSr.status() || !subdepartmentSr.status()) {
                handleToast({
                    title: t(TranslationCommon.FAILED_DATA_LOADED),
                    type: "alert",
                    variant: "danger",
                });
            }

            setUserOptions(userSr.data);
            setSubdepartmentsOptions(subdepartmentSr.data);
        });
    };

    const handleCheckChange = (checkedValue: OnCheckChangeValues) => {
        setValues((prev) =>
            prev.map((value) =>
                checkedValue.id === value.id
                    ? { ...value, checked: true }
                    : {
                          ...value,
                          checked: checkedValue.id === "DEPT",
                          selectedValue: value.id === "DEPT" ? value.selectedValue : INITIAL_SELECTED_VALUE,
                      }
            )
        );
    };

    const handleSelectChange = async (selectedValue: OnSelectValues) => {
        const isDepartment = selectedValue.id === "DEPT";

        setValues((prev) =>
            prev.map(
                (value) =>
                    value.id === selectedValue.id
                        ? { ...value, selectedValue: selectedValue.value }
                        : { ...value, hidden: value.id === "SUBDEPT" ? true : isDepartment ? false : value.hidden }
                // TODO: --SUBDEPARTMENT--
                // : { ...value, hidden: isDepartment ? false : value.hidden }
            )
        );
        if (isDepartment) {
            const departmentId = selectedValue.value.value;
            await fetchUserSubdept(Number(departmentId));
        }
    };

    const handleSelect = ({ value }: ButtonGroupItem) => {
        if (value === selectorValue) return;

        handleIsPermanent(value);
        setselectorValue(value);
    };

    const handleIsPermanent = (value: string) => {
        if (value === REASIG_MODAL_BUTTON_GROUP.PERMANENT) {
            handleDateChange({ endDate: undefined, hideEndDate: true });
            return;
        }
        handleDateChange({ hideEndDate: false });
    };

    const handleDateChange = (values: Partial<ReasignDatesModel>) => {
        setDates((prev) => ({ ...prev, ...values }));
    };

    const validate = () => {
        handleErrorChange(INITIAL_INPUT_ERROR_VALUES);
        const deptValue = values.find(({ id }) => id === "DEPT")!;
        if (!deptValue.selectedValue.value.length) {
            handleErrorChange({ errorInputValue: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }

        if (!dates.startDate) {
            handleErrorChange({ errorStartDate: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }
        if (selectorValue === REASIG_MODAL_BUTTON_GROUP.PERMANENT) {
            return true;
        }
        if (!dates.endDate) {
            handleErrorChange({ errorEndDate: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }

        if (dates.startDate > dates.endDate) {
            handleErrorChange({
                errorStartDate: t(TranslationErrors.REASIGN_TASK_DATES_ERROR_MESSAGE),
            });
            return false;
        }

        return true;
    };

    const handleReasign = () => {
        handleErrorChange(INITIAL_INPUT_ERROR_VALUES);
        const deptValue = values.find(({ id }) => id === "DEPT")!;
        const userValue = values.find(({ id }) => id === "USER")!;
        const subdeptValue = values.find(({ id }) => id === "SUBDEPT")!;
        if (!validate()) return;
        const startDateWHour = new Date(dates.startDate!).setHours(0, 0, 0);
        const endDateWHour = new Date(dates.endDate!).setHours(23, 59, 59);
        onReasign({
            startDate: new Date(startDateWHour),
            endDate: selectorValue === "TEMPORAL" ? new Date(endDateWHour) : undefined,
            reassignedToDepartmentId: Number(deptValue.selectedValue.value),
            reassignedToUserId: !!userValue.selectedValue.value.length
                ? Number(userValue.selectedValue.value)
                : undefined,
            reassignedToSubdepartmentId: !!subdeptValue.selectedValue.value.length
                ? Number(subdeptValue.selectedValue.value)
                : undefined,
        });
    };

    const handleErrorChange = (values: Partial<ReasignInputErrorModel>) => {
        setInputError((prev) => ({ ...prev, ...values }));
    };

    useEffect(() => {
        fetchDeptData();
    }, []);

    return {
        dates,
        handleCheckChange,
        handleDateChange,
        handleErrorChange,
        handleReasign,
        handleSelect,
        handleSelectChange,
        inputError,
        ITEM_GROUP,
        selectorValue,
        values,
        departmentOptions,
        userOptions,
        subdepartmentsOptions,
    };
};
