import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { LegendItem } from "app/components_v2/SupervisorAnalist/Legend/LegendItem/LegendItem";

export const LegendItemSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="LegendItem" show={show}>
            <div style={{ width: "350px" }}>
                <LegendItem color="blue" label="4 y 5 con o sin reseña" count={700} rounded percentage={10} />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem
                    color="lightBlue"
                    label="4 y 5 con o sin reseña"
                    count={700}
                    onClick={() => {}}
                    percentage={10}
                />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem
                    color="lightRed"
                    label="1, 2, 3 estrellas con reseña"
                    count={280}
                    onClick={() => {}}
                    rounded
                    percentage={70}
                />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem
                    color="lightRed"
                    label="4 y 5 con o sin reseña"
                    count={700}
                    onClick={() => {}}
                    percentage={10}
                />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem color="red" label="4 y 5 con o sin reseña" onClick={() => {}} percentage={30} />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem
                    color="lightGreen"
                    label="4 y 5 con o sin reseña"
                    count={10000}
                    rounded
                    onClick={() => {}}
                    percentage={40}
                />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem color="green" label="4 y 5 con o sin reseña" onClick={() => {}} percentage={50} />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem
                    color="lightYellow"
                    label="4 y 5 con o sin reseña"
                    count={70089}
                    onClick={() => {}}
                    percentage={60}
                />
            </div>
            <div style={{ width: "350px" }}>
                <LegendItem color="yellow" label="4 y 5 con o sin reseña" rounded onClick={() => {}} percentage={90} />
            </div>
        </Section>
    );
};
