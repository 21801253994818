import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { FC, ReactNode } from "react";

type TaskTemporalitySporadicProps = {
    taskStart: string;
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalitySporadic: FC<TaskTemporalitySporadicProps> = ({
    customError,
    onChange,
    taskStart,
    isDisabled,
    min,
    taskstartLabel,
    hint,
}) => {
    return (
        <div className="taskTemporalitySporadic__taskStart">
            <TaskStart
                onChange={onChange}
                taskStart={taskStart}
                customError={customError.taskStart}
                disabled={isDisabled}
                min={min}
                label={taskstartLabel}
                hint={hint}
            />
        </div>
    );
};
