import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import RolesWizard from "app/components_v2/wizards/roles&permissions/rolesWizard";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";

export const WizardsSection: FC<SectionsShowProp> = ({ show }) => {
    const [OpenRoles, setOpenRoles] = useState<boolean>(false);
    const [OpenTemporaryRoles, setOpenTemporaryRoles] = useState<boolean>(false);

    return (
        <Section title="Wizards" show={show}>
            <button onClick={() => setOpenRoles(true)}>Rols i permissos</button>
            {OpenRoles && (
                <GenericModal
                    header={{ title: "roles y permisos", onClose: () => setOpenRoles(false) }}
                    size="lg"
                >
                    <RolesWizard closeModal={() => setOpenRoles(false)} />
                </GenericModal>
            )}
            <button onClick={() => setOpenTemporaryRoles(true)}>Rols i permissos temp.</button>
            {OpenTemporaryRoles && (
                <GenericModal
                    header={{ title: "roles y permisos", onClose: () => setOpenRoles(false) }}
                    size="lg"
                >
                    <RolesWizard temporal closeModal={() => setOpenTemporaryRoles(false)} />
                </GenericModal>
            )}
        </Section>
    );
};
export default WizardsSection;
