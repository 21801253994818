import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHeaderTitle } from "app/hooks/useHeaderTitle";
import { useNavigateToInitialScreen } from "app/hooks/useNavigateToInitialScreen";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useBreadcrumbs } from "../../state/BreadcrumbsProvider";
import { useSession, useTitle } from "app/hooks";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import RankingService from "app/services/03-REP/RankingService";
import { CompanyRankingProps, DateRange, getDataProps } from "../../types";
import { getRankingExtraParam } from "app/helpers/03-REP/getRankingExtraParam";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { isUserTypeSupervisorOrAnalist } from "app/helpers/userTypeCheck";
import { EntityRankingModel } from "app/models/03-REP/Ranking/EntityRankingModel";
import { rankingExtraParams } from "../../helpers/rankingExtraParams";
import { RankingLayout } from "app/components_v2/Layout/RankingLayout/RankingLayout";
import { RankingSortBy } from "app/components_v2/Layout/RankingLayout/RankingHeader/RankingHeader";
import { RankingFilterModel } from "app/components_v2/__filters/RankingFilter/types";
import { INITIAL_RANKING_FILTER_DATA } from "../constants/RankingConstants";

export const RankingUserByDepartment: FC<CompanyRankingProps> = ({
    startDate: startDateProp,
    endDate: endDateProp,
    onDateChange,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { onChangeHeaderTitle } = useHeaderTitle();
    const { updateBreadcrumbs, breadcrumbs } = useBreadcrumbs();
    const { navigateToInitialScreen } = useNavigateToInitialScreen();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const session = useSession();
    const nav = useNavigate();
    const [searchParams] = useSearchParams();

    !!id && useTitle(t(TranslationTitles.USER_BY_DEPARTMENT_RANKING_PAGE_TITLE));

    const [title, setTitle] = useState<string>("");
    const [startDate, setStartDate] = useState<Date>(startDateProp);
    const [endDate, setEndDate] = useState<Date | undefined>(endDateProp);
    const [sortBy, setSortBy] = useState<RankingSortBy>("STAR_ASC");
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [data, setData] = useState<EntityRankingModel[]>([]);
    const [isHeaderLoading, setIsHeaderLoading] = useState<boolean>(true);
    const [filterData, setFilterData] = useState(INITIAL_RANKING_FILTER_DATA);

    const handleDateChange = (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            setStartDate(start);
            setEndDate(undefined);
            onDateChange({ startDate: start, endDate: undefined });
        }
        if (end) {
            setEndDate(end);
            onDateChange({ endDate: end });
        }
    };

    const getData = async ({ query, pageI }: getDataProps) => {
        setLoading(true);
        const { sortDirection, sortField } = getRankingExtraParam(sortBy);
        const hasIdParam = id !== undefined;

        const { data, error, status } = await RankingService.GetAllUserRanking({
            pageIndex: pageI ? pageI - 1 : 0,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams: rankingExtraParams({
                startDate,
                endDate,
                companyId: !hasIdParam ? searchParams.get("companyId") || undefined : undefined,
                departmentId: hasIdParam ? id : undefined,
                jobDescriptionId: filterData.jobDescriptionId !== "-1" ? filterData.jobDescriptionId : undefined,
            }),
            query,
            sortDirection,
            sortField,
        });

        if (!status() || data.list === null) {
            console.error(error);
            setLoading(false);
            setData([]);
            setTotal(0);
            setIsHeaderLoading(false);
            return;
        }
        const companyId = searchParams.get("companyId");

        setData(data.list);
        setIsHeaderLoading(false);
        !id &&
            updateBreadcrumbs({
                name: !companyId
                    ? `${t(TranslationKeys.GLOBAL_RANKING_USERS)}`
                    : `${t(TranslationTitles.RANKING_USERS_BY_DEPARTMENT_TITLE).replace("{1}", data.title || "")}`,
            });

        setTotal(data.count);

        !id &&
            setTitle(
                !companyId
                    ? t(TranslationKeys.GLOBAL_RANKING_USERS)
                    : t(TranslationTitles.RANKING_USERS_BY_DEPARTMENT_TITLE).replace("{1}", data.title || "")
            );

        setLoading(false);
    };

    const onClick = (rank: EntityRankingModel) => {
        if (rank.companyId) changeWorkingCompanyByCompanyId(rank.companyId);
        if (isUserTypeSupervisorOrAnalist(rank?.userType)) {
            nav(`/${PrivatePaths.COMPANY_RANKING}/supervisor/${rank.id}`);
            return;
        }
        nav(`/${PrivatePaths.COMPANY_RANKING}/user/${rank.id}`);
    };

    const handleFilterData = (values: RankingFilterModel) => setFilterData(values);

    useEffect(() => {
        if (endDate === undefined) return;

        getData({ pageI: 0 });
        setPageIndex(0);
    }, [startDate, endDate, sortBy, filterData]);

    useEffect(() => {
        if (!hasPermissionToView(session, SecScreen.COMPANY_RANKING)) navigateToInitialScreen();

        if (title.length) onChangeHeaderTitle(title);
    }, [title]);

    return (
        <RankingLayout
            breadcrumbs={breadcrumbs}
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
            onPressEnter={(query) => getData({ query, pageI: 0 })}
            title={title}
            data={data}
            pageIndex={pageIndex}
            onPageIndexChange={(pi) => {
                setPageIndex(pi);
                getData({ pageI: pi });
            }}
            rankingTypePages="USER"
            onClickPill={onClick}
            total={total}
            isLoading={loading}
            headerLoading={isHeaderLoading}
            sortBy={sortBy}
            onChangeSortBy={setSortBy}
            filterData={filterData}
            initialfilterData={INITIAL_RANKING_FILTER_DATA}
            onFilter={handleFilterData}
        />
    );
};
