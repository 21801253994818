import { EditImageFooterIcon } from "../EditImageFooterIcon/EditImageFooterIcon";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { EditImageCropperActions } from "../types";
import { FC } from "react";
import { AriaLabels } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type EditImageFooterCropperProps = {
    onSelect: (action: EditImageCropperActions) => void;
};

export const EditImageFooterCropper: FC<EditImageFooterCropperProps> = ({ onSelect }) => {
    const { t } = useTranslation();

    return (
        <div className="editImageFooterCropper">
            <div className="editImageFooterCropper__container">
                <EditImageFooterIcon
                    icon={faXmark}
                    onClick={() => onSelect("CANCEL")}
                    ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_CANCEL_CROP)}
                />
                <EditImageFooterIcon
                    icon={faCheck}
                    onClick={() => onSelect("ACCEPT")}
                    ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_CROP)}
                />
            </div>
        </div>
    );
};
