import { SortedTypeModel } from "app/components_v2/Table/types";
import { BaseTaskGridSFModel } from "../state/context/BaseTaskContext";

export const INITIAL_BASE_TASK_SORT_FIELD: string = "name";
export const INITIAL_BASE_TASK_SORT_DIRECTION: SortedTypeModel = "asc";
export const INITIAL_BASE_TASK_SF_VALUES: BaseTaskGridSFModel = {
    query: "",
    baseTaskTypesId: [],
    isPhotoRequired: undefined,
    reportType: undefined,
    planId: "-1",
};
