import { AssetGridContext } from "../context/AssetGridContext";
import { AssetGridSF } from "../../types";
import { FC, useState } from "react";
import { ProviderProps } from "app/state/Providers/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { TranslationKeys } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useSearchParams } from "react-router-dom";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import {
    INITIAL_ASSET_SF_VALUES,
    INITIAL_ASSET_SORT_DIRECTION,
    INITIAL_ASSET_SORT_FIELD,
} from "../../constants/assetConstants";

export const AssetGridProvider: FC<ProviderProps> = ({ children }) => {
    const [urlParams] = useSearchParams();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const planId = urlParams.get("planId");

    const [pageIndex, setPageIndex] = useState<number>(1);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [sortField, setSortField] = useState<string>(INITIAL_ASSET_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_ASSET_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] = useState<AssetGridSF>(INITIAL_ASSET_SF_VALUES);
    const [query, setQuery] = useState<string>("");

    const { handleCompanyChange, selectCompany } = useSelectCompanyTable(
        !!planId?.length
            ? {
                  defaultCompany: {
                      label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }),
                      value: "-1",
                  },
              }
            : undefined
    );

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<AssetGridSF>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    return (
        <AssetGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                currentTab,
                onChangeCurrentTab: setCurrentTab,
                sortDirection,
                sortField,
                onSortChange,
                onSFChange,
                secondaryFilterValues,
                onCompanyChange: handleCompanyChange,
                selectCompany,
                query,
                onQueryChange: setQuery,
            }}
        >
            {children}
        </AssetGridContext.Provider>
    );
};
