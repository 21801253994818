import OnBoardingSubstepInstanceService from "app/services/01-SEG/OnBoardingSubstepInstanceService";
import { useState } from "react";
import { useFetchErrors } from "../useFetchErrors";
import { useToast } from "../Toast/useToast";
import { PrivatePaths } from "app/shared/Constants";
import { useNavigate } from "react-router-dom";
import OnBoardingStepInstanceService from "app/services/01-SEG/OnBoardingStepInstanceService";
import { UpdateOnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingServiceModel";
import { t } from "i18next";
import { TranslationErrors } from "app/translation/translationKeys";
import { v4 } from "uuid";
import { useSendEmailToast } from "./useSendEmailToast";

type IUseOnBoardingStep = {
    onCompleteSubStep?: () => void;
    onCompleteStep?: () => void;
    onReviewStep?: () => void;
    onInProgressStep?: () => void;
};

export const useOnBoardingStepModal = ({
    onCompleteSubStep,
    onCompleteStep,
    onReviewStep,
    onInProgressStep,
}: IUseOnBoardingStep) => {
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const nav = useNavigate();
    const { removeSendingMailToast, sendingMailToast, emailSendedToast } = useSendEmailToast();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const completeSubStep = async (subStepId: number) => {
        setIsLoading(true);

        const { getParsedError, status: statusSr } = await OnBoardingSubstepInstanceService.CompleteSubStep({
            id: subStepId,
            status: "DONE",
        });

        if (!statusSr()) {
            setIsLoading(false);
            handleToast({
                title: t(TranslationErrors.ONBOARDING_FAILED_COMPLETE),
                subtitle: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setIsLoading(false);
        nav(`/${PrivatePaths.ON_BOARDING}`);
        onCompleteSubStep && onCompleteSubStep();
    };

    const completeStep = async (stepId: number) => {
        setIsLoading(true);
        sendingMailToast();

        const { getParsedError, status: statusSr } = await OnBoardingStepInstanceService.CompleteStep({
            id: stepId,
            status: "REVIEW",
        });

        if (!statusSr()) {
            setIsLoading(false);
            removeSendingMailToast();
            handleToast({
                title: t(TranslationErrors.ONBOARDING_FAILED_SEND),
                subtitle: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setIsLoading(false);
        removeSendingMailToast();
        emailSendedToast();
        onCompleteStep && onCompleteStep();
    };

    const reviewStep = async ({ comment, status, id }: UpdateOnBoardingStatus) => {
        setIsLoading(true);
        sendingMailToast();

        const { getParsedError, status: statusSr } = await OnBoardingStepInstanceService.CompleteStep({
            id,
            status,
            comment,
        });

        if (!statusSr()) {
            setIsLoading(false);
            removeSendingMailToast();
            handleToast({
                title: t(TranslationErrors.ONBOARDING_FAILED_SEND),
                subtitle: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setIsLoading(false);
        removeSendingMailToast();
        emailSendedToast();
        onReviewStep && onReviewStep();
    };

    const inProgressStep = async (stepId: number) => {
        setIsLoading(true);
        sendingMailToast();

        const { getParsedError, status: statusSr } = await OnBoardingStepInstanceService.CompleteStep({
            id: stepId,
            status: "IN_PROGRESS",
        });

        if (!statusSr()) {
            setIsLoading(false);
            removeSendingMailToast();
            handleToast({
                title: t(TranslationErrors.ONBOARDING_FAILED_IN_PROGRESS),
                subtitle: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setIsLoading(false);
        removeSendingMailToast();
        emailSendedToast();
        onInProgressStep && onInProgressStep();
    };

    return { completeSubStep, completeStep, reviewStep, inProgressStep, isLoading };
};
