import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faAlarmExclamation, faCheck, faCircleX } from "@fortawesome/pro-regular-svg-icons";
import { Badge } from "app/components_v2/Badge/Badge";
import { TaskPillState } from "../type";
import { FC } from "react";
import { BadgeVariants } from "app/components_v2/Badge/badgeTypes";

export type TaskPillBadgeProps = {
    state: TaskPillState;
    title: string;
    isSporadic?: boolean;
    disabled?: boolean;
};

export const TaskPillBadge: FC<TaskPillBadgeProps> = ({ state, title, disabled, isSporadic }) => {
    const { variant, icon }: BadgeVariants =
        state === "PENDING"
            ? { icon: faClock, variant: "blue" }
            : state === "IN_TIME"
            ? { icon: faCheck, variant: "green" }
            : state === "TIMED_OUT"
            ? { icon: faCircleX, variant: "red" }
            : state === "OUT_OF_TIME"
            ? { icon: faAlarmExclamation, variant: "yellow" }
            : { icon: faClock, variant: "orange" };

    return (
        <div className="taskPill__section__right">
            <Badge
                icon={isSporadic ? undefined : icon}
                title={title}
                iconPosition="left"
                variant={variant}
                disabled={disabled && state !== "IN_TIME" && state !== "OUT_OF_TIME"}
            />
        </div>
    );
};
