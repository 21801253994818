import { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SupervisorTeamCountProps = {
    number: number;
    label: string;
    icon: IconDefinition;
};

const SupervisorTeamCount: FC<SupervisorTeamCountProps> = ({ number, label, icon }) => {
    return (
        <div className="supervisor-team-count">
            <div className="supervisor-team-count__content">
                <div className="supervisor-team-count__icon">
                    <FontAwesomeIcon className="supervisor-team-count__element" icon={icon} />
                    <span className="supervisor-team-count__number">{number}</span>
                </div>
                <div className="supervisor-team-count__text">{label}</div>
            </div>
        </div>
    );
};

export default SupervisorTeamCount;
