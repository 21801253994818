import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";

export const getTaskAssignedToByTabIndex = (tabIndex: number) => {
    const tabs: Record<number, SelectAssignedModel> = {
        0: "all",
        1: "workingPosition",
        2: "department",
        3: "subDepartment",
        4: "user",
    };

    return tabs[tabIndex];
};
