import { useState } from "react";
import { ErrorWorkingPositionFormValuesModel, WorkingPositionFormValuesModel } from "../types";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";

const INITIAL_ERROR_FORM_VALUES: ErrorWorkingPositionFormValuesModel = {
    errorCode: "",
    errorName: "",
    errorCompanyId: "",
};

export const useValidate = ({ fK_QrCode, name, companyId }: WorkingPositionFormValuesModel) => {
    const { t } = useTranslation();
    const [errorFormValues, setErrorFormValues] =
        useState<ErrorWorkingPositionFormValuesModel>(INITIAL_ERROR_FORM_VALUES);

    const handleErrorsChange = (values: Partial<ErrorWorkingPositionFormValuesModel>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const validate = (): boolean => {
        let error = false;
        handleErrorsChange({ ...INITIAL_ERROR_FORM_VALUES });
        if (fK_QrCode.length < 1) {
            handleErrorsChange({ errorCode: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }

        if (name.length < 1) {
            handleErrorsChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }

        if (companyId === -1) {
            handleErrorsChange({ errorCompanyId: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }

        return error;
    };

    return {
        errorFormValues,
        ...errorFormValues,
        validate,
    };
};
