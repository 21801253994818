import { StartAuditModalErrors, StartAuditModalFormValues } from "../types";

export const INITIAL_START_AUDIT_FORM_VALUES: StartAuditModalFormValues = {
    auditId: "-1",
    companyId: "-1",
};

export const INITIAL_START_AUDIT_ERRORS: StartAuditModalErrors = {
    errorAuditId: "",
    errorCompanyId: "",
};
