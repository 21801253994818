import { GroupCompanyErrorFormValues, GroupCompanyFormValues } from "../Components/GroupCompanyModal/types";

export const INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES: GroupCompanyErrorFormValues = {
    errorName: "",
    errorNif: "",
};

export const INITIAL_GROUP_COMPANY_FORM_VALUES: GroupCompanyFormValues = {
    name: "",
    nif: "",
};
