import { validAudioExtensions, validPhotoExtensions, validVideoExtensions } from "app/shared/Constants";

export const isVideoOrPhoto = (nombreArchivo: string): string => {
    const indexDot = nombreArchivo.lastIndexOf(".");
    if (indexDot === -1 || indexDot === nombreArchivo.length - 1) return "otro";

    const extension = nombreArchivo.slice(indexDot + 1).toLowerCase();

    if (validPhotoExtensions.includes(extension.toLowerCase())) return "image";
    else if (validVideoExtensions.includes(extension.toLowerCase())) return "video";
    else if (validAudioExtensions.includes(extension.toLowerCase())) return "audio";
    else return "file";
};
