import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { OmitedFilterData } from "../../types";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type onChangeProps = {
    state?: boolean;
    star: string;
};

type FilterStarsProps = {
    filterData: OmitedFilterData;
    onChange: (values: onChangeProps) => void;
};

export const FilterStars: FC<FilterStarsProps> = ({ filterData, onChange }) => {
    return (
        <div className="filterFeedback">
            <div className="filterFeedback__container">
                <CheckBox
                    onChange={(checked) => onChange({ state: checked, star: "fiveStar" })}
                    checked={filterData.fiveStar || false}
                />
                <div
                    className="filterFeedback__container__stars"
                    onClick={() => onChange({ state: !filterData.fiveStar, star: "fiveStar" })}
                >
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                </div>
            </div>
            <div className="filterFeedback__container">
                <CheckBox
                    onChange={(checked) => onChange({ state: checked, star: "fourStar" })}
                    checked={filterData.fourStar || false}
                />
                <div
                    className="filterFeedback__container__stars"
                    onClick={() => onChange({ state: !filterData.fourStar, star: "fourStar" })}
                >
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                </div>
            </div>
            <div className="filterFeedback__container">
                <CheckBox
                    onChange={(checked) => onChange({ state: checked, star: "threeStar" })}
                    checked={filterData.threeStar || false}
                />
                <div
                    className="filterFeedback__container__stars"
                    onClick={() => onChange({ state: !filterData.threeStar, star: "threeStar" })}
                >
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                </div>
            </div>
            <div className="filterFeedback__container">
                <CheckBox
                    onChange={(checked) => onChange({ state: checked, star: "twoStar" })}
                    checked={filterData.twoStar || false}
                />
                <div
                    className="filterFeedback__container__stars"
                    onClick={() => onChange({ state: !filterData.twoStar, star: "twoStar" })}
                >
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                </div>
            </div>
            <div className="filterFeedback__container">
                <CheckBox
                    onChange={(checked) => onChange({ state: checked, star: "oneStar" })}
                    checked={filterData.oneStar || false}
                />
                <div
                    className="filterFeedback__container__stars"
                    onClick={() => onChange({ state: !filterData.oneStar, star: "oneStar" })}
                >
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                    <FontAwesomeIcon className="filterFeedback__container__starsIcon--disabled" icon={faStarSharp} />
                </div>
            </div>
        </div>
    );
};
