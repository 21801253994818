import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { PendingTasksCompanyDepartmentModalContext } from "app/state/context/PendingTasksCompanyDepartmentModalContext/PendingTasksCompanyDepartmentModalContext";
import { EntitiesWithPendingTasksPerDay } from "app/dtos/01-SEG/EntitiesWithPendingTasksPerDay/EntitiesWithPendingTasksPerDayDto";

export const INITIAL_PENDING_TASKS_COMPANY_DEPARTMENT: EntitiesWithPendingTasksPerDay = {
    companies: [],
    departments: [],
};

export const PendingTasksCompanyDepartmentModalProvider: FC<ProviderProps> = ({ children }) => {
    const [companiesAndDepartmentsWithPendingTasks, setCompaniesAndDepartmentsWithPendingTasks] =
        useState<EntitiesWithPendingTasksPerDay>(INITIAL_PENDING_TASKS_COMPANY_DEPARTMENT);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = (companiesAndDepartmentsWithPendingTasks: EntitiesWithPendingTasksPerDay) => {
        setIsOpen(true);
        setCompaniesAndDepartmentsWithPendingTasks(companiesAndDepartmentsWithPendingTasks);
    };

    const reset = () => {
        setIsOpen(false);
        setCompaniesAndDepartmentsWithPendingTasks(INITIAL_PENDING_TASKS_COMPANY_DEPARTMENT);
    };

    return (
        <PendingTasksCompanyDepartmentModalContext.Provider
            value={{
                companiesAndDepartmentsWithPendingTasks,
                isOpen,
                open,
                reset,
            }}
        >
            {children}
        </PendingTasksCompanyDepartmentModalContext.Provider>
    );
};
