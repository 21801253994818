import { SortedTypeModel } from "app/components_v2/Table/types";
import { CloseDepartmentModel } from "app/models/01-SEG/CloseDepartment/CloseDepartmentModel";

export const INITIAL_DEPARTMENT_GRID_SORT_FIELD = "name";
export const INITIAL__DEPARTMENT_GRID_SORT_DIRECTION: SortedTypeModel = "default";
export const INITIAL_CLOSE_DEPARTMENTS: CloseDepartmentModel = {
    id: 0,
    fK_Department: 0,
    closeDate: new Date(),
    openDate: new Date(),
};
