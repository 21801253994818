import { Navigate, Route, Routes } from "react-router-dom";
import { Feedback } from "./Feedback";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";

export const FeedbackPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route path="" element={<PrivateRoute component={Feedback} viewCode={SecScreen.MY_VALORATION} />} />
            <Route
                path="/:taskInstanceId"
                element={<PrivateRoute component={Feedback} viewCode={SecScreen.MY_VALORATION} />}
            />
        </Routes>
    );
};
