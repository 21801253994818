import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { AuditInstanceCountersDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceCountersDto";
import { AuditCheckListItemInstanceInsertDto } from "app/dtos/05-QUA/AuditInstance/AuditCheckListItemInstanceDto";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";
import { AuditInstanceDetailDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceDetailDto";

export default class AuditInstanceService {
    public static async GetOne(auditId: number, params?: PaginationParams) {
        return FetchService.get<AuditInstanceModel>({
            url: `${ApiPaths.QUA_API}/AuditInstance/${auditId}`,
            paginationParams: params,
        });
    }

    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditInstanceModel>>({
            url: `${ApiPaths.QUA_API}/AuditInstance`,
            paginationParams: params,
        });
    }

    public static async GetCounters(params?: PaginationParams) {
        return FetchService.get<AuditInstanceCountersDto>({
            url: `${ApiPaths.QUA_API}/AuditInstance/counters`,
            paginationParams: params,
        });
    }

    public static async GetAuditInstanceDetailData(auditId: number) {
        return FetchService.get<AuditInstanceDetailDto>({
            url: `${ApiPaths.QUA_API}/AuditInstance/DetailData/${auditId}`,
        });
    }

    public static async StartAudit(auditId: number, companyId: number) {
        return FetchService.post({
            url: `${ApiPaths.QUA_API}/AuditInstance/StartAudit/${auditId}/${companyId}`,
        });
    }

    public static async CompleteAuditChecklistItem(
        itemId: number,
        auditChecklistItemInstanceDto: AuditCheckListItemInstanceInsertDto
    ) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/AuditInstance/CompleteAuditCheckListItem/${itemId}`,
            body: auditChecklistItemInstanceDto,
        });
    }

    public static async CompleteAuditChecklist(auditGroupCheckListInstanceId: number) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/AuditInstance/CompleteAuditCheckList/${auditGroupCheckListInstanceId}`,
        });
    }

    public static async CompleteAuditInstance(audiInstanceId: number) {
        return FetchService.put<AuditInstanceModel>({
            url: `${ApiPaths.QUA_API}/AuditInstance/CompleteAudit/${audiInstanceId}`,
        });
    }

    public static async CompleteAuditInstanceReview(audiInstanceId: number) {
        return FetchService.put<AuditInstanceModel>({
            url: `${ApiPaths.QUA_API}/AuditInstance/CompleteAuditReview/${audiInstanceId}`,
        });
    }

    public static async CompleteAuditChecklistMultiple(
        auditGroupCheckListInstanceId: number,
        value: AuditGroupCheckListItemInstanceValues
    ) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/AuditInstance/CompleteAuditCheckListMultiple/${auditGroupCheckListInstanceId}`,
            body: { value },
        });
    }

    public static async CreateAuditReportPDFFromHTML(companyId: number, html: string) {
        return FetchService.post({
            url: `${ApiPaths.QUA_API}/AuditInstance/CreateAuditReportPDF/${companyId}`,
            body: { html },
        });
    }
}
