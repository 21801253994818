import { useEffect, useState } from "react";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSearchParams } from "react-router-dom";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import SensorEntriesService from "app/services/04-SEN/SensorEntriesService";
import { fillExtraParams } from "../helpers/fillExtraParams";
import { PaginationDefaults } from "app/shared/Constants";
import { INITIAL_AUTOMATIC_RECORD_FILTER_DATA } from "../constants/automaticRecordConstants";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { SensorEntriesListModel } from "app/models/04-SEN/SensorEntriesModels/SensorEntriesListModel";
import { AutomaticRecordGridModel, AutomaticRecordGridSF } from "../models/AutomaticRecordGridModels";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";

export const useAutomaticRecord = () => {
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const [searchParams] = useSearchParams();
    const { selectCompany, handleCompanyChange: onCompanyChange, filterIcon } = useSelectCompanyTable();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const assetIdParam = searchParams.get("assetId");

    const [automaticRecordList, setAutomaticRecordList] = useState<SensorEntriesListModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("dateTimeEntry");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("desc");
    const [filterData, setFilterData] = useState<AutomaticRecordGridSF>(INITIAL_AUTOMATIC_RECORD_FILTER_DATA);
    const [showSecondaryFilter, setShowSecondaryFilter] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query?.length);

    const getAutomaticRecords = async ({
        pi,
        q,
        sortF,
        sortD,
        sfData,
        selectedCompany,
    }: AutomaticRecordGridModel = {}) => {
        setIsLoading(true);

        const filterDataFetch = sfData || filterData;
        const queryFetch = q !== undefined ? q : query;
        const companyIdFetch = selectedCompany || selectCompany.company.value;

        const { data, status, getParsedError } = await SensorEntriesService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pi !== undefined ? pi : pageIndex - 1,
            sortField: sortF ?? sortField,
            sortDirection: sortD ?? sortDirection,
            query: queryFetch,
            extraParams: fillExtraParams({ filterData: filterDataFetch, companyId: companyIdFetch }),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsLoading(false);
            setAutomaticRecordList([]);
            setTotal(0);
            return;
        }
        setAutomaticRecordList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getAutomaticRecords({ pi: pi - 1 });
    };

    const onChangeSortDirectionField = (sortF: string, sortD?: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD ?? "default");
        getAutomaticRecords({ sortF, sortD });
    };

    const onSaveSf = (sfData: AutomaticRecordGridSF) => {
        setFilterData(sfData);
        setShowSecondaryFilter(false);
        getAutomaticRecords({ pi: 0, sfData });
        setPageIndex(1);
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query.length) {
            getAutomaticRecords({ pi: 0 });
            setPageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleCleanFetch = () => {
        setQuery("");
        setShowSearchInput(false);
        getAutomaticRecords({ pi: 0, q: "" });
        setPageIndex(1);
    };

    const handleChangeCompany = ({ label, value }: OptionsSearch) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        setPageIndex(1);
        getAutomaticRecords({ pi: 0, selectedCompany: value });
        if (value != "-1") changeWorkingCompanyByCompanyId(Number(value));
    };

    const onExport = (params?: IDownLoadCsvReturn) =>
        SensorEntriesService.Export({
            query,
            extraParams: fillExtraParams({ filterData: filterData, companyId: selectCompany.company.value }),
            ...params,
        });

    useEffect(() => {
        let initialFilterData = filterData;
        if (assetIdParam !== null) {
            initialFilterData = { ...filterData, assetIds: [assetIdParam] };
            setFilterData(initialFilterData);
        }

        getAutomaticRecords({ sfData: initialFilterData, sortD: "desc" });
    }, []);

    return {
        automaticRecordList,
        filterData,
        getAutomaticRecords,
        handleChangeCompany,
        handleCleanFetch,
        isLoading,
        onChangePageIndex,
        onChangeSortDirectionField,
        onCompanyChange,
        onExport,
        onQueryChange: setQuery,
        onQuerySearch,
        onSaveSf,
        pageIndex,
        query,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        total,
        selectCompany,
        filterIcon,
    };
};
