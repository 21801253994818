import { FC } from "react";

type StepFourInfoProps = {
    title: string;
    description: string;
};

export const StepFourInfo: FC<StepFourInfoProps> = ({ description, title }) => {
    return (
        <div className="stepFourInfo">
            <p className="stepFourInfo__title">{title}</p>
            <p className="stepFourInfo__description">{description}</p>
        </div>
    );
};
