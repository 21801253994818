import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TranslationKeys } from "app/translation/translationKeys";
import { Actions } from "app/state/actions";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { PostLoginContext } from "../state/context/PostLoginContext";
import { useNavigate } from "react-router-dom";
import { PrivatePaths } from "app/shared/Constants";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { useSession } from "app/hooks";

type StepProps = {
    next: () => void;
    departments: UsrDepartmentModel[];
    onSelectDepartment: (dept: UsrDepartmentModel) => void;
};

export const StepTwo: FC<StepProps> = ({ next, departments, onSelectDepartment }) => {
    const { t } = useTranslation();
    const session = useSession();
    const dispatch = useDispatch();
    const { setStepTwo, workingCompany, workingDepartment } = useContext(PostLoginContext);
    const nav = useNavigate();

    const saveSession = (wDept: UsrDepartmentModel) => {
        if (session) setStepTwo(wDept);
    };

    const isWorkingDepartmentActive = (id: number) => {
        const sessionDepartment = session?.workingDepartment?.departmentId;
        if (!workingDepartment) return false;
        if (workingDepartment.departmentId === id && !sessionDepartment) return true;
        if (!sessionDepartment) return false;
        return sessionDepartment === id;
    };

    useEffect(() => {
        if (departments?.length === 0 || departments === undefined) {
            if (session && workingCompany) {
                setWorkingEntity({
                    workingCompany: workingCompany,
                    workingDepartment: undefined,
                    workingSubDepartment: undefined,
                });

                dispatch(
                    Actions.changeCompany({
                        workingCompany: workingCompany,
                        workingDepartment: undefined,
                        workingSubDepartment: undefined,
                    })
                );
            }
            nav(`/${PrivatePaths.DASHBOARD}`);
            return;
        } else if (departments?.length === 1) {
            handleDepartmentSelect({
                ...departments[0],
            });
        }
    }, []);

    const handleDepartmentSelect = (department: UsrDepartmentModel) => {
        if (!session || !workingCompany) return;

        // TODO: --SUBDEPARTMENT--
        // // Set department
        // saveSession(department);
        // // SetDepartmet for subdepartment usage
        // // Fill subDept array with subdept from dep selected
        // onSelectDepartment(department);
        // // Go next step
        // next();

        setWorkingEntity({
            workingCompany,
            workingDepartment: department,
            workingSubDepartment: undefined,
        });

        dispatch(
            Actions.changeCompany({
                workingCompany,
                workingDepartment: department,
                workingSubDepartment: undefined,
            })
        );

        dispatch(Actions.switchWizardMode(false));
        nav(`/${PrivatePaths.DASHBOARD}`);
    };

    return (
        <WizardForm>
            <div className="selectDepartmentWizard__children">
                <h1 className="selectDepartmentWizard__children__title">
                    {!!session?.user.companies?.length && session?.user.companies?.length > 1
                        ? t(TranslationKeys.SELCT_DEPARTMENT_DEPARTMENT_TITLE)
                        : t(TranslationKeys.SELCT_DEPARTMENTS_DEPARTMENT_TITLE)}
                </h1>
                {departments && !!departments.length && (
                    <div className="selectDepartmentWizard__children__options">
                        {departments.map((department, i) => (
                            <OptionCompany
                                name={department.departmentName}
                                onClick={() => handleDepartmentSelect(department)}
                                key={i}
                                isActive={isWorkingDepartmentActive(department.departmentId)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </WizardForm>
    );
};
