import { FC } from "react";
import { WizardHeaderNumber } from "../WizardHeaderNumber/WizardHeaderNumber";
import { headerStepNumbersModel } from "app/components_v2/wizards/types";

type WizardHeaderItemProps = headerStepNumbersModel & {
    id: number;
    currentStep: number;
    totalSteps: number;
    onClick?: () => void;
};
export const WizardHeaderItem: FC<WizardHeaderItemProps> = ({
    title,
    id,
    currentStep,
    totalSteps,
    onClick,
}) => {
    const variant =
        Number(id) === currentStep ? "primary" : Number(id) < currentStep ? "success" : "gray";

    return (
        <div
            className={`wizardStepBarItem ${
                variant === "gray" ? "wizardStepBarItem--disabled" : ""
            }`}
            onClick={onClick}
        >
            <div className="wizardStepBarItem__header">
                <WizardHeaderNumber
                    id={id}
                    variant={variant}
                    totalSteps={totalSteps}
                    currentStep={currentStep}
                />
            </div>
            {title && <p className="wizardStepBarItem__title">{title}</p>}
        </div>
    );
};
