import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import { Step1 } from "app/models/02-TAR/TaskWizard";

type useStepOneProps = Step1 & {
    next: () => void;
};

export const useStepOne = ({ taskTitle, next }: useStepOneProps) => {
    const { t } = useTranslation();

    const [validations, setValidations] = useState({
        taskTitle: "",
        planAPPCC: "",
    });

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        let aux = { ...validations };
        // Task Title
        aux.taskTitle = "";
        if (!taskTitle.length) {
            aux.taskTitle = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        if (check) next();
        else setValidations({ ...aux });
    };

    return {
        validate,
        validations,
    };
};
