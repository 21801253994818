import { FC } from "react";
import { IDropDownList } from "../types";
import { DropdownListItem } from "../DropdownListItem/DropdownListItem";

export const DropdownList: FC<IDropDownList> = ({ items, size }) => {
    return (
        <div className="dropdownList">
            {items.map(
                (item, i) => !item.hidden && <DropdownListItem {...item} key={i} size={size} />
            )}
        </div>
    );
};
