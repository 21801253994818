import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "../../shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AlertNotificationModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";
import { MultipleAlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/MultipleAlertNotificationDto";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";

export default class AlertNotificationService {
    // GET One Alert Notification
    public static async GetOne(id: number | string, instanceId: number) {
        return FetchService.get<AlertNotificationModel>({
            url: `${ApiPaths.TAR_API}/AlertNotification/${id}?customerInstanceId=${instanceId}`,
        });
    }

    // GET Alert Notification List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AlertNotificationDto>>({
            url: `${ApiPaths.TAR_API}/AlertNotification`,
            paginationParams: params,
        });
    }

    // POST New Alert Notification
    public static async Save(values: MultipleAlertNotificationDto) {
        return FetchService.post<number>({
            url: `${ApiPaths.TAR_API}/AlertNotification`,
            body: values,
        });
    }

    // PUT Mark One Alert Notification Seen
    public static async Edit(id: number | string) {
        return FetchService.put<PaginatedResult<AlertNotificationModel>>({
            url: `${ApiPaths.TAR_API}/AlertNotification/${id}`,
        });
    }
}
