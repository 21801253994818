import { faEnvelope, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { TextArea } from "app/components_v2/__inputs";
import { useState } from "react";
import { Section } from "../Section/Section";
import { SelectWeekDayBubbles, TimeRangePicker, WorkDay } from "app/components_v2/__inputs";

import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { TimePicker } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";

export const InputsSection = () => {
    const { t } = useTranslation();

    const [inputTextvalue, setInputTextvalue] = useState("");
    const [audioBase64, setAudioBase64] = useState<string | null>("");
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date>();
    const [time, setTime] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const onChange = (dates: any) => {
        console.log("dates", dates);
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onDeleteDates = () => {
        setStartDate(undefined);
        setEndDate(undefined);
    };

    const onChangeOneDate = (date: any) => setStartDate(date);

    const onDeleteOneDate = () => setStartDate(undefined);

    const getDayOfWeekTranslation = (index: number) =>
        `DAY_WEEK_${
            ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"][index]
        }_FIRST_LETTER`;
    const dayList: WorkDay[] = Array.from({ length: 7 }, (_, index) => ({
        id: index,
        letter: t(TranslationKeys[getDayOfWeekTranslation(index) as keyof typeof TranslationKeys]),
        isActive: true,
    }));

    const [week, setWeek] = useState(dayList);

    const onUpdateRangeTime = (start: string, end: string) => {
        setStartTime(start);
        setEndTime(end);
    };

    return (
        <Section title="Inputs">
            <h3 className="designSys__section__element__title">Readonly input</h3>
            <ReadOnlyInput label="¿Como ha ido el Servicio?" value="Bien, ningún problema" icon={{ value: faCheck }} />
            <ReadOnlyInput
                label="¿Ha habido algun problema durante el servicio?"
                value="Un grifo del baño no cierra bien y pierde agua aksjdkjashgdkj haskjdgkjasgdkj gasjhdasdkgaksjjkakjdas jkhgdkjashdhjkdhasjkkjasdkjgkjdgasjkdjas"
                icon={{ value: faCheck, variant: "success" }}
            />
            <ReadOnlyInput value="Bien, ningún problema" icon={{ value: faXmark, variant: "error" }} />
            <ReadOnlyInput
                value="Un grifo del baño no cierra bien y pierde agua aksjdkjashgdkj haskjdgkjasgdkj gasjhdasdkgaksjjkakjdas jkhgdkjashdhjkdhasjkkjasdkjgkjdgasjkdjas"
                icon={{ value: faXmark, variant: "error" }}
            />
            <ReadOnlyInput value="Bien, ningún problema" />
            <ReadOnlyInput value="Un grifo del baño no cierra bien y pierde agua aksjdkjashgdkj haskjdgkjasgdkj gasjhdasdkgaksjjkakjdas jkhgdkjashdhjkdhasjkkjasdkjgkjdgasjkdjas" />

            <h3 className="designSys__section__element__title">Input text</h3>
            <Input
                label="Input Text"
                placeholder="placeholder example"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
            />
            <Input
                label="Input Text With Error"
                placeholder="placeholder example"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                errorMessage="This is an error message."
            />
            <Input
                label="Input Text Disabled"
                placeholder="placeholder example"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                disabled={true}
            />

            <h3>Input Password</h3>
            <Input
                label="Password"
                placeholder="*********"
                value={inputTextvalue}
                type="password"
                onChange={setInputTextvalue}
                errorMessage={inputTextvalue.length >= 3 ? "Error" : ""}
                hint="hint largo pero muy largo para inputs"
            />

            <Input
                label="Password With Error"
                placeholder="*********"
                value={inputTextvalue}
                type="password"
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                errorMessage="This is an error message."
            />

            <Input
                label="Password Disabled"
                placeholder="*********"
                value={inputTextvalue}
                type="password"
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                disabled={true}
            />
            <h3>Input Mail</h3>
            <Input
                label="Input Mail"
                placeholder="olivia@untitledui.com"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconLeft={faEnvelope}
            />
            <Input
                label="Input Mail With Error"
                placeholder="olivia@untitledui.com"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconLeft={faEnvelope}
                errorMessage="This is an error message."
            />
            <Input
                label="Input Mail Disabled"
                placeholder="olivia@untitledui.com"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconLeft={faEnvelope}
                disabled={true}
            />

            <h3 className="designSys__section__element__title">Input Search </h3>
            <Input
                label="Input Search"
                placeholder="Buscar"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconRight={faSearch}
            />
            <Input
                label="Input Search With Error"
                placeholder="Buscar"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconRight={faSearch}
                errorMessage="This is an error message."
            />
            <Input
                label="Input Search Disabled"
                placeholder="Buscar"
                value={inputTextvalue}
                onChange={setInputTextvalue}
                hint="hint largo pero muy largo para inputs"
                iconRight={faSearch}
                disabled={true}
            />

            <h3 className="designSys__section__element__title">TextArea</h3>
            <TextArea
                text={inputTextvalue}
                label="TextArea"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea With Error"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
                errorMessage="This is an error message."
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea Disabled"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
                disabled
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea With Audio and Hint"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
                hint="This is a hint message"
            />

            <h3 className="designSys__section__element__title">TextArea With Audio</h3>
            <TextArea
                text={inputTextvalue}
                label="TextArea With Audio"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea With Audio & Error"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
                errorMessage={"This is an error message"}
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea With Audio & Disabled"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={(text: string) => setInputTextvalue(text)}
                maxLength={140}
                disabled={true}
            />

            <TextArea
                text={inputTextvalue}
                label="TextArea With Audio and Hint"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={setInputTextvalue}
                maxLength={140}
                hint="This is a hint message"
            />

            <h3 className="designSys__section__element__title">Date Picker</h3>
            <DateRangePicker
                onChange={onChangeOneDate}
                startDate={startDate}
                endDate={endDate}
                range={false}
                label="Date picker label"
                hint="Hint Date picker"
            />
            <DateRangePicker
                onChange={onChangeOneDate}
                startDate={startDate}
                endDate={endDate}
                range={false}
                label="Date picker label"
                hint="Hint Date picker"
            />
            <DateRangePicker
                onChange={onChangeOneDate}
                startDate={startDate}
                endDate={endDate}
                range={false}
                label="Date picker label with delete"
                hint="Hint Date picker"
                canDelete
                handleDeleteDates={onDeleteOneDate}
            />
            <DateRangePicker
                onChange={onChangeOneDate}
                startDate={startDate}
                endDate={endDate}
                range={false}
                disabled={true}
                label="Date picker label"
                hint="Hint Date picker"
            />

            <h3 className="designSys__section__element__title">Date Picker Range</h3>
            <DateRangePicker
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                label="Date picker range label"
                hint="Hint Date picker"
            />
            <DateRangePicker
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                label="Date picker range label more options"
                hint="Hint Date picker"
                showMoreOptions
            />
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                label="Date picker range label"
                hint="Hint Date picker"
            />
            <DateRangePicker
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                label="Date picker label with delete"
                hint="Hint Date picker"
                canDelete
                handleDeleteDates={onDeleteDates}
            />
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                label="Date picker range label"
                hint="Hint Date picker"
                disabled
            />

            <h3 className="designSys__section__element__title">Date Range picker Inverted</h3>
            <div className="designSys__section__element__blueContainer">
                <DateRangePicker
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    inverted
                    label="Date picker inverted label"
                    hint="Hint Date picker"
                />
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    inverted
                    label="Date picker inverted label"
                    hint="Hint Date picker"
                />
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    inverted
                    label="Date picker inverted label"
                    hint="Hint Date picker"
                    disabled={true}
                />
            </div>

            <h3 className="designSys__section__element__title">Select WeekDay</h3>
            <div className="flex">
                <SelectWeekDayBubbles list={week} onDayChange={setWeek} />
            </div>
            <div className="flex">
                <SelectWeekDayBubbles
                    label="Select WeekDay Disabled"
                    list={week}
                    onDayChange={setWeek}
                    disabled
                    size="xs"
                />
            </div>
            <div className="flex">
                <SelectWeekDayBubbles
                    label="Select WeekDay With Error"
                    list={week}
                    onDayChange={setWeek}
                    errorMessage="Hay algo que está mal por aquí"
                />
            </div>
            <div className="flex">
                <SelectWeekDayBubbles
                    label="Select WeekDay With Hint"
                    list={week}
                    onDayChange={setWeek}
                    hint="Este campo sirve para configurar tu propio horario de notificaciones"
                />
            </div>
            <div className="flex">
                <SelectWeekDayBubbles label="Select WeekDay Readonly" list={week} onDayChange={setWeek} readonly />
            </div>

            <h3 className="designSys__section__element__title">Select WeekDay Inverted</h3>
            <div className="designSys__section__element__blueContainer" style={{ height: "70px" }}>
                <SelectWeekDayBubbles list={week} onDayChange={setWeek} variant="dark" />
            </div>
            <div className="designSys__section__element__blueContainer" style={{ height: "80px" }}>
                <SelectWeekDayBubbles
                    label="Select WeekDay Dark Disabled"
                    list={week}
                    onDayChange={setWeek}
                    variant="dark"
                    disabled
                />
            </div>
            <div className="designSys__section__element__blueContainer" style={{ height: "100px" }}>
                <SelectWeekDayBubbles
                    label="Select WeekDay With Error"
                    list={week}
                    onDayChange={setWeek}
                    variant="dark"
                    errorMessage="Hay algo que está mal por aquí"
                />
            </div>
            <div className="designSys__section__element__blueContainer" style={{ height: "100px" }}>
                <SelectWeekDayBubbles
                    label="Select WeekDay With Hint"
                    list={week}
                    onDayChange={setWeek}
                    variant="dark"
                    hint="Este campo sirve para configurar tu propio horario de notificaciones"
                />
            </div>
            <div className="designSys__section__element__blueContainer" style={{ height: "100px" }}>
                <SelectWeekDayBubbles
                    label="Select WeekDay readonly"
                    list={week}
                    onDayChange={setWeek}
                    variant="dark"
                    readonly
                />
            </div>

            <h3 className="designSys__section__element__title">Select Hour</h3>
            <TimePicker value={time} onChange={setTime} />
            <TimePicker label="TimePicker Disabled" value={time} onChange={setTime} disabled />
            <TimePicker
                label="TimePicker With Error"
                value={time}
                onChange={setTime}
                errorMessage="Hay algo que está mal aquí"
            />
            <TimePicker
                label="TimePicker With Hint"
                value={time}
                onChange={setTime}
                hint="Aquí puedes configurar solamente horas"
            />

            <h3 className="designSys__section__element__title">Select Range Hour</h3>
            <TimeRangePicker startTime={startTime} endTime={endTime} onTimeUpdate={onUpdateRangeTime} />
            <TimeRangePicker
                startTime={startTime}
                endTime={endTime}
                onTimeUpdate={onUpdateRangeTime}
                labelStart="TimePicker Disabled"
                labelEnd="TimePicker Disabled"
                disabled
            />
            <TimeRangePicker
                startTime={startTime}
                endTime={endTime}
                onTimeUpdate={onUpdateRangeTime}
                labelStart="TimePicker With Error"
                labelEnd="TimePicker With Error"
                errorMessage="Hay algo que está mal aquí"
            />
            <TimeRangePicker
                startTime={startTime}
                endTime={endTime}
                onTimeUpdate={onUpdateRangeTime}
                labelStart="TimePicker With Hint"
                labelEnd="TimePicker With Hint"
                hint="Aquí puedes configurar solamente horas"
            />
        </Section>
    );
};
