import { FC } from "react";
import { SelectActionsLastItemsModel } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SelectWithActionsLastItemsProps = {
    item: SelectActionsLastItemsModel;
};

export const SelectWithActionsLastItems: FC<SelectWithActionsLastItemsProps> = ({ item }) => {
    const { icon, onClick, title } = item;
    return (
        <div className="selectWithActionsLastItems" onClick={onClick}>
            <FontAwesomeIcon icon={icon} className="selectWithActionsLastItems__icon" />
            <p className="selectWithActionsLastItems__title">{title}</p>
        </div>
    );
};
