import { ChangeEvent, CSSProperties, FC } from "react";

type HueSliderProps = {
    value: number;
    onChange: (value: number, hsl: string) => void;
};

export const HueSlider: FC<HueSliderProps> = ({ onChange, value }) => {
    const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
        const hslNumber = Number(e.target.value);
        onChange(hslNumber, `hsl(${hslNumber}, 100%, 50%)`);
    };

    return (
        <input
            type="range"
            min="0"
            max="360"
            value={value}
            onChange={handleSliderChange}
            className="hueSlider"
            style={{ "--hue-bg": `hsl(${value}, 100%, 50%)` } as CSSProperties}
        />
    );
};
