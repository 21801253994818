export const HeaderAnalystSkelleton = () => {
    return (
        <div className="headerAnalyst">
            <div className="headerAnalyst__info">
                <div className="headerAnalystSection__avatar"></div>
                <div className="headerAnalystSection__name"></div>
            </div>
            <div className="headerAnalystSection--32">
                <div className="headerAnalystSection__stars"></div>
            </div>
        </div>
    );
};
