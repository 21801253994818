import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router";
import { AssetForm } from "./AssetForm";
import { AssetGrid } from "./AssetGrid";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";
import { AssetGridProvider } from "./state/Provider/AssetGridProvider";

export const AssetPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_ASSETS);
    return (
        <AssetGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<PrivateRoute component={AssetGrid} viewCode={SecScreen.ASSETS} />}></Route>
                <Route path="new" element={<PrivateRoute component={AssetForm} viewCode={SecScreen.ASSETS} />}></Route>
                <Route
                    path="edit/:assetId"
                    element={<PrivateRoute component={AssetForm} viewCode={SecScreen.ASSETS} />}
                ></Route>
            </Routes>
        </AssetGridProvider>
    );
};
