import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { faCirclePlus, faKeySkeleton, faMagnifyingGlass, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FC, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { getDateFormatted } from "app/helpers";
import { GoBackIcon } from "app/components_v2/GoBackIcon/GoBackIcon";
import { hasPermission, hasPermissionToAdd, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { RoleScheduledModel } from "app/models/01-SEG/Role/RoleScheduledModel";
import { RoleService } from "app/services";
import RolesWizard from "app/components_v2/wizards/roles&permissions/rolesWizard";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { TemporaryroleEditDates } from "./TemporaryroleEditDates/TemporaryroleEditDates";
import { TemporaryRoleEditName } from "./TemporaryRoleEditName/TemporaryRoleEditName";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useNavigate } from "react-router-dom";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import moment from "moment";

const INITIAL_SORT_FIELD = "Role.Name";
const INITIAL_SORT_DIRECTION: SortedTypeModel = "asc";

const TemporaryRoles: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const instanceId = useSession();

    const { handleToast } = useToast();

    const [data, setData] = useState<RoleScheduledModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_SORT_DIRECTION);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isEditNameModalOpen, setIsEditNameModalOpen] = useState<boolean>(false);
    const [selectedScheduledRole, setSelectedScheduledRole] = useState<RoleModel>();
    const [isEditDateModalOpen, setIsEditDateModalOpen] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<RoleScheduledModel>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isRoleNameLoading, setIsRoleNameLoading] = useState<boolean>(false);
    const [isRoleDatesLoading, setIsRoleDatesLoading] = useState<boolean>(false);

    useTitle(t(TranslationTitles.TEMPORARY_ROLES_PAGE_TITLE));

    const columns: ColumnsType<RoleScheduledModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "Role.Name",
            render: (record) => <CellTitle title={record.roleModel?.name} />,
            sortedType: "asc",
        },
        {
            label: t(TranslationKeys.USER),
            dataIndex: "User.FirstName",
            render: (record) => <CellTitle title={record.userPersonalName} />,
            sortedType: "default",
        },
        {
            label: t(TranslationCommon.STATUS),
            dataIndex: "status",
            render: (record) => (
                <Badge
                    variant={record.roleModel?.isActive ? "green" : "red"}
                    title={record.roleModel?.isActive ? t("active") : t("inactive")}
                />
            ),
        },
        {
            label: t("from"),
            dataIndex: "DateFrom",
            sortedType: "default",
            render: (record) => {
                const date = record.dateFrom.toString().split("T");
                const formattedDate = getDateFormatted(new Date(new Date(date[0])));

                record!.range = [moment(record.dateFrom), moment(record.dateTo)];
                return (
                    <span
                        className={`temporaryGrid__dates${
                            hasPermissionToEdit(SecScreen.TEMPORARY_ROLES) ? "--clickable" : ""
                        }`}
                        onClick={() => handleClickDates(record)}
                    >
                        {formattedDate}
                    </span>
                );
            },
        },
        {
            label: t("to"),
            dataIndex: "DateTo",
            sortedType: "default",
            render: (record) => {
                const date = record.dateTo.toString().split("T");
                const formattedDate = getDateFormatted(new Date(new Date(date[0])));
                return (
                    <span
                        className={`temporaryGrid__dates${
                            hasPermissionToEdit(SecScreen.TEMPORARY_ROLES) ? "--clickable" : ""
                        }`}
                        onClick={() => handleClickDates(record)}
                    >
                        {formattedDate}
                    </span>
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            className: "temporaryGrid__actions",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        // {
                        //     icon: faPen,
                        //     onClick: () => {
                        //         setIsEditNameModalOpen(true);
                        //         setSelectedScheduledRole(record.roleModel);
                        //     },
                        //     hidden: !hasPermissionToEdit(SecScreen.TEMPORARY_ROLES),
                        // },
                        {
                            icon: faKeySkeleton,
                            onClick: () => navigate(`edit/${record.id}`),
                        },
                    ]}
                />
            ),
        },
    ];

    const getRoles = async (pageOne?: boolean) => {
        setIsLoading(true);
        pageOne && setPageIndex(1);

        let extraparams = `customerInstanceId=${instanceId || 0}`;

        const {
            data: roleData,
            error,
            status,
        } = await RoleService.GetScheduledRoles(
            {
                pageSize: PaginationDefaults.PAGE_SIZE,
                pageIndex: pageOne ? 0 : pageIndex - 1,
                extraParams: extraparams,
                query: query,
                sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
                sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            },
            hasPermission(SecScreen.TEMPORARY_ROLES)
        );

        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(roleData.list);
        setTotal(roleData.count);
        setIsLoading(false);
    };

    const handleCleanFetch = () => {
        setQuery("");
        setShowSearchInput(false);
        getRoles(true);
    };

    const handleClickDates = (value: RoleScheduledModel) => {
        if (!hasPermissionToEdit(SecScreen.TEMPORARY_ROLES)) return;
        setSelectedDate(value);
        setIsEditDateModalOpen(true);
    };
    useEffect(() => {
        getRoles();
    }, [pageIndex, sortField, sortDirection, refetch]);

    const handleUpdateNewRoleName = () => {
        setRefetch(!refetch);
        setIsEditNameModalOpen(false);
        setIsEditDateModalOpen(false);
    };

    return (
        <PageContainer paddingTop>
            {isEditNameModalOpen && (
                <GenericModal
                    size="sm"
                    header={{
                        title: t(TranslationKeys.MODIFY_NAME),
                        onClose: () => setIsEditNameModalOpen(false),
                    }}
                    footer={
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                fullWidth: true,
                                type: "submit",
                                form: "editRoleForm",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CLOSE),
                                onClick: () => setIsEditNameModalOpen(false),
                                buttonType: "tertiary",
                                fullWidth: true,
                            }}
                            loading={isRoleNameLoading}
                        />
                    }
                >
                    <TemporaryRoleEditName
                        onFinish={handleUpdateNewRoleName}
                        scheduledRole={selectedScheduledRole}
                        setIsSaving={setIsRoleNameLoading}
                    />
                </GenericModal>
            )}

            {isEditDateModalOpen && (
                <GenericModal
                    size="sm"
                    header={{
                        title: `${t(TranslationCommon.EDIT)} ${t(TranslationKeys.TITLE_DATE)}`,
                        onClose: () => setIsEditDateModalOpen(false),
                    }}
                    footer={
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                fullWidth: true,
                                type: "submit",
                                form: "editRoleDatesForm",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CLOSE),
                                onClick: () => setIsEditDateModalOpen(false),
                                buttonType: "tertiary",
                                fullWidth: true,
                            }}
                            loading={isRoleDatesLoading}
                        />
                    }
                >
                    <TemporaryroleEditDates
                        onFinish={handleUpdateNewRoleName}
                        selectedRole={selectedDate}
                        setIsSaving={setIsRoleDatesLoading}
                    />
                </GenericModal>
            )}
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={data}
                placeholder={<TableError icon={faUsers} />}
                title={t(TranslationTitles.TEMPORARY_ROLES_TITLE)}
                subTitle={`${total} ${total > 1 ? t(TranslationKeys.ROLES) : t(TranslationKeys.LABEL_ROLE)}`}
                leftIcon={<GoBackIcon onClick={() => navigate(`/${PrivatePaths.ROLE_MANAGEMENT}`)} />}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={setPageIndex}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    if (sortedTypeParam) setSortDirection(sortedTypeParam);
                }}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => setShowModal(true),
                        hidden: !hasPermissionToAdd(SecScreen.TEMPORARY_ROLES),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={setQuery}
                                    value={query}
                                    fetch={() => getRoles(true)}
                                    cleanFunction={() => handleCleanFetch()}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                ]}
                onDoubleClick={(record) => navigate(`edit/${record.id}`)}
            />
            {showModal && (
                <GenericModal
                    header={{
                        title: t(TranslationTitles.ROLES_TEMPORARY_TITLE),
                        onClose: () => setShowModal(false),
                    }}
                    size="lg"
                    hideChildrenPadding
                >
                    <RolesWizard
                        closeModal={() => {
                            setShowModal(false);
                            getRoles(true);
                        }}
                        temporal
                    />
                </GenericModal>
            )}
        </PageContainer>
    );
};

export default TemporaryRoles;
