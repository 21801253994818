import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { SupervisorProgressbarColors } from "app/shared/Constants";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import DividerWithText from "../../DividerWithText/DividerWithText";
import { TableError } from "../../Table/TableError/TableError";
import { ProgresssBarWithLabel } from "../../ProgressBarWithLabel/ProgressBarWithLabel";

type FastReviewValorationDetailProps = {
    fastReview: UserScoreModel[];
    emptyState: boolean;
};

export const FastReviewValorationDetail: FC<FastReviewValorationDetailProps> = ({ fastReview, emptyState }) => {
    const { t } = useTranslation();

    return (
        <div className="supervisorValorationDetail__section fastReviewValorationDetail">
            <DividerWithText text={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_VALORATION)} />
            <div className="fastReviewValorationDetail__content">
                {emptyState ? (
                    <TableError
                        icon={faListCheck}
                        title={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_SUBTITLE)}
                        description={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_DATE)}
                    />
                ) : (
                    fastReview.map((state, i) => (
                        <div key={i} className="flex gap--12">
                            <ProgresssBarWithLabel
                                percentage={state.percentage}
                                leftLabel={`${t(TranslationKeys[state?.rangeType as keyof typeof TranslationKeys])} (${
                                    state.count
                                })`}
                                barLabel={`${state.percentage}%`}
                                color={SupervisorProgressbarColors[i % SupervisorProgressbarColors?.length]}
                                barSize={"md"}
                                leftLabelFullWidth
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
