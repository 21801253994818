import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { LanguageSelector, SelectWeekDayBubbles, TimeRangePicker, WorkDay } from "app/components_v2/__inputs";
import { Switch } from "app/components_v2/__inputs/Switch/Switch";
import Spinner from "app/components_v2/Spinner/Spinner";
import { getHourSecWithString, transformMinutesHoursToDate } from "app/helpers";
import { ReverseUserWorkDaysMapper } from "app/mappers/UserWorkDaysMapper";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { ProfileService } from "app/services";
import { Languages, SecScreen } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { changeLanguage } from "i18next";
import { useUpdateLanguage } from "app/hooks/Profile/useUpdateLanguage";
import { useSession } from "app/hooks";
import { LanguageType } from "app/components_v2/__inputs/LanguageSelector/type/languageType";
import { Button } from "app/components_v2/__buttons/Button/Button";

type MyConfigurationProps = {
    user?: UserModel;
    setUser: (user: UserModel) => void;
    isLoading: boolean;
    scheduledDoNotDisturb: boolean;
    onChangeScheduledDoNotDisturb: (value: boolean) => void;
    minNotifHour: string;
    maxNotifHour: string;
    onChangeHours: (minHour: string, maxhour: string) => void;
    weekDays: WorkDay[];
    onDayChange: (list: WorkDay[]) => void;
};

export const MyConfiguration: FC<MyConfigurationProps> = ({
    user,
    setUser,
    isLoading,
    onChangeScheduledDoNotDisturb,
    scheduledDoNotDisturb,
    maxNotifHour,
    minNotifHour,
    onChangeHours,
    onDayChange,
    weekDays,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { handleChangeLanguage } = useUpdateLanguage();
    const session = useSession();

    const [loading, setLoading] = useState(false);
    const [errorMessageStart, setErrorMessageStart] = useState<string | undefined>();
    const [errorMessageEnd, setErrorMessageEnd] = useState<string | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(session?.user.language || "es");

    const hasPermissionsToViewNotifications = hasPermission(SecScreen.SYSTEM_NOTIFICATION);
    const options = [
        { value: "es", label: Languages.ESPANOL },
        { value: "en", label: Languages.ENGLISH },
    ];

    const handleUpdateWorkDaysHours = async () => {
        if (!user) return;
        setLoading(true);
        const minNotifHourToIsoString = new Date(transformMinutesHoursToDate(minNotifHour)).toISOString().slice(0, -1);
        const minNotifHourToUTC = getHourSecWithString(new Date(minNotifHourToIsoString));

        const maxNotifHourToIsoString = new Date(transformMinutesHoursToDate(maxNotifHour)).toISOString().slice(0, -1);
        const maxNotifHourToUTC = getHourSecWithString(new Date(maxNotifHourToIsoString));

        await ProfileService.UpdateScheduledDoNotDisturbMode({ value: true });
        const sr = await ProfileService.UpdateWorkDaysHours({
            minNotifHour: minNotifHourToUTC.trim(),
            maxNotifHour: maxNotifHourToUTC.trim(),
            userWorkDays: ReverseUserWorkDaysMapper(weekDays, user?.id || 0),
        });

        if (sr.status()) {
            setUser({
                ...user,
                minNotifHour: minNotifHour,
                maxNotifHour: maxNotifHour,
                userWorkDays: ReverseUserWorkDaysMapper(weekDays, user?.id || 0),
            });
            handleToast({
                title: t(TranslationModals.SUCCESS_SAVE),
                type: "alert",
                variant: "success",
            });
        }

        setLoading(false);
    };

    const onUpdateNotifHour = (start: string, end: string) => {
        setErrorMessage(undefined);
        setErrorMessageStart(undefined);
        setErrorMessageEnd(undefined);
        onChangeHours(start, end);
    };

    const validateScheduledDoNotDisturbAndChangeLang = async () => {
        changeLanguage(selectedLanguage);
        handleChangeLanguage(selectedLanguage);
        if (!hasPermissionsToViewNotifications) {
            return;
        }
        if (!scheduledDoNotDisturb) {
            await ProfileService.UpdateScheduledDoNotDisturbMode({ value: false });
            return;
        }

        if (!minNotifHour.length) {
            setErrorMessageStart(t(TranslationErrors.START_TIME_NULL_OR_EMPTY));
            return;
        }
        if (!maxNotifHour.length) {
            setErrorMessageEnd(t(TranslationErrors.END_TIME_NULL_OR_EMPTY));
            return;
        }
        if (minNotifHour >= maxNotifHour) {
            setErrorMessage(t(TranslationErrors.TIME_RANGE_ERROR));
            return;
        }
        handleUpdateWorkDaysHours();
    };

    useEffect(() => {
        const language = session?.user.language;
        if (!language) return;
        setSelectedLanguage(language);
    }, [session?.user.language]);

    return (
        <div className="myConfiguration">
            {isLoading ? (
                <div className="myConfiguration__loading">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="myConfiguration__container">
                        <p className="myConfiguration__title">
                            {t(
                                hasPermissionsToViewNotifications
                                    ? TranslationKeys.NOTIFICATIONS_LANGUAGE
                                    : TranslationCommon.LANGUAGE
                            )}
                        </p>
                        <div className="myConfiguration__container">
                            {hasPermissionsToViewNotifications && (
                                <div className="myConfiguration__notifications">
                                    <div className="myConfiguration__notifications__doNotDisturb">
                                        <div className="myConfiguration__notifications__doNotDisturb__content">
                                            <Switch
                                                checked={scheduledDoNotDisturb}
                                                onChange={onChangeScheduledDoNotDisturb}
                                            />
                                            <div>
                                                <p
                                                    className="myConfiguration__description"
                                                    onClick={() =>
                                                        onChangeScheduledDoNotDisturb(!scheduledDoNotDisturb)
                                                    }
                                                >
                                                    {t(TranslationKeys.DO_NOT_DISTURB_SCHEDULED)}
                                                </p>
                                                <p className="myConfiguration__subdescription">
                                                    {t(TranslationKeys.DO_NOT_DISTURB_SCHEDULED_DESCRIPTION)}
                                                </p>
                                            </div>
                                        </div>
                                        {scheduledDoNotDisturb && (
                                            <div className="myConfiguration__notifications__doNotDisturb">
                                                <div className="myConfiguration__notifications__doNotDisturb__timeContainer">
                                                    <TimeRangePicker
                                                        startTime={minNotifHour}
                                                        endTime={maxNotifHour}
                                                        onTimeUpdate={onUpdateNotifHour}
                                                        labelStart={t(TranslationCommon.TIME_FROM)}
                                                        labelEnd={t(TranslationCommon.TIME_TO)}
                                                        errorMessage={errorMessage}
                                                        errorMessageStart={errorMessageStart}
                                                        errorMessageEnd={errorMessageEnd}
                                                    />
                                                </div>
                                                <SelectWeekDayBubbles list={weekDays} onDayChange={onDayChange} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="myConfiguration__language">
                                {hasPermissionsToViewNotifications && (
                                    <p className="myConfiguration__description">{t(TranslationCommon.LANGUAGE)}</p>
                                )}
                                <LanguageSelector
                                    language={selectedLanguage}
                                    options={options}
                                    onSelectedLanguage={(selectedValue) =>
                                        setSelectedLanguage(selectedValue.value as LanguageType)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="myConfiguration__button">
                        <Button
                            onClick={validateScheduledDoNotDisturbAndChangeLang}
                            text={t(TranslationCommon.SAVE)}
                            isLoading={loading}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
