import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { AuditBoxModalProps, AuditType, AuditTypeValues } from "./types/AuditBoxModalTypes";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Input } from "app/components_v2/__inputs";

export const AuditBoxModal: FC<AuditBoxModalProps> = ({ isLoading, onSubmit, onCloseModal, type, value = "" }) => {
    const { t } = useTranslation();

    const [name, setName] = useState<string>(value);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const auditRecord: Record<AuditType, AuditTypeValues> = {
        SAVE_AUDIT: { title: t(TranslationKeys.NEW_AUDIT), inputLabel: t(TranslationKeys.AUDIT_NAME_LABEL) },
        EDIT_AUDIT: { title: t(TranslationKeys.EDIT_AUDIT), inputLabel: t(TranslationKeys.AUDIT_NAME_LABEL) },
        SAVE_AUDIT_GROUP: {
            title: t(TranslationKeys.NEW_AUDIT_GROUP),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_NAME_LABEL),
        },
        EDIT_AUDIT_GROUP: {
            title: t(TranslationKeys.EDIT_AUDIT_GROUP),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_NAME_LABEL),
        },
        SAVE_AUDIT_GROUP_CHECKLIST: {
            title: t(TranslationKeys.NEW_AUDIT_GROUP_CHECKLIST),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL),
        },
        EDIT_AUDIT_GROUP_CHECKLIST: {
            title: t(TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL),
        },
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!validate()) return;
        onSubmit(name);
    };

    const validate = (): boolean => {
        setErrorMessage("");
        if (!name?.length) {
            setErrorMessage(t(TranslationCommon.INPUT_NOT_EMPTY));
            return false;
        }

        return true;
    };

    return (
        <GenericModal
            size="sm"
            header={{ title: auditRecord[type].title, onClose: onCloseModal, variant: "appcc" }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.SAVE),
                        form: "editAudit",
                        type: "submit",
                        variant: "appcc",
                    }}
                    closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onCloseModal, variant: "appcc" }}
                    loading={isLoading}
                />
            }
        >
            <form id="editAudit" onSubmit={handleSubmit}>
                <Input
                    label={auditRecord[type].inputLabel}
                    placeholder={auditRecord[type].inputLabel}
                    value={name}
                    onChange={setName}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
