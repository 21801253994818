import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TaskPillBodyRatingSkelleton = () => {
    return (
        <div className="taskPillBodyRating-skelleton">
            <div className="taskPillBodyRating-skelleton__starIcon">
                <FontAwesomeIcon icon={faStarSharp} className="taskPillBodyRating-skelleton__starIcon__icon" />
                <FontAwesomeIcon icon={faStarSharp} className="taskPillBodyRating-skelleton__starIcon__icon" />
                <FontAwesomeIcon icon={faStarSharp} className="taskPillBodyRating-skelleton__starIcon__icon" />
                <FontAwesomeIcon icon={faStarSharp} className="taskPillBodyRating-skelleton__starIcon__icon" />
                <FontAwesomeIcon icon={faStarSharp} className="taskPillBodyRating-skelleton__starIcon__icon" />
            </div>
        </div>
    );
};
