import { SensorEntriesListModel } from "app/models/04-SEN/SensorEntriesModels/SensorEntriesListModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";

export default class SensorEntriesService {
    public static async GetData(parms: PaginationParams) {
        return FetchService.get<PaginatedResult<SensorEntriesListModel>>({
            url: `${ApiPaths.SEN_API}/SensorEntries`,
            paginationParams: parms,
        });
    }

    public static async GetSensorEntriesCount(sensorSerialNumber: string) {
        return FetchService.get<number | null>({
            url: `${ApiPaths.SEN_API}/SensorEntries/${sensorSerialNumber}`,
        });
    }

    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.SEN_API}/SensorEntries/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }
}
