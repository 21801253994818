import { TypeCodeTask } from "app/models/02-TAR/Task/TaskBody";
import { TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { TFunction } from "react-i18next";

export const selectBadgeTitle = (typeCode: TypeCodeTask, t: TFunction) => {
    const translations: Record<TypeCodeTask, string> = {
        ONE_TIME: TaskTranslations.TASK_TEMPORALITY_ONE_DAY,
        DAILY: TranslationKeys.DAILY,
        WEEKLY: TranslationKeys.WEEKLY,
        MONTHLY: TranslationKeys.MONTHLY,
        ANNUALY: TranslationKeys.ANNUALY,
    };

    return t(translations[typeCode]);
};
