import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";

type IExtraParams = {
    assignedTo: SelectAssignedModel;
    filterdata: TaskGridSecondaryFilterModel;
    planId: string | null;
    companyId: string;
};

export const fillTaskCountersExtraparams = ({ assignedTo, filterdata, planId, companyId }: IExtraParams): string => {
    const {
        responsable,
        workingPositionDepartments,
        baseTask,
        frequency,
        toHour,
        fromHour,
        isPhotoRequired,
        isDataRequired,
        isCritical,
        taskType,
        classifications,
    } = filterdata;
    const params = new URLSearchParams();

    const assignedToParams: Record<string, string> = {
        user: "UserId",
        department: "DepartmentId",
        subDepartment: "SubDepartmentId",
    };

    if (companyId !== "-1") params.append("CompanyId", String(companyId));
    if (!!baseTask.length && baseTask !== "-1") params.append("FK_BaseTask", baseTask);
    if (!!planId && !!planId.length) params.append("FK_PlanAPPCC", planId);
    if (frequency) params.append("Frequency", frequency);
    if (fromHour) params.append("FromHour", fromHour);
    if (toHour) params.append("ToHour", toHour);
    if (isPhotoRequired) params.append("IsPhotoRequired", String(isPhotoRequired));
    if (isDataRequired) params.append("IsDataRequired", String(isDataRequired));
    if (isCritical) params.append("IsCritical", String(isCritical));
    if (taskType && taskType !== "-1") params.append("taskType", taskType);
    if (!!classifications.length) params.append("BaseTaskTypesId", classifications.map(({ value }) => value).join(";"));

    if (assignedTo === "workingPosition") {
        params.append("IsAssignedToQR", "true");
        params.append("AssignedTo", "WORKING_POSITION");
        params.append("workingPositionId", String(responsable.value));
        if (workingPositionDepartments.value !== "-1")
            params.append("DepartmentId", String(workingPositionDepartments.value));
        return params.toString();
    }
    params.append("AssignedTo", assignedTo);

    if (responsable.value.length) params.append(assignedToParams[assignedTo], responsable.value);

    return params.toString();
};
