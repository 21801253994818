import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { Popover } from "app/components_v2/Popover/Popover";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { AuditTranslation, TranslationKeys } from "app/translation/translationKeys";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "usehooks-ts";

type AuditSelectorProps = {
    sortDirection: SortedTypeModel;
    sortField: string;
    onSortChange: (sortDirection: SortedTypeModel, sortField: string) => void;
};

export const AuditSelector: FC<AuditSelectorProps> = ({ sortDirection, sortField, onSortChange }) => {
    const { t } = useTranslation();

    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const popoverTaget = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(popoverTaget, () => {
        if (isOrderModalOpen) setIsOrderModalOpen(false);
    });

    const hidePopover = () => setIsOrderModalOpen(false);

    const onClickModal = (direction: SortedTypeModel) => {
        onSortChange(direction, "OpenUser.FirstName");
        hidePopover();
    };

    const getPopoverItems = () => [
        {
            text: t(AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DEFAULT),
            onClick: () => onClickModal("default"),
            active: sortDirection === "default" && sortField === "OpenUser.FirstName",
        },
        {
            text: t(AuditTranslation.AUDIT_ORDER_BY_AUDITOR_ASC),
            onClick: () => onClickModal("asc"),
            active: sortDirection === "asc" && sortField === "OpenUser.FirstName",
        },
        {
            text: t(AuditTranslation.AUDIT_ORDER_BY_AUDITOR_DESC),
            onClick: () => onClickModal("desc"),
            active: sortDirection === "desc" && sortField === "OpenUser.FirstName",
        },
    ];

    return (
        <div
            ref={popoverTaget}
            className="auditSelector"
            onClick={(e) => {
                e.stopPropagation();
                setIsOrderModalOpen((prev) => !prev);
            }}
        >
            <p className="auditSelector__text">{t(TranslationKeys.ORDER_BY_STARS)}</p>
            <div className="auditSelector__order">
                <p className="auditSelector__order__text">
                    {getPopoverItems().find(({ active }) => active)?.text || ""}
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="auditSelector__order__icon" />
            </div>
            {isOrderModalOpen && (
                <Popover
                    target={popoverTaget.current}
                    position="bottomLeft"
                    onResize={hidePopover}
                    onScroll={hidePopover}
                    scrollTarget={document.querySelector(".customContainer")}
                    portal={false}
                    className="auditSelector__popover"
                >
                    <DropdownList items={getPopoverItems()} />
                </Popover>
            )}
        </div>
    );
};
