import { Input } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { ActionType } from "app/models/FormComponentsModel";
import MeasurementUnitService from "app/services/05-QUA/MeasurementUnitService";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { MeasurementUnitModalProps } from "./types/MeasurementUnitModalTypes";

export const MeasurementUnitModal: FC<MeasurementUnitModalProps> = ({
    unit,
    variant = "primary",
    onSave,
    onEdit,
    onCancel,
}) => {
    const { t } = useTranslation();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { getErrorMessage } = useFetchErrors();

    const [measurementUnit, setMeasurementUnit] = useState<string>(unit.unit);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const action: ActionType = !unit.unit.length ? "create" : "edit";

    const getTitle =
        action === "create"
            ? t(TranslationKeys.SAVE_MEASUREMENT_UNIT_TITLE)
            : t(TranslationKeys.EDIT_MEASUREMENT_UNIT_TITLE);

    const getConfirmButton = action === "create" ? t(TranslationCommon.CREATE) : t(TranslationCommon.SAVE);

    const handleAdd = async () => {
        const { data, status, getParsedError } = await MeasurementUnitService.Save({ id: 0, unit: measurementUnit });
        const error = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), error)) return;
        handleSuccessManager(t(TranslationModals.MEASUREMENT_UNIT_SAVED_TOAST));
        onSave(data);
    };

    const handleEdit = async () => {
        const { data, status, getParsedError } = await MeasurementUnitService.Edit(unit.id, {
            id: 0,
            unit: measurementUnit,
        });
        const error = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), error)) return;
        handleSuccessManager(t(TranslationModals.MEASUREMENT_UNIT_EDITED_TOAST));
        onEdit(data);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!validate()) return;
        setIsLoading(true);
        if (action === "create") await handleAdd();
        else await handleEdit();
        setIsLoading(false);
    };

    const validate = () => {
        setErrorMessage("");
        if (!measurementUnit.length) {
            setErrorMessage(t(TranslationErrors.MEASUREMENT_UNIT_CANNOT_BE_NULL));
            return false;
        }
        return true;
    };

    return (
        <GenericModal
            size="sm"
            header={{ title: getTitle, onClose: onCancel, variant }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: getConfirmButton,
                        form: "editMeasurementUnit",
                        type: "submit",
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "secondary",
                        onClick: onCancel,
                        variant,
                    }}
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={handleSubmit} id={"editMeasurementUnit"}>
                <Input
                    onChange={setMeasurementUnit}
                    label={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                    placeholder={t(TranslationKeys.ASSET_UNIT_OF_MEASURE)}
                    value={measurementUnit}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
