import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Label } from "app/components_v2/__inputs/Label";
import { Input } from "app/components_v2/__inputs";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { DynamicFieldsType } from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type DynamicFieldItemProps = {
    id: string;
    value: string;
    checked?: boolean;
    dynamicFieldType?: DynamicFieldsType;
    label?: string;
    errorMessage?: string;
    focus?: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
    onChangeCheckbox?: (value: boolean) => void;
    onDeleteItem: () => void;
    hideDeleteButton?: boolean;
    isAnOption?: boolean;
};

export const DynamicFieldItem: FC<DynamicFieldItemProps> = ({
    checked,
    onChange,
    onChangeCheckbox,
    value,
    dynamicFieldType,
    id,
    onDeleteItem,
    label,
    errorMessage,
    focus,
    disabled,
    hideDeleteButton,
    isAnOption,
}) => {
    const { t } = useTranslation();
    const { getDynamicFieldPlaceholderTranslation, getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();
    const labelText = label || (dynamicFieldType !== undefined ? getDynamicFieldTranslation(dynamicFieldType) : "");
    const showCheckbox = checked !== undefined && !!onChangeCheckbox;
    const showLabel = !!labelText.length && !isAnOption;

    const placeholder = getDynamicFieldPlaceholderTranslation(dynamicFieldType || "DATE", isAnOption);
    const handleDelete = () => {
        if (disabled) return;
        onDeleteItem();
    };

    return (
        <div className="dynamicFieldItem">
            <div className="dynamicFieldItem__container">
                {(showLabel || showCheckbox) && (
                    <div className="dynamicFieldItem__labels">
                        {showLabel && <Label label={labelText} disabled={disabled} size="sm" />}
                        {showCheckbox && (
                            <CheckBox
                                checked={checked}
                                onChange={onChangeCheckbox}
                                label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_REQUIRED)}
                                htmlFor={`required-checkbox-${id}`}
                                size="sm"
                                disabled={disabled}
                            />
                        )}
                    </div>
                )}
                <div className="dynamicFieldItem__input">
                    <Input
                        onChange={onChange}
                        value={value}
                        showBorderColor={!!errorMessage}
                        focus={focus}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                    {!disabled && !hideDeleteButton && (
                        <FontAwesomeIcon className="deleteInputIcon" onClick={handleDelete} icon={faCircleXmark} />
                    )}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
        </div>
    );
};
