import { FC, useState } from "react";
import { ProviderProps } from "app/state/Providers/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    INITIAL_ISSUE_SF_VALUES,
    INITIAL_ISSUE_SORT_DIRECTION,
    INITIAL_ISSUE_SORT_FIELD,
} from "../../constants/issueConstants";
import { IssueGridContext, IssueGridSFModel } from "../context/issueGridContext";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";

export const IssueGridProvider: FC<ProviderProps> = ({ children }) => {
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [sortField, setSortField] = useState<string>(INITIAL_ISSUE_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_ISSUE_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] = useState<IssueGridSFModel>(INITIAL_ISSUE_SF_VALUES);

    const { handleCompanyChange, selectCompany } = useSelectCompanyTable();

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<IssueGridSFModel>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    return (
        <IssueGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                currentTab,
                onChangeCurrentTab: setCurrentTab,
                sortDirection,
                sortField,
                onSortChange,
                onSFChange,
                secondaryFilterValues,
                onCompanyChange: handleCompanyChange,
                selectCompany,
            }}
        >
            {children}
        </IssueGridContext.Provider>
    );
};
