import { FC } from "react";

type CarrouselItemSkelletonProps = {
    quantity?: number;
};

const CarrouselItemSkelleton: FC<CarrouselItemSkelletonProps> = ({ quantity = 1 }) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, i) => (
                <div className="carrouselItem carrouselItem-skelleton" key={i}>
                    <div className="carrouselItem-skelleton__avatar"></div>
                    <div className="carrouselItem-skelleton__body">
                        <div className="carrouselItem-skelleton__body__text"></div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CarrouselItemSkelleton;
