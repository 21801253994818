import { FC } from "react";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { SupervisorDoughnutColors } from "app/shared/Constants";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import { TableError } from "../Table/TableError/TableError";
import { TeamValorationDetail } from "./TeamValorationDetail/TeamValorationDetail";
import { FastReviewValorationDetail } from "./FastReviewValorationDetail/FastReviewValorationDetail";
import { FastCommentValorationDetail } from "./FastCommentValorationDetail/FastCommentValorationDetail";
import { DoughnutValorationDetail } from "./DoughnutValorationDetail/DoughnutValorationDetail";
import { TaskProgressBarValorationDetail } from "./TaskProgressBarValorationDetail/TaskProgressBarValorationDetail";
import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";

type SupervisorValorationDetailProps = {
    data: AnalistSupervisorDetailModel;
};

export const SupervisorValorationDetail: FC<SupervisorValorationDetailProps> = ({ data }) => {
    const {
        supervisorTeamAVG,
        supervisorFastReview,
        supervisorFastCommentModel,
        supervisorReviewQualityModel,
        supervisorTaskStateModel,
    } = data;

    const isTeamAvgEmpty = () => !supervisorTeamAVG.avg;

    const isSupervisorArrayDataEmpty = (array: UserScoreModel[]) => array.every(({ count }) => count === 0);

    const emptyDetail = () =>
        isTeamAvgEmpty() &&
        isSupervisorArrayDataEmpty(supervisorFastReview) &&
        isSupervisorArrayDataEmpty(supervisorFastCommentModel) &&
        isSupervisorArrayDataEmpty(supervisorReviewQualityModel) &&
        isSupervisorArrayDataEmpty(supervisorTaskStateModel);

    return emptyDetail() ? (
        <TableError icon={faListCheck} />
    ) : (
        <div className="supervisorValorationDetail">
            <TeamValorationDetail teamAvg={supervisorTeamAVG} emptyState={isTeamAvgEmpty()} />
            <FastReviewValorationDetail
                fastReview={supervisorFastReview}
                emptyState={isSupervisorArrayDataEmpty(supervisorFastReview)}
            />
            <FastCommentValorationDetail
                fastComments={supervisorFastCommentModel}
                emptyState={isSupervisorArrayDataEmpty(supervisorFastCommentModel)}
            />
            <DoughnutValorationDetail
                doughnutData={supervisorReviewQualityModel}
                emptyState={isSupervisorArrayDataEmpty(supervisorReviewQualityModel)}
                colors={SupervisorDoughnutColors}
            />
            <TaskProgressBarValorationDetail
                taskData={supervisorTaskStateModel}
                emptyState={isSupervisorArrayDataEmpty(supervisorTaskStateModel)}
            />
        </div>
    );
};
