import { SecScreen } from "app/shared/Constants";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useSession } from "./useSession";
import {
    isUserTypeAdmin,
    isUserTypeAnalist,
    isUserTypeSupervisor,
    isUserTypeSupervisorOrAnalist,
    isUserTypeWorker,
} from "app/helpers/userTypeCheck";

export const useUserType = () => {
    const session = useSession();

    return {
        isWorker: isUserTypeWorker(session?.user.userType),
        isSupervisor: isUserTypeSupervisor(session?.user.userType),
        isAnalist: isUserTypeAnalist(session?.user.userType),
        isSupervisorOrAnalist: isUserTypeSupervisorOrAnalist(session?.user.userType),
        isAdmin: isUserTypeAdmin(session?.user.userType),
        isAuditor:
            hasPermissionToView(session, SecScreen.PLAN_APPCC) ||
            hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG),
    };
};
