import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import AssetService from "app/services/05-QUA/AssetService";
import { AssetTranslations, TranslationCommon } from "app/translation/translationKeys";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { IssueTypeCode, SecScreen } from "app/shared/Constants";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { Switch } from "app/components_v2/__inputs";
import { Taskfield } from "../Taskfield/Taskfield";
import { IssueModal } from "app/components_v2/__modals/IssueModal/IssueModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";

type GroupedTaskFieldProps = {
    groupedFields: TaskInstanceFieldModel[];
    customSetFields: (e: TaskInstanceFieldModel) => void;
    readOnly?: boolean;
    customErrors?: Map<number | string, string> | undefined;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
    statusCode: string;
};

export const GroupedTaskField: FC<GroupedTaskFieldProps> = ({
    groupedFields,
    customSetFields,
    readOnly,
    customErrors,
    errorRef,
    disabled,
    statusCode,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { fillValues, assetId, reset } = useContext(IssueModalContext);

    const [notWorking, setNotWorking] = useState(groupedFields[0].asset2?.notWorking || false);
    const [isIssueModalAssetNotWorkingOpen, setIsIssueModalAssetNotWorkingOpen] = useState<boolean>(false);
    const [isAssetFixed, setIsAssetFixed] = useState(false);
    const [isLoadingAssetRepair, setIsLoadingAssetRepair] = useState(false);

    const handleNotWorkingAsset = async (value: boolean, assetId?: number) => {
        if (!assetId) return;

        groupedFields.forEach((taskfield) => {
            if (!taskfield || !taskfield.asset2) return;
            customSetFields({ ...taskfield, asset2: { ...taskfield.asset2, notWorking: true } });
        });

        fillValues({
            issueType: IssueTypeCode.TASK_ASSET,
            taskinstanceId: groupedFields[0].taskInstanceId,
            assetId,
            isAssetWorking: !value,
        });

        if (value) {
            setNotWorking(value);
            setIsIssueModalAssetNotWorkingOpen(true);
        } else setIsAssetFixed(true);
    };

    const handleFixedAsset = async () => {
        setIsLoadingAssetRepair(true);
        if (!assetId) {
            handleToast({ title: t(AssetTranslations.CANNOT_BE_REPAIRED), type: "alert", variant: "danger" });
            return;
        }

        groupedFields.forEach((taskfield) => {
            if (!taskfield || !taskfield.asset2) return;
            customSetFields({ ...taskfield, asset2: { ...taskfield.asset2, notWorking: false } });
        });

        setNotWorking(false);
        reset();
        await AssetService.NotWorking(assetId, { value: false });
        setIsAssetFixed(false);
        setIsLoadingAssetRepair(false);
    };

    const handleCloseIssueAssetNotWorkingModal = (isSubmitting: boolean) => {
        setIsIssueModalAssetNotWorkingOpen(false);
        if (isSubmitting) return;

        setNotWorking(false);
        reset();
    };

    return (
        <div className="groupedTaskField">
            {isIssueModalAssetNotWorkingOpen && (
                <IssueModal onCloseModal={handleCloseIssueAssetNotWorkingModal} disableAsset />
            )}
            {isAssetFixed && (
                <ConfirmModal
                    title={t(AssetTranslations.ASSET_REPARATION_TITLE)}
                    description={t(AssetTranslations.ASSET_REPARATION_DESCRIPTION)}
                    onConfirm={handleFixedAsset}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onClose={() => setIsAssetFixed(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={isLoadingAssetRepair}
                />
            )}

            <div className="groupedTaskField__header">
                {groupedFields[0]?.asset2?.name && (
                    <h2 className="groupedTaskField__header__title">{`${groupedFields[0]?.asset2?.name}${
                        !!groupedFields[0]?.asset2?.assetId.length ? ` (${groupedFields[0]?.asset2?.assetId})` : ""
                    }`}</h2>
                )}
                {hasPermission(SecScreen.OPEN_MANUAL_ISSUE) &&
                    groupedFields[0]?.asset2?.fK_Company != null &&
                    groupedFields[0]?.asset2?.fK_Company != 0 && (
                        <div className="groupedTaskField__header__notWorking">
                            <p className="groupedTaskField__header__title">
                                {t(AssetTranslations.ASSET_NOT_WORKING_QUESTION)}
                            </p>
                            <Switch
                                checked={notWorking}
                                onChange={(value) => handleNotWorkingAsset(value, groupedFields[0].fK_Asset2)}
                                disabled={readOnly}
                            />
                        </div>
                    )}
            </div>
            {!notWorking &&
                groupedFields
                    .filter(({ isHidden }) => !isHidden)
                    .map((field) => {
                        return (
                            <Taskfield
                                key={field.id}
                                field={field}
                                handleInputChange={customSetFields}
                                customError={customErrors?.get(field.id)}
                                readonly={readOnly}
                                errorRef={errorRef}
                                disabled={disabled}
                                statusCode={statusCode}
                            />
                        );
                    })}
        </div>
    );
};
