import { faStarSharp } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { DateRangePicker } from "app/components_v2/__inputs";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { Popover } from "app/components_v2/Popover/Popover";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { ReviewFeedbackCountersDto } from "app/dtos/02-TAR/TaskInstance/ReviewFeedbackCountersDto";
import { fillTaskInstanceExtraParams } from "app/helpers/ExtraParams/taskInstanceFillExtraParams";
import { useGoBack, useSession, useTitle, useWindowSize } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useGetPageTitle } from "app/hooks/useGetPageTitle";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import TaskInstanceReviewService from "app/services/02-TAR/TaskInstanceReviewService";
import { PaginationDefaults, screenSize } from "app/shared/Constants";
import { TranslationErrors, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { FilterData } from "../01-TAR-Dashboard/constants/DashboardResumeContext";
import { TaskDetailModal } from "../01-TAR-Dashboard/TaskDetail/TaskDetailModal";
import { TaskPillList } from "../01-TAR-Dashboard/TaskPillList/TaskPillList";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { DateRange } from "app/models/utilities";

export const Feedback: FC = () => {
    const { width } = useWindowSize();
    const pageTitle = useGetPageTitle();
    const { taskInstanceId } = useParams();
    const { t } = useTranslation();
    const session = useSession();
    const location = useLocation();
    const nav = useNavigate();
    const { realParentGoBack } = useGoBack();
    const { handleToast } = useToast();

    useTitle(
        t(
            taskInstanceId !== undefined
                ? TranslationTitles.FEEDBACK_DETAIL_TITLE
                : TranslationTitles.FEEDBACK_PAGE_TITLE
        )
    );

    const [currentTab, setCurrentTab] = useState(0);
    const [feedback, setFeedback] = useState<TaskInstanceModel[]>([]);
    const [feedbackWithPhotos, setFeedbackWithPhotos] = useState<TaskInstanceModel[]>([]);
    const [feedbackCounters, setFeedbackCounters] = useState<ReviewFeedbackCountersDto>({
        withReviewCount: 0,
        withoutReviewCount: 0,
    });
    const filterData: FilterData = {
        company: session?.workingCompany?.companyId?.toString(),
    };
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageIndexComments, setPageIndexComments] = useState(0);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    const sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));
    const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
    const [endDate, setEndDate] = useState<Date | undefined>(new Date());
    const popoverTaget = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(popoverTaget, () => {
        if (isOrderModalOpen) setIsOrderModalOpen(false);
    });

    const feedbackExtraParams = (filter: FilterData, withFeedbackComments: boolean) => {
        const { company } = filter;
        return fillTaskInstanceExtraParams({
            ...filter,
            companyId: Number(company),
            hasFeedbackComment: withFeedbackComments,
        });
    };

    const queryParams = new URLSearchParams(location.search);
    const hasComments = !!queryParams.get("comments");

    const fetchTaskInstanceFeedbackCounters = async () => {
        const sr = await TaskInstanceReviewService.GetFeedbackCounters({
            extraParams: feedbackExtraParams({ ...filterData, deadlineDate: startDate, deadlineDate1: endDate }, true),
            sortField: "Stars",
            sortDirection,
        });
        sr.status()
            ? setFeedbackCounters(sr.data)
            : handleToast({
                  title: t(TranslationErrors.FEEDBACK_COUNTERS_ERROR),
                  variant: "danger",
                  type: "alert",
              });
    };

    const fetchTaskInstanceFeedback = async (
        withFeedbackComments: boolean,
        pageIndex?: number,
        pageSize?: number,
        startDateProp?: Date,
        endDateProp?: Date
    ) => {
        setIsLoading(true);
        let extraParams = feedbackExtraParams(
            {
                ...filterData,
                deadlineDate: startDateProp || startDate,
                deadlineDate1: endDateProp || endDate,
            },
            withFeedbackComments
        );
        const sr = await TaskInstanceReviewService.GetFeedbackTaskInstances({
            extraParams,
            pageSize,
            pageIndex: pageIndex && (pageIndex === 0 ? 0 : pageIndex - 1),
            sortField: "Stars",
            sortDirection,
        });
        if (sr.status()) withFeedbackComments ? setFeedbackWithPhotos(sr.data.list) : setFeedback(sr.data.list);
        setIsLoading(false);
    };

    const onClickChangeTab = (current: number) => {
        currentTab !== current && setIsLoading(true);
        setCurrentTab(current);
        current === 0 ? nav(`?comments=true`) : nav(``);
    };

    const onDetailClick = (id: number) => (hasComments ? nav(`${id}?comments=true`) : nav(`${id}`));

    const onChangeDates = (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            setStartDate(start);
            setEndDate(undefined);
        }
        if (end) {
            setEndDate(end);
            fetchTaskInstanceFeedback(hasComments, 0, PaginationDefaults.PAGE_SIZE, start, end);
            setPageIndex(1);
        }
    };

    const getSortText = () => {
        if (sortDirection === "default") return t(TranslationKeys.FEEDBACK_MODAL_ORDER_RECENT);
        if (sortDirection === "asc") return t(TranslationKeys.FEEDBACK_MODAL_ORDER_LOWER);
        if (sortDirection === "desc") return t(TranslationKeys.FEEDBACK_MODAL_ORDER_HIGHER);
    };

    const onClickModal = (direction: SortedTypeModel) => {
        setSortDirection(direction);
        hidePopover();
    };

    const getPopoverItems = () => [
        {
            text: t(TranslationKeys.FEEDBACK_MODAL_ORDER_RECENT),
            onClick: () => onClickModal("default"),
            active: sortDirection === "default",
        },
        {
            text: t(TranslationKeys.FEEDBACK_MODAL_ORDER_LOWER),
            onClick: () => onClickModal("asc"),
            active: sortDirection === "asc",
        },
        {
            text: t(TranslationKeys.FEEDBACK_MODAL_ORDER_HIGHER),
            onClick: () => onClickModal("desc"),
            active: sortDirection === "desc",
        },
    ];

    const hidePopover = () => setIsOrderModalOpen(false);

    useEffect(() => {
        fetchTaskInstanceFeedback(
            hasComments,
            hasComments ? pageIndexComments : pageIndex,
            PaginationDefaults.PAGE_SIZE
        );
        setCurrentTab(hasComments ? 0 : 1);
    }, [hasComments, sortDirection, session?.workingCompany?.companyId]);

    useEffect(() => {
        if (startDate && endDate) {
            fetchTaskInstanceFeedbackCounters();
        }
    }, [hasComments, startDate, endDate, session?.workingCompany?.companyId]);

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.WITH_REVIEW),
            onClick: onClickChangeTab,
            rightCount: feedbackCounters?.withReviewCount,
            children: (
                <TaskPillList
                    loadPage={(page: number, pageSize: number) => {
                        fetchTaskInstanceFeedback(hasComments, page, pageSize);
                    }}
                    filterData={filterData}
                    list={feedbackWithPhotos}
                    total={feedbackCounters?.withReviewCount}
                    isLoading={isLoading}
                    onChangePageIndex={setPageIndexComments}
                    pageIndex={pageIndexComments}
                    onDetailClick={onDetailClick}
                    starSize="xs"
                    starColor="yellow"
                    editable={false}
                    hideMobilePhotos
                    isSupervisor={false}
                />
            ),
            type: "dark",
        },
        {
            text: t(TranslationKeys.WITHOUT_REVIEW),
            onClick: onClickChangeTab,
            rightCount: feedbackCounters?.withoutReviewCount,
            children: (
                <TaskPillList
                    loadPage={(page: number, pageSize: number) => {
                        fetchTaskInstanceFeedback(hasComments, page, pageSize);
                    }}
                    filterData={filterData}
                    list={feedback}
                    total={feedbackCounters?.withoutReviewCount}
                    isLoading={isLoading}
                    onChangePageIndex={setPageIndex}
                    pageIndex={pageIndex}
                    onDetailClick={onDetailClick}
                    starSize="xs"
                    starColor="yellow"
                    editable={false}
                    isSupervisor={false}
                />
            ),
            type: "dark",
        },
    ];

    return (
        <PageContainer
            fullHeight
            header={
                <TabHeader
                    tabs={
                        <>
                            <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
                            <div
                                ref={popoverTaget}
                                className="feedback__order"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsOrderModalOpen((prev) => !prev);
                                }}
                            >
                                <p className="feedback__order__text mobile_display_none">
                                    {t(TranslationKeys.ORDER_BY_STARS)}
                                </p>
                                <div className="feedback__order__container">
                                    <p className="feedback__order__text feedback__order__text__width">
                                        {getSortText()}
                                    </p>
                                    <FontAwesomeIcon icon={faChevronDown} className="feedback__order__icon" />
                                </div>
                                {isOrderModalOpen && (
                                    <Popover
                                        target={popoverTaget.current}
                                        position="bottomRight"
                                        onResize={hidePopover}
                                        onScroll={hidePopover}
                                        scrollTarget={document.querySelector(".customContainer")}
                                        portal={false}
                                        className="popeverFeedback"
                                    >
                                        <DropdownList items={getPopoverItems()} />
                                    </Popover>
                                )}
                            </div>
                        </>
                    }
                >
                    {!!pageTitle.length && width >= screenSize.TABLET && <p className="pageTitle">{pageTitle}</p>}
                    <div className="feedback__dateRangePicker">
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChangeDates}
                            range
                            inverted
                            showMoreOptions
                        />
                    </div>
                </TabHeader>
            }
        >
            {(currentTab === 0 && !feedbackCounters.withReviewCount && !isLoading) ||
            (currentTab === 1 && !feedbackCounters.withoutReviewCount && !isLoading) ? (
                <TableError icon={faStarSharp} title={t(TranslationKeys.EMPTY_LIST_TASKI)} size="xl" />
            ) : (
                tabs[currentTab].children
            )}
            {taskInstanceId && (
                <TaskDetailModal taskInstanceId={taskInstanceId} onClose={realParentGoBack} editTaskDetail={false} />
            )}
        </PageContainer>
    );
};
