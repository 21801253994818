import { FC } from "react";

type RankingPillSkelletonProps = {
    variant?: "default" | "percentage";
    hideValoration?: boolean;
};

export const RankingPillSkelleton: FC<RankingPillSkelletonProps> = ({
    variant = "default",
    hideValoration,
}) => {
    return (
        <div className={`rankingPill--${variant} rankingPill--shadow`}>
            <div className="rankingPill__container">
                <div
                    className={`rankingPill__info ${
                        hideValoration ? "skelletonRankingPill__info--noStars" : ""
                    }`}
                >
                    <div className="rankingPill__info__avatar">
                        <div className="skelletonRankingPill__info">
                            <div className="skelletonRankingPill__info__icon"></div>
                            <div className="skelletonRankingPill__info__text"></div>
                        </div>
                    </div>
                </div>
                {!hideValoration && (
                    <div className="rankingPill__info__stars">
                        <div className="skelletonRankingPill__userValoration"></div>
                        <div className="skelletonRankingPill__badge"></div>
                    </div>
                )}
                <div className="rankingPill__counters">
                    {variant === "default" ? (
                        <>
                            <div className="skelletonRankingPill__counter"></div>
                            <div className="skelletonRankingPill__counter"></div>
                        </>
                    ) : (
                        <>
                            <div className="skelletonRankingPill__counter--fullWidth"></div>
                            <div className="skelletonRankingPill__counter--fullWidth"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
