import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

type CriticalTaskProps = {
    title?: string;
};

export const CriticalTask: FC<CriticalTaskProps> = ({ title }) => {
    return (
        <div className={`${title ? "criticalTask" : ""}`}>
            <FontAwesomeIcon
                className={`criticalTask__icon${title ? "--gray" : ""}`}
                icon={faCircleExclamation}
            />
            {title && <p className="criticalTask__title">{title}</p>}
        </div>
    );
};
