import { Input } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useToast } from "app/hooks/Toast/useToast";
import { PlanAPPCCModel } from "app/models/05-QUA/PlanAPPCCModels/PlanAPPCCModels";
import { ActionType } from "app/models/FormComponentsModel";
import PlanAPPCCService from "app/services/05-QUA/PlanAPPCCService";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type EditPlanAPPCCModalProps = {
    onClose: () => void;
    onSave: (value: PlanAPPCCModel, type: ActionType) => void;
    value: PlanAPPCCModel | null;
    type: ActionType;
};

const formId = "editPlanForm";

const INITIAL_PLAN: PlanAPPCCModel = { id: 0, name: "", counter: 0 };

export const EditPlanAPPCCModal: FC<EditPlanAPPCCModalProps> = ({ onClose, onSave, value, type }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [plan, setPlan] = useState<PlanAPPCCModel>(value || INITIAL_PLAN);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { name } = plan;
    const title: Record<ActionType, string> = {
        create: t(TranslationKeys.PLAN_CREATE),
        edit: t(TranslationKeys.PLAN_EDIT),
    };
    const actionButton: Record<ActionType, string> = {
        create: t(TranslationCommon.CREATE),
        edit: t(TranslationCommon.SAVE),
    };

    const handleInputChange = (newValue: Partial<PlanAPPCCModel>) => setPlan((prev) => ({ ...prev, ...newValue }));

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSubmitting(true);

        if (type === "edit") {
            await handleEdit();
            return;
        }

        await handleCreate();
    };

    const handleEdit = async () => {
        const { status } = await PlanAPPCCService.Update(plan);

        if (!status()) {
            handleToast({ title: t(TranslationModals.PLANS_FAILED_EDIT), variant: "danger", type: "alert" });
            setIsSubmitting(false);
            return;
        }

        onSave(plan, "edit");
    };

    const handleCreate = async () => {
        const { status } = await PlanAPPCCService.Create(plan.name);

        if (!status()) {
            handleToast({ title: t(TranslationModals.PLANS_FAILED_CREATE), variant: "danger", type: "alert" });
            setIsSubmitting(false);
            return;
        }

        onSave(plan, "create");
    };

    const validate = () => {
        setErrorMessage("");
        if (!name.length) {
            setErrorMessage(t(TranslationCommon.INPUT_NOT_EMPTY));
            return false;
        }

        return true;
    };

    return (
        <GenericModal
            header={{ title: title[type], onClose: onClose, variant: "appcc" }}
            portal
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        type: "submit",
                        text: actionButton[type],
                        fullWidth: true,
                        form: formId,
                        variant: "appcc",
                    }}
                    closeButton={{
                        onClick: onClose,
                        text: t(TranslationCommon.CANCEL),
                        fullWidth: true,
                        buttonType: "tertiary",
                        variant: "appcc",
                    }}
                    buttonsJustification="end"
                    loading={isSubmitting}
                />
            }
        >
            <form onSubmit={handleSubmit} id={formId}>
                <Input
                    onChange={(newValue) => handleInputChange({ name: newValue })}
                    value={name}
                    label={t(TranslationKeys.EDIT_FORM_PLAN_NAME_LABEL)}
                    placeholder={t(TranslationKeys.EDIT_FORM_PLAN_NAME_PLACEHOLDER)}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
