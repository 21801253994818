import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { UploadPhoto } from "app/components_v2/UploadPhoto/UploadPhoto";
import { ImageBase64String } from "app/dtos/ImageBase64String";
import { ProfileService } from "app/services";
import { useDispatch } from "react-redux";
import { useSession } from "app/hooks";
import { Actions } from "app/state/actions";

export const UploadPhotoSection: FC<SectionsShowProp> = ({ show }) => {
    const dispatch = useDispatch();
    const session = useSession();
    const handlePhotoUpload = async (imageBase64: ImageBase64String) => {
        await ProfileService.UploadPhoto(imageBase64);
        dispatch(Actions.updateImageProfile(imageBase64.imageBase64Content));
    };
    return (
        <Section title="Upload Photo" show={show}>
            <div className="uploadPhotoBackground">
                <UploadPhoto onUpload={handlePhotoUpload} photoUrl={session?.user.profilePictureURL || ""} />
                <UploadPhoto onUpload={handlePhotoUpload} photoUrl={session?.user.profilePictureURL || ""} squared />
            </div>
        </Section>
    );
};
