import { Avatar } from "app/components_v2/Avatar/Avatar";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { FC } from "react";

type AlertNotificationsModalInfoProps = {
    profilePicture?: string;
    avatarName: string;
    colorId: number;
    date: string;
};

export const AlertNotificationsModalInfo: FC<AlertNotificationsModalInfoProps> = ({
    avatarName,
    colorId,
    date,
    profilePicture,
}) => {
    return (
        <CellWithAvatar
            avatarName={avatarName}
            colorId={colorId}
            badgeTitle={date}
            badgeVariant="none"
            avatarSize="sm"
            img={profilePicture}
        />
    );
};
