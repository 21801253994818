import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskTemporalityType } from "app/models/02-TAR/TaskWizard";
import { FC, ReactNode } from "react";
import { TaskTemporalitySporadicDisabled } from "./TaskTemporalitySporadicDisabled/TaskTemporalitySporadicDisabled";
import { TaskTemporalityDateRangeDisabled } from "./TaskTemporalityDateRangeDisabled/TaskTemporalityDateRangeDisabled";
import { TaskTemporalityOneDayDisabled } from "./TaskTemporalityOneDayDisabled/TaskTemporalityOneDayDisabled";
import { TaskTemporalityPeriodicalDisabled } from "./TaskTemporalityPeriodicalDisabled/TaskTemporalityPeriodicalDisabled";

type TaskTemporalityDisabledsProps = {
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    taskTemporality: TaskTemporalityModel;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityDisableds: FC<TaskTemporalityDisabledsProps> = ({
    customError,
    onChange,
    hint,
    isDisabled,
    min,
    taskstartLabel,
    taskTemporality,
}) => {
    const { temporalityType, taskTemporalityDateRange } = taskTemporality;

    if (temporalityType === "SPORADIC")
        return (
            <TaskTemporalitySporadicDisabled
                {...taskTemporality}
                customError={customError}
                onChange={onChange}
                min={min}
                taskstartLabel={taskstartLabel}
                isDisabled={isDisabled}
                hint={hint}
            />
        );

    if (temporalityType === "RANGE_DATES")
        return <TaskTemporalityDateRangeDisabled taskTemporalityDateRange={taskTemporalityDateRange} />;

    if (temporalityType === "ONE_DAY")
        return (
            <TaskTemporalityOneDayDisabled
                {...taskTemporality}
                customError={customError}
                onChange={onChange}
                min={min}
                taskstartLabel={taskstartLabel}
                isDisabled={isDisabled}
                hint={hint}
            />
        );

    if (temporalityType === "PERIODICAL" && taskTemporality.recurringTask !== null)
        return (
            <TaskTemporalityPeriodicalDisabled
                {...taskTemporality}
                customError={customError}
                onChange={onChange}
                min={min}
                taskstartLabel={taskstartLabel}
                isDisabled={isDisabled}
                hint={hint}
                recurringTask={taskTemporality.recurringTask}
            />
        );

    return null;
};
