import { FC } from "react";
import { useStepFour } from "./hooks";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { StepFourProps } from "./models";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { SelectOptions } from "app/components_v2/__inputs";
import { TaskTemporalityPeriodical } from "app/components_v2/WizardTask/TaskTemporalityPeriodical/TaskTemporalityPeriodical";
import { TaskTemporalityOneDay } from "app/components_v2/WizardTask/TaskTemporalityOneDay/TaskTemporalityOneDay";
import { TaskTemporalitySporadic } from "app/components_v2/WizardTask/TaskTemporalitySporadic/TaskTemporalitySporadic";
import { TaskTemporalityDateRange } from "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRange";
import { TaskTemporalityDisableds } from "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDisableds";

export const StepFour: FC<StepFourProps> = ({
    taskStart,
    taskHours,
    recurringTask,
    finish,
    taskType,
    onChange,
    next,
    issueId,
    temporalityType,
    taskTemporalityDateRange,
    disabledFields,
}) => {
    const {
        validations,
        validate,
        onCloseModal,
        onConfirmModal,
        showModalHoursOutOfTime,
        getTaskTemporalityOptions,
        handleChangeTaskTemporalityType,
    } = useStepFour({
        onChange,
        recurringTask,
        taskHours,
        taskStart,
        finish,
        taskType,
        next,
        issueId,
        temporalityType,
        taskTemporalityDateRange,
        disabledFields,
    });

    const { t } = useTranslation();

    if (disabledFields && disabledFields.disabledType !== "NONE") {
        return (
            <WizardForm validate={validate}>
                <TaskTemporalityDisableds
                    customError={validations}
                    onChange={onChange}
                    taskTemporality={{
                        finish,
                        recurringTask,
                        taskHours,
                        taskStart,
                        taskTemporalityDateRange,
                        taskType,
                        temporalityType,
                    }}
                    min={new Date()}
                />
            </WizardForm>
        );
    }

    return (
        <WizardForm validate={validate}>
            <SelectOptions
                isMulti={false}
                options={getTaskTemporalityOptions()}
                onChange={handleChangeTaskTemporalityType}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                selectedValue={temporalityType}
                errorMessage={validations.temporalityType}
            />
            {temporalityType === "PERIODICAL" && (
                <TaskTemporalityPeriodical
                    customError={validations}
                    finish={finish}
                    onChange={onChange}
                    recurringTask={recurringTask}
                    taskHours={taskHours}
                    taskStart={taskStart}
                    min={new Date()}
                />
            )}
            {temporalityType === "ONE_DAY" && (
                <TaskTemporalityOneDay
                    customError={validations}
                    onChange={onChange}
                    taskHours={taskHours}
                    taskStart={taskStart}
                    min={new Date()}
                />
            )}
            {temporalityType === "SPORADIC" && (
                <TaskTemporalitySporadic customError={validations} onChange={onChange} taskStart={taskStart} />
            )}
            {showModalHoursOutOfTime && (
                <ConfirmModal
                    onConfirm={onConfirmModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={onCloseModal}
                    title={t(TranslationKeys.HOURS_OUT_OF_RANGE_TITLE)}
                    description={t(TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION)}
                    portal
                />
            )}

            {temporalityType === "RANGE_DATES" && (
                <TaskTemporalityDateRange
                    customError={validations}
                    onChange={onChange}
                    taskTemporalityDateRange={taskTemporalityDateRange}
                />
            )}
        </WizardForm>
    );
};
