import { FC, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type PaginationIconProps = {
    icon: IconProp;
    onClick: () => void;
    variant?: TabHeaderVariants;
    isDisabled?: boolean;
};

export const PaginationIcon: FC<PaginationIconProps> = ({ icon, onClick, isDisabled, variant = "primary" }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
        <div
            onMouseDown={() => !isDisabled && setIsFocused(true)}
            onMouseUp={() => !isDisabled && setIsFocused(false)}
            onMouseLeave={() => !isDisabled && setIsFocused(false)}
            className={`paginationArrow--${variant} paginationArrow${
                isDisabled ? "--disabled" : ""
            } paginationArrow--${variant}${isFocused ? "--focused" : ""}`}
            onClick={() => !isDisabled && onClick()}
        >
            <FontAwesomeIcon
                className={`paginationArrow__svg paginationArrow__svg--${variant} paginationArrow__svg${
                    isDisabled ? "--disabled" : ""
                }`}
                icon={icon}
            />
        </div>
    );
};
