import { faArrowRightArrowLeft, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { SecScreen, screenSize } from "app/shared/Constants";
import { SensorModalTranslations, TranslationCommon } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

type SensorLinkedModalFooterProps = {
    onClickReplace: () => void;
    onClickDeactivate: () => void;
    onClose: () => void;
};

export const SensorLinkedModalFooter: FC<SensorLinkedModalFooterProps> = ({
    onClickDeactivate,
    onClickReplace,
    onClose,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <GenericModalFooter
            closeButton={{
                text: t(TranslationCommon.CANCEL),
                onClick: onClose,
                variant: "appcc",
                fullWidth: false,
                isHidden: width < screenSize.TABLET,
            }}
            customButton={
                <div className="sensorLinkedModalFooter">
                    <AddNewButton onClick={onClickReplace} icon={faArrowRightArrowLeft} variant="appcc">
                        {t(SensorModalTranslations.SENSOR_LINKED_REPLACE)}
                    </AddNewButton>
                    {hasPermissionToDelete(SecScreen.ASSETS) && (
                        <AddNewButton onClick={onClickDeactivate} icon={faXmark} variant="appcc">
                            {t(SensorModalTranslations.SENSOR_LINKED_DELETE_ASSET)}
                        </AddNewButton>
                    )}
                </div>
            }
        />
    );
};
