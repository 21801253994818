import { FC } from "react";
import { LegendItemMark } from "./LegendItemMark/LegendItemMark";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { SupervisorAnalistColors } from "../../types";

export type LegendItemProps = {
    color: SupervisorAnalistColors;
    label: string;
    count?: number;
    rounded?: boolean;
    onClick?: () => void;
    percentage?: number;
};

export const LegendItem: FC<LegendItemProps> = ({ color, label, count, rounded, onClick, percentage }) => {
    const hasPercentage = percentage !== undefined;

    return (
        <div className={`legendItem ${onClick ? `legendItem--clickable--${color}` : ""}`} onClick={onClick}>
            <div className="legendItem__container" data-testid="legendItem">
                <LegendItemMark color={color} rounded={rounded} />
                <p className="legendItem__text">
                    {label} {!!count && `(${formatBigNumber(count)})`}
                </p>
            </div>
            {hasPercentage ? <p className="legendItem__percentage">{`${percentage}%`}</p> : <></>}
        </div>
    );
};
