import { TabTaskModel } from "app/models/02-TAR/TaskPage";

export const getTabIndexFromTabValue = (tabIndex: TabTaskModel): number => {
    const tabs: Record<TabTaskModel, number> = {
        ALL: 0,
        QR: 1,
        TEAM: 2,
        SUBTEAM: 3,
        USER: 4,
    };

    return tabs[tabIndex];
};
