import { OptionsSearch } from "app/models/FormComponentsModel";
import { FC } from "react";
import { SelectActionsModel, SelectActionsOptions } from "../types";
import { Icon } from "app/components_v2/Icon/Icon";

type SelectWithActionsItemProps = {
    item: SelectActionsOptions;
    onSelect: (item: OptionsSearch) => void;
    isActive: boolean;
    actions: SelectActionsModel[];
};

export const SelectWithActionsItem: FC<SelectWithActionsItemProps> = ({
    item,
    onSelect,
    isActive,
    actions,
}) => {
    const { label, hideActions } = item;

    return (
        <div
            className={`selectWithActionsItem${isActive ? "--active" : ""}`}
            onClick={() => {
                onSelect(item);
            }}
        >
            <div className="selectWithActionsItem__content">
                <p className={`selectWithActionsItem__content__text`}>{label}</p>
            </div>
            {!hideActions && !!actions.length && (
                <div className="selectWithActionsItem__actions">
                    {actions.map(
                        ({ icon, onClick, hidden }, key) =>
                            !hidden && (
                                <Icon
                                    key={key}
                                    icon={icon}
                                    onClick={() => onClick(item)}
                                    theme="light"
                                    dataTestId={`icon-${key}`}
                                />
                            )
                    )}
                </div>
            )}
        </div>
    );
};
