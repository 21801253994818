import { FC, useState } from "react";

export type DayBubbleProps = {
    letter: string;
    active: boolean;
    onClick: () => void;
    variant?: "primary" | "dark";
    disabled?: boolean;
    readonly?: boolean;
    size?: "xs";
};

export const DayBubble: FC<DayBubbleProps> = ({
    letter,
    active,
    onClick,
    variant = "primary",
    disabled,
    readonly,
    size,
}) => {
    const [value, setValue] = useState(active);

    return (
        <div
            className={`dayBubble--${variant}${active ? "--active" : ""} ${size ? `dayBubble--${size}` : ""} ${
                disabled ? `dayBubble--disabled${active ? "--active" : ""}` : ""
            }
            ${readonly ? `dayBubble--${variant}--readonly${active ? "--active" : ""}` : ""}
            `}
            onClick={() => {
                if (readonly || disabled) return;
                setValue(!value);
                onClick();
            }}
        >
            {letter}
        </div>
    );
};
