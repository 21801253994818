import { FC } from "react";
import { useStartEndDates } from "app/hooks/Dates/useStartEndDates";
import { ReviewTaskContext } from "../context/reviewTaskContext";
import { ProviderProps } from "app/state/Providers/types";

export const ReviewTaskProvider: FC<ProviderProps> = ({ children }) => {
    const { dates, onDateChange } = useStartEndDates();

    return <ReviewTaskContext.Provider value={{ dates, onDateChange }}>{children}</ReviewTaskContext.Provider>;
};
