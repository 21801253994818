import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { ButtonType, ButtonVariants } from "app/components_v2/__buttons/Button/ButtonIcon/types";
import { screenSize } from "app/shared/Constants";
import { FC, ReactNode } from "react";
import { useWindowSize } from "usehooks-ts";

export type ModalFooterButtonProps = {
    text: string;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    iconLeft?: IconProp;
    iconRight?: IconProp;
    fullWidth?: boolean;
    disabled?: boolean;
    buttonType?: ButtonType;
    type?: "button" | "submit" | "reset";
    form?: string;
    danger?: boolean;
    isHidden?: boolean;
    variant?: ButtonVariants;
};

type GenericModalFooterProps = {
    confirmButton?: ModalFooterButtonProps;
    customButton?: ReactNode;
    closeButton?: ModalFooterButtonProps;
    buttonsJustification?: "spaceBetween" | "end";
    borderTop?: boolean;
    boxShadow?: boolean;
    loading?: boolean;
    flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
};

export const GenericModalFooter: FC<GenericModalFooterProps> = ({
    confirmButton,
    closeButton,
    buttonsJustification = "spaceBetween",
    boxShadow = true,
    borderTop = true,
    loading,
    flexDirection = "row",
    customButton,
}) => {
    const { width } = useWindowSize();

    return (
        <div
            className={`genericModalFooter ${
                closeButton || customButton ? `genericModalFooter--${buttonsJustification}` : "genericModalFooter--end"
            } ${boxShadow ? "genericModalFooter--boxShadow" : ""} ${borderTop ? "genericModalFooter--borderTop" : ""} `}
            style={{ flexDirection }}
        >
            {closeButton && !closeButton.isHidden && (
                <Button
                    buttonType={closeButton.type}
                    form={closeButton.form}
                    fullWidth={closeButton.fullWidth || width < screenSize.TABLET}
                    iconLeft={closeButton.iconLeft}
                    iconRight={closeButton.iconRight}
                    isDisabled={loading || closeButton.disabled}
                    onClick={closeButton.onClick}
                    text={closeButton.text}
                    type={closeButton.buttonType || "secondary"}
                    variant={closeButton.variant}
                />
            )}
            {!!confirmButton && !confirmButton.isHidden && (
                <Button
                    buttonType={confirmButton.type}
                    form={confirmButton.form}
                    fullWidth={confirmButton.fullWidth || width < screenSize.TABLET}
                    iconLeft={confirmButton.iconLeft}
                    iconRight={confirmButton.iconRight}
                    isDisabled={confirmButton.disabled}
                    isLoading={loading}
                    onClick={confirmButton.onClick}
                    text={confirmButton.text}
                    type={confirmButton.buttonType}
                    variant={confirmButton.variant}
                />
            )}
            {customButton && customButton}
        </div>
    );
};
