import { AuditGroupModel } from "app/models/05-QUA/AuditModels/AuditGroupModels";
import { FC } from "react";
import { AuditGroupGrid } from "../../AuditGroupGrid/AuditGroupGrid";

type AuditBoxBodyProps = {
    auditGroups: AuditGroupModel[];
    fetchAuditGroups: () => Promise<void>;
};

export const AuditBoxBody: FC<AuditBoxBodyProps> = ({ auditGroups, fetchAuditGroups }) => {
    return (
        <div className="auditBoxBody">
            {auditGroups.map((auditGroup) => (
                <AuditGroupGrid key={auditGroup.id} auditGroup={auditGroup} fetchAuditGroups={fetchAuditGroups} />
            ))}
        </div>
    );
};
