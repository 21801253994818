import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { TaskTemporalityRangeHour } from "../TaskTemporalityRangeHour/TaskTemporalityRangeHour";
import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { FC, ReactNode } from "react";
import { taskHoursModel } from "app/models/02-TAR/TaskWizard";
import { useTaskTemporalityRangeHours } from "../hooks/useTaskTemporalityRangeHours";

type TaskTemporalityOneDayProps = {
    taskStart: string;
    taskHours: taskHoursModel[];
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityOneDay: FC<TaskTemporalityOneDayProps> = ({
    customError,
    onChange,
    taskStart,
    isDisabled,
    min,
    taskHours,
    taskstartLabel,
    hint,
}) => {
    const { onAddNewHour, onAddNewRangeHour, onDeleteHour } = useTaskTemporalityRangeHours({
        onChange,
        taskHours,
    });

    return (
        <>
            <div className="taskTemporalityOneDay__taskStart">
                <TaskStart
                    onChange={onChange}
                    taskStart={taskStart}
                    customError={customError.taskStart}
                    disabled={isDisabled}
                    min={min}
                    label={taskstartLabel}
                    hint={hint}
                />
            </div>
            <div className="taskTemporalityOneDay__container">
                <TaskTemporalityRangeHour
                    errorMessage={customError.taskHours}
                    onAddNewHour={onAddNewHour}
                    onAddNewRangeHour={onAddNewRangeHour}
                    onChange={onChange}
                    onDeleteHour={onDeleteHour}
                    taskHours={taskHours}
                    isDisabled={isDisabled}
                />
            </div>
        </>
    );
};
