import { useTranslation } from "react-i18next";
import { useSession } from "../useSession";
import { CustomerTypeTranslations } from "app/translation/translationKeys";

export type QuantityType = "SINGULAR" | "PLURAL";

export type TranslationOptions = {
    firstReplace: QuantityType;
    secondReplace?: QuantityType;
    isGenderSensitive?: boolean;
};

export const useCustomerTypeTranslation = () => {
    const { t } = useTranslation();
    const session = useSession();
    const customerType = session?.user.customerType;
    const isPropertyManagement = customerType === "PROPERTY_MANAGEMENT";

    const singularPluralTranslation: Record<QuantityType, string> = {
        SINGULAR: isPropertyManagement ? t(CustomerTypeTranslations.COMMUNITY) : t(CustomerTypeTranslations.COMPANY),
        PLURAL: isPropertyManagement ? t(CustomerTypeTranslations.COMMUNITIES) : t(CustomerTypeTranslations.COMPANIES),
    };

    const translateCustomerTypeKeys = (key: string, options: TranslationOptions) => {
        // Check gender translation suffix -> _M = MASCULINE | _F = FEMENINE
        const genderKey = options.isGenderSensitive && isPropertyManagement ? `${key}_F` : key;
        const baseTranslation = t(genderKey);

        const firstTranslation = singularPluralTranslation[options.firstReplace || "SINGULAR"];
        const secondTranslation = options.secondReplace ? singularPluralTranslation[options.secondReplace] : "";

        return baseTranslation.replace("{0}", firstTranslation).replace("{1}", secondTranslation);
    };

    const translateCompany = (quantityType: QuantityType) => singularPluralTranslation[quantityType];

    return { translateCustomerTypeKeys, translateCompany };
};
