import { FC } from "react";
import { faAlarmClock, faCheck, faCircleXmark, faEquals } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { RankingPillCounterIcon, RankingPillCounterProps } from "../types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import { AriaLabels } from "app/translation/translationKeys";

export const RankingPillCounter: FC<RankingPillCounterProps> = ({
    counter,
    percentage,
    type = "default",
    fullHeight,
}) => {
    const { t } = useTranslation();

    const icons: Record<RankingPillCounterIcon, IconProp> = {
        yellow: faAlarmClock,
        danger: faCircleXmark,
        success: faCheck,
        defaultCross: faCircleXmark,
        default: faEquals,
        defaultClock: faAlarmClock,
    };
    const ariaLabelIcon: Record<RankingPillCounterIcon, string> = {
        yellow: t(AriaLabels.AL_RANKING_PILL_COUNTER_ALARM),
        danger: t(AriaLabels.AL_RANKING_PILL_COUNTER_ERROR),
        success: t(AriaLabels.AL_RANKING_PILL_COUNTER_SUCCESS),
        defaultCross: t(AriaLabels.AL_RANKING_PILL_COUNTER_CROSS),
        default: t(AriaLabels.AL_RANKING_PILL_COUNTER_EQUALS),
        defaultClock: t(AriaLabels.AL_RANKING_PILL_COUNTER_DEFAULT_ALARM),
    };

    return (
        <div
            className={`rankingPillCounter--${type} ${
                percentage !== undefined ? "rankingPillCounter--fullWidth" : ""
            }  ${fullHeight ? "rankingPillCounter--fullHeight" : ""}`}
            data-testid={`rankingPillCounter--${type}`}
        >
            {counter >= 0 && counter < 100 && (
                <FontAwesomeIcon
                    icon={icons[type]}
                    className={`rankingPillCounter__icon--${type}`}
                    aria-label={ariaLabelIcon[type]}
                />
            )}
            <div
                className="rankingPillCounter__number"
                aria-label={t(AriaLabels.AL_RANKING_PILL_COUNTER_TOTAL)}
            >
                <p className={`rankingPillCounter__text--${type}`}>{formatBigNumber(counter)}</p>
                {percentage !== undefined && (
                    <span
                        className={`rankingPillCounter__percentage--${type}`}
                        role="percentage"
                    >{`(${percentage}%)`}</span>
                )}
            </div>
        </div>
    );
};
