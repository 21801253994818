import { FC } from "react";
import { StarsReviews } from "../__stars/StarsReviews/StarsReviews";
import { TaskReviewCommentModel } from "./types";
import { StarColorModel, StarSizeModel } from "app/shared/types/StarTypes";

export type StarRendererProps = {
    stars: number;
    comment: TaskReviewCommentModel;
    isSupervisor?: boolean;
    onStarsChange?: (stars: number) => void | undefined;
    starColor?: StarColorModel;
    starSize?: StarSizeModel;
    audioUrl?: string | null;
    isMyFeedback?: boolean;
    editStarRating?: boolean;
    clampComment?: boolean;
};

export const TaskReview: FC<StarRendererProps> = ({
    stars,
    comment,
    onStarsChange,
    starColor,
    starSize,
    audioUrl,
    editStarRating,
    clampComment,
}) => {
    const { comment: commentValue, date, name } = comment;

    return (
        <div className={`taskReview${stars === 0 ? "" : stars < 3 ? "--danger" : stars > 3 ? "--green" : ""}`}>
            {(name || date || commentValue) && (
                <div className="taskReview__body">
                    <div className="taskReview__comment">
                        {name && <p className="taskReview__comment__name">{name}</p>}
                        {date && <p className="taskReview__comment__date">{date}</p>}
                    </div>
                    {commentValue && (
                        <p className={`taskReview__comment__comment ${clampComment ? "text_clamp text_clamp-1" : ""}`}>
                            {commentValue}
                        </p>
                    )}
                </div>
            )}
            <StarsReviews
                stars={stars}
                onRateChange={onStarsChange && onStarsChange}
                starColor={starColor}
                starSize={starSize}
                editStarRating={editStarRating}
            />
            {audioUrl && (
                <div className="taskReview__audio">
                    <audio
                        className="taskReview__audio__player"
                        controls
                        onClick={(e) => e.stopPropagation()}
                        controlsList="nodownload"
                        src={audioUrl}
                    />
                </div>
            )}
        </div>
    );
};
