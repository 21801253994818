import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BadgeSize, BadgeVariant } from "../badgeTypes";

type BagdgeIconProps = {
    size: BadgeSize;
    variant: BadgeVariant;
    icon: IconProp;
    outline?: boolean;
    disabled?: boolean;
    theme?: "dark" | "light";
};

export const BagdgeIcon: FC<BagdgeIconProps> = ({
    variant,
    icon,
    outline,
    disabled,
    theme = "light",
}) => {
    return (
        <FontAwesomeIcon
            icon={icon}
            className={`badgeIcon${outline ? "--outline" : ""}--${variant} ${
                disabled ? "badgeIcon--disabled" : ""
            } ${theme}`}
            data-testid="badgeIcon"
        />
    );
};
