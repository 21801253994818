import { DynamicFieldsType, DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";
import { isDropdownDynamicField } from "../dynamicFields/isDropdownDynamicField";
import { v4 } from "uuid";

export const onSelectDynamicField = (
    dynamicFieldType: DynamicFieldsType,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    const dynamicFieldsFiltered = dynamicFields.filter(({ isDeleted }) => !isDeleted);
    if (dynamicFieldsFiltered.length >= 20) return dynamicFields;
    return [
        ...dynamicFields,
        {
            dynamicFieldsType: dynamicFieldType,
            id: v4(),
            isRequired: true,
            taskFieldOptions: isDropdownDynamicField(dynamicFieldType) ? [{ id: v4(), label: "" }] : [],
            label: "",
            isDeleted: false,
        },
    ];
};

export const onDynamicFieldCheckboxChange = (
    value: boolean,
    id: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      isRequired: value,
                  }
                : field
        ),
    ];
};

export const onDynamicFieldInputChange = (
    value: string,
    id: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      label: value,
                  }
                : field
        ),
    ];
};

export const onDeleteDynamicField = (id: string, dynamicFields: DynamicFieldsModel[]): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.dbId) {
        return dynamicFields.map((field) =>
            field.id === id ? { ...field, isDeleted: true, taskFieldOptions: [] } : field
        );
    }

    return dynamicFields.filter((field) => field.id !== id);
};
export const onDeleteDynamicDropDownField = (
    id: string,
    itemId: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.taskFieldOptions.length < 2) return dynamicFields;
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      taskFieldOptions: [...field.taskFieldOptions.filter((taskfield) => taskfield.id !== itemId)],
                  }
                : field
        ),
    ];
};
export const onChangeDynamicDropDownField = (
    id: string,
    itemId: string,
    value: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    return [
        ...dynamicFields.map((field) => {
            if (field.id !== id) return field;
            return {
                ...field,
                taskFieldOptions: [
                    ...field.taskFieldOptions.map((taskfield) => {
                        if (taskfield.id !== itemId) return taskfield;
                        return {
                            ...taskfield,
                            label: value,
                        };
                    }),
                ],
            };
        }),
    ];
};
export const onAddNewDynamicDropDownField = (id: string, dynamicFields: DynamicFieldsModel[]): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.taskFieldOptions.length >= 10) return dynamicFields;
    return [
        ...dynamicFields.map((field) => {
            if (field.id !== id) return field;
            return {
                ...field,
                taskFieldOptions: [...field.taskFieldOptions, { id: v4(), label: "" }],
            };
        }),
    ];
};
