import { FC } from "react";
import { RankingPillCounterProps } from "../types";
import { RankingPillCounter } from "../RankingPillCounter/RankingPillCounter";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { useTranslation } from "react-i18next";
import { AriaLabels } from "app/translation/translationKeys";

type RankingPillCountersProps = {
    counters: RankingPillCounterProps[];
    total?: number;
};

export const RankingPillCounters: FC<RankingPillCountersProps> = ({ counters, total }) => {
    const { t } = useTranslation();
    return (
        <div
            className={`rankingPillCounters ${
                total !== undefined ? "rankingPillCounters--border" : ""
            }`}
            data-testid="rankingPillCounters"
        >
            <div className="rankingPillCounters__counters">
                {counters.map((counter, key) => (
                    <RankingPillCounter
                        {...counter}
                        key={key}
                        fullHeight={counters.length === 1 && counter.percentage === undefined}
                    />
                ))}
            </div>
            {total !== undefined && (
                <div className="rankingPillCounters__total">
                    <span className="rankingPillCounters__slash">/</span>
                    <p
                        className="rankingPillCounters__total__number"
                        aria-label={t(AriaLabels.AL_RANKING_PILL_COUNTERS_TOTAL)}
                    >{`${formatBigNumber(total)}`}</p>
                </div>
            )}
        </div>
    );
};
