import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AuditGroupCheckListModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListModels";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class AuditGroupCheckListService {
    // GET Single AuditGroupCheckList
    public static async GetOne(id: number) {
        return FetchService.get<AuditGroupCheckListModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList/${id}`,
        });
    }

    // GET AuditGroupCheckList List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditGroupCheckListModel>>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList`,
            paginationParams: params,
        });
    }

    // GET AuditGroupCheckList List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST AuditGroupCheckList
    public static async Save(body: AuditGroupCheckListModel) {
        return FetchService.post<AuditGroupCheckListModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList`,
            body,
        });
    }

    // PUT AuditGroupCheckList
    public static async Edit(id: number, body: AuditGroupCheckListModel) {
        return FetchService.put<AuditGroupCheckListModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList/${id}`,
            body,
        });
    }

    // DELETE AuditGroupCheckList
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckList/${id}`,
        });
    }
}
