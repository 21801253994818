import { CloseCompanySeenModel } from "app/models/01-SEG/CloseCompanySeen/CloseCompanySeenModel";
import { CloseCompanyTranslations, DepartmentTranslations } from "app/translation/translationKeys";
import { CloseDepartmentSeenModel } from "app/models/01-SEG/CloseDepartmentSeen/CloseDepartmentSeenModel";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { OpenClosedTagDepartmentList } from "./OpenClosedTagDepartmentList/OpenClosedTagDepartmentList";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useTranslation } from "react-i18next";

type OpenClosedOptionCompanyListProps = {
    companyList: CloseCompanySeenModel[];
    departmentList: CloseDepartmentSeenModel[];
    variant: OpenCloseStatusCode;
};

export const OpenClosedOptionCompanyCollapsable: FC<OpenClosedOptionCompanyListProps> = ({
    companyList,
    departmentList,
    variant,
}) => {
    const { t } = useTranslation();
    const { translateCompany } = useCustomerTypeTranslation();

    const collapsableTitle: Record<OpenCloseStatusCode, string> = {
        OPEN: t(CloseCompanyTranslations.OPEN_COMPANIES_COLLAPSABLE_TITLE),
        CLOSED: t(CloseCompanyTranslations.CLOSE_COMPANIES_COLLAPSABLE_TITLE),
    };

    const getBadgeTitle = (date1: Date, date2: Date): string => {
        if (date1 === date2) return getDayMonthFormatedWithYearNumbers(date1.toString() || "", "/", "YYYY");

        return t(CloseCompanyTranslations.COMPANY_CLOSED_DAYS).replace(
            "{1}",
            `${getDayMonthFormatedWithYearNumbers(
                date1.toString() || "",
                "/",
                "YYYY"
            )} - ${getDayMonthFormatedWithYearNumbers(date2.toString() || "", "/", "YYYY")}`
        );
    };

    return (
        <Collapsable title={collapsableTitle[variant]}>
            <div className="openClosedOptionCompanyCollapsable">
                <div className="openClosedOptionCompanyCollapsable__companyGroup">
                    {!!companyList.length && (
                        <label className="openClosedOptionCompanyCollapsable__companyGroup__label">
                            {translateCompany("PLURAL")}
                        </label>
                    )}
                    {companyList.map(({ companyName, logoURL, closeDate, openDate, state }, i) => (
                        <OptionCompany
                            key={i}
                            name={companyName}
                            logoImage={logoURL}
                            badge={{
                                title: getBadgeTitle(closeDate, openDate),
                                icon: faClock,
                                iconPosition: "left",
                                theme: "light",
                                variant: state === "CLOSED" ? "red" : "green",
                                outline: false,
                            }}
                        />
                    ))}
                    {!!departmentList.length && (
                        <label className="openClosedOptionCompanyCollapsable__companyGroup__label">
                            {t(DepartmentTranslations.DEPARTMENTS_TITLE)}
                        </label>
                    )}
                    <OpenClosedTagDepartmentList
                        list={departmentList}
                        variant={variant}
                        getBadgeTitle={getBadgeTitle}
                    />
                </div>
            </div>
        </Collapsable>
    );
};
