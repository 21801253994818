import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { BaseAuditGroupCheckListItemTypeModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemTypeModels/BaseAuditGroupCheckListItemTypeModel";

export default class BaseAuditGroupCheckListItemTypeService {
    // GET Single BaseAuditGroupCheckListItemType
    public static async GetOne(id: number) {
        return FetchService.get<BaseAuditGroupCheckListItemTypeModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItemType/${id}`,
        });
    }

    // GET BaseAuditGroupCheckListItemType List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<BaseAuditGroupCheckListItemTypeModel>>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItemType`,
            paginationParams: params,
        });
    }

    // POST BaseAuditGroupCheckListItemType
    public static async Save(body: BaseAuditGroupCheckListItemTypeModel) {
        return FetchService.post<BaseAuditGroupCheckListItemTypeModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItemType`,
            body,
        });
    }

    // PUT BaseAuditGroupCheckListItemType
    public static async Edit(id: number, body: BaseAuditGroupCheckListItemTypeModel) {
        return FetchService.put<BaseAuditGroupCheckListItemTypeModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItemType/${id}`,
            body,
        });
    }

    // DELETE BaseAuditGroupCheckListItemType
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItemType/${id}`,
        });
    }
}
