import { SortedTypeModel } from "app/components_v2/Table/types";
import { AssetGridSF } from "../../types";
import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { createContext } from "react";

export type AssetGridContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    currentTab: number;
    onChangeCurrentTab: (tab: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    secondaryFilterValues: AssetGridSF;
    onSFChange: (sfValues: Partial<AssetGridSF>) => void;
    selectCompany: SelectCompanyModel;
    onCompanyChange: (value: Partial<SelectCompanyModel>) => void;
    query: string;
    onQueryChange: (query: string) => void;
};

export const AssetGridContext = createContext<AssetGridContextModel>({} as AssetGridContextModel);
