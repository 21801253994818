import { getStoreSession } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";

export type workingCompanyModel = {
    workingCompany: UserCompanyModel;
    workingDepartment: UsrDepartmentModel;
    workingSubDepartment: UsrSubDepartmentModel;
};

export const ChangeCompany = () => {
    const localStorageSession = getStoreSession();

    return (
        <div className="change__company">
            <p className="change__company__title">{localStorageSession?.workingCompany?.companyName}</p>
        </div>
    );
};
