import { useCallback, useState } from "react";
import { buildParams, buildParamsWorkerDetail } from "app/pages/03-REP/01-AnalystRankingPage/helpers/helpers";
import AnalyticsService from "app/services/03-REP/AnalyticsService";
import { DatesReviewTask } from "./Dates/types";

type IuseSupervisorDetailModal = {
    startDateInput: Date;
    endDateInput: Date;
    userId: number;
    isSupervisor: boolean;
};

export const useSupervisorDetailModal = ({
    endDateInput,
    startDateInput,
    userId,
    isSupervisor,
}: IuseSupervisorDetailModal) => {
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [dataChart, setDataChart] = useState<any>({});

    const fetchUserData = useCallback(
        async ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
            setIsLoadingModal(true);
            const params = buildParams({
                startDateInput: startDate || startDateInput,
                endDateInput: endDate || endDateInput,
            });
            const paramsWorker = buildParamsWorkerDetail({
                startDateInput: startDate || startDateInput,
                endDateInput: endDate || endDateInput,
            });
            const { status, data } = isSupervisor
                ? await AnalyticsService.GetSupervisorDetail(userId, {
                      extraParams: params.toString(),
                  })
                : await AnalyticsService.GetWorkerDetail(userId, {
                      extraParams: paramsWorker.toString(),
                  });

            if (status()) {
                setDataChart(data);
            }
            setIsLoadingModal(false);
        },
        [startDateInput, endDateInput, AnalyticsService]
    );

    const handleOpenModal = () => {
        setIsLoadingModal(true);
        setShowModal(true);
        fetchUserData({});
    };

    const onDateChange = ({ endDate, startDate }: Partial<DatesReviewTask>) => {
        if (!endDate) return;
        fetchUserData({ endDate, startDate });
    };

    return {
        isLoadingModal,
        showModal,
        dataChart,
        handleOpenModal,
        onDateChange,
        onShowModalChange: (value: boolean) => setShowModal(value),
    };
};
