import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { OnBoardingStepsInstanceModel } from "app/models/01-SEG/Onboarding/OnBoardingStepsInstanceModel";
import OnBoardingStepInstanceService from "app/services/01-SEG/OnBoardingStepInstanceService";
import { useEffect, useState } from "react";
import { OnBoardingSubStep } from "../OnBoardingSubStep/OnBoardingSubStep";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { OnBoardingStepsTitle } from "../OnBoardingStepsTitle/OnBoardingStepsTitle";
import { useUserType } from "app/hooks/useUserType";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { OnBoardingSubstepInstancesModel } from "app/models/01-SEG/Onboarding/OnBoardingSubstepInstancesModel";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { useTranslation } from "react-i18next";
import { OnBoardingStepsTranslation, TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { faArrowsRotate, faBackward } from "@fortawesome/pro-regular-svg-icons";
import { useSession } from "app/hooks";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { Button } from "app/components_v2/__buttons/Button/Button";

export const OnBoardingSteps = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { isAdmin } = useUserType();
    const session = useSession();
    
    const isOnBoarding = !!session?.isOnBoarding;

    const [onBoardingSteps, setOnBoardingSteps] = useState<OnBoardingStepsInstanceModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isResetModalVisible, setIsResetModalVisible] = useState<boolean>(false);
    const [isResetLoading, setIsResetLoading] = useState<boolean>(false);

    const getSteps = async () => {
        const { data, status, getParsedError } = await OnBoardingStepInstanceService.GetData();

        if (!status()) {
            setOnBoardingSteps([]);
            setIsLoading(false);
            handleToast({
                title: t(TranslationErrors.ONBOARDING_FAILED_FETCH),
                subtitle: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setOnBoardingSteps(data);
        setIsLoading(false);

        if (!!data.length && data[data.length - 1].status === "DONE" && isOnBoarding) window.location.reload();
    };

    const handleReset = async () => {
        setIsResetLoading(true);
        const { status, getParsedError } = await OnBoardingStepInstanceService.ResetOnboarding();

        handleToast({
            title: status()
                ? t(OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_TITLE)
                : getErrorMessage(getParsedError()),
            type: "alert",
            variant: status() ? "success" : "danger",
        });

        getSteps();
        setIsResetLoading(false);
        setIsResetModalVisible(false);
    };

    const showCompleteButton = (
        onBoardingSubstepInstances: OnBoardingSubstepInstancesModel[],
        status: OnBoardingStatus
    ) => {
        const isStatusInReview = status === "REVIEW";
        if (isAdmin && isStatusInReview) return true;

        const isStatusInProgress = status === "IN_PROGRESS";
        const isAllSubStepsDone = onBoardingSubstepInstances.every((obsi) => obsi.status === "DONE");
        const isSupervisorOrAnalist = !isAdmin;

        if (isSupervisorOrAnalist && isStatusInProgress && isAllSubStepsDone) return true;

        return false;
    };

    const showMakeStepInProgress = (stepId: number, key: number): boolean => {
        if (!isAdmin) return false;

        const blockedOnBoarding = onBoardingSteps.find(({ status }) => status === "BLOCKED");
        if (!blockedOnBoarding) return false;

        if (blockedOnBoarding.id !== stepId) return false;
        if (key === 0) return false;

        const prevStep = onBoardingSteps[key - 1];
        const isPrevStepDone = prevStep.status === "DONE";

        return isPrevStepDone;
    };

    useEffect(() => {
        getSteps();
    }, []);

    return (
        <>
            {isResetModalVisible && (
                <ConfirmModal
                    onConfirm={handleReset}
                    onConfirmText={t(OnBoardingStepsTranslation.ONBOARDING_RESET)}
                    description={t(OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_DESCRIPTION)}
                    isLoading={isResetLoading}
                    onClose={() => setIsResetModalVisible(false)}
                    title={t(OnBoardingStepsTranslation.ONBOARDING_RESET_MODAL_TITLE)}
                    onCloseText={t(TranslationCommon.CLOSE)}
                    type="info"
                />
            )}
            <PageContainer
                fullHeight={isLoading}
                header={
                    <TabHeader classNameToScrollTop=".customContainer" paddingSize="sm">
                        <div className="onBoardingSteps__header">
                            <h1 className="onBoardingSteps__title">{t(OnBoardingStepsTranslation.ONBOARDING_TITLE)}</h1>
                            <div className="onBoardingSteps__buttons">
                                {isAdmin && (
                                    <Button
                                        iconRight={faBackward}
                                        transparent
                                        onClick={() => setIsResetModalVisible(true)}
                                    />
                                )}
                                <Button iconRight={faArrowsRotate} transparent onClick={getSteps} />
                            </div>
                        </div>
                    </TabHeader>
                }
            >
                {isLoading ? (
                    <div className="onBoardingSteps__spinner">
                        <Spinner />
                    </div>
                ) : (
                    <div className="onBoardingSteps">
                        {onBoardingSteps.map((step, key) => {
                            const { id, onBoardingSubstepInstances, status } = step;
                            return (
                                <WhiteBox key={id}>
                                    <div className="onBoardingSteps__container">
                                        <OnBoardingStepsTitle
                                            step={step}
                                            stepIndex={key + 1}
                                            onCompleteStep={getSteps}
                                            onReviewStep={getSteps}
                                            onInProgressStep={getSteps}
                                            isCompleteVisible={showCompleteButton(onBoardingSubstepInstances, status)}
                                            isInProgressVisible={showMakeStepInProgress(id, key)}
                                            isLastStep={key + 1 === onBoardingSteps.length}
                                        />
                                        <OnBoardingSubStep
                                            onBoardingSubstepInstances={onBoardingSubstepInstances}
                                            onCompleteSubStep={getSteps}
                                        />
                                    </div>
                                </WhiteBox>
                            );
                        })}
                    </div>
                )}
            </PageContainer>
        </>
    );
};
