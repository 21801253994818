import { FC, useState } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { Section } from "../Section/Section";
import { OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";

type Props = {
    show?: boolean;
};

export const SelectOutSideSection: FC<Props> = ({ show }) => {
    const options: OptionModel[] = [
        { value: "chocolate", label: "Chocolate", initials: "CH" },
        { value: "strawberry", label: "Strawberry", initials: "ST" },
        { value: "vanilla", label: "Vanilla", initials: "VA" },
    ];
    const options2: OptionModel[] = [
        { value: "chocolate1", label: "Chocolate1", initials: "CH" },
        { value: "strawberry1", label: "Strawberry1", initials: "ST" },
        { value: "vanilla", label: "Vanilla", initials: "VA" },
        { value: "vanilla1", label: "Vanilla1", initials: "VA" },
    ];
    const [optionSelected, setOptionSelected] = useState(true);

    const [selectOptionsValue, setSelectOptionsValue] = useState<string>("");
    const [optionsWithListItems, setOptionsWithListItems] = useState<OptionModel[]>([]);

    const handleDeleteListItems = (id: string) => {
        setOptionsWithListItems((prev) => prev.filter(({ value }) => value !== id));
    };
    return (
        <Section title="SelectOutisde" show={show}>
            <button onClick={() => setOptionSelected((prev) => !prev)}>Cambiar opciones</button>
            <OptionsOutsideSelect
                label="Input Select With Hidden Selected Options and List Item Pop  "
                options={optionSelected ? options : options2}
                onChange={(e) => {
                    setOptionsWithListItems(e);
                }}
                values={optionsWithListItems.map((values) => ({ ...values }))}
                onDeleteItem={handleDeleteListItems}
                hint="This is a hint example"
                isMulti
            />
            <h3 className="designSys__section__element__title">Input Select </h3>
            <SelectOptions
                label="Input Select"
                options={options}
                isMulti={false}
                onChange={(e) => {
                    setSelectOptionsValue(e.value);
                }}
                selectedValue={selectOptionsValue}
                hint="This is a hint example"
            />
            <SelectOptions
                label="Input Select With Error"
                options={options}
                isMulti={false}
                onChange={(e) => {
                    setSelectOptionsValue(e.value);
                }}
                selectedValue={selectOptionsValue}
                errorMessage="This is an error message."
                hint="This is a hint example"
            />
            <SelectOptions
                label="Input Select Disabled"
                options={options}
                isMulti={false}
                onChange={(e) => {
                    setSelectOptionsValue(e.value);
                }}
                selectedValue={selectOptionsValue}
                disabled
                hint="This is a hint example"
            />
            <h3 className="designSys__section__element__title">Input MultiSelect </h3>
            <SelectOptions
                label="Input Multi Select"
                options={options}
                isMulti={true}
                onChange={(e) => {
                    console.log(e);
                }}
                hint="This is a hint example"
                hideSelectedValue={false}
            />
            <SelectOptions
                label="Input Multi Select error"
                options={options}
                isMulti={true}
                onChange={(e) => {
                    console.log(e);
                }}
                errorMessage="This is an error message."
                hint="This is a hint example"
            />
            <SelectOptions
                label="Input Multi Select disabled"
                options={options}
                isMulti={true}
                onChange={(e) => {
                    console.log(e);
                }}
                disabled
                hint="This is a hint example"
            />
            <h3 className="designSys__section__element__title">
                Input Select With Hidden Selected Options and List Item Pop
            </h3>
            <OptionsOutsideSelect
                label="Input Select With Hidden Selected Options and List Item Pop  "
                options={options}
                onChange={(e) => {
                    setOptionsWithListItems(e);
                }}
                values={optionsWithListItems.map(({ label, value }) => ({
                    label,
                    value: value,
                }))}
                onDeleteItem={handleDeleteListItems}
                hint="This is a hint example"
                isMulti
            />
            <OptionsOutsideSelect
                label="Input Select With Hidden Selected Options and List Item Pop error"
                onChange={(e) => {
                    setOptionsWithListItems(e);
                }}
                hint="This is a hint example"
                errorMessage="This is an error message."
                options={options}
                values={optionsWithListItems.map(({ label, value }) => ({
                    label,
                    value: value,
                }))}
                onDeleteItem={handleDeleteListItems}
                isMulti
            />
            <OptionsOutsideSelect
                label="Input Select With Hidden Selected Options and List Item Pop disabled"
                options={options}
                onChange={(e) => {
                    setOptionsWithListItems(e);
                }}
                hint="This is a hint example"
                disabled
                onDeleteItem={handleDeleteListItems}
                values={optionsWithListItems.map(({ label, value }) => ({
                    label,
                    value: value,
                }))}
                isMulti
            />
        </Section>
    );
};
