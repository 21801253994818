import { useState } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { useSession } from "./useSession";
import { getInitials } from "app/helpers/getInitials";

export const useCompanies = () => {
    const session = useSession();
    const [companies, setCompanies] = useState<OptionModel[]>([]);

    const getCompanies = async () => {
        const companiesSr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
        });

        if (!companiesSr.status()) {
            setCompanies([]);
            return;
        }
        setCompanies(companiesSr.data.map(({ label, value }) => ({ label, value, initials: getInitials(label) })));
    };
    return { companies, getCompanies };
};
