import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { FC } from "react";

type DynamicFieldItemReadOnlyProps = {
    type: string;
    label: string;
    options: string[];
};

export const DynamicFieldItemReadOnly: FC<DynamicFieldItemReadOnlyProps> = ({ label, options, type }) => {
    return (
        <div className="dynamicFieldItemReadOnly">
            <ReadOnlyInput value={label} label={type} />
            {!!options.length && (
                <ul className="dynamicFieldItemReadOnly__options">
                    {options.map((option, i) => (
                        <li key={i}>
                            <ReadOnlyInput value={option} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
