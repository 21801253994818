import { CustomerType } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { WizardLicenseModel } from "../types";

export const INITIAL_FORM_VALUES: WizardLicenseModel = {
    instanceName: "",
    contactName: "",
    contactEmail: "",
    phoneNumber: "",
    initials: "",
    permisions: [],
    adminFirstName: "",
    adminLastName: "",
    adminUsername: "",
    adminEmail: "",
    adminUserCode: "",
    customerType: "RESTAURANT",
};

export const customerTypes: CustomerType[] = ["RESTAURANT", "HOTEL", "PROPERTY_MANAGEMENT"];
