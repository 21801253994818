import { FC } from "react";
import { HeaderItemProp } from "../headerTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { LinkDiv } from "../LinkDiv/LinkDiv";

export const HeaderRightItem: FC<HeaderItemProp> = ({
    text,
    to,
    image,
    notification,
    iconLeft,
    iconRight,
    userId,
    userName,
    isAvatar,
    onClick,
}) => {
    return (
        <div className="headerItemRight" onClick={onClick}>
            <LinkDiv to={to} className="headerItemRight__text">
                {isAvatar && <Avatar avatarName={userName || ""} colorId={userId || 0} img={image} size="sm" />}
                {iconLeft === "left" && (
                    <>
                        {notification && <div className="markableIcon__notification" />}
                        <FontAwesomeIcon className="headerItemRight__icon" icon={iconLeft} />
                    </>
                )}
                <div className="text_clamp text_clamp-1">{text}</div>
                {iconRight && (
                    <div className="headerItemRight__icon__container">
                        {notification && <div className="markableIcon__notification" />}
                        <FontAwesomeIcon className="headerItemRight__icon" icon={iconRight} />
                    </div>
                )}
            </LinkDiv>
        </div>
    );
};
