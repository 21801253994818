import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseTaskTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskDataInputPopoverChildrenProps = {
    isLoading: boolean;
    options: string[];
    isAPPCC?: boolean;
};

export const TaskDataInputPopoverChildren: FC<TaskDataInputPopoverChildrenProps> = ({
    isLoading,
    options,
    isAPPCC,
}) => {
    const { t } = useTranslation();

    if (isLoading)
        return (
            <p className="taskDataInputPopoverChildren__searching">
                {!!isAPPCC
                    ? t(BaseTaskTranslations.BASE_TASK_SEARCHING_TASKS)
                    : t(BaseTaskTranslations.BASE_TASK_SEARCHING_REGISTRIES)}
            </p>
        );
    if (!options.length) return null;

    return (
        <>
            <div className="taskDataInputPopoverChildren__header">
                <FontAwesomeIcon className="taskDataInputPopoverChildren__icon" icon={faTriangleExclamation} />
                <p className="taskDataInputPopoverChildren__title">
                    {!!isAPPCC
                        ? t(BaseTaskTranslations.BASE_TASK_SIMILAR_TASKS)
                        : t(BaseTaskTranslations.BASE_TASK_SIMILAR_REGISTRIES)}
                </p>
            </div>
            <ul className="taskDataInputPopoverChildren__list">
                {!!options.length &&
                    options.map((option, i) => (
                        <li key={i} className="taskDataInputPopoverChildren__text">
                            {option}
                        </li>
                    ))}
            </ul>
        </>
    );
};
