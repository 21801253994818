import { FC } from "react";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "app/components_v2/Badge/Badge";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { GetUserTypeDescription } from "app/helpers/userType/GetUserTypeDescription";
import { UserType } from "app/shared/types/UserTypes";
import { USER_TYPE } from "app/shared/Constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RowPositionModel } from "app/components_v2/Table/types";
import { UserListDto } from "app/dtos/01-SEG/UserListDto";

type MobileUserRowProps = {
    user: UserListDto;
    rowPosition: RowPositionModel;
};

export const MobileUserRow: FC<MobileUserRowProps> = ({ user, rowPosition }) => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const getUserColor = (userType: UserType): BadgeVariant => {
        return userType === USER_TYPE.ANALIST
            ? "purple"
            : userType === USER_TYPE.SUPERVISOR
              ? "deepGreen"
              : userType === USER_TYPE.WORKER
                ? "deepBlue"
                : "grey";
    };

    const { firstName, lastName, userCode, roleNameList, id, userType } = user;
    return (
        <div className={`mobileUserRow--${rowPosition}`} onDoubleClick={() => nav(`edit/${id}`)}>
            <div className="mobileUserRow__container">
                <div className="mobileUserRow__container__text">
                    <div className="flex--between">
                        <p className="mobileUserRow__name">{`${firstName} ${lastName}`}</p>
                        <Badge variant={getUserColor(userType)} title={GetUserTypeDescription(userType, t)} />
                    </div>
                    <p className="mobileUserRow__userCode">{userCode}</p>
                    <p className="mobileUserRow__userCode">{roleNameList?.map((roleName) => roleName)?.join(" - ")}</p>
                </div>
            </div>
            <div className="mobileUserRow__edit" onClick={() => nav(`edit/${id}`)}>
                <FontAwesomeIcon icon={faPen} className="mobileUserRow__edit__icon" />
            </div>
        </div>
    );
};
