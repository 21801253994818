import { Container } from "app/components_v2/__containers/Container";
import { AppSource } from "app/shared/Constants";
import { FC, ReactNode } from "react";
import { CrudHeader, CrudHeaderProps } from "../CrudHeader/CrudHeader";
import { useSession } from "app/hooks";
import { Header } from "app/components_v2/Header/Header";
import { useScrollTop } from "app/hooks/global/useScrollTop";
import Spinner from "app/components_v2/Spinner/Spinner";

type FormLayoutProps = {
    children: ReactNode | ReactNode[];
    footer?: ReactNode;
    headerOptions: CrudHeaderProps;
    noHeight?: boolean;
    relative?: boolean;
    variant?: "white" | "gray";
    isLoading?: boolean;
    fullWidth?: boolean;
};

export const FormLayout: FC<FormLayoutProps> = ({
    headerOptions,
    children,
    noHeight,
    footer,
    relative,
    variant = "white",
    isLoading = true,
    fullWidth,
}) => {
    const headerColor = useScrollTop(10, ".formLayoutScroll__children");
    const session = useSession();
    const isOfflineApp = session?.appSource === AppSource.OFFLINE;

    return (
        <Container>
            {!isOfflineApp && (
                <Header
                    isTransparent={true}
                    headerColor={headerColor}
                    variant={headerOptions.variant}
                    isGrayMode={
                        headerOptions.isClosed ||
                        headerOptions.isGrayMode ||
                        (headerOptions.type === "disable" && !headerOptions.isActive)
                    }
                />
            )}
            <div className="formLayoutScroll">
                <div className={`formLayoutScroll__children formLayoutScroll__children--${variant}`}>
                    <CrudHeader {...headerOptions} />
                    <div
                        className={`formLayout ${relative ? "relative" : ""} formLayout--${variant}  ${
                            fullWidth ? "formLayout--fullWidth" : ""
                        }`}
                    >
                        {isLoading ? (
                            <div className="formLayout__spinner">
                                <Spinner />
                            </div>
                        ) : (
                            <div
                                className={`formLayout__children${
                                    noHeight ? "--noHeight" : ""
                                } formLayout__children--${variant}`}
                            >
                                {children}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {footer}
        </Container>
    );
};
