import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { TaskPillList } from "app/pages/02-TAR/01-TAR-Dashboard/TaskPillList";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskHistoryListProps = {
    data: TaskInstanceModel[];
    total: number;
    isLoading: boolean;
    pageIndex: number;
    onChangePageIndex: (pi: number) => void;
    onDetailClick: (id: number) => void;
};

export const TaskHistoryList: FC<TaskHistoryListProps> = ({
    data,
    isLoading,
    onChangePageIndex,
    onDetailClick,
    pageIndex,
    total,
}) => {
    const { t } = useTranslation();

    if (!data.length && !isLoading)
        return (
            <div className="fullHeight">
                <TableError icon={faListCheck} title={t(TranslationKeys.EMPTY_LIST_TASK_REVIEW)} size="xl" />
            </div>
        );

    return (
        <TaskPillList
            list={data}
            total={total}
            editable={false}
            hideFeedback
            isLoading={isLoading}
            onChangePageIndex={onChangePageIndex}
            onDetailClick={onDetailClick}
            pageIndex={pageIndex}
            hideMobilePhotos={false}
        />
    );
};
