import { Badge } from "app/components_v2/Badge/Badge";
import { TranslationKeys, AriaLabels } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AssetMobileBadgesProps = {
    issueCount: number;
    issueCountTotal: number;
};

export const AssetMobileBadges: FC<AssetMobileBadgesProps> = ({ issueCount, issueCountTotal }) => {
    const { t } = useTranslation();
    return (
        <div className="assetBadges">
            <Badge
                bolderTextStart={String(issueCount)}
                title={t(TranslationKeys.MOBILE_ISSUE_COUNT)}
                size="sm"
                variant={issueCount === 0 ? "grey" : "red"}
                ariaLabel={AriaLabels.AL_ISSUE_COUNT}
            />
            <Badge
                bolderTextStart={String(issueCountTotal)}
                title={t(TranslationKeys.MOBILE_ISSUE_COUNT_TOTAL)}
                size="sm"
                variant={issueCountTotal === 0 ? "grey" : "red"}
                ariaLabel={AriaLabels.AL_ISSUE_COUNT_TOTAL}
            />
        </div>
    );
};
