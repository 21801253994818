import { useRef, useState } from "react";
import { useToast } from "../Toast/useToast";
import { TranscriptionErrors } from "./types";
import { useTranslation } from "react-i18next";
import { TranscriptionTranslations } from "app/translation/translationKeys";

type IUseTranscription = {
    value: string;
    onChange: (allTextTranscripted: string, newTextTranscripted: string) => void;
    defaultLang?: string;
    onError?: (errorMsg: TranscriptionErrors) => void;
};

export const useTranscription = ({ defaultLang = "es", onError, onChange, value }: IUseTranscription) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isTranscripting, setIsTranscripting] = useState<boolean>(false);
    const recognition = useRef<SpeechRecognition | null>(null);
    const [lang, setLang] = useState<string>(defaultLang);

    const onStartRecording = () => {
        if (!("webkitSpeechRecognition" in window || "SpeechRecognition" in window)) {
            handleToast({ type: "alert", title: t(TranscriptionTranslations.BROWSER_NOT_COMPATIBLE) });
            onError && onError("BROWSER_NOT_COMPATIBLE");
            return;
        }

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        recognition.current = new SpeechRecognition();
        recognition.current.lang = lang;
        recognition.current.continuous = true;

        try {
            recognition.current.start();
            recognition.current.onresult = async (e) => {
                let transcript = value;
                let newTranscription = "";
                for (let i = 0; i < e.results.length; i++) {
                    transcript = formatSpeech(transcript, e.results[i][0].transcript);
                    newTranscription = formatSpeech("", e.results[i][0].transcript);
                }
                onChange(transcript, newTranscription);
            };
            recognition.current.onsoundstart = async (e) => {
                setIsTranscripting(true);
            };
            recognition.current.onstart = (e) => {
                setIsRecording(true);
            };
        } catch (error) {
            setIsRecording(false);
            recognition.current.stop();
            handleToast({ type: "alert", title: t(TranscriptionTranslations.ERROR_STARTING_TRANSCRIPTING) });
            onError && onError("ERROR_STARTING_TRANSCRIPTING");
            console.log(error);
        }
    };

    const formatSpeech = (previousTranscription: string, newTranscription: string) => {
        if (!!previousTranscription.length) {
            const needSpaceBetweenTranscriptions = previousTranscription[previousTranscription.length-1] !== " "
            return `${previousTranscription}${needSpaceBetweenTranscriptions ? " " : ""}${newTranscription.charAt(1).toUpperCase() + newTranscription.slice(2)}. `;
        }
        return `${newTranscription.charAt(0).toUpperCase() + newTranscription.slice(1)}. `;
    };

    const stopRecording = () => {
        recognition.current?.stop();
        setIsTranscripting(false);
        setIsRecording(false);
    };

    return {
        isRecording,
        setIsRecording,
        isTranscripting,
        setIsTranscripting,
        recognition,
        lang,
        setLang,
        onStartRecording,
        stopRecording,
    };
};
