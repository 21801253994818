import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { TaskPillList } from "app/pages/02-TAR/01-TAR-Dashboard/TaskPillList/TaskPillList";
import { useWindowSize } from "usehooks-ts";
import { TabSkelleton } from "app/components_v2/__skelletons/TabSkelleton/TabSkelleton";
import { ANALYST_ENTITY_TYPE, screenSize } from "app/shared/Constants";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalistEntityDetailTaskListCounters } from "app/dtos/03-REP/Analytics/AnalistEntityDetailTaskListCounters";

type AnalistDetailTaskListProps = {
    isLoading?: boolean;
    taskListData: any;
    pageIndex?: number;
    pageSize?: number;
    handleTaskListPagination: (pageIndex: number) => void;
    handleTaskListTabChange: (tabIndex: number) => void;
    setShowTaskDetail: (showTaskDetail: number | undefined) => void;
    taskCounters: AnalistEntityDetailTaskListCounters;
    currentTab: number;
    entityType: AnalystEntityType;
};
export const AnalistDetailTaskList: FC<AnalistDetailTaskListProps> = ({
    isLoading = false,
    taskListData,
    pageIndex,
    pageSize,
    setShowTaskDetail,
    handleTaskListPagination,
    handleTaskListTabChange,
    taskCounters,
    currentTab,
    entityType,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const showOneColumn =
        entityType !== ANALYST_ENTITY_TYPE.USER ||
        (entityType === ANALYST_ENTITY_TYPE.USER && width <= screenSize.LAPTOP);

    const tabs: TabPropsReduced[] = [
        {
            onClick: () => handleTaskListTabChange(0),
            rightCount: taskCounters.withNoReviewsCount,
            text: t(TranslationKeys.WITHOUT_REVIEW),
        },
        {
            onClick: () => handleTaskListTabChange(1),
            rightCount: taskCounters.feedbackTasksCount,
            text: t(TranslationKeys.WITH_REVIEW),
        },
        {
            onClick: () => handleTaskListTabChange(2),
            rightCount: taskCounters.commentTasksCount,
            text: t(TranslationKeys.WITH_COMMENTS),
        },
    ];

    return (
        <div className="analistDetailTaskListContainer">
            {isLoading ? (
                <TabSkelleton quantity={tabs.length} />
            ) : (
                <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
            )}
            {taskListData?.taskInstance?.list.length || isLoading ? (
                <TaskPillList
                    borderStyle="border"
                    removePadding
                    isLoading={isLoading}
                    hideMobilePhotos
                    hidePhotos
                    onDetailClick={setShowTaskDetail}
                    list={taskListData?.taskInstance?.list}
                    total={taskListData?.taskInstance?.count}
                    starSize="xs"
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    quantity={pageSize}
                    paginate
                    onChangePageIndex={handleTaskListPagination}
                    scrollToRef={true}
                    skeletonOptions={{
                        type: "withStars",
                        variant: showOneColumn ? "mobile" : "desktop",
                        hasImage: false,
                    }}
                    showOneColumn={entityType !== ANALYST_ENTITY_TYPE.USER}
                />
            ) : (
                <TableError
                    title={t(TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH_USERS)}
                    description={t(TranslationKeys.NO_TASKS_FOR_YOUR_SEARCH_USERS_TEXT)}
                    icon={faListCheck}
                />
            )}
        </div>
    );
};
