import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { TASK_FOTO_EXMAPLE_DEFAULT_ID } from "app/shared/Constants";

export const baseTaskToDisableds = ({
    name,
    description,
    fotoExample,
    isPhotoRequired,
    reportType,
    allowAnyData,
    isCritical,
    taskTemporalityType,
    fotoExampleId,
}: BaseTaskModel): EditTaskDisabledFieldsModel => {
    return {
        disabledDataToReport: reportType !== "NONE",
        disabledChecklist: reportType === "CHECKLIST" && !allowAnyData,
        disabledDynamicFields: reportType === "DYNAMIC_FIELD" && !allowAnyData,
        disabledTaskTitle: !!name.length,
        disabledTaskDesc: !!description?.length,
        disabledFotoExample: false,
        disabledIsPhotoRequired: isPhotoRequired,
        disabledPlan: true,
        disabledIsCritical: isCritical,
        disabledType: taskTemporalityType,
    };
};
