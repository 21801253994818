import { FormEvent, useState } from "react";
import { WizardLicenseStepThreeModel } from "../../types";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { RegexPatterns } from "app/shared/Constants";

type IuseStepThree = WizardLicenseStepThreeModel & {
    next: () => void;
};
export const useStepThree = ({
    adminUserCode,
    adminEmail,
    adminFirstName,
    adminLastName,
    adminUsername,
    next,
}: IuseStepThree) => {
    const { t } = useTranslation();
    const [validations, setValidations] = useState({
        errorAdminUserCode: "",
        errorAdminEmail: "",
        errorAdminFirstName: "",
        errorAdminLastName: "",
        errorAdminUsername: "",
    });

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        let aux = { ...validations };

        aux.errorAdminFirstName = "";
        if (!adminFirstName.length) {
            aux.errorAdminFirstName = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.errorAdminLastName = "";
        if (!adminLastName.length) {
            aux.errorAdminLastName = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        aux.errorAdminUsername = "";
        if (!adminUsername.length) {
            aux.errorAdminUsername = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        const usernameRegex = new RegExp(RegexPatterns.username);
        const isUsernameValid = usernameRegex.test(adminUsername);
        if (!!adminUsername.length && !isUsernameValid) {
            aux.errorAdminUsername = t(TranslationErrors.USERNAME_INVALID_CHARACTERS);
            check = false;
        }

        aux.errorAdminEmail = "";
        if (!new RegExp(RegexPatterns.mail).test(adminEmail)) {
            aux.errorAdminEmail = t(TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE);
            check = false;
        }

        aux.errorAdminUserCode = "";
        if (!adminUserCode.length) {
            aux.errorAdminUserCode = t(TranslationCommon.INPUT_NOT_EMPTY);
            check = false;
        }

        if (check) next();
        else setValidations({ ...aux });
    };

    return {
        validations,
        validate,
    };
};
