import { FC } from "react";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TaskInstanceStatus, WorkerDoughnutColors } from "app/shared/Constants";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import { AnalistWorkerDetailModel } from "app/models/03-REP/Analytics/AnalistWorkerDetailModel";
import { PercentageStarsDetail } from "./PercentageStarsDetail/PercentageStarsDetail";
import { DoughnutValorationDetail } from "./DoughnutValorationDetail/DoughnutValorationDetail";
import { TableError } from "../Table/TableError/TableError";

type WorkerValorationDetailProps = {
    workerDetail: AnalistWorkerDetailModel;
};

export const WorkerValorationDetail: FC<WorkerValorationDetailProps> = ({ workerDetail }) => {
    const { analistWorkerDetailDonutModel, analistWorkerDetailPercentageStarsModel } = workerDetail;

    // Check stars no empty
    const {
        fiveStarsPercentage,
        fourStarsPercentage,
        threeStarsPercentage,
        twoStarsPercentage,
        oneStarsPercentage,
        avg,
        trend,
        totalTasks,
    } = analistWorkerDetailPercentageStarsModel;

    const isStarsEmpty = () =>
        (fiveStarsPercentage ?? 0) +
            (fourStarsPercentage ?? 0) +
            (threeStarsPercentage ?? 0) +
            (twoStarsPercentage ?? 0) +
            (oneStarsPercentage ?? 0) ===
        0;

    // Check donut no empty
    const {
        totalInTimeCount,
        totalOutOfTimeCount,
        totalSharedTimedOutCount,
        totalTimedOutCount,
        totalInTimePercentage,
        totalOutOfTimePercentage,
        totalSharedTimedOutPercentage,
        totalTimedOutPercentage,
    } = analistWorkerDetailDonutModel;

    const isWorkerArrayDataEmpty = () =>
        (totalInTimeCount ?? 0) +
            (totalOutOfTimeCount ?? 0) +
            (totalSharedTimedOutCount ?? 0) +
            (totalTimedOutCount ?? 0) ===
        0;

    const isAllEmpty = isStarsEmpty() && isWorkerArrayDataEmpty();

    if (isAllEmpty)
        return (
            <div className="workerValorationDetail__noResults">
                <TableError icon={faListCheck} />
            </div>
        );

    const asd: UserScoreModel[] = [
        {
            rangeType: TaskInstanceStatus.IN_TIME,
            count: totalInTimeCount,
            percentage: totalInTimePercentage,
        },
        {
            rangeType: TaskInstanceStatus.OUT_OF_TIME,
            count: totalOutOfTimeCount,
            percentage: totalOutOfTimePercentage,
        },
        {
            rangeType: TaskInstanceStatus.TIMED_OUT,
            count: totalTimedOutCount,
            percentage: totalTimedOutPercentage,
        },
        {
            rangeType: TaskInstanceStatus.SHARED_TIMED_OUT,
            count: totalSharedTimedOutCount ?? 0,
            percentage: totalSharedTimedOutPercentage ?? 0,
        },
    ];

    return (
        <div className="workerValorationDetail">
            <DoughnutValorationDetail
                doughnutData={asd}
                emptyState={isWorkerArrayDataEmpty()}
                colors={WorkerDoughnutColors}
            />
            <div>
                <PercentageStarsDetail
                    starsNumbers={[
                        fiveStarsPercentage ?? 0,
                        fourStarsPercentage ?? 0,
                        threeStarsPercentage ?? 0,
                        twoStarsPercentage ?? 0,
                        oneStarsPercentage ?? 0,
                    ]}
                    avg={avg}
                    totalTasks={totalTasks}
                    trend={Number(trend?.toFixed(0))}
                    isEmpty={isStarsEmpty()}
                />
            </div>
        </div>
    );
};
