import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Input } from "app/components_v2/__inputs";
import { Label } from "app/components_v2/__inputs/Label";
import { ReadOnlyVariants } from "app/components_v2/__inputs/ReadOnlyInput/types";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskStatus } from "app/shared/Constants";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

export type TaskFieldProps = {
    field: TaskInstanceFieldModel;
    statusCode: string;
    handleInputChange: (x: TaskInstanceFieldModel) => void;
    customError?: string;
    readonly?: boolean;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
};
export const Taskfield: FC<TaskFieldProps> = ({
    field,
    handleInputChange,
    customError,
    readonly,
    errorRef,
    disabled,
    statusCode,
}) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState<string>(
        `${
            field.taskInstanceFieldValues && field.taskInstanceFieldValues[0]?.value != null
                ? field?.taskInstanceFieldValues[0]?.value
                : ""
        }`
    );

    const isAsset: boolean = field.asset2 != null;
    const showErrors = isAsset && statusCode === TaskStatus.COMPLETED && field.dynamicFieldType === "NUMBER";
    const label = field.label || (field.taskInstanceFieldValues && field?.taskInstanceFieldValues[0]?.value);
    const minMaxPlaceholder = `${
        field.taskInstanceFieldValues[0]?.min != null
            ? `${field.taskInstanceFieldValues[0]?.min}/`
            : `${t(TranslationKeys.PLACEHOLDER_REPORT_DATA)}`
    }${
        field.taskInstanceFieldValues[0]?.max != null
            ? `${field.taskInstanceFieldValues[0]?.max} ${field.taskInstanceFieldValues[0]?.measureUnit}`
            : ""
    }`;

    const isTemperatureOutOfRange = () => {
        const min = field.taskInstanceFieldValues[0]?.min;
        const max = field.taskInstanceFieldValues[0]?.max;
        if (min == null || max == null) return false;
        if (!field?.taskInstanceFieldValues[0]?.value.length) return false;
        if (
            Number(field?.taskInstanceFieldValues[0]?.value) > max ||
            Number(field?.taskInstanceFieldValues[0]?.value) < min
        )
            return true;

        return false;
    };

    const isFieldTypeTextEmpty = () => !field.taskInstanceFieldValues[0].value.length;

    const tieneMasDeUnGuion = (str: string) => /-.*-/.test(str);
    const replaceDotsPerComma = (str: string) => str.replace(".", ",");
    const replaceCommaPerDots = (str: string) => str.replace(",", ".");

    const onChange = (value: string) =>
        handleInputChange({
            ...field,
            taskInstanceFieldValues: [{ ...field.taskInstanceFieldValues[0], value }],
        });

    const onChangeTextValue = (value: string) => {
        onChange(value);
        setInputValue(value);
    };

    const validateValue = (value: string) => {
        if (value.length === 1 && value === "-") {
            onChangeTextValue("-");
            return;
        }

        if (value.length === 0) {
            onChangeTextValue("");
            return;
        }

        const valueCommaToDot = replaceCommaPerDots(value);
        if (isNaN(Number(valueCommaToDot))) return;
        if (valueCommaToDot.length >= 1 && tieneMasDeUnGuion(valueCommaToDot)) return;
        const valueFormatted = replaceDotsPerComma(valueCommaToDot);
        setInputValue(valueFormatted);
        onChange(valueFormatted);
    };

    const showErrorBorderColor = (): boolean => {
        if (customError) return true;
        if (readonly && showErrors) {
            if (field.dynamicFieldType === "TEXT") return isFieldTypeTextEmpty();
            return isTemperatureOutOfRange();
        }
        return false;
    };

    const getBadgeIcon = (): IconProp => {
        if (field.dynamicFieldType === "TEXT" && isFieldTypeTextEmpty()) return faXmark;
        if (field.dynamicFieldType !== "TEXT" && isTemperatureOutOfRange()) return faXmark;
        return faCheck;
    };

    const getBadgeVariant = (): ReadOnlyVariants => {
        if (field.dynamicFieldType === "TEXT" && isFieldTypeTextEmpty()) return "error";
        if (field.dynamicFieldType !== "TEXT" && isTemperatureOutOfRange()) return "error";
        return "success";
    };

    return (
        <div className="taskfield__container">
            <div className="taskfield" ref={(ref) => errorRef && errorRef(ref)}>
                <div className="taskfield__content">
                    <Label label={readonly && isTemperatureOutOfRange() ? `${label} (${minMaxPlaceholder})` : label} />
                </div>
                <div className="taskfield__input">
                    <Input
                        type="text"
                        onChange={(newValue) => {
                            field.dynamicFieldType === "TEXT" ? onChangeTextValue(newValue) : validateValue(newValue);
                        }}
                        placeholder={minMaxPlaceholder}
                        value={replaceDotsPerComma(inputValue)}
                        readonly={readonly}
                        max={10}
                        showBorderColor={showErrorBorderColor()}
                        disabled={disabled}
                        readOnlyOptions={
                            showErrors ? { icon: { value: getBadgeIcon(), variant: getBadgeVariant() } } : undefined
                        }
                    />
                </div>
            </div>
            {customError && <ErrorMessage errorMessage={customError} />}
        </div>
    );
};
