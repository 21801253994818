import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TaskProgressbarColors } from "app/shared/Constants";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import DividerWithText from "../../DividerWithText/DividerWithText";
import MultiPercentageBar, { MultiPercentageBarItem } from "../../MultiPercentageBar/MultiPercentageBar";
import { TableError } from "../../Table/TableError/TableError";

type TaskProgressBarValorationDetailProps = {
    taskData: UserScoreModel[];
    emptyState: boolean;
};

export const TaskProgressBarValorationDetail: FC<TaskProgressBarValorationDetailProps> = ({ taskData, emptyState }) => {
    const { t } = useTranslation();

    const formatBarData = (data: UserScoreModel[]): MultiPercentageBarItem[] =>
        data.map(({ count, percentage, rangeType }, index) => ({
            color: TaskProgressbarColors[index % TaskProgressbarColors?.length],
            count,
            label: t(TranslationKeys[rangeType as keyof typeof TranslationKeys]),
            totalPercentage: percentage,
        }));

    return (
        <div className="taskProgressBarValorationDetail">
            <DividerWithText text={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS)} />
            <div className="taskProgressBarValorationDetail__content">
                {emptyState ? (
                    <TableError
                        icon={faListCheck}
                        title={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_SUBTITLE)}
                        description={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE)}
                    />
                ) : (
                    <MultiPercentageBar sections={formatBarData(taskData)} />
                )}
            </div>
        </div>
    );
};
