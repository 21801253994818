import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons";
import { Actions } from "app/state/actions";
import { Languages } from "app/shared/Constants";
import { SelectOptions } from "../SelectOptions/SelectOptions";
import { IconSelect } from "../IconSelect/IconSelect";
import { LanguageType } from "./type/languageType";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type LanguageSelectorProps = {
    options: OptionModel[];
    isMenu?: boolean;
    language: LanguageType;
    onSelectedLanguage: (option: OptionModel) => void;
    onChangeLanguage?: (language: string) => void;
};

const langMap = new Map<string, Languages>([
    ["en", Languages.ENGLISH],
    ["es", Languages.ESPANOL],
]);

export const LanguageSelector: FC<LanguageSelectorProps> = ({
    options,
    isMenu,
    onSelectedLanguage,
    onChangeLanguage,
    language,
}) => {
    const dispatch = useDispatch();

    const languageLabel: Record<LanguageType, Languages> = {
        es: Languages.ESPANOL,
        en: Languages.ENGLISH,
    };

    const [showOptions, setShowOptions] = useState<boolean>(false);

    const checkDefaultLang = (checkLang: string) => {
        let defaultLang: OptionModel = {
            label: !!language ? languageLabel[language] : Languages.ESPANOL,
            value: language ?? ("es" as LanguageType),
        };

        langMap.forEach((key, value) => {
            if (checkLang.toLowerCase().includes(value)) defaultLang = { label: key, value };
        });

        return defaultLang;
    };

    const handleChangeLang = async (selectedValue: OptionModel) => {
        if (language === selectedValue.value) return;
        if (isMenu && onChangeLanguage) onChangeLanguage(selectedValue.value);

        onSelectedLanguage(selectedValue);
        dispatch(Actions.updateLanguage(selectedValue.value));
    };

    const handleShow = (value: boolean) => setShowOptions(value);

    useEffect(() => {
        const defaultLanguage = checkDefaultLang(language || i18next.language);
        handleChangeLang(defaultLanguage);
    }, []);

    return (
        <div className={isMenu ? `iconSelect ${showOptions ? "grayBg200" : ""}` : "languageSelector"}>
            {isMenu ? (
                <IconSelect
                    icon={faLanguage}
                    handleChange={handleChangeLang}
                    options={options}
                    currentSelection={{
                        label: !!language ? languageLabel[language] : Languages.ESPANOL,
                        value: language || i18next.language,
                    }}
                    showOptions={handleShow}
                />
            ) : (
                <SelectOptions selectedValue={language} options={options} onChange={handleChangeLang} isMulti={false} />
            )}
        </div>
    );
};
