import { FC } from "react";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type GoBackIconProps = {
    onClick: () => void;
};

export const GoBackIcon: FC<GoBackIconProps> = ({ onClick }) => {
    return (
        <div className="goBackIcon" onClick={onClick}>
            <FontAwesomeIcon icon={faArrowLeft} className="goBackIcon__icon" />
        </div>
    );
};
