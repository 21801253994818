import { FC } from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { screenSize } from "app/shared/Constants";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import { SupervisorDoughnutColorModel, WorkerDoughnutColorModel } from "app/components_v2/SupervisorAnalist/types";
import { DoughnutItem } from "../../DoughnutChart/types";
import DividerWithText from "../../DividerWithText/DividerWithText";
import { TableError } from "../../Table/TableError/TableError";
import { DoughnutChart } from "../../DoughnutChart/DoughnutChart";

type DoughnutValorationDetailProps = {
    doughnutData: UserScoreModel[];
    emptyState: boolean;
    colors: (SupervisorDoughnutColorModel | WorkerDoughnutColorModel)[];
};

export const DoughnutValorationDetail: FC<DoughnutValorationDetailProps> = ({ doughnutData, emptyState, colors }) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const formatDoughnutData = (data: UserScoreModel[]): DoughnutItem[] =>
        data.map(({ count, percentage, rangeType }, index) => ({
            id: index,
            color: colors[index % colors?.length],
            count,
            label: t(TranslationKeys[rangeType as keyof typeof TranslationKeys]),
            percentage,
            isFocused: true,
        }));

    return (
        <div className="doughnutValorationDetail">
            <DividerWithText text={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_TASK_TYPES)} />
            {emptyState ? (
                <TableError
                    icon={faListCheck}
                    title={t(TranslationKeys.NO_VALORATION)}
                    description={t(TranslationKeys.NO_VALORATION_SUBTITLE)}
                />
            ) : (
                <div className="doughnutValorationDetail__content">
                    <DoughnutChart
                        data={formatDoughnutData(doughnutData)}
                        row={width > screenSize.LAPTOP}
                        maxWidth={width > screenSize.MOBILE ? 300 : 200}
                        allowOffset={false}
                        clickable={false}
                        showEmptyItems={true}
                    />
                </div>
            )}
        </div>
    );
};
