import { SortedTypeModel } from "app/components_v2/Table/types";
import { getDateFormattedSelects } from "app/helpers";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";

type IProps = {
    currentTab: number;
    sortDirection: SortedTypeModel;
    sortField: string;
    companyId: string;
    openDate?: Date;
    openDate2?: Date;
    closedDate?: Date;
    closedDate2?: Date;
};
export const fillExtraparams = ({
    currentTab,
    sortDirection,
    sortField,
    companyId,
    closedDate2,
    closedDate,
    openDate2,
    openDate,
}: IProps) => {
    const extraparams = new URLSearchParams();
    extraparams.append("Status", Status[currentTab]);
    extraparams.append("SortField", sortField);
    extraparams.append("SortDirection", sortDirection);
    if (companyId !== "-1") extraparams.append("CompanyId", companyId);
    if (!!closedDate) extraparams.append("closedDate", getDateFormattedSelects(closedDate));
    if (!!closedDate2) extraparams.append("closedDate2", getDateFormattedSelects(closedDate2));
    if (!!openDate) extraparams.append("openDate", getDateFormattedSelects(openDate));
    if (!!openDate2) extraparams.append("openDate2", getDateFormattedSelects(openDate2));

    return extraparams.toString();
};

const Status: Record<number, AuditInstanceStatus> = {
    0: "IN_PROGRESS",
    1: "COMPLETED",
    2: "REVIEWED",
    3: "CLOSED",
};
