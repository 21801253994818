import { FC } from "react";
import { BadgeSize, BadgeVariant } from "../badgeTypes";

type BadgeTitleProps = {
    size: BadgeSize;
    variant: BadgeVariant;
    title?: string;
    bolderTextEnd?: string;
    bolderTextStart?: string;
    disabled?: boolean;
    theme?: "dark" | "light";
    clamp?: boolean;
};

export const BadgeTitle: FC<BadgeTitleProps> = ({
    size,
    title,
    variant,
    bolderTextEnd,
    bolderTextStart,
    disabled,
    theme = "light",
    clamp,
}) => {
    return (
        <p
            className={`badgeTitle--${variant} badgeTitle--${size} ${disabled ? "badgeTitle--disabled" : ""} ${theme} ${
                clamp ? "badgeTitle--clamp" : ""
            }`}
            aria-label="titulo badge"
        >
            {bolderTextStart ? <span className={`badgeTitle--${size}--bolder`}>{bolderTextStart} </span> : ""}
            {title && title}
            {bolderTextEnd ? <span className={`badgeTitle--${size}--bolder`}>{bolderTextEnd}</span> : ""}
        </p>
    );
};
