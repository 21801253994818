import { createContext } from "react";
import { CloseCompaniesAndDepartmentsDtoModel } from "app/dtos/01-SEG/CloseCompaniesAndDepartmentsDtoModel/CloseCompaniesAndDepartmentsDtoModel";

export type CloseCompanyModalContextModel = {
    closeOpenCompaniesAndDepartments: CloseCompaniesAndDepartmentsDtoModel;
    isOpen: boolean;
    open: (closeOpenCompanies: CloseCompaniesAndDepartmentsDtoModel) => void;
    reset: () => void;
};

export const CloseCompanyModalContext = createContext<CloseCompanyModalContextModel>(
    {} as CloseCompanyModalContextModel
);
