import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { FC, ReactNode } from "react";
import { screenSize } from "app/shared/Constants";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { TableActionButtons } from "app/components_v2/Table/TableActionButtons/TableActionButtons";
import { TaskHisotryGridSfModel } from "../../../state/context/taskHistoryGridContext";
import { TaskHistoryFilter } from "app/components_v2/__filters/TaskHistoryFilter/TaskHistoryFilter";
import { useWindowSize } from "usehooks-ts";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { SelectOptions } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TranslationKeys } from "app/translation/translationKeys";

type TaskHistoryHeaderProps = {
    title: string;
    secondayFilter: {
        filterData: TaskHisotryGridSfModel;
        initialFilterData: TaskHisotryGridSfModel;
        showSecondaryFilter: boolean;
        companyId: string;
        onCancel?: () => void;
        onSave?: (values: TaskHisotryGridSfModel) => void;
    };
    actionButtons: ActionButtonsModel[];
    tabs?: ReactNode;
    variant?: TabHeaderVariants;
    onChangePlan?: (plan: string) => void;
    planOptions?: OptionModel[];
    selectedPlan?: string;
};

export const TaskHistoryHeader: FC<TaskHistoryHeaderProps> = ({
    title,
    secondayFilter,
    actionButtons,
    tabs,
    variant = "primary",
    planOptions,
    selectedPlan,
    onChangePlan,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <>
            <TabHeader
                paddingSize="sm"
                classNameToScrollTop=".customContainer"
                variant={variant}
                tabs={tabs}
                tabHeight={width >= screenSize.MOBILE ? 50 : 40}
            >
                <div className="taskHistoryHeader">
                    <div className="taskHistoryHeader__plan">
                        <h1 className="taskHistoryHeader__title">{title}</h1>
                        {variant === "appcc" && (
                            <div className="planAPPCCGridHeader__select">
                                <SelectOptions
                                    isMulti={false}
                                    onChange={({ value }) => onChangePlan && onChangePlan(value)}
                                    options={planOptions || []}
                                    placeholder={t(TranslationKeys.ALL_PLANS)}
                                    selectedValue={
                                        !!selectedPlan?.length && selectedPlan !== "-1" ? selectedPlan : undefined
                                    }
                                    isSearchable
                                    inverted
                                />
                            </div>
                        )}
                    </div>
                    {width < screenSize.MOBILE && (
                        <div className="taskHistoryHeader__icons">
                            <TableActionButtons actionButtons={actionButtons} />
                        </div>
                    )}
                </div>
            </TabHeader>
            <TaskHistoryFilter {...secondayFilter} variant={variant} planId={selectedPlan || "-1"} />
        </>
    );
};
