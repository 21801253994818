import { AvatarSkelleton } from "../../../AvatarSkelleton/AvatarSkelleton";

export const TaskPillBodyHeaderSkelleton = () => {
    return (
        <div className="taskPillBodyHeader-skelleton">
            <AvatarSkelleton />
            <div className="taskPillBodyHeader-skelleton__actions">
                <div className="taskPillBodyHeader-skelleton__actions__badge"></div>
            </div>
        </div>
    );
};
