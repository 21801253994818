import { FC, useState } from "react";
import ButtonGroup from "app/components_v2/__buttons/Buttongroup/ButtonGroup";
import { Section, SectionsShowProp } from "../Section/Section";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";

export const ButtonGroupSection: FC<SectionsShowProp> = ({ show }) => {
    const [selectedValue, setSelectedValue] = useState<string>("");

    const items: ButtonGroupItem[] = [
        {
            label: "Completda",
            value: "1",
        },
        {
            label: "Completada Fuera de Tiempo",
            value: "2",
        },
        {
            label: "A punto de vencer",
            value: "3",
        },
        {
            label: "Vencida",
            value: "4",
        },
        {
            label: "Vencida Compartida",
            value: "5",
        },
    ];

    return (
        <Section title="Button group" show={show}>
            <ButtonGroup
                label="Buttons group:"
                onSelect={({ value }) => setSelectedValue(value)}
                items={items}
                selectedValue={selectedValue}
            />
            <p className="designSys__section__element__title"></p>
            <ButtonGroup
                label="Buttons group inverted:"
                onSelect={({ value }) => setSelectedValue(value)}
                items={items}
                inverted
                selectedValue={selectedValue}
            />
        </Section>
    );
};
