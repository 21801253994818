import { useTranslation } from "react-i18next";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { TranslationFAQs, TranslationTitles } from "app/translation/translationKeys";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { useTitle } from "app/hooks";

type FaqsModel = {
    title: string;
    description: string;
};

enum FAQType {
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION",
}

export const FAQList = () => {
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.FAQS_PAGE_TITLE));

    const mapTranslationFAQsToList = (): FaqsModel[] => {
        const faqs: FaqsModel[] = [];
        for (const key in TranslationFAQs) {
            if (key.endsWith("_TITLE")) {
                const titleKey: string = key;
                const descKey: string = key.replace(FAQType.TITLE, FAQType.DESCRIPTION);

                if (Object.prototype.hasOwnProperty.call(TranslationFAQs, descKey)) {
                    faqs.push({
                        title: t(titleKey),
                        description: t(descKey),
                    });
                }
            }
        }
        return faqs;
    };

    return (
        <PageContainer
            header={
                <TabHeader>
                    <p className="FAQList__title">{t(TranslationTitles.FAQS_PAGE_TITLE)}</p>
                </TabHeader>
            }
        >
            <WhiteBox fullHeight>
                <div className="FAQList">
                    {mapTranslationFAQsToList().map(({ title, description }, i) => (
                        <Collapsable key={i} isCollapsable open={true} title={title}>
                            <p className="FAQList__description">{description}</p>
                        </Collapsable>
                    ))}
                </div>
            </WhiteBox>
        </PageContainer>
    );
};
