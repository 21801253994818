import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { CustomWeek, TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { FC, ReactNode } from "react";
import { TaskTemporalityRangeHour } from "../TaskTemporalityRangeHour/TaskTemporalityRangeHour";
import { Divider } from "app/components_v2/Divider/Divider";
import { RecurrentTaskModel, taskEnds, taskHoursModel } from "app/models/02-TAR/TaskWizard";
import {
    CustomRepeatEvery,
    CustomSelectOptionsEveryMonth,
    SelectRecurringTask,
} from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/components";
import { useTaskTemporality } from "./useTaskTemporality";
import { useTaskTemporalityRangeHours } from "../hooks/useTaskTemporalityRangeHours";
import { TaskTemporalityPeriodicalEnds } from "../TaskTemporalityPeriodicalEnds/TaskTemporalityPeriodicalEnds";

type TaskTemporalityPeriodicalProps = {
    customError: CustomErrorTaskTemporalityModel;
    taskStart: string;
    isDisabled?: boolean;
    min?: Date;
    taskHours: taskHoursModel[];
    recurringTask: RecurrentTaskModel | null;
    finish: taskEnds;
    taskstartLabel?: string;
    hint?: ReactNode;
    hideRecurrentSelector?: boolean;
    hideTaskEnds?: boolean;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityPeriodical: FC<TaskTemporalityPeriodicalProps> = ({
    taskStart,
    taskHours,
    isDisabled,
    min,
    customError,
    recurringTask,
    finish,
    onChange,
    taskstartLabel,
    hint,
    hideRecurrentSelector,
    hideTaskEnds,
}) => {
    const { recurentTaskSelectorValues, customOptionsValue, weekOptions } = useTaskTemporality(
        taskStart,
        recurringTask
    );

    const { onAddNewHour, onAddNewRangeHour, onDeleteHour } = useTaskTemporalityRangeHours({
        onChange,
        taskHours,
    });

    if (!recurringTask) return null;

    const isCustom = recurringTask.value === "custom";

    return (
        <>
            <div className="taskTemporalityPeriodical__taskStart">
                <TaskStart
                    onChange={onChange}
                    taskStart={taskStart}
                    customError={customError.taskStart}
                    disabled={isDisabled}
                    min={min}
                    label={taskstartLabel}
                    hint={hint}
                />
            </div>
            <div className="taskTemporalityPeriodical__container">
                <TaskTemporalityRangeHour
                    errorMessage={customError.taskHours}
                    onAddNewHour={onAddNewHour}
                    onAddNewRangeHour={onAddNewRangeHour}
                    onChange={onChange}
                    onDeleteHour={onDeleteHour}
                    taskHours={taskHours}
                    isDisabled={isDisabled}
                />
                <Divider />
                <div className="taskTemporalityPeriodical__recurrency">
                    {recurringTask.isRecurrent && (
                        <>
                            <div className="taskTemporalityPeriodical__recurrency__repeat">
                                {!hideRecurrentSelector && (
                                    <SelectRecurringTask
                                        onChange={onChange}
                                        recurentTaskSelectorValues={recurentTaskSelectorValues}
                                        recurringTask={recurringTask}
                                        disabled={isDisabled}
                                    />
                                )}

                                {isCustom && (
                                    <div className="taskTemporality__customRepeat">
                                        <CustomRepeatEvery
                                            recurringTask={recurringTask}
                                            onChange={onChange}
                                            disabled={isDisabled}
                                            options={customOptionsValue}
                                        />
                                    </div>
                                )}
                                {recurringTask.custom.customValue === "week" && isCustom && (
                                    <CustomWeek
                                        recurringTask={recurringTask}
                                        onChange={onChange}
                                        customError={customError.customDaysBubles}
                                        disabled={isDisabled}
                                        errorClassName="task__temp__errorMessage"
                                    />
                                )}

                                {recurringTask.value === "custom" &&
                                    recurringTask.custom.customValue === "everyMonth" && (
                                        <CustomSelectOptionsEveryMonth
                                            recurringTask={recurringTask}
                                            onChange={onChange}
                                            weekOptions={weekOptions}
                                            customError={customError.customMonth}
                                            disabled={isDisabled}
                                        />
                                    )}
                            </div>

                            {!!taskStart.length && !hideTaskEnds && (
                                <TaskTemporalityPeriodicalEnds
                                    customError={customError}
                                    finish={finish}
                                    isDisabled={isDisabled}
                                    min={taskStart}
                                    onChange={onChange}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
