import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faStarSharp, faUser, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { TeamAvgDto } from "app/dtos/03-REP/Analytics/TeamAvgDto";
import DividerWithText from "../../DividerWithText/DividerWithText";
import SupervisorTeamCount from "../../SupervisorTeamCount/SupervisorTeamCount";
import { TableError } from "../../Table/TableError/TableError";
import { UserValoration } from "../../UserValoration/UserValoration";

type TeamValorationDetailProps = {
    teamAvg: TeamAvgDto;
    emptyState: boolean;
};

export const TeamValorationDetail: FC<TeamValorationDetailProps> = ({ teamAvg, emptyState }) => {
    const { t } = useTranslation();

    return (
        <div className="teamValorationDetail">
            <DividerWithText text={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TEAMS)} />
            {emptyState ? (
                <TableError icon={faStarSharp} title={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_TEAM_AVG)} />
            ) : (
                <div className="teamValorationDetail__content">
                    <div>
                        <UserValoration
                            valoration={teamAvg?.avg || 0}
                            total={String(teamAvg?.count)}
                            roundedTrend={Number(teamAvg?.trend)}
                            variant="gray"
                        />
                    </div>
                    <div className="teamValorationDetail__counters">
                        <SupervisorTeamCount icon={faUsers} label={t(TranslationKeys.TEAMS)} number={teamAvg?.team} />
                        <SupervisorTeamCount
                            icon={faUser}
                            label={t(TranslationKeys.WORKERS)}
                            number={teamAvg?.people}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
