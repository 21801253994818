import { FC } from "react";
import { RegexValidationItemModel } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";

type RegexValidatorItemProps = {
    item: RegexValidationItemModel;
    iconTitle?: string;
};
export const RegexValidatorItem: FC<RegexValidatorItemProps> = ({ item, iconTitle }) => {
    const { errorMessage, isInvalid } = item;
    return (
        <div className="regexValidationItem">
            <FontAwesomeIcon
                icon={isInvalid ? faCircleXmark : faCheckCircle}
                className={`regexValidationItem__icon${isInvalid ? "--invalid" : "--valid"}`}
                title={iconTitle}
            />
            <p className={`regexValidationItem__errorMessage${isInvalid ? "--invalid" : "--valid"}`}>{errorMessage}</p>
        </div>
    );
};
