import { FC, useState } from "react";
import { Input } from "../__inputs";
import { validateNumberLength } from "app/helpers/__validates/validateNumberLength";

type dropdownItem = { id: number; label: string; value: string };

type TaskDynamicFieldProps = {
    isRequired: boolean;
    label: string;
    fieldValue: string;
    readonly: boolean;
    errorMessage?: string;
    onChange: (newValue: string | dropdownItem[]) => void;
};

export const TaskDynamicField: FC<TaskDynamicFieldProps> = ({
    isRequired,
    label,
    fieldValue,
    readonly,
    errorMessage,
    onChange,
}) => {
    const [inputValue, setInputValue] = useState<string>(fieldValue);
    const tieneMasDeUnGuion = (str: string) => /-.*-/.test(str);
    const replaceDotsPerComma = (str: string) => str.replace(".", ",");
    const replaceCommaPerDots = (str: string) => str.replace(",", ".");

    const onChangeTextValue = (value: string) => {
        onChange(value);
        setInputValue(value);
    };

    const validateValue = (value: string) => {
        if (value.length === 1 && value === "-") {
            onChangeTextValue("-");
            return;
        }

        if (value.length === 0) {
            onChangeTextValue("");
            return;
        }

        const valueCommaToDot = replaceCommaPerDots(value);
        if (!validateNumberLength(Number(valueCommaToDot), 10)) return;
        if (isNaN(Number(valueCommaToDot))) return;
        if (valueCommaToDot.length >= 1 && tieneMasDeUnGuion(valueCommaToDot)) return;
        const valueFormatted = replaceDotsPerComma(valueCommaToDot);
        setInputValue(valueFormatted);
        onChange(valueFormatted);
    };

    return (
        <div className="fullWidth">
            <Input
                onChange={validateValue}
                label={label}
                isOptional={!isRequired}
                placeholder={label}
                errorMessage={errorMessage}
                readonly={readonly}
                value={inputValue}
            />
        </div>
    );
};
