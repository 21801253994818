import { PrivatePaths, screenSize } from "app/shared/Constants";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "./useSession";
import { useWindowSize } from "usehooks-ts";
import { useNavigateToInitialScreen } from "./useNavigateToInitialScreen";
import { PreviousPathNameContext } from "app/state/context/prevoiusPathNameContext/prevoiusPathNameContext";

export const useGoBack = () => {
    const nav = useNavigate();
    const location = useLocation();
    const session = useSession();
    const { width } = useWindowSize();
    const { navigateToInitialScreen } = useNavigateToInitialScreen();

    const { previousPathName, changePathNames, pathNames, previousGoBack2, setPathNames } =
        useContext(PreviousPathNameContext);

    const handleParentGoBack = () => {
        if (!pathNames[pathNames.length - 2]) {
            if (location.search) nav(`/${location.pathname}${location.search}`);
            else nav("/");

            return;
        }

        const path =
            pathNames[pathNames.length - 2] === location.pathname
                ? `/${previousPathName.split("/")[1]}`
                : pathNames[pathNames.length - 2];
        changePathNames();
        nav(`${path}${location.search}`);
    };

    const handleGoBack = (customPathName?: string) => {
        const path =
            customPathName ||
            (previousPathName === location.pathname ? `/${PrivatePaths.DASHBOARD}` : previousPathName);
        nav(path);
    };

    const pathParentGoBack = () => {
        const path = previousPathName === location.pathname ? `/${previousPathName.split("/")[1]}` : previousPathName;
        return path;
    };

    const pathRealParentGoBack = () => {
        const path = `/${location.pathname.split("/")[1]}${location.search}`;
        return path;
    };

    const realParentGoBack = () => {
        nav(pathRealParentGoBack());
    };

    const handlePostLoginNavigate = () => {
        if (pathNames[pathNames.length - 1] === location.pathname && pathNames.length === 1) {
            const firstRedirect = `/${PrivatePaths.DASHBOARD.split("/").filter((x) => x.length > 0)[0]}`;
            nav(firstRedirect);
            return;
        }

        if (pathNames[pathNames.length - 2] === undefined) {
            const redirectWithPreviousPathname =
                previousPathName === location.pathname
                    ? session?.user.initialScreenCode
                        ? `/${PrivatePaths.DASHBOARD.split("/").filter((x) => x.length > 0)[0]}`
                        : previousPathName
                    : previousPathName;
            nav(redirectWithPreviousPathname);
        }
        nav(pathNames[pathNames.length - 2]);
    };

    const goBack = () => {
        const paths = pathNames;
        paths.pop();

        !previousGoBack2 ? navigateToInitialScreen() : nav(previousGoBack2);

        setPathNames(paths);
        return;
    };

    const getOriginPathname = () => {
        const search = location.search;
        const pathname = location.pathname.split("/").filter((x) => x)[0];
        if (!!search.length) {
            return `${pathname}${search}`;
        }
        return pathname;
    };

    const goBackToNotificationPage = () => {
        const notificationPathname = pathNames[pathNames.length - 2] || "";
        if (notificationPathname === `/${PrivatePaths.NOTIFICATION}` && width <= screenSize.TABLET) {
            nav(`/${PrivatePaths.NOTIFICATION}`);
            return true;
        }
        return false;
    };

    const removeUrlFromPathnames = (url: string) => setPathNames(pathNames.filter((path) => path !== url));

    return {
        handleParentGoBack,
        handleGoBack,
        pathParentGoBack,
        realParentGoBack,
        handlePostLoginNavigate,
        goBack,
        pathNames,
        getOriginPathname,
        goBackToNotificationPage,
        removeUrlFromPathnames,
        previousPathName,
    };
};
