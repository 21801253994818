import { faCheck, faClock } from "@fortawesome/pro-regular-svg-icons";
import { RoundedFilterButtonGroup } from "app/components_v2/RoundedFilterButtonGroup/RoundedFilterButtonGroup";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuditHeaderButtons } from "./types";
import { AuditTranslation } from "app/translation/translationKeys";

type AuditHeaderProps = {
    title: string;
    onClick: (buttonValue: AuditHeaderButtons) => void;
    counters: Record<AuditHeaderButtons, number>;
    currentFilterButton: AuditHeaderButtons;
};

const actionButtons: Record<number, AuditHeaderButtons> = {
    0: "IN_PROGRESS",
    1: "DONE",
};

const filterButtonsToId: Record<AuditHeaderButtons, number> = {
    IN_PROGRESS: 0,
    DONE: 1,
};

export const AuditHeader: FC<AuditHeaderProps> = ({ onClick, title, counters, currentFilterButton }) => {
    const { t } = useTranslation();
    return (
        <div className="auditGrid__header">
            <h1 className="auditGrid__header__title">{title}</h1>
            <RoundedFilterButtonGroup
                items={[
                    {
                        id: 0,
                        icon: faClock,
                        title: t(AuditTranslation.AUDIT_IN_PROGRESS_FILTER_BUTTON),
                        number: counters.IN_PROGRESS,
                    },
                    {
                        id: 1,
                        icon: faCheck,
                        title: t(AuditTranslation.AUDIT_DONE_FILTER_BUTTON),
                        number: counters.DONE,
                    },
                ]}
                active={filterButtonsToId[currentFilterButton]}
                onClick={(id) => onClick(actionButtons[id])}
                fontSize="sm"
                variant="appcc"
            />
        </div>
    );
};
