import { getDateFormatted } from "../Date.utilities";
import { DateRange } from "app/models/utilities";

export const hasOverlappingRanges = (closeCompanies: DateRange[]): boolean => {
    // Convertir las fechas a objetos Date y ordenar los rangos por closeDate
    const parsedRanges = closeCompanies
        .map(({ "0": closeDate, "1": openDate }) => {
            if (!closeDate || !openDate) return;
            return {
                closeDate: getDateFormatted(new Date(closeDate)),
                openDate: getDateFormatted(new Date(openDate)),
            };
        })
        .filter((range) => !!range)
        .map((range) => ({
            closeDate: new Date((range?.closeDate || "").split("-").reverse().join("-")),
            openDate: new Date((range?.openDate || "").split("-").reverse().join("-")),
        }))
        .sort((a, b) => a.closeDate.getTime() - b.closeDate.getTime());

    // Verificar si hay solapamientos entre rangos consecutivos
    for (let i = 0; i < parsedRanges.length - 1; i++) {
        if (parsedRanges[i].openDate >= parsedRanges[i + 1].closeDate) {
            return true; // Hay un solapamiento
        }
    }
    return false; // No hay solapamientos
};
