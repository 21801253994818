import { forwardRef } from "react";
import { SquareInputSize } from "./types";

type SquareInputProps = {
    onChange: (value: string) => void;
    onDelete: () => void;
    value: string;
    size?: SquareInputSize;
    placeholder?: string;
    focus?: boolean;
    pattern?: RegExp;
};

export const SquareInput = forwardRef<HTMLInputElement, SquareInputProps>(
    ({ onChange, value, size = "lg", placeholder, onDelete, focus, pattern }, ref) => {
        const handleChange = (newValue: string) => {
            if (pattern && !pattern.test(newValue)) return;
            if (newValue) onChange(newValue);
        };
        return (
            <input
                ref={ref}
                type="text"
                value={value}
                autoFocus={focus}
                onChange={(e) => {
                    const newValue = e.target.value.trim().toUpperCase();
                    handleChange(newValue);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Backspace" || e.key === "Delete") {
                        e.preventDefault();
                        onDelete();
                    }
                }}
                className={`sqaureInput sqaureInput--${size}`}
                placeholder={placeholder}
                maxLength={1}
            />
        );
    }
);
