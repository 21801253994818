import { FC, useEffect, useState } from "react";
import { ProviderProps } from "../types";
import { ToatsProps } from "app/components_v2/Toasts/toastModels";
import { ToastContainer, toast } from "react-toastify";
import { Toasts } from "app/components_v2/Toasts/Toasts";
import { ToastContext } from "app/state/context/toastContext";

export const ToastProvider: FC<ProviderProps> = ({ children }) => {
    const [toastData, setToastData] = useState<ToatsProps | null>(null);

    const addToast = (newToast: ToatsProps) => {
        setToastData(newToast);
    };

    const removeToast = (id: string) => {
        toast.dismiss(id);
    };

    useEffect(() => {
        toast.onChange((payload) => {
            payload.status === "removed" && setToastData(null);
        });
    }, []);

    useEffect(() => {
        if (!toastData) return;
        const autoClose = toastData.isLoading ? false : 2000;
        toast(<Toasts {...toastData} />, {
            toastId: toastData.id,
            autoClose,
        });
    }, [toastData]);

    return (
        <ToastContext.Provider
            value={{
                addToast,
                removeToast,
            }}
        >
            {children}
            <ToastContainer
                className="toastGlobal"
                toastClassName={
                    toastData?.type === "alert"
                        ? `toastAlert--${toastData.variant} ${!toastData?.subtitle ? "toastAlert--lessPadding" : ""}`
                        : `toastAlert--notification ${!toastData?.subtitle ? "toastAlert--lessPadding" : ""}`
                }
                hideProgressBar={true}
            />
        </ToastContext.Provider>
    );
};
