import { FieldValue } from "app/components_v2/WizardTask";
import { checkListModel, DynamicFieldsModel, reportType, DynamicFieldsType } from "app/models/02-TAR/TaskWizard";
import { v4 } from "uuid";

export const onAddNewCheckList = (checkList: checkListModel[]): checkListModel[] => {
    const itemsNoDeleted = checkList.filter((el) => !el.isDeleted);
    if (!!itemsNoDeleted.length && itemsNoDeleted[itemsNoDeleted.length - 1].name === "") return checkList;
    return [...checkList, { id: v4(), isDeleted: false, name: "" }];
};

export const onDeleteCheckList = (checkListId: string, checkList: checkListModel[]): checkListModel[] => {
    if (checkList.filter((el) => !el.isDeleted).length <= 1) return checkList;
    const selectedCheckList = checkList.find(({ id }) => id === checkListId);
    if (!selectedCheckList) return checkList;
    if (selectedCheckList.dbId) {
        return checkList.map((item) => (item.id === checkListId ? { ...item, isDeleted: true } : item));
    }
    return checkList.filter(({ id }) => id !== checkListId);
};

export const onBlurCheckList = (checkList: checkListModel[]): checkListModel[] => {
    if (checkList.filter((el) => !el.isDeleted).length <= 1) return checkList;

    const checkListWithDbIdsAndEmptyValue = checkList.map((item) =>
        item.name === "" && item.dbId ? { ...item, isDeleted: true } : { ...item }
    );

    return checkListWithDbIdsAndEmptyValue.filter(
        (el) => el.dbId || (el.dbId === undefined && el.isDeleted === false && el.name !== "")
    );
};

export const onSelectCheckList = (fieldValue: FieldValue, checkList: checkListModel[]): checkListModel[] => {
    if (!fieldValue?.id) return checkList;
    return checkList.map((value) => (value.id === fieldValue.id ? { ...value, name: fieldValue.value } : value));
};

export const onResetBaseTaskReportFields = (
    checkList: checkListModel[],
    dynamicFields: DynamicFieldsModel[],
    selectableValue: reportType | undefined,
    dynamicFieldsType: DynamicFieldsType | undefined
) => {
    const checklistReset: checkListModel[] | undefined = [
        ...checkList
            .map((data) => ({ ...data, isDeleted: true }))
            .filter(({ dbId }) => dbId)
            .filter(({ name }) => name),
    ];
    const newChecklist: checkListModel = {
        id: v4(),
        isDeleted: false,
        name: "",
    };
    const resetDynamicField: DynamicFieldsModel[] | undefined = [
        ...dynamicFields.map((data) => ({ ...data, isDeleted: true })).filter(({ dbId }) => dbId),
    ];

    return {
        reportType: selectableValue,
        reportData: [],
        checkList: selectableValue !== "CHECKLIST" ? checklistReset : [...checkList, newChecklist],
        dynamicFields: selectableValue !== "DYNAMIC_FIELD" || !dynamicFieldsType ? resetDynamicField : [],
    };
};
