import { useToast } from "app/hooks/Toast/useToast";
import { TaskInstanceCheckListModel } from "app/models/02-TAR/TaskInstance/TaskInstanceCheckListModel";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import { IssueAssetsModalContext } from "app/state/context/issueAssetsModalContext/issueAssetsModalContext";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { MutableRefObject, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "./useFetchErrors";
import { validateTaskDetailDynamicFields } from "app/components_v2/TaskDetailDynamicFields/TaskDetailDynamicFieldsValidation";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { MAX_ASSET_OUT_OF_RANGE_ISSUES } from "app/pages/05-QUA/Asset/constants/assetConstants";

export const useCompleteTaskInstance = (
    setTaskInstance?: (value: TaskInstanceModel) => void,
    onComplete?: () => void,
    setIsConfirmModalOpenEmtpyOptionals?: (value: boolean) => void,
    onLoading?: (value: boolean) => void
) => {
    const { open: openIssueAssetModal } = useContext(IssueAssetsModalContext);
    const { handleToast } = useToast();

    const [imageCustomError, setImageCustomError] = useState<string>("");
    const [customErrors, setCustomErrors] = useState<Map<number | string, string>>();
    const [firstError, setFirstError] = useState<string | null>(null);
    const [errorFound, setErrorFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [checkListModalOpen, setcheckListModalOpen] = useState<boolean>(false);
    const { getErrorMessage } = useFetchErrors();
    const [dynamicFieldsErrors, setDynamicFieldsErrors] = useState<number[]>([]);
    const [temperatureOutOfRangeModalIsOpen, setTemperatureOutOfRangeModalIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    const completeTaskInstance = async (taskInstance?: TaskInstanceModel, fields?: TaskInstanceFieldModel[]) => {
        if (!taskInstance) return;

        setLoading(true);
        onLoading && onLoading(true);

        const sr = await TaskInstanceService.CompleteTask(
            {
                id: taskInstance.id,
                taskInstanceFields: taskInstance.taskInstanceFields?.map((field) => ({
                    ...field,
                    asset: undefined,
                    asset2: undefined,
                })),
                taskInstanceFields2: fields?.map((field) => ({
                    ...field,
                    asset: undefined,
                    asset2: undefined,
                    assetFieldRange: undefined,
                })),
            },
            taskInstance.id
        );

        if (!sr.status()) {
            handleToast({
                title: getErrorMessage(sr.getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setLoading(false);
            onLoading && onLoading(false);
            onComplete && onComplete();
            return;
        }

        handleToast({
            title: t(TranslationModals.TOAST_SUCCESS_TASK),
            variant: "success",
            type: "alert",
        });
        const tasInstanceSr = await TaskInstanceService.GetOne(taskInstance.id);
        if (tasInstanceSr.status()) setTaskInstance && setTaskInstance(tasInstanceSr.data);
        setLoading(false);
        onLoading && onLoading(false);
        onComplete && onComplete();
    };

    const handleTaskComplete = async (
        taskInstance?: TaskInstanceModel,
        fields?: TaskInstanceFieldModel[],
        images?: ImageDragFile[],
        taskCheckList?: TaskInstanceCheckListModel[],
        errorRefs?: MutableRefObject<HTMLDivElement[]>,
        e?: React.MouseEvent<HTMLElement>
    ) => {
        e?.preventDefault();
        let foundError: string | null = null;
        let focused = false;
        let validationCheck = true;
        let fieldsOutOfRange = false;
        const outOfRangeAssets: TaskInstanceFieldModel[] = [];
        const errors = new Map<number | string, string>();

        const inputsAllowedToHaveErrors: boolean[] = [];
        const fieldsToValidate = fields?.filter(({ isHidden }) => !isHidden) || [];

        if (!!fieldsToValidate?.length && fieldsToValidate[0]?.asset2 != null) {
            fieldsToValidate?.forEach((field, i) => {
                const taskFieldMax = field.taskInstanceFieldValues[0].max;
                const taskFieldMin = field.taskInstanceFieldValues[0].min;
                const taskFieldValue = field.taskInstanceFieldValues[0].value;

                inputsAllowedToHaveErrors[i] = true;
                if (field.asset2?.notWorking) {
                    inputsAllowedToHaveErrors[i] = false;
                    return;
                }
                if (taskFieldMax == null || taskFieldMin == null) return;

                if (taskFieldValue != null && !!taskFieldValue.length) {
                    if (taskInstance?.taskInstanceFields2?.some((field) => field.asset2 != null)) {
                        inputsAllowedToHaveErrors[i] = false;
                        if (
                            taskFieldMin > Number(field.taskInstanceFieldValues[0].value) ||
                            taskFieldMax < Number(field.taskInstanceFieldValues[0].value)
                        ) {
                            const issueConsecutiveCount = field.asset2?.issueCount || 0;
                            if (issueConsecutiveCount >= MAX_ASSET_OUT_OF_RANGE_ISSUES - 1)
                                outOfRangeAssets.push(field);
                            fieldsOutOfRange = true;
                        }
                    } else errors?.set(field.id, "");
                } else {
                    validationCheck = false;
                    errors?.set(field.id, t(TranslationKeys.REPORTED_DATA_TASK));
                    if (!focused) {
                        document.getElementById(field.id.toString())?.focus();
                        focused = true;
                    }
                }
            });
        } else {
            const { invalidFieldIds, hasEmptyOptionals } = validateTaskDetailDynamicFields(fieldsToValidate!);
            const errorsDynamicFields = fieldsToValidate ? invalidFieldIds : [];

            if (!invalidFieldIds.length && hasEmptyOptionals) {
                setIsConfirmModalOpenEmtpyOptionals && setIsConfirmModalOpenEmtpyOptionals(hasEmptyOptionals);
                return false;
            }
            setDynamicFieldsErrors(errorsDynamicFields);
            validationCheck = !errorsDynamicFields.length;
        }
        setCustomErrors(errors);

        errorRefs &&
            errorRefs.current.every((ref, i) => {
                const input = ref.querySelector("input") as HTMLInputElement;
                if (input.value === "" && inputsAllowedToHaveErrors[i] === true) {
                    foundError = input.id;
                    return true;
                }
                return false;
            });

        if (foundError) {
            setFirstError(foundError);
            setErrorFound((prev) => !prev);
            validationCheck = false;
        } else {
            setFirstError(null);
        }
        if (taskInstance?.isPhotoRequired && (images?.every((x) => x.isErrored) || !images?.length) && !foundError) {
            handleToast({
                title: t(TranslationModals.TOAST_WARNING_PHOTO_REQUIRED),
                variant: "danger",
                type: "alert",
            });
            setImageCustomError(t(TranslationCommon.INPUT_NOT_EMPTY));
            validationCheck = false;
        } else setImageCustomError("");

        if (fieldsOutOfRange && validationCheck && taskInstance?.id) {
            if (taskInstance?.generateAutomaticAssetIssue && !!outOfRangeAssets.length) {
                openIssueAssetModal({
                    taskinstanceId: taskInstance?.id,
                    assetsOutOfRange: outOfRangeAssets,
                    hasPlan: !!taskInstance.planId,
                });
            } else setTemperatureOutOfRangeModalIsOpen(true);
            return false;
        }

        if (!!taskCheckList?.length) {
            const hasEmptyChecklistItems = taskCheckList.some(({ isActive }) => isActive === null);
            if (hasEmptyChecklistItems && validationCheck) {
                setcheckListModalOpen(true);
                return false;
            }
        }

        if (validationCheck) await completeTaskInstance(taskInstance, fields);
        return validationCheck;
    };

    return {
        dynamicFieldsErrors,
        handleTaskComplete,
        imageCustomError,
        setImageCustomError,
        customErrors,
        firstError,
        errorFound,
        loading,
        checkListModalOpen,
        setcheckListModalOpen,
        completeTaskInstance,
        temperatureOutOfRangeModalIsOpen,
        setTemperatureOutOfRangeModalIsOpen,
    };
};
