import { ON_BOARDING_SUB_STEPS, PublicPaths } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { WorkingPositionGrid } from "./WorkingPositionGrid/WorkingPositionGrid";
import { WorkingPositionForm } from "./WorkingPositionForm/WorkingPositionForm";
import { PrintQr } from "./PrintQr/PrintQr";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";
import { QrGridProvider } from "./state/providers/QrGridProvider";

export const WorkingPositionPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_QR);
    return (
        <QrGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<WorkingPositionGrid />}></Route>
                <Route path="new" element={<WorkingPositionForm />}></Route>
                <Route path="edit/:workingPositionId" element={<WorkingPositionForm />}></Route>
                <Route path="print" element={<PrintQr />}></Route>
            </Routes>
        </QrGridProvider>
    );
};
