import { useWindowSize } from "usehooks-ts";

export const useRankingPill = () => {
    const { width } = useWindowSize();

    const calculatePercentageDependingDeviceWidth = (percentage: number): number | undefined => {
        return width < 1500 ? undefined : percentage;
    };

    const getNumbersOfStarsDependingDeviceWidth = () => (width < 450 ? 1 : 5);

    return {
        calculatePercentageDependingDeviceWidth,
        getNumbersOfStarsDependingDeviceWidth,
    };
};
