import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { InitialAppLoad } from "app/dtos/01-SEG/InitialAppLoad/InitialAppLoadModel";

export default class InitialLoadService {
    public static async InitialLoad(token: string, signalRId?: string) {
        return FetchService.get<InitialAppLoad>({
            url: `${ApiPaths.SEG_API}/InitialAppLoad/${signalRId}`,
            token: token,
        });
    }
}
