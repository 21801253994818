import { useState } from "react";
import { useSession } from "app/hooks/useSession";
import { fillTaskInstanceExtraParams } from "app/helpers/ExtraParams/taskInstanceFillExtraParams";
import { ComponentStatus, OptionsSearch } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type GetCompaniesProps = {
    deadlineDate?: Date;
    deadlineDate1?: Date;
    finishedDate?: Date;
    finishedDate1?: Date;
    companyId?: number;
};

export const useGetCompanies = ({ deadlineDate1, deadlineDate, companyId }: GetCompaniesProps) => {
    const session = useSession();

    const [data, setData] = useState<OptionsSearch[]>([]);
    const [status, setStatus] = useState<ComponentStatus>("complete");
    const [httpError, setHttpError] = useState<number>();

    const getCompanies = async () => {
        setStatus("loading");

        const serviceSr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                deadlineDate,
                deadlineDate1,
                myTasksUserId: session?.user.id,
                companyId,
            }),
        });

        if (!serviceSr.status()) {
            console.error(serviceSr.error);
            setHttpError(serviceSr.httpCodeStatus);
            setStatus("errored");
            return;
        }

        setData(serviceSr.data);
        setStatus("complete");
    };

    return { data, status, httpError, getCompanies };
};
