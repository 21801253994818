import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";

export const SecondaryFilterSection: FC<SectionsShowProp> = ({ show }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showResetFilter, setShowResetFilter] = useState(true);
    return (
        <Section title="SecondaryFilter" show={show}>
            <button onClick={() => setIsOpen(!isOpen)}>Abrir / cerrar</button>
            <SecondaryFilter
                isOpen={isOpen}
                onClickOutside={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                onSave={() => setIsOpen(false)}
                onReset={() => setShowResetFilter(false)}
                showResetFilter={showResetFilter}
            >
                <form action="">
                    <input type="text" />
                </form>
            </SecondaryFilter>
        </Section>
    );
};
