import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { Switch } from "app/components_v2/__inputs/Switch/Switch";
import { SwitchDragFile } from "app/components_v2/SwitchDragFile/SwitchDragFile";
import {
    EditDataModel,
    ExtraDataModel,
    CustomErrorExtraDataModel,
    EditTaskDisabledExtraDataModel,
} from "app/models/02-TAR/Task/EditTask";
import { useExtraData } from "../DataToReport/hooks/useExtraData";

export const ExtraData: FC<
    EditDataModel<ExtraDataModel, CustomErrorExtraDataModel, EditTaskDisabledExtraDataModel>
> = ({ criticalTask, isDisabled, isPhotoRequired, onChange, somethingToReportImage, disabledFields, imageBase64 }) => {
    const { t } = useTranslation();

    const { handleChangePhoto, onChangeImg, onDeleteImg } = useExtraData({ onChange, disabledFields });

    return (
        <div className="extraData">
            <div className="dataToReport__photoRegister">
                <div className="dataToReport__switchContainer">
                    <Switch
                        checked={criticalTask}
                        onChange={(value) => onChange({ criticalTask: value })}
                        disabled={isDisabled || disabledFields?.disabledIsCritical}
                        label={t(TranslationKeys.CRITICAL_TASK)}
                    />
                </div>
            </div>
            <SwitchDragFile
                image={imageBase64 || somethingToReportImage}
                isChecked={isPhotoRequired}
                onDeleteImage={onDeleteImg}
                handleChangePhoto={handleChangePhoto}
                onChangePhoto={onChangeImg}
                disabledCheckbox={isDisabled || disabledFields?.disabledIsPhotoRequired}
                disabledImage={isDisabled || disabledFields?.disabledFotoExample}
                isImageEditable
            />
        </div>
    );
};
