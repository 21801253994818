import { FC, ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";

type CollapsableSidebarProps = {
    title: string;
    children: ReactNode;
    isOpen?: boolean;
};

export const CollapsableSidebar: FC<CollapsableSidebarProps> = ({
    children,
    title,
    isOpen = true,
}) => {
    const [_isOpen, setIsOpen] = useState<boolean>(isOpen);

    return (
        <div className={`collapsableSidebar${!_isOpen ? "--closed" : ""}`}>
            <div className="collapsableSidebar__header" onClick={() => setIsOpen((prev) => !prev)}>
                <p className="collapsableSidebar__title">{title}</p>
                <FontAwesomeIcon
                    className={`collapsableSidebar__icon${!_isOpen ? "--closed" : ""}`}
                    icon={faChevronUp}
                />
            </div>
            <div className={`collapsableSidebar__collapsable${!_isOpen ? "--closed" : ""}`}>
                <div className="collapsableSidebar__body">{children}</div>
            </div>
        </div>
    );
};
