import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { Input } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";

type AddDepartmentModalProps = {
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (value: string) => void;
};

export const AddDepartmentModal: FC<AddDepartmentModalProps> = ({ onClose, onSubmit, isLoading }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("");
    const [errors, setErrors] = useState<string>("");

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        !value.length ? setErrors(t(TranslationErrors.ASSET_NAME_REQUIRED)) : onSubmit(value);
    };

    return (
        <GenericModal
            header={{ title: t(TranslationKeys.NEW_DEPARTMENT), onClose }}
            size="sm"
            portal
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.ACCEPT),
                        form: "addDepartmentModal",
                        type: "submit",
                        fullWidth: true,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        onClick: onClose,
                        buttonType: "tertiary",
                        fullWidth: true,
                    }}
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={validate} className="addDepartmentModal" id="addDepartmentModal">
                <Input
                    onChange={setValue}
                    value={value}
                    label={t(TranslationKeys.DEPARTMENT_NAME)}
                    placeholder={t(TranslationKeys.PLACEHOLDER_DEPARTMENT_NAME)}
                    errorMessage={errors}
                    focus
                />
            </form>
        </GenericModal>
    );
};
