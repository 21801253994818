import { FC } from "react";
import { Tag } from "app/components_v2/Tag/Tag";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useTranslation } from "react-i18next";
import { IssueTranslation } from "app/translation/translationKeys";

type IssueBodyResponsibleProps = {
    receivers: OptionModel[];
};

export const IssueBodyResponsible: FC<IssueBodyResponsibleProps> = ({ receivers }) => {
    const { t } = useTranslation();
    return (
        <div className="issueBodyResponsible">
            <IssueBodyTitle>{t(IssueTranslation.ISSUE_BODY_RESPONSIBLE_TITLE)}</IssueBodyTitle>
            <div className="issueBodyResponsible__container">
                {receivers.map(({ label, value, initials, profilePictureURL }) => (
                    <Tag
                        key={value}
                        message={label}
                        avatar={{ id: value, initials: initials || "", name: label, profilePictureURL }}
                        variant="white"
                    />
                ))}
            </div>
        </div>
    );
};
