import { RegexPatterns } from "app/shared/Constants";
import { TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type checkPasswordReturn = {
    isErrored: boolean;
    errorMessage?: string;
};

export const useValidateResetPassword = () => {
    const { t } = useTranslation();

    const checkPasswordIsValid = (password: string): checkPasswordReturn => {
        const passRules = new RegExp(RegexPatterns.password);
        if (passRules.test(password) || password.includes("*") || password.includes("'")) {
            return {
                isErrored: false,
            };
        }

        return {
            isErrored: true,
            errorMessage: t(TranslationErrors.PASS_ADMIN_INVALID),
        };
    };

    const checkPassword = (password: string): checkPasswordReturn => {
        const { isErrored: isPasswordErrored, errorMessage: passwordErrorMessage } = checkPasswordIsValid(password);

        if (isPasswordErrored) {
            return { isErrored: true, errorMessage: passwordErrorMessage };
        }

        return {
            isErrored: false,
        };
    };

    return {
        checkPasswordIsValid,
        checkPassword,
    };
};
