import PrivateRoute from "app/routes/PrivateRoute";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { IssueGrid } from "./IssueGrid/IssueGrid";
import { IssueGridProvider } from "./state/Providers/IssueGridProvider";
import { EditIssue } from "./EditIssue/EditIssue";

export const IssuePage = () => {
    return (
        <IssueGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<PrivateRoute component={IssueGrid} viewCode={SecScreen.ISSUE} />}></Route>
                <Route
                    path="edit/:id"
                    element={<PrivateRoute component={EditIssue} viewCode={SecScreen.ISSUE} />}
                ></Route>
            </Routes>
        </IssueGridProvider>
    );
};
