import { UpdateOnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingServiceModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { OnBoardingSubstepInstancesModel } from "app/models/01-SEG/Onboarding/OnBoardingSubstepInstancesModel";

export default class OnBoardingSubstepInstanceService {
    public static async GetByName(name: string) {
        return FetchService.get<OnBoardingSubstepInstancesModel>({
            url: `${ApiPaths.SEG_API}/OnBoardingSubstepInstance/${name}`,
        });
    }

    public static async CompleteSubStep(body: UpdateOnBoardingStatus) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/OnBoardingSubstepInstance/${body.id}`,
            body,
        });
    }
}
