import { FC } from "react";
import {
    SelectWithActionsMenuContext,
    SelectWithActionsMenuContextModel,
} from "../context/SelectWithActionsMenuContext";
import { ProviderProps } from "app/state/Providers/types";

type SelectWithActionsProviderProps = ProviderProps & SelectWithActionsMenuContextModel;

export const SelectWithActionsProvider: FC<SelectWithActionsProviderProps> = ({
    children,
    ...props
}) => {
    return (
        <SelectWithActionsMenuContext.Provider value={props}>
            {children}
        </SelectWithActionsMenuContext.Provider>
    );
};
