import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { v4 } from "uuid";

export const fillAsset = ({
    assetFields,
    assetId,
    description,
    fK_AssetType,
    fK_Company,
    fK_PlanAPPCC,
    name,
    isRelatedWithTasks,
    notWorking,
    sensorSerialNumber,
    battery,
    signalLevel,
}: AssetModel2): AssetModelFormValues => {
    return {
        name,
        fK_PlanAPPCC,
        fK_Company,
        fK_AssetType,
        description,
        assetId,
        assetFields: assetFields.map(({ assetDynamicField, label, assetFieldRange, id, isHidden }) => ({
            assetDynamicField,
            id: v4(),
            dbId: id,
            isDeleted: false,
            label,
            assetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRange : null,
            isHidden,
        })),
        isRelatedWithTasks,
        notWorking,
        allowSensorSerialNumber: !!sensorSerialNumber.length,
        sensorSerialNumber: sensorSerialNumber,
        battery,
        signalLevel,
    };
};
