import { FC } from "react";
import { ChatBotMessageModel } from "../types";
import { ChatBotMessageText } from "../ChatBotMessageText/ChatBotMessageText";
import { ChatBotReplyButtons } from "../ChatBotReplyButtons/ChatBotReplyButtons";

type ChatBotMessageProps = {
    message: ChatBotMessageModel;
    isPreviousMessageSameOwner: boolean;
    onClickIcon?: (actionKey: string) => void;
};

export const ChatBotMessage: FC<ChatBotMessageProps> = ({ message, isPreviousMessageSameOwner, onClickIcon }) => {
    const { type } = message;

    if (type === "button" && !!message.buttons?.length)
        return (
            <ChatBotReplyButtons
                message={{ ...message, buttons: message.buttons }}
                onClick={(actionKey) => onClickIcon && onClickIcon(actionKey)}
            />
        );

    return <ChatBotMessageText message={message} isPreviousMessageSameOwner={isPreviousMessageSameOwner} />;
};
