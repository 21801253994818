import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import { FullScreenHeaderOptions } from "./types";
import { FullScreenModalHeader } from "./FullScreenModalHeader/FullScreenModalHeader";

type FullScreenModalProps = {
    children: ReactNode;
    headerOptions: FullScreenHeaderOptions;
    portal?: boolean;
};

export const FullScreenModal: FC<FullScreenModalProps> = ({ children, headerOptions, portal = true }) => {
    const ModalContent = (
        <div className="fullScreenModal">
            <FullScreenModalHeader {...headerOptions} />
            {children}
        </div>
    );
    return <>{portal ? createPortal(ModalContent, document.body) : ModalContent}</>;
};
