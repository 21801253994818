import { FC, useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import FourLinkLogo from "assets/common/logo.svg";
import { useTranslation } from "react-i18next";
import { AriaLabels } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type GenerateQrProps = {
    url: string;
    companyName: string;
    qrName: string;
    companyPhoto?: string;
    qrCode: string;
    onAllImagesLoaded?: () => void;
};

type ImageLoaded = {
    companyPhotoIsLoaded: boolean;
    logoIsLoaded: boolean;
};

export const GenerateQr: FC<GenerateQrProps> = ({
    companyName,
    qrName,
    companyPhoto,
    url,
    qrCode,
    onAllImagesLoaded,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const qrRef = useRef<HTMLCanvasElement | null>(null);

    const [imageLoaded, setImageLoaded] = useState<ImageLoaded>({
        logoIsLoaded: false,
        companyPhotoIsLoaded: !companyPhoto ? true : false,
    });

    const handleLoadChange = (values: Partial<ImageLoaded>) => {
        setImageLoaded((prev) => ({ ...prev, ...values }));
    };

    const handleLoadCompany = () => {
        handleLoadChange({ companyPhotoIsLoaded: true });
    };

    const handleLoadLogo = () => {
        handleLoadChange({ logoIsLoaded: true });
    };

    useEffect(() => {
        if (imageLoaded.companyPhotoIsLoaded && imageLoaded.logoIsLoaded) onAllImagesLoaded && onAllImagesLoaded();
    }, [imageLoaded]);

    useEffect(() => {
        QRCode.toCanvas(qrRef.current, url, { color: { dark: "#2f368c", light: "#0000" }, margin: 0 });
    }, [url]);

    return (
        <div className="generateQr">
            <div className="generateQr__header">
                <div className="generateQr__qrCode">
                    <canvas ref={qrRef} className="generateQr__qrCode__image" />
                    <p className="generateQr__qrCode__label">{qrCode}</p>
                </div>
                <div className="generateQr__info">
                    <h1 className="generateQr__info__companyName">{companyName}</h1>
                    <h2 className="generateQr__info__qrName">{qrName}</h2>
                </div>
            </div>
            <div className="generateQr__footer">
                {companyPhoto && (
                    <img
                        aria-label={translateCustomerTypeKeys(AriaLabels.AL_QR_COMPANY_PHOTO, {
                            firstReplace: "SINGULAR",
                            isGenderSensitive: true,
                        })}
                        className="generateQr__companyPhoto"
                        src={companyPhoto}
                        onLoad={handleLoadCompany}
                    />
                )}
                <img
                    aria-label={t(AriaLabels.AL_QR_FORULINK_PHOTO)}
                    className="generateQr__logo"
                    src={FourLinkLogo}
                    onLoad={handleLoadLogo}
                />
            </div>
        </div>
    );
};
