import { SensorModalTitle } from "../../SensorModalTitle/SensorModalTitle";
import { SensorModalTranslations, TranslationCommon } from "app/translation/translationKeys";
import { SensorModalText } from "../../SensorModalText/SensorModalText";
import { useTranslation } from "react-i18next";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { FC } from "react";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type SensorLinkedModalBodyProps = {
    asset: AssetModel2;
};

export const SensorLinkedModalBody: FC<SensorLinkedModalBodyProps> = ({ asset }) => {
    const { t } = useTranslation();
    const { translateCompany } = useCustomerTypeTranslation();

    const { name, description, assetType, company, planAPPCC, notWorking } = asset;

    return (
        <div className="sensorLinkedModalBody">
            <div className="sensorLinkedModalBody__description">
                <SensorModalTitle>{t(SensorModalTranslations.SENSOR_LINKED_ASSET_NAME)}</SensorModalTitle>
                <SensorModalText>{name}</SensorModalText>
            </div>
            {!!description.length && (
                <div className="sensorLinkedModalBody__description">
                    <SensorModalTitle>{t(SensorModalTranslations.SENSOR_LINKED_ASSET_DESCRIPTION)}</SensorModalTitle>
                    <SensorModalText>{description}</SensorModalText>
                </div>
            )}
            {!!company && (
                <div className="sensorLinkedModalBody__description">
                    <SensorModalTitle>{translateCompany("SINGULAR")}</SensorModalTitle>
                    <SensorModalText>{company.name}</SensorModalText>
                </div>
            )}
            {!!assetType && (
                <div className="sensorLinkedModalBody__description">
                    <SensorModalTitle>{t(SensorModalTranslations.SENSOR_LINKED_ASSET_TYPE)}</SensorModalTitle>
                    <SensorModalText>{assetType.name}</SensorModalText>
                </div>
            )}
            {!!planAPPCC && (
                <div className="sensorLinkedModalBody__description">
                    <SensorModalTitle>{t(SensorModalTranslations.SENSOR_LINKED_ASSET_PLAN)}</SensorModalTitle>
                    <SensorModalText>{planAPPCC.name}</SensorModalText>
                </div>
            )}
            <div className="sensorLinkedModalBody__description">
                <SensorModalTitle>{t(SensorModalTranslations.SENSOR_LINKED_ASSET_NOT_WORKING)}</SensorModalTitle>
                <SensorModalText>{notWorking ? t(TranslationCommon.YES) : t(TranslationCommon.NO)}</SensorModalText>
            </div>
        </div>
    );
};
