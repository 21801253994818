import { useState } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { useSession } from "./useSession";

export const useDepartments = () => {
    const session = useSession();
    const [departments, setDepartments] = useState<OptionModel[]>([]);

    const getDepartment = async (companyId: number) => {
        const departmentsSr = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&companyId=${companyId}`,
        });

        if (!departmentsSr.status()) {
            setDepartments([]);
            return;
        }
        setDepartments(departmentsSr.data);
    };
    return { departments, getDepartment };
};
