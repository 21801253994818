import { FC } from "react";
import Select, { CSSObjectWithLabel } from "react-select";

type SelectCustomChildrenProps = {
    inverted?: boolean;
    MenuList: any;
    placeholder?: string;
};

const SelectCustomChildren: FC<SelectCustomChildrenProps> = ({
    inverted,
    MenuList,
    placeholder,
}) => {
    const customStyles = {
        placeholder: (styles: CSSObjectWithLabel) => ({
            ...styles,
            color: inverted ? "white" : "gray",
            fontSize: "16px",
        }),
    };

    return (
        <Select
            styles={customStyles}
            className={`selectCustomChildren${inverted ? "--inverted" : ""}`}
            components={{ MenuList }}
            placeholder={placeholder}
            isSearchable={false}
            menuPosition="fixed"
            classNames={{
                control: () =>
                    `reactSelectClassName ${inverted ? "reactSelectClassName--inverted" : ""} `,
                menuPortal: () => "zIndexUp",
                indicatorsContainer: () => (inverted ? "reactSelectIndicator--inverted" : ""),
                singleValue: () => (inverted ? "reactSelectSingleValue--inverted" : ""),
            }}
        />
    );
};

export default SelectCustomChildren;
