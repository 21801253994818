import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuditInstanceDetailDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceDetailDto";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { PrivatePaths } from "app/shared/Constants";
import { TranslationKeys } from "app/translation/translationKeys";
import { useToast } from "app/hooks/Toast/useToast";

export const useEditAuditHeader = (auditInstanceId: number) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { handleToast } = useToast();

    const [isEditAuditHeaderLoading, setIsEditAuditHeaderLoading] = useState<boolean>(true);
    const [editAuditHeaderData, setEditAuditHeaderData] = useState<AuditInstanceDetailDto>();

    const fetchEditAuditHeaderData = async () => {
        setIsEditAuditHeaderLoading(true);
        const { data, status } = await AuditInstanceService.GetAuditInstanceDetailData(auditInstanceId);

        if (!status()) {
            handleToast({ title: t(TranslationKeys.AUDIT_NOT_EXIST), variant: "danger", type: "alert" });
            setIsEditAuditHeaderLoading(false);
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
            return;
        }
        setEditAuditHeaderData(data);
        setIsEditAuditHeaderLoading(false);
    };

    return { isEditAuditHeaderLoading, editAuditHeaderData, fetchEditAuditHeaderData };
};
