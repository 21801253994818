import { useState } from "react";
import { ReceivedNotificationsExtraParams } from "app/helpers";
import AlertNotificationService from "app/services/02-TAR/AlertNotificationService";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useSession } from "../useSession";
import { SecScreen } from "app/shared/Constants";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";

type Props = {
    id?: number;
    notSeen?: boolean;
};

export const useReceivedNotifications = ({ id, notSeen }: Props) => {
    const session = useSession();
    const [receivedNotificationList, setReceivedNotificationList] = useState<AlertNotificationDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchNotifications = async () => {
        if (!hasPermissionToView(session, SecScreen.ALERTS)) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        const { data, error, status } = await AlertNotificationService.GetData({
            extraParams: ReceivedNotificationsExtraParams({
                notSeen,
                receiverUserId: id,
            }),
        });

        if (!status()) {
            console.error(error);
            setIsLoading(false);
            return;
        }
        setReceivedNotificationList(data.list);
        setIsLoading(false);
    };

    return {
        receivedNotificationList,
        setReceivedNotificationList,
        isLoading,
        fetchNotifications,
    };
};
