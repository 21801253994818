import React, { useState, useEffect } from "react";
import { SupervisorAnalistColors } from "../types";

type CircularProgressBarProps = {
    percentage: number;
    size?: number;
    strokeWidth?: number;
    progressColor: SupervisorAnalistColors;
    bgColor?: string;
    legendText?: string;
    legendNumber?: number;
};

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
    percentage,
    strokeWidth = 16,
    progressColor,
    bgColor = "#E5E5E5",
    size = 100,
    legendText,
    legendNumber,
}) => {
    const [animate, setAnimate] = useState(false);
    const [currentPercentage, setCurrentPercentage] = useState(0);
    const radius = size / 2;
    const halfCircle = radius - strokeWidth / 2;
    const circumference = radius * Math.PI * 2;
    const offset =
        percentage > 0 && percentage < 8 ? circumference * (1 - 8 / 100) : circumference * (1 - percentage / 100);

    useEffect(() => {
        setAnimate(true);
    }, []);

    useEffect(() => {
        if (animate) {
            const steps = 100;
            const stepDuration = 10;
            const stepIncrement = percentage / steps;
            let stepCount = 0;

            const timer = setInterval(() => {
                stepCount++;
                setCurrentPercentage(stepIncrement * stepCount);
                if (stepCount === steps) {
                    clearInterval(timer);
                }
            }, stepDuration);

            return () => clearInterval(timer);
        }
    }, [animate, percentage]);

    return (
        <div className="container_all__circularProgressBar">
            <div
                style={{
                    width: size,
                    height: size,
                }}
            >
                <svg height={size} width={size}>
                    <circle
                        stroke={bgColor}
                        fill="transparent"
                        strokeWidth={strokeWidth}
                        r={halfCircle}
                        cx={size / 2}
                        cy={size / 2}
                    />
                    <circle
                        fill="transparent"
                        strokeWidth={strokeWidth}
                        strokeLinecap="round"
                        strokeDasharray={circumference + " " + circumference}
                        style={{
                            strokeDashoffset: animate ? offset + strokeWidth : circumference,
                            transition: "stroke-dashoffset 1s ease-in-out",
                        }}
                        r={halfCircle}
                        cx={size / 2}
                        cy={size / 2}
                        transform={`rotate(-90 ${size / 2} ${size / 2})`}
                        className={`circularProgressBar--${progressColor}`}
                    />
                    <text
                        x="50%"
                        y="50%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        className="circularProgressBar__text"
                    >
                        {`${Math.round(currentPercentage)}%`}
                    </text>
                </svg>
            </div>
            {legendText && <p className="circularProgressBar__legend">{legendText}</p>}
            <p className="circularProgressBar__legend__number">({legendNumber})</p>
        </div>
    );
};

export default CircularProgressBar;
