import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { HeaderItemProps } from "../headerTypes";
import { LinkDiv } from "../LinkDiv/LinkDiv";

export const HeaderItem: FC<HeaderItemProps> = ({
    to,
    isActive = false,
    text,
    onClick,
    notification,
    children,
    iconLeft,
    iconRight,
    noDisplay,
    variant = "primary",
    grayMode,
}) => {
    return (
        <div className={`headerItem${noDisplay ? noDisplay : ""}`}>
            <LinkDiv
                to={to}
                className={`headerRightIcon${!text ? "--solo" : ""}${
                    isActive ? `--active--${grayMode ? "grayMode" : variant}` : ""
                } ${text ? `headerText${isActive ? "--active" : ""}` : ""}`}
                onClick={onClick}
            >
                {iconLeft && (
                    <>
                        {notification && <div className="markableIcon__notification" />}
                        <FontAwesomeIcon
                            className={`headerRightIcon__icon${!text ? "--solo" : ""}${isActive ? "--active" : ""} `}
                            icon={iconLeft}
                        />
                    </>
                )}
                {text}
                {iconRight && (
                    <>
                        {notification && <div className="markableIcon__notification" />}
                        <FontAwesomeIcon
                            className={`headerRightIcon__icon${!text ? "--solo" : ""}${isActive ? "--active" : ""}`}
                            icon={iconRight}
                        />
                    </>
                )}
            </LinkDiv>
            {children && children}
        </div>
    );
};
