import { FC, useState } from "react";
import { SliderModal } from "../__modals/base/SliderModal/SliderModal";
import { ImageModal } from "../__modals/base/ImageModal/ImageModal";

type AttachmentProps = {
    fileName: string;
};

export const Attachment: FC<AttachmentProps> = ({ fileName }) => {
    const [photo, setPhoto] = useState<string | null>(null);
    return (
        <>
            <div className="attachment">
                <img loading="lazy" className="attachment__image" onClick={() => setPhoto(fileName)} src={fileName} />
            </div>
            {photo && (
                <SliderModal onClose={() => setPhoto(null)}>
                    <ImageModal img={photo} />
                </SliderModal>
            )}
        </>
    );
};
