import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { FullScreenQrModal } from "app/components_v2/__modals/FullScreenQrModal/FullScreenQrModal";

export const FullScreenModalSection: FC<SectionsShowProp> = ({ show }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <Section show={show} title="FullScreenQrModal">
            <button onClick={() => setIsModalOpen(true)}>abrir</button>
            {isModalOpen && <FullScreenQrModal onClose={() => setIsModalOpen(false)} onScanQr={alert} />}
        </Section>
    );
};
