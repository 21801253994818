import { FC } from "react";
import { RegexValidationItemModel } from "./types";
import { RegexValidatorItem } from "./RegexValidatorItem/RegexValidatorItem";

type RegexValidatorProps = {
    items: RegexValidationItemModel[];
};

export const RegexValidator: FC<RegexValidatorProps> = ({ items }) => {
    return (
        <div className="regexValidator">
            {items.map((item, key) => (
                <RegexValidatorItem item={item} key={key} />
            ))}
        </div>
    );
};
