import { FC } from "react";

type AvatarSkelletonProps = {
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    showAvatarName?: boolean;
};

export const AvatarSkelleton: FC<AvatarSkelletonProps> = ({
    size = "xs",
    showAvatarName = true,
}) => {
    return (
        <div className="avatar-skelleton">
            <div className={`avatar-is-loading--${size}`}></div>
            {showAvatarName && <p className={`avatarName-is-loading--${size}`}></p>}
        </div>
    );
};
