import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { BreadCrumbs, BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { PrivatePaths } from "app/shared/Constants";
import { TranslationTitles } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export const BreadCrumbsSection: FC<SectionsShowProp> = ({ show }) => {
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const urlSplited = "analyst-ranking/departments/1/users".split("/");
    const departmentId = urlSplited[urlSplited.length - 2];
    const rankingUrl = `/${PrivatePaths.COMPANY_RANKING}`;

    const crumbs: BreadcrumbModel[] = [
        {
            name: translateCustomerTypeKeys(TranslationTitles.RANKING_COMPANY_TITLE, { firstReplace: "PLURAL" }),
            to: `/${PrivatePaths.COMPANY_RANKING}`,
        },
        {
            name: "Detalle",
            to: `${rankingUrl}detail/14`,
        },
        {
            name: "Departamnetos Casa Masala",
            to: `${rankingUrl}department/${departmentId}`,
        },
        {
            name: "Detalle",
            to: `${rankingUrl}department/detail/${departmentId}`,
        },
        {
            name: "Ranking Cocina",
            to: `${rankingUrl}department/detail/${departmentId}/users`,
        },
    ];

    const crumb: BreadcrumbModel[] = [
        {
            name: translateCustomerTypeKeys(TranslationTitles.RANKING_COMPANY_TITLE, { firstReplace: "PLURAL" }),
            to: `/${PrivatePaths.COMPANY_RANKING}`,
        },
    ];

    return (
        <Section title="Breadcrumbs" show={show}>
            <div className="breadcrumbSection">
                <BreadCrumbs crumbs={crumbs} />
            </div>

            <div className="breadcrumbSection">
                <BreadCrumbs crumbs={crumb} />
            </div>
        </Section>
    );
};
