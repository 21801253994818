export type FilterData = {
    name?: string;
    deadlineDate?: Date;
    deadlineDate1?: Date;
    finishedDate?: Date;
    finishedDate1?: Date;
    company?: string;
    user?: string;
    department?: string;
    subdepartment?: string;
    fields?: string;
    plan?: string;
    status?: string;
    wellDoneCheck?: boolean;
    worseDoneCheck?: boolean;
    hasData?: boolean;
    hasPhoto?: boolean;
    completed?: boolean;
    critical?: boolean;
    noCritical?: boolean;
    myTasksUserId?: number;
    notEvaluatedCheck?: boolean;
    oneStar?: boolean;
    twoStar?: boolean;
    threeStar?: boolean;
    fourStar?: boolean;
    fiveStar?: boolean;
    isAssignedToQR?: boolean;
};

const initialValues = {
    name: undefined,
    deadlineDate: undefined,
    deadlineDate1: undefined,
    finishedDate: undefined,
    finishedDate1: undefined,
    user: "-1",
    department: "-1",
    subdepartment: "-1",
    fields: undefined,
    plan: undefined,
    status: undefined,
    wellDoneCheck: false,
    worseDoneCheck: false,
    notEvaluatedCheck: false,
    oneStar: false,
    twoStar: false,
    threeStar: false,
    fourStar: false,
    fiveStar: false,
    hasData: false,
    hasPhoto: false,
    completed: false,
    critical: false,
    noCritical: false,
};

export const initialFilterState: FilterData = {
    ...initialValues,
    company: undefined,
    myTasksUserId: undefined,
};

export type FilterDataProps = {
    filterData: FilterData;
    setFilterData: React.Dispatch<React.SetStateAction<FilterData>>;
};
