import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { RankingPill } from "app/components_v2/RankingPill/RankingPill";
import { RankingPillProps } from "app/components_v2/RankingPill/types";
import AvatarImage from "assets/common/Avatar.png";
import { RankingPillSkelleton } from "app/components_v2/__skelletons/RankingPillSkelleton/RankingPillSkelleton";
import { useRankingPillFullWidth } from "app/hooks/RankingPill/useRankingPillFullWidth";

const workerPillData: RankingPillProps = {
    name: "Ramón Salvans",
    colorId: 1,
    counters: [
        {
            type: "success",
            counter: 33,
        },
    ],
    starsValoration: 3,
    totalTasks: 10,
    trend: 999,
    userType: "ANALIST",
};
const workerPillData2: RankingPillProps = {
    name: "Ramón Salvans",
    colorId: 1,
    counters: [
        {
            type: "yellow",
            counter: -33,
        },
    ],
    starsValoration: 3,
    totalTasks: 10,
    trend: -999,
    borderType: "line",
    userType: "SUPERVISOR",
};
const workerPillData3: RankingPillProps = {
    name: "Ramón Salvans",
    colorId: 1,
    counters: [
        {
            type: "danger",
            counter: -33,
        },
    ],
    starsValoration: 3,
    totalTasks: 10,
    trend: 0,
    borderType: "line",
    userType: "WORKER",
};
const workerPillData4: RankingPillProps = {
    name: "Ramón Salvans",
    colorId: 1,
    counters: [
        {
            type: "success",
            counter: 9,
        },
        {
            type: "danger",
            counter: 55,
        },
    ],
    starsValoration: 3,
    totalTasks: 10,
    trend: 30,
    userType: "ANALIST",
};
const workerPillData5: RankingPillProps = {
    name: "Ramón Salvans",
    colorId: 1,
    counters: [
        {
            type: "yellow",
            counter: 999,
        },
        {
            type: "danger",
            counter: -999,
        },
    ],
    starsValoration: 3,
    totalTasks: 10,
    trend: 30,
    userType: "SUPERVISOR",
};

const companyPillData: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 10000000000,
    trend: 999,
    counters: [
        {
            type: "yellow",
            counter: 16,
        },
        {
            type: "danger",
            counter: 2,
        },
    ],
    colorId: 3, // company id
    type: "default",
    userType: "WORKER",
};
const companyPillData2: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 10000000000,
    trend: -999,
    counters: [
        {
            type: "success",
            counter: 16,
        },
        {
            type: "yellow",
            counter: 2,
        },
    ],
    colorId: 3, // company id
    type: "default",
    userType: "ANALIST",
};
const companyPillData3: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 10000000000,
    trend: 59,
    counters: [
        {
            type: "yellow",
            counter: 999,
        },
        {
            type: "danger",
            counter: -88,
        },
    ],
    colorId: 3, // company id
    type: "default",
    userType: "SUPERVISOR",
};
const companyPillData4: RankingPillProps = {
    name: "123",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 8999,
    trend: 202,
    counters: [
        {
            type: "success",
            counter: 2,
            percentage: 50,
        },
    ],
    colorId: 3, // company id
    type: "percentage",
    userType: "WORKER",
};
const companyPillData5: RankingPillProps = {
    name: "123",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 8999,
    trend: 22,
    counters: [
        {
            type: "yellow",
            counter: 2,
            percentage: 50,
        },
    ],
    colorId: 3, // company id
    type: "percentage",
    userType: "ANALIST",
};
const companyPillData6: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 330,
    trend: -56,
    totalCounter: 27,
    counters: [
        {
            type: "yellow",
            counter: 2,
            percentage: 50.5,
        },
        {
            type: "danger",
            counter: 25,
            percentage: 49.5,
        },
    ],
    colorId: 3, // company id
    type: "percentage",
    userType: "SUPERVISOR",
};
const companyPillData7: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 330,
    trend: -56,
    totalCounter: 8889,
    counters: [
        {
            type: "yellow",
            counter: 1,
            percentage: 0.49,
        },
        {
            type: "danger",
            counter: 8888,
            percentage: 99.51,
        },
    ],
    colorId: 3, // company id
    type: "percentage",
    userType: "SUPERVISOR",
};

const companyPillData8: RankingPillProps = {
    name: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
    profileURL: AvatarImage,
    starsValoration: 4.5,
    totalTasks: 10000000000,
    trend: -999,
    colorId: 3, // company id
    type: "default",
    userType: "WORKER",
};

export const RankingPillSection: FC<SectionsShowProp> = ({ show }) => {
    const { numberOfStars } = useRankingPillFullWidth();
    function generateTotalTasks() {
        return Math.floor(Math.random() * 350) + 1;
    }

    function generateTrend() {
        return Math.floor(Math.random() * 201) - 100;
    }

    return (
        <Section title="Ranking pill" show={show}>
            <p>Worker pill</p>
            <RankingPill {...workerPillData} numberOfStars={numberOfStars} />
            <RankingPill {...workerPillData2} numberOfStars={numberOfStars} />
            <RankingPill {...workerPillData3} numberOfStars={numberOfStars} />
            <RankingPill {...workerPillData4} numberOfStars={numberOfStars} />
            <RankingPill {...workerPillData5} numberOfStars={numberOfStars} />
            <p>Company pill</p>
            <RankingPill {...companyPillData} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData2} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData3} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData4} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData5} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData6} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData7} numberOfStars={numberOfStars} />
            <RankingPill {...companyPillData8} numberOfStars={numberOfStars} />
            <p>Company pill with different trend and stars</p>
            <RankingPill
                {...companyPillData6}
                totalTasks={generateTotalTasks()}
                starsValoration={1}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={1}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={1}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.3}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.5}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.5}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.5}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.8}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.8}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.8}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.9}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.9}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={4.9}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={5}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <RankingPill
                {...companyPillData6}
                starsValoration={5.1}
                totalTasks={generateTotalTasks()}
                trend={generateTrend()}
                numberOfStars={numberOfStars}
            />
            <p></p>
            <RankingPillSkelleton />
            <RankingPillSkelleton variant="percentage" />
        </Section>
    );
};
