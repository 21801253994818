import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { Label, TimePicker } from "app/components_v2/__inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { typeHour } from "app/models/02-TAR/TaskWizard";

type MultiTaskHourProps = {
    hour: string;
    maxHour?: string;
    type: typeHour;
    id: string;
    onChangeHour: (value: string) => void;
    onChangeMaxHour?: (value: string) => void;
    onDeleteHour: (id: string) => void;
    errorMessage?: string;
    errorClassName?: string;
    disabled?: boolean;
    multi?: boolean;
};

export const MultiTaskHour: FC<MultiTaskHourProps> = ({
    hour,
    id,
    onChangeHour,
    onChangeMaxHour,
    onDeleteHour,
    maxHour,
    errorMessage,
    disabled,
    multi = false,
}) => {
    const { t } = useTranslation();

    const label = multi ? t(TranslationCommon.TIME_FROM) : t(TranslationKeys.TASK_START_HOURS);
    const labelSecondHour = t(TranslationCommon.TIME_TO);

    return (
        <div className="multiTaskHour">
            <div className="multiTaskHour__wrapper">
                <div className="taskHourContainer">
                    <Label label={label} disabled={disabled} />
                    <div className={`taskHourContainer__inputBtn${!multi ? "--alone" : ""}`}>
                        <TimePicker value={hour} onChange={onChangeHour} disabled={disabled} />
                        {!disabled && !multi && (
                            <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="deleteInputIcon"
                                onClick={() => onDeleteHour(id)}
                            />
                        )}
                    </div>
                </div>
                {multi && (
                    <div className="taskHourContainer">
                        <Label label={labelSecondHour} disabled={disabled} />
                        <div className="taskHourContainer__inputBtn">
                            <TimePicker
                                value={maxHour!}
                                onChange={(value) => onChangeMaxHour && onChangeMaxHour(value)}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                )}
                {!disabled && multi && (
                    <div className="multiTaskHour__deleteMultiHour">
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="deleteInputIcon"
                            onClick={() => onDeleteHour(id)}
                        />
                    </div>
                )}
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
};
