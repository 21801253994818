import { NotificationVariantModel } from "app/models/NotificationVariant";
import { NotificationVariant } from "app/shared/Constants";

export const notificationVariantRecord: Record<string, NotificationVariantModel> = {
    [NotificationVariant.DANGER.toString()]: "danger",
    [NotificationVariant.INFO.toString()]: "primary",
    [NotificationVariant.STAR_DANGER.toString()]: "star-danger",
    [NotificationVariant.STAR_INFO.toString()]: "star-info",
    [NotificationVariant.STAR_YELLOW.toString()]: "star-yellow",
    [NotificationVariant.USER_REGISTRATION.toString()]: "danger",
    [NotificationVariant.USER_DEREGISTRATION.toString()]: "danger",
    [NotificationVariant.SPORADIC_TASK.toString()]: "sporadic-task",
    [NotificationVariant.AUDIT_REPORT.toString()]: "audit-report",
    [NotificationVariant.PATCH_NOTES.toString()]: "patch-notes",
    [NotificationVariant.AUDIT_NC.toString()]: "audit-nc",
    [NotificationVariant.ISSUE_TASK_TEMP.toString()]: "issue-task-temp",
    [NotificationVariant.ISSUE_TASK.toString()]: "issue-task",
};
