import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useUserType } from "app/hooks/useUserType";
import { TranslationTitles } from "app/translation/translationKeys";
import { ProfileService } from "app/services";
import AnalyticsService from "app/services/03-REP/AnalyticsService";
import { buildParams, buildParamsWorkerDetail } from "app/pages/03-REP/01-AnalystRankingPage/helpers/helpers";
import { convertUTCtoLocaleDate, getHourSecWithString, transformMinutesHoursToDate } from "app/helpers";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { UserWorkDaysMapper } from "app/mappers/UserWorkDaysMapper";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";
import { AnalistWorkerDetailModel } from "app/models/03-REP/Analytics/AnalistWorkerDetailModel";
import { WorkDay } from "app/components_v2/__inputs";
import { ValorationDetailModal } from "app/components_v2/__modals/ValorationDetailModal/ValorationDetailModal";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { MyInformation } from "./TabChildrens/MyInformation/MyInformation";
import { MyConfiguration } from "./TabChildrens/MyConfiguration/MyConfiguration";
import { MyProfileHeader } from "./MyProfileHeader/MyProfileHeader";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";

const today = new Date(new Date().toISOString());
const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
const INITIAL_START_DATE = new Date(oneWeekAgo);
const INITIAL_END_DATE = new Date(today.setDate(today.getDate() - 1));

const MyProfilePage: FC = () => {
    const session = useSession();
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { isSupervisorOrAnalist } = useUserType();

    useTitle(t(TranslationTitles.MY_PROFILE_PAGE_TITLE));

    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(true);
    const [user, setUser] = useState<UserModel>();
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [supervisorDetail, setSupervisorDetail] = useState<AnalistSupervisorDetailModel>();
    const [workerDetail, setWorkerDetail] = useState<AnalistWorkerDetailModel>();
    const [scheduledDoNotDisturb, setScheduledDoNotDisturb] = useState<boolean>(false);
    const [minNotifHour, setMinNotifHour] = useState("");
    const [maxNotifHour, setMaxNotifHour] = useState("");
    const [weekDays, setWeekDays] = useState<WorkDay[]>([]);

    const fetchAllInfo = async () => {
        if (!session?.user.id) return;
        setIsLoadingProfile(true);
        const { data, status, getParsedError } = await ProfileService.Get(session?.token);

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsLoadingProfile(false);
            return;
        }

        setUser(data);
        setScheduledDoNotDisturb(data.scheduledDoNotDisturbMode);
        const minHour = data?.minNotifHour
            ? getHourSecWithString(convertUTCtoLocaleDate(new Date(transformMinutesHoursToDate(data?.minNotifHour))))
            : "";

        const maxHour = data?.maxNotifHour
            ? getHourSecWithString(convertUTCtoLocaleDate(new Date(transformMinutesHoursToDate(data?.maxNotifHour))))
            : "";

        handleChangeHours(minHour, maxHour);

        setWeekDays(UserWorkDaysMapper(data.userWorkDays));
        setIsLoadingProfile(false);
    };

    const fetchUserData = useCallback(
        async ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
            setIsLoadingModal(true);

            const params = buildParams({
                startDateInput: startDate || INITIAL_START_DATE,
                endDateInput: endDate || INITIAL_END_DATE,
            });

            const paramsWorker = buildParamsWorkerDetail({
                startDateInput: startDate || INITIAL_START_DATE,
                endDateInput: endDate || INITIAL_END_DATE,
            });

            const { status, data } = isSupervisorOrAnalist
                ? await AnalyticsService.GetSupervisorDetail(session?.user.id || 0, {
                      extraParams: params.toString(),
                  })
                : await AnalyticsService.GetWorkerDetail(session?.user.id || 0, {
                      extraParams: paramsWorker.toString(),
                  });

            if (status())
                isSupervisorOrAnalist
                    ? setSupervisorDetail(data as AnalistSupervisorDetailModel)
                    : setWorkerDetail(data as AnalistWorkerDetailModel);

            setIsLoadingModal(false);
        },
        [AnalyticsService]
    );

    const handleOpenModal = () => {
        setIsLoadingModal(true);
        setShowModal(true);
        fetchUserData({});
    };

    const handleChangeHours = (minHour: string, maxHour: string) => {
        setMinNotifHour(minHour);
        setMaxNotifHour(maxHour);
    };

    const onDateChange = ({ endDate, startDate }: Partial<DatesReviewTask>) => {
        if (!endDate) return;
        fetchUserData({ endDate, startDate });
    };

    useEffect(() => {
        fetchAllInfo();
    }, []);

    return (
        <PageContainer
            header={
                <TabHeader>
                    <MyProfileHeader isLoading={isLoadingProfile} onOpenModal={handleOpenModal} user={user} />
                    {showModal && (
                        <ValorationDetailModal
                            showModal={setShowModal}
                            startDate={INITIAL_START_DATE}
                            endDate={INITIAL_END_DATE}
                            onDateChange={onDateChange}
                            data={supervisorDetail ? supervisorDetail : workerDetail}
                            isLoading={isLoadingModal}
                            isSupervisor={isSupervisorOrAnalist}
                        />
                    )}
                </TabHeader>
            }
        >
            <div className="myprofile">
                <div className="myprofile__column">
                    <WhiteBox fullHeight>
                        <MyInformation />
                    </WhiteBox>
                </div>
                <div className="myprofile__column">
                    <WhiteBox fullHeight>
                        <MyConfiguration
                            user={user}
                            setUser={setUser}
                            isLoading={isLoadingProfile}
                            onChangeScheduledDoNotDisturb={setScheduledDoNotDisturb}
                            scheduledDoNotDisturb={scheduledDoNotDisturb}
                            minNotifHour={minNotifHour}
                            maxNotifHour={maxNotifHour}
                            onChangeHours={handleChangeHours}
                            weekDays={weekDays}
                            onDayChange={setWeekDays}
                        />
                    </WhiteBox>
                </div>
            </div>
        </PageContainer>
    );
};

export default MyProfilePage;
