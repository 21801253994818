import { FC, useState } from "react";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Hint } from "../Hint/Hint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { Label } from "../Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";

type TimePickerProps = {
    value: string;
    onChange: (value: string) => void;
    label?: string;
    readonly?: boolean;
    disabled?: boolean;
    errorColor?: boolean;
    errorMessage?: string;
    hint?: string;
    isOptional?: boolean;
};

export const TimePicker: FC<TimePickerProps> = ({
    value,
    onChange,
    label,
    readonly,
    disabled,
    errorColor,
    errorMessage,
    hint,
    isOptional,
}) => {
    const [showHint, setShowHint] = useState(false);

    if (readonly) return <ReadOnlyInput value={value} label={label} type="date" placeholder="--:--" />;

    return (
        <div className="timePicker">
            {label && (
                <div className="timePicker__label">
                    <Label isOptional={isOptional} label={label} />
                    {hint && <FontAwesomeIcon icon={faCircleQuestion} onClick={() => setShowHint(!showHint)} />}
                </div>
            )}
            <input
                className={`timePicker__input${!!errorMessage || errorColor ? "--error" : ""}`}
                type="time"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            />
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            {showHint && hint && !errorMessage && <Hint text={hint} />}
        </div>
    );
};
