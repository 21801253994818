import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { taskHoursModel } from "app/models/02-TAR/TaskWizard";
import { v4 } from "uuid";

type IuseTaskTemporalityRangeHours = {
    taskHours: taskHoursModel[];
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporalityRangeHours = ({ onChange, taskHours }: IuseTaskTemporalityRangeHours) => {
    // Cuando añades la hora te comprueba si hay 3 o más
    // si la hay no te deja hacer nada
    // si no hay más de 2 te la añade
    const onAddNewHour = () => {
        if (taskHours.length >= 3) return;

        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "hour" }],
        });
    };

    const onAddNewRangeHour = () => {
        if (taskHours.length >= 3) return;
        onChange({
            taskHours: [...taskHours, { hour: "", id: v4(), maxHour: "", type: "range" }],
        });
    };

    const onDeleteHour = (id: string) => {
        onChange({
            taskHours: taskHours.filter((hour) => id !== hour.id),
        });
    };

    // Cuando borras o dejas sin valor el input de añadir horas
    // Si hay solo una hora pero vacía no hace nada
    // Si hay un input sin valor y dás click fuera te lo elimina
    const onBlur = () => {
        if (taskHours.length <= 1) return;
        return onChange({
            taskHours: taskHours.filter(({ hour }) => hour !== ""),
        });
    };

    return {
        onAddNewHour,
        onAddNewRangeHour,
        onDeleteHour,
        onBlur,
    };
};
