import { FC } from "react";
import { AvatarSkelleton } from "../AvatarSkelleton";

type UsersLoginItemSkelletonProps = {
    quantity?: number;
};

export const UsersLoginItemSkelleton: FC<UsersLoginItemSkelletonProps> = ({ quantity = 3 }) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, i) => (
                <div className="usersLoginItemSkelleton" key={i}>
                    <AvatarSkelleton size="sm" />
                    <div className="usersLoginItemSkelleton__trash"></div>
                </div>
            ))}
        </>
    );
};
