import { FC } from "react";
import { EditImageAction } from "../types";
import { EditImageIcon } from "../EditImageIcon/EditImageIcon";
import { faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";

type EditImageAcceptDeclineProps = {
    currentAction: EditImageAction | null;
    onAccept: () => void;
    onCancel: () => void;
};

export const EditImageAcceptDecline: FC<EditImageAcceptDeclineProps> = ({ currentAction, onAccept, onCancel }) => {
    if (currentAction === "CROPPER") return null;
    return (
        <div className="editImageAcceptDecline">
            {currentAction ? (
                <EditImageIcon icon={faCheck} onClick={onAccept} />
            ) : (
                <EditImageIcon icon={faXmark} onClick={onCancel} />
            )}
        </div>
    );
};
