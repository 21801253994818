import { FC, useContext } from "react";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { GroupBase, MenuListProps, components } from "react-select";
import { SelectWithActionsItem } from "../SelectWithActionsItem/SelectWithActionsItem";
import { SelectWithActionsMenuContext } from "../context/SelectWithActionsMenuContext";
import { SelectWithActionNoOptions } from "../SelectWithActionNoOptions/SelectWithActionNoOptions";
import { SelectWithActionsLastItems } from "../SelectWithActionsLastItems/SelectWithActionsLastItems";

type SelectWithActionsMenuProps = MenuListProps<OptionsSearch, false, GroupBase<OptionsSearch>>;

export const SelectWithActionsMenu: FC<SelectWithActionsMenuProps> = ({ ...props }) => {
    const { onSelect, selectedValue, selectOptions, searchedValue, actions, lastItems, closeMenu } =
        useContext(SelectWithActionsMenuContext);
    const optionsFiltered = selectOptions.filter(({ label }) =>
        label.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase())
    );

    const formattedActions = actions.map(({ icon, onClick, hidden }) => ({
        icon,
        hidden,
        onClick: (item: OptionsSearch) => {
            onClick(item);
            closeMenu();
        },
    }));

    return (
        <components.MenuList {...props}>
            <div className="selectWithActionsMenu">
                {!optionsFiltered.length && <SelectWithActionNoOptions />}
                {optionsFiltered.map((item) => (
                    <SelectWithActionsItem
                        item={item}
                        key={item.value}
                        onSelect={onSelect}
                        isActive={item.value === selectedValue}
                        actions={formattedActions}
                    />
                ))}
                {!!lastItems.filter((x) => !x.hidden).length && (
                    <div className="selectWithActionsMenu__lastItems">
                        {lastItems.map(
                            (item, key) =>
                                !item.hidden && (
                                    <SelectWithActionsLastItems
                                        item={{
                                            ...item,
                                            onClick: () => {
                                                item.onClick();
                                                closeMenu();
                                            },
                                        }}
                                        key={key}
                                    />
                                )
                        )}
                    </div>
                )}
            </div>
        </components.MenuList>
    );
};
