import { FC, useState } from "react";
import { RadioButtonProps } from "./radioTypes";
import { RadioCheckboxContainer } from "../__containers/RadioCheckboxContainer/RadioCheckboxContainer";
import { Label } from "../__inputs/Label";

export const RadioButton: FC<RadioButtonProps> = ({
    onChange,
    checked = false,
    disabled,
    htmlFor,
    name,
    label,
    sublabel,
    inverted,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const handleChange = () => {
        onChange && onChange(!checked);
    };

    return (
        <div className={`radioGroup__item`}>
            <input type="radio" onChange={handleChange} checked={checked} hidden={true} id={htmlFor} name={name} />
            <RadioCheckboxContainer>
                <>
                    <div
                        onClick={() => !disabled && handleChange()}
                        className={`radio${checked ? "--active" : ""}${isFocused ? "--focus" : ""} ${
                            disabled ? "disabled" : ""
                        }`}
                        onMouseDown={() => !disabled && setIsFocused(true)}
                        onMouseUp={() => !disabled && setIsFocused(false)}
                        onMouseLeave={() => !disabled && setIsFocused(false)}
                        role="radio"
                        aria-checked={checked}
                        aria-disabled={disabled}
                    >
                        <div className={`radio__interior ${disabled ? "disabled" : ""}`}></div>
                    </div>
                    {label && (
                        <Label
                            label={label}
                            subLabel={sublabel}
                            disabled={disabled}
                            htmlFor={htmlFor}
                            inverted={inverted}
                            minWidth
                        />
                    )}
                </>
            </RadioCheckboxContainer>
        </div>
    );
};
