import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { Badge } from "app/components_v2/Badge/Badge";
import { ProgressBar } from "app/components_v2/ProgressBar/ProgressBar";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { RowPositionModel } from "app/components_v2/Table/types";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";
import { AuditGroupCheckListInstanceTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AuditGroupMobileProps = {
    row: AuditGroupCheckListInstanceModel;
    rowPosition: RowPositionModel;
    onClick: () => void;
};

export const AuditGroupMobile: FC<AuditGroupMobileProps> = ({ onClick, row, rowPosition }) => {
    const { t } = useTranslation();

    const { name, totalCheckedItems, status, auditGroupCheckListItemInstances } = row;
    const isCompleted = status === "COMPLETED";
    const isAllItemsNA = auditGroupCheckListItemInstances.every(({ value }) => value === "NA");
    const percentage = (totalCheckedItems * 100) / auditGroupCheckListItemInstances.length;
    const issueCount = auditGroupCheckListItemInstances.filter(({ value }) => value === "KO").length;

    return (
        <div className={`mobileAuditGroupRow--${rowPosition}`}>
            <div className="mobileAuditGroupRow__header">
                <div className="mobileAuditGroupRow__header__container">
                    <p className="mobileAuditGroupRow__name">{name}</p>
                    <Badge
                        title={
                            isCompleted
                                ? isAllItemsNA
                                    ? t(
                                          AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE
                                      )
                                    : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED)
                                : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS)
                        }
                        variant={isCompleted ? (isAllItemsNA ? "grey" : "green") : "blue"}
                    />
                </div>
                <CellIcons
                    icons={[
                        {
                            icon: faListCheck,
                            onClick: onClick,
                        },
                    ]}
                />
            </div>
            <div className="mobileAuditGroupRow__footer">
                <div className="mobileAuditGroupRow__progressbar">
                    <ProgressBar color="green" percentage={percentage} size="sm" />
                    <p className="mobileAuditGroupRow__progressbar__total">
                        {totalCheckedItems} / {auditGroupCheckListItemInstances.length}
                    </p>
                </div>
                <p className="mobileAuditGroupRow__issueCount">
                    {`${t(
                        AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_ISSUE_COUNT_SMALL
                    )}: ${issueCount}`}
                </p>
            </div>
        </div>
    );
};
