import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { OptionsSearch } from "app/models/FormComponentsModel";

export default class CustomerInstanceWorkingPositionService {
    public static async GetWorkingPositionsFromCustomerInstance(customerInstance: string | number, used?: boolean) {
        return FetchService.get<PaginatedResult<OptionsSearch>>({
            url: `${ApiPaths.TAR_API}/CustomerInstanceWorkingPosition/GetQRfromCustomerInstance/${customerInstance}?used=${used}`,
        });
    }
}
