type UserData = {
    firstName: string;
    id: number;
};

export const userData: UserData[] = [
    { firstName: "Empleado 1", id: 1 },
    { firstName: "Empleado 2", id: 2 },
    { firstName: "Empleado 3", id: 3 },
    { firstName: "Empleado 4", id: 4 },
    { firstName: "Empleado 5", id: 5 },
    { firstName: "Empleado 6", id: 6 },
    { firstName: "Empleado 7", id: 7 },
];
