import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CloseCompanyTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { PostLoginContext } from "../state/context/PostLoginContext";
import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { TaskReviewService } from "app/services";
import { useToast } from "app/hooks/Toast/useToast";
import { useUserType } from "app/hooks/useUserType";
import { useSession } from "app/hooks";
import { getDateFormattedSelects, getDayMonthFormatedWithYearNumbers } from "app/helpers/Date.utilities";
import { OptionCompanySkelleton } from "app/components_v2/__skelletons/OptionCompanySkelleton/OptionCompanySkelleton";
import { faQuestion, faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { CompanyTaskToReview } from "app/dtos/01-SEG/Company/CompanyTaskToReview";
import { BadgeType } from "app/components_v2/Badge/badgeTypes";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { Input } from "../../../__inputs/Input/Input";
import { TableError } from "app/components_v2/Table/TableError/TableError";

type StepProps = {
    next: () => void;
    onSelectCompany: (Wcomp: UserCompanyModel) => void;
};

export const StepOne: FC<StepProps> = ({ next, onSelectCompany }) => {
    const { t } = useTranslation();
    const session = useSession();
    const { setStepOne, setStepTwo, setStepThree, workingCompany } = useContext(PostLoginContext);
    const { handleToast } = useToast();
    const { isSupervisorOrAnalist } = useUserType();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const companies = session?.user?.companies;
    const sessionCompany = session?.workingCompany?.companyId;

    const [companyPendingTasks, setCompanyPendingTasks] = useState<CompanyTaskToReview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [searchCompanyValue, setSearchCompanyValue] = useState<string>("");

    const handleCompanySearch = (newValue: string) => {
        setSearchCompanyValue(newValue);
    };

    const handleCompanySearchSubmit = (e: FormEvent) => {
        e.preventDefault();
        getCompanyPendingTasksToReview();
    };

    const saveSession = (wCompany: UserCompanyModel) => {
        if (session) {
            setStepOne(wCompany);
            setStepTwo({
                departmentId: 0,
                departmentName: "",
            });
            setStepThree({
                subdepartmentId: 0,
                subdepartmentName: "",
            });
        }
    };

    const handleCompanySelect = (company: UserCompanyModel) => {
        const { companyId, companyName, departments, logoUrl } = company;
        saveSession({ companyId, companyName, departments, logoUrl });
        onSelectCompany(company);
        next();
    };

    const isWorkingCompanyActive = (id: number) => {
        if (!workingCompany) return false;
        if (workingCompany.companyId === id) return true;
        if (!sessionCompany) return false;
        return sessionCompany === id;
    };

    const getCompanyPendingTasksToReview = async () => {
        setIsLoading(true);
        let extraParams = `deadlineDate=${getDateFormattedSelects(new Date())}`;
        if (!!searchCompanyValue.length) extraParams += `&query=${searchCompanyValue}`;

        const pendingTasksSr = await TaskReviewService.GetCompanyPendingTasksToReview(extraParams);

        if (!pendingTasksSr.status()) {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            setCompanyPendingTasks([]);
            return;
        }
        setIsLoading(false);
        setCompanyPendingTasks(pendingTasksSr.data);
    };

    const getSelectedCompany = (company: CompanyTaskToReview) => {
        const selectedTask = companyPendingTasks.find((pendingTasks) => pendingTasks.companyId === company.companyId);
        const closeDate = getDayMonthFormatedWithYearNumbers(selectedTask?.closedDate?.toString() || "", "/");
        const openDate = getDayMonthFormatedWithYearNumbers(selectedTask?.openDate?.toString() || "", "/");
        const badge: BadgeType | undefined = selectedTask?.isClosed
            ? {
                  icon: faXmark,
                  title: t(CloseCompanyTranslations.COMPANY_CLOSED_DAYS).replace("{1}", `${closeDate} - ${openDate}`),
                  variant: "grey",
                  theme: "light",
                  outline: false,
              }
            : undefined;
        const count = session?.user.userType !== "WORKER" ? selectedTask?.pendingTasksToReview : 0;

        return {
            selectedTask,
            badge,
            count,
        };
    };

    useEffect(() => {
        saveSession({
            companyId: 0,
            companyName: "",
        });
    }, []);

    useEffect(() => {
        const hasMultipleCompanies = companies && companies?.length > 1;
        const hasSingleCompany = companies?.length === 1;

        if (hasSingleCompany) {
            handleCompanySelect({ ...companies[0] });
            return;
        }

        if (!hasMultipleCompanies) return;
        getCompanyPendingTasksToReview();
    }, [companies, session?.workingCompany]);

    return (
        <WizardForm validate={handleCompanySearchSubmit}>
            <div className="selectDepartmentWizard__children">
                {!sessionCompany && (
                    <p className="selectDepartmentWizard__children__subtitle">
                        {` ${t(TranslationKeys.WELCOME_COMPANY)} ${session?.user.firstName}`}
                    </p>
                )}
                <h1 className="selectDepartmentWizard__children__title">
                    {isSupervisorOrAnalist
                        ? translateCustomerTypeKeys(TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE_SUPERVISOR, {
                              firstReplace: "SINGULAR",
                          })
                        : translateCustomerTypeKeys(TranslationKeys.SELCT_DEPARTMENT_COMPANY_TITLE, {
                              firstReplace: "SINGULAR",
                          })}
                </h1>
                <div className="selectDepartmentWizard__children__options">
                    <Input
                        value={searchCompanyValue}
                        onChange={handleCompanySearch}
                        placeholder={t(TranslationCommon.SEARCH)}
                        iconRight={faSearch}
                        onClickIcon={getCompanyPendingTasksToReview}
                    />
                    {isLoading ? (
                        <OptionCompanySkelleton quantity={5} />
                    ) : (
                        <>
                            {!!companyPendingTasks.length ? (
                                companyPendingTasks.map((company, i) => {
                                    const { badge, selectedTask, count } = getSelectedCompany(company);
                                    return (
                                        <OptionCompany
                                            name={company.companyName}
                                            onClick={() => {
                                                const selectedCompany = companies?.find(
                                                    (x) => x.companyId == company.companyId
                                                );
                                                selectedCompany && handleCompanySelect(selectedCompany);
                                            }}
                                            isActive={isWorkingCompanyActive(company.companyId)}
                                            key={i}
                                            logoImage={company.logoUrl || ""}
                                            count={count || 0}
                                            isDisabled={selectedTask?.isClosed}
                                            badge={badge}
                                            justifyContent="center"
                                        />
                                    );
                                })
                            ) : (
                                <div className="selectDepartmentWizard__children__options__companyListEmpty">
                                    <TableError icon={faQuestion} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </WizardForm>
    );
};
