import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { useSession } from "app/hooks";
import { AvatarStack } from "app/components_v2/AvatarStack/AvatarStack";

export const AvatarSection: FC<SectionsShowProp> = ({ show }) => {
    const session = useSession();

    const secondaryAvatars = [
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "Roger Perez",
            receiverUserId: 56,
        },
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "pepe raj",
            receiverUserId: 76,
        },
        {
            receiverProfilePictureURL: "",
            receiverUserName: "Asd Sasdasd",
            receiverUserId: 13,
        },
        {
            receiverProfilePictureURL: "",
            receiverUserName: "Easd Basdasd",
            receiverUserId: 36,
        },
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "Roger Perez",
            receiverUserId: 46,
        },
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "Roger Perez",
            receiverUserId: 86,
        },
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "Roger Perez",
            receiverUserId: 96,
        },
    ];

    const [avatars, setAvatars] = useState([
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "Roger Perez",
            receiverUserId: 56,
        },
        {
            receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
            receiverUserName: "pepe raj",
            receiverUserId: 76,
        },
        {
            receiverProfilePictureURL: "",
            receiverUserName: "Asd Sasdasd",
            receiverUserId: 13,
        },
        {
            receiverProfilePictureURL: "",
            receiverUserName: "Easd Basdasd",
            receiverUserId: 36,
        },
    ]);

    const addOneMoreAvatar = () => {
        setAvatars((prev) => [
            ...prev,
            {
                receiverProfilePictureURL: "https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg",
                receiverUserName: "Roger Perez",
                receiverUserId: 96,
            },
        ]);
    };

    const removeLastOne = () => {
        if (!avatars.length) return;
        setAvatars((prevAvatars) => prevAvatars.slice(0, -1));
    };

    return (
        <Section title="AVATAR" show={show}>
            <div className="designSystemAvatar">
                <Avatar
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                    border
                    name="Pepe"
                    img="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg"
                    background="white"
                    capitalize
                    clamp
                />
                <Avatar
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                    border
                    name="Pepe"
                    img="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg"
                    background="white"
                    capitalize
                    clamp
                    goMyProfile
                />

                <Avatar
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                />
                <Avatar
                    img="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg"
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                />
                <Avatar
                    img="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg"
                    notificationNumber={3}
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                />
                <Avatar
                    img="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_640.jpg"
                    notificationNumber={103}
                    avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                    colorId={session?.user.id || 0}
                />
                <div>
                    <button onClick={addOneMoreAvatar}>Añadir</button>
                    <button onClick={removeLastOne}>Quitar</button>
                </div>
                <h3>XS</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="xs"
                />
                <h3>sm</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="sm"
                />
                <h3>md</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="md"
                />
                <h3>lg</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="lg"
                />
                <h3>xl</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="xl"
                />
                <h3>2xl</h3>
                <AvatarStack
                    avatars={avatars.map(({ receiverProfilePictureURL, receiverUserId, receiverUserName }) => ({
                        id: receiverUserId,
                        name: receiverUserName,
                        profilePictureUrl: receiverProfilePictureURL,
                    }))}
                    size="2xl"
                />
            </div>
        </Section>
    );
};
