import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { RestablishPasswordRequestModel } from "app/models/00-LOGIN/Profile/RestablishPasswordRequestModel";
import { LoginResponseModel } from "app/models/00-LOGIN/Auth/LoginResponseModel";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { ImageBase64String } from "app/dtos/ImageBase64String";
import { Bool } from "app/dtos/Bool";
import { UserWorkDaysHoursModel } from "app/models/01-SEG/User/UserWorkDaysHoursModel";

export default class ProfileService {
    public static async Get(token?: string) {
        return FetchService.get<UserModel>({
            url: `${ApiPaths.LOGIN_API}/profile`,
            token: token,
        });
    }

    public static async Put(body: UserModel) {
        return FetchService.put<UserModel>({
            url: `${ApiPaths.LOGIN_API}/profile`,
            body: body,
        });
    }

    public static async ChangePassword(request: RestablishPasswordRequestModel) {
        return FetchService.put<LoginResponseModel>({
            url: `${ApiPaths.LOGIN_API}/profile/changePassword`,
            body: request,
        });
    }

    public static async NewPassword(request: RestablishPasswordRequestModel) {
        return FetchService.put<LoginResponseModel>({
            url: `${ApiPaths.LOGIN_API}${ApiPaths.NEW_PASSWORD}`,
            body: request,
        });
    }

    public static async ActivateAccount(request: RestablishPasswordRequestModel) {
        return FetchService.put<LoginResponseModel>({
            url: `${ApiPaths.LOGIN_API}${ApiPaths.AUTH_ACTIVATE_ACCOUNT}`,
            body: request,
        });
    }

    public static async SendRecoverPasswordEmail(request: RestablishPasswordRequestModel) {
        return FetchService.post<LoginResponseModel>({
            url: `${ApiPaths.LOGIN_API}${ApiPaths.AUTH_RESET_PASSWORD}`,
            body: request,
        });
    }

    // -----------------------------------USER-PROFILE-------------------------------------- //

    public static async UploadPhoto(imageBase64: ImageBase64String) {
        return FetchService.put({
            url: `${ApiPaths.LOGIN_API}/profile/UpdatePhoto`,
            body: imageBase64,
        });
    }

    public static async UpdateDoNotDisturbMode(value: Bool) {
        return FetchService.put({
            url: `${ApiPaths.LOGIN_API}/profile/UpdateDoNotDisturbMode`,
            body: value,
        });
    }

    public static async UpdateWorkDaysHours(workDaysHours: UserWorkDaysHoursModel) {
        return FetchService.put({
            url: `${ApiPaths.LOGIN_API}/profile/UpdateWorkDaysHours`,
            body: workDaysHours,
        });
    }

    public static async UpdateScheduledDoNotDisturbMode(value: Bool) {
        return FetchService.put({
            url: `${ApiPaths.LOGIN_API}/profile/UpdateScheduledDoNotDisturbMode`,
            body: value,
        });
    }

    public static async UpdateLanguage(language: string) {
        return FetchService.put({
            url: `${ApiPaths.LOGIN_API}/Profile/UpdateLanguage`,
            body: { language },
        });
    }
}
