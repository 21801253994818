import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";

export default class TarSelectorService {
    // GET Returns a list of task instance names
    public static async GetTaskNames(params?: PaginationParams) {
        return FetchService.get<string[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/getTaskNames`,
            paginationParams: params,
        });
    }

    public static async GetWorkingPostions(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/WorkingPositions`,
            paginationParams: params,
        });
    }

    public static async GetDynamicFields() {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/DynamicFields`,
        });
    }

    // GET all base task types
    public static async GetBaseTaskTypes() {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/BaseTaskType`,
        });
    }

    public static async GetWorkingPositionOptionsAssignedToTask(params?: PaginationParams) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/GetWorkingPositionOptionsAssignedToTask`,
            paginationParams: params,
        });
    }

    // GET all base tasks
    public static async GetBaseTasks(extraParams?: string) {
        return FetchService.get<OptionModel[]>({
            url: `${ApiPaths.TAR_API}/TarSelector/BaseTask`,
            paginationParams: { extraParams },
        });
    }
}
