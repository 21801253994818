import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Input, OptionsOutsideSelect } from "app/components_v2/__inputs";

type UsersToSend = {
    usersId: number[];
    name: string;
    allUsers: OptionsSearch[];
};

type AddUsersModalProps = {
    title: string;
    onClose: () => void;
    service: () => Promise<ServiceResponse<OptionModel[]>>;
    handleAddUsers: (users: UsersToSend) => void;
    users: OptionModel[];
    name: string;
    nameLabel: string;
    isSaving: boolean;
};

const AddUsersModal: FC<AddUsersModalProps> = ({
    title,
    onClose,
    service,
    handleAddUsers,
    users,
    name,
    nameLabel,
    isSaving,
}) => {
    const { t } = useTranslation();
    const [selectedUsers, setSelectedUsers] = useState<OptionModel[]>([]);
    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [inputValue, setInputValue] = useState<string>(name);

    const getUsersInDepartmentAlready = async () => {
        const { data, status } = await service();
        if (!status) {
            console.error(t(TranslationErrors.GENERIC_ERROR));
            setSelectedUsers([]);
        }
        setSelectedUsers(users);

        setUsersOptions(data);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleAddUsers({
            name: inputValue,
            usersId: selectedUsers.map((user) => Number(user.value)),
            allUsers: usersOptions,
        });
    };

    useEffect(() => {
        getUsersInDepartmentAlready();
    }, []);

    return (
        <GenericModal
            header={{ title, onClose }}
            size="lg"
            footer={
                hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT) && (
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "addUsersModalForm",
                            type: "submit",
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: onClose,
                        }}
                        loading={isSaving}
                    />
                )
            }
        >
            <form onSubmit={handleSubmit} className="addUsersModal" id="addUsersModalForm">
                <div className="addUsersModal__container">
                    <Input onChange={setInputValue} value={inputValue} label={nameLabel} />
                    <div className="addUsersModal__dropdown">
                        <OptionsOutsideSelect
                            onChange={setSelectedUsers}
                            options={usersOptions}
                            onDeleteItem={(item) => {
                                setSelectedUsers((prev) => prev.filter(({ value }) => value !== item));
                            }}
                            values={selectedUsers}
                            hidden={!hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT)}
                            placeholder={t(TranslationKeys.SELECT_USERS)}
                            label={t(TranslationKeys.USERS)}
                            isMulti
                        />
                    </div>
                </div>
            </form>
        </GenericModal>
    );
};
export default AddUsersModal;
