import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Cookies from "universal-cookie";
import { ApiPaths, CookieKey } from "../../shared/Constants";

// Singleton pattern for SignalR
class SignalRService {
    // Unique connection
    private static connection: SignalRService;

    private constructor() {
        this.SignalRConnection.serverTimeoutInMilliseconds = 120000;
    }

    public static getSignalR(): SignalRService {
        if (!SignalRService.connection) SignalRService.connection = new SignalRService();
        return SignalRService.connection;
    }

    // Create connection
    public SignalRConnection = new HubConnectionBuilder()
        .withUrl(ApiPaths.SIGNALR_API + "/chatHub", {
            withCredentials: false,
            accessTokenFactory: () => new Cookies().get(CookieKey.REFRESH_TOKEN),
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

    // Start connection
    public start = async (): Promise<string> => {
        // 1. Establish SignalR connection and join rooms
        if (this.SignalRConnection.state === "Disconnected") {
            try {
                await this.SignalRConnection.start();
            } catch (err) {
                console.error("SignalR connect ERROR: ", err);
            }
        }

        this.SignalRConnection.onreconnecting((error) => {});
        this.SignalRConnection.onreconnected((connectionId) => {});

        //  First execution
        if (this.SignalRConnection.state === "Disconnected") {
            setTimeout(() => {
                this.start();
            }, 2000);
        }

        //  Execution when connection is closed
        this.SignalRConnection.onclose(() => {
            if (this.SignalRConnection.state === "Disconnected") {
                setTimeout(() => {
                    this.start();
                }, 2000);
            }
        });
        return this.SignalRConnection.state;
    };

    // Stop SignalR
    public async stopSignalR() {
        this.SignalRConnection.stop();
    }
}

export default SignalRService;
