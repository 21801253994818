import { FC, useRef, useState } from "react";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { DateRangePicker, Input } from "app/components_v2/__inputs";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { faChevronDown, faChevronUp, faSearch, faSliders, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { RankingFilter } from "app/components_v2/__filters/RankingFilter/RankingFilter";
import { RankingFilterModel } from "app/components_v2/__filters/RankingFilter/types";
import { BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { PrivatePaths, screenSize } from "app/shared/Constants";
import { Icon } from "app/components_v2/Icon/Icon";
import { useLocation, useNavigate, useParams } from "react-router";
import { useUserType } from "app/hooks/useUserType";
import { useSession } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { MobileHeaderTitleSkelleton } from "app/components_v2/__skelletons/MobileHeaderTitleSkelleton/MobileHeaderTitleSkelleton";
import { Popover } from "app/components_v2/Popover/Popover";
import { DateRange } from "app/models/utilities";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export type RankingSortBy =
    | "NAME_ASC"
    | "NAME_DESC"
    | "STAR_ASC"
    | "STAR_DESC"
    | "NOT_COMPLETED"
    | "COMPLETED_OUT_OF_TIME";

type RankingHeaderProps = {
    title: string;
    startDate?: Date;
    endDate?: Date;
    sortBy: RankingSortBy;
    filterData?: RankingFilterModel;
    initialfilterData?: RankingFilterModel;
    onFilter?: (value: RankingFilterModel) => void;
    onChangeSortBy: (value: RankingSortBy) => void;
    onDateChange?: (dates: DateRange) => void;
    onPressEnter: (query: string) => void;
    breadcrumbs?: BreadcrumbModel[];
    isLoading?: boolean;
};

export const RankingHeader: FC<RankingHeaderProps> = ({
    title,
    onDateChange,
    onPressEnter,
    startDate,
    endDate,
    sortBy,
    onChangeSortBy,
    filterData,
    initialfilterData,
    onFilter,
    breadcrumbs,
    isLoading,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const { id } = useParams();
    const session = useSession();
    const location = useLocation();
    const nav = useNavigate();
    const [searchParams] = useSearchParams();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const { isAnalist } = useUserType();

    const [query, setQuery] = useState<string>("");
    const [showInput, setShowInput] = useState<boolean>(!onDateChange);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const titleRef = useRef<HTMLDivElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const companyId = searchParams.get("companyId");
    const showFilterData = filterData && initialfilterData;

    useOnClickOutside(modalRef, () => {
        if (isOrderModalOpen) setIsOrderModalOpen(false);
    });

    useOnClickOutside(titleRef, () => {
        if (showModal) setShowModal(false);
    });

    const getText = (value: RankingSortBy) => {
        if (value === "NAME_ASC") return t(TranslationKeys.RANKING_ASC_NAME);
        if (value === "NAME_DESC") return t(TranslationKeys.RANKING_DESC_NAME);
        if (value === "STAR_ASC") return t(TranslationKeys.RANKING_ASC_STARS);
        if (value === "STAR_DESC") return t(TranslationKeys.RANKING_DESC_STARS);
        if (value === "COMPLETED_OUT_OF_TIME") return t(TranslationKeys.RANKING_COMPLETED_OUT_OF_TIME);
        return t(TranslationKeys.RANKING_NOT_COMPLETED);
    };

    const handleClick = (value: RankingSortBy) => {
        onChangeSortBy(value);
        setIsOrderModalOpen(false);
    };

    const handleFilterSave = (values: RankingFilterModel) => {
        onFilter && onFilter(values);
        setIsFilterOpen(false);
    };

    const getDropdownItems = () => {
        const items = [
            {
                text: getText("NAME_ASC"),
                onClick: () => handleClick("NAME_ASC"),
                active: sortBy === "NAME_ASC",
            },
            {
                text: getText("NAME_DESC"),
                onClick: () => handleClick("NAME_DESC"),
                active: sortBy === "NAME_DESC",
            },
            {
                text: getText("STAR_ASC"),
                onClick: () => handleClick("STAR_ASC"),
                active: sortBy === "STAR_ASC",
            },
            {
                text: getText("STAR_DESC"),
                onClick: () => handleClick("STAR_DESC"),
                active: sortBy === "STAR_DESC",
            },
        ];
        if (!!onDateChange) {
            items.push(
                {
                    text: getText("COMPLETED_OUT_OF_TIME"),
                    onClick: () => handleClick("COMPLETED_OUT_OF_TIME"),
                    active: sortBy === "COMPLETED_OUT_OF_TIME",
                },
                {
                    text: getText("NOT_COMPLETED"),
                    onClick: () => handleClick("NOT_COMPLETED"),
                    active: sortBy === "NOT_COMPLETED",
                }
            );
        }
        return items;
    };

    const handleSearch = () => {
        onPressEnter(query);
        setShowInput(!!query.length);
    };

    const handleDeleteText = () => {
        setQuery("");
        onPressEnter("");
    };

    const getRankingItems = () => {
        const items = [];
        if (isAnalist && session?.user.companies?.length && session?.user.companies?.length > 1) {
            items.push({
                text: translateCustomerTypeKeys(TranslationKeys.GLOBAL_RANKING_COMPANIES, { firstReplace: "PLURAL" }),
                onClick: () => {
                    (!location.pathname.includes("ranking-users") || !location.pathname.includes("ranking-teams")) &&
                        nav(`/${PrivatePaths.COMPANY_RANKING}`);
                },
                radius: true,
                active: !location.pathname.includes("ranking-users") && !location.pathname.includes("ranking-teams"),
            });
        }
        items.push(
            {
                text: t(TranslationKeys.GLOBAL_RANKING_DEPARTMENTS),
                onClick: () => {
                    !location.pathname.includes("ranking-teams") &&
                        nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-teams`);
                },
                radius: true,
                active: location.pathname.includes("ranking-teams"),
            },
            {
                text: t(TranslationKeys.GLOBAL_RANKING_USERS),
                onClick: () => {
                    !location.pathname.includes("ranking-users") &&
                        nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-users`);
                },
                radius: true,
                active: location.pathname.includes("ranking-users"),
            }
        );

        return items;
    };

    const hideRankingPopover = () => setShowModal(false);
    const hideSortByPopover = () => setIsOrderModalOpen(false);

    return (
        <TabHeader breadCrums={breadcrumbs} paddingSize="sm" isBreadcrumbLoading={isLoading}>
            {showFilterData && (
                <RankingFilter
                    filterData={filterData}
                    initialFilterData={initialfilterData}
                    showSecondaryFilter={isFilterOpen}
                    onCancel={() => setIsFilterOpen(false)}
                    onSave={handleFilterSave}
                />
            )}

            <div className="rankingLayoutHeader__container">
                <div className="rankingLayoutHeader">
                    <div>
                        <div
                            className={`flex gap--8 rankingLayoutHeader__title__container ${
                                !id && !companyId ? "pointer" : ""
                            }`}
                            onClick={() => setShowModal(!showModal)}
                            ref={titleRef}
                        >
                            {isLoading ? (
                                <MobileHeaderTitleSkelleton />
                            ) : (
                                <p className="rankingLayoutHeader__title">{title}</p>
                            )}

                            {!id && !companyId && !isLoading && (
                                <FontAwesomeIcon icon={showModal ? faChevronUp : faChevronDown} />
                            )}

                            {showModal && !id && !companyId && (
                                <Popover
                                    target={titleRef.current}
                                    position="bottomLeft"
                                    onResize={hideRankingPopover}
                                    onScroll={hideRankingPopover}
                                    scrollTarget={document.querySelector(".customContainer")}
                                    portal={false}
                                >
                                    <DropdownList items={getRankingItems()} />
                                </Popover>
                            )}
                        </div>
                    </div>

                    <div
                        className={`rankingLayoutHeader__filters${
                            showInput && width < screenSize.MOBILE ? "--column" : ""
                        }`}
                    >
                        {onDateChange && (
                            <DateRangePicker
                                inverted
                                onChange={(dates: DateRange) => onDateChange(dates)}
                                startDate={startDate}
                                endDate={endDate}
                                showMoreOptions
                                disableFutureDates
                                showToday={false}
                            />
                        )}
                        {showInput || width >= screenSize.MOBILE ? (
                            <div
                                className={`rankingLayoutHeader__filters__input ${
                                    !onDateChange && width >= screenSize.MOBILE ? "w50" : ""
                                }`}
                            >
                                <Input
                                    value={query}
                                    onChange={setQuery}
                                    placeholder={t(TranslationCommon.SEARCH)}
                                    iconRight={!(width <= screenSize.MOBILE) && !!query.length ? faXmark : faSearch}
                                    whiteInput
                                    transparent
                                    onPressEnter={handleSearch}
                                    onClickIcon={() => {
                                        width <= screenSize.MOBILE ? handleSearch() : handleDeleteText();

                                        onDateChange && setShowInput(false);
                                    }}
                                />
                            </div>
                        ) : (
                            <FontAwesomeIcon
                                icon={faSearch}
                                className="rankingLayoutHeader__filters__icon"
                                onClick={() => setShowInput(true)}
                            />
                        )}
                    </div>
                </div>
                <div className="rankingLayoutHeader__sort" ref={modalRef}>
                    <div
                        className="rankingLayoutHeader__sort__order"
                        onClick={() => setIsOrderModalOpen((prev) => !prev)}
                    >
                        <p className="rankingLayoutHeader__sort__order__text">{t(TranslationKeys.ORDER_BY_STARS)}</p>
                        <div className="rankingLayoutHeader__sort__order__container">
                            <p className="rankingLayoutHeader__sort__order__text">{getText(sortBy)}</p>
                            <FontAwesomeIcon icon={faChevronDown} className="feedback__order__icon" />
                        </div>
                    </div>
                    {showFilterData && (
                        <div className="rankingLayoutHeader__sort__secondaryFilter">
                            <Icon icon={faSliders} onClick={() => setIsFilterOpen(true)} />
                        </div>
                    )}
                    {isOrderModalOpen && (
                        <Popover
                            target={modalRef.current}
                            position="bottomLeft"
                            onResize={hideSortByPopover}
                            onScroll={hideSortByPopover}
                            scrollTarget={document.querySelector(".customContainer")}
                            portal={false}
                        >
                            <DropdownList items={getDropdownItems()} />
                        </Popover>
                    )}
                </div>
            </div>
        </TabHeader>
    );
};
