import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { ReasignTypeSelector } from "./ReasignTypeSelector/ReasignTypeSelector";
import { ReasignDates } from "./ReasignDates/ReasignDates";
import { useReasignModal } from "./useReasignModal";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { IOnReasign } from "./types";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ReasignTo } from "./ReasignTo/ReasignTo";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type ReasignModalProps = {
    onClose: () => void;
    onReasign: (reasignValue: IOnReasign) => void;
    isLoading?: boolean;
    variant?: TabHeaderVariants;
};
export const ReasignModal: FC<ReasignModalProps> = ({ onClose, onReasign, isLoading, variant = "primary" }) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const {
        handleCheckChange,
        handleErrorChange,
        handleSelectChange,
        inputError,
        values,
        selectorValue,
        handleSelect,
        dates,
        handleDateChange,
        handleReasign,
        ITEM_GROUP,
        ...options
    } = useReasignModal({ onReasign });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleReasign();
    };

    return (
        <GenericModal
            header={{ title: t(TranslationKeys.REASIGN_TASK_TITLE), onClose, variant }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.REASIGN_TASK),
                        iconRight: faArrowsRotate,
                        fullWidth: width < screenSize.TABLET,
                        type: "submit",
                        form: "reasignModal",
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: onClose,
                        variant,
                    }}
                    loading={isLoading}
                />
            }
            size="md-tall"
        >
            <form onSubmit={handleSubmit} className="reasignModal" id="reasignModal">
                <ReasignTo
                    values={values}
                    onCheckChange={handleCheckChange}
                    onSelectChange={handleSelectChange}
                    errorMessage={inputError.errorInputValue}
                    onChangeErrorMessage={() => handleErrorChange({ errorInputValue: "" })}
                    {...options}
                />
                <ReasignTypeSelector items={ITEM_GROUP} value={selectorValue} onSelect={handleSelect} />
                <ReasignDates
                    {...dates}
                    onChangeDates={handleDateChange}
                    errorStartDateMessage={inputError.errorStartDate}
                    errorEndDateMessage={inputError.errorEndDate}
                    onChangeErrorMessage={() => handleErrorChange({ errorStartDate: "" })}
                />
            </form>
        </GenericModal>
    );
};
