import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import {
    BaseAuditGroupCheckListItemErrorsModel,
    BaseAuditGroupCheckListItemGridModel,
} from "../models/BaseAuditGroupCheckListItemGridModels";
import { PaginationDefaults } from "app/shared/Constants";
import { ActionType } from "app/models/FormComponentsModel";
import { TranslationCommon } from "app/translation/translationKeys";

export const INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM: BaseAuditGroupCheckListItemModel = {
    id: 0,
    name: "",
    fK_BaseAuditGroupCheckListItemType: 0,
    ponderationLevel: 1,
    type: "",
    counter: 0,
};

export const INITIAL_FETCH_BASE_AUDIT_GROUP_CHECKLIST_ITEM: BaseAuditGroupCheckListItemGridModel = {
    pageSize: PaginationDefaults.PAGE_SIZE,
    pageIndex: 1,
    sortDirection: "default",
    sortField: "id",
};

export const headerModalOptions: Record<ActionType, string> = {
    edit: TranslationCommon.EDIT,
    create: TranslationCommon.CREATE,
};

export const confirmButtonOptions: Record<ActionType, string> = {
    edit: TranslationCommon.SAVE,
    create: TranslationCommon.CREATE,
};

export const INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_ERRORS: BaseAuditGroupCheckListItemErrorsModel = {
    nameError: "",
    fK_BaseAuditGroupCheckListItemTypeError: "",
    ponderationLevelError: "",
};
