import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import {
    BaseAuditGroupCheckListItemTypeTranslations,
    BaseAuditGroupCheckListItemTranslations,
    TranslationCommon,
} from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SelectOptions } from "app/components_v2/__inputs";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { AuditReportSFModel } from "app/pages/05-QUA/Audit/EditAudit/tabs/AuditReport/models/AuditReportSFModel";

type AuditReportFilterProps = {
    filterData: AuditReportSFModel;
    initialFilterData: AuditReportSFModel;
    isOpen: boolean;
    onCancel: () => void;
    onSave: (values: AuditReportSFModel) => void;
};

export const AuditReportFilter: FC<AuditReportFilterProps> = ({
    filterData,
    initialFilterData,
    isOpen,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState<AuditReportSFModel>(filterData);
    const [baseAuditGroupCheckListItemTypeOptions, setBaseAuditGroupCheckListItemTypeOptions] = useState<
        OptionsSearch[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getBaseAuditGroupCheckListItemTypes = async () => {
        setIsLoading(true);
        const { data, status } = await QuaSelectorService.GetBaseAuditGroupCheckListItemType();
        if (!status()) {
            setBaseAuditGroupCheckListItemTypeOptions([]);
            return;
        }

        setBaseAuditGroupCheckListItemTypeOptions([
            {
                label: t(BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_ALL_TYPES),
                value: "-1",
            },
            ...data,
        ]);
        setIsLoading(false);
    };

    const handleInputChange = (values: Partial<AuditReportSFModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave(internalFilterData);

    useEffect(() => {
        getBaseAuditGroupCheckListItemTypes();
    }, []);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant="appcc"
        >
            <SelectOptions
                isMulti={false}
                onChange={({ label, value }) =>
                    handleInputChange({
                        baseAuditGroupCheckListItemTypeId: value,
                        baseAuditGroupCheckListItemTypeLabel: label,
                    })
                }
                options={baseAuditGroupCheckListItemTypeOptions}
                label={t(BaseAuditGroupCheckListItemTranslations.TYPE_LABEL)}
                selectedValue={internalFilterData.baseAuditGroupCheckListItemTypeId}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isLoading={isLoading}
            />
        </SecondaryFilter>
    );
};
