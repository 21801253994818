import { BaseTaskListModel } from "app/models/02-TAR/BaseTask/BaseTaskListModel";
import { BaseTaskTranslations } from "app/translation/translationKeys";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faCamera, faCameraSlash, faClipboardList, faClipboardListCheck } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PlanAPPCCCounters } from "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/PlanAPPCCCounters";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";

type MobileBaseTaskRowBodyProps = {
    data: BaseTaskListModel;
    isAPPCC: boolean;
};

export const MobileBaseTaskRowBody: FC<MobileBaseTaskRowBodyProps> = ({ data, isAPPCC }) => {
    const { t } = useTranslation();
    const session = useSession();

    const { associatedTasksCount, fotoExample, isPhotoRequired, hasBaseTaskFields, hasBaseTaskCheckList, id } = data;

    return (
        <div className="mobileBaseTaskRowBody">
            <PlanAPPCCCounters
                readonly={!hasPermissionToView(session, SecScreen.TASK)}
                title={`${t(BaseTaskTranslations.BASE_TASK_ASSOCIATED_TASKS_COUNT_LABEL)}: ${formatBigNumber(
                    associatedTasksCount,
                    2
                )}`}
                to={`/${isAPPCC ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE}?baseTaskId=${id}`}
            />
            <CellIcons
                variant="ligthGray"
                icons={[
                    {
                        icon: faClipboardListCheck,
                        hidden: !hasBaseTaskCheckList,
                    },
                    {
                        icon: fotoExample?.length ? faCamera : faCameraSlash,
                        hidden: !isPhotoRequired,
                    },
                    {
                        icon: faClipboardList,
                        hidden: !hasBaseTaskFields,
                    },
                ]}
            />
        </div>
    );
};
