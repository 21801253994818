import { FC, useState } from "react";
import { HeaderTitleContext } from "../../context/headerTitleContext";
import { ProviderProps } from "../types";

export const HeaderTitleProvider: FC<ProviderProps> = ({ children }) => {
    const [headerTitle, setHeaderTitle] = useState<string>("");

    return (
        <HeaderTitleContext.Provider value={{ headerTitle, onChangeHeaderTitle: setHeaderTitle }}>
            {children}
        </HeaderTitleContext.Provider>
    );
};
