import { TaskPillAvatar } from "app/components_v2/TaskPill/type";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { ReduxSession } from "app/models/SessionModel";
import { TaskStatus } from "app/shared/Constants";

export const getAvatarInfo = (
    {
        statusCode,
        editedBy,
        asignedToUserId,
        asignedToSubdepartmentId,
        asignedToWorkingPositionId,
        asignedToDepartmentId,
        finishedByUserName,
        asignedToUserName,
        asignedToSubdepartmentName,
        asignedToWorkingPositionName,
        asignedToDepartmentName,
        profilePictureURL,
        taskType,
    }: TaskInstanceModel,
    session?: ReduxSession
): TaskPillAvatar => {
    if (taskType === "SPORADIC" && session && asignedToUserId == session.user.id)
        return {
            id: session.user.id || 0,
            name: `${session.user.firstName || ""} ${session.user.lastName || ""}`,
            url: session.user.profilePictureURL,
        };

    return {
        id:
            statusCode === TaskStatus.COMPLETED && editedBy
                ? editedBy
                : statusCode === TaskStatus.PENDING && editedBy
                ? editedBy
                : asignedToUserId || asignedToSubdepartmentId || asignedToWorkingPositionId || asignedToDepartmentId,
        name:
            statusCode === TaskStatus.COMPLETED && finishedByUserName
                ? finishedByUserName
                : statusCode === TaskStatus.PENDING && finishedByUserName
                ? finishedByUserName
                : asignedToUserName ||
                  asignedToSubdepartmentName ||
                  asignedToWorkingPositionName ||
                  asignedToDepartmentName,
        url: profilePictureURL,
    };
};
