import { FC } from "react";
type CellTitleProps = {
    title?: string | null | number;
    bolder?: boolean;
    alignCenter?: boolean;
};
export const CellTitle: FC<CellTitleProps> = ({ title, bolder, alignCenter }) => {
    return (
        <span className={`cellTitle${bolder ? "--bolder" : ""} ${alignCenter ? "cellTitle--center" : ""} text_clamp`}>
            {title}
        </span>
    );
};
