import { DataToReportItemProps } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step3/models";
import { FC } from "react";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { Input } from "app/components_v2/__inputs/Input/Input";

export const DataToReportItem: FC<DataToReportItemProps> = ({
    onChange,
    value,
    onBlur,
    disabled,
    placeholder,
    focus,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <div className="data_report_item">
            <Input
                placeholder={placeholder || t(TranslationKeys.PLACEHOLDER_REPORT_DATA)}
                value={value}
                onChange={(newValue) => onChange(newValue)}
                onBlur={() => onBlur && onBlur()}
                disabled={disabled}
                focus={focus}
                errorMessage={errorMessage}
            />
        </div>
    );
};
