import { useEffect, useState } from "react";
import { ColumnsType } from "../types";
import { isEqual } from "lodash";

type useTableProps<T> = {
    cols: ColumnsType<T>[];
    data: T[];
    clearAll?: boolean;
};

export function useTable<T>({ cols, clearAll, data }: useTableProps<T>) {
    const [columns, setColumns] = useState<ColumnsType<T>[]>(cols);
    const [checkedPage, setCheckedPage] = useState<T[]>([]);
    const [headerCheckBox, setHeaderCheckBox] = useState<boolean>(false);
    const [indeterminateCheckbox, setIndeterminateCheckbox] = useState<boolean>(false);

    const handleCheck = (value: T[]) => {
        setHeaderCheckBox(!!value.length);
        setIndeterminateCheckbox(true);
        setCheckedPage(value);
    };

    useEffect(() => {
        clearAllCheckBoxes();
    }, [clearAll]);

    const findAllAndCheck = () => {
        const arrayFiltered = checkedPage.filter((x) => {
            return !data.some((y) => isEqual(x, y));
        });
        data.map((row) => {
            arrayFiltered.push(row);
        });

        setCheckedPage(arrayFiltered);
        setHeaderCheckBox(true);
        setIndeterminateCheckbox(false);
    };

    const findAllAndUncheck = () => {
        const arrayFiltered = checkedPage.filter((x) => {
            return !data.some((y) => isEqual(x, y));
        });

        setCheckedPage(arrayFiltered);

        if (!!arrayFiltered?.length) {
            setIndeterminateCheckbox(true);
            setHeaderCheckBox(true);
        } else {
            setIndeterminateCheckbox(false);
            setHeaderCheckBox(false);
        }
    };

    const handleHeaderCheck = () => {
        if (!headerCheckBox || (indeterminateCheckbox && headerCheckBox)) {
            findAllAndCheck();
            return;
        }
        if (headerCheckBox && !indeterminateCheckbox) {
            findAllAndUncheck();
            return;
        }
    };

    const clearAllCheckBoxes = () => {
        setCheckedPage([]);
        setHeaderCheckBox(false);
        setIndeterminateCheckbox(false);
    };

    const getAllCurrentSelectedData = () => {
        //@ts-ignore
        const dataIds: number[] = data.map((el) => el.id);

        //@ts-ignore
        const checkedPageIds: number[] = checkedPage.map((el) => el.id);

        return !!dataIds.length
            ? dataIds.every((numero) => checkedPageIds.includes(numero))
            : false;
    };

    useEffect(() => {
        if (getAllCurrentSelectedData()) {
            setIndeterminateCheckbox(false);
            setHeaderCheckBox(true);

            return;
        }
        if (!!checkedPage?.length) {
            setIndeterminateCheckbox(true);
            setHeaderCheckBox(true);
        } else {
            setIndeterminateCheckbox(false);
            setHeaderCheckBox(false);
        }
    }, [data]);

    return {
        handleHeaderCheck,
        handleCheck,
        columns,
        setColumns,
        checkedPage,
        headerCheckBox,
        indeterminateCheckbox,
    };
}
