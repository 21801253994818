import Spinner from "app/components_v2/Spinner/Spinner";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import ButtonGroup from "app/components_v2/__buttons/Buttongroup/ButtonGroup";
import { SelectOptions } from "app/components_v2/__inputs";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { ComponentStatus, OptionsSearch } from "app/models/FormComponentsModel";
import RoleService from "app/services/01-SEG/RoleService";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoleWizardSteps } from "./types";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";

type Step2Props = RoleWizardSteps & {
    next: () => void;
    fetchAllModules: () => Promise<void>;
    fetchSelectedRole: (roleSelected: OptionsSearch) => Promise<void>;
};

const Step2: FC<Step2Props> = ({ onChangeData, data, next, fetchAllModules, fetchSelectedRole }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();

    const [value, setValue] = useState<string>("");
    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [roles, setRoles] = useState<RoleModel[]>([]);
    const [error, setError] = useState("");

    const { roleSelected, copyExistingRoles, permissions } = data;
    const items: ButtonGroupItem[] = [
        { label: t(TranslationCommon.NO), value: "NO" },
        { label: t(TranslationCommon.YES), value: "YES" },
    ];

    const validate = () => {
        copyExistingRoles && !roleSelected.value && setError(t(TranslationCommon.INPUT_NOT_EMPTY));
        return !copyExistingRoles || Boolean(roleSelected.value);
    };

    useEffect(() => {
        const fetch = async () => {
            const sr = await RoleService.GetRoles({
                extraParams: `customerInstanceId=${session?.user.customerInstanceId || 0}`,
            });

            if (sr.status()) {
                setStatus("complete");
                setRoles(sr.data.list);
            } else {
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
                setStatus("errored");
            }
        };

        fetch();
    }, []);

    const onSelect = async ({ value }: ButtonGroupItem) => {
        setValue(value);
        const isExistingRoles = value === "YES";
        onChangeData({
            copyExistingRoles: isExistingRoles,
            roleSelected: isExistingRoles ? roleSelected : { label: "", value: "" },
        });

        if (!isExistingRoles) {
            await fetchAllModules();
            next();
        }
    };

    const handleSelectRole = (role: OptionsSearch) => {
        onChangeData({
            roleSelected: role,
            permissions: [],
        });

        fetchSelectedRole(role);
    };

    return (
        <WizardForm
            validate={(e) => {
                e.preventDefault();
                validate() && next();
            }}
        >
            {status === "loading" ? (
                <div className="roleWizardStep2__spinner">
                    <Spinner />
                </div>
            ) : (
                <>
                    <ButtonGroup
                        items={items}
                        onSelect={onSelect}
                        label={`${t(TranslationKeys.WIZARD_ROLE_COPY_PERMISSIONS)}*`}
                        selectedValue={value !== "-1" ? value : undefined}
                    />

                    {copyExistingRoles && (
                        <SelectOptions
                            isMulti={false}
                            onChange={handleSelectRole}
                            options={roles.map(({ name, id }) => ({
                                label: name,
                                value: String(id),
                            }))}
                            errorMessage={error}
                            selectedValue={roleSelected.value !== "-1" ? roleSelected.value : undefined}
                            label={t(TranslationKeys.WIZARD_ROLE_SELECT_ROLE)}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                        />
                    )}
                </>
            )}
        </WizardForm>
    );
};

export default Step2;
