import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { BaseTaskListModel } from "app/models/02-TAR/BaseTask/BaseTaskListModel";

export default class BaseTaskService {
    // GET Single BaseTask
    public static async GetOne(id: number) {
        return FetchService.get<BaseTaskModel>({
            url: `${ApiPaths.TAR_API}/BaseTask/${id}`,
        });
    }

    // GET BaseTask List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<BaseTaskListModel>>({
            url: `${ApiPaths.TAR_API}/BaseTask`,
            paginationParams: params,
        });
    }

    // GET BaseTask List
    public static async GetTitles(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<string>>({
            url: `${ApiPaths.TAR_API}/BaseTask/Titles`,
            paginationParams: params,
        });
    }

    // GET BaseTask List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.TAR_API}/BaseTask/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST BaseTask
    public static async Save(body: BaseTaskModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.TAR_API}/BaseTask`,
            body,
        });
    }

    // PUT BaseTask
    public static async Edit(id: number, body: BaseTaskModel) {
        return FetchService.put<BaseTaskModel>({
            url: `${ApiPaths.TAR_API}/BaseTask/${id}`,
            body,
        });
    }

    // DELETE BaseTask
    public static async Delete(id: number) {
        return FetchService.delete<BaseTaskModel>({
            url: `${ApiPaths.TAR_API}/BaseTask/${id}`,
        });
    }
}
