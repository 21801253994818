import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReceivedNotifications, useSession, useTitle } from "app/hooks";
import { AlertNotificationMapper } from "app/mappers/AlertNotificationMapper";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { AlertNotificationsModal } from "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModal";
import { PrivatePaths } from "app/shared/Constants";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";

export const PendingAlertsPage: FC = () => {
    const session = useSession();
    const nav = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const { receivedNotificationList } = useReceivedNotifications({
        id: session?.user?.id,
        notSeen: true,
    });

    useEffect(() => {
        if (!receivedNotificationList.length) {
            setShowModal(false);
            nav(PrivatePaths.DASHBOARD);
        }
        setShowModal(true);
    }, [receivedNotificationList]);

    return (
        <PageContainer header={<TabHeader />}>
            {showModal && (
                <AlertNotificationsModal
                    data={AlertNotificationMapper(receivedNotificationList)}
                    closeModal={() => {
                        nav(PrivatePaths.DASHBOARD);
                        setShowModal(false);
                    }}
                />
            )}
        </PageContainer>
    );
};
