import Logo from "assets/common/4Link-white.png";
import { FC, ReactNode } from "react";

type AuthLayoutProps = {
    children: ReactNode;
};

export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
    return (
        <div className="loginContainer">
            <img src={Logo} alt="4link" className="loginContainer__logo" />
            <div className="loginContainer__container">{children}</div>
        </div>
    );
};
