import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { MeasurementUnitModel } from "app/models/05-QUA/MeasurementUnitModels/MeasurementUnitModel";

export default class MeasurementUnitService {
    // GET Single MeasurementUnit
    public static async GetOne(id: number) {
        return FetchService.get<MeasurementUnitModel>({
            url: `${ApiPaths.QUA_API}/MeasurementUnit/${id}`,
        });
    }

    // GET MeasurementUnit List
    public static async GetList(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<MeasurementUnitModel>>({
            url: `${ApiPaths.QUA_API}/MeasurementUnit`,
            paginationParams: params,
        });
    }

    // POST MeasurementUnit
    public static async Save(asset: MeasurementUnitModel) {
        return FetchService.post<MeasurementUnitModel>({
            url: `${ApiPaths.QUA_API}/MeasurementUnit`,
            body: asset,
        });
    }

    // PUT MeasurementUnit
    public static async Edit(id: number, asset: MeasurementUnitModel) {
        return FetchService.put<MeasurementUnitModel>({
            url: `${ApiPaths.QUA_API}/MeasurementUnit/${id}`,
            body: asset,
        });
    }

    // DELETE MeasurementUnit
    public static async Delete(id: number | string) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/MeasurementUnit/${id}`,
        });
    }
}
