import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { EditTask } from "app/pages/02-TAR/07-TAR-CRUD/EditTask";
import { TaskGrid } from "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/TaskGrid";
import { TaskGridProvider } from "app/pages/02-TAR/07-TAR-CRUD/state/provider/TaskGridProvider";
import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export const TaskAPPCCPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_APPCC_TASK);
    return (
        <TaskGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path={""} element={<TaskGrid />} />
                <Route
                    path="edit/:id"
                    element={<PrivateRoute component={EditTask} viewCode={SecScreen.REGISTRY_MANAGEMENT} />}
                ></Route>
                <Route
                    path="duplicate/:id"
                    element={<PrivateRoute component={EditTask} viewCode={SecScreen.REGISTRY_MANAGEMENT} />}
                ></Route>
                <Route
                    path="fastNew"
                    element={<PrivateRoute component={EditTask} viewCode={SecScreen.REGISTRY_MANAGEMENT} />}
                ></Route>
            </Routes>
        </TaskGridProvider>
    );
};
