import { FC } from "react";
import { Input } from "app/components_v2/__inputs";
import { faClose, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type TableInputTextProps = {
    onChange: (value: string) => void;
    value: string;
    fetch: () => void;
    cleanFunction: () => void;
    variant?: TabHeaderVariants;
};

export const TableInputText: FC<TableInputTextProps> = ({
    cleanFunction,
    fetch,
    onChange,
    value,
    variant = "primary",
}) => {
    return (
        <Input
            focus
            onChange={onChange}
            iconRight={!!value.length ? faSearch : faClose}
            value={value}
            onClickIcon={() => {
                !!value.length ? fetch() : cleanFunction();
            }}
            onPressEnter={() => {
                !!value.length ? fetch() : cleanFunction();
            }}
            whiteInput={variant === "appcc" ? undefined : true}
            transparent={variant === "appcc" ? undefined : true}
            onClick={(e) => e.stopPropagation()}
        />
    );
};
