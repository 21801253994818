import { FC } from "react";
import { Section } from "../Section/Section";
import { WorkerPill } from "app/components_v2/WorkerPill/WorkerPill";

type WorkerPillSectionProps = {
    show?: boolean;
};

export const WorkerPillSection: FC<WorkerPillSectionProps> = ({ show }) => {
    return (
        <Section title="Worker pill" show={show}>
            <p className="designSys__section__element__title">WorkerPill</p>
            <>
                <WorkerPill
                    starsValoration={5}
                    fullName="Roger Perez"
                    userId={123}
                    undoedTasks={0}
                    totalValoration={50}
                    trend={40}
                />
            </>
            <p className="designSys__section__element__title">WorkerPill BAD</p>
            <>
                <WorkerPill
                    starsValoration={1.5}
                    fullName="Ismael Cobo"
                    userId={174}
                    undoedTasks={4}
                    totalValoration={50}
                    trend={-500}
                />
            </>
        </Section>
    );
};
