import { useState } from "react";
import { useSession } from "app/hooks";
import TaskReviewService from "app/services/02-TAR/TaskReviewService";
import { TaskReviewUser } from "app/models/02-TAR/TaskReview";
import { fillCarrouselExtraParams } from "../utils";
import { FilterData } from "../../01-TAR-Dashboard/constants/DashboardResumeContext";
import { ComponentStatus } from "app/models/FormComponentsModel";

type IfetchCarrouselItems = {
    start?: Date;
    end?: Date;
};

export const useReviewSideBar = (filterData: FilterData, startDate: Date, endDate: Date | undefined) => {
    const session = useSession();

    const [user, setUser] = useState<TaskReviewUser[]>([]);
    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [httpError, setHttpError] = useState<number>();

    const fetchCarrouselItems = async ({ start, end }: IfetchCarrouselItems = {}) => {
        setStatus("loading");
        const startDateFetch = start || startDate;
        const endDateFetch = end || endDate;
        const extraParams = fillCarrouselExtraParams(
            "off_time",
            filterData,
            startDateFetch,
            endDateFetch,
            filterData.user,
            filterData.department,
            filterData.subdepartment
        );

        const userSr = await TaskReviewService.GetTaskReviewUserList(
            session?.workingCompany?.companyId || 0,
            session?.user.id || 0,
            extraParams
        );

        if (!userSr.status()) {
            console.error(userSr.error || "Error");
            setHttpError(userSr.httpCodeStatus);
            setStatus("errored");
            return;
        }
        setUser(userSr.data.list);

        setStatus("complete");
    };

    return {
        status,
        httpError,
        setStatus,
        fetchCarrouselItems,
        user,
    };
};
