import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type AlertNotificationsModalHeaderProps = {
    title: string;
    numberOfAlerts: number;
    actualAlertIndex: number;
};

export const AlertNotificationsModalHeader: FC<AlertNotificationsModalHeaderProps> = ({
    numberOfAlerts,
    title,
    actualAlertIndex,
}) => {
    return (
        <div className="alertNotificationHeader">
            <div className="alertNotificationHeader__container">
                <div className="alertNotificationHeader__icon__shadow-big">
                    <FontAwesomeIcon className="alertNotificationHeader__icon" icon={faTriangleExclamation} />
                </div>
                <h2 className="alertNotificationHeader__title">{title}</h2>
            </div>
            {numberOfAlerts > 1 && (
                <div className="alertNotificationHeader__total">
                    <span className="alertNotificationHeader__total__currentIndex">{actualAlertIndex}</span>
                    <span className="alertNotificationHeader__total__slashTotal">/{numberOfAlerts}</span>
                </div>
            )}
        </div>
    );
};
