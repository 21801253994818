import { FC } from "react";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/pro-solid-svg-icons";
import { StarAndValorationSkeleton } from "app/components_v2/__skelletons/StarAndValorationSkeleton/StarAndValorationSkeleton";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { Badge } from "app/components_v2/Badge/Badge";
import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type StarRatingBadgeProps = {
    starRatingAVG?: number;
    trend?: number | null;
    isLoading?: boolean;
    total?: number;
    size?: "md" | "xl";
    showFullRatingText?: boolean;
    direction?: "col" | "row";
    startSize?: "md" | "xs";
    justifyContent?: "flex-start" | "flex-end";
    onClick?: () => void;
};

const ROUNDED = 10;

export const StarRatingBadge: FC<StarRatingBadgeProps> = ({
    starRatingAVG,
    trend,
    isLoading,
    total,
    size,
    showFullRatingText = true,
    direction = "col",
    startSize = "md",
    justifyContent,
    onClick,
}) => {
    const { t } = useTranslation();

    const trendFormatted = trend ? Math.round(trend * ROUNDED) / ROUNDED : null;
    const starRatingAVGFormatted = starRatingAVG ? Math.round(starRatingAVG * ROUNDED) / ROUNDED : 0;

    const badgeTitleRecord: Record<string, string> = {
        positive: showFullRatingText
            ? t(TranslationKeys.BETTER_THAN_LAST_MONTH).replace("{1}", `${trendFormatted}%`)
            : `${trendFormatted}%`,
        negative: showFullRatingText
            ? t(TranslationKeys.WORSE_THAN_LAST_MONTH).replace("{1}", `${trendFormatted}%`)
            : `${trendFormatted}%`,
        neutral: showFullRatingText ? t(TranslationKeys.SAME_THAN_LAST_MONTH) : "0%",
    };

    const badgeIconRecord: Record<string, IconProp> = {
        positive: faArrowTrendUp,
        negative: faArrowTrendDown,
        neutral: faArrowRightLong,
    };

    const badgeVariantRecord: Record<string, BadgeVariant> = {
        positive: "green",
        negative: "red",
        neutral: "grey",
    };

    const badgeType = trendFormatted
        ? trendFormatted > 0
            ? "positive"
            : trendFormatted < 0
            ? "negative"
            : "neutral"
        : "neutral";

    if (!starRatingAVG) return null;

    return (
        <>
            {isLoading ? (
                <StarAndValorationSkeleton />
            ) : (
                <div
                    className={`starRatingBadge${direction === "col" ? "--col" : "--row"} ${
                        justifyContent ? `starRatingBadge--${justifyContent}` : ""
                    } ${!!onClick ? "pointer" : ""}`}
                    onClick={onClick}
                    role="button"
                >
                    <UserValoration
                        valoration={starRatingAVGFormatted}
                        total={total ? String(total) : undefined}
                        size={size}
                        startSize={startSize}
                        justify="start"
                    />
                    <div className="starRatingBadge__badge">
                        {trendFormatted != null && (
                            <Badge
                                theme="dark"
                                title={badgeTitleRecord[badgeType]}
                                icon={badgeIconRecord[badgeType]}
                                iconPosition="left"
                                variant={badgeVariantRecord[badgeType]}
                                ariaLabel={badgeTitleRecord[badgeType]}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
