import { FC } from "react";
import { IssueModalAuditInfo } from "app/state/context/issueModalContext/models/type";

type IssueModalHeaderProps = {
    values: IssueModalAuditInfo;
};

export const IssueModalHeader: FC<IssueModalHeaderProps> = ({ values }) => {
    const { auditChecklisItemtName, auditGroupName } = values;
    return (
        <div className="issueModalHeader">
            <p className="issueModalHeader__title">{auditGroupName}</p>
            <p className="issueModalHeader__subtitle">{auditChecklisItemtName}</p>
        </div>
    );
};
