import { BaseTaskTypeModal } from "../BaseTaskTypeModal/BaseTaskTypeModal";
import { BaseTaskTypeModel } from "app/models/02-TAR/BaseTask/BaseTaskTypeModel";
import { compressImage } from "app/helpers/images/compressImage";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { EMPTY_BASE_TASK_TYPE_OPTION } from "../../constants/editBaseTaskConstants";
import { ExtraBaseTaskDataModel } from "../../models/EditBaseTaskFormModels";
import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FC, useState } from "react";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { SelectActionsLastItemsModel, SelectActionsModel } from "app/components_v2/SelectWithActions/types";
import { SelectOptions, Switch } from "app/components_v2/__inputs";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { SwitchDragFile } from "app/components_v2/SwitchDragFile/SwitchDragFile";
import { t } from "i18next";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useToast } from "app/hooks/Toast/useToast";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import {
    BaseTaskTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import BaseTaskTypeService from "app/services/02-TAR/BaseTaskTypeService";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type BaseTaskExtraDataProps = ExtraBaseTaskDataModel & {
    variant: TabHeaderVariants;
};

export const BaseTaskExtraData: FC<BaseTaskExtraDataProps> = ({
    isDisabled,
    isPhotoRequired,
    isCritical,
    onChange,
    somethingToReportImage,
    validations,
    selectedBaseTaskType,
    setSelectedBaseTaskType,
    baseTaskTypes,
    setBaseTaskTypes,
    requiredPlanAPPCC,
    selectedPlanAPPCC,
    setSelectedPlanAPPCC,
    planAPPCCOptions,
    imageBase64,
    variant,
}) => {
    const { handleToast } = useToast();
    const { handleSuccessManager } = useSuccessManager();
    const { getErrorMessage } = useFetchErrors();

    const [isChecked, setIsChecked] = useState<boolean>(isPhotoRequired);
    const [showSaveBaseTaskTypeModal, setShowSaveBaseTaskTypeModal] = useState(false);
    const [showDeleteBaseTaskTypeModal, setShowDeleteBaseTaskTypeModal] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [baseTaskType, setBaseTaskType] = useState(selectedBaseTaskType);

    const onChangeImg = (img: string) => {
        onChange({
            imageBase64: img,
            fotoExampleId: null,
            somethingToReportImage: img,
        });
    };

    const handleChangePhoto = async (e: any) => {
        const imageCompressed = await compressImage({ file: e.target.files[0] });
        onChange({
            somethingToReportImage: imageCompressed as File,
        });
    };

    const onDeleteImg = (check: boolean) => {
        onChange({
            imageBase64: "",
            somethingToReportImage: "",
            fotoExampleId: null,
            isPhotoRequired: check,
        });
        setIsChecked((prev) => !prev);
    };

    const handleSaveBaseTaskType = (baseTaskType: BaseTaskTypeModel) => {
        setSelectedBaseTaskType({ label: baseTaskType.type, value: String(baseTaskType.id) });
        setBaseTaskTypes([...baseTaskTypes, { label: baseTaskType.type, value: String(baseTaskType.id) }]);
        setShowSaveBaseTaskTypeModal(false);
    };

    const handleEditBaseTaskType = (baseTaskType: BaseTaskTypeModel) => {
        setSelectedBaseTaskType({ label: baseTaskType.type, value: String(baseTaskType.id) });
        const updatedBaseTaskTypes = baseTaskTypes.map(({ label, value }) => ({
            label: value === String(baseTaskType.id) ? baseTaskType.type : label,
            value: value === String(baseTaskType.id) ? String(baseTaskType.id) : value,
        }));
        setBaseTaskTypes(updatedBaseTaskTypes);
        setShowSaveBaseTaskTypeModal(false);
    };

    const handleDeleteBaseTaskType = async () => {
        setIsDeleteLoading(true);

        if (!Number(baseTaskType.value)) return;
        const { status, getParsedError } = await BaseTaskTypeService.Delete(Number(baseTaskType.value));

        const error = getErrorMessage(getParsedError());
        if (!status()) {
            handleToast({
                title: t(TranslationModals.CANNOT_DELETE_BASE_TASK_TYPE_TITLE),
                subtitle: error,
                variant: "danger",
                type: "alert",
            });
            setIsDeleteLoading(false);
            return;
        }

        handleSuccessManager(t(TranslationModals.BASE_TASK_TYPE_DELETED_SUCCESSFULLY));
        const filteredBaseTaskType = baseTaskTypes.filter(({ value }) => value !== baseTaskType.value);
        setBaseTaskTypes(filteredBaseTaskType);
        setBaseTaskType(EMPTY_BASE_TASK_TYPE_OPTION);
        if (selectedBaseTaskType.value === baseTaskType.value) setSelectedBaseTaskType(EMPTY_BASE_TASK_TYPE_OPTION);

        setIsDeleteLoading(false);
        setShowDeleteBaseTaskTypeModal(false);
    };

    const selectActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: (baseTaskType) => {
                setBaseTaskType(baseTaskType);
                setShowSaveBaseTaskTypeModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.BASE_TASK_MANAGEMENT),
        },
        {
            icon: faTrash,
            onClick: (baseTaskType) => {
                setBaseTaskType(baseTaskType);
                setShowDeleteBaseTaskTypeModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.BASE_TASK_MANAGEMENT),
        },
    ];

    const lastItems: SelectActionsLastItemsModel[] = [
        {
            title: t(TranslationKeys.BASE_TASK_TYPE_ADD_NEW_TYPE_TITLE),
            icon: faPlus,
            onClick: () => {
                setBaseTaskType(EMPTY_BASE_TASK_TYPE_OPTION);
                setShowSaveBaseTaskTypeModal(true);
            },
            hidden: !hasPermissionToEdit(SecScreen.BASE_TASK_MANAGEMENT),
        },
    ];

    return (
        <div className="baseTaskExtraData">
            <WhiteBoxCollapsable
                collapsableOptions={{ title: t(TranslationKeys.DATA_TO_REPORT_EXTRA_DATA), border: "none", variant }}
                whiteBoxOptions={{ fullWidth: true }}
            >
                <div className="baseTaskExtraData__container">
                    {showSaveBaseTaskTypeModal && (
                        <BaseTaskTypeModal
                            onSaveBaseTaskType={handleSaveBaseTaskType}
                            onEditBaseTaskType={handleEditBaseTaskType}
                            baseTaskType={baseTaskType}
                            onCancel={() => {
                                setBaseTaskType(selectedBaseTaskType);
                                setShowSaveBaseTaskTypeModal(false);
                            }}
                            variant={variant}
                        />
                    )}
                    {showDeleteBaseTaskTypeModal && (
                        <ConfirmModal
                            onClose={() => setShowDeleteBaseTaskTypeModal(false)}
                            onConfirm={handleDeleteBaseTaskType}
                            onConfirmText={t(TranslationCommon.DELETE)}
                            onCloseText={t(TranslationCommon.CANCEL)}
                            title={t(TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_TITLE)}
                            description={t(TranslationModals.BASE_TASK_TYPE_DELETE_MODAL_DESCRIPTION).replace(
                                "{0}",
                                baseTaskType.label || ""
                            )}
                            isLoading={isDeleteLoading}
                            type="delete"
                            variant={variant}
                        />
                    )}
                    <SelectWithActions
                        selectedValue={selectedBaseTaskType.value}
                        actions={selectActions}
                        lastItems={lastItems}
                        options={baseTaskTypes}
                        onChange={setSelectedBaseTaskType}
                        label={t(BaseTaskTranslations.BASE_TASK_TYPE_LABEL)}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        disabled={isDisabled}
                        errorMessage={validations.baseTaskType}
                    />
                    {requiredPlanAPPCC && (
                        <SelectOptions
                            selectedValue={selectedPlanAPPCC.value !== "-1" ? selectedPlanAPPCC.value : undefined}
                            options={planAPPCCOptions}
                            onChange={setSelectedPlanAPPCC}
                            label={t(BaseTaskTranslations.BASE_TASKS_PLAN_APPCC_LABEL)}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            isMulti={false}
                            disabled={isDisabled}
                            errorMessage={validations.planAPPCC}
                        />
                    )}
                    <SwitchDragFile
                        image={imageBase64 || somethingToReportImage}
                        isChecked={isChecked}
                        onDeleteImage={onDeleteImg}
                        handleChangePhoto={handleChangePhoto}
                        onChangePhoto={onChangeImg}
                        disabledCheckbox={isDisabled}
                        disabledImage={isDisabled}
                        isImageEditable={true}
                    />
                    <Switch
                        checked={isCritical}
                        onChange={(value) => onChange({ criticalTask: value })}
                        disabled={isDisabled}
                        label={t(TranslationKeys.CRITICAL_TASK)}
                    />
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
