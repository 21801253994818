import { FC } from "react";
import { faArrowLeft, faArrowRight, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { GenericModalFooter } from "../__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

export type WizardsFooterProps = {
    validateNextStep: boolean;
    setCounter: (value: number) => void;
    totalSteps: number;
    counter: number;
    isDisabledByDefault?: boolean;
    isLoading?: boolean;
    finishButtonText?: string;
    variant?: TabHeaderVariants;
    onFinish?: () => void;
    prev?: () => void;
    onClose?: () => void;
};

const WizardsFooter: FC<WizardsFooterProps> = ({
    validateNextStep,
    setCounter,
    totalSteps,
    counter,
    isDisabledByDefault,
    isLoading,
    finishButtonText,
    variant = "primary",
    prev,
    onClose,
}) => {
    const { t } = useTranslation();
    const finalStepText = finishButtonText || "Finish";
    return (
        <GenericModalFooter
            confirmButton={{
                iconRight: counter < totalSteps ? faArrowRight : faCheck,
                text: counter < totalSteps ? t(TranslationCommon.NEXT) : finalStepText,
                form: "wizard__form",
                type: "submit",
                disabled: isDisabledByDefault ? !validateNextStep : false,
                variant,
            }}
            closeButton={
                counter > 1
                    ? {
                          iconLeft: faArrowLeft,
                          text: t(TranslationCommon.PREVIOUS),
                          buttonType: "secondary",
                          onClick: () => (prev ? prev() : setCounter(counter - 1)),
                          variant,
                      }
                    : onClose
                    ? {
                          text: t(TranslationCommon.CANCEL),
                          buttonType: "secondary",
                          onClick: onClose,
                          variant,
                      }
                    : undefined
            }
            loading={isLoading}
        />
    );
};

export default WizardsFooter;
