import { FC } from "react";
import { Navigate, Route, Routes } from "react-router";
import { PublicPaths, SecScreen } from "../../../shared/Constants";
import PrivateRoute from "../../../routes/PrivateRoute";
import GroupCompany from "./Components/GroupCompany";
import { EditCompany } from "./Components/Company/EditCompany";

const GroupCompanyPage: FC = () => {
    return (
        <>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route
                    path=""
                    element={<PrivateRoute component={GroupCompany} viewCode={SecScreen.CENTERS_MANAGEMENT} />}
                ></Route>
                <Route
                    path="edit/:companyId"
                    element={<PrivateRoute component={EditCompany} viewCode={SecScreen.CENTERS_MANAGEMENT} />}
                />
            </Routes>
        </>
    );
};

export default GroupCompanyPage;
