import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "app/components_v2/Badge/Badge";
import { OptionCompanyImage } from "app/components_v2/OptionCompany/OptionCompanyImage/OptionCompanyImage";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { TranslationCommon } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
type MobileCompanyGridBodyProps = {
    company: CompanyModel;
};
export const MobileCompanyGridBody: FC<MobileCompanyGridBodyProps> = ({ company }) => {
    const { t } = useTranslation();
    const nav = useNavigate();

    const { name, logoFileURL, address, id, closeCompanies } = company;
    const isClosed = closeCompanies?.length && closeCompanies[0].closeDate != null;

    return (
        <div className="mobileCompanyGridBody" onDoubleClick={() => nav(`edit/${id}`)}>
            <div className="mobileCompanyGridBody__container">
                <OptionCompanyImage name={name} logoImage={logoFileURL} />
                <div className="mobileCompanyGridBody__body">
                    <p className="mobileCompanyGridBody__body__title">{name}</p>
                    <p className="mobileCompanyGridBody__body__address">{address}</p>
                </div>
            </div>
            <div className="mobileCompanyGridBody__icon" onClick={() => nav(`edit/${id}`)}>
                <Badge
                    title={isClosed ? t(TranslationCommon.CLOSED) : t(TranslationCommon.OPEN)}
                    variant={isClosed ? "red" : "green"}
                />
                <FontAwesomeIcon className="mobileCompanyGridBody__icon__edit" icon={faPen} />
            </div>
        </div>
    );
};
