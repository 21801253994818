import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { SquareButton } from "app/components_v2/__buttons/SquareButton/SquareButton";
import { faKeyboard, faQrcode } from "@fortawesome/pro-regular-svg-icons";

export const SquareButtonSection: FC<SectionsShowProp> = ({ show }) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    return (
        <Section show={show} title="Square Button">
            <div className="designSystemSquareButton">
                <SquareButton isFocused={isFocused} onClick={() => setIsFocused(true)} icon={faQrcode}>
                    ESCANEAR
                </SquareButton>
                <SquareButton isFocused={!isFocused} onClick={() => setIsFocused(false)} icon={faKeyboard}>
                    INTRODUCIR
                </SquareButton>
            </div>
        </Section>
    );
};
