import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSensorBattery } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorBattery";
import { useSensorSignal } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorSignal";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { AssetTranslations, AutomaticRecordTranslations } from "app/translation/translationKeys";
import { PrivatePaths } from "app/shared/Constants";
import { AssetFormInfoModel } from "app/models/05-QUA/AssetModels";
import { Switch, Input } from "app/components_v2/__inputs";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { Badge } from "app/components_v2/Badge/Badge";
import { useNavigate } from "react-router-dom";

type AssetSensorInfoProps = {
    allowSensorSerialNumber: boolean;
    battery: number | undefined;
    errorSerialNumber: string;
    id: number | undefined;
    isDisabled: boolean;
    onInputChange: (fields: Partial<AssetFormInfoModel>) => void;
    sensorSerialNumber: string;
    signalLevel: number | undefined;
};

export const AssetSensorInfo: FC<AssetSensorInfoProps> = ({
    allowSensorSerialNumber,
    battery,
    errorSerialNumber,
    id,
    isDisabled,
    onInputChange,
    sensorSerialNumber,
    signalLevel,
}) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    const [showNoDataPlaceholder, setShowNoDataPlaceholder] = useState<boolean>(true);

    return (
        <div className="assetSensorInfo">
            <Switch
                checked={allowSensorSerialNumber}
                onChange={(isChecked) => onInputChange({ allowSensorSerialNumber: isChecked })}
                label={t(AssetTranslations.ASSET_LINKED_TO_A_SENSOR)}
            />
            {allowSensorSerialNumber && (
                <div className="assetSensorInfo__container">
                    <div>
                        <Input
                            value={sensorSerialNumber}
                            onChange={(value) => {
                                onInputChange({ sensorSerialNumber: value });
                                setShowNoDataPlaceholder(false);
                            }}
                            placeholder={t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_LABEL)}
                            disabled={isDisabled}
                            errorMessage={errorSerialNumber}
                            max={8}
                        />
                    </div>
                    {battery && signalLevel ? (
                        <div className="assetSensorInfo__container__content">
                            <div className="assetSensorInfo__container__content__badges">
                                <Badge
                                    size="lg"
                                    title={battery ? batteryBadgeTitle(battery) : "-"}
                                    variant={battery ? batteryBadgeColor(battery) : "grey"}
                                    icon={battery ? batteryBadgeIcon(battery) : undefined}
                                    iconPosition="right"
                                />
                                <Badge
                                    size="lg"
                                    title={signalLevel ? signalBadgeTitle(signalLevel) : "-"}
                                    variant={signalLevel ? signalBadgeColor(signalLevel) : "grey"}
                                    icon={signalLevel ? signalBadgeIcon(signalLevel) : undefined}
                                    iconPosition="right"
                                />
                            </div>
                            <Button
                                size="xs"
                                text={t(AssetTranslations.ASSET_REDIRECT_AUTOMATIC_RECORD_LABEL)}
                                iconRight={faArrowRight}
                                onClick={() => nav(`/${PrivatePaths.AUTOMATIC_RECORDS}?assetId=${id}`)}
                                buttonType="button"
                                variant="appcc"
                                type="secondary"
                            />
                        </div>
                    ) : (
                        sensorSerialNumber.length == 8 &&
                        showNoDataPlaceholder && (
                            <p className="assetSensorInfo__container__content__noData">
                                {t(AssetTranslations.ASSET_SENSOR_NO_DATA)}
                            </p>
                        )
                    )}
                </div>
            )}
        </div>
    );
};
