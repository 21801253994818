import { FC, useState } from "react";
import { INITIAL_USER_GRID_SORT_DIRECTION, INITIAL_USER_GRID_SORT_FIELD } from "../../constants/userGridConstants";
import { ProviderProps } from "app/state/Providers/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { UserGridContext } from "../context/userGridContext";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { TranslationKeys } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export const UserGridProvider: FC<ProviderProps> = ({ children }) => {
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [pageIndex, setPageIndex] = useState<number>(1);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [sortField, setSortField] = useState<string>(INITIAL_USER_GRID_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_USER_GRID_SORT_DIRECTION);
    const [query, setQuery] = useState<string>("");

    const { handleCompanyChange, selectCompany, filterIcon } = useSelectCompanyTable({
        defaultCompany: {
            label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }),
            value: "-1",
        },
    });

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    return (
        <UserGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                currentTab,
                onChangeCurrentTab: setCurrentTab,
                sortDirection,
                sortField,
                onSortChange,
                selectCompany,
                onCompanyChange: handleCompanyChange,
                query,
                onQueryChange: setQuery,
                filterIcon,
            }}
        >
            {children}
        </UserGridContext.Provider>
    );
};
