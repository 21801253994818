import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PublicPaths } from "../shared/Constants";
import { useSelector } from "react-redux";
import { IAppState } from "../state/reducer";
import { NotLoggedInRouteProps } from "./interfaces";

// handle the public logued routes
const PublicRoute: React.FC<NotLoggedInRouteProps> = ({
    component: Component,
}) => {
    const location = useLocation();
    const isLoggedIn = useSelector<IAppState, boolean>(
        (state) => state.isLoggedIn
    );

    if (!isLoggedIn) return <Component />;

    return (
        <Navigate
            to={{
                pathname: PublicPaths.ROOT,
            }}
            state={{ from: location.pathname }}
        />
    );
};

export default PublicRoute;
