import { FC } from "react";

export type IssueBodyAssetFieldProps = {
    fieldValue?: string;
    name: string;
    min: number;
    max: number;
    measureUnit: string;
};

export const IssueBodyAssetField: FC<IssueBodyAssetFieldProps> = ({ max, measureUnit, min, fieldValue, name }) => {
    const issueAssetField = `${name}: ${
        fieldValue?.length ? `${fieldValue} ${measureUnit}` : ""
    } (${min} / ${max} ${measureUnit})`;

    return <li className="issueBodyAssetField">{issueAssetField}</li>;
};
