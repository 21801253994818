import { SporadicTaskModalOptions } from "../types";

export const sporadicTaskOptions: SporadicTaskModalOptions = {
    feedback: {
        editable: false,
        isVisible: false,
        showValoratedBy: false,
    },
    isEditable: true,
};
