import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { GroupedTaskField } from "./GroupedTaskField/GroupedTaskField";

type TaskFieldsProps = {
    fields: TaskInstanceFieldModel[];
    customSetFields: (e: TaskInstanceFieldModel) => void;
    readOnly?: boolean;
    customErrors?: Map<number | string, string> | undefined;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
    statusCode: string;
};

export const TaskFields: FC<TaskFieldsProps> = ({
    fields,
    customSetFields,
    readOnly = false,
    customErrors,
    errorRef,
    disabled,
    statusCode,
}) => {
    const { t } = useTranslation();
    const title = t(TranslationKeys.TASK_DETAIL_DATA_TO_REPORT_TITLE);

    const groupByAssetId = () => {
        const groupedObjects: TaskInstanceFieldModel[][] = [];
        fields.forEach((field) => {
            const id = field.asset2?.id;
            if (id == null) return;
            if (!groupedObjects[id]) groupedObjects[id] = [];

            groupedObjects[id].push(field);
        });

        const fieldsMapped = Object.values(groupedObjects).map((values) =>
            values.sort((field1, field2) => {
                if (field1.dynamicFieldType === "NUMBER" && field2.dynamicFieldType === "TEXT") {
                    return -1;
                } else if (field1.dynamicFieldType === "TEXT" && field2.dynamicFieldType === "NUMBER") {
                    return 1;
                }
                return 0;
            })
        );
        return fieldsMapped;
    };

    return (
        <div className="taskFields__container">
            <Collapsable title={title}>
                <div className="taskFields__content">
                    {groupByAssetId().map((groupedFields, i) => (
                        <GroupedTaskField
                            key={i}
                            groupedFields={groupedFields}
                            customSetFields={customSetFields}
                            readOnly={readOnly}
                            customErrors={customErrors}
                            disabled={disabled}
                            errorRef={errorRef}
                            statusCode={statusCode}
                        />
                    ))}
                </div>
            </Collapsable>
        </div>
    );
};
