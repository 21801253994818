import { FC } from "react";
import { Section } from "../Section/Section";
import { FilterButton } from "app/components_v2/__buttons/FilterButton/FilterButton";
import { faCheck, faClock, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

type Props = {
    show?: boolean;
};

export const FilterButtonSection: FC<Props> = ({ show }) => {
    return (
        <Section title="Filter Button" show={show}>
            <>
                <h2>Aquí va un Filter Button</h2>
            </>
            <div className="filterbuttonsdesingsystem">
                <FilterButton counter="10" subtitle="Vencidas" onClick={() => {}} isActive={true} />
                <FilterButton counter="0" subtitle="A punto de vencer" onClick={() => {}} />
                <FilterButton counter="99" subtitle="Pendientes" onClick={() => {}} isActive={true} />
                <FilterButton counter="33" subtitle="Realizadas" onClick={() => {}} />
            </div>
            <>
                <h2>Aquí va un Filter Button con iconos a la izquierda</h2>
            </>
            <div className="filterbuttonsdesingsystem">
                <FilterButton counter="10" subtitle="Vencidas" onClick={() => {}} isActive={true} icon={faClock} />
                <FilterButton
                    counter="0"
                    subtitle="A punto de vencer"
                    onClick={() => {}}
                    icon={faExclamationTriangle}
                />
                <FilterButton counter="99" subtitle="Pendientes" onClick={() => {}} isActive={true} icon={faClock} />
                <FilterButton counter="33" subtitle="Realizadas" onClick={() => {}} icon={faCheck} />
            </div>
            <>
                <h2>Aquí va un Filter Button con iconos derecha</h2>
            </>
            <div className="filterbuttonsdesingsystem">
                <FilterButton
                    counter="10"
                    subtitle="Vencidas"
                    onClick={() => {}}
                    isActive={true}
                    iconPosition="right"
                    icon={faClock}
                />
                <FilterButton
                    counter="0"
                    subtitle="A punto de vencer"
                    onClick={() => {}}
                    iconPosition="right"
                    icon={faExclamationTriangle}
                />
                <FilterButton
                    counter="99"
                    subtitle="Pendientes"
                    onClick={() => {}}
                    isActive={true}
                    icon={faClock}
                    iconPosition="right"
                />
                <FilterButton
                    counter="33"
                    subtitle="Realizadas"
                    onClick={() => {}}
                    iconPosition="right"
                    icon={faCheck}
                />
            </div>
            <>
                <h2>Aquí va un Filter Button con 2 contadores</h2>
            </>
            <div className="filterbuttonsdesingsystem">
                <FilterButton counter="10" counter2="12" subtitle="Vencidas" onClick={() => {}} isActive={true} />
                <FilterButton counter="0" counter2="9" subtitle="A punto de vencer" onClick={() => {}} />
                <FilterButton counter="99" counter2="99" subtitle="Pendientes" onClick={() => {}} isActive={true} />
                <FilterButton counter="33" counter2="35" subtitle="Realizadas" onClick={() => {}} />
            </div>
        </Section>
    );
};
