import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseAuditGroupCheckListItemTypeTranslations, TranslationCommon } from "app/translation/translationKeys";
import { INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE } from "../constants/baseAuditGroupCheckListItemTypeConstants";
import { ActionType } from "app/models/FormComponentsModel";
import { headerModalOptions, confirmButtonOptions } from "../../constants/BaseAuditGroupCheckListItemConstants";
import { BaseAuditGroupCheckListItemTypeModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemTypeModels/BaseAuditGroupCheckListItemTypeModel";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Input } from "app/components_v2/__inputs/Input/Input";

type BaseAuditGroupCheckListItemTypeModalProps = {
    type: ActionType;
    onSubmit: (type: ActionType, item: BaseAuditGroupCheckListItemTypeModel) => void;
    onClose: () => void;
    isLoading: boolean;
    baseAuditGroupCheckListItemTypeNameError: string;
    baseAuditGroupCheckListItemType?: BaseAuditGroupCheckListItemTypeModel;
};

const formId = "baseAuditGroupCheckListItemTypeForm";

export const BaseAuditGroupCheckListItemTypeModal: FC<BaseAuditGroupCheckListItemTypeModalProps> = ({
    type,
    onSubmit,
    onClose,
    isLoading,
    baseAuditGroupCheckListItemTypeNameError,
    baseAuditGroupCheckListItemType,
}) => {
    const { t } = useTranslation();

    const [internalData, setInternalData] = useState<BaseAuditGroupCheckListItemTypeModel>(
        baseAuditGroupCheckListItemType || INITIAL_BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE
    );

    const handleChange = (value: Partial<BaseAuditGroupCheckListItemTypeModel>) =>
        setInternalData((prev) => ({ ...prev, ...value }));

    return (
        <GenericModal
            header={{
                title: t(headerModalOptions[type]),
                onClose: onClose,
                variant: "appcc",
            }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(confirmButtonOptions[type]),
                        type: "submit",
                        form: formId,
                        variant: "appcc",
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        onClick: onClose,
                        variant: "appcc",
                    }}
                    loading={isLoading}
                />
            }
            size="md"
        >
            <form
                className="baseAuditGroupCheckListItemModal"
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(type, internalData);
                }}
                id={formId}
            >
                <Input
                    label={t(
                        BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL
                    )}
                    placeholder={t(
                        BaseAuditGroupCheckListItemTypeTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_TYPE_NAME_LABEL
                    )}
                    value={internalData.name}
                    errorMessage={baseAuditGroupCheckListItemTypeNameError}
                    onChange={(value) => handleChange({ name: value })}
                />
            </form>
        </GenericModal>
    );
};
