import { FC } from "react";
import { RankingType } from "../types";
import { useWindowSize } from "usehooks-ts";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { UserType } from "app/shared/types/UserTypes";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { RankingPillInfoAvatarName } from "./RankingPillInfoAvatarName/RankingPillInfoAvatarName";
import { screenSize } from "app/shared/Constants";

export type RankingPillInfoProps = {
    type: RankingType;
    hasCounters: boolean;
    name: string;
    colorId: number;
    profileURL?: string | null;
    nameInitials?: string;
    userType?: UserType;
    starsValoration?: number | null;
    totalTasks: number;
    trend: number;
    numberOfStars?: number;
    showUserType?: boolean;
    jobDescription?: string;
    pillStarsBottom?: boolean;
};

export const RankingPillInfo: FC<RankingPillInfoProps> = ({
    hasCounters,
    name,
    colorId,
    profileURL,
    nameInitials,
    userType,
    totalTasks,
    starsValoration,
    trend,
    numberOfStars,
    showUserType,
    jobDescription,
    pillStarsBottom,
}) => {
    const { width } = useWindowSize();
    return (
        <>
            <div
                className={`rankingPill__info__avatar${!hasCounters ? "--noCounters" : ""}`}
                data-testid="rankingPillInfoAvatar"
            >
                <Avatar
                    avatarName={name}
                    colorId={colorId}
                    size="md"
                    img={profileURL || undefined}
                    clamp
                    nameInitials={nameInitials}
                />
                <RankingPillInfoAvatarName
                    name={name}
                    userType={userType}
                    jobDescription={jobDescription}
                    showUserType={showUserType}
                />
            </div>
            {starsValoration != null && (
                <div
                    className={`rankingPill${pillStarsBottom ? "__info__starsBottom" : "__info__stars"}${
                        !hasCounters ? "--noCounters" : ""
                    }`}
                    data-testid="rankingPillInfoAvatarStars"
                >
                    <UserValoration
                        valoration={starsValoration}
                        variant="gray"
                        total={String(totalTasks)}
                        justify={width < screenSize.TABLET ? "end" : "start"}
                        roundedTrend={trend}
                        maxStars={numberOfStars}
                        showTrend={false}
                    />
                </div>
            )}
        </>
    );
};
