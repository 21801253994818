import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { InputWithPopover } from "app/components_v2/__inputs/InputWithPopover/InputWithPopover";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import { useDebounceValue } from "usehooks-ts";
import { useEffectAfterFirstRender } from "app/hooks/useEffectAfterFirstRender";
import { TaskDataInputPopoverChildren } from "../../07-TAR-CRUD/EditTask/Components/TaskDataInputPopoverChildren/TaskDataInputPopoverChildren";

export const InputWithPopoverSection: FC<SectionsShowProp> = ({ show }) => {
    const [value, setValue] = useState<string>("");
    const [options, setOptions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [debouncedValue, setDebounceValue] = useDebounceValue("", 300);

    const onChange = (inputValue: string) => {
        if (!inputValue.length) setOptions([]);
        else setIsLoading(true);
        setDebounceValue(inputValue);
        setValue(inputValue);
    };

    const getOptions = async () => {
        if (!value.length) return;
        setIsLoading(true);
        const { data, status } = await BaseTaskService.GetTitles({ pageIndex: 0, pageSize: 5, query: value });

        if (!status()) {
            setOptions([]);
            setIsLoading(false);
            return;
        }

        setOptions(data.list);
        setIsLoading(false);
    };

    useEffectAfterFirstRender(() => {
        if (!value.length) return;

        getOptions();
    }, [debouncedValue]);

    return (
        <Section show={show} title="Input with Popover">
            <div style={{ width: "100%", maxWidth: "250px" }}>
                <InputWithPopover onChange={onChange} value={value} label="Título de la tarea" isPopoverVisible>
                    <TaskDataInputPopoverChildren isAPPCC={false} isLoading={isLoading} options={options} />
                </InputWithPopover>
            </div>
        </Section>
    );
};
