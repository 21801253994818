import { Navigate, Route, Routes } from "react-router-dom";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";
import { EmailDistributionListGrid } from "./EmailDistributionListGrid/EmailDistributionListGrid";

const EmailDistributionListPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />}></Route>
            <Route
                path={""}
                element={<PrivateRoute component={EmailDistributionListGrid} viewCode={SecScreen.DISTRIBUTION_LIST} />}
            />
        </Routes>
    );
};

export default EmailDistributionListPage;
