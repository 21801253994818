import { FC } from "react";
import { TaskPillBodyRatingSkelleton } from "../../TaskPillBodyRatingSkelleton/TaskPillBodyRatingSkelleton";

type Props = {
    showStarRating?: boolean;
};

export const TaskPillBodySkelleton: FC<Props> = ({ showStarRating }) => {
    return (
        <div className="taskPillBody-skelleton">
            <div className="taskPillBody-skelleton__header">
                <div className="taskPillBody-skelleton__title"></div>
                {showStarRating && <TaskPillBodyRatingSkelleton />}
            </div>
            <div className="taskPillBody-skelleton__content">
                <div className="taskPillBody-skelleton__content__description"></div>
                <div className="taskPillBody-skelleton__content__container">
                    <div className="taskPillBody-skelleton__content__container__button"></div>
                    <div className="taskPillBody-skelleton__content__container__button"></div>
                </div>
            </div>
        </div>
    );
};
