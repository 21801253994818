import { DashboardEntityCardModel } from "app/models/03-REP/DashboardCard/DashboardEntityCardModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class DashboardCardService {
    public static async GetUserById(id: string | number) {
        return FetchService.get<DashboardEntityCardModel>({
            url: `${ApiPaths.REP_API}/DashboardCard/UserCard/${id}`,
        });
    }
    public static async GetCompanyById(id: string | number, params?: PaginationParams) {
        return FetchService.get<DashboardEntityCardModel>({
            paginationParams: params,
            url: `${ApiPaths.REP_API}/DashboardCard/CompanyCard/${id}`,
        });
    }
    public static async GetDepartmentById(id: string | number) {
        return FetchService.get<DashboardEntityCardModel>({
            url: `${ApiPaths.REP_API}/DashboardCard/DepartmentCard/${id}`,
        });
    }
    public static async GetSubdepartmentById(id: string | number) {
        return FetchService.get<DashboardEntityCardModel>({
            url: `${ApiPaths.REP_API}/DashboardCard/SubdepartmentCard/${id}`,
        });
    }
}
