import React, { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { MobileFooter } from "app/components_v2/MobileFooter";

export const MobileFooterSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Mobile Footer" show={show}>
            <MobileFooter systemNotificationsCount={999} setSystemNotificationsCount={() => {}} />
        </Section>
    );
};
