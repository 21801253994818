import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useIssueAssetLastStep } from "./hooks/useIssueAssetLastStep";
import { TranslationKeys } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect } from "app/components_v2/__inputs";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { IssueAssetStepTitle } from "../IssueAssetStepTitle/IssueAssetStepTitle";

export type IssueAssetLastStepProps = {
    onFinish: () => void;
    onChange: (supervisors: OptionModel[]) => void;
    supervisors: OptionModel[];
    supervisorOptions: OptionModel[];
};

export const IssueAssetLastStep: FC<IssueAssetLastStepProps> = ({
    onChange,
    onFinish,
    supervisors,
    supervisorOptions,
}) => {
    const { t } = useTranslation();
    const { errorSupervisor, handleAddSupervisors, handleDeleteSupervisors, validate } = useIssueAssetLastStep({
        onChange,
        onFinish,
        supervisors,
        supervisorOptions,
    });

    return (
        <WizardForm validate={validate}>
            <IssueAssetStepTitle>{t(TranslationKeys.ISSUE_ASSET_LAST_STEP_TITLE)}</IssueAssetStepTitle>
            <OptionsOutsideSelect
                onChange={handleAddSupervisors}
                onDeleteItem={handleDeleteSupervisors}
                options={supervisorOptions}
                values={supervisors}
                label={t(TranslationKeys.SUPERVISOR_ISSUE_LABEL)}
                isSearchable
                autoInitials
                placeholder={t(TranslationKeys.SUPERVISOR_ISSUE_PLACEHOLDER)}
                errorMessage={errorSupervisor}
            />
        </WizardForm>
    );
};
