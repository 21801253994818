import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

export const ErrorIcon = () => {
    return (
        <div className="errorIcon">
            <FontAwesomeIcon icon={faCircleExclamation} className={`errorIcon__icon`} />
        </div>
    );
};
