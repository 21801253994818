import { AssetStatusPostScanned } from "../../types";
import { PrivatePaths } from "app/shared/Constants";
import { SensorModalTranslations } from "app/translation/translationKeys";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useNavigate } from "react-router-dom";
import { useSensorQr } from "app/hooks/Sensor/useSensorQr";
import { useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import AssetService from "app/services/05-QUA/AssetService";

type IuseSensorNotLinkedModal = {
    sensorSerialNumber: string;
    onClose: () => void;
};

export const useSensorNotLinkedModal = ({ onClose, sensorSerialNumber }: IuseSensorNotLinkedModal) => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
    const [assetStatusPostScanned, setAssetStatusPostScanned] = useState<AssetStatusPostScanned | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isAssetReplacingLoading, setIsAssetReplacingLoading] = useState<boolean>(false);

    const { getAsset, asset: assetSearched } = useSensorQr({
        onError: () => {
            setShowReplaceModal(false);
            setAssetStatusPostScanned(null);
            setShowConfirmModal(false);
        },
        onSuccess: (isLinked) => {
            if (!isLinked) {
                setShowReplaceModal(false);
                setAssetStatusPostScanned(null);
                setShowConfirmModal(false);
                handleToast({
                    title: t(SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_TITLE),
                    subtitle: t(SensorModalTranslations.SENSOR_NOT_LINKED_TO_SENSOR_NOT_LINKED_ERROR_TOAST_DESCRIPTION),
                    type: "alert",
                    variant: "danger",
                });
                return;
            }
            setAssetStatusPostScanned("LINKED");
            setShowConfirmModal(true);
        },
    });

    const handleRedirectCreateAsset = () => {
        nav(`/${PrivatePaths.ASSETS}/new?serialNumber=${sensorSerialNumber}`);
        onClose();
    };

    const handleScanSensorQr = async (qrCode: string) => {
        if (sensorSerialNumber.toLocaleLowerCase() === qrCode.toLocaleLowerCase()) {
            handleToast({
                title: t(SensorModalTranslations.SENSOR_SAME_QR_ERROR_TITLE),
                type: "alert",
                variant: "danger",
            });
            setShowReplaceModal(false);
            return;
        }
        await getAsset(qrCode);
    };

    const handleCloseReplaceScanModal = () => {
        setShowConfirmModal(false);
        setShowReplaceModal(false);
    };

    const handleReplaceSensor = async () => {
        if (!assetSearched) return;
        setIsAssetReplacingLoading(true);
        const { status, getParsedError } = await AssetService.ReplaceAssetsSerialNumbers(
            assetSearched.sensorSerialNumber,
            sensorSerialNumber
        );

        if (!status()) {
            setShowConfirmModal(false);
            setShowReplaceModal(false);
            setIsAssetReplacingLoading(false);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        handleToast({
            title: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE),
            type: "alert",
            variant: "success",
        });
        setShowConfirmModal(false);
        setShowReplaceModal(false);
        setIsAssetReplacingLoading(false);
        onClose();
    };

    return {
        assetStatusPostScanned,
        handleCloseReplaceScanModal,
        handleRedirectCreateAsset,
        handleReplaceSensor,
        handleScanSensorQr,
        isAssetReplacingLoading,
        setShowReplaceModal,
        showConfirmModal,
        showReplaceModal,
        assetSearched,
    };
};
