import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Switch } from "app/components_v2/__inputs";

export const SwitchSection: FC<SectionsShowProp> = ({ show }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    return (
        <Section title="Switches" show={show}>
            <>
                <p className="designSys__section__element__title">Toggle :</p>
                <Switch
                    checked={isChecked}
                    onChange={setIsChecked}
                    disabled={false}
                    label="Toggle label"
                />
            </>
            <>
                <p className="designSys__section__element__title">Disabled Toggle :</p>
                <Switch
                    checked={isChecked}
                    onChange={setIsChecked}
                    disabled={true}
                    label="Disabled Toggle label"
                />
            </>
            <>
                <p className="designSys__section__element__title">Toggle reverse :</p>
                <Switch
                    checked={isChecked}
                    onChange={setIsChecked}
                    disabled={false}
                    label="Toggle reverse label"
                    reverse
                />
            </>
            <>
                <p className="designSys__section__element__title">Disabled Toggle reverse :</p>
                <Switch
                    checked={isChecked}
                    onChange={setIsChecked}
                    disabled={true}
                    label="Disabled Toggle reverse label"
                    reverse
                />
            </>
            <div
                style={{ backgroundColor: "#15547e", display: "flex", height: "50px", gap: "20px", alignItems:"center" }}
            >
                <p className="designSys__section__element__title"> Toggle inverted :</p>
                <Switch
                    checked={isChecked}
                    onChange={setIsChecked}
                    label="Inverted Toggle"
                    inverted
                />
            </div>
        </Section>
    );
};
