import { useState } from "react";

export const useModal = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleClose = () => setIsModalOpen(false);
    const handleOpen = () => setIsModalOpen(true);
    const handleToggle = () => setIsModalOpen((prev) => !prev);
    const handleClick = (value: boolean) => setIsModalOpen(value);

    return { isModalOpen, handleClose, handleOpen, handleToggle, handleClick };
};
