import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { FC } from "react";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { useTranslation } from "react-i18next";
import { IssueTranslation } from "app/translation/translationKeys";

type IssueClassificationProps = {
    issueClassification: IssueClassificationModel;
};

export const IssueClassification: FC<IssueClassificationProps> = ({ issueClassification }) => {
    const { t } = useTranslation();

    const { name } = issueClassification;
    return (
        <div className="issueBodyDescription">
            <IssueBodyTitle>{t(IssueTranslation.ISSUE_CLASSIFICATION)}</IssueBodyTitle>
            <p className="issueBodyDescription__description">{name}</p>
        </div>
    );
};
