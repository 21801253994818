import { FC } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";

type ProgresssBarWithLabelProps = {
    percentage: number;
    leftLabel?: string;
    leftLabelFullWidth?: boolean;
    barLabel?: string;
    barLabelSize?: "xs" | "sm" | "md";
    color?: "green" | "yellow" | "red" | "blue";
    barSize?: "sm" | "md";
};

export const ProgresssBarWithLabel: FC<ProgresssBarWithLabelProps> = ({
    percentage,
    leftLabel,
    leftLabelFullWidth,
    barLabel,
    color = "blue",
    barSize = "sm",
    barLabelSize = "md",
}) => {
    return (
        <div className="progresssBarWithLabel">
            {leftLabel && (
                <p
                    className={`progresssBarWithLabel__leftLabel ${
                        leftLabelFullWidth ? "progresssBarWithLabel__leftLabel--fullWidth" : ""
                    }`}
                >
                    {leftLabel}
                </p>
            )}
            <div className="progresssBarWithLabel__bar">
                <ProgressBar color={color} percentage={percentage} size={barSize} />
                {barLabel && <p className={`progresssBarWithLabel__bar__barLabel--${barLabelSize}`}>{barLabel}</p>}
            </div>
        </div>
    );
};
