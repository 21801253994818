import { FC } from "react";
import { ActionButtonsModel } from "../TableTabHeader/TableTabHeader";
import { Icon } from "app/components_v2/Icon/Icon";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type TableActionButtonsProps = {
    actionButtons: ActionButtonsModel[];
    variant?: TabHeaderVariants;
};

export const TableActionButtons: FC<TableActionButtonsProps> = ({ actionButtons, variant }) => {
    return (
        <>
            {actionButtons.map(
                ({ icon, onClick, hidden, inputComponent, disabled, showMarkableIcon, title }, i) =>
                    !hidden && (
                        <div key={i}>
                            {inputComponent && inputComponent.show ? (
                                inputComponent.component
                            ) : (
                                <>
                                    {showMarkableIcon && (
                                        <div className="markableIcon__notification tableTab__header__actions__markableIcon"></div>
                                    )}
                                    <Icon
                                        disabled={disabled}
                                        icon={icon}
                                        onClick={onClick}
                                        title={title}
                                        theme={variant === "appcc" ? "light" : "dark"}
                                    />
                                </>
                            )}
                        </div>
                    )
            )}
        </>
    );
};
