import { FC } from "react";
import { useDateRange } from "app/pages/03-REP/01-AnalystRankingPage/Hooks/useDateRange";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";
import DateRangeSkelleton from "app/components_v2/__skelletons/DateRangeSkelleton/DateRangeSkelleton";
import { AvatarRatingSkelleton } from "app/components_v2/__skelletons/AvatarRatingSkelleton/AvatarRatingSkelleton";
import Spinner from "app/components_v2/Spinner/Spinner";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { DateRangePicker } from "app/components_v2/__inputs";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { SupervisorValorationDetail } from "app/components_v2/ValorationDetail/SupervisorValorationDetail";
import { WorkerValorationDetail } from "app/components_v2/ValorationDetail/WorkerValorationDetail";
import { AnalistWorkerDetailModel } from "app/models/03-REP/Analytics/AnalistWorkerDetailModel";

type ValorationDetailModalProps = {
    showModal: (state: boolean) => void;
    data?: AnalistSupervisorDetailModel | AnalistWorkerDetailModel;
    startDate: Date;
    endDate: Date;
    onDateChange: (value: Partial<DatesReviewTask>) => void;
    isLoading?: boolean;
    isSupervisor: boolean;
};

export const ValorationDetailModal: FC<ValorationDetailModalProps> = ({
    showModal,
    data,
    startDate,
    endDate,
    onDateChange,
    isLoading,
    isSupervisor,
}) => {
    const { startDateInput, endDateInput, onChange } = useDateRange({
        initialStartDate: startDate,
        initialEndDate: endDate,
        onDateChange: ({ startDate, endDate }) => onDateChange({ startDate, endDate }),
    });

    const handleInputChange = (dates: Date[]) => onChange(dates);

    return (
        <GenericModal
            size="lg"
            header={{
                title: "Tu puntuación",
                headerImage: "/headerBackground.jpg",
                onClose: () => showModal(false),
                headerChildren: (
                    <div className="SuperVisorValorationDetailModal__header">
                        <div className="SuperVisorValorationDetailModal__header__item">
                            {isLoading ? (
                                <AvatarRatingSkelleton />
                            ) : (
                                <>
                                    <Avatar
                                        colorId={1}
                                        size="2xl"
                                        clamp
                                        variant="secondary"
                                        img={
                                            (isSupervisor
                                                ? (data as AnalistSupervisorDetailModel).entityHeaderInfoModel?.photoURL
                                                : (data as AnalistWorkerDetailModel).workerHeaderInfoModel?.photoURL) ||
                                            undefined
                                        }
                                        nameInitials={
                                            (isSupervisor
                                                ? (data as AnalistSupervisorDetailModel).entityHeaderInfoModel
                                                      ?.nameInitials
                                                : (data as AnalistWorkerDetailModel).workerHeaderInfoModel
                                                      ?.nameInitials) || undefined
                                        }
                                    />

                                    <div>
                                        <p className="SuperVisorValorationDetailModal__avatar__name text_clamp text_clamp-1 capitalize">
                                            {(isSupervisor
                                                ? (data as AnalistSupervisorDetailModel).entityHeaderInfoModel?.name
                                                : (data as AnalistWorkerDetailModel).workerHeaderInfoModel?.name) ||
                                                undefined}
                                        </p>
                                        <div>
                                            <UserValoration
                                                valoration={
                                                    (isSupervisor
                                                        ? (data as AnalistSupervisorDetailModel).entityHeaderInfoModel
                                                              ?.avg
                                                        : (data as AnalistWorkerDetailModel).workerHeaderInfoModel
                                                              ?.avg) ||
                                                    undefined ||
                                                    0
                                                }
                                                roundedTrend={Number(
                                                    Number(
                                                        (isSupervisor
                                                            ? (data as AnalistSupervisorDetailModel)
                                                                  .entityHeaderInfoModel?.trend
                                                            : (data as AnalistWorkerDetailModel).workerHeaderInfoModel
                                                                  ?.trend) ||
                                                            undefined ||
                                                            0
                                                    ).toFixed(0)
                                                )}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="flex">
                            {isLoading ? (
                                <DateRangeSkelleton />
                            ) : (
                                <DateRangePicker
                                    inverted
                                    onChange={handleInputChange}
                                    startDate={startDateInput}
                                    endDate={endDateInput}
                                    showMoreOptions
                                    range
                                    showToday={false}
                                    disableFutureDates
                                />
                            )}
                        </div>
                    </div>
                ),
            }}
        >
            {isLoading ? (
                <div className="flex--center fullHeight">
                    <Spinner />
                </div>
            ) : isSupervisor ? (
                <SupervisorValorationDetail data={data as AnalistSupervisorDetailModel} />
            ) : (
                <WorkerValorationDetail workerDetail={data as AnalistWorkerDetailModel} />
            )}
        </GenericModal>
    );
};
