import { AriaLabels } from "app/translation/translationKeys";
import { EDIT_IMAGE_ACTIVE_MENU_ICON_COLOR } from "../constants/EditImageConstants";
import { EditImageAction } from "../types";
import { EditImageIcon } from "../EditImageIcon/EditImageIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    faArrowTurnLeft,
    faPen,
    faPenRuler,
    faReflectHorizontal,
    faRotate,
    faScissors,
} from "@fortawesome/pro-regular-svg-icons";

type EditImageMenuProps = {
    currentAction: EditImageAction | null;
    isDrawed: boolean;
    hslColor: string;
    onClickIcon: (action: EditImageAction) => void;
    onUndoDraw: () => void;
};

export const EditImageMenu: FC<EditImageMenuProps> = ({
    currentAction,
    isDrawed,
    hslColor,
    onClickIcon,
    onUndoDraw,
}) => {
    const { t } = useTranslation();

    const showIcon = (action: EditImageAction) => currentAction === null || currentAction === action;

    return (
        <div className="editImageMenu">
            <div className="editImageMenu__container">
                {isDrawed && currentAction !== "CROPPER" && (
                    <EditImageIcon
                        icon={faArrowTurnLeft}
                        onClick={onUndoDraw}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_UNDO)}
                    />
                )}
                {currentAction === null && (
                    <EditImageIcon
                        icon={faReflectHorizontal}
                        onClick={() => onClickIcon("MIRROR")}
                        activeColor={EDIT_IMAGE_ACTIVE_MENU_ICON_COLOR}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_MIRROR)}
                    />
                )}
                {currentAction === null && (
                    <EditImageIcon
                        icon={faRotate}
                        onClick={() => onClickIcon("ROTATE")}
                        activeColor={EDIT_IMAGE_ACTIVE_MENU_ICON_COLOR}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_ROTATE)}
                    />
                )}
                {showIcon("CROPPER") && (
                    <EditImageIcon
                        icon={faScissors}
                        onClick={() => onClickIcon("CROPPER")}
                        active={currentAction === "CROPPER"}
                        activeColor={EDIT_IMAGE_ACTIVE_MENU_ICON_COLOR}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_CROPPER)}
                    />
                )}
                {showIcon("FORMS") && (
                    <EditImageIcon
                        icon={faPenRuler}
                        onClick={() => onClickIcon("FORMS")}
                        active={currentAction === "FORMS"}
                        activeColor={hslColor}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_FORMS)}
                    />
                )}
                {showIcon("DRAW") && (
                    <EditImageIcon
                        icon={faPen}
                        onClick={() => onClickIcon("DRAW")}
                        active={currentAction === "DRAW"}
                        activeColor={hslColor}
                        ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_DRAW)}
                    />
                )}
            </div>
        </div>
    );
};
