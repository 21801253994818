import { FC } from "react";
import { Section } from "../Section/Section";
import { RadioGroup } from "app/components_v2/RadioButton/RadioGroup";

type Props = {
    show?: boolean;
};

export const RadioSection: FC<Props> = ({ show }) => {
    const optionsRadio = [
        { label: "Apple", sublabel: "AppleSublabel", value: "Apple", disabled: true },
        { label: "Frase medianamente larga", value: "Pear" },
        { label: "Frase medianamente larga", sublabel: "AppleSublabel", value: "Pear22" },
        { label: "Orange", value: "Orange", sublabel: "AppleSublabel", disabled: false },
    ];
    return (
        <Section title="radio" show={show}>
            <RadioGroup
                options={optionsRadio}
                onChange={(value) => console.log(value)}
                defaultValue="Apple"
                direction="column"
            />
        </Section>
    );
};
