import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "../Avatar/Avatar";

type UsersLoginItemProps = {
    userId: number;
    profilePhotoUrl: string | null;
    fullName: string;
    username: string;
    onDeleteItem: (userId: number) => void;
    onClick: (username: string) => void;
};

export const UsersLoginItem: FC<UsersLoginItemProps> = ({
    onDeleteItem,
    userId,
    profilePhotoUrl,
    fullName,
    onClick,
    username,
}) => {
    return (
        <div className="usersLoginItem" onClick={() => onClick(username)}>
            <div className="usersLoginItem__avatar">
                <Avatar colorId={userId} avatarName={fullName} img={profilePhotoUrl || ""} size="sm" />
                <p className="usersLoginItem__avatar__title">{fullName}</p>
            </div>
            <div
                className="usersLoginItem__icon"
                onClick={(e) => {
                    e.stopPropagation();
                    onDeleteItem(userId);
                }}
            >
                <FontAwesomeIcon icon={faTrash} className="usersLoginItem__svg" />
            </div>
        </div>
    );
};
