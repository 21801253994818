import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import { TaskDetailDynamicFields } from "app/components_v2/TaskDetailDynamicFields/TaskDetailDynamicFields";
import { DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";

type PreviewDynamicFieldsModalProps = {
    onClose: () => void;
    dynamicfields: DynamicFieldsModel[];
};

export const PreviewDynamicFieldsModal: FC<PreviewDynamicFieldsModalProps> = ({ onClose, dynamicfields }) => {
    const { t } = useTranslation();

    return (
        <GenericModal
            size="md-tall"
            footer={
                <GenericModalFooter
                    closeButton={{
                        text: t(TranslationCommon.CLOSE),
                        onClick: onClose,
                    }}
                />
            }
        >
            <TaskDetailDynamicFields
                taskinstanceFields={dynamicfields.map(
                    ({ dynamicFieldsType, isRequired, label, taskFieldOptions }, i) => ({
                        dynamicFieldType: dynamicFieldsType,
                        id: i,
                        isRequired,
                        label,
                        taskInstanceFieldValues:
                            dynamicFieldsType === "DROPDOWN"
                                ? taskFieldOptions.map((option, i) => ({
                                      id: i,
                                      label: option.label,
                                      value: "",
                                      max: null,
                                      measureUnit: "",
                                      min: null,
                                  }))
                                : [{ id: i, label: label, value: "", max: null, measureUnit: "", min: null }],
                        taskInstanceId: i,
                        taskFieldOptions: taskFieldOptions.map((option, i) => ({ id: i, label: option.label })),
                        isHidden: false,
                    })
                )}
                readonly={false}
                errors={[]}
            />
        </GenericModal>
    );
};
