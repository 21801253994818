import { isHoursOutOfRange, validateHours } from "app/helpers/__validates/validateTaskHours";
import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import { useToast } from "app/hooks/Toast/useToast";
import {
    EditTaskDisabledFieldsModel,
    editTasktValidation,
    TaskfieldOptionsError,
} from "app/models/02-TAR/Task/EditTask";
import { AllSteps, taskHoursModel } from "app/models/02-TAR/TaskWizard";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsEnabledModel } from "../../WizardTasks/Step2/models";
import { INITIAL_EDIT_TASK_DISABLEDS, INITIAL_EDIT_TASK_FORM_VALUE_ERRORS } from "../constants/editTaskConstants";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { DateRange } from "app/models/utilities";

export const useEditTask = (initialStepsValues: AllSteps) => {
    const [data, setData] = useState(initialStepsValues);
    const [dataCopy, setDataCopy] = useState(initialStepsValues);
    const { handleToast } = useToast();
    const { filterTaskFieldsWithoutErrorMessage, taskfieldOptionsDropdown } = useDynamicFieldsErrorHandler();
    const [baseTaskDisabledFields, setBaseTaskDisabledFields] =
        useState<EditTaskDisabledFieldsModel>(INITIAL_EDIT_TASK_DISABLEDS);

    const [validations, setValidations] = useState<editTasktValidation>(INITIAL_EDIT_TASK_FORM_VALUE_ERRORS);

    const [isEnabled, setIsEnabled] = useState<IsEnabledModel>({
        workingPosition: true,
        user: false,
        subdepartment: false,
    });

    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    const { t } = useTranslation();

    function updateFields(fields: Partial<AllSteps>) {
        if (!!fields.checkList || !!fields.dynamicFields || !!fields.reportData)
            setValidations((prev) => ({ ...prev, reportData: "" }));
        setData((prev: AllSteps) => {
            return { ...prev, ...fields };
        });
    }

    const handleErrorsChange = (values: Partial<editTasktValidation>) => {
        setValidations((prev) => ({ ...prev, ...values }));
    };

    // Comprueba que las horas sean diferentes
    const isHoursEquals = (hours: taskHoursModel[]) => {
        for (let i = 0; i < hours.length; i++) {
            for (let x = 0; x < hours.length; x++) {
                if (i === x) continue;
                if (
                    new Date(hours[i].hour).getMinutes() === new Date(hours[x].hour).getMinutes() &&
                    new Date(hours[i].hour).getHours() === new Date(hours[x].hour).getHours()
                )
                    return true;
            }
        }

        return false;
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;

        handleErrorsChange(INITIAL_EDIT_TASK_FORM_VALUE_ERRORS);

        if (!data.taskTitle.length) {
            handleErrorsChange({ taskTitle: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!data.companyForTask.value.length) {
            handleErrorsChange({ companyForTask: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (data.companyForTask.value.length && !data.DepartmentForTask.value.length) {
            handleErrorsChange({ DepartmentForTask: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (
            data.companyForTask.worksWithQr &&
            (!data.userForTask.value.length || data.userForTask.value === "-1") &&
            (!data.workingPositionForTask.value.length || data.workingPositionForTask.value === "-1")
        ) {
            handleErrorsChange({ userOrQRForTask: t(TranslationKeys.MUST_SELECT_USER_OR_QR_BODY) });
            check = false;
        }

        const isValidTemporality = baseTaskDisabledFields.disabledType === "NONE" ? validateTemporality() : true;

        // Si la validación de la termporalidad está bien no sobrescribas la validación
        check = isValidTemporality ? check : false;

        if (baseTaskDisabledFields.disabledType === "PERIODICAL") {
            check = validateTaskEnds();
        }

        if (data.reportType === "DATA") {
            const isAtLeastOneValue = data.reportData.filter(({ assetId }) => !assetId).filter(({ value }) => value);

            if (!isAtLeastOneValue.length) {
                handleErrorsChange({ reportData: t(TranslationCommon.INPUT_NOT_EMPTY) });
                check = false;
            }
        }

        if (data.reportType === "ASSET") {
            const isAtLeastOneValue = data.reportData
                .filter(({ assetId }) => assetId)
                .filter(({ value, isDeleted }) => value && !isDeleted);

            if (!isAtLeastOneValue.length) {
                handleErrorsChange({ reportData: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
                check = false;
            }
        }

        if (data.reportType === "DYNAMIC_FIELD") {
            const dynamicFields = data.dynamicFields.filter(({ isDeleted }) => !isDeleted);
            if (!dynamicFields.length) {
                handleErrorsChange({ reportData: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
                check = false;
            }

            const dynamicfieldsErrors = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);
            handleErrorsChange({ dynamicFields: dynamicfieldsErrors });

            if (dynamicfieldsErrors.length) check = false;
        }

        if (data.reportType === "CHECKLIST") {
            const checklist = data.checkList.filter(({ isDeleted }) => !isDeleted);
            const errorChecklist: TaskfieldOptionsError[] = checklist.map(({ id, name }) => ({
                errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                id,
            }));

            handleErrorsChange({ checklist: errorChecklist });
        }

        if (!check) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                subtitle: t(TranslationModals.FAILED_SAVE_TASK_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }
        if (isHoursOutOfRange(data.taskHours)) {
            setShowModalHoursOutOfTime(true);
            return false;
        }

        return true;
    };

    const validateTemporality = (): boolean => {
        if (data.temporalityType === "PERIODICAL") return validatePeriodicalTemporality();
        if (data.temporalityType === "ONE_DAY") return validateOneDayTemporality();
        if (data.temporalityType === "SPORADIC") return validateSporadicTemporality();
        if (data.temporalityType === "RANGE_DATES") return validateDateRangeTemporality();
        if (data.temporalityType === "NONE") return validateNoneTemporality();
        return true;
    };

    const validateNoneTemporality = () => {
        handleErrorsChange({ temporalityType: t(TranslationCommon.SELECT_OPTION) });
        return false;
    };

    const validateTaskEnds = () => {
        let check = true;
        if (data.finish.checked === "el" && !data.finish.value) {
            handleErrorsChange({ finishEl: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (data.finish.checked === "after" && typeof data.finish.value === "number" && data.finish.value <= 0) {
            handleErrorsChange({ finishResp: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        return check;
    };

    const validatePeriodicalTemporality = (): boolean => {
        let check = true;

        if (!data.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        check = validateTaskEnds();

        if (!data.recurringTask) return check;

        const taskHoursErrors = validateHours(data.taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        if (isHoursEquals(data.taskHours)) {
            handleErrorsChange({ taskHours: t(TranslationKeys.INVALID_HOURS_SAME_HOURS) });
            check = false;
        }
        if (
            data.recurringTask.isRecurrent &&
            data.recurringTask.value === "custom" &&
            data.recurringTask.custom.repeatEvery <= 0
        ) {
            handleErrorsChange({ customReps: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        if (
            data.recurringTask.isRecurrent &&
            data.recurringTask.value === "custom" &&
            data.recurringTask.custom.customValue === "week" &&
            !data.recurringTask.custom.days.some(({ isActive }) => isActive === true)
        ) {
            handleErrorsChange({ customDaysBubles: t(TranslationKeys.SELECT_ONE_DAY) });
            check = false;
        }

        if (
            data.recurringTask.isRecurrent &&
            data.recurringTask.value === "custom" &&
            data.recurringTask.custom.customValue === "everyMonth" &&
            !data.recurringTask.custom.selectedOptions.value.length
        ) {
            handleErrorsChange({ customMonth: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }
        return check;
    };

    const validateOneDayTemporality = (): boolean => {
        let check = true;
        if (!data.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        const taskHoursErrors = validateHours(data.taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        return check;
    };

    const validateSporadicTemporality = (): boolean => {
        let check = true;

        if (!data.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        return check;
    };

    const validateDateRangeTemporality = (): boolean => {
        let check = true;
        const taskTemporalityDateRangeFiltered = data.taskTemporalityDateRange.filter(({ isDeleted }) => !isDeleted);
        if (!taskTemporalityDateRangeFiltered.length) {
            handleErrorsChange({ dateRange: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }
        const taskTemporalityDateRange: DateRange[] = taskTemporalityDateRangeFiltered.map(({ startDate, endDate }) => [
            new Date(startDate),
            new Date(endDate),
        ]);
        if (hasOverlappingRanges(taskTemporalityDateRange)) {
            handleErrorsChange({ dateRange: t(TaskTranslations.DATE_OVERLAPING) });
            check = false;
        }
        return check;
    };

    const setEnableUserDept = (values: IsEnabledModel) => setIsEnabled(values);

    const onConfirmModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    const onCloseModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    return {
        data,
        onChange: updateFields,
        setData,
        validate,
        validations,
        isEnabledUserDept: isEnabled,
        setEnableUserDept,
        dataCopy,
        setDataCopy,
        onConfirmModalHoursOutOfTime,
        onCloseModalHoursOutOfTime,
        showModalHoursOutOfTime,
        setValidations,
        baseTaskDisabledFields,
        setBaseTaskDisabledFields,
    };
};
