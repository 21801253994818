import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { AlertPill } from "app/components_v2/AlertPill/AlertPill";

export const AlertPillSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="alertPill" show={show}>
            <AlertPill
                date={new Date()}
                text="Lorem ipsum dolor sit amet consectetur."
                fullName="Ismael Cobo"
                userId={174}
                type="programmed"
            />
            <AlertPill
                date={new Date()}
                text="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
                fullName="Ismael Cobo"
                userId={174}
                type="programmed"
                status="readed"
            />
            <AlertPill
                date={new Date()}
                text="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
                fullName="Ismael Cobo"
                userId={174}
                type="programmed"
                status="sended"
            />
            <AlertPill
                date={new Date()}
                text="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
                fullName="Ismael Cobo"
                userId={174}
            />
        </Section>
    );
};
