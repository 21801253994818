import { ErrorResponse } from "./ErrorResponse";
import { GenericErrors } from "../../shared/Constants";

export default class ServiceResponse<T> {
    public data!: T;
    public error!: string;
    public errorResponse!: ErrorResponse;
    public httpCodeStatus!: number;
    public servicePath!: string;

    public status = () => {
        return (
            (this.error === "" || this.error === null || this.error === undefined) &&
            (this.errorResponse === undefined || this.errorResponse === null)
        );
    };

    public addError = (error?: string) => {
        if (error) this.error = error;
        else this.error = `${GenericErrors.WRONG} with: ${this.servicePath}`;
    };

    public getParsedError = () => {
        return this.errorResponse?.errors[0]?.errorMessage || this.errorResponse?.errors[0]?.errorCode || this.error;
    };
}
