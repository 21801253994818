import { useToast } from "../Toast/useToast";

export const useSuccessManager = () => {
    const { handleToast } = useToast();

    const handleSuccessManager = (message: string) => {
        handleToast({
            title: message,
            variant: "success",
            type: "alert",
        });
    };

    return { handleSuccessManager };
};
