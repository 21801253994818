import { SortedTypeModel } from "app/components_v2/Table/types";
import { AuditGridSFModel } from "../state/context/auditGridContext";
import { AuditInstanceCountersDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceCountersDto";
import { TranslationKeys } from "app/translation/translationKeys";

export const INITIAL_AUDIT_GRID_SORT_FIELD = "OpenUser.FirstName";
export const INITIAL_AUDIT_GRID_SORT_DIRECTION: SortedTypeModel = "default";
export const INITIAL_AUDIT_GRID_SECONDARY_FILTER: AuditGridSFModel = {
    query: "",
    companyId: "-1",
    companyName: TranslationKeys.ALL_COMPANIES,
    closedDate: undefined,
    closedDate2: undefined,
    openDate: undefined,
    openDate2: undefined,
};
export const INITIAL_AUDIT_GRID_COUNTERS: AuditInstanceCountersDto = {
    inProgressCounter: 0,
    completedCounter: 0,
    reviewedCounter: 0,
    closedCounter: 0,
};
