import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { TaskPill } from "app/components_v2/TaskPill/TaskPill";
import { TaskPillAvatar } from "app/components_v2/TaskPill/type";

export const TaskpillSection: FC<SectionsShowProp> = ({ show }) => {
    const userTaskPill: TaskPillAvatar = {
        name: "Sara Treballadora",
        url: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww&w=1000&q=80",
        id: 1,
    };
    const [stars, setStars] = useState(1);
    const [state, setState] = useState<"PENDING" | "TIMED_OUT" | "OUT_OF_TIME" | "IN_TIME" | "NEAR_END">("PENDING");

    const incomingComment = {
        name: "Antoni Supervisor",
        date: "27 Marzo",
        comment: "La freidora aún estaba sucia.",
    };

    const handleStars = (stars: number) => {
        setStars(stars);
    };

    return (
        <Section title="TaskPill" show={show}>
            <p className="designSys__section__element__title">Taskpill sense estrelles</p>
            <TaskPill
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={0}
                onStarsChange={handleStars}
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill sense estrelles disabled</p>
            <TaskPill
                user={userTaskPill}
                hour="15:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={0}
                onStarsChange={handleStars}
                startHour={"14:00"}
                disabled
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill disabled pero completed</p>
            <TaskPill
                user={userTaskPill}
                hour="12:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={"IN_TIME"}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={0}
                onStarsChange={handleStars}
                startHour={"15:00"}
                disabled
                completedDate="12:00"
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill amb fotos</p>
            <TaskPill
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={0}
                onStarsChange={handleStars}
                carrouselPhotos={[
                    {
                        badgeTitle: "",
                        photo: "https://cdn.pixabay.com/photo/2023/05/28/13/15/helicopter-8023696_640.jpg}",
                    },
                    { badgeTitle: "", photo: "https://cdn.pixabay.com/photo/2023/06/01/12/06/snow-8033482__340.jpg}" },
                    {
                        badgeTitle: "",
                        photo: "https://cdn.pixabay.com/photo/2023/05/16/14/17/sail-boat-7997799_640.jpg}",
                    },
                ]}
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill amb estrelles</p>
            <TaskPill
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={stars}
                onStarsChange={handleStars}
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill amb fotos disabled</p>
            <TaskPill
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={0}
                onStarsChange={handleStars}
                carrouselPhotos={[
                    {
                        badgeTitle: "",
                        photo: "https://cdn.pixabay.com/photo/2023/05/28/13/15/helicopter-8023696_640.jpg",
                    },
                ]}
                disabled
                taskTemporalityType="PERIODICAL"
            />

            <p className="designSys__section__element__title">Supervisor taskpill</p>
            <TaskPill
                isSupervisor
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={stars}
                onStarsChange={handleStars}
                taskTemporalityType="PERIODICAL"
            />
            <p className="designSys__section__element__title">Taskpill amb fotos i estrelles</p>
            <TaskPill
                user={userTaskPill}
                hour="14:00"
                title="Limpiar freidora"
                date="27 Marzo"
                state={state}
                onPillClick={() => {
                    console.log("redirect");
                }}
                isCritical
                comment={incomingComment}
                stars={stars}
                onStarsChange={handleStars}
                carrouselPhotos={[
                    {
                        badgeTitle: "",
                        photo: "https://cdn.pixabay.com/photo/2023/05/28/13/15/helicopter-8023696_640.jpg}",
                    },
                    { badgeTitle: "", photo: "https://cdn.pixabay.com/photo/2023/06/01/12/06/snow-8033482__340.jpg}" },
                    {
                        badgeTitle: "",
                        photo: "https://cdn.pixabay.com/photo/2023/05/16/14/17/sail-boat-7997799_640.jpg}",
                    },
                ]}
                taskTemporalityType="PERIODICAL"
            />
        </Section>
    );
};
