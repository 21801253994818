import { Button } from "app/components_v2/__buttons/Button/Button";
import { ComponentStatus } from "app/models/FormComponentsModel";
import { FC, FormEvent, useEffect, useState } from "react";
import { GroupCompanyCollapse } from "./GroupCompanyCollapse";
import { GroupCompanyErrorFormValues, GroupCompanyFormValues } from "./GroupCompanyModal/types";
import { GroupCompanyModal } from "./GroupCompanyModal/GroupCompanyModal";
import { GroupCompanyModel } from "app/models/01-SEG/GroupCompany/GroupCompanyModel";
import { GroupCompanyService } from "app/services";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import {
    INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES,
    INITIAL_GROUP_COMPANY_FORM_VALUES,
} from "../constants/groupCompanyConstants";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { RegexPatterns, SecScreen } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import Loading from "app/components_v2/Loading/Loading";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const GroupCompany: FC = () => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { translateCustomerTypeKeys, translateCompany } = useCustomerTypeTranslation();

    useTitle(translateCustomerTypeKeys(TranslationTitles.GROUP_COMPANY_PAGE_TITLE, { firstReplace: "PLURAL" }));

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [groups, setGroups] = useState<GroupCompanyModel[]>([]);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [formValues, setFormValues] = useState<GroupCompanyFormValues>(INITIAL_GROUP_COMPANY_FORM_VALUES);
    const [groupErrorMessages, setGroupErrorMessages] = useState<GroupCompanyErrorFormValues>(
        INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES
    );
    const handleInputChange = (values: Partial<GroupCompanyFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<GroupCompanyErrorFormValues>) => {
        setGroupErrorMessages((prev) => ({ ...prev, ...values }));
    };

    const getGroupCompanies = async () => {
        const sr = await GroupCompanyService.GetData();
        if (!sr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });

            setGroups([]);
            setStatus("complete");
            return;
        }

        setGroups(sr.data.list);
        setStatus("complete");
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (validate()) return;

        setIsCreating(true);

        const newGroup: GroupCompanyModel = {
            ...formValues,
            id: 0,
            customerInstanceId: session?.user.customerInstanceId || 0,
            isActive: true,
            isDeletable: false,
        };
        const { status, getParsedError } = await GroupCompanyService.Save(newGroup);

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), type: "alert", variant: "danger" });
            setIsCreating(false);
            setShowCreateModal(false);
            return;
        }
        getGroupCompanies();
        setIsCreating(false);
        setShowCreateModal(false);
    };

    const validate = () => {
        let isErrored = false;
        handleErrorChange(INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES);

        if (!formValues.name.length) {
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isErrored = true;
        }
        if (!formValues.nif.length) {
            handleErrorChange({ errorNif: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isErrored = true;
        }

        if (!!formValues.nif.length && !RegexPatterns.nifAndNie.test(formValues.nif)) {
            handleErrorChange({ errorNif: t(TranslationCommon.INVALID_NIF) });
            isErrored = true;
        }

        return isErrored;
    };

    const onCloseCreateGroupModal = () => {
        setFormValues(INITIAL_GROUP_COMPANY_FORM_VALUES);
        setShowCreateModal(false);
        setGroupErrorMessages(INITIAL_GROUP_COMPANY_ERROR_FORM_VALUES);
    };

    useEffect(() => {
        getGroupCompanies();
    }, []);

    if (status === "loading")
        return (
            <PageContainer paddingTop>
                <Loading />
            </PageContainer>
        );

    return (
        <PageContainer paddingTop>
            {showCreateModal && (
                <GroupCompanyModal
                    values={formValues}
                    onSubmit={handleSubmit}
                    isLoading={isCreating}
                    errorMesages={groupErrorMessages}
                    onChange={handleInputChange}
                    onClose={onCloseCreateGroupModal}
                    isEdit={false}
                />
            )}
            <div className="gridPage__title">
                <p>{translateCompany("PLURAL")}</p>
                {hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT) && (
                    <Button
                        text={t(TranslationKeys.NEW_GROUP_COMPANY)}
                        onClick={() => setShowCreateModal(true)}
                        iconLeft={faPlus}
                    />
                )}
            </div>
            <div className="groupCompany">
                {groups.length === 0 && (
                    <p>
                        {translateCustomerTypeKeys(TranslationKeys.NO_GROUPS_TEXT, {
                            firstReplace: "SINGULAR",
                            secondReplace: "PLURAL",
                            isGenderSensitive: true,
                        })}
                    </p>
                )}
                {groups.map((group, i) => (
                    <GroupCompanyCollapse
                        model={group}
                        key={group.id}
                        afterDelete={getGroupCompanies}
                        showOpened={i === 0}
                    />
                ))}
            </div>
        </PageContainer>
    );
};

export default GroupCompany;
