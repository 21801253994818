import { FC, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type FilterButtonProps = {
    counter: string;
    counter2?: string;
    onClick?: () => void;
    isActive?: boolean;
    subtitle?: string;
    iconPosition?: "left" | "right";
    icon?: IconProp;
};

export const FilterButton: FC<FilterButtonProps> = ({
    onClick,
    counter,
    isActive = false,
    counter2,
    subtitle,
    iconPosition = "left",
    icon,
}) => {
    const [isFilterActive, setIsFilterActive] = useState<boolean>(isActive);

    useEffect(() => {
        setIsFilterActive(isActive);
    }, [isActive]);

    const handleClick = () => {
        onClick && onClick();
        setIsFilterActive(!isFilterActive);
    };

    return (
        <div className="filterButtonsContainer">
            <button className={`filterButton${isFilterActive ? "--active" : ""}`} onClick={handleClick}>
                <div className="filterButton__container">
                    {icon && iconPosition === "left" && (
                        <FontAwesomeIcon icon={icon} className="filterButton__icon" title="left filter buttoon icon" />
                    )}
                    <div className="filterButton__counterContainer">
                        <p className={`filterButton__counterContainer__counter1${counter2 ? "--bolder" : ""}`}>
                            {counter}
                        </p>
                        {!!counter2?.length && <p className="filterButton__counterContainer__counter2">/{counter2}</p>}
                    </div>
                    {icon && iconPosition === "right" && (
                        <FontAwesomeIcon icon={icon} className="filterButton__icon" title="right filter buttoon icon" />
                    )}
                </div>
            </button>
            {subtitle && <p className={`filterButton__subtitle${isFilterActive ? "--active" : ""}`}>{subtitle}</p>}
        </div>
    );
};
