import { DynamicFieldsType } from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const useGetDynamicFieldsTranslations = () => {
    const { t } = useTranslation();

    const getDynamicFieldPlaceholderTranslation = (dynamicFieldType: DynamicFieldsType, isOption?: boolean) => {
        const dynamicFieldTranslation: Record<DynamicFieldsType, string> = {
            DATE: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE),
            DATERANGE: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE),
            DATETIME: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_AND_HOUR),
            DROPDOWN: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT),
            NUMBER: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_NUMBER),
            TEXT: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_TEXT),
            TIME: t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_HOUR),
        };

        return isOption && dynamicFieldType === "DROPDOWN"
            ? t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_OPTION)
            : dynamicFieldTranslation[dynamicFieldType];
    };

    const getDynamicFieldTranslation = (dynamicFieldType: DynamicFieldsType) => {
        const dynamicFieldTranslation: Record<DynamicFieldsType, string> = {
            DATE: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATE),
            DATERANGE: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATERANGE),
            DATETIME: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_DATETIME),
            DROPDOWN: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_DROPDOWN),
            NUMBER: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_NUMBER),
            TEXT: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_TEXT),
            TIME: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_TIME),
        };

        return dynamicFieldTranslation[dynamicFieldType];
    };

    return {
        getDynamicFieldPlaceholderTranslation,
        getDynamicFieldTranslation,
    };
};
