import { FC } from "react";

type DividerWithTextProps = {
    text: string;
};

const DividerWithText: FC<DividerWithTextProps> = ({ text }) => {
    return (
        <div className="dividerWithText">
            <p>{text}</p>
            <hr className="dividerWithText__line" role="separator" />
        </div>
    );
};

export default DividerWithText;
