import { createContext } from "react";
export type PatchNotesModalContextModel = {
    openModal: (id: number, options?: PatchNotesContextOptions) => void;
    closeModal: () => void;
    isOpen: boolean;
    patchNoteVersionId: number;
    options?: PatchNotesContextOptions;
};

export type PatchNotesContextOptions = {
    readonly?: boolean;
};

export const PatchNotesModalContext = createContext<PatchNotesModalContextModel>({} as PatchNotesModalContextModel);
