import { FC, FormEvent, useEffect, useState } from "react";
import {
    EmailDistributionListFormValues,
    SelectedEmailDistributionList,
} from "../../models/EmailDistributionListFormValues";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsOutsideSelect } from "app/components_v2/__inputs";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { DistributionListFilterType } from "../../models/EmailDistributionListGridModels";
import { useToast } from "app/hooks/Toast/useToast";

type EditCreateEmailDistributionListUsersModalProps = {
    title: string;
    emailDistributionList: SelectedEmailDistributionList;
    onClose: () => void;
    onSubmit: (values: EmailDistributionListFormValues) => Promise<void>;
};

const formId = "editEmailDistributionListUsers";

export const EditCreateEmailDistributionListUsersModal: FC<EditCreateEmailDistributionListUsersModalProps> = ({
    onClose,
    onSubmit,
    title,
    emailDistributionList,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const { fK_Company, fK_DistributionList, userList } = emailDistributionList;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<OptionModel[]>(userList);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleDelteUser = (id: string) => {
        const user = selectedUsers.find(({ value }) => value === id);
        if (!user) return;

        setSelectedUsers((prev) => prev.filter(({ value }) => value !== id));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSaving(true);
        await onSubmit({
            companyList: [],
            fK_DistributionList: fK_DistributionList,
            userList: selectedUsers,
            fK_Company: fK_Company,
        });
        setIsSaving(false);
    };

    const validate = () => {
        let isValid = !!selectedUsers.length;
        setErrorMessage(!selectedUsers.length ? t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) : "");
        return isValid;
    };

    const getUsers = async () => {
        setIsLoading(true);
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");
        if (!!fK_Company.length) supervisorParams.append("CompaniesId", fK_Company);

        const key = !!fK_DistributionList.length ? Number(fK_DistributionList) : 0;
        if (key == 1 || key == 2) supervisorParams.append(distributionListFilter[key], "true");

        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), type: "notification", variant: "danger" });
            onClose();
            setIsLoading(false);
            return;
        }

        setUserOptions(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <GenericModal
            header={{ title, onClose }}
            size="md"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.SAVE),
                        form: formId,
                        type: "submit",
                        disabled: isLoading,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: onClose,
                    }}
                    loading={isSaving}
                />
            }
        >
            <form id={formId} onSubmit={handleSubmit}>
                <OptionsOutsideSelect
                    onChange={setSelectedUsers}
                    options={userOptions}
                    onDeleteItem={handleDelteUser}
                    values={selectedUsers}
                    placeholder={t(TranslationKeys.SELECT_USERS)}
                    label={t(TranslationKeys.USERS)}
                    isLoading={isLoading}
                    isMulti
                    errorMessage={errorMessage}
                    autoInitials
                />
            </form>
        </GenericModal>
    );
};
