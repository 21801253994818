import { FC } from "react";
import { useTitle, useWindowSize } from "app/hooks/CustomHooks";
import { useNavigate } from "react-router";
import { PrivatePaths, screenSize } from "app/shared/Constants";
import { Notification } from "./Notification/Notification";
import { useGoBack } from "app/hooks";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { GenericModalHeader } from "app/components_v2/__modals/base/GenericModal/GenericModalHeader/GenericModalHeader";

export const NotificationPage: FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const nav = useNavigate();
    const { handleParentGoBack } = useGoBack();
    if (width >= screenSize.TABLET) nav(`/${PrivatePaths.DASHBOARD}`);
    useTitle(t(TranslationKeys.NOTIFICATIONS));

    return (
        <div className="notificationPage">
            <GenericModalHeader title={t(TranslationKeys.NOTIFICATIONS)} onClose={() => handleParentGoBack()} />
            <div className="notificationPage__container">
                <Notification />
            </div>
        </div>
    );
};
