import { FC } from "react";
import { NotificationFileModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";
import { AlertPillStatus, AlertPillType } from "./types";
import { AlertPillHeader } from "./AlertPillHeader/AlertPillHeader";
import { AlertPillBody } from "./AlertPillBody/AlertPillBody";
import { AlertPillFiles } from "./AlertPillFiles/AlertPillFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/pro-regular-svg-icons";

type AlertPillProps = {
    profilePictureUrl?: string;
    fullName?: string;
    userId?: number;
    date: Date;
    text?: string;
    audio?: string;
    files?: NotificationFileModel[];
    onClick?: () => void;
    type?: AlertPillType;
    status?: AlertPillStatus;
    multipleAvatars?: any;
    assignedToQr?: boolean;
};

export const AlertPill: FC<AlertPillProps> = ({
    date,
    text,
    audio,
    files,
    type = "normal",
    fullName,
    userId,
    profilePictureUrl,
    status,
    onClick,
    multipleAvatars,
    assignedToQr,
}) => {
    return (
        <div className="alertPill" onClick={onClick} role="listitem">
            <AlertPillHeader
                date={date}
                type={type}
                fullName={fullName}
                multipleAvatars={multipleAvatars}
                profilePictureUrl={profilePictureUrl}
                userId={userId}
            />
            <AlertPillBody status={status} text={text} />
            {(assignedToQr || files || audio) && (
                <div className="alertPill__footer">
                    <AlertPillFiles audio={audio} files={files} />
                    {assignedToQr && <FontAwesomeIcon className="alertPill__footer__qr" icon={faQrcode} />}
                </div>
            )}
        </div>
    );
};
