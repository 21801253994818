import { FC, useEffect, useRef } from "react";
import { useSession } from "app/hooks";
import { convertUTCtoLocaleDate, getDayMonthAndTimeFormatted } from "app/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { AttachedFile } from "app/components_v2/AttachedFile/AttachedFile";

export type MessageModel = {
    message: string;
    timestamp: Date;
    customerInstanceId: number;
    name?: string;
    messageId: string;
    userSendId: number;
    type?: string;
    senderName?: string;
    profilePic?: string;
    dbId?: number;
};

type MessageComponentsProps = {
    handleShowImage: (message: string) => void;
    handleDelete: (props: MessageModel) => void;
    props: MessageModel;
    lastMessageHeightRef?: any;
    onlyDeleteImages?: boolean;
    isGroup?: boolean;
    maxWidthFitContent?: boolean;
};

export const Message: FC<MessageComponentsProps> = ({
    props,
    lastMessageHeightRef,
    handleShowImage,
    handleDelete,
    onlyDeleteImages,
    isGroup,
    maxWidthFitContent,
}) => {
    const session = useSession();
    const audioRef = useRef<HTMLAudioElement>(null);

    const isAudio = (type: string | undefined) => {
        const word: string | undefined = "audio";
        if (type) return type.includes(word);
    };
    const isPhoto = (type: string | undefined) => {
        const word: string | undefined = "image";
        if (type) return type.includes(word);
    };
    const isFile = (type: string | undefined) => {
        const word: string | undefined = "application";
        if (type) return type.includes(word);
    };
    const isVideo = (type: string | undefined) => {
        const word: string | undefined = "video";
        if (type) return type.includes(word);
    };

    const showTrash = () => {
        const onlyImages = (isPhoto(props.type) || isVideo(props.type)) && session?.user.id === props.userSendId;
        const allMessages = session?.user.id === props.userSendId;

        return onlyDeleteImages ? onlyImages : allMessages;
    };

    useEffect(() => {
        if (isAudio(props.type) && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
        }
    }, [props.message]);

    return (
        <>
            {props.type !== "Deleted" && (
                <div
                    ref={lastMessageHeightRef}
                    className={session?.user.id === props.userSendId ? "message" : "message__other"}
                >
                    <div className={`message__row${maxWidthFitContent ? "--maxWidthFitContent" : ""}`}>
                        <div className="message__container">
                            <div className="message__profile">
                                {session?.user.id !== props.userSendId
                                    ? isGroup && (
                                          <Avatar
                                              avatarName={props.senderName}
                                              colorId={props.userSendId}
                                              size="xs"
                                              img={props.profilePic}
                                              name={props.senderName}
                                          />
                                      )
                                    : showTrash() && (
                                          <FontAwesomeIcon
                                              className="message__deleteIcon"
                                              icon={faTrash}
                                              onClick={() => handleDelete(props)}
                                          />
                                      )}
                                <div className="message__flex">
                                    {getDayMonthAndTimeFormatted(convertUTCtoLocaleDate(new Date(props.timestamp)))}
                                </div>
                            </div>

                            {isAudio(props.type) && (
                                <audio className="message__audio" src={props.message} controls ref={audioRef} />
                            )}
                            {isPhoto(props.type) && (
                                <img
                                    alt="Chat pic"
                                    onClick={() => handleShowImage(props.message)}
                                    className="message__img"
                                    src={props.message}
                                />
                            )}
                            {isFile(props.type) && props.name && (
                                <AttachedFile
                                    urlClick={props.message}
                                    message={props.name}
                                    other={session?.user.id !== props.userSendId}
                                />
                            )}
                            {isVideo(props.type) && <audio src={props.message} controls />}
                            {(props.type === null || props.type === "text") && (
                                <p className="message__text">{props.message}</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
