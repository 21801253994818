import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import ChatBotGPT from "app/components_v2/ChatBotGPT/ChatBotGPT";
import { ChatBotMessage } from "app/components_v2/ChatBotGPT/ChatBotMessage/ChatBotMessage";
import { ChatBotMessageModel } from "app/components_v2/ChatBotGPT/types";

const messageAssistant: ChatBotMessageModel = {
    date: new Date(),
    sender: "assistant",
    text: "Hola, soy Laia, tu asistente personal. ¿En qué puedo ayudarte?",
    id: "",
    isLoading: false,
    type: "text",
    isErrored: false,
};
const messageUser: ChatBotMessageModel = {
    date: new Date(),
    sender: "user",
    text: "¿Como funcionan las tareas?",
    id: "",
    isLoading: false,
    type: "text",
    isErrored: false,
};

export const ChatBotGPTSection: FC<SectionsShowProp> = ({ show }) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    return (
        <Section title="ChatBotGPT" show={show}>
            <button onClick={() => setIsVisible((prev) => !prev)}>Mostrar / esconder</button>
            {isVisible && (
                <div style={{ width: "350px", height: "500px" }}>
                    <ChatBotGPT />
                </div>
            )}

            <h2>Mensajes </h2>
            <ChatBotMessage isPreviousMessageSameOwner={false} message={messageAssistant} />
            <ChatBotMessage isPreviousMessageSameOwner={false} message={messageUser} />
            <ChatBotMessage isPreviousMessageSameOwner={false} message={{ ...messageAssistant, isErrored: true }} />
            <ChatBotMessage isPreviousMessageSameOwner={false} message={{ ...messageUser, isErrored: true }} />
        </Section>
    );
};
