import { FC } from "react";
import CustomLegend from "../CustomLegend/CustomLegend";
import { SupervisorAnalistColors } from "../SupervisorAnalist/types";
import { roundPercentages } from "app/helpers/rankingPill/roundNumberArray";

export type MultiPercentageBarItem = {
    totalPercentage: number;
    label: string;
    color: SupervisorAnalistColors;
    count: number;
};

type MultiPercentageBarProps = {
    sections: MultiPercentageBarItem[];
    lateral?: boolean;
};

const MultiPercentageBar: FC<MultiPercentageBarProps> = ({ sections, lateral }) => {
    const percentages = sections?.map(({ totalPercentage }) => totalPercentage);
    const roundedPercentages = roundPercentages(percentages, 2);
    sections = sections?.map((section, i) => ({
        ...section,
        totalPercentage: roundedPercentages[i],
    }));

    return (
        <div className={`multi-percentage-bar--container${lateral ? "--lateral" : ""}`}>
            <div className="multi-percentage-bar">
                {sections?.map(
                    ({ color, totalPercentage }, index) =>
                        totalPercentage > 0 && (
                            <div
                                key={index}
                                className={`multi-percentage-bar-section--${color}`}
                                style={{
                                    width: `${totalPercentage}%`,
                                }}
                            >
                                <span className="progress-percentage">{totalPercentage}%</span>
                            </div>
                        )
                )}
            </div>
            <div className="multi-percentage-bar-percentage">
                <CustomLegend items={sections} />
            </div>
        </div>
    );
};

export default MultiPercentageBar;
