import { FC } from "react";
import { WizardLicenseStepOneProps } from "./types";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { Input, SelectOptions } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { TranslationKeys, WizardLicenseTranslations } from "app/translation/translationKeys";
import { useStepOne } from "./hooks/useStepOne";
import { MAX_INITIALS_LENGTH } from "app/shared/Constants";
import { customerTypes } from "../constants/LicensesConstants";
import { CustomerType } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";

export const StepOne: FC<WizardLicenseStepOneProps> = ({
    contactEmail,
    contactName,
    instanceName,
    phoneNumber,
    initials,
    customerType,
    next,
    onChange,
}) => {
    const { t } = useTranslation();

    const { errorMessages, validate } = useStepOne({
        contactEmail,
        contactName,
        instanceName,
        phoneNumber,
        initials,
        customerType,
        next,
    });

    const {
        errorContactEmail,
        errorContactName,
        errorInstanceName,
        errorPhoneNumber,
        errorInitials,
        errorCustomerType,
    } = errorMessages;

    return (
        <WizardForm validate={validate}>
            <Input
                onChange={(newValue) => onChange({ instanceName: newValue })}
                label={t(TranslationKeys.WIZARD_INSTANCE_NAME)}
                placeholder={t(TranslationKeys.WIZARD_INSTANCE_PLACEHOLDER)}
                value={instanceName}
                errorMessage={errorInstanceName}
            />
            <Input
                onChange={(newValue) => onChange({ contactName: newValue })}
                label={t(TranslationKeys.WIZARD_LICENSE_CONTACT_NAME)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_NAME)}
                value={contactName}
                errorMessage={errorContactName}
            />
            <Input
                onChange={(newValue) => onChange({ contactEmail: newValue })}
                label={t(TranslationKeys.WIZARD_LICENSE_CONTACT_EMAIL)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_EMAIL)}
                value={contactEmail}
                errorMessage={errorContactEmail}
            />
            <Input
                onChange={(newValue) => onChange({ phoneNumber: newValue })}
                label={t(TranslationKeys.WIZARD_LICENSE_CONTACT_PHONE)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_CONTACT_PHONE)}
                value={phoneNumber}
                errorMessage={errorPhoneNumber}
            />
            <Input
                onChange={(newValue) => onChange({ initials: newValue.toUpperCase() })}
                label={t(TranslationKeys.WIZARD_LICENSE_INITIALS)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_INITIALS)}
                value={initials}
                errorMessage={errorInitials}
                max={MAX_INITIALS_LENGTH}
            />
            <SelectOptions
                isMulti={false}
                label={t(WizardLicenseTranslations.WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL)}
                placeholder={t(WizardLicenseTranslations.WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL)}
                onChange={({ value }) => onChange({ customerType: value as CustomerType })}
                options={customerTypes.map((customerType) => ({ label: t(customerType), value: customerType }))}
                selectedValue={customerType}
                errorMessage={errorCustomerType}
            />
        </WizardForm>
    );
};
