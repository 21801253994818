import { FC } from "react";

import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import {
    Step4,
    RecurrentTaskModel,
    RecurrentTaskOption2,
    RecurrentTaskValuesModel,
} from "app/models/02-TAR/TaskWizard";
import { TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type SelectRecurringTaskProps = {
    onChange: (fields: Partial<Step4>) => void;
    recurringTask: RecurrentTaskModel;
    recurentTaskSelectorValues: RecurrentTaskOption2[];
    disabled?: boolean;
};

export const SelectRecurringTask: FC<SelectRecurringTaskProps> = ({
    onChange,
    recurentTaskSelectorValues,
    recurringTask,
    disabled,
}) => {
    const { t } = useTranslation();

    return (
        <SelectOptions
            isMulti={false}
            onChange={({ label, value }) =>
                onChange({
                    recurringTask: {
                        ...recurringTask,
                        value: value as RecurrentTaskValuesModel,
                        text: label,
                    },
                })
            }
            selectedValue={recurringTask.value}
            placeholder={t(TranslationCommon.SELECT_OPTION)}
            options={recurentTaskSelectorValues}
            disabled={disabled}
            isSearchable={false}
        />
    );
};
