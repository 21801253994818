import { FC, useEffect, useState } from "react";
import { DayBubble } from "../DayBubble/DayBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { Hint } from "../Hint/Hint";

export type WorkDay = {
    id: number;
    letter: string;
    isActive: boolean;
};

export type SelectWeekDayBubblesProps = {
    list: WorkDay[];
    onDayChange?: (list: WorkDay[]) => void;
    variant?: "primary" | "dark";
    label?: string;
    disabled?: boolean;
    errorMessage?: string;
    hint?: string;
    readonly?: boolean;
    size?: "xs";
};

export const SelectWeekDayBubbles: FC<SelectWeekDayBubblesProps> = ({
    list,
    onDayChange,
    variant = "primary",
    label,
    disabled,
    errorMessage,
    hint,
    readonly,
    size,
}) => {
    const [week, setWeek] = useState<WorkDay[]>([]);
    const [showHint, setShowHint] = useState(false);

    const handleDay = (id: number) => {
        const weekValues = week.map((weekDay) => {
            if (weekDay.id === id) weekDay.isActive = !weekDay.isActive;
            return weekDay;
        });
        setWeek(weekValues);
        onDayChange && onDayChange(weekValues);
    };

    useEffect(() => {
        setWeek(list);
    }, [list]);

    return (
        <div className="dayBubbleList">
            {label && (
                <div className={`dayBubbleList__label--${variant}`}>
                    <label>{label}</label>
                    {hint && (
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            onClick={() => setShowHint(!showHint)}
                            title="Day bubble hint icon"
                        />
                    )}
                </div>
            )}
            <div className="dayBubbleList__list">
                {week.map(({ letter, isActive, id }, i) => (
                    <DayBubble
                        key={i}
                        letter={letter}
                        active={isActive}
                        onClick={() => handleDay(id)}
                        variant={variant}
                        disabled={disabled}
                        readonly={readonly}
                        size={size}
                    />
                ))}
            </div>
            {errorMessage && <p className={`dayBubbleList__error--${variant}`}>{errorMessage}</p>}
            {showHint && hint && !errorMessage && <Hint text={hint} inverted={variant === "dark"} />}
        </div>
    );
};
