import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoggedInRouteProps } from "./interfaces";
import { PublicPaths } from "../shared/Constants";
import { IAppState } from "../state/reducer";
import { hasPermissionToView } from "./HelperRoleBasedAccess";
import { NotFound } from "app/pages/404/NotFound";
import { useSession } from "app/hooks";

// handle the private routes
const PrivateRoute: React.FC<LoggedInRouteProps> = ({ path, viewCode, component: Component }) => {
    const location = useLocation();
    const isLoggedIn = useSelector<IAppState, boolean>((state) => state.isLoggedIn);

    const session = useSession();
    const isOnBoarding = !!session?.isOnBoarding;

    const accessToView = hasPermissionToView(session, viewCode);

    if (isLoggedIn && accessToView) return <Component />;

    if (isLoggedIn && !session?.user.companies?.length && !isOnBoarding) {
        return <NotFound errorType="no company" />;
    }

    if (isLoggedIn && !session?.user.permissions?.length) {
        return <NotFound errorType="no permisions" />;
    }

    if (isLoggedIn && !accessToView) {
        return <NotFound errorType="accessDenied" />;
    }

    return (
        <Navigate
            replace
            to={{
                pathname: PublicPaths.ROOT,
            }}
            state={{ from: location.pathname }}
        />
    );
};

export default PrivateRoute;
