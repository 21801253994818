import { EditImage } from "app/components_v2/EditImage/EditImage";
import { FC, RefObject } from "react";

type MediaCaptureProps = {
    videoRef: RefObject<HTMLVideoElement>;
    imageFile: File | null;
    onCancelImage: () => void;
    onAccept: (photoBase64: string) => void;
};

export const MediaCapture: FC<MediaCaptureProps> = ({ videoRef, imageFile, onCancelImage, onAccept }) => {
    return (
        <div className="mediaCapture">
            {imageFile && (
                <div className="mediaCapture__image designSystemEditImage">
                    <EditImage onCancel={onCancelImage} onAccept={onAccept} file={imageFile} />
                </div>
            )}
            <video
                className="mediaCapture__video"
                autoPlay={true}
                playsInline={true}
                muted={true}
                ref={videoRef}
                style={{ display: !imageFile ? "block" : "none" }}
                id="webcam"
            />
        </div>
    );
};
