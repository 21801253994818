import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

type MarkableIconProps = {
    isActive: boolean;
    onClick: () => void;
    activeIcon: IconProp;
    inactiveIcon?: IconProp;
    counter?: number;
    size?: "regular" | "sm" | "xl";
    alert?: boolean;
    allowHover?: boolean;
    variant?: TabHeaderVariants | "warning";
};

export const MarkableIcon: FC<MarkableIconProps> = ({
    isActive,
    onClick,
    activeIcon,
    inactiveIcon,
    counter,
    size = "regular",
    alert,
    allowHover,
    variant = "primary",
}) => {
    return (
        <div
            className={`markableIcon--${variant}${isActive ? "--active" : ""} markableIcon${
                alert ? "--alert" : ""
            }  markableIcon--${size} ${allowHover ? "allowHover" : ""}`}
            onClick={onClick}
        >
            {!!counter && <div className="markableIcon__notification" />}
            <FontAwesomeIcon
                className={`markableIcon__icon--${variant}${isActive ? "--active" : ""} ${
                    size ? `markableIcon__icon--${size}` : ""
                }`}
                icon={isActive ? activeIcon : inactiveIcon || activeIcon}
            />
        </div>
    );
};
