import { validPhotoExtensions } from "app/shared/Constants";

export const isPhoto = (file: File): boolean => {
    const fileName: string = file.type.toLowerCase();
    const fileExtension: string | undefined = fileName.split("/").pop();
    return fileExtension !== undefined && validPhotoExtensions.includes(fileExtension);
};

export const isFileNamePhoto = (filename: string): boolean => {
    const indexDot = filename.lastIndexOf(".");

    if (indexDot === -1 || indexDot === filename.length - 1) return false;

    const fileNameExtension = filename.slice(indexDot + 1).toLowerCase();

    return validPhotoExtensions.includes(fileNameExtension);
};
