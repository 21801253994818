import { FormEvent, useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import JobDescriptionService from "app/services/01-SEG/JobDescriptionService";
import { JobDescriptionModel } from "app/models/01-SEG/JobDescription/JobDescriptionModel";
import { ActionType } from "app/models/FormComponentsModel";
import { UserInfoJobDescriptionModalProps } from "../UserInfoJobDescriptionModal";

type UseJobDescriptionModal = Omit<UserInfoJobDescriptionModalProps, "onCancel">;

export const useJobDescriptionModal = ({ selectedJobDescription, onAddNew, onEdit }: UseJobDescriptionModal) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const isSelectedJobDescriptionNotNull = selectedJobDescription !== null;
    const action: ActionType = isSelectedJobDescriptionNotNull ? "edit" : "create";

    const [description, setDescription] = useState<string>(() =>
        action === "edit" && isSelectedJobDescriptionNotNull ? selectedJobDescription.description : ""
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoading(true);
        if (action === "create") await handleAdd();
        if (action === "edit") await handleEdit();
        setIsLoading(false);
    };

    const handleAdd = async () => {
        const { data, getParsedError, status } = await JobDescriptionService.Save(description);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }
        onAddNew(data);
    };

    const handleEdit = async () => {
        if (!selectedJobDescription) return;

        const editedJobDescription: JobDescriptionModel = {
            ...selectedJobDescription,
            description,
        };
        const { data, getParsedError, status } = await JobDescriptionService.Edit(editedJobDescription);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }
        onEdit(data);
    };

    const validate = () => {
        if (description.length >= 100 || !description.length) {
            setErrorMessage(t(TranslationKeys.INPUT_MAX_CHARACTERS).replace("{0}", "100"));
            return false;
        }
        return true;
    };

    return {
        description,
        onDescriptionChange: setDescription,
        isLoading,
        errorMessage,
        onSubmit: handleSubmit,
        action,
    };
};
