import { FC, useRef, useState } from "react";
import { FullScreenQrSelectedOption, FullScreenQrType } from "./types";
import { FullScreenModal } from "../FullScreenModal/FullScreenModal";
import { FullScreenQrModalHeader } from "./FullScreenQrModalHeader/FullScreenQrModalHeader";
import { FullScreenQrModalManual } from "./FullScreenQrModalManual/FullScreenQrModalManual";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { QRScanner } from "app/components_v2/CustomQrScanner/CustomQrScanner";
import { getQrCode } from "app/helpers/QR/getQrCode";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { ScanQRTranslations, TranslationModals } from "app/translation/translationKeys";

type FullScreenQrModalProps = {
    onClose: () => void;
    onScanQr: (qrCode: string) => void;
    type?: FullScreenQrType;
    title?: string;
};

export const FullScreenQrModal: FC<FullScreenQrModalProps> = ({ onClose, onScanQr, type = "TASK", title }) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const isDesktop = width >= screenSize.TABLET;

    const [selectedOption, setSelectedOption] = useState<FullScreenQrSelectedOption>(isDesktop ? "MANUAL" : "SCAN");
    const videoRef = useRef<HTMLVideoElement>(null);

    const closeModal = () => {
        closeVideo();
        onClose();
    };

    const onSelect = (value: FullScreenQrSelectedOption) => {
        if (value === selectedOption) return;
        setSelectedOption(value);
        closeVideo();
    };

    const closeVideo = () => {
        if (!videoRef.current || !videoRef.current?.srcObject) return;
        const stream = videoRef.current?.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
            if (track.readyState == "live") {
                track.stop();
            }
        });
    };

    const handleScanQr = (value: string) => {
        const qrCode = getQrCode(value);
        if (!qrCode) {
            handleToast({
                title: t(TranslationModals.TOAST_QR_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        if (type === "TASK") localStorage.setItem("qrCode", qrCode);
        onScanQr(qrCode);
    };

    return (
        <FullScreenModal
            headerOptions={{
                onClose: closeModal,
                title: t(ScanQRTranslations.QR_SCANNER_MODAL_CLOSE_SCANNER),
            }}
        >
            <div className="fullScreenQrModal">
                {!isDesktop && (
                    <FullScreenQrModalHeader onSelect={onSelect} selectedOption={selectedOption} title={title} />
                )}
                {(selectedOption === "MANUAL" || isDesktop) && (
                    <FullScreenQrModalManual onScanQr={handleScanQr} type={type} title={title} />
                )}
                {selectedOption === "SCAN" && !isDesktop && <QRScanner onScan={handleScanQr} videoRef={videoRef} />}
            </div>
        </FullScreenModal>
    );
};
