import { FC, FormEvent } from "react";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { StepFiveProps } from "./models";
import { StepOneInfo, StepThreeInfo, StepTwoInfo } from "./components";
import { StepFourInfo } from "./components/StepFourInfo/StepFourInfo";

export const StepFive: FC<StepFiveProps> = (props) => {
    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onFinish();
    };

    return (
        <WizardForm validate={validate}>
            <div className="stepFiveWizardTask">
                <StepOneInfo {...props} />

                <StepTwoInfo {...props} />

                <StepThreeInfo {...props} />

                <StepFourInfo {...props} />
            </div>
        </WizardForm>
    );
};
