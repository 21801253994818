import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";

import { AuditModel } from "app/models/05-QUA/AuditModels/AuditModels";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";

export default class AuditService {
    // GET Single Audit
    public static async GetOne(id: number) {
        return FetchService.get<AuditModel>({
            url: `${ApiPaths.QUA_API}/Audit/${id}`,
        });
    }

    // GET Audit List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditModel>>({
            url: `${ApiPaths.QUA_API}/Audit`,
            paginationParams: params,
        });
    }

    // GET Audit List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/Audit/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST Audit
    public static async Save(body: AuditModel) {
        return FetchService.post<AuditModel>({
            url: `${ApiPaths.QUA_API}/Audit`,
            body,
        });
    }

    // PUT Audit
    public static async Edit(id: number, body: AuditModel) {
        return FetchService.put<AuditModel>({
            url: `${ApiPaths.QUA_API}/Audit/${id}`,
            body,
        });
    }

    // DELETE Audit
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/Audit/${id}`,
        });
    }
}
