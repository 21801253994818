// Please if you define new config values add to this security control

import {
    BasicColorModel,
    ProgressbarColorModel,
    SupervisorDoughnutColorModel,
    WorkerDoughnutColorModel,
} from "app/components_v2/SupervisorAnalist/types";
import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { UserType } from "app/shared/types/UserTypes";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { DataToReportReview } from "app/models/utilities";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";
import { TaskTemporalityType } from "app/models/02-TAR/TaskWizard";

if (!process.env.REACT_APP_SEG_API || !process.env.REACT_APP_LOGIN_API) {
    throw new Error("Missing config from .env file");
}

export enum PublicPaths {
    WILDCARD = "*",
    ROOT = "/*",
    PUBLIC = "public/*",
    LOGIN = "/login",
    ACTIVATE_ACCOUNT = "/activate-account",
    CHANGE_PASSWORD = "/reset-password",
    RECOVER_PASSWORD = "/recover-password",
    NEW_PASSWORD = "/new-password",
    SELECT_PROFILE = "/select-profile",
    PRIVACY_POLICY_PAGE = "https://4link.app/4link/terminos-de-privacidad/",
    PRINT = "print",
}

export enum PrivatePaths {
    /* INITIAL SCREEN */
    MY_PROFILE = "my-profile",
    DASHBOARD = "dashboard",

    TASK_PHOTO = "review-photos",

    COMPANY_RANKING = "analyst-ranking",
    TASK_HISTORY = "task-history",
    REGISTRY_HISTORY = "appcc-history",

    CENTERS_MANAGEMENT = "companies",
    USER_MANAGEMENT = "users-page",
    ROLE_MANAGEMENT = "roles",

    BASE_TASK_MANAGEMENT = "base-task",
    BASE_TASK_APPCC_MANAGEMENT = "base-task-appcc",
    QR = "working-position",
    TASK_PAGE = "tasks",

    ISSUE = "issue",

    PLAN_APPCC = "appcc-plan",
    REGISTRY_MANAGEMENT = "appcc-task",
    CORR_MEASURE_MANAGER = "appcc-corrective-measures",
    ASSETS = "appcc-asset",

    AUDIT_MANAGEMENT = "audit-template",
    AUDIT_INST_MANAG = "audit-history",
    /* INITIAL SCREEN END */

    MOVEMENT_LOG = "movement-log",
    TEMPORARY_ROLE_PAGE = "temporary-roles",
    COMPANY_PAGE = "company",
    DEPARTMENT_PAGE = "department",
    ENVIRONMENT_PAGE = "environment",
    LICENSE_PAGE = "licenses",
    CHAT = "chat",
    NOTIFICATION = "notification",
    SELECT_DEPARTMENT = "select-department",
    ACCEPT_POLICY = "accept-policy",

    /* SIDE MENU */
    SUPERVISOR_RANKING = "review-fulfillment",
    FEEDBACK_TASKS = "review-feedback",
    ALERTS = "alert-notification",
    NEW_TASK = "tasks/new",

    // PENDING ALERTS
    PENDING_ALERTS = "pending-alerts",

    //DESIGN
    DESIGN_SYS = "design-system",

    // FAQS
    FAQS_PAGE = "faqs",

    // BASE AUDIT GROUP CHECKLIST ITEM
    BASE_AUDIT_GROUP_CHECKLIST_ITEM = "base-audit",

    // ONBOARDINGPAGE
    ON_BOARDING = "onboarding",

    // AUTOMATIC RECORDS
    AUTOMATIC_RECORDS = "automatic-records",

    // EMAIL DISTRIBUTION LIST
    DISTRIBUTION_LIST = "distribution-list",

    // PATCH NOTES
    PATCH_NOTES = "patch-notes",
}

export const APPCC_VARIANT_ROUTES: string[] = [
    `/${PrivatePaths.PLAN_APPCC}`,
    `/${PrivatePaths.REGISTRY_MANAGEMENT}`,
    `/${PrivatePaths.CORR_MEASURE_MANAGER}`,
    `/${PrivatePaths.ASSETS}`,
    `/${PrivatePaths.AUDIT_MANAGEMENT}`,
    `/${PrivatePaths.AUDIT_INST_MANAG}`,
    `/${PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM}`,
    `/${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}`,
    `/${PrivatePaths.REGISTRY_HISTORY}`,
];

export const AVAILABLE_ACTIVE_SCREENS: string[] = [
    "TASK_PHOTO",
    "COMPANY_RANKING",
    "AUDIT_MANAGEMENT",
    "ISSUE",
    "FEEDBACK_TASKS",
    "PLAN_APPCC",
];

export enum BreadcrumsTranslation {
    MY_PROFILE = "my-profile",
    MOVEMENT_LOG = "movement-log",
    DASHBOARD = "dashboard",
    USERS_PAGE = "users-page",
    GROUP_COMPANY = "companies",
    ROLE_PAGE = "roles",
    TEMPORARY_ROLE_PAGE = "temporary-roles",
    COMPANY_PAGE = "company",
    DEPARTMENT_PAGE = "department",
    ENVIRONMENT_PAGE = "environment",
    NEW = "new",
    EDIT = "edit",
    LICENSE_PAGE = "licenses",
    TEMPORARY = "temporary",
    CHAT = "chat",
    WM = "WaterMelon®",
    PREMIUM_SHELLFISH = "Premium Shellfish®",
    FOR_LINK = "4Link®",
    SELECT_DEPARTMENT = "select-department",
    DETAILS = "details",
    // EDIT/DUPLICATE TASK
    DUPLICATE = "duplicate",
    ASSET_PAGE = "appcc-asset",

    // REVIEW TASKS BREADCRUMBS
    TASK_PHOTO = "review-photos",
    DETAIL = "detail",
    FEEDBACK = "feedback",
    TASKS = "tasks",
    TASKS_HISTORY = "task-history",

    // TASKS
    FAST_NEW = "fastNew",

    // RANKING
    RANKING = "ranking",
    RANKING_SUPERVISOR = "review-fulfillment",

    //WORKING POSITION
    WORKING_POSITION = "working-position",

    // BASE TASK
    BASE_TASK_PAGE = "base-task",
    BASE_TASK_PAGE_APPCC = "base-task-appcc",

    // ISSUE
    ISSUE_PAGE = "issue",

    // Plan APPCC
    PLAN_APPCC_PAGE = "appcc-plan",
    TASK_APPCC_PAGE = "appcc-task",

    // AUDIT
    AUDIT_HISTORY = "audit-history",
}

export const ApiPaths = {
    AUTH: "/auth/login",
    AUTH_REFRESH_TOKEN: "/auth/refreshToken",
    AUTH_RESET_PASSWORD: "/profile/restablishPassword",
    AUTH_ACTIVATE_ACCOUNT: "/profile/activateAccount",
    LOGIN_API: process.env.REACT_APP_LOGIN_API,
    SEG_API: process.env.REACT_APP_SEG_API,
    TAR_API: process.env.REACT_APP_TAR_API,
    REP_API: process.env.REACT_APP_REP_API,
    SEN_API: process.env.REACT_APP_SEN_API,
    QUA_API: process.env.REACT_APP_QUA_API,
    OPENAI_API: process.env.REACT_APP_OPENAI_API_KEY,
    SIGNALR_API: process.env.REACT_APP_SIGNALR,
    MODULE_CONFIG: "/config",
    NEW_PASSWORD: "/profile/restablishFirstPassword",
};

export const PRINT_QR_URL = `${process.env.REACT_APP_WEBAPP}/${PrivatePaths.DASHBOARD}/qr`;

export enum PaginationDefaults {
    PAGE_SIZE = 10,
}

export enum GenericErrors {
    WRONG = "Something went wrong",
}

export const TASK_TYPE: Record<TaskType, TaskType> = {
    NORMAL: "NORMAL",
    SPORADIC: "SPORADIC",
    AUDIT: "AUDIT",
};

export const TASK_TEMPORALITY_TYPE: Record<TaskTemporalityType, TaskTemporalityType> = {
    NONE: "NONE",
    ONE_DAY: "ONE_DAY",
    PERIODICAL: "PERIODICAL",
    RANGE_DATES: "RANGE_DATES",
    SPORADIC: "SPORADIC",
};

export const TASK_TEMPORALITY_TYPE_ARRAY: TaskTemporalityType[] = [
    "NONE",
    "ONE_DAY",
    "PERIODICAL",
    "RANGE_DATES",
    "SPORADIC",
];

export const RegexPatterns = {
    onlyNumbers: /^\d+$/,
    password: /^(?!.*[*'""])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,12}$/,
    mail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    validDateFormat: /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    phoneNumber: /^(?:\+\d{1,3}\s?)?(?:\d{1,4}[-\s]?){1,5}\d{1,4}$/,
    betweenSixAndTwelve: /^.{6,12}$/,
    oneCapitalLetter: /(?=.*[A-ZÑÇ])/,
    oneMinuscule: /(?=.*[a-zñç])/,
    oneNumber: /(?=.*\d)/,
    specialCharacter: /[$&+,:;=?@#|'<>.^()%!-]/,
    onlyTwoAlphabeticalCharacters: /^[A-Za-z]{2}$/,
    nifAndNie: /^([XYZxyz]\d{7}|[0-9]{8})[A-Za-z]$/,
    username: /^[a-zA-Z0-9]+$/,
};

export enum Views {
    /* Without Role Permissons */
    MY_PROFILE = "MY_PROFILE",
    LICENSES = "LICENSES_PAGE",
    SELECT_DEPARTMENT = "SELECT_DEPARTMENT",

    /* --------------------------------- NEW VIEWS ------------------ */
    /* CONFIGURATION MODULE */
    NAV_HEADER = "NAV_HEADER",
    GROUP_COMPANY = "GROUP_COMPANY",
    COMPANY_PAGE = "COMPANY_PAGE",
    DEPARTMENT_PAGE = "DEPARTMENT_PAGE",
    ROLE_PAGE = "CRUD_ROLES",
    PROGRAMMED_ROLES = "PROGRAMMED_ROLES",
    USERS_PAGE = "USERS_PAGE",
    MOVEMENT_LOG = "MOVEMENT_LOG",
    ENVIRONMENT_PAGE = "ENVIRONMENT_PAGE",
    DESIGN_SYS = "DESIGN_SYS",

    /* COMMUNICATION AND NOTIFICATION MODULE (COM_AND_NOTIF)*/
    CHAT = "CHAT",
    SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
    ALERT = "ALERT",

    /* TASK REGISTRY (TAR_REGISTRY) */
    DASHBOARD = "DASHBOARD",

    /* TASK PLANIFICATION (TAR_PLAN)*/
    REASIGN_TASKS = "REASIGN_TASKS",
    TASK_PAGE = "TASK_PAGE",
    ASSET_PAGE = "ASSET_PAGE",

    /* TASK REVISION */
    TASK_PHOTO = "TASK_PHOTO",
    TASK_FULFILLMENT = "TASK_FULFILLMENT",
    FEEDBACK_TASKS = "FEEDBACK_TASKS",

    /* REPORT */
    TASK_HISTORY = "TASK_HISTORY",

    /* PENDING_ALERTS */
    PENDING_ALERTS = "PENDING_ALERTS",

    // WORKING POSITION
    CRUD_WORK_POS = "CRUD_WORK_POS",

    // RANKING
    COMPANY_DETAIL = "COMPANY_DETAIL",
    COMPANY_RANKING = "COMPANY_RANKING",
    DEPARTMENT_DETAIL = "DEPARTMENT_DETAIL",
    DEPARTMENT_RANKING = "DEPARTMENT_RANKING",
    USER_DETAIL = "USER_DETAIL",
    USER_RANKING = "USER_RANKING",

    // ISSUE
    CRUD_ISSUE = "CRUD_ISSUE",

    // APPCC
    APPCC_BTASK_MANAG = "APPCC_BTASK_MANAG",
}

export enum SecScreen {
    DASHBOARD = "DASHBOARD",
    LICENSES = "LICENSES_PAGE",
    COMPANY_RANKING = "COMPANY_RANKING",
    SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
    CENTERS_MANAGEMENT = "CENTERS_MANAGEMENT",
    TEAM_MANAGEMENT = "TEAM_MANAGEMENT",
    USER_MANAGEMENT = "USER_MANAGEMENT",
    ROLE_MANAGEMENT = "ROLE_MANAGEMENT",
    TEMPORARY_ROLES = "TEMPORARY_ROLES",
    TASK_REVIEW = "TASK_PHOTO",
    MY_VALORATION = "FEEDBACK_TASKS",
    RESET_TASK = "RESET_TASK",
    ALERTS = "ALERTS",
    TASK = "TASK_PAGE",
    QR = "QR",
    REASIGN_TASK = "REASIGN_TASK",
    ASSETS = "ASSETS",
    OPEN_TASK_ISSUE = "OPEN_TASK_ISSUE",
    OPEN_MANUAL_ISSUE = "OPEN_MANUAL_ISSUE",
    REOPEN_ISSUE = "REOPEN_ISSUE",
    CLOSE_ISSUE = "CLOSE_ISSUE",
    ATTACH_FILES = "ATTACH_FILES",
    ISSUE = "ISSUE",
    PLAN_APPCC = "PLAN_APPCC",
    TASK_HISTORY = "TASK_HISTORY",
    REGISTRY_HISTORY = "REGISTRY_HISTORY",
    ALLOW_GALERY_PHOTO = "ALLOW_GALERY_PHOTO",
    BASE_TASK_MANAGEMENT = "BASE_TASK_MANAGEMENT",
    JOB_DESC_MANAGER = "JOB_DESC_MANAGER",
    AUDIT_MANAGEMENT = "AUDIT_MANAGEMENT",
    AUDIT_INST_MANAG = "AUDIT_INST_MANAG",
    AUDIT_INST_REV_NC = "AUDIT_INST_REV_NC",
    AUDIT_INST_REPORT = "AUDIT_INST_REPORT",
    AUDIT_GROUP_MANAG = "AUDIT_GROUP_MANAG",
    AG_CHECKLIST_MANAG = "AG_CHECKLIST_MANAG",
    AGCL_ITEM_MANAG = "AGCL_ITEM_MANAG",
    REGISTRY_MANAGEMENT = "REGISTRY_MANAGEMENT",
    CORR_MEASURE_MANAGER = "CORR_MEASURE_MANAGER",
    AUDIT_DEL_NC = "AUDIT_DEL_NC",
    SPORADIC_TASK = "SPORADIC_TASK",
    AUTOMATIC_RECORD = "AUTOMATIC_RECORD",
    DISTRIBUTION_LIST = "DISTRIBUTION_LIST",
    CLOSE_COMPANY_DEPT = "CLOSE_COMPANY_DEPT",
    ISSUE_CLASSIFICATION = "ISSUE_CLASSIFICATION",
    APPCC_BTASK_MANAG = "APPCC_BTASK_MANAG",
}

export enum Modules {
    TAR_REGISTRY = "TAR_REGISTRY",
    TAR_PLAN = "TAR_PLAN",
    TAR_REVISION = "TAR_REVISION",
    ANALYTICS = "ANALYTICS",
    REPORT = "REPORT",
    ADMIN = "ADMIN",
    ANALYST = "ANALYTICS",
    SUPERVISOR = "TAR_REVISION",
    EMPLOYEE = "TAR_REGISTRY",
    ALERTS_NOTIFICATIONS = "ALERTS_NOTIFICATIONS",
    TAR_MANAGEMENT = "TAR_PLAN",
    ISSUE_MANAGEMENT = "ISSUE_MANAGEMENT",
}

export enum ViewActions {
    ADD = "ADD",
    VIEW = "VIEW",
    DELETE = "DELETE",
    UPDATE = "UPDATE",
    SETTINGS = "SETTINGS",
    IMPERSONATE = "IMPERSONATE",
}

export const CommonViewActionsAsLowerCase = [
    ViewActions.ADD.toLowerCase(),
    ViewActions.VIEW.toLowerCase(),
    ViewActions.DELETE.toLowerCase(),
    ViewActions.UPDATE.toLowerCase(),
];

export enum Roles {
    ADMIN = "ADMIN",
}

export enum CookieKey {
    REFRESH_TOKEN = "refreshToken",
}

export const DefaultInstanceConfig = {
    primaryColor: "#397096",
    secondaryColor: "#1C5372",
    accentColor: "#EEF9FF",
    alertColor: "#ED5879",
};

// Task status
export enum TaskStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
}

export enum TaskInstanceStatus {
    SHARED_TIMED_OUT = "SHARED_TIMED_OUT",
    TIMED_OUT = "TIMED_OUT",
    OUT_OF_TIME = "OUT_OF_TIME",
    IN_TIME = "IN_TIME",
    NEAR_END = "NEAR_END",
}

export enum AnalistTaskInstanceListTabsType {
    NOREVIEW = "NOREVIEW",
    FEEDBACK = "FEEDBACK",
    COMMENTS = "COMMENTS",
}

export type AnalistTaskInstanceListTabs = "FEEDBACK" | "COMMENTS" | "NOREVIEW";

export enum TaskStatusDashboard {
    PENDING = "PENDING_DASHBOARD",
    COMPLETED = "COMPLETED_DASHBOARD",
}

export const WizardModeUrls = [
    PrivatePaths.NEW_TASK,
    `${PrivatePaths.CENTERS_MANAGEMENT}/new`,
    `${PrivatePaths.ROLE_MANAGEMENT}/new`,
    `${PrivatePaths.ROLE_MANAGEMENT}/temporary/new`,
    `${PrivatePaths.LICENSE_PAGE}/new`,
];

export const screenSize = {
    DESKTOP: 1440,
    LAPTOP: 1024,
    TABLET: 901,
    MOBILE: 768,
};

export enum Languages {
    ESPANOL = "Español",
    ENGLISH = "English",
}

export enum NotificationVariant {
    DANGER = "DANGER",
    INFO = "INFO",
    ALERT = "ALERT",
    STAR_DANGER = "STAR_DANGER",
    STAR_INFO = "STAR_INFO",
    STAR_YELLOW = "STAR_YELLOW",
    USER_REGISTRATION = "USER_REGISTRATION",
    USER_DEREGISTRATION = "USER_DEREGISTRATION",
    SPORADIC_TASK = "SPORADIC_TASK",
    AUDIT_REPORT = "AUDIT_REPORT",
    PATCH_NOTES = "PATCH_NOTES",
    AUDIT_NC = "AUDIT_NC",
    ISSUE_TASK_TEMP = "ISSUE_TASK_TEMP",
    ISSUE_TASK = "ISSUE_TASK",
}

export enum MeasurementTypes {
    CELSIUS = "ºC",
    FARENHEIT = "ºF",
}

export enum FrequencyTypes {
    ONE_TIME = "ONE_TIME",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    ANNUALY = "ANNUALY",
    RANGE_DATES = "RANGE_DATES",
}

export enum AppSource {
    ONLINE = "APP",
    OFFLINE = "OFFLINEAPP",
}

export type AppSourceEntries = "APP" | "OFFLINEAPP";

export const CELSIUS = "celsius";
export const FARENHEIT = "farenheit";
export const CELSIUS_UNIT = "ºC";
export const FARENHEIT_UNIT = "ºF";

export const USER_TYPE: Record<UserType, UserType> = {
    ADMIN: "ADMIN",
    SUPERVISOR: "SUPERVISOR",
    ANALIST: "ANALIST",
    WORKER: "WORKER",
};
export const USER_TYPE_ARRAY: UserType[] = ["ADMIN", "ANALIST", "SUPERVISOR", "WORKER"];
export const ANALYST_ENTITY_TYPE: Record<AnalystEntityType, AnalystEntityType> = {
    COMPANY: "COMPANY",
    DEPARTMENT: "DEPARTMENT",
    USER: "USER",
};

export const USERS_LOGIN_LOCALSTORAGE_KEY = "users-login";
export const REFRESH_TOKEN_EXPIRATION_LOCALSTORAGE_KEY = "refreshTokenExpiration";
export const TOKEN_EXPIRATION_LOCALSTORAGE_KEY = "tokenExpiration";

export const AUTH_ROUTES_TO_DO_NOT_DO_REFRESH: string[] = [
    PublicPaths.LOGIN,
    PublicPaths.ACTIVATE_ACCOUNT,
    PublicPaths.CHANGE_PASSWORD,
    PublicPaths.RECOVER_PASSWORD,
    PublicPaths.SELECT_PROFILE,
];
export const refreshTokenInterval = 4 * 3600 * 1000;
export const IssueTypeCode: Record<IssueTypeCodeModel, IssueTypeCodeModel> = {
    AUDIT: "AUDIT",
    GENERIC: "GENERIC",
    TASK: "TASK",
    TASK_ASSET: "TASK_ASSET",
};

export const DataToReportReviewValues: Record<DataToReportReview, DataToReportReview> = {
    ALL: "ALL",
    NO_DATA: "NO_DATA",
    WITH_ASSET: "WITH_ASSET",
    WITH_CHECKLIST: "WITH_CHECKLIST",
    WITH_DATA: "WITH_DATA",
};

export const MAX_ALLOWED_ISSUE_SUPERVISORS = 20;
export const MIN_ALLOWED_ISSUE_SUPERVISORS = 1;

export enum ON_BOARDING_SUB_STEPS {
    ONBOARDING_CREATE_COMPANY = "ONBOARDING_CREATE_COMPANY",
    ONBOARDING_CREATE_ROLES = "ONBOARDING_CREATE_ROLES",
    ONBOARDING_CREATE_USERS = "ONBOARDING_CREATE_USERS",
    ONBOARDING_ASSIGN_USERS_TO_TEAMS = "ONBOARDING_ASSIGN_USERS_TO_TEAMS",
    ONBOARDING_CREATE_PLANS = "ONBOARDING_CREATE_PLANS",
    ONBOARDING_CREATE_CORRECTIVE_MEASURES = "ONBOARDING_CREATE_CORRECTIVE_MEASURES",
    ONBOARDING_CREATE_ASSETS = "ONBOARDING_CREATE_ASSETS",
    ONBOARDING_CREATE_BASETASK = "ONBOARDING_CREATE_BASETASK",
    ONBOARDING_CREATE_APPCC_BASETASK = "ONBOARDING_CREATE_APPCC_BASETASK",
    ONBOARDING_CREATE_QR = "ONBOARDING_CREATE_QR",
    ONBOARDING_CREATE_TASK = "ONBOARDING_CREATE_TASK",
    ONBOARDING_CREATE_APPCC_TASK = "ONBOARDING_CREATE_APPCC_TASK",
    ONBOARDING_CREATE_BASE_AUDIT_ITEMS = "ONBOARDING_CREATE_BASE_AUDIT_ITEMS",
    ONBOARDING_CREATE_AUDIT_TEMPLATE = "ONBOARDING_CREATE_AUDIT_TEMPLATE",
}

export const ON_BOARDING_SUB_STEPS_ID: Record<string, number> = {
    ONBOARDING_CREATE_COMPANY: 1,
    ONBOARDING_CREATE_ROLES: 2,
    ONBOARDING_CREATE_USERS: 3,
    ONBOARDING_ASSIGN_USERS_TO_TEAMS: 4,
    ONBOARDING_CREATE_PLANS: 5,
    ONBOARDING_CREATE_CORRECTIVE_MEASURES: 6,
    ONBOARDING_CREATE_ASSETS: 7,
    ONBOARDING_CREATE_BASETASK: 8,
    ONBOARDING_CREATE_APPCC_BASETASK: 9,
    ONBOARDING_CREATE_QR: 10,
    ONBOARDING_CREATE_TASK: 11,
    ONBOARDING_CREATE_APPCC_TASK: 12,
    ONBOARDING_CREATE_BASE_AUDIT_ITEMS: 13,
    ONBOARDING_CREATE_AUDIT_TEMPLATE: 14,
};

export type SensorEntryLevelModel = "LOW" | "MEDIUM" | "HIGH";
export const sensorEntryLevel: Record<SensorEntryLevelModel, SensorEntryLevelModel> = {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
};
export const TOTAL_ALLOWED_DEPARTMENTS_PER_COMPANY = 5;

export const PREPOSITIONS_TO_AVOID = [
    "a",
    "al",
    "asi",
    "con",
    "de",
    "del",
    "e",
    "el",
    "en",
    "la",
    "las",
    "los",
    "mas",
    "muy",
    "ni",
    "o",
    "ora",
    "para",
    "pero",
    "porque",
    "pues",
    "que",
    "se",
    "sea",
    "segun",
    "si",
    "sin",
    "sino",
    "sobre",
    "tal",
    "tras",
    "u",
    "un",
    "una",
    "uno",
    "unos",
    "y",
    "ya",
    "-",
];

export const cifLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "N", "P", "Q", "R", "S", "U", "V", "W"];

export const SupervisorProgressbarColors: BasicColorModel[] = ["green", "yellow", "red"];
export const SupervisorDoughnutColors: SupervisorDoughnutColorModel[] = ["blue", "green", "yellow", "red"];
export const WorkerDoughnutColors: WorkerDoughnutColorModel[] = ["green", "yellow", "red", "darkRed"];
export const TaskProgressbarColors: ProgressbarColorModel[] = ["green", "yellow", "red", "darkRed"];

export const MAX_INITIALS_LENGTH = 2;
export const MAX_STRING_LENGTH_255 = 255;

export const MAX_SIZE_IMAGES_30MB = 31457280;
export const MAX_TASK_HISTORY_ROWS_CSV = 20000;
export const TASK_FOTO_EXMAPLE_DEFAULT_ID = 300;

export const validPhotoExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "avif"];
export const validVideoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];
export const validAudioExtensions = ["wav", "mp3", "ogg", "mpeg", "flac", "acc", "aiff"];
export const validDocumentExtensions = ["pdf", "doc", "docx", "xls", "xlsx"];
export const validTypes = ["image/*", "video/*", "audio/*", "application/*"];
