import { FC, ImgHTMLAttributes } from "react";
import { AvatarSize } from "../types";
import { mergeClassNames } from "app/helpers/classNames/mergeClassNames";

type AvatarImageProps = ImgHTMLAttributes<HTMLImageElement> & {
    size: AvatarSize;
    border?: boolean;
    objectFit?: "contain";
    background?: "white";
};

export const AvatarImage: FC<AvatarImageProps> = ({ size, background, border, objectFit, ...rest }) => {
    return (
        <img
            {...rest}
            className={mergeClassNames("avatarImage", `avatarImage--${size}`, {
                ["avatarImage--border"]: border,
                [`avatarImage--${objectFit}`]: !!objectFit,
                [`avatarImage--${background}`]: !!background,
            })}
        />
    );
};
