import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { BarChart } from "app/components_v2/BarChart/BarChart";
import { BarDataset } from "app/components_v2/BarChart/types";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { BarChartCustom } from "app/components_v2/BarChart/BarChartCustom/BarChartCustom";

export const BarChartSection: FC<SectionsShowProp> = ({ show }) => {
    const { width } = useWindowSize();

    const startDate = "2024-07-02";
    const numberOfDays = 60;

    const generateLabels = (startDate: string, numberOfDays: number) => {
        const labels = [];
        const start = new Date(startDate);

        for (let i = 0; i < numberOfDays; i++) {
            const currentDate = new Date(start);
            currentDate.setDate(start.getDate() + i);
            const day = String(currentDate.getDate()).padStart(2, "0");
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const year = currentDate.getFullYear();
            labels.push(`${day}-${month}-${year}`);
        }

        return labels;
    };

    const generateRandomNumbers = (numberOfDays: number) => {
        const randomNumbers = [];
        for (let i = 0; i < numberOfDays; i++) {
            const randomNumber = Math.floor(Math.random() * 101);
            randomNumbers.push(randomNumber);
        }
        return randomNumbers;
    };

    const datasets: BarDataset[] = [
        {
            label: "Vencidas",
            data: generateRandomNumbers(numberOfDays),
            color: "red",
        },
        {
            label: "Fuera de Tiempo",
            data: generateRandomNumbers(numberOfDays),
            color: "yellow",
        },
        {
            label: "Realizada",
            data: generateRandomNumbers(numberOfDays),
            color: "green",
        },
        {
            label: "Vencida Compartida",
            data: generateRandomNumbers(numberOfDays),
            color: "darkRed",
        },
    ];

    return (
        <Section title="Bar Chart" show={show}>
            <BarChart
                labels={generateLabels(startDate, numberOfDays)}
                datasets={datasets}
                xMax={width >= screenSize.TABLET ? 5 : 3}
            />
            <BarChartCustom datasets={datasets} labels={generateLabels(startDate, numberOfDays)} />
        </Section>
    );
};
