import { FC } from "react";
import { getDayMonthAndTimeFormatted } from "app/helpers";
import { NotificationIcon } from "../NotificationIcon/NotificationIcon";
import { NotificationVariantModel } from "app/models/NotificationVariant";

type NotificationPillProps = {
    title: string;
    body?: string;
    date?: Date;
    seen?: boolean;
    onClick?: () => void;
    variant?: NotificationVariantModel;
    profilePictureURL?: string;
    nameInitials?: string;
    userId?: number;
};

export const NotificationPill: FC<NotificationPillProps> = ({
    body,
    title,
    date,
    seen,
    onClick,
    variant = "primary",
    profilePictureURL,
    nameInitials,
    userId,
}) => {
    return (
        <div className="notificationPill" onClick={onClick}>
            <NotificationIcon
                nameInitials={nameInitials}
                variant={variant}
                profilePictureURL={profilePictureURL}
                userId={userId}
            />

            <div className="notificationPill__content">
                <div className="notificationPill__content__header">
                    <h2 className={`notificationPill__content__header__title--clamp${body ? "1" : "3"}`}>{title}</h2>
                    {date && (
                        <p className="notificationPill__content__header__date">
                            {getDayMonthAndTimeFormatted(date, true)}
                        </p>
                    )}
                </div>
                {body && <p className="notificationPill__content__body">{body}</p>}
            </div>
            {seen && <span className="notificationPill__notification" />}
        </div>
    );
};
