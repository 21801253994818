import { FC, FormEvent } from "react";
import { WizardLicenseStepFourrops } from "./types";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { StepFourInfo } from "./components/StepFourInfo/StepFourInfo";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

export const StepFour: FC<WizardLicenseStepFourrops> = (props) => {
    const { t } = useTranslation();
    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onFinish();
    };
    const {
        adminUserCode,
        adminEmail,
        adminFirstName,
        adminLastName,
        adminUsername,
        contactEmail,
        contactName,
        instanceName,
        phoneNumber,
    } = props;

    return (
        <WizardForm validate={validate}>
            <div className="licenseMainContact">
                <div className="licenseMainContact__container">
                    <StepFourInfo title="Nombre de la instancia" description={instanceName} />
                    <p className="licenseMainContact__container__title">{t(TranslationKeys.MAIN_CONTACT)}</p>
                    <div className="licenseMainContact__container">
                        <StepFourInfo title={t(TranslationKeys.NAME)} description={contactName} />
                        <StepFourInfo title={t(TranslationKeys.EMAIL)} description={contactEmail} />
                        <StepFourInfo
                            title={t(TranslationKeys.WIZARD_LICENSE_PHONE_NUMBER)}
                            description={phoneNumber}
                        />
                    </div>
                </div>
                <div className="licenseMainContact__container">
                    <p className="licenseMainContact__container__title">{t(TranslationKeys.PRIMARY_ADMINISTRATOR)}</p>
                    <div className="licenseMainContact__container">
                        <StepFourInfo
                            title={t(TranslationKeys.WIZARD_LICENSE_NAME_AND_LASTNAME)}
                            description={`${adminFirstName} ${adminLastName}`}
                        />
                        <StepFourInfo title={t(TranslationKeys.LABEL_USERNAME)} description={adminUsername} />
                        <StepFourInfo title={t(TranslationKeys.WIZARD_LICENSE_EMIAL)} description={adminEmail} />
                        <StepFourInfo
                            title={t(TranslationKeys.WIZARD_LICENSE_DOCUMENT_NUMBER)}
                            description={adminUserCode}
                        />
                    </div>
                </div>
            </div>
        </WizardForm>
    );
};
