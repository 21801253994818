import { RoundedFilterButtonGroup } from "app/components_v2/RoundedFilterButtonGroup/RoundedFilterButtonGroup";
import { Section, SectionsShowProp } from "../Section/Section";
import { RoundedFilterButtonModel } from "app/components_v2/RoundedFilterButtonGroup/types";
import { faCheck, faClock } from "@fortawesome/pro-regular-svg-icons";
import { FC, useState } from "react";

export const RoundedFilterButtonGroupSection: FC<SectionsShowProp> = ({ show }) => {
    const items: RoundedFilterButtonModel[] = [
        { icon: faClock, id: 1, number: 12, title: "Pendientes" },
        { icon: faCheck, id: 2, number: 23455555, title: "Realizadas" },
    ];

    const [active, setActive] = useState<number>(1);

    return (
        <Section title="RoundedFilterButtonGroup" show={show}>
            <button onClick={() => setActive(active === 1 ? 2 : 1)}>Change</button>
            <div style={{ backgroundColor: "#27648d", width: "375px", padding: "20px 12px" }}>
                <RoundedFilterButtonGroup items={items} active={active} onClick={setActive} />
            </div>
        </Section>
    );
};
