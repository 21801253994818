import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSession, useTitle } from "app/hooks";
import { usePatchNotes } from "./hooks/usePatchNotes";
import { useNavigate } from "react-router-dom";
import { faCirclePlus, faNotes, faPaperPlane, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import {
    PatchNotesTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import { getDayMonthAndTimeFormatted } from "app/helpers";
import { ColumnsType } from "app/components_v2/Table/types";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { MobilePatchNoteRow } from "./components/MobilePatchNoteRow/MobilePatchNoteRow";
import { PatchNotesModal } from "./components/PatchNotesModal/PatchNotesModal";

export const PatchNotesGrid: FC = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const session = useSession();
    const isCustomerInstanceNull = session?.user.customerInstanceId == null;

    const {
        getPatchNoteVersions,
        handleSubmit,
        isLoading,
        isSavingLoading,
        onCloseCreateModal,
        onCloseDeleteModal,
        onCloseEditModal,
        onDelete,
        onPageIndexChange,
        onShowCreateModal,
        onShowDeleteModal,
        onShowEditModal,
        onSortDirectionFieldChange,
        pageIndex,
        patchNote,
        patchNoteVersionList,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        onShowSendModal,
    } = usePatchNotes();

    useTitle(t(TranslationTitles.PATCH_NOTES_TITLE));

    const columns: ColumnsType<PatchNoteVersionModel>[] = [
        {
            dataIndex: "Name",
            label: t(PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL),
            sortedType: "default",
            render: ({ name }) => <CellTitle title={name} />,
        },
        {
            dataIndex: "Title",
            label: t(PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL),
            sortedType: "default",
            render: ({ title }) => <CellTitle title={title} />,
        },
        {
            dataIndex: "PublishDate",
            className: "patchNotesGrid__date",
            label: t(PatchNotesTranslations.PATCH_NOTES_PUBLISH_DATE_LABEL),
            sortedType: "default",
            render: ({ publishDate }) => <CellTitle title={getDayMonthAndTimeFormatted(new Date(publishDate))} />,
        },
        {
            dataIndex: "CustomerInstanceCount",
            className: "patchNotesGrid__actions",
            alignCenter: true,
            label: t(PatchNotesTranslations.PATCH_NOTES_CUSTOMERS_COUNT_LABEL),
            render: ({ customerInstanceCount }) => (
                <div className="patchNotesGrid__count">
                    <CellTitle title={customerInstanceCount} />
                </div>
            ),
        },
        {
            dataIndex: "actions",
            className: "patchNotesGrid__actions",
            alignCenter: true,
            label: t(TranslationKeys.ACTIONS),
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPaperPlane,
                            onClick: () => onShowSendModal(record.id),
                            hidden: !isCustomerInstanceNull,
                        },
                        {
                            icon: faPen,
                            onClick: () => onShowEditModal(record.id),
                            hidden: !isCustomerInstanceNull,
                        },
                        {
                            icon: faTrash,
                            onClick: () => onShowDeleteModal(record),
                            hidden: !isCustomerInstanceNull,
                        },
                    ]}
                />
            ),
            hidden: !isCustomerInstanceNull,
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: () => onShowCreateModal(true),
            hidden: !isCustomerInstanceNull,
        },
    ];

    useEffect(() => {
        if (!isCustomerInstanceNull) {
            nav(`/${PrivatePaths.LICENSE_PAGE}`);
            return;
        }
        getPatchNoteVersions();
    }, []);

    return (
        <>
            {showCreateModal && (
                <PatchNotesModal
                    type="create"
                    patchNote={patchNote}
                    onClose={onCloseCreateModal}
                    onSubmit={handleSubmit}
                    isLoading={isSavingLoading}
                />
            )}
            {showEditModal && (
                <PatchNotesModal
                    type="edit"
                    patchNote={patchNote}
                    onClose={onCloseEditModal}
                    onSubmit={handleSubmit}
                    isLoading={isSavingLoading}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(PatchNotesTranslations.PATCH_NOTE_DELETE_TITLE_LABEL).replace("{0}", patchNote.name)}
                    description={t(PatchNotesTranslations.PATCH_NOTE_DELETE_DESCRIPTION_LABEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={() => onDelete(patchNote.id)}
                    onClose={onCloseDeleteModal}
                    type="delete"
                />
            )}
            <PageContainer paddingTop>
                <TableCollapsable
                    title={t(TranslationTitles.PATCH_NOTES_TITLE)}
                    cols={columns}
                    data={patchNoteVersionList}
                    total={total}
                    placeholder={<TableError icon={faNotes} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    onDoubleClick={({ id }) => onShowEditModal(id)}
                    onChangePageIndex={onPageIndexChange}
                    onChangeSortDirectionField={onSortDirectionFieldChange}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    actionButtons={actionButtons}
                    mobileBody={(row) => (
                        <MobilePatchNoteRow
                            data={row}
                            onClickEdit={() => onShowEditModal(row.id)}
                            onClickDelete={() => onShowDeleteModal(row)}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
};
