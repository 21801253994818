import { getCustomerInstanceId, removeStoreUserSession } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { applyMiddleware, compose, createStore, Middleware } from "redux";
import FetchService from "../services/Fetch/FetchService";
import { ActionType } from "./actions";
import { IAction } from "./stateModels";
import { LocalStorageSession, ReduxSession } from "app/models/SessionModel";
import { ChangeCompanyModel } from "app/models/ChangeCompanyModel";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";

// Use this middleware to set token of your service, greetings from MR.U
const tokenMiddleware: Middleware<{}, IAppState> = (store) => (next) => (action: IAction) => {
    if (action.type === ActionType.SET_SESSION) {
        const session: ReduxSession = action.payload;
        FetchService.setToken(session.token);
        FetchService.setInstance(session.user.customerInstanceId || getCustomerInstanceId() || 0);
    }

    if (action.type === ActionType.SET_CUSTOMER_INSTANCE_ID) {
        FetchService.setInstance(action.payload);
    }

    return next(action);
};

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const envBaseUrl = window?.location.href;
const production = process.env.REACT_APP_WEBAPP || "";
const staging = process.env.REACT_APP_WEBAPP_STAGING || "";

const composeEnhancers =
    envBaseUrl.includes(production) || envBaseUrl.includes(staging)
        ? compose
        : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface IAppState {
    isLoggedIn: boolean;
    session?: ReduxSession;
    customerInstanceId?: number;
    sideMenuIsCollapsed: boolean;
    avatarImgUrl?: string;

    impersonateMode: boolean;
    prevSession?: ReduxSession;
    wizardMode: boolean;
    userCompanyConfig?: UserCompanyModel;
    mobileHeaderTitle: string;
    mobileHeadersvg?: string;
    childrenMobile?: React.ReactElement;
    isSideBarMobileOpened: boolean;

    qrCode?: string;
}

export const initialState: IAppState = {
    isLoggedIn: false,
    session: undefined,
    customerInstanceId: undefined,
    sideMenuIsCollapsed: false,
    impersonateMode: false,
    wizardMode: false,
    mobileHeaderTitle: "",
    isSideBarMobileOpened: false,
    qrCode: undefined,
};

// Reducer
export default function appReducer(state = initialState, action: IAction): IAppState {
    switch (action.type) {
        case ActionType.LOG_OUT_RESET_STATE: {
            removeStoreUserSession();
            return {
                ...action.payload,
            };
        }
        /* Esta machaca tot al fer refresh */
        case ActionType.SET_SESSION: {
            return {
                ...state,
                isLoggedIn: true,
                session: action.payload,
                customerInstanceId: action.payload.user.customerInstanceId || getCustomerInstanceId(),
                avatarImgUrl: action.payload.user.profilePictureURL,
            };
        }
        case ActionType.COLAPSE_MENU: {
            return {
                ...state,
                sideMenuIsCollapsed: action.payload,
            };
        }
        case ActionType.SET_CUSTOMER_INSTANCE_ID: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    user: { ...state.session.user, customerInstanceId: action.payload },
                },
            };
        }
        case ActionType.SET_AVATAR_URL: {
            return {
                ...state,
                avatarImgUrl: action.payload,
            };
        }
        case ActionType.SET_IMPERSONATE_MODE: {
            const prevSession: ReduxSession = { ...state.session! };

            return {
                ...state,
                impersonateMode: true,
                session: { ...action.payload, token: prevSession.token },
                prevSession: prevSession,
                avatarImgUrl: action.payload.user.profilePictureURL,
            };
        }
        case ActionType.OUT_IMPERSONATE_MODE: {
            const session: ReduxSession = { ...state.prevSession! };

            return {
                ...state,
                impersonateMode: false,
                session: session,
                prevSession: undefined,
                avatarImgUrl: session.user.profilePictureURL,
            };
        }
        case ActionType.SWITCH_WIZARD_MODE: {
            return {
                ...state,
                wizardMode: action.payload,
            };
        }
        // Jony's Actions
        case ActionType.SET_COMPANY_CONFIG: {
            return {
                ...state,
                userCompanyConfig: action.payload,
            };
        }

        case ActionType.UPDATE_SESSION: {
            return {
                ...state,
                session: action.payload,
            };
        }
        case ActionType.SET_OPEN_SIDEBAR_MOBILE: {
            return {
                ...state,
                isSideBarMobileOpened: action.payload,
            };
        }

        case ActionType.UPDATE_PROFILE_IMAGE: {
            if (!state.session?.user) return state;

            const userState: UserModel = {
                ...state.session.user,
                profilePictureURL: action.payload,
            };

            return {
                ...state,
                session: {
                    ...state.session,
                    user: userState,
                },
            };
        }

        case ActionType.UPDATE_LOGGED_IN: {
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        }

        case ActionType.UPDATE_QR_CODE: {
            return {
                ...state,
                qrCode: action.payload,
            };
        }

        case ActionType.UPDATE_IS_ACTIVATION_PENDING: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    isActivationPending: action.payload,
                    user: {
                        ...state.session.user,
                        isActivationPending: action.payload,
                    },
                },
            };
        }
        case ActionType.UPDATE_DO_NOT_DISTURB: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    user: {
                        ...state.session.user,
                        doNotDisturbMode: action.payload,
                    },
                },
            };
        }
        case ActionType.UPDATE_IS_PRIVACY_ACCEPTED: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    user: {
                        ...state.session.user,
                        isPrivacyAccepted: action.payload,
                    },
                },
            };
        }
        case ActionType.UPDATE_POST_MESSAGE: {
            if (!state.session) return state;
            const { appSource, workingCompany, workingDepartment, workingSubDepartment }: LocalStorageSession =
                action.payload;
            return {
                ...state,
                session: {
                    ...state.session,
                    appSource,
                    workingCompany,
                    workingDepartment,
                    workingSubDepartment,
                },
            };
        }
        case ActionType.CHANGE_COMPANY: {
            if (!state.session) return state;
            const { workingCompany, workingDepartment, workingSubDepartment }: ChangeCompanyModel = action.payload;
            return {
                ...state,
                session: {
                    ...state.session,
                    workingCompany,
                    workingDepartment,
                    workingSubDepartment,
                },
            };
        }
        case ActionType.UPDATE_USER_SESION: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    user: action.payload,
                },
            };
        }
        case ActionType.UPDATE_TOKEN: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    token: action.payload,
                },
            };
        }
        case ActionType.UPDATE_TOKEN_TIME: {
            if (!state.session) return state;
            return {
                ...state,
                session: {
                    ...state.session,
                    ...action.payload,
                },
            };
        }
        case ActionType.UPDATE_IS_ONBOARDING: {
            if (!state.session) return state;

            return {
                ...state,
                session: {
                    ...state.session,
                    isOnBoarding: action.payload,
                },
            };
        }
        case ActionType.UPDATE_LANGUAGE: {
            if (!state.session) return state;

            return {
                ...state,
                session: {
                    ...state.session,
                    user: {
                        ...state.session.user,
                        language: action.payload,
                    },
                },
            };
        }
        default:
            return state;
    }
}

export const store = createStore(appReducer, initialState, composeEnhancers(applyMiddleware(tokenMiddleware)));
