import { FC, useState } from "react";
import { RoundedFilterButtonModel } from "../RoundedFilterButtonGroup/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

type RoundedFilterButtonProps = RoundedFilterButtonModel & {
    isActive?: boolean;
    onClick: () => void;
    variant?: TabHeaderVariants;
};

export const RoundedFilterButton: FC<RoundedFilterButtonProps> = ({
    icon,
    id,
    number,
    title,
    isActive,
    onClick,
    fontSize = "xs",
    variant = "primary",
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    return (
        <div
            className={`roundedFilterButton${isActive ? "--active" : ""}${
                isFocused ? "--focus" : ""
            } roundedFilterButton--${variant}`}
            onClick={onClick}
            onMouseDown={() => setIsFocused(true)}
            onMouseUp={() => setIsFocused(false)}
            onMouseLeave={() => setIsFocused(false)}
            key={id}
        >
            <div className="roundedFilterButton__left">
                <FontAwesomeIcon icon={icon} className={`roundedFilterButton__left__icon--${fontSize}`} />
                {number != null && <p className="roundedFilterButton__left__number">{formatBigNumber(number)}</p>}
            </div>
            <p className={`roundedFilterButton__text--${fontSize}`}>{title}</p>
        </div>
    );
};
