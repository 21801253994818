import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { Label } from "app/components_v2/__inputs/Label";

type SelectUserProps = {
    changeUserSelected: (value: string, text: string) => void;
    value: string;
    options: OptionModel[];
    isEnabled: boolean;
    customError?: string;
    isDisabled?: boolean;
    optional?: boolean;
};

export const SelectUser: FC<SelectUserProps> = ({
    changeUserSelected,
    isEnabled,
    options,
    value,
    customError,
    isDisabled,
    optional = true,
}) => {
    const { t } = useTranslation();
    const [selectorOptions, setSelectorOptions] = useState<OptionModel[]>([]);

    useEffect(() => {
        setSelectorOptions([
            { value: "-1", label: t(TranslationCommon.SELECT_OPTION) },
            ...options.map(({ label, value, initials, profilePictureURL }) => ({
                label,
                value,
                initials,
                profilePictureURL
            })),
        ]);
    }, [options]);

    return (
        <div className="stepTwoSelectWithRadio">
            <Label label={t(TranslationKeys.USER)} className="stepTwoSelectWithRadio__label" isOptional={optional} />
            <div
                className={`stepTwoSelectWithRadio__container ${isDisabled ? "stepTwoSelectWithRadio__container--disabled" : ""
                    }`}
            >
                {!isDisabled && <RadioButton checked={!isEnabled} disabled={isDisabled} />}
                <SelectOptions
                    options={selectorOptions}
                    isMulti={false}
                    onChange={(newValue) => changeUserSelected(newValue.value, newValue.label)}
                    selectedValue={value !== "-1" ? value : undefined}
                    htmlFor="selectUser"
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    showBorderColor={!!customError?.length}
                    disabled={isDisabled}
                />
            </div>
            {customError && (
                <div className="stepTwoSelectWithRadio__errorMessage">
                    <ErrorMessage errorMessage={customError} />
                </div>
            )}
        </div>
    );
};
