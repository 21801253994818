import { PrivatePaths, Views } from "app/shared/Constants";
import { useNavigate } from "react-router-dom";
import { useSession } from "./useSession";

export const useNavigateToInitialScreen = () => {
    const nav = useNavigate();
    const session = useSession();

    const navigateToInitialScreen = () => {
        nav(
            `/${
                PrivatePaths[
                    (session?.user.initialScreenCode as keyof typeof PrivatePaths) ||
                        Views.DASHBOARD
                ]
            }`
        );
    };

    return { navigateToInitialScreen };
};
