import { Button } from "app/components_v2/__buttons/Button/Button";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type SecondaryFilterFooterProps = {
    onCancel?: () => void;
    onSave?: () => void;
    variant?: TabHeaderVariants;
};

export const SecondaryFilterFooter: FC<SecondaryFilterFooterProps> = ({ onCancel, onSave, variant = "primary" }) => {
    const { t } = useTranslation();
    return (
        <div className="secondaryFilterFooter">
            <Button type="tertiary" onClick={onCancel} text={t(TranslationCommon.CANCEL)} fullWidth variant={variant} />
            <Button onClick={onSave} text={t(TranslationKeys.APPLY_FILTER)} fullWidth variant={variant} />
        </div>
    );
};
