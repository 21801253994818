import { FC } from "react";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";

type CheckBoxRoleProps = {
    show?: boolean;
    isChecked: boolean;
    onChange: (check: boolean) => void;
    indeterminate?: boolean;
    disabled?: boolean;
};

export const CheckBoxRole: FC<CheckBoxRoleProps> = ({
    isChecked,
    onChange,
    show = true,
    indeterminate = false,
    disabled,
}) => {
    return (
        <div className="checkBoxRole">
            {show && (
                <CheckBox
                    checked={isChecked}
                    onChange={onChange}
                    intermediate={indeterminate}
                    disabled={disabled}
                />
            )}
        </div>
    );
};
