import { OptionsSearch } from "app/models/FormComponentsModel";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { TranslationKeys } from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const usePlanAPPCC = () => {
    const { t } = useTranslation();
    const [urlParams, setUrlParams] = useSearchParams();

    const [planOptions, setPlanOptions] = useState<OptionsSearch[]>([]);

    const getPlans = async () => {
        const { data, status } = await QuaSelectorService.GetPlansAPPCC();

        if (!status()) {
            setPlanOptions([]);
            return;
        }
        setPlanOptions([{ label: t(TranslationKeys.ALL_PLANS), value: "-1" }, ...data]);
    };

    return {
        planOptions,
        getPlans,
        urlParams,
        onChangeUrlParams: setUrlParams,
    };
};
