import { FC } from "react";

export type ProgressBarProps = {
    color: "green" | "yellow" | "red" | "blue";
    size: "sm" | "md";
    percentage: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({ color, percentage, size }) => {
    return (
        <div className={`progressBar progressBar--${size}`} data-testid="progress-bar">
            <div
                style={{ width: `${percentage}%` }}
                className={`progressBar__totalProgress--${color} ${
                    percentage > 0 ? `progressBar__totalProgress--${size}--minWidth` : ""
                }`}
                data-testid="progress"
            ></div>
        </div>
    );
};
