import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type BreadcrumbsSkeletonProps = {
    quantity?: number;
};

export const BreadcrumbsSkeleton: FC<BreadcrumbsSkeletonProps> = ({ quantity = 2 }) => {
    return (
        <div className="breadcrumbsSkeleton">
            {Array.from({ length: quantity }).map((_, i) => (
                <div className="breadcrumbsSkeleton__item" key={i}>
                    <div className="breadcrumbsSkeleton__item__title"></div>
                    {i + 1 !== quantity && (
                        <span className="breadcrumbsSkeleton__item__separator">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="breadcrumb__separator__icon"
                            />
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};
