import { faCircle, faRectangle } from "@fortawesome/pro-regular-svg-icons";
import { EditImageFooterIcon } from "../EditImageFooterIcon/EditImageFooterIcon";
import { FC } from "react";
import { EditImageFormsAction } from "../types";
import { AriaLabels } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export type EditImageFooterFormsProps = {
    currentForm: EditImageFormsAction | null;
    onChangeForm: (form: EditImageFormsAction) => void;
};

export const EditImageFooterForms: FC<EditImageFooterFormsProps> = ({ currentForm, onChangeForm }) => {
    const { t } = useTranslation();

    const handleClick = (form: EditImageFormsAction) => {
        if (form === currentForm) return;
        onChangeForm(form);
    };

    return (
        <div className="editImageFooterForms">
            <div className="editImageFooterForms__container">
                <EditImageFooterIcon
                    icon={faRectangle}
                    onClick={() => handleClick("RECTANGLE")}
                    active={currentForm === "RECTANGLE"}
                    ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_RECTANGLE)}
                />
                <EditImageFooterIcon
                    icon={faCircle}
                    onClick={() => handleClick("CIRCLE")}
                    active={currentForm === "CIRCLE"}
                    ariaLabel={t(AriaLabels.AL_EDIT_IMAGE_CIRCLE)}
                />
            </div>
        </div>
    );
};
