import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { IssueAssetValues } from "app/state/Providers/types";
import { createContext } from "react";

export type IIssueAssetOpen = {
    taskinstanceId: number;
    assetsOutOfRange: TaskInstanceFieldModel[];
    hasPlan: boolean;
};

export type IssueAssetsModalContextModel = {
    isOpen: boolean;
    taskInstanceId: number | null;
    open: (value: IIssueAssetOpen) => void;
    reset: () => void;
    assetsOutOfRange: TaskInstanceFieldModel[];
    assetValues: Record<number, IssueAssetValues[]>;
    showCorrectiveMeassures: boolean;
};

export const IssueAssetsModalContext = createContext<IssueAssetsModalContextModel>({} as IssueAssetsModalContextModel);
