import { CompanyService } from "app/services";
import { CompanyWizardModelExtra } from "./types";
import { FC, ReactElement, useEffect, useState } from "react";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SecScreen } from "app/shared/Constants";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { useWizard } from "app/hooks/useWizard";
import { Wizard } from "../Wizard";
import { WizardCompanyDepartmentModel } from "app/dtos/01-SEG/Company/CompanyDto";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

type CompanyWizardProps = {
    closeModal: () => void;
    selectedCompanyGroup: OptionsSearch;
};

const CompanyWizard: FC<CompanyWizardProps> = ({ closeModal, selectedCompanyGroup }) => {
    const { handleToast } = useToast();
    const session = useSession();
    const { t } = useTranslation();

    const titles = [
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_INFORMATION) },
        {
            title: t(TranslationKeys.WIZARD_HEADER_TASK_REFERENCE),
            hidden: !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT),
        },
        {
            title: t(TranslationKeys.USERS),
            hidden: !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT),
        },
        { title: t(TranslationCommon.CONFIRM) },
    ];

    const { currentStepIndex, goTo, onStepChange, next, prev, isLastStep } = useWizard(titles.length);

    const [formValues, setFormValues] = useState<CompanyWizardModelExtra>({
        company: {
            id: 0,
            customerInstanceId: session?.user.customerInstanceId || 0,
            groupCompanyId: Number(selectedCompanyGroup.value),
            groupName: selectedCompanyGroup.label,
            name: "",
            city: "",
            address: "",
            zip: "",
            isActive: false,
            rolesId: [],
            worksWithQR: false,
            closeCompanies: null,
        },
        departments: [],
        checkedUsers: [],
        departmentUsersChecked: [],
        subdepartmentUsersChecked: [],
        companySelectedId: { label: "", value: "" },
    });

    const [validateNextStep, setValidateNextStep] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (values: Partial<CompanyWizardModelExtra>) =>
        setFormValues((prev) => ({ ...prev, ...values }));

    const handleFinish = async () => {
        const { departments, company } = formValues;
        if (!company) return;
        setIsLoading(true);
        const departmentsWizard: WizardCompanyDepartmentModel[] = departments.map(({ name, subs, users }) => ({
            name,
            subs: subs.map((sub) => ({
                name: sub.name,
                users: sub.users.map((id) => ({
                    id,
                    customerInstanceId: session?.user.customerInstanceId || 0,
                })),
            })),
            users: users.map((id) => ({
                id,
                customerInstanceId: session?.user.customerInstanceId || 0,
            })),
        }));
        const { status } = await CompanyService.SaveCompanyWizard({
            company,
            departments: departmentsWizard,
        });

        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.SUCCESS_SAVE),
            type: "alert",
            variant: "success",
        });
        setIsLoading(false);
        closeModal();
    };

    const steps: ReactElement[] = [
        <Step1 onChangeData={handleChange} data={formValues} validateNextStep={setValidateNextStep} next={next} />,
        <Step2
            onChangeData={handleChange}
            data={formValues}
            validateNextStep={setValidateNextStep}
            next={next}
            onStepChange={onStepChange}
        />,
        <Step3 onChangeData={handleChange} data={formValues} validateNextStep={setValidateNextStep} next={next} />,
        <Step4 handleFinish={handleFinish} data={formValues} />,
    ];

    return (
        <Wizard
            currentStepIndex={currentStepIndex}
            headerItems={titles.filter((x) => !x.hidden)}
            onStepChange={onStepChange}
            step={
                hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT)
                    ? steps[currentStepIndex - 1]
                    : steps.filter(
                          (_, key) => !(key === 1 || key === 2) && !hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT)
                      )[currentStepIndex - 1]
            }
            totalSteps={titles.filter((x) => !x.hidden).length}
            goTo={goTo}
            validateNextStep={validateNextStep}
            isLoading={isLoading}
            finishButtonText={t(TranslationCommon.SAVE)}
            prev={() => (isLastStep && !formValues.copyExistingCompany ? goTo(2) : prev())}
        />
    );
};

export default CompanyWizard;
