import { FC } from "react";
import { ChatBotMessageModel } from "../types";
import { ChatBotMessageHeader } from "../ChatBotMessageHeader/ChatBotMessageHeader";
import Markdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { ChatBotGPTTranslations } from "app/translation/translationKeys";

type ChatBotMessageTextProps = {
    message: ChatBotMessageModel;
    isPreviousMessageSameOwner: boolean;
};

export const ChatBotMessageText: FC<ChatBotMessageTextProps> = ({ isPreviousMessageSameOwner, message }) => {
    const { t } = useTranslation();

    const { date, isLoading, sender, text, isHidden, isErrored } = message;

    if (isHidden) return null;
    return (
        <div className={`chatBotMessage chatBotMessage--${sender}`}>
            {!isPreviousMessageSameOwner && <ChatBotMessageHeader date={date} sender={sender} />}
            <span
                className={`chatBotMessage__text chatBotMessage__text--${sender} ${
                    isErrored ? "chatBotMessage__text--error" : ""
                }`}
                aria-label={text}
            >
                {sender === "assistant" ? (
                    <Markdown
                        components={{
                            ol: ({ node, ...rest }) => {
                                return <ol {...rest} className="chatBotMessage__text__list"></ol>;
                            },
                            ul: ({ node, ...rest }) => {
                                return <ul {...rest} className="chatBotMessage__text__list"></ul>;
                            },
                        }}
                    >
                        {isLoading ? t(ChatBotGPTTranslations.CHAT_BOT_GTP_LOADING_MESSAGE) : text}
                    </Markdown>
                ) : (
                    text
                )}
            </span>
        </div>
    );
};
