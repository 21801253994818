import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { Step2 } from "app/models/02-TAR/TaskWizard";
import { OptionsStep2 } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsEnabledModel } from "../models";
import { userData } from "../userData";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type SelectorStepTwoModel = {
    isLoading: boolean;
    values: OptionModel[] | null;
};

const INITIAL_SELECTOR_STEP_TWO: SelectorStepTwoModel = {
    isLoading: false,
    values: null,
};

export const useStepTwo = (
    companyForTask: OptionModel,
    SubDepartementForTask: OptionModel,
    DepartmentForTask: OptionModel,
    userForTask: OptionModel,
    workingPositionForTask: OptionModel,
    onChange: (fields: Partial<Step2>) => void,
    next: () => void
) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [validations, setValidations] = useState({
        DepartmentForTask: "",
        SubDepartementForTask: "",
        companyForTask: "",
        userForTask: "",
        userOrQRForTask: "",
    });
    const [companyValues, setCompanyValues] = useState<CompanyOptionModel[] | null>(null);
    const [userValues, setUserValues] = useState<OptionsStep2>({});
    const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
    const [employeesValues, setemployeesValues] = useState<SelectorStepTwoModel>(INITIAL_SELECTOR_STEP_TWO);
    const [departmentValues, setDepartmentValues] = useState<SelectorStepTwoModel>(INITIAL_SELECTOR_STEP_TWO);
    //  TODO: --SUBDEPARTMENT--
    // const [subdepartmentValues, setSubDepartmentValues] = useState<SelectorStepTwoModel>(INITIAL_SELECTOR_STEP_TWO);
    const [workingPositionValues, setWorkingPositionValues] = useState<SelectorStepTwoModel>(INITIAL_SELECTOR_STEP_TWO);
    const [isEnabled, setIsEnabled] = useState<IsEnabledModel>({
        subdepartment: false,
        user: false,
        workingPosition: true,
    });
    const [lastCompanySelected, setLastCompanySelected] = useState<string>(companyForTask.value);

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        const aux = { ...validations };

        aux.companyForTask = "";
        if (!companyForTask.value.length) {
            aux.companyForTask = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        aux.DepartmentForTask = "";

        if (!DepartmentForTask.value.length) {
            aux.DepartmentForTask = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        if (companyForTask.value.length && !DepartmentForTask.value.length) {
            aux.DepartmentForTask = t(TranslationCommon.SELECT_OPTION);
            check = false;
        }

        const selectedCompany = companyValues?.find(({ value }) => value === companyForTask.value);
        if (
            selectedCompany?.worksWithQR &&
            (!userForTask.value.length || userForTask.value === "-1") &&
            (!workingPositionForTask.value.length || workingPositionForTask.value === "-1")
        ) {
            aux.userOrQRForTask = t(TranslationKeys.MUST_SELECT_USER_OR_QR_BODY);
            handleToast({
                title: translateCustomerTypeKeys(TranslationKeys.MUST_SELECT_USER_OR_QR_TITLE, {
                    firstReplace: "SINGULAR",
                    isGenderSensitive: true,
                }),
                subtitle: t(TranslationKeys.MUST_SELECT_USER_OR_QR_BODY),
                variant: "danger",
                type: "alert",
            });
            check = false;
        }

        setValidations({ ...aux });
        if (check) next();
    };

    const changeCompanySelected = (selectedCompanyId: string, selectedCompanyName: string) => {
        const selectedCompany = companyValues?.find(({ value }) => value === selectedCompanyId);
        setSelectedCompanyId(selectedCompanyId);
        onChange({
            companyForTask: {
                label: selectedCompanyName,
                value: selectedCompanyId,
                worksWithQr: !!selectedCompany?.worksWithQR,
            },
        });
        setIsEnabled({ subdepartment: false, user: false, workingPosition: true });
    };

    const changeDepartmentSelected = (selectedDepartmentId: string, selectedDepartmentName: string) => {
        onChange({
            DepartmentForTask: {
                ...DepartmentForTask,
                label: selectedDepartmentName,
                value: selectedDepartmentId,
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            userForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
        changeSubDepartmentSelected("", "");
        setIsEnabled({ subdepartment: false, user: false, workingPosition: true });
    };

    const changeSubDepartmentSelected = (selectedSubdepartmentId: string, selectedSubdepartmentName: string) =>
        onChange({
            SubDepartementForTask: {
                ...SubDepartementForTask,
                label: selectedSubdepartmentName,
                value: selectedSubdepartmentId,
            },
        });

    const changeUserSelected = (selectedUserId: string, selectedUserName: string) =>
        onChange({
            userForTask: {
                label: selectedUserName,
                value: selectedUserId,
            },
        });

    const changeWorkingPositionSelected = (selectedWorkingPositionId: string, selectedWorkingPositonName: string) =>
        onChange({
            workingPositionForTask: {
                ...workingPositionForTask,
                label: selectedWorkingPositonName,
                value: selectedWorkingPositionId,
            },
        });

    const enableUser = () => {
        setIsEnabled({
            subdepartment: false,
            user: true,
            workingPosition: false,
        });
        onChange({
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
    };

    //  TODO: --SUBDEPARTMENT--
    // const enableSubdepartments = () => {
    //     setIsEnabled({
    //         subdepartment: true,
    //         user: false,
    //         workingPosition: false,
    //     });
    //     onChange({
    //         userForTask: {
    //             label: "",
    //             value: "",
    //         },
    //         workingPositionForTask: {
    //             label: "",
    //             value: "",
    //         },
    //     });
    // };

    const enableWorkingPosition = () => {
        setIsEnabled({
            subdepartment: false,
            user: false,
            workingPosition: true,
        });
        onChange({
            userForTask: {
                label: "",
                value: "",
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
        });
    };

    const fetchCompanies = async () => {
        const companySr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });
        if (!companySr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setCompanyValues([]);
            return;
        }
        setCompanyValues(companySr.data);
    };

    useEffect(() => {
        if (!companyForTask.value.length) return;

        let aux: OptionsStep2 = {};
        aux = {
            ...aux,
            [`${selectedCompanyId}`]: [{ value: "", label: t(TranslationCommon.SELECT_OPTION) }],
        };
        userData.map(({ firstName, id }) =>
            aux[selectedCompanyId].push({
                label: firstName,
                value: String(id),
            })
        );

        setUserValues(aux);
    }, [companyForTask.value]);

    useEffect(() => {
        if (!companyForTask.value) return;
        if (lastCompanySelected !== companyForTask.value) {
            onChange({
                userForTask: {
                    label: "",
                    value: "",
                },
                DepartmentForTask: {
                    label: "",
                    value: "",
                },
                SubDepartementForTask: {
                    label: "",
                    value: "",
                },
                workingPositionForTask: {
                    label: "",
                    value: "",
                },
            });
        }
        setLastCompanySelected(companyForTask.value);
    }, [companyForTask.value]);

    useEffect(() => {
        if (SubDepartementForTask.value.length) {
            setIsEnabled({ subdepartment: true, user: false, workingPosition: false });
            return;
        }
        if (userForTask.value.length) {
            setIsEnabled({ subdepartment: false, user: true, workingPosition: false });
            return;
        }

        setIsEnabled({ subdepartment: false, user: false, workingPosition: true });
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        const fetch = async () => {
            setemployeesValues({ isLoading: true, values: [] });
            const employeeSr = await SegSelectorService.GetUserNamesWithIds({
                extraParams: `CompanyId=${companyForTask.value}&DepartmentId=${DepartmentForTask.value}`,
            });
            if (employeeSr.status()) {
                setemployeesValues({
                    isLoading: false,
                    values: employeeSr.data,
                });
            } else
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
        };

        if (companyForTask.value && DepartmentForTask.value) fetch();
    }, [companyForTask.value, DepartmentForTask.value]);

    useEffect(() => {
        const fetch = async () => {
            setDepartmentValues({ isLoading: true, values: [] });

            const departmentSr = await SegSelectorService.GetDepartmentNamesWithIds({
                extraParams: `companyId=${companyForTask.value}`,
            });

            if (departmentSr.status()) {
                setDepartmentValues({
                    isLoading: false,
                    values: departmentSr.data,
                });
            } else
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
        };

        if (companyForTask.value) fetch();
    }, [companyForTask.value]);

    //  TODO: --SUBDEPARTMENT--
    // useEffect(() => {
    //     const fetch = async () => {
    //         setSubDepartmentValues({ isLoading: true, values: [] });
    //         const subDepartmentSr = await SegSelectorService.GetSubDepartmentNamesWithIds({
    //             extraParams: `DepartmentId=${DepartmentForTask.value}`,
    //         });

    //         if (subDepartmentSr.status()) {
    //             setSubDepartmentValues({
    //                 isLoading: false,
    //                 values: [{ value: "-1", label: t(TranslationCommon.SELECT_OPTION) }, ...subDepartmentSr.data],
    //             });
    //         } else
    //             handleToast({
    //                 title: t(TranslationModals.TOAST_GENERIC_ERROR),
    //                 type: "alert",
    //                 variant: "danger",
    //             });
    //     };

    //     if (DepartmentForTask.value) fetch();
    // }, [DepartmentForTask.value]);

    useEffect(() => {
        const fetch = async () => {
            setWorkingPositionValues({ isLoading: false, values: [] });
            const { status, data } = await TarSelectorService.GetWorkingPostions({
                extraParams: `companyId=${companyForTask.value}&IsDeleted=false`,
            });

            if (!status()) {
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
                setWorkingPositionValues((prev) => ({ ...prev, isLoading: false }));
                return;
            }

            setWorkingPositionValues({
                isLoading: false,
                values: [{ value: "-1", label: t(TranslationCommon.SELECT_OPTION) }, ...data],
            });
        };

        if (companyForTask.value && DepartmentForTask.value) fetch();
    }, [DepartmentForTask.value, companyForTask.value]);

    return {
        companyValues,
        userValues,
        employeesValues,
        departmentValues,
        workingPositionValues,
        validate,
        validations,
        selectedCompanyId,
        isEnabled,
        lastCompanySelected,
        changeCompanySelected,
        changeDepartmentSelected,
        changeUserSelected,
        enableUser,
        changeWorkingPositionSelected,
        enableWorkingPosition,
    };
};
