import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { TextArea } from "app/components_v2/__inputs";
import { MailModal } from "app/components_v2/__modals/base/MailModal/MailModal";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";
import { TaskpillValorationHeaderType } from "./types";
import { TaskValorationModalHeader } from "./TaskValorationModalHeader/TaskValorationModalHeader";

type TaskValorationModalProps = {
    handleClose: () => void;
    feedback: TaskInstanceFeedbackMessageModel;
    message: string;
    setMessage: (message: string) => void;
    handleSubmit: () => void;
    setAudioBase64?: (audio: string | null) => void;
    setAudioUrl?: (audio: string | null) => void;
    isSending?: boolean;
    errorMessage?: string;
    taskName: string;
    user: TaskpillValorationHeaderType;
};

export const TaskValorationModal: FC<TaskValorationModalProps> = ({
    handleClose,
    feedback,
    message,
    setMessage,
    handleSubmit,
    setAudioBase64,
    setAudioUrl,
    isSending,
    errorMessage,
    taskName,
    user,
}) => {
    const { t } = useTranslation();
    const [isRecording, setIsRecording] = useState(false);

    return (
        <MailModal
            title={`${
                feedback.rating <= 2
                    ? t(TranslationKeys.LOW_EVALUATION_REASON)
                    : t(TranslationKeys.ADD_EVALUATION_COMMENT)
            }`}
            onClose={handleClose}
            portal
            confirmButton={{
                text: t(TranslationKeys.VALORATE_TASK),
                onClick: handleSubmit,
                iconRight: faStarSharp,
                disabled: isRecording,
            }}
            loading={isSending}
        >
            <div className="feedback__popup">
                <TaskValorationModalHeader {...user} rating={feedback.rating} taskName={taskName} />
                <TextArea
                    onChange={setMessage}
                    text={message}
                    label={t(TranslationKeys.VALORATION_COMMENTS)}
                    placeholder={t(TranslationKeys.VALORATION_TEXTAREA_PLACEHOLDER)}
                    errorMessage={errorMessage}
                    uploadAudio={{
                        showSendAudio: true,
                        audioUrl: feedback.audioURL,
                        audioBase64: feedback.audioBase64,
                        setAudioBase64: setAudioBase64,
                        setAudioUrl: setAudioUrl,
                        isRecording: isRecording,
                        setIsRecording: setIsRecording,
                    }}
                />
            </div>
        </MailModal>
    );
};
