import { useToast } from "app/hooks/Toast/useToast";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { PrivatePaths } from "app/shared/Constants";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { IssueTranslation, TranslationKeys } from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

type UseAuditStartParams = { auditInstanceId: number; actualStatus: AuditInstanceStatus };

export const useAuditStart = ({ auditInstanceId, actualStatus }: UseAuditStartParams) => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();

    const [auditStartData, setAuditStartData] = useState<AuditInstanceModel | null>(null);
    const [isAuditStartLoading, setAuditStartIsLoading] = useState<boolean>(true);
    const [isAuditStartSubmiting, setAuditStartIsSubmiting] = useState<boolean>(false);
    const [isAuditStartConfirmModalOpen, setAuditStartIsConfirmModalOpen] = useState<boolean>(false);

    const fetchAuditStartData = async () => {
        if (!auditInstanceId) {
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
            return;
        }

        setAuditStartIsLoading(true);

        const extraParams = `status=${actualStatus}`;

        const { data, status } = await AuditInstanceService.GetOne(Number(auditInstanceId), { extraParams });

        if (!status()) {
            handleToast({ title: t(TranslationKeys.AUDIT_NOT_EXIST), variant: "danger", type: "alert" });
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
            return;
        }

        setAuditStartData(data);
        setAuditStartIsLoading(false);
    };

    const handleComplete = async () => {
        setAuditStartIsSubmiting(true);
        const toastId = v4();
        handleToast({
            title: t(IssueTranslation.CREATING_AUDIT_TITLE_TOAST),
            subtitle: t(IssueTranslation.CREATING_AUDIT_DESCRIPTION_TOAST),
            id: toastId,
            variant: "primary",
            type: "alert",
            isLoading: true,
        });

        const { status } = await AuditInstanceService.CompleteAuditInstance(Number(auditInstanceId));

        if (!status()) {
            setAuditStartIsSubmiting(false);
            removeToast(toastId);
            handleToast({
                title: t(TranslationKeys.AUDIT_FAILED_COMPLETE),
                variant: "danger",
                type: "alert",
            });
            return;
        }

        setAuditStartIsSubmiting(false);
        removeToast(toastId);
        handleToast({
            title: t(TranslationKeys.AUDIT_SUCCESS_COMPLETE),
            variant: "success",
            type: "alert",
        });
        nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
    };

    const confirmAuditStartModalSubmit = () => {
        handleComplete();
        setAuditStartIsConfirmModalOpen(false);
    };

    const handleAuditStartConfirmModalOpen = () => {
        if (!auditStartData) return;
        if (!auditInstanceId) return;
        const isAllAuditGroupCompleted = auditStartData.auditGroupInstances.every((ag) =>
            ag.auditGroupCheckListInstances.every((agcl) => agcl.status === "COMPLETED")
        );
        if (!isAllAuditGroupCompleted) {
            handleToast({
                title: t(TranslationKeys.AUDIT_ALL_CHECKLIST_REQUIRED),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        setAuditStartIsConfirmModalOpen(true);
    };

    return {
        isAuditStartSubmiting,
        isAuditStartConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        confirmAuditStartModalSubmit,
        handleAuditStartConfirmModalOpen,
        isAuditStartLoading,
        fetchAuditStartData,
        auditStartData,
        setAuditStartData,
    };
};
