import { faKeySkeleton, faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowPositionModel } from "app/components_v2/Table/types";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type MobileRoleGridBodyProps = {
    role: RoleModel;
    onEditClick: () => void;
    rowPosition: RowPositionModel;
};

export const MobileRoleGridBody: FC<MobileRoleGridBodyProps> = ({ role, onEditClick, rowPosition }) => {
    const nav = useNavigate();

    const { name, id } = role;
    return (
        <div className={`mobileRoleGridBody--${rowPosition}`}>
            <div className="mobileRoleGridBody__container">
                <p className="mobileRoleGridBody__name">{name}</p>
                <div className="mobileRoleGridBody__user">
                    <div className="mobileRoleGridBody__icon" onClick={onEditClick}>
                        <FontAwesomeIcon className="mobileRoleGridBody__icon__edit" icon={faPen} />
                    </div>
                </div>
            </div>
            <div className="mobileRoleGridBody__action" onClick={() => nav(`edit/${id}`)}>
                <FontAwesomeIcon className="mobileRoleGridBody__action__edit" icon={faKeySkeleton} />
            </div>
        </div>
    );
};
