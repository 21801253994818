import { FC } from "react";
import { useTranslation } from "react-i18next";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { AnalistTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { BarChart } from "app/components_v2/BarChart/BarChart";
import { TaskInstanceStatus } from "app/shared/Constants";
import { SupervisorAnalistDarkColor } from "app/components_v2/SupervisorAnalist/types";
import { omitEmptyDays } from "app/helpers/analist/filterBarChart";
import { AnalistDetailEntityBarChartModel } from "app/models/03-REP/Analytics/AnalistEntityDetailBarChartModel";
import { useWindowSize } from "usehooks-ts";

type AnalistDetailBarChartProps = {
    isLoading?: boolean;
    chartData: AnalistDetailEntityBarChartModel | null;
    hasNoData: boolean;
};
export const AnalistDetailBarChart: FC<AnalistDetailBarChartProps> = ({ isLoading = false, chartData, hasNoData }) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const chartDataFiltered = omitEmptyDays(chartData);

    const getColor = (taskStatus: string): SupervisorAnalistDarkColor => {
        if (taskStatus === TaskInstanceStatus.SHARED_TIMED_OUT) return "darkRed";
        if (taskStatus === TaskInstanceStatus.TIMED_OUT) return "red";
        if (taskStatus === TaskInstanceStatus.IN_TIME) return "green";
        return "yellow";
    };

    const getLabel = (taskStatus: string): string => {
        if (taskStatus === TaskInstanceStatus.SHARED_TIMED_OUT) return t(TranslationKeys.SHARED_TIMED_OUT);
        if (taskStatus === TaskInstanceStatus.TIMED_OUT) return t(TranslationKeys.TIMED_OUT);
        if (taskStatus === TaskInstanceStatus.IN_TIME) return t(TranslationKeys.IN_TIME);
        return t(TranslationKeys.OUT_OF_TIME);
    };

    return hasNoData ? (
        <TableError icon={faListCheck} title={t(TranslationCommon.NO_DATA_FOR_YOUR_SEARCH)} />
    ) : isLoading ? (
        <div className="spinnerContainer--moreHeight">
            <Spinner />
        </div>
    ) : (
        chartDataFiltered && (
            <BarChart
                datasets={chartDataFiltered.analistBarChart.map(({ chartValues, taskStatus }) => ({
                    data: chartValues,
                    color: getColor(taskStatus),
                    label: getLabel(taskStatus),
                }))}
                labels={chartDataFiltered.labels}
                yAxisTitle={t(AnalistTranslation.TOTAL_TASKS_NUMBER)}
                xMax={width < 768 ? 2 : width < 1024 ? 6 : 4}
            />
        )
    );
};
