import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePendingTasksCompanyDepartmentModal } from "app/hooks/PendingTasksCompanyDepartmentModal/usePendingTasksCompanyDepartmentModal";
import { faArrowRight, faCalendarCheck, faCalendarTimes } from "@fortawesome/pro-regular-svg-icons";
import { mapEntityListToFormValuesList, mapMinMaxDates } from "./helpers/mappers";
import { PendingTasksCompanyDepartmentTranslations, TranslationCommon } from "app/translation/translationKeys";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { EntityPendingTasksCard } from "./EntityPendingTasksCard/EntityPendingTasksCard";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";

export const PendingTasksCompanyDepartmentModal = () => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const {
        companies,
        companiesAndDepartmentsWithPendingTasks,
        companyDateRangeLimits,
        confirmModalDescription,
        confirmModalFetch,
        confirmModalTitle,
        departmentDateRangeLimits,
        departments,
        entityStatus,
        isConfimModalOpen,
        isLoading,
        isLoadingClosed,
        isLoadingNotClosed,
        isOpenCompaniesCollapsable,
        isOpenDepartmentsCollapsable,
        onChangeDateRange,
        onClickClosed,
        onClickNotClosed,
        onSelectEntity,
        reset,
        setCompanies,
        setCompanyDateRangeLimits,
        setDepartmentDateRangeLimits,
        setDepartments,
        setIsConfimModalOpen,
        setIsLoading,
        setIsOpenCompaniesCollapsable,
        setIsOpenDepartmentsCollapsable,
        handleCheckAll,
        isAllChecked,
        isIndeterminated,
    } = usePendingTasksCompanyDepartmentModal();

    const hasSelectedItems = companies.some(({ checked }) => checked) || departments.some(({ checked }) => checked);

    const getFooterButtonTitle = () =>
        hasSelectedItems
            ? translateCustomerTypeKeys(
                  PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_YES_CLOSE,
                  { firstReplace: "SINGULAR", isGenderSensitive: true }
              )
            : translateCustomerTypeKeys(
                  PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_NOT_CLOSED,
                  { firstReplace: "SINGULAR", isGenderSensitive: true }
              );

    const getFooterButtonIcon = () => (hasSelectedItems ? faCalendarTimes : faCalendarCheck);

    const getFooterButtonOnClick = () => (hasSelectedItems ? onClickClosed : onClickNotClosed);

    const getFooterButtonLoading = () => (hasSelectedItems ? isLoadingClosed : isLoadingNotClosed);

    useEffect(() => {
        setIsLoading(true);
        const mappedCompanies = mapEntityListToFormValuesList(companiesAndDepartmentsWithPendingTasks.companies);
        const mappedDepartments = mapEntityListToFormValuesList(companiesAndDepartmentsWithPendingTasks.departments);
        setCompanies(mappedCompanies);
        setDepartments(mappedDepartments);
        setCompanyDateRangeLimits(mapMinMaxDates(mappedCompanies));
        setDepartmentDateRangeLimits(mapMinMaxDates(mappedDepartments));
        if (!!mappedCompanies.length || !!mappedDepartments.length) setIsLoading(false);
    }, []);

    return (
        <>
            {isConfimModalOpen && (
                <ConfirmModal
                    title={confirmModalTitle[entityStatus]}
                    description={confirmModalDescription[entityStatus]}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={confirmModalFetch[entityStatus]}
                    onClose={() => setIsConfimModalOpen(false)}
                />
            )}
            <GenericModal
                header={{
                    title: translateCustomerTypeKeys(
                        PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_TITLE,
                        { firstReplace: "PLURAL" }
                    ),
                }}
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: getFooterButtonTitle(),
                            iconRight: getFooterButtonIcon(),
                            onClick: getFooterButtonOnClick(),
                        }}
                        loading={getFooterButtonLoading()}
                        closeButton={{
                            text: t(TranslationCommon.OMIT),
                            onClick: reset,
                            iconRight: faArrowRight,
                        }}
                    />
                }
                size="md"
            >
                {isLoading ? (
                    <div className="pendingTasksCompanyDepartmentModal__spinner">
                        <Spinner />
                    </div>
                ) : (
                    <div className="pendingTasksCompanyDepartmentModal">
                        <h2 className="pendingTasksCompanyDepartmentModal__title">
                            {translateCustomerTypeKeys(
                                PendingTasksCompanyDepartmentTranslations.PENDING_COMPANIES_AND_DEPARTMENTS_DETECTED_DESCRIPTION,
                                { firstReplace: "PLURAL", isGenderSensitive: true }
                            )}
                        </h2>
                        <CheckBox
                            label={t(TranslationCommon.SELECT_ALL)}
                            checked={isAllChecked()}
                            intermediate={isIndeterminated()}
                            onChange={handleCheckAll}
                        />

                        {!!companies.length && (
                            <Collapsable
                                border="bottom"
                                isCollapsable
                                title={translateCustomerTypeKeys(
                                    PendingTasksCompanyDepartmentTranslations.COMPANY_COLLAPSABLE_TITLE,
                                    { firstReplace: "PLURAL", isGenderSensitive: true }
                                )}
                                onChange={() => setIsOpenCompaniesCollapsable(!isOpenCompaniesCollapsable)}
                                open={isOpenCompaniesCollapsable}
                            >
                                {companies.map((company) => {
                                    const minMaxValue = companyDateRangeLimits.find((date) => date.id === company.id);
                                    return (
                                        <EntityPendingTasksCard
                                            key={company.id}
                                            data={company}
                                            onSelectEntity={onSelectEntity}
                                            onChangeDateRange={onChangeDateRange}
                                            entitySetter={setCompanies}
                                            min={minMaxValue?.minDate || company.startDate}
                                            max={minMaxValue?.maxDate || company.endDate || company.startDate}
                                        />
                                    );
                                })}
                            </Collapsable>
                        )}
                        {!!departments.length && (
                            <Collapsable
                                border="bottom"
                                isCollapsable
                                title={t(PendingTasksCompanyDepartmentTranslations.DEPARTMENT_COLLAPSABLE_TITLE)}
                                onChange={() => setIsOpenDepartmentsCollapsable(!isOpenDepartmentsCollapsable)}
                                open={isOpenDepartmentsCollapsable}
                            >
                                {departments.map((department) => {
                                    const minMaxValue = departmentDateRangeLimits.find(
                                        (date) => date.id === department.id
                                    );
                                    return (
                                        <EntityPendingTasksCard
                                            key={department.id}
                                            data={department}
                                            onSelectEntity={onSelectEntity}
                                            onChangeDateRange={onChangeDateRange}
                                            entitySetter={setDepartments}
                                            min={minMaxValue?.minDate || department.startDate}
                                            max={minMaxValue?.maxDate || department.endDate || department.startDate}
                                        />
                                    );
                                })}
                            </Collapsable>
                        )}
                    </div>
                )}
            </GenericModal>
        </>
    );
};
