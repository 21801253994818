import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { ProgressBar } from "app/components_v2/ProgressBar/ProgressBar";
import MultiPercentageBar from "app/components_v2/MultiPercentageBar/MultiPercentageBar";
import { SupervisorAnalistColors } from "app/components_v2/SupervisorAnalist/types";

export type SectionModel = {
    totalPercentage: number;
    label: string;
    color: SupervisorAnalistColors;
    count: number;
};

export const ProgressBarSection: FC<SectionsShowProp> = ({ show }) => {
    const exampleSections: SectionModel[] = [
        {
            totalPercentage: 70,
            label: "Realizadas",
            color: "green",
            count: 980,
        },
        {
            totalPercentage: 20,
            label: "Fuera de Tiempo",
            color: "yellow",
            count: 250,
        },
        {
            totalPercentage: 10,
            label: "Vencidas",
            color: "red",
            count: 140,
        },
    ];
    return (
        <Section title="ProgressBar" show={show}>
            <div className="progressBarSection__progressbar">
                <h3>Size sm:</h3>
                <ProgressBar color="green" percentage={0} size="sm" />
                <ProgressBar color="yellow" percentage={25} size="sm" />
                <ProgressBar color="blue" percentage={50} size="sm" />
                <ProgressBar color="green" percentage={75} size="sm" />
                <ProgressBar color="red" percentage={100} size="sm" />

                <h3>Size md:</h3>
                <ProgressBar color="red" percentage={0} size="md" />
                <ProgressBar color="blue" percentage={25} size="md" />
                <ProgressBar color="yellow" percentage={50} size="md" />
                <ProgressBar color="red" percentage={75} size="md" />
                <ProgressBar color="green" percentage={100} size="md" />
            </div>

            <div className="progressBarSection">
                <h3>MULTIPERCENTAGEBAR</h3>
                <MultiPercentageBar sections={exampleSections} />
            </div>
        </Section>
    );
};
