import { useAssetForm } from "./hooks/useAssetForm";
import { useTranslation } from "react-i18next";
import {
    AssetTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { isEqual } from "lodash";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { AssetFormInfo } from "./AssetFormInfo/AssetFormInfo";
import { AssetDynamicFields } from "./AssetDynamicFields/AssetDynamicFields";
import { useTitle } from "app/hooks";

export const AssetForm = () => {
    const { t } = useTranslation();

    const {
        assetId,
        data,
        dataCopy,
        handleCancelModal,
        handleCloseDeleteModal,
        handleDelete,
        handleOpenDeleteModal,
        handleSave,
        handleSubmit,
        isAssetDeleted,
        isAssetDeleteModalOpen,
        isAssetDeleting,
        isAssetLoading,
        isCancelModalOpen,
        isEditPage,
        isReplaceSensorEntriesModalOpen,
        isSaving,
        onInputChange,
        sensorEntriesCount,
        setIsCancelModalOpen,
        setIsReplaceSensorEntriesModalOpen,
        validations,
        isSensorLoading,
    } = useAssetForm();

    const title = isEditPage ? t(TranslationKeys.EDIT_ASSET) : t(TranslationKeys.NEW_ASSET);

    useTitle(t(isEditPage ? TranslationTitles.EDIT_ASSET_PAGE_TITLE : TranslationTitles.NEW_ASSET_PAGE_TITLE));

    const handleDeleteModal = async () => {
        await handleDelete();
        handleCloseDeleteModal();
    };

    const getDeleteModalDescription = (): string => {
        if (isAssetDeleted) return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_ACTIVATE);
        if (data.isRelatedWithTasks) return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE);
        return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED);
    };

    const replaceSensorSerialNumberModalDescription = t(
        AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_DESCRIPTION
    ).replace(/\{(\d+)\}/g, (_, i) => [data.sensorSerialNumber, String(sensorEntriesCount), data.name][+i]);

    return (
        <>
            {isAssetDeleteModalOpen && (
                <ConfirmModal
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleDeleteModal}
                    onConfirmText={
                        isAssetDeleted
                            ? t(TranslationKeys.EDIT_FORM_USER_ACTIVATE)
                            : t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE)
                    }
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={
                        isAssetDeleted
                            ? t(TranslationKeys.ASSET_MODAL_TITLE_ACTIVATE).replace("{0}", data.name)
                            : t(TranslationKeys.ASSET_MODAL_TITLE_DEACTIVATE).replace("{0}", data.name)
                    }
                    description={getDeleteModalDescription()}
                    isLoading={isAssetDeleting}
                    variant="appcc"
                />
            )}

            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={() => setIsCancelModalOpen(false)}
                    onConfirm={handleCancelModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                    variant="appcc"
                />
            )}
            {isReplaceSensorEntriesModalOpen && (
                <ConfirmModal
                    title={t(AssetTranslations.ASSET_FORM_REPLACE_SENSOR_ENTRIES_TITLE)}
                    description={replaceSensorSerialNumberModalDescription}
                    onConfirm={handleSave}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onClose={() => setIsReplaceSensorEntriesModalOpen(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    variant="appcc"
                    isLoading={isSaving}
                />
            )}
            <FormLayout
                isLoading={isAssetLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: !isAssetDeleted
                        ? t(TranslationKeys.WORKING_POSITION_DEACTIVE)
                        : t(TranslationKeys.WORKING_POSITION_ACTIVE),
                    showDeleteButton: hasPermissionToDelete(SecScreen.ASSETS) && isEditPage,
                    type: "disable",
                    badge: { isVisible: isEditPage },
                    isActive: !isAssetDeleted,
                    onClick: handleOpenDeleteModal,
                    title: title,
                    variant: "appcc",
                }}
                footer={
                    ((isEditPage && hasPermissionToEdit(SecScreen.ASSETS)) ||
                        (!isEditPage && hasPermissionToAdd(SecScreen.ASSETS))) &&
                    !isAssetDeleted && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "assetForm",
                                type: "submit",
                                disabled: isAssetDeleted,
                                variant: "appcc",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: () => setIsCancelModalOpen(true),
                                buttonType: "tertiary",
                                disabled: isEqual(data, dataCopy) || isAssetDeleted,
                                variant: "appcc",
                            }}
                            loading={isSaving || isSensorLoading}
                        />
                    )
                }
            >
                <div className="assetFormPageContainer">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        className="assetForm"
                        id="assetForm"
                    >
                        <div className="assetForm__container">
                            <AssetFormInfo
                                {...data}
                                onInputChange={onInputChange}
                                isDisabled={
                                    (isEditPage && !hasPermissionToEdit(SecScreen.ASSETS)) ||
                                    (!isEditPage && !hasPermissionToAdd(SecScreen.ASSETS)) ||
                                    isAssetDeleted
                                }
                                customError={validations}
                                id={assetId != null ? Number(assetId) : undefined}
                                isEditPage={isEditPage}
                            />
                            {data.fK_AssetType !== 0 && (
                                <AssetDynamicFields
                                    {...data}
                                    onInputChange={onInputChange}
                                    isDisabled={
                                        (isEditPage && !hasPermissionToEdit(SecScreen.ASSETS)) ||
                                        (!isEditPage && !hasPermissionToAdd(SecScreen.ASSETS)) ||
                                        isAssetDeleted
                                    }
                                    customError={validations}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </FormLayout>
        </>
    );
};
