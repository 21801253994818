import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { v4 } from "uuid";

export const taskInstancePhotoModelToImageDragFile = (taskInstance?: TaskInstanceModel): ImageDragFile[] => {
    const taskInstancePhotos = taskInstance?.taskInstancePhotos;
    if (!taskInstancePhotos) return [];

    return taskInstancePhotos.map(({ taskPhoto, taskPhotoId }) => ({
        fileName: taskPhotoId ? `photo_${taskPhotoId}.jpg` : "photo.jpg",
        id: v4(),
        isErrored: false,
        isLoading: false,
        dbId: taskPhotoId,
        mediaType: "jpg",
        url: taskPhoto,
    }));
};
