import { FC, useEffect, useState } from "react";
import { RankingFilterModel } from "./types";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { isEqual } from "lodash";
import { SelectOptions } from "app/components_v2/__inputs";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { useJobDescriptionSelector } from "./hooks/useJobDescriptionSelector";
import { AnalistRankingTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";

type RankingFilterProps = {
    filterData: RankingFilterModel;
    initialFilterData: RankingFilterModel;
    showSecondaryFilter: boolean;
    onCancel?: () => void;
    onSave: (values: RankingFilterModel) => void;
};

export const RankingFilter: FC<RankingFilterProps> = ({
    filterData,
    initialFilterData,
    showSecondaryFilter,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();
    const { jobDescriptionOptions, isLoading, getJobDescriptionSelector } = useJobDescriptionSelector();

    const [internalFilterData, setInternalFilterData] = useState<RankingFilterModel>(filterData);

    const handleInputChange = (values: Partial<RankingFilterModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleLeaveFilter = () => {
        handleInputChange(filterData || initialFilterData);
        onCancel && onCancel();
    };

    const onReset = () => handleInputChange(initialFilterData);

    useEffect(() => {
        getJobDescriptionSelector();
    }, []);

    return (
        <SecondaryFilter
            isOpen={showSecondaryFilter}
            onClickOutside={handleLeaveFilter}
            onCancel={handleLeaveFilter}
            onReset={onReset}
            onSave={() => onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <div className="rankingFilter">
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => handleInputChange({ jobDescriptionId: value })}
                        options={jobDescriptionOptions}
                        selectedValue={
                            internalFilterData.jobDescriptionId !== "-1"
                                ? internalFilterData.jobDescriptionId
                                : undefined
                        }
                        label={t(AnalistRankingTranslations.JOB_DESCRIPTION_LABEL)}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                    />
                </div>
            )}
        </SecondaryFilter>
    );
};
