import { AssetTabType } from "app/shared/types/AssetTypes";
import { AssetGridSF } from "../../types";

type IfillExtraparams = {
    companyId: string;
    fK_PlanAPPCC: string;
    tab: AssetTabType;
    filterData: AssetGridSF;
};

export const fillExtraparams = ({ fK_PlanAPPCC, companyId, tab, filterData }: IfillExtraparams): string => {
    const extraparams = new URLSearchParams();

    const { hasConsecutiveIssues, hasSensorCode, isNotWorking } = filterData;

    extraparams.append("Tab", tab);
    if (companyId !== "-1") extraparams.append("CompanyId", companyId);
    if (fK_PlanAPPCC !== "-1") extraparams.append("FK_PlanAPPCC", String(fK_PlanAPPCC));
    if (hasConsecutiveIssues !== "-1") extraparams.append("HasConsecutiveIssues", hasConsecutiveIssues);
    if (hasSensorCode !== "-1") extraparams.append("HasSensorCode", hasSensorCode);
    if (isNotWorking !== "-1") extraparams.append("IsNotWorking", isNotWorking);

    return extraparams.toString();
};

export const mapTabNumberToTabType = (tabNumber: number) => {
    const tabRecord: Record<number, AssetTabType> = {
        0: "WITHOUT_SENSOR",
        1: "WITH_SENSOR",
        2: "DEACTIVATED",
    };
    return tabRecord[tabNumber];
};
