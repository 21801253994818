import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faArrowLeft, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { Tag } from "app/components_v2/Tag/Tag";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type SumaryUsersAddedDeletedModalProps = {
    onConfirm: () => void;
    onClose: () => void;
    title: string;
    description: string;
    usersAdded: OptionModel[];
    usersDeleted: OptionModel[];
};

export const SumaryUsersAddedDeletedModal: FC<SumaryUsersAddedDeletedModalProps> = ({
    onClose,
    onConfirm,
    description,
    title,
    usersAdded,
    usersDeleted,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <ConfirmModal
                onConfirm={onConfirm}
                onClose={onClose}
                onConfirmText={t(TranslationCommon.YES_CHANGE)}
                onConfirmIcon={faCheck}
                title={title}
                description={description}
                onCloseText={t(TranslationCommon.NO_GOBACK)}
                onCloseIcon={faArrowLeft}
            >
                {!!usersAdded.length && (
                    <Collapsable isCollapsable={!!usersDeleted.length} title={t(TranslationKeys.ADDEDS_USERS_TO_TEAM)}>
                        <div className="gap--8 flex fullWidth wrap">
                            {usersAdded.map((user) => (
                                <Tag
                                    key={user.value}
                                    message={user.label}
                                    avatar={{
                                        id: user.value,
                                        initials: user.initials || "",
                                        name: user.label,
                                        profilePictureURL: user.profilePictureURL,
                                    }}
                                    width="auto"
                                    pointer={false}
                                    variant="green"
                                />
                            ))}
                        </div>
                    </Collapsable>
                )}
                {!!usersDeleted.length && (
                    <Collapsable isCollapsable={!!usersAdded.length} title={t(TranslationKeys.DELETEDS_USERS_TO_TEAM)}>
                        <div className="gap--8 flex fullWidth wrap">
                            {usersDeleted.map((user) => (
                                <Tag
                                    key={user.value}
                                    message={user.label}
                                    avatar={{
                                        id: user.value,
                                        initials: user.initials || "",
                                        name: user.label,
                                        profilePictureURL: user.profilePictureURL,
                                    }}
                                    width="auto"
                                    pointer={false}
                                    variant="red"
                                />
                            ))}
                        </div>
                    </Collapsable>
                )}
            </ConfirmModal>
        </>
    );
};
