import { FC } from "react";
import { NotificationIcon } from "../NotificationIcon/NotificationIcon";
import { ToatsProps } from "./toastModels";
import Spinner from "../Spinner/Spinner";

export const Toasts: FC<ToatsProps> = ({
    type = "notification",
    variant = "primary",
    title,
    subtitle,
    onClick,
    onClick2,
    isLoading,
}) => {
    return (
        <div className="toast">
            <div className="toast__container">
                <div className="toast__container__title">
                    {isLoading && <Spinner />}
                    {variant !== "none" && !isLoading && <NotificationIcon variant={variant} type={type} />}
                    {title && <h3 className="toast__title">{title}</h3>}
                </div>
                {subtitle && (
                    <div className="toast__container__body">
                        <p className="toast__subtitle">{subtitle}</p>
                    </div>
                )}
                {(onClick || onClick2) && (
                    <div className="toast__container__footer">
                        {onClick && (
                            <button
                                className="toast__buttons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClick.onClick();
                                }}
                            >
                                {onClick.text}
                            </button>
                        )}
                        {onClick2 && (
                            <button
                                className="toast__buttons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClick2.onClick();
                                }}
                            >
                                {onClick2.text}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
