import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { ExportCsvModal } from "app/components_v2/__modals/ExportCsvModal/ExportCsvModal";

export const ExportCSVModalSection: FC<SectionsShowProp> = ({ show }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <Section title="Export csv Modal" show={show}>
            <button onClick={() => setIsModalOpen((prev) => !prev)}>abrir/cerrar</button>
            {isModalOpen && (
                <ExportCsvModal
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={(params) => {
                        setIsModalOpen(false);
                    }}
                    title="Exportar como csv"
                    pageIndex={2}
                    total={150}
                />
            )}
        </Section>
    );
};
