import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../__modals/base/Modal/Modal";
import { MediaCapture } from "./MediaCapture/MediaCapture";
import { AddNewButton } from "../__buttons/AddNewButton/AddNewButton";
import { Button } from "../__buttons/Button/Button";

type WebcamCaptureProps = {
    onPhotoCapture: (photoData: string) => void;
    closeModal: () => void;
};

const WebcamCapture: React.FC<WebcamCaptureProps> = ({ onPhotoCapture, closeModal }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);

    const handleOrientation = () => {
        closeCamera();
        setTimeout(startCamera, 300);
    };

    const startCamera = async () => {
        try {
            const videoOptions: MediaTrackConstraints = {
                width: {
                    min: 1280,
                    ideal: 1920,
                },
                height: {
                    min: 720,
                    ideal: 1080,
                },
                facingMode: { exact: "environment" },
            };
            const stream = await navigator.mediaDevices.getUserMedia({
                video: videoOptions,
            });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error("Error accessing webcam:", error);
            handleToast({
                title: t(TranslationModals.TOAST_PHOTO_ERROR_TITLE),
                subtitle: t(TranslationModals.TOAST_PHOTO_ERROR_SUBTITLE),
                variant: "danger",
                type: "alert",
            });
            closeModal();
        }
    };

    const takePhoto = () => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");
            if (!context) return;

            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                if (!blob) return;
                const file = new File([blob], "imagen.png", { type: "image/png" });
                setImageFile(file);
            });
        }
    };

    const acceptPhoto = (photoBase64: string) => {
        onPhotoCapture(photoBase64);
        setImageFile(null);
        closeCamera();
    };

    const handleCloseModal = () => {
        closeCamera();
        closeModal();
    };

    const closeCamera = () => {
        if (!videoRef.current || !videoRef.current?.srcObject) return;
        const stream = videoRef.current?.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
            if (track.readyState == "live") {
                track.stop();
            }
        });
    };

    const deletePhoto = () => {
        setImageFile(null);
    };

    useEffect(() => {
        startCamera();
    }, []);

    useEffect(() => {
        window.addEventListener("orientationchange", handleOrientation);

        return () => {
            window.removeEventListener("orientationchange", handleOrientation);
        };
    }, []);

    return (
        <Modal portal className="zIndexUp webcamCapture" showBlur={false}>
            <div className="webcamCapture__container">
                <MediaCapture
                    videoRef={videoRef}
                    imageFile={imageFile}
                    onCancelImage={deletePhoto}
                    onAccept={acceptPhoto}
                />

                <canvas ref={canvasRef} style={{ display: "none" }} />
                {!imageFile && (
                    <div className="webcamCapture__actions">
                        <>
                            <AddNewButton onClick={handleCloseModal} variant="white">
                                {t(TranslationCommon.CANCEL)}
                            </AddNewButton>
                            <Button type="tertiary" iconLeft={faCamera} onClick={takePhoto} />
                        </>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default WebcamCapture;
