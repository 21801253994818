import { Avatar } from "app/components_v2/Avatar/Avatar";
import { faCalendarDays, faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { NotificationsCompanyModal } from "./NotificationsCompanyModal/NotificationsCompanyModal";
import { NotificationsDepartmentModal } from "./NotificationsDepartmentModal/NotificationsDepartmentModal";
import { NotificationsQrModal } from "./NotificationsQrModal/NotificationsQrModal";
import { ScheduleNotificationModal } from "../ScheduleNotificationModal/ScheduleNotificationModal";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { TextArea } from "app/components_v2/__inputs";
import { TranslationKeys } from "app/translation/translationKeys";
import { useNotificationModal } from "./hooks/useNotificationModal";
import { useTranslation } from "react-i18next";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type NotificationsModalProps = {
    onClose: () => void;
    handleSubmitMessage?: () => void;
    userId?: number;
    userName?: string;
    profileURL?: string;
    portal?: boolean;
};

export const NotificationsModal: FC<NotificationsModalProps> = ({
    onClose,
    handleSubmitMessage,
    userId,
    userName,
    profileURL,
    portal,
}) => {
    const { t } = useTranslation();
    const { translateCompany } = useCustomerTypeTranslation();
    const {
        handleSubmit,
        setShowProgramNotifsModal,
        showProgramNotifsModal,
        currentTab,
        loading,
        maxSizeError,
        openProgramedNotificationModal,
        notificationAssigned,
        uploadAudio,
        uploadFile,
        textAreaError,
        textAreaValue,
        onChangeTextarea,
        onSelectDepartment,
        onSelectCompany,
        onSelectQr,
        onChangeCurrentTab,
    } = useNotificationModal({
        onClose,
        handleSubmitMessage,
        userId,
    });

    const tabs: TabPropsReduced[] = [
        {
            onClick: onChangeCurrentTab,
            text: t(TranslationKeys.TASK_FILTER_DEPARTMENT),
            children: <NotificationsDepartmentModal {...notificationAssigned} onSelect={onSelectDepartment} />,
            type: "dark",
        },
        {
            onClick: onChangeCurrentTab,
            text: translateCompany("PLURAL"),
            children: <NotificationsCompanyModal {...notificationAssigned} onSelect={onSelectCompany} />,
            type: "dark",
        },
        {
            onClick: onChangeCurrentTab,
            text: t(TranslationKeys.TASK_FILTER_WORKING_POSITION),
            children: <NotificationsQrModal {...notificationAssigned} onSelect={onSelectQr} />,
            type: "dark",
        },
    ];

    return (
        <>
            {showProgramNotifsModal && (
                <ScheduleNotificationModal
                    onSubmit={handleSubmit}
                    closeModal={() => setShowProgramNotifsModal(false)}
                />
            )}

            <GenericModal
                header={{
                    title: t(TranslationKeys.NEW_ALERT),
                    onClose,
                    headerChildren: !userId ? <Tabs tabs={tabs} currentTab={currentTab} hideChildren /> : undefined,
                    headerImage: !userId ? "/headerBackground.jpg" : undefined,
                }}
                size="lg"
                hideChildrenPadding
                portal={portal}
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationKeys.SEND),
                            iconRight: faPaperPlaneTop,
                            type: "submit",
                            form: "notifiactionsModal",
                            disabled: !!maxSizeError,
                        }}
                        closeButton={
                            currentTab === 2
                                ? undefined
                                : {
                                      text: t(TranslationKeys.NOTIFICATION_SCHEDULE),
                                      iconRight: faCalendarDays,
                                      buttonType: "secondary",
                                      onClick: openProgramedNotificationModal,
                                      disabled: !!maxSizeError,
                                  }
                        }
                        loading={loading}
                    />
                }
            >
                <form
                    className="notifiactionsModal"
                    id="notifiactionsModal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <div className="notifiactionsModal__container">
                        {userId && userName ? (
                            <div className="notifiactionsModal__container__avatar">
                                <p className="notifiactionsModal__container__avatar__title">
                                    {t(TranslationKeys.SEND_TO)}
                                </p>
                                <Avatar
                                    avatarName={userName}
                                    colorId={userId}
                                    size="xs"
                                    name={userName}
                                    img={profileURL ? profileURL : ""}
                                />
                            </div>
                        ) : (
                            tabs[currentTab].children
                        )}
                        <TextArea
                            label={t(TranslationKeys.SINGLE_ALERT)}
                            text={textAreaValue}
                            maxLength={300}
                            placeholder={t(TranslationKeys.ALERT_TEXTAREA_PLACEHOLDER)}
                            errorMessage={
                                textAreaError
                                    ? t(TranslationKeys.FILL_THIS_FIELD)
                                    : maxSizeError
                                    ? maxSizeError
                                    : undefined
                            }
                            onChange={onChangeTextarea}
                            uploadFile={uploadFile}
                            uploadAudio={uploadAudio}
                        />
                    </div>
                </form>
            </GenericModal>
        </>
    );
};
