import { Input } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { GroupCompanyErrorFormValues, GroupCompanyFormValues } from "./types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type GroupCompanyModalProps = {
    errorMesages: GroupCompanyErrorFormValues;
    isEdit?: boolean;
    isLoading: boolean;
    values: GroupCompanyFormValues;
    onChange: (values: Partial<GroupCompanyFormValues>) => void;
    onClose: () => void;
    onSubmit: (e: FormEvent) => void;
};

export const GroupCompanyModal: FC<GroupCompanyModalProps> = ({
    isEdit,
    isLoading,
    values,
    errorMesages,
    onChange,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const { name, nif } = values;
    const { errorName, errorNif } = errorMesages;

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(e);
    };

    return (
        <GenericModal
            header={{
                title: translateCustomerTypeKeys(TranslationKeys.EDIT_COMPANY_GROUP_TITLE, { firstReplace: "PLURAL" }),
                onClose: onClose,
            }}
            portal
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        type: "submit",
                        text: isEdit ? t(TranslationCommon.SAVE) : t(TranslationCommon.CREATE),
                        fullWidth: true,
                        form: "groupCompanyModal",
                    }}
                    closeButton={{
                        onClick: onClose,
                        text: t(TranslationCommon.CANCEL),
                        fullWidth: true,
                        buttonType: "tertiary",
                    }}
                    buttonsJustification="end"
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={handleSubmit} className="groupCompanyModal" id="groupCompanyModal">
                <Input
                    label={t(TranslationKeys.GROUP_COMPANY_FORM_NAME_LABEL)}
                    value={name}
                    errorMessage={errorName}
                    placeholder={translateCustomerTypeKeys(TranslationKeys.GROUP_COMPANY_FORM_NAME_PLACEHOLDER, {
                        firstReplace: "SINGULAR",
                    })}
                    onChange={(newValue) => onChange({ name: newValue })}
                />
                <Input
                    label={t(TranslationKeys.EDIT_FORM_COMPANY_NIF)}
                    value={nif}
                    errorMessage={errorNif}
                    placeholder={t(TranslationKeys.EDIT_FORM_COMPANY_NIF)}
                    onChange={(newValue) => onChange({ nif: newValue })}
                    max={9}
                />
            </form>
        </GenericModal>
    );
};
