import { useSession } from "app/hooks";
import { Icon } from "app/components_v2/Icon/Icon";
import { MarkableIcon } from "app/components_v2/MarkableIcon/MarkableIcon";
import { UploadPhoto } from "app/components_v2/UploadPhoto/UploadPhoto";
import { faArrowRightFromBracket, faBellSlash as activeBell } from "@fortawesome/pro-solid-svg-icons";
import { faBellOn as inactiveBell } from "@fortawesome/pro-regular-svg-icons";
import { FC, useCallback, useState } from "react";
import { ProfileService } from "app/services";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { useLogOut } from "app/hooks/useLogout";
import { StarRatingBadge } from "app/components_v2/__stars/StarRatingBadge/StarRatingBadge";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { ImageBase64String } from "app/dtos/ImageBase64String";
import { UserModel } from "app/models/01-SEG/User/UserModel";

type MyProfileHeaderProps = {
    isLoading: boolean;
    user?: UserModel;
    onOpenModal: () => void;
};

export const MyProfileHeader: FC<MyProfileHeaderProps> = ({ isLoading, user, onOpenModal }) => {
    const session = useSession();
    const handleLogOut = useLogOut();
    const dispatch = useDispatch();

    const [doNotDisturb, setDoNotDisturb] = useState<boolean>(session?.user.doNotDisturbMode!);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const hasPermissionsToViewNotifications = hasPermission(SecScreen.SYSTEM_NOTIFICATION);

    const onChangeDoNotDistubMode = useCallback(() => {
        if (timeoutId) clearTimeout(timeoutId);

        const newTimeoutId = setTimeout(() => handleDoNotDirturbMode(), 500);
        setTimeoutId(newTimeoutId);
        setDoNotDisturb(!doNotDisturb);
    }, [timeoutId]);

    const handleDoNotDirturbMode = async () => {
        await ProfileService.UpdateDoNotDisturbMode({ value: !doNotDisturb });
        dispatch(Actions.updateDoNotDisturb(!doNotDisturb));
    };

    const handlePhotoUpload = async (imageBase64: ImageBase64String) => {
        await ProfileService.UploadPhoto(imageBase64);
        dispatch(Actions.updateImageProfile(imageBase64.imageBase64Content));
    };

    return (
        <div className="myProfile__header">
            <div className="myProfile__header__doNotDisturb">
                {hasPermissionsToViewNotifications && (
                    <MarkableIcon
                        isActive={doNotDisturb}
                        onClick={onChangeDoNotDistubMode}
                        activeIcon={activeBell}
                        inactiveIcon={inactiveBell}
                        variant={doNotDisturb ? "warning" : "primary"}
                        allowHover
                    />
                )}
                <Icon onClick={handleLogOut} icon={faArrowRightFromBracket} />
            </div>
            <div className="myProfile__header__user">
                <UploadPhoto photoUrl={session?.user.profilePictureURL || ""} onUpload={handlePhotoUpload} />
                <div className="myProfile__header__user__info">
                    <p className="myProfile__header__user__info__name">{`${session?.user.firstName} ${session?.user.lastName}`}</p>
                    <StarRatingBadge
                        isLoading={isLoading}
                        starRatingAVG={user?.actualAVG || 0}
                        trend={Number((user?.trend || 0).toFixed(0))}
                        total={user?.totalRatedTasks || 0}
                        justifyContent="flex-end"
                        onClick={onOpenModal}
                    />
                </div>
            </div>
        </div>
    );
};
