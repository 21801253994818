import { BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { MobileHeader } from "app/components_v2/MobileHeader/MobileHeader";
import { FC, ReactNode } from "react";
import { CrudHeaderAvatarProps, CrudHeaderType } from "./types";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { CrudHeaderLeftNode } from "./CrudHeaderLeftNode";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";

type CrudHeaderChildrenProps = {
    crumb: BreadcrumbModel[];
    background?: "transparent" | "primary" | "image" | "grey" | undefined;
    avatar?: CrudHeaderAvatarProps;
    title?: string;
    onClick?: () => void;
    isLoading?: boolean;
    buttonLabel?: string;
    type: CrudHeaderType;
    badge?: {
        isVisible: boolean;
    };
    isActive?: boolean;
    isClosed?: boolean;
    isCompanyEdit?: boolean;
    showDeleteButton?: boolean;
    secondaryButton?: SecondaryButton;
    topNode?: ReactNode;
    leftNode?: ReactNode;
    leftNodeOneColumn?: boolean;
    rightNode?: ReactNode;
    variant?: TabHeaderVariants;
    titleSkelleton?: ReactNode;
    customHeader?: ReactNode;
};
type SecondaryButton = {
    onClickSecondaryButton: () => void;
    label: string;
    icon: IconProp;
    variant?: TabHeaderVariants;
};

export const CrudHeaderChildren: FC<CrudHeaderChildrenProps> = ({
    crumb,
    background,
    buttonLabel,
    type,
    avatar,
    badge,
    isActive,
    isLoading,
    onClick,
    title,
    isClosed,
    isCompanyEdit,
    secondaryButton,
    showDeleteButton,
    topNode,
    leftNode,
    leftNodeOneColumn,
    rightNode,
    variant = "primary",
    titleSkelleton,
    customHeader,
}) => {
    const { width } = useWindowSize();

    return (
        <MobileHeader
            crumb={crumb}
            background={background || "transparent"}
            absolute={false}
            height="auto"
            rightNode={rightNode && rightNode}
            variant={variant}
            leftNode={
                leftNode ? (
                    leftNode
                ) : (
                    <div className="crudHeaderChildren">
                        <CrudHeaderLeftNode
                            type={type}
                            avatar={avatar}
                            badge={badge}
                            isActive={isActive}
                            title={title}
                            titleSkelleton={titleSkelleton}
                            isLoading={isLoading}
                            isClosed={isClosed}
                            isCompanyEdit={isCompanyEdit}
                            leftNodeOneColumn={leftNodeOneColumn}
                            customHeader={customHeader}
                        />
                        {(secondaryButton || (width >= screenSize.TABLET && showDeleteButton)) && (
                            <div className="crudHeaderChildren__leftNodeButtons">
                                {secondaryButton &&
                                    (width < screenSize.TABLET ? (
                                        <AddNewButton
                                            icon={secondaryButton?.icon}
                                            onClick={secondaryButton?.onClickSecondaryButton}
                                            variant="white"
                                        >
                                            {secondaryButton?.label}
                                        </AddNewButton>
                                    ) : (
                                        <Button
                                            text={secondaryButton?.label}
                                            onClick={secondaryButton?.onClickSecondaryButton}
                                            iconLeft={secondaryButton?.icon}
                                            transparent={true}
                                            variant={secondaryButton.variant}
                                        />
                                    ))}
                                {width >= screenSize.TABLET && showDeleteButton && (
                                    <Button
                                        text={buttonLabel}
                                        transparent
                                        onClick={onClick}
                                        iconLeft={type === "delete" ? faTrash : undefined}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )
            }
            topNode={
                topNode
                    ? topNode
                    : showDeleteButton &&
                      width < screenSize.TABLET && (
                          <AddNewButton
                              onClick={() => onClick && onClick()}
                              variant="white"
                              icon={type === "delete" ? faTrash : undefined}
                          >
                              {buttonLabel}
                          </AddNewButton>
                      )
            }
            removePadding
        />
    );
};
