import { SortedTypeModel } from "app/components_v2/Table/types";
import { createContext } from "react";
import { PlanAPPCCGridSF } from "../../models/PlanAPPCCGridModels";

export type PlanAPPCCProviderContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    onSFChange: (sfValues: Partial<PlanAPPCCGridSF>) => void;
    secondaryFilterValues: PlanAPPCCGridSF;
};

export const PlanAPPCCContex = createContext<PlanAPPCCProviderContextModel>({} as PlanAPPCCProviderContextModel);
