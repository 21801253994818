import { ReactNode, forwardRef } from "react";
import { createPortal } from "react-dom";

type ModalProps = {
    children: ReactNode;
    showBlur?: boolean;
    portal?: boolean;
    className?: string;
};

export const Modal = forwardRef<HTMLInputElement, ModalProps>(
    ({ children, showBlur = true, portal = true, className = "" }, ref) => {
        const ModalContent = (
            <div className={`modal2 ${showBlur ? "modal2--blur" : ""} ${className}`} ref={ref}>
                {children}
            </div>
        );
        return <>{portal ? createPortal(ModalContent, document.body) : ModalContent}</>;
    }
);
