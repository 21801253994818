import { FC, useRef } from "react";
import { TaskPillSkelleton } from "app/components_v2/__skelletons/TaskPillSkelleton/TaskPillSkelleton";
import { AlertPill } from "app/components_v2/AlertPill/AlertPill";
import { useSession } from "app/hooks";
import { convertUTCtoLocaleDate } from "app/helpers";
import Pagination from "app/components_v2/Pagination/Pagination";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";
import { PaginationDefaults } from "app/shared/Constants";
import { useTranslation } from "react-i18next";

type AlertPillListProps = {
    data: AlertNotificationDto[];
    isLoading: boolean;
    total: number;
    onAlertClick: (id: number) => void;
    onChangePageIndex?: (value: number) => void;
    pageIndex?: number;
    isReceived?: boolean;
    isSended?: boolean;
    isProgrammed?: boolean;
};

export const AlertPillList: FC<AlertPillListProps> = ({
    data,
    isLoading,
    onAlertClick,
    onChangePageIndex,
    pageIndex,
    total,
    isReceived,
    isSended,
    isProgrammed,
}) => {
    const { t } = useTranslation();

    const listRef = useRef<HTMLDivElement | null>(null);
    const session = useSession();
    const getTableErrorText = () => {
        if (isReceived) return t(TranslationKeys.NO_ALERTS_RECEIVED);
        if (isSended) return t(TranslationKeys.NO_ALERTS_SENT);
        if (isProgrammed) return t(TranslationKeys.NO_ALERTS_PROGRAMMED);
    };

    return (
        <div className="alertPillList" ref={listRef}>
            {isLoading ? (
                <TaskPillSkelleton quantity={6} />
            ) : (
                <>
                    {total === 0 ? (
                        <TableError icon={faTriangleExclamation} title={getTableErrorText()} />
                    ) : (
                        data.map(
                            ({
                                sendDate,
                                id,
                                body,
                                senderUserName,
                                senderUserId,
                                receivedDate,
                                audio,
                                notificationFiles,
                                isProgrammed,
                                senderProfilePictureURL,
                                receiverNotificationList,
                                seenCount,
                                seenTotalCount,
                                fK_WorkingPosition,
                                isSent,
                                workingPositionName,
                            }) => {
                                let isProgrammedAlert = false;
                                let isSendedAlert = false;
                                const receivedDateDate = receivedDate && convertUTCtoLocaleDate(new Date(receivedDate));
                                const today = new Date();
                                const showQrInfo = !isSent && !!fK_WorkingPosition && receivedDateDate;
                                const fullName = showQrInfo ? workingPositionName : senderUserName || "";
                                const userId = showQrInfo ? fK_WorkingPosition : senderUserId;
                                const profilePictureURL = showQrInfo ? undefined : senderProfilePictureURL;

                                if (receivedDateDate && receivedDateDate > today) isProgrammedAlert = true;
                                else if (senderUserId === session?.user.id && !isProgrammedAlert) isSendedAlert = true;
                                return (
                                    <AlertPill
                                        date={
                                            (isProgrammed && !isSendedAlert && receivedDateDate) || showQrInfo
                                                ? receivedDateDate
                                                : convertUTCtoLocaleDate(sendDate)
                                        }
                                        text={body}
                                        audio={audio}
                                        files={notificationFiles}
                                        fullName={fullName}
                                        userId={userId}
                                        profilePictureUrl={profilePictureURL}
                                        status={
                                            isSended && isSendedAlert
                                                ? seenCount === seenTotalCount
                                                    ? "readed"
                                                    : "sended"
                                                : undefined
                                        }
                                        type={isProgrammedAlert ? "programmed" : "normal"}
                                        key={id}
                                        onClick={() => {
                                            onAlertClick(id);
                                        }}
                                        multipleAvatars={receiverNotificationList}
                                        assignedToQr={!!fK_WorkingPosition}
                                    />
                                );
                            }
                        )
                    )}
                    {total / PaginationDefaults.PAGE_SIZE >= 1 && (
                        <Pagination
                            onClick={(pageIndex) => {
                                onChangePageIndex && onChangePageIndex(pageIndex);
                                listRef.current?.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                            pageIndex={pageIndex || 1}
                            total={total}
                        />
                    )}
                </>
            )}
        </div>
    );
};
