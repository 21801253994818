import { AuditFilterTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { AuditGridSFModel } from "../../../state/context/auditGridContext";
import { DateRangePicker } from "app/components_v2/__inputs";
import { FC, useState } from "react";
import { getInitials } from "app/helpers/getInitials";
import { isEqual } from "lodash";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useSession } from "app/hooks/useSession";
import { useTranslation } from "react-i18next";

type AuditFilterProps = {
    filterData: AuditGridSFModel;
    initialFilterData: AuditGridSFModel;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: AuditGridSFModel) => void;
    isOpen: boolean;
    currentTab: OpenCloseStatusCode;
    variant?: TabHeaderVariants;
};

export const AuditFilter: FC<AuditFilterProps> = ({
    filterData,
    initialFilterData,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
    isOpen,
    currentTab,
    variant = "primary",
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { translateCompany, translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [internalFilterData, setInternalFilterData] = useState<AuditGridSFModel>(filterData);

    const handleInputChange = (values: Partial<AuditGridSFModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => {
        handleInputChange({
            ...initialFilterData,
            companyName: session?.workingCompany?.companyName,
        });
    };

    const handleSave = () => onSave && onSave(internalFilterData);

    const companyOptions: OptionModel[] = [
        { label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }), value: "-1" },
        ...(session?.user.companies?.map(({ companyName, companyId, logoUrl }) => ({
            label: companyName || "",
            value: String(companyId),
            initials: getInitials(companyName || ""),
            profilePictureURL: logoUrl,
        })) || []),
    ];

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant={variant}
        >
            <SelectOptions
                label={translateCompany("PLURAL")}
                selectedValue={internalFilterData.companyId}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                options={companyOptions}
                onChange={({ label, value }) => handleInputChange({ companyName: t(label), companyId: value })}
                isMulti={false}
            />
            <DateRangePicker
                startDate={internalFilterData.openDate}
                endDate={internalFilterData.openDate2}
                onChange={([openDate, openDate2]) => handleInputChange({ openDate, openDate2 })}
                label={t(AuditFilterTranslations.AUDIT_FILTER_START_DATE)}
                showMoreOptions
            />
            {currentTab === "CLOSED" && (
                <DateRangePicker
                    startDate={internalFilterData.closedDate}
                    endDate={internalFilterData.closedDate2}
                    onChange={([closedDate, closedDate2]) => handleInputChange({ closedDate, closedDate2 })}
                    label={t(AuditFilterTranslations.AUDIT_FILTER_CLOSED_DATE)}
                    showMoreOptions
                />
            )}
        </SecondaryFilter>
    );
};
