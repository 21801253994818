import { FC, useEffect, useState } from "react";
import { TimePicker } from "../TimePicker/TimePicker";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Label } from "../Label";

type TimeRangePickerProps = {
    startTime: string;
    endTime: string;
    onTimeUpdate: (startTime: string, endTime: string) => void;
    label?: string;
    labelStart?: string;
    labelEnd?: string;
    readonly?: boolean;
    disabled?: boolean;
    errorMessageStart?: string;
    errorMessageEnd?: string;
    errorMessage?: string;
    hint?: string;
    reset?: () => void;
};

export const TimeRangePicker: FC<TimeRangePickerProps> = ({
    startTime,
    endTime,
    onTimeUpdate,
    label,
    labelStart,
    labelEnd,
    readonly,
    disabled,
    errorMessageStart,
    errorMessageEnd,
    errorMessage,
    hint,
    reset,
}) => {
    const [start, setStart] = useState(startTime);
    const [end, setEnd] = useState(endTime);

    useEffect(() => {
        onTimeUpdate(start, end);
    }, [start, end]);

    useEffect(() => {
        if (startTime === "" && endTime === "" && reset) {
            setStart("");
            setEnd("");
        }
    }, [reset]);

    return (
        <div className="timeRangePicker">
            {label && <Label label={label} />}
            <div className="timeRangePicker__timePickers">
                <TimePicker
                    label={labelStart}
                    value={start}
                    onChange={setStart}
                    readonly={readonly}
                    disabled={disabled}
                    errorMessage={errorMessageStart}
                    errorColor={!!errorMessage}
                    hint={hint}
                />
                <TimePicker
                    label={labelEnd}
                    value={end}
                    onChange={setEnd}
                    readonly={readonly}
                    disabled={!start?.length || disabled}
                    errorMessage={errorMessageEnd}
                    errorColor={!!errorMessage}
                    hint={hint}
                />
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
};
