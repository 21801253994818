import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";

export const useJobDescriptionSelector = (departmentId?: string) => {
    const { t } = useTranslation();

    const selectOption: OptionModel = { label: t(TranslationCommon.SELECT_OPTION), value: "-1" };

    const [jobDescriptionOptions, setJobDescriptionOptions] = useState<OptionModel[]>([selectOption]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getJobDescriptionSelector = async () => {
        setIsLoading(true);
        const extraParams = departmentId !== "-1" ? `departmentId=${departmentId}` : "";
        const { data, status } = await SegSelectorService.GetJobDescriptions({ extraParams });
        if (!status()) {
            setJobDescriptionOptions([selectOption]);
            return;
        }
        setJobDescriptionOptions([selectOption, ...data]);
        setIsLoading(false);
    };

    return { jobDescriptionOptions, isLoading, getJobDescriptionSelector };
};
