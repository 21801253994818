import { useState } from "react";

export const useWizard = (totalSteps: number) => {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(1);

    const onStepChange = (stepIndex: number) => {
        if (stepIndex < 1 || stepIndex > totalSteps) return;
        setCurrentStepIndex(stepIndex);
    };

    const next = () => onStepChange(currentStepIndex + 1);
    const prev = () => onStepChange(currentStepIndex - 1);
    const goTo = (value: number) => onStepChange(value);

    return {
        isFirstStep: currentStepIndex === 1,
        isLastStep: currentStepIndex === totalSteps,
        currentStepIndex,
        onStepChange,
        next,
        prev,
        goTo,
    };
};
