import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TranslationKeys } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useEffect, useState } from "react";
import { useSession } from "app/hooks";

export type SelectCompanyModel = {
    isOpen: boolean;
    company: OptionsSearch;
};

type IuseSelectCompanyTable = {
    defaultCompany?: OptionsSearch;
};
export const useSelectCompanyTable = ({ defaultCompany }: IuseSelectCompanyTable = {}) => {
    const session = useSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const sessionCompanyId = session?.workingCompany?.companyId;
    const sessionCompanyName = session?.workingCompany?.companyName;
    const companyData: OptionsSearch = {
        label:
            sessionCompanyName || translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }),
        value: sessionCompanyId !== undefined ? String(sessionCompanyId) : "-1",
    };

    const [selectCompany, setSelectCompany] = useState<SelectCompanyModel>({
        isOpen: false,
        company: defaultCompany || companyData,
    });

    const handleCompanyChange = (value: Partial<SelectCompanyModel>) =>
        setSelectCompany((prev) => ({ ...prev, ...value }));

    useEffect(() => {
        handleCompanyChange({
            company: defaultCompany || companyData,
        });
    }, []);

    return { selectCompany, handleCompanyChange, filterIcon: faFilter };
};
