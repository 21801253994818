import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import EmailDistributionListService from "app/services/01-SEG/EmailDistributionListService";
import { EmailDistributionListTranslations } from "app/translation/translationKeys";
import { fillExtraParams } from "../helpers/emailDistributionListHelper";
import { PaginationDefaults } from "app/shared/Constants";
import { INITIAL_EMAIL_DISTRIBUTION_LIST_SELECTED } from "../constants/EmailDistributionListConstants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { EmailDistributionListGridModel } from "../models/EmailDistributionListGridModels";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import {
    EmailDistributionListFormValues,
    SelectedEmailDistributionList,
} from "../models/EmailDistributionListFormValues";
import { ActionType } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useSession } from "app/hooks";

export const useEmailDistributionList = () => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { filterIcon, handleCompanyChange, selectCompany } = useSelectCompanyTable();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const session = useSession();

    const [emailDistributionList, setEmailDistributionList] = useState<EmailDistributionListModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currentEmailDistributionList, setCurrentEmailDistributionList] = useState<SelectedEmailDistributionList>(
        INITIAL_EMAIL_DISTRIBUTION_LIST_SELECTED
    );

    const getEmailDistributionList = async ({
        pi,
        q,
        sortF,
        sortD,
        companyId,
    }: EmailDistributionListGridModel = {}) => {
        setIsLoading(true);

        const selectedCompany = companyId ?? selectCompany.company.value;

        const { data, status, getParsedError } = await EmailDistributionListService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pi !== undefined ? pi : pageIndex - 1,
            sortField: sortF ?? sortField,
            sortDirection: sortD ?? sortDirection,
            extraParams: fillExtraParams({}, selectedCompany),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsLoading(false);
            setEmailDistributionList([]);
            setTotal(0);
        }
        setEmailDistributionList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getEmailDistributionList({ pi: pi - 1 });
    };

    const onChangeSortDirectionField = (sortF: string, sortD?: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD ?? "default");
        getEmailDistributionList({ sortF, sortD });
    };

    const onChangeCompany = ({ label, value }: OptionModel) => {
        handleCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        setPageIndex(1);
        getEmailDistributionList({ pi: 0, companyId: value });
        value !== "-1" && changeWorkingCompanyByCompanyId(Number(value));
    };

    const onAddNew = () => {
        setCurrentEmailDistributionList(INITIAL_EMAIL_DISTRIBUTION_LIST_SELECTED);
        setShowCreateModal(true);
    };

    const onClickOutsideCompany = () => {
        handleCompanyChange({ isOpen: false });
    };

    const onClickEdit = (fK_Company: number | null, fK_DistributionList: number, userList: OptionModel[]) => {
        setCurrentEmailDistributionList({
            fK_Company: fK_Company === null ? "" : String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList,
        });
        setShowEditModal(true);
    };

    const onClickDelete = (fK_Company: number | null, fK_DistributionList: number, userList: OptionModel[]) => {
        setCurrentEmailDistributionList({
            fK_Company: fK_Company === null ? "" : String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList,
        });
        setShowDeleteModal(true);
    };

    const handleSubmit = async (
        { companyList, fK_DistributionList, userList, fK_Company }: EmailDistributionListFormValues,
        type: ActionType
    ) => {
        const service = type === "create" ? EmailDistributionListService.Save : EmailDistributionListService.Edit;
        const closeModal = type === "create" ? setShowCreateModal : setShowEditModal;
        const successMessage =
            type === "create"
                ? t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SAVE_SUCCESS)
                : t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_EDIT_SUCCESS);

        const { status, getParsedError } = await service({
            companyList: companyList.map(({ value }) => Number(value)),
            fK_DistributionList: Number(fK_DistributionList),
            userIdList: userList.map(({ value }) => Number(value)),
            fK_Company: !!fK_Company?.length ? Number(fK_Company) : null,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            closeModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(successMessage);
        changeWorkingCompanyByCompanyId(Number(currentEmailDistributionList.fK_Company));
        getEmailDistributionList();
        closeModal(false);
        setIsLoading(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);

        const { status, getParsedError } = await EmailDistributionListService.Delete({
            fK_Company: !currentEmailDistributionList.fK_Company.length
                ? null
                : Number(currentEmailDistributionList.fK_Company),
            fK_DistributionList: Number(currentEmailDistributionList.fK_DistributionList),
            userIdList: currentEmailDistributionList.userList.map(({ value }) => Number(value)),
            companyList: [],
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setShowDeleteModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(t(EmailDistributionListTranslations.EMAIL_DISTRIBUTION_LIST_SUCCESS_DELETE));
        getEmailDistributionList();

        setShowDeleteModal(false);
        setIsLoading(false);
    };

    const onClickFilterIcon = () => handleCompanyChange({ isOpen: true });

    useEffect(() => {
        getEmailDistributionList({ companyId: String(session?.workingCompany?.companyId) });
    }, []);

    return {
        currentEmailDistributionList,
        emailDistributionList,
        filterIcon,
        handleSubmit,
        isLoading,
        onChangePageIndex,
        onChangeSortDirectionField,
        onClickDelete,
        onClickEdit,
        onChangeCompany,
        onDelete: handleDelete,
        pageIndex,
        selectCompany,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        onAddNew,
        onClickOutsideCompany,
        onChangeShowCreateModal: setShowCreateModal,
        onChangeShowEditModal: setShowEditModal,
        onChangeShowDeleteModal: setShowDeleteModal,
        onClickFilterIcon,
    };
};
