import { FC } from "react";
import { useTranslation } from "react-i18next";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { BaseAuditGroupCheckListItemTranslations } from "app/translation/translationKeys";
import { RowPositionModel } from "app/components_v2/Table/types";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";

type MobileBaseAuditGroupCheckListItemRowProps = {
    data: BaseAuditGroupCheckListItemModel;
    rowPosition: RowPositionModel;
    onEditClick: (value: BaseAuditGroupCheckListItemModel) => void;
    onDeleteClick: (value: BaseAuditGroupCheckListItemModel) => void;
};

export const MobileBaseAuditGroupCheckListItemRow: FC<MobileBaseAuditGroupCheckListItemRowProps> = ({
    data,
    rowPosition,
    onEditClick,
    onDeleteClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`mobileBaseAuditGroupCheckListItemRow--${rowPosition}`}>
            <div>
                <p className="mobileBaseAuditGroupCheckListItemRow__title">{data.name}</p>
                <p className="mobileBaseAuditGroupCheckListItemRow__type">{data.type}</p>
            </div>
            <div className="mobileBaseAuditGroupCheckListItemRow__rightData">
                <Badge
                    title={`${t(
                        BaseAuditGroupCheckListItemTranslations.PONDERATION_LEVEL_LABEL
                    )}: ${data.ponderationLevel.toString()}`}
                    size="md"
                    variant="blue"
                />
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => onEditClick(data),
                        },
                        {
                            icon: faTrash,
                            onClick: () => onDeleteClick(data),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
