import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { OtpInput } from "app/components_v2/__inputs/OtpInput/OtpInput";

export const OtpInputSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="OTP Input" show={show}>
            <div className="designSystemOtp">
                <OtpInput onChange={console.log} length={6} placeholder="Q12345" />
            </div>
        </Section>
    );
};
