import { TaskInstanceCommentModel } from "app/models/02-TAR/TaskInstance/TaskInstanceCommentModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";

export default class TaskInstanceCommentService {
    public static async Post(body: TaskInstanceCommentModel) {
        return FetchService.post<TaskInstanceCommentModel>({
            url: `${ApiPaths.TAR_API}/TaskInstanceComment`,
            body: body,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete<TaskInstanceCommentModel[]>({
            url: `${ApiPaths.TAR_API}/TaskInstanceComment/${id}`,
        });
    }
}
