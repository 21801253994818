const DateRangeSkelleton = () => {
    return (
        <div className="dateRange-skelleton">
            <div className="dateRange-skelleton__input"></div>
            <div className="dateRange-skelleton__icon"></div>
        </div>
    );
};

export default DateRangeSkelleton;
