import { faArrowAltCircleDown, faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { Section } from "../Section/Section";
import { FC } from "react";
import { ButtonGroupSection } from "../ButtonGroupSection/ButtonGroupSection";
import { Button } from "app/components_v2/__buttons/Button/Button";

type Props = {
    show?: boolean;
};

export const ButtonsSection: FC<Props> = ({ show }) => {
    return (
        <Section title="Buttons" show={show}>
            <h2>Primary</h2>
            <h3>Contained</h3>
            <>
                <Button text="Hola que tal" />
                <Button text="Hola que tal" iconLeft={faStarSharp} />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button iconRight={faArrowAltCircleDown} />
                <Button iconRight={faArrowAltCircleDown} isLoading />
                <Button text="Hola que tal" iconLeft={faStarSharp} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button text="Hola que tal" type="secondary" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button iconRight={faArrowAltCircleDown} type="secondary" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
            </>
            <h3>Tertiary</h3>
            <>
                <Button text="Hola que tal" type="tertiary" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button iconRight={faArrowAltCircleDown} type="tertiary" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
            </>
            <h3>Transparent</h3>
            <div className="designSystemButtonTransparent">
                <Button text="Hola que tal" iconLeft={faStarSharp} transparent />
                <Button text="Hola que tal" iconLeft={faStarSharp} transparent isLoading />
            </div>
            <h3>Link</h3>
            <>
                <Button text="Hola que tal" type="link" />
                <Button text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button iconRight={faArrowAltCircleDown} type="link" />{" "}
                <Button iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h2>Danger</h2>
            <h3>Contained</h3>
            <>
                <Button variant="danger" text="Hola que tal" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="danger" iconRight={faArrowAltCircleDown} />
                <Button variant="danger" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="secondary" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="danger"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="tertiary" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="danger"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Transparent</h3>
            <div className="designSystemButtonTransparent">
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} transparent />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} transparent isLoading />
            </div>
            <h3>Link</h3>
            <>
                <Button variant="danger" text="Hola que tal" type="link" />
                <Button variant="danger" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="danger" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="danger" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h2>Purple</h2>
            <h3>Contained</h3>
            <>
                <Button variant="purple" text="Hola que tal" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="purple" iconRight={faArrowAltCircleDown} />
                <Button variant="purple" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="secondary" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="purple"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="tertiary" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="purple"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Transparent</h3>
            <div className="designSystemButtonTransparent">
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} transparent />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} transparent isLoading />
            </div>
            <h3>Link</h3>
            <>
                <Button variant="purple" text="Hola que tal" type="link" />
                <Button variant="purple" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="purple" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="purple" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h3>Disabled</h3>
            <>
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="contained" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" isDisabled />
            </>
            <h2>appcc</h2>
            <h3>Contained</h3>
            <>
                <Button variant="appcc" text="Hola que tal" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} isLoading />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} isLoading />
            </>
            <h3>Secondary</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="secondary" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="secondary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="secondary" isLoading />
                <Button
                    variant="appcc"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="secondary"
                    isLoading
                />
            </>
            <h3>Tertiary</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="tertiary" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="tertiary" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="tertiary" isLoading />
                <Button
                    variant="appcc"
                    text="Hola que tal"
                    iconRight={faArrowAltCircleDown}
                    type="tertiary"
                    isLoading
                />
            </>
            <h3>Transparent</h3>
            <div className="designSystemButtonTransparent">
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} transparent />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} transparent isLoading />
            </div>
            <h3>Link</h3>
            <>
                <Button variant="appcc" text="Hola que tal" type="link" />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="link" />
                <Button variant="appcc" iconRight={faArrowAltCircleDown} type="link" isLoading />
                <Button variant="appcc" text="Hola que tal" iconRight={faArrowAltCircleDown} type="link" isLoading />
            </>
            <h3>Disabled</h3>
            <>
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="contained" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="secondary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="tertiary" isDisabled />
                <Button variant="appcc" text="Hola que tal" iconLeft={faStarSharp} type="link" isDisabled />
            </>

            <ButtonGroupSection show />
        </Section>
    );
};
