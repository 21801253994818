import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { TranslationTitles } from "app/translation/translationKeys";
import { Navigate, Route, Routes } from "react-router-dom";
import { PublicPaths } from "app/shared/Constants";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { SelectProfile } from "./SelectProfile";

export const SelectProfilesPage = () => {
    const { t } = useTranslation();
    useTitle(t(TranslationTitles.SELECT_PROFILE_PAGE_TITLE));

    return (
        <AuthLayout>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<SelectProfile />} />
            </Routes>
        </AuthLayout>
    );
};
