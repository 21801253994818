import { PaginationDefaults } from "app/shared/Constants";
import { FC } from "react";

type OptionCompanySkelletonProps = {
    quantity?: number;
};

export const OptionCompanySkelleton: FC<OptionCompanySkelletonProps> = ({
    quantity = PaginationDefaults.PAGE_SIZE,
}) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, i) => (
                <div className="optionCompanySkelleton" key={i}>
                    <div className="optionCompanySkelleton__container">
                        <div className="optionCompanySkelleton__image"></div>
                        <div className="optionCompanySkelleton__text">
                            <div className="optionCompanySkelleton__text__bg"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
