import { FC, useRef } from "react";
import { DataToReportItem } from "./DataToReportItem/DataToReportItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { FieldValue } from "../SelectDataToReport/SelectDataToReport";

type DataToReportListProps = {
    items: FieldValue[];
    onAddNew: () => void;
    onInputChange: (field: FieldValue) => void;
    onBlur: () => void;
    onDelete: (id: string) => void;
    customError?: TaskfieldOptionsError[];
    disabled?: boolean;
    placeholder?: string;
};

export const DataToReportList: FC<DataToReportListProps> = ({
    items,
    onAddNew,
    onInputChange,
    onBlur,
    customError = [],
    onDelete,
    disabled,
    placeholder,
}) => {
    const { t } = useTranslation();
    const ref = useRef<boolean>(false);

    const handleAddNew = () => {
        if (disabled) return;
        ref.current = true;
        onAddNew();
    };

    return (
        <div className="data_report_inputs__wrapper">
            {items
                .filter((el) => !el.isDeleted)
                .map(({ id, value }) => {
                    return (
                        <div className="data_report_input__wrapper" key={id}>
                            <DataToReportItem
                                onBlur={onBlur}
                                onChange={(valueItem) => onInputChange({ value: valueItem, id })}
                                value={value}
                                disabled={disabled}
                                placeholder={placeholder}
                                focus={ref.current}
                                errorMessage={customError.find((err) => err.id === id)?.errorMessage}
                            />
                            {!disabled && (
                                <FontAwesomeIcon
                                    className="deleteInputIcon"
                                    onClick={() => onDelete(id)}
                                    icon={faCircleXmark}
                                />
                            )}
                        </div>
                    );
                })}
            {!disabled && (
                <div className="data_report_inputs__wrapper__AddNew__container">
                    <div className="data_report_inputs__wrapper__AddNew" onClick={handleAddNew}>
                        <FontAwesomeIcon className="data_report_input__wrapper__AddNew__icon" icon={faPlus} />
                        <p className="data_report_inputs__wrapper__AddNew__text">
                            {t(TranslationKeys.ADD_ITEM_TO_CHECKLIST)}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};
