import { LocalStorageSession, TokenAndRefreshTokenExpirationModel, WorkingCompanyModel } from "app/models/SessionModel";
import { getCompoundKey, keys } from "./StorageConstans";
import { USERS_LOGIN_LOCALSTORAGE_KEY } from "app/shared/Constants";

// Summary: Data in localStorage doesn't expire.
// Get the user data from the local storage
export const getStoreSession = (): LocalStorageSession | undefined => {
    const userStr = localStorage.getItem(getCompoundKey(keys.SESSION));

    if (userStr) return JSON.parse(userStr);
    else return undefined;
};

// Remove the token and user from the local storage
export const removeStoreUserSession = () => {
    localStorage.removeItem(getCompoundKey(keys.TOKEN));
    localStorage.removeItem(getCompoundKey(keys.SESSION));
};

// Set the token and user from the local storage
export const setLocalStorageSession = (localStorageSession?: LocalStorageSession) => {
    localStorage.setItem(getCompoundKey(keys.SESSION), JSON.stringify(localStorageSession));
};

// Set customer instance id from the local storage
export const setCustomerInstanceId = (instanceId: number) => {
    localStorage.setItem(getCompoundKey(keys.CUSTOMER_INSTANCE), instanceId.toString());
};

export const setWorkingEntity = (workingCompany: WorkingCompanyModel) => {
    const localStorageSession = getStoreSession();
    if (!localStorageSession) return;
    localStorage.setItem(getCompoundKey(keys.SESSION), JSON.stringify({ ...localStorageSession, ...workingCompany }));
};

export const setIsActivationPending = (isActivationPending: boolean) => {
    const localStorageSession = getStoreSession();
    if (!localStorageSession) return;
    localStorage.setItem(getCompoundKey(keys.SESSION), JSON.stringify({ ...localStorageSession, isActivationPending }));
};

export const setTokenAndRefreshTokenExpiration = ({
    refreshTokenExpiration,
    tokenExpiration,
}: TokenAndRefreshTokenExpirationModel) => {
    const localStorageSession = getStoreSession() || {};
    localStorage.setItem(
        getCompoundKey(keys.SESSION),
        JSON.stringify({ ...localStorageSession, refreshTokenExpiration, tokenExpiration })
    );
};

// Get customer instance id from the local storage
export const getCustomerInstanceId = () => {
    const id = localStorage.getItem(getCompoundKey(keys.CUSTOMER_INSTANCE));
    return id ? Number(id) : undefined;
};

// Set SignalR ConnectionId to local storage
export const setConnectionId = (connectionId: string) => {
    localStorage.setItem(getCompoundKey(keys.CONNECTION_ID), connectionId);
};

// Get SignalR ConnectionId from local storage
export const getConnectionId = () => {
    const connectionId = localStorage.getItem(getCompoundKey(keys.CONNECTION_ID));
    return connectionId || null;
};

export const setUserLogin = (userId: number) => {
    let usersIds = getUsersLogin();
    if (usersIds.length >= 10 && !usersIds.includes(userId)) return;
    if (usersIds.includes(userId)) usersIds = usersIds.filter((uId) => uId !== userId);
    usersIds.unshift(userId);
    localStorage.setItem(USERS_LOGIN_LOCALSTORAGE_KEY, JSON.stringify(usersIds));
};

export const setUsersLogin = (users: number[]) => {
    localStorage.setItem(USERS_LOGIN_LOCALSTORAGE_KEY, JSON.stringify(users));
};

export const getUsersLogin = (): number[] => {
    const usersLogin = localStorage.getItem(USERS_LOGIN_LOCALSTORAGE_KEY);
    if (!usersLogin?.length) return [];

    return JSON.parse(usersLogin);
};

export const clearLocalStorage = () => {
    const usersIds = getUsersLogin();
    localStorage.clear();
    localStorage.setItem(USERS_LOGIN_LOCALSTORAGE_KEY, JSON.stringify(usersIds));
};

export const getLanguage = () => {
    const language = localStorage.getItem(getCompoundKey("i18nextLng"));
    return language || "es";
};
