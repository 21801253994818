import { SupervisorAnalistColors } from "app/components_v2/SupervisorAnalist/types";
import { FC } from "react";

type LegendItemMarkProps = {
    color: SupervisorAnalistColors;
    rounded?: boolean;
};

export const LegendItemMark: FC<LegendItemMarkProps> = ({ color, rounded }) => {
    return (
        <span className={`legendItemMark--${color} ${rounded ? "rounded" : ""}`} data-testid="legendItemMark"></span>
    );
};
