type Props = {
    planId: string;
};

export const fillExtraparams = ({ planId }: Props): string => {
    const extraParams = new URLSearchParams();

    extraParams.append("FK_PlanAPPCC", planId);

    return extraParams.toString();
};
