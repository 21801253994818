import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import {
    CorrectiveMeasureInsertModel,
    CorrectiveMeasureModel,
} from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";

export default class CorrectiveMeasureService {
    public static async GetList(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<CorrectiveMeasureModel>>({
            url: `${ApiPaths.QUA_API}/CorrectiveMeasure`,
            paginationParams: params,
        });
    }

    public static async Save(corrMes: CorrectiveMeasureInsertModel) {
        return FetchService.post({
            url: `${ApiPaths.QUA_API}/CorrectiveMeasure`,
            body: corrMes,
        });
    }

    public static async Edit(corrMes: CorrectiveMeasureInsertModel) {
        return FetchService.put<CorrectiveMeasureModel>({
            url: `${ApiPaths.QUA_API}/CorrectiveMeasure/${corrMes.id}`,
            body: corrMes,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/CorrectiveMeasure/${id}`,
        });
    }
}
