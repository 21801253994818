import { FC } from "react";
import { AvatarSkelleton } from "../AvatarSkelleton/AvatarSkelleton";

type AvatarRatingSkelletonProps = {
    showText?: boolean;
    showRating?: boolean;
};

export const AvatarRatingSkelleton: FC<AvatarRatingSkelletonProps> = ({
    showText,
    showRating = true,
}) => {
    return (
        <div className="avatarRating">
            <AvatarSkelleton size="2xl" showAvatarName={false} />
            <div
                className={`avatarRating__userInfo avatarRating-skelleton${showText ? "-73" : ""} ${
                    !showRating ? "avatarRating-skelleton--center" : ""
                }`}
            >
                <div className="avatarRating-skelleton-24">
                    <div className={`avatarRating-skelleton__userInfo__text `}></div>
                </div>
                {showText ? (
                    <div className="avatarRating-skelleton-45">
                        <div className="avatarRating-skelleton-28">
                            <div className="avatarRating-skelleton__userInfo__stars"></div>
                        </div>
                        <div className="avatarRating-skelleton-17">
                            <div className="avatarRating-skelleton__userInfo__supervisorText"></div>
                        </div>
                    </div>
                ) : (
                    <>
                        {showRating && (
                            <div className="avatarRating-skelleton-28">
                                <div className="avatarRating-skelleton__userInfo__stars"></div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
