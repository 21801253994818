import { EditTaskDisabledFieldsModel, editTasktValidation } from "app/models/02-TAR/Task/EditTask";

export const INITIAL_EDIT_TASK_DISABLEDS: EditTaskDisabledFieldsModel = {
    disabledDataToReport: false,
    disabledChecklist: false,
    disabledDynamicFields: false,
    disabledTaskTitle: false,
    disabledTaskDesc: false,
    disabledFotoExample: false,
    disabledIsPhotoRequired: false,
    disabledIsCritical: false,
    disabledPlan: true,
    disabledType: "NONE",
};

export const INITIAL_EDIT_TASK_FORM_VALUE_ERRORS: editTasktValidation = {
    taskTitle: "",
    planAPPCC: "",
    companyForTask: "",
    DepartmentForTask: "",
    userForTask: "",
    userOrQRForTask: "",
    reportData: "",
    taskStart: "",
    taskHours: {
        value: "",
        errors: [],
    },
    finishEl: "",
    finishResp: "",
    customReps: "",
    customDaysBubles: "",
    customMonth: "",
    selectOption: "",
    dynamicFields: [],
    checklist: [],
    dateRange: "",
    temporalityType: "",
};
