import { SelectedColumnViewModel } from "../../models/taskPageModels";

export const getTaskTab = (currentTab: number): SelectedColumnViewModel => {
    return {
        all: currentTab === 0,
        workingPosition: currentTab === 1,
        dept: currentTab === 2,
        subDept: currentTab === 3,
        user: currentTab === 4,
    };
};
