import { useEffect, useRef } from "react";

export const useEffectAfterFirstRender = (effect: () => void, dependencies: unknown[]) => {
    const isInitialRender = useRef<boolean>(true);
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        effect();
    }, dependencies);
};
