import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { EmailDistributionListDto } from "app/dtos/01-SEG/EmailDistributionListDto/EmailDistributionListDto";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";

export default class EmailDistributionListService {
    public static async GetOne(id: number) {
        return FetchService.get<EmailDistributionListModel>({
            url: `${ApiPaths.SEG_API}/EmailDistributionList/${id}`,
        });
    }

    public static async GetData(parms: PaginationParams) {
        return FetchService.get<PaginatedResult<EmailDistributionListModel>>({
            url: `${ApiPaths.SEG_API}/EmailDistributionList`,
            paginationParams: parms,
        });
    }

    public static async Save(body: EmailDistributionListDto) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/EmailDistributionList`,
            body,
        });
    }

    public static async Edit(body: EmailDistributionListDto) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/EmailDistributionList`,
            body,
        });
    }

    public static async Delete(body: EmailDistributionListDto) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/EmailDistributionList`,
            body,
        });
    }
}
