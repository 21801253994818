import { FC } from "react";

type ErrorMessageProps = {
    errorMessage: string;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ errorMessage }) => {
    return (
        <div data-testid="errorMessage" className={`errorMessage`}>
            {errorMessage}
        </div>
    );
};
