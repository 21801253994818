import { useState, useEffect, FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSession } from "app/hooks";
import { v4 } from "uuid";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import TaskInstanceCommentService from "app/services/02-TAR/TaskInstanceCommentService";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import {
    TaskInstanceCommentInsertModel,
    TaskInstanceCommentModel,
} from "app/models/02-TAR/TaskInstance/TaskInstanceCommentModel";
import { ChatInputBox } from "app/components_v2/Chat/ChatInputBox/ChatInputBox";
import { Message, MessageModel } from "app/components_v2/Chat/Message/Message";
import { ImageModal } from "app/components_v2/__modals/base/ImageModal/ImageModal";
import { SliderModal } from "app/components_v2/__modals/base/SliderModal/SliderModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FileData } from "app/shared/types/FileDataTypes";
import { INITIAL_MESSAGE } from "./constants/TaskCommentsConstants";
import { AUDIO_RECORDER_FORMAT_TYPE } from "app/components_v2/AudioRecorder/helpers/audioRecorderHelper";

type TaskCommentsProps = {
    taskInstance: TaskInstanceModel;
    disabled?: boolean;
    onCollapsableChange?: (isOpen: boolean) => void;
};

export const TaskComments: FC<TaskCommentsProps> = ({ taskInstance, onCollapsableChange }) => {
    const { t } = useTranslation();
    const session = useSession();
    const location = useLocation();

    const [comments, setComments] = useState<TaskInstanceCommentInsertModel[]>([]);
    const [currentMessage, setCurrentMessage] = useState<FileData>(INITIAL_MESSAGE);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState<string | undefined>(undefined);
    const [deleteData, setDeleteData] = useState<MessageModel>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [audioBase64, setAudioBase64] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const scrollToCommentsRef = useRef<HTMLParagraphElement>(null);

    const handleMessage = (fileData: FileData) => {
        setCurrentMessage(fileData);
    };

    const handleSendMessage = async () => {
        const { base64String: message, file } = currentMessage;
        if (!session || !message || !message.trim().length) return;
        setCurrentMessage(INITIAL_MESSAGE);

        const newComment: TaskInstanceCommentModel = {
            customerInstanceId: session.user.customerInstanceId || 0,
            taskInstanceId: taskInstance.id,
            message: file.type === "text" ? message.trim() : "",
            senderUserId: session.user.id,
            sendDate: new Date(new Date().toISOString().slice(0, -1)),
            senderUserName: session.user.firstName + " " + session.user.lastName,
            profilePictureURL: session.user.profilePictureURL,
            type: file.type,
            fileBase64: file.type !== "text" ? message.trim() : "",
            fileName: file.name || "",
            id: 0,
        };

        const commnetFront: TaskInstanceCommentInsertModel = { ...newComment, id: v4() };

        if (newComment.type !== "text") setUploading(true);
        else setComments([commnetFront, ...comments]);

        const sr = await TaskInstanceCommentService.Post(newComment);
        if (sr.status()) {
            const newComment = sr.data;
            commnetFront.dbId = newComment.id;
            commnetFront.message = newComment.message;
            newComment.type !== "text" && setComments([commnetFront, ...comments]);
            setUploading(false);
        }
    };

    const handleShowImage = (url: string | undefined) => {
        setImageURL(url);
        setShowImage(true);
    };

    const handleDeleteOptions = (message: MessageModel) => {
        setDeleteData(message);
        setShowDeleteModal(true);
    };

    const handleDelete = async (id?: number) => {
        if (!id) return;
        const sr = await TaskInstanceCommentService.Delete(id);
        if (!sr.status()) return;
        setComments((prev) => prev.filter((comment) => comment.dbId !== id));
        setShowDeleteModal(false);
    };

    const createMockFile = (type: string, name?: string): File =>
        ({
            name: name ? name : "",
            size: 0,
            type,
            lastModified: Date.now(),
            arrayBuffer: async () => new ArrayBuffer(0),
            slice: () => new Blob(),
            stream: () => new ReadableStream(),
            text: async () => "",
        } as File);

    const scrollToComments = () => {
        setTimeout(
            () => scrollToCommentsRef != null && scrollToCommentsRef.current?.scrollIntoView({ behavior: "smooth" }),
            100
        );
    };

    const setAudio = (base64String: string | null) => {
        setAudioBase64(base64String);
        base64String &&
            handleMessage({ base64String: base64String, file: createMockFile(AUDIO_RECORDER_FORMAT_TYPE, "Audio") });
    };

    const handleCollapsableChange = (isCollapsableOpen: boolean) => {
        onCollapsableChange && onCollapsableChange(isCollapsableOpen);
        setIsOpen(isCollapsableOpen);
    };

    useEffect(() => {
        if (location.search.includes("scrollToComments")) scrollToComments();
    }, [location.search]);

    useEffect(() => {
        setComments(
            taskInstance.taskInstanceComments
                ? taskInstance.taskInstanceComments.map((comment) => ({ ...comment, dbId: comment.id, id: v4() }))
                : []
        );
        setIsOpen(!!taskInstance.taskInstanceComments?.length);
    }, [taskInstance]);

    return (
        <div className="taskComments">
            <Collapsable
                title={t(TranslationKeys.COMMENTS_OR_QUESTIONS)}
                openAfterEvent={comments?.length}
                open={false}
                onChange={handleCollapsableChange}
            >
                <div className="taskComments__chat">
                    <div className="taskComments__chatbox">
                        {!!comments.length ? (
                            <div className={`taskComments__container__messages`}>
                                {comments.map((comment, i) => {
                                    const chatMessageModel: MessageModel = {
                                        message: comment.message,
                                        timestamp: comment.sendDate,
                                        customerInstanceId: comment.customerInstanceId,
                                        name: comment.fileName,
                                        messageId: comment.id,
                                        userSendId: comment.senderUserId,
                                        type: comment.type,
                                        senderName: comment.senderUserName,
                                        profilePic: comment.profilePictureURL,
                                        dbId: comment.dbId,
                                    };
                                    return (
                                        <Message
                                            key={i}
                                            handleShowImage={handleShowImage}
                                            handleDelete={handleDeleteOptions}
                                            props={chatMessageModel}
                                            onlyDeleteImages
                                            isGroup={true}
                                            maxWidthFitContent
                                        />
                                    );
                                })}
                                {showImage && imageURL && (
                                    <SliderModal onClose={() => setShowImage(false)}>
                                        <ImageModal img={imageURL} />
                                    </SliderModal>
                                )}
                                {showDeleteModal && (
                                    <ConfirmModal
                                        type="delete"
                                        onConfirm={() => handleDelete(deleteData?.dbId)}
                                        onConfirmText={t(TranslationCommon.DELETE)}
                                        description={deleteData?.message}
                                        onClose={() => setShowDeleteModal(false)}
                                        onCloseText={t(TranslationCommon.CANCEL)}
                                        title={t(TranslationKeys.DELETE_IMAGE_MODAL_TITLE)}
                                    />
                                )}
                            </div>
                        ) : null}
                        {isOpen && (
                            <ChatInputBox
                                sendMessage={handleSendMessage}
                                setCurrentMessage={(message) =>
                                    handleMessage({
                                        base64String: message,
                                        file: createMockFile("text"),
                                    })
                                }
                                maxLength={250}
                                upsideDownEmojis={!comments?.length}
                                shortEmojis={true}
                                borderRadiusVariant={!!comments.length ? "borderRadiusBottom" : "borderRadiusAll"}
                                uploadFile={{
                                    showSendFile: true,
                                    sendFile: handleMessage,
                                }}
                                uploadAudio={{
                                    showSendAudio: true,
                                    audioBase64: audioBase64,
                                    setAudioBase64: (audio) => setAudio(audio),
                                }}
                                uploading={uploading}
                                autoFocus={!comments.length}
                            />
                        )}
                    </div>
                    {currentMessage?.base64String?.length === 250 && (
                        <ErrorMessage errorMessage={t(TranslationKeys.TASK_COMMENTS_MAX_250_CHARACTERS)} />
                    )}
                    <p className="taskComments__info" ref={scrollToCommentsRef}>
                        {t(TranslationKeys.TASK_COMMENTS_INFO_1)} {t(TranslationKeys.TASK_COMMENTS_INFO_2)}
                    </p>
                </div>
            </Collapsable>
        </div>
    );
};
