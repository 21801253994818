import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { faCheckDouble } from "@fortawesome/pro-regular-svg-icons";
import { UserService } from "app/services";
import { CloseCompanyTranslations, TranslationCommon } from "app/translation/translationKeys";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { OpenClosedOptionCompanyCollapsable } from "./components/OpenClosedOptionCompanyCollapsable";
import { usePendingTasksCompanyDepartmentModal } from "app/hooks/PendingTasksCompanyDepartmentModal/usePendingTasksCompanyDepartmentModal";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export const CloseCompanyModal: FC = () => {
    const { t } = useTranslation();
    const { closeOpenCompaniesAndDepartments, reset } = useContext(CloseCompanyModalContext);
    const { getPendingTasksCompanyDepartment } = usePendingTasksCompanyDepartmentModal();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const session = useSession();
    const userId = session?.user.id;
    const { openCompanies, closedCompanies, openDepartments, closedDepartments } = closeOpenCompaniesAndDepartments;

    const handleUpdateCloseCompaniesAndDepartmentsSeen = async () => {
        if (!userId) return;
        setIsSubmiting(true);
        await UserService.UpdateCloseCompaniesAndDepartmentsSeen();
        setIsSubmiting(false);
        reset();
        await getPendingTasksCompanyDepartment();
    };

    return (
        <GenericModal
            allowCloseOnEscape={false}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.ACCEPT),
                        iconRight: faCheckDouble,
                        onClick: handleUpdateCloseCompaniesAndDepartmentsSeen,
                    }}
                    loading={isSubmiting}
                />
            }
            size="md"
        >
            <div className="closeCompanyModal">
                <h1 className="closeCompanyModal__title">
                    {translateCustomerTypeKeys(CloseCompanyTranslations.CLOSE_COMPANIES_MODAL_TITLE, {
                        firstReplace: "PLURAL",
                    })}
                </h1>
                {(!!openCompanies.length || !!openDepartments.length) && (
                    <OpenClosedOptionCompanyCollapsable
                        companyList={openCompanies}
                        departmentList={openDepartments}
                        variant="OPEN"
                    />
                )}
                {(!!closedCompanies.length || !!closedDepartments.length) && (
                    <OpenClosedOptionCompanyCollapsable
                        companyList={closedCompanies}
                        departmentList={closedDepartments}
                        variant="CLOSED"
                    />
                )}
            </div>
        </GenericModal>
    );
};
