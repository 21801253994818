import { FC } from "react";
import { PlanAPPCCCounters } from "../../components/PlanAPPCCCounters/PlanAPPCCCounters";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useSession } from "app/hooks";

type MobilePlanRowFooterProps = {
    taskCount: number;
    assetCount: number;
    corectiveMeasureCount: number;
    id: number;
};

export const MobilePlanRowFooter: FC<MobilePlanRowFooterProps> = ({
    assetCount,
    corectiveMeasureCount,
    taskCount,
    id,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;

    return (
        <div className="planMobileRowFooter">
            <div className="planMobileRowFooter__info">
                <p className="planMobileRowFooter__info__text">{t(TranslationKeys.PLAN_NAME_TASK_COUNT)}</p>
                <PlanAPPCCCounters
                    to={`/${PrivatePaths.REGISTRY_MANAGEMENT}?planId=${id}`}
                    title={`${String(taskCount)}`}
                    readonly={!hasPermissionToView(session, SecScreen.PLAN_APPCC) || !!isOnBoarding}
                />
            </div>
            <div className="planMobileRowFooter__info">
                <p className="planMobileRowFooter__info__text">{t(TranslationKeys.PLAN_NAME_ASSET_COUNT)}</p>
                <PlanAPPCCCounters
                    to={`/${PrivatePaths.ASSETS}?planId=${id}`}
                    title={`${String(assetCount)}`}
                    readonly={!hasPermissionToView(session, SecScreen.ASSETS) || !!isOnBoarding}
                />
            </div>
            <div className="planMobileRowFooter__info">
                <p className="planMobileRowFooter__info__text">
                    {t(TranslationKeys.PLAN_NAME_CORRECTIVE_MEASSURE_COUNT)}
                </p>
                <PlanAPPCCCounters
                    to={`/${PrivatePaths.CORR_MEASURE_MANAGER}?planId=${id}`}
                    title={`${String(corectiveMeasureCount)}`}
                    readonly={!hasPermissionToView(session, SecScreen.CORR_MEASURE_MANAGER) || !!isOnBoarding}
                />
            </div>
        </div>
    );
};
