import { useRef, useState } from "react";
import { ChatBotMessageModel } from "../types";
import OpenAI from "openai";
import { ApiPaths } from "app/shared/Constants";
import { v4 } from "uuid";
import { CHAT_BOT_ACTION_KEYS } from "../constants/chatBotConstants";
import { ChatCompletionMessageParam } from "openai/resources";
import { CHAT_BOT_GPT_INFO } from "app/shared/chatgptChatbotContextInfo";
import { useChatBotMessages } from "./useChatBotMessages";
import { useTranslation } from "react-i18next";
import { ChatBotGPTTranslations } from "app/translation/translationKeys";

const openai = new OpenAI({
    apiKey: ApiPaths.OPENAI_API,
    dangerouslyAllowBrowser: true,
});

export const useChatBot = () => {
    const { t } = useTranslation();

    const { initialMessage } = useChatBotMessages();
    const [messages, setMessages] = useState<ChatBotMessageModel[]>(initialMessage);
    const [input, setInput] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleSendMessage = async (allMessages: ChatBotMessageModel[], botMessageId: string) => {
        try {
            const previousMessages: ChatCompletionMessageParam[] = allMessages
                .filter(({ text }) => text.length)
                .map(({ sender, text }) => ({
                    role: sender,
                    content: text,
                }));

            const messagesGpt: ChatCompletionMessageParam[] = [
                { role: "system", content: CHAT_BOT_GPT_INFO },
                ...previousMessages,
            ];

            if (input.length) messagesGpt.push({ role: "user", content: input });

            const chunks = await openai.chat.completions.create({
                messages: messagesGpt,
                model: "gpt-4o",
                stream: true,
            });

            let reply = "";

            for await (const chunk of chunks) {
                const choice = chunk.choices[0];
                const content = choice?.delta?.content ?? "";

                reply += content;
                setMessages((prevMessages) =>
                    prevMessages.map((message) =>
                        message.id === botMessageId ? { ...message, text: reply, isLoading: false } : message
                    )
                );
                if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
            setIsDisabled(false);
            if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
        } catch (error) {
            console.error("Error interacting with OpenAI API", error);
            setMessages((prevMessages) =>
                prevMessages.map((message) =>
                    message.id === botMessageId
                        ? {
                              ...message,
                              text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_ERROR_MESSAGE),
                              isErrored: true,
                              isLoading: false,
                          }
                        : message
                )
            );
            setIsDisabled(false);
            setInput("");
        }
    };

    const onClickButton = async (actionKey: string, index: number) => {
        const buttonMessage = messages[index];
        if (!buttonMessage.buttons?.length) return;
        if (!CHAT_BOT_ACTION_KEYS.includes(actionKey)) return;

        const textButton: Record<string, string> = {
            USER_INFO: "voy a preguntarte cosas sobre usuarios",
            TASK_INFO: "voy a preguntarte cosas sobre tareas",
            APPCC_INFO: "voy a preguntarte cosas sobre appcc",
        };

        const userMessage: ChatBotMessageModel = {
            sender: "user",
            text: textButton[actionKey],
            id: v4(),
            date: new Date(),
            isLoading: false,
            type: "text",
            isHidden: true,
            isErrored: false,
        };

        let allMessage = [...messages];
        allMessage.push(userMessage);

        setIsDisabled(true);
        setInput("");

        if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;

        const botMessageId = v4();
        const botMessage: ChatBotMessageModel = {
            sender: "assistant",
            text: "",
            id: botMessageId,
            date: new Date(),
            isLoading: true,
            type: "text",
            isErrored: false,
        };

        allMessage.push(botMessage);
        allMessage = allMessage.map((message) =>
            message.id === buttonMessage.id
                ? {
                      ...message,
                      buttons: message.buttons?.map((button) => ({
                          ...button,
                          readonly: true,
                          show: button.actionKey === actionKey,
                      })),
                  }
                : message
        );
        setMessages(allMessage);
        await handleSendMessage(allMessage, botMessageId);
    };
    const sendUserMessage = async () => {
        if (!input.trim()) return;

        const newMessage: ChatBotMessageModel = {
            sender: "user",
            text: input,
            id: v4(),
            date: new Date(),
            isLoading: false,
            type: "text",
            isErrored: false,
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                !!message.buttons?.length
                    ? {
                          ...message,
                          isHidden: true,
                          buttons: message.buttons?.map((button) => ({
                              ...button,
                              readonly: true,
                              show: false,
                          })),
                      }
                    : message
            )
        );
        const botMessageId = v4();
        const botMessage: ChatBotMessageModel = {
            sender: "assistant",
            text: "",
            id: botMessageId,
            date: new Date(),
            isLoading: true,
            type: "text",
            isErrored: false,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        setIsDisabled(true);
        setInput("");
        if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;

        await handleSendMessage(messages, botMessageId);
    };

    const isPreviousMessageSameOwner = (index: number) => {
        if (index === 0) return false;

        const currentMessage = messages[index];
        const previousMessages = messages[index - 1];

        return currentMessage.sender === previousMessages.sender;
    };

    return {
        messages,
        input,
        isDisabled,
        containerRef,
        sendUserMessage,
        onClickButton,
        isPreviousMessageSameOwner,
        onChangeInput: setInput,
    };
};
