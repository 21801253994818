import { TypeCodeTask } from "app/models/02-TAR/Task/TaskBody";
import { RecurentTaskCustomValues, RecurrentTaskValuesModel } from "app/models/02-TAR/TaskWizard";

export const selectTypeCodeCustom = (value: RecurentTaskCustomValues): TypeCodeTask => {
    switch (value) {
        case "day":
            return "DAILY";
        case "week":
            return "WEEKLY";
        case "everyMonth":
            return "MONTHLY";
        case "everyYear":
            return "ANNUALY";
        default:
            return "ONE_TIME";
    }
};

export const selectTypeCode = (value: RecurrentTaskValuesModel): TypeCodeTask => {
    switch (value) {
        case "everyDay":
            return "DAILY";
        case "everyWeek":
            return "WEEKLY";
        case "everyMonth":
            return "MONTHLY";
        case "annually":
            return "ANNUALY";
        case "mondayToFriday":
            return "WEEKLY";
        default:
            return "ONE_TIME";
        // TODO: Mirar como poner este mondayToFriday
    }
};
