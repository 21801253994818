import { FC, useState } from "react";
import { SwitchVariants } from "./types";
import { Label } from "../Label";

type SwitchProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    variant?: SwitchVariants;
    label?: string;
    reverse?: boolean;
    inverted?: boolean;
};

export const Switch: FC<SwitchProps> = ({
    checked,
    onChange,
    disabled = false,
    variant = "primary",
    label,
    reverse,
    inverted,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    return (
        <div className={`switchContainer${reverse ? "--reverse" : ""}`}>
            <div
                className={`switchContainer__switch${checked ? "--active" : ""}${
                    isFocused ? "--focused" : ""
                }--${variant} ${disabled ? "disabled" : ""}  ${inverted ? "invertedSwitch" : ""}${
                    inverted && checked ? "--active" : ""
                }`}
                onClick={() => {
                    !disabled && onChange(!checked);
                }}
                onMouseDown={() => !disabled && setIsFocused(true)}
                onMouseUp={() => !disabled && setIsFocused(false)}
                onMouseLeave={() => !disabled && setIsFocused(false)}
                data-testid={`switchContainerSwitch--${variant}`}
            >
                <div
                    className={`switchContainer__switch__ball${checked ? "--active" : ""}${
                        isFocused ? "--focused" : ""
                    } ${disabled ? "disabled" : ""} ${inverted ? "invertedSwitch__ball" : ""}${
                        inverted && checked ? "--active" : ""
                    }`}
                    data-testid="switchContainerSwitchBall"
                ></div>
            </div>
            {label && (
                <Label
                    label={label}
                    disabled={disabled}
                    onLabelClick={() => !disabled && onChange(!checked)}
                    inverted={inverted}
                />
            )}
        </div>
    );
};
