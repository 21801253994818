import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

type SliderModalProps = {
    children: ReactNode | ReactNode[];
    onClose: () => void;
};

export const SliderModal: FC<SliderModalProps> = ({ children, onClose }) => {
    const ModalContent = (
        <>
            <div className={`fullHeightOpacity zIndexUp`}></div>
            <div className="sliderModal zIndexUp">
                <div className="sliderModal__wrapper">
                    <div className="sliderModal__header">
                        <FontAwesomeIcon
                            className="sliderModal__header__icon"
                            icon={faXmark}
                            onClick={onClose}
                        />
                    </div>
                    <div className="sliderModal__content">{children}</div>
                </div>
            </div>
        </>
    );
    return createPortal(ModalContent, document.body);
};
