import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FC } from "react";
import { Label } from "../Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReadOnlyType, ReadOnlyVariants } from "./types";

type ReadOnlyInputProps = {
    value: string;
    label?: string;
    icon?: {
        variant?: ReadOnlyVariants;
        value: IconProp;
    };
    type?: ReadOnlyType;
    placeholder?: string;
    options?: {
        width?: "auto" | "full";
    };
};

export const ReadOnlyInput: FC<ReadOnlyInputProps> = ({ value, icon, label, type = "input", options, placeholder }) => {
    const getWidth = () => {
        const width = options?.width;
        if (!width) return "full";
        return width;
    };

    return (
        <div
            role="textbox"
            aria-readonly="true"
            tabIndex={0}
            className={`readOnlyInput ${`readOnlyInput--${getWidth()}`}`}
        >
            {!!label?.length && <Label label={label} disabled />}
            <div className={`readOnlyInput__container readOnlyInput__container--${type}`} role="group">
                {value.length ? (
                    <p className="readOnlyInput__value">{value}</p>
                ) : (
                    <p className="readOnlyInput__placeholder">{placeholder}</p>
                )}
                {icon && (
                    <span className="readOnlyInput__icon">
                        <FontAwesomeIcon
                            title="readOnly icon"
                            className={`readOnlyInput__icon__svg  readOnlyInput__icon__svg--${
                                icon.variant || "success"
                            }`}
                            icon={icon.value}
                        />
                    </span>
                )}
            </div>
        </div>
    );
};
