import { useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type IhandleDelete = {
    isActive: boolean;
};

export const useDeleteWorkingPosition = (workingPositionId?: string) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const handleDelete = async ({ isActive }: IhandleDelete): Promise<boolean> => {
        if (!workingPositionId) return false;
        setIsDeleting(true);
        const successActivateDeactivate = isActive
            ? await handleDeactivate(Number(workingPositionId))
            : await handleActivate(Number(workingPositionId));

        setIsDeleting(false);
        setIsDeleteModalOpen(false);
        return successActivateDeactivate;
    };

    const handleDeactivate = async (id: number): Promise<boolean> => {
        const { status } = await WorkingPositionService.Delete(id);
        if (!status()) {
            handleToast({
                title: t(TranslationKeys.WORKING_POSITION_FAILED_DEACTIVATE),
                type: "alert",
                variant: "danger",
            });

            return false;
        }

        handleToast({
            title: t(TranslationKeys.WORKING_POSITION_SUCCESS_DEACTIVATE),
            type: "alert",
            variant: "success",
        });

        return true;
    };

    const handleActivate = async (id: number): Promise<boolean> => {
        const { status } = await WorkingPositionService.Activation(id);
        if (!status()) {
            handleToast({
                title: t(TranslationKeys.WORKING_POSITION_FAILED_ACTIVATE),
                type: "alert",
                variant: "danger",
            });

            return false;
        }

        handleToast({
            title: t(TranslationKeys.WORKING_POSITION_SUCCESS_ACTIVATE),
            type: "alert",
            variant: "success",
        });

        return true;
    };

    return {
        onDelete: handleDelete,
        isDeleting,
        isDeleteModalOpen,
        setIsDeleteModalOpen,
    };
};
