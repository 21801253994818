import { useTranslation } from "react-i18next";
import { faBatteryFull, faBatteryHalf, faBatteryLow } from "@fortawesome/pro-regular-svg-icons";
import { AutomaticRecordTranslations } from "app/translation/translationKeys";
import { BatteryLevels } from "../constants/automaticRecordConstants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";

export const useSensorBattery = () => {
    const { t } = useTranslation();

    const batteryBadgeTitle = (batteryLevel: number, mobile?: boolean): string =>
        mobile
            ? t(AutomaticRecordTranslations.BATTERY_LEVEL_LABEL)
            : batteryLevel <= BatteryLevels.LOW_BATTERY
            ? t(AutomaticRecordTranslations.BATTERY_LEVEL_LOW_LABEL)
            : batteryLevel > BatteryLevels.LOW_BATTERY && batteryLevel < BatteryLevels.MEDIUM_BATTERY
            ? t(AutomaticRecordTranslations.BATTERY_LEVEL_MEDIUM_LABEL)
            : t(AutomaticRecordTranslations.BATTERY_LEVEL_HIGH_LABEL);

    const batteryBadgeColor = (batteryLevel: number): BadgeVariant =>
        batteryLevel <= BatteryLevels.LOW_BATTERY
            ? "red"
            : batteryLevel > BatteryLevels.LOW_BATTERY && batteryLevel < BatteryLevels.MEDIUM_BATTERY
            ? "yellow"
            : "green";

    const batteryBadgeIcon = (batteryLevel: number): IconProp =>
        batteryLevel <= BatteryLevels.LOW_BATTERY
            ? faBatteryLow
            : batteryLevel > BatteryLevels.LOW_BATTERY && batteryLevel < BatteryLevels.MEDIUM_BATTERY
            ? faBatteryHalf
            : faBatteryFull;

    return { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon };
};
