import SystemUserService from "app/services/01-SEG/SystemUserService";
import { TranslationErrors } from "app/translation/translationKeys";
import { useToast } from "./Toast/useToast";
import { useDispatch } from "react-redux";
import { InstanceService } from "app/services";
import { Actions } from "app/state/actions";

export const useSystemUser = () => {
    const { handleToast } = useToast();
    const dispatch = useDispatch();

    const handleSystemUser = async (customerInstanceId: number | null) => {
        const systemUserSr = await SystemUserService.PutSystemUserCustomerInstance(customerInstanceId);
        if (!systemUserSr.status()) {
            handleToast({
                title: TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE,
                variant: "danger",
                type: "alert",
            });
            return;
        }
        if (!customerInstanceId) return systemUserSr.data;

        const customerInstanceSr = await InstanceService.GetInstance(customerInstanceId);
        if (!customerInstanceSr.status()) {
            handleToast({
                title: TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE,
                variant: "danger",
                type: "alert",
            });
            return;
        }
        dispatch(Actions.updateIsOnBoarding(customerInstanceSr.data.isOnBoarding));
        return systemUserSr.data;
    };
    return { handleSystemUser };
};
