import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";
import { CompanyWizardModel } from "app/models/01-SEG/Company/CompanyWizardModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { TranslationCommon } from "app/translation/translationKeys";

export type CheckedModel = {
    id: number;
    checked: boolean;
    departmentId?: number;
    subdepartmentId?: number;
};

export type CheckedDeps = CheckedModel & {
    subdeps?: CheckedModel[];
};

export type CompanyWizardDepartmentsSubdepartments = {
    id: number;
    subdepartmentName: string;
};

export type DepartmentUsersCheckModel = {
    departmentId: number;
    name: string;
    users: number[];
};

export type SubdepartmentUsersCheckModel = {
    subdepartmnetId: number;
    departmentId: number;
    name: string;
    users: number[];
};

export type CompanyWizardModelExtra = CompanyWizardModel & {
    companySelectedId: OptionsSearch;
    copyExistingCompany?: boolean;
    checkedDepsAndSubdeps?: CheckedDeps[];
    checkedUsers: CheckedModel[];
    copyExistingUsers?: boolean;
    departmentUsersChecked: DepartmentUsersCheckModel[];
    subdepartmentUsersChecked: SubdepartmentUsersCheckModel[];
};

export type CompanyWizardSteps = {
    onChangeData: (value: Partial<CompanyWizardModelExtra>) => void;
    data: CompanyWizardModelExtra;
    validateNextStep: (bool: boolean) => void;
    next: () => void;
    onStepChange?: (stepIndex: number) => void;
    prev?: () => void;
};

export type Step1ErrorsModel = {
    nameError: string;
    companyTypeIdError: string;
    documentNumberError: string;
    legalNameError: string;
    cityError: string;
    addressError: string;
    zipError: string;
    emailError: string;
    phoneError: string;
};

export const ERROR_INITIAL_VALUES = {
    nameError: "",
    companyTypeIdError: "",
    documentNumberError: "",
    legalNameError: "",
    cityError: "",
    addressError: "",
    zipError: "",
    emailError: "",
    phoneError: "",
};

export const yesNoItems = (t: Function): ButtonGroupItem[] => [
    {
        label: t(TranslationCommon.NO),
        value: "NO",
    },
    {
        label: t(TranslationCommon.YES),
        value: "YES",
    },
];
