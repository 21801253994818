import { FC } from "react";

type NumberIconProps = {
    className?: string;
    value: number;
};

export const NumberIcon: FC<NumberIconProps> = ({ value, className = "" }) => {
    return <span className={`numberIcon ${className}`}>{value > 99 ? 99 : value}</span>;
};
