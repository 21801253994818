import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Input, SelectOptions } from "app/components_v2/__inputs";
import { CustomerInstanceModel, CustomerType } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { TranslationKeys, WizardLicenseTranslations } from "app/translation/translationKeys";
import { customerTypes } from "app/components_v2/wizards/WizardLicense/constants/LicensesConstants";
import { WizardLicenseStepOneErrors } from "app/components_v2/wizards/WizardLicense/StepOne/models/models";

type Props = {
    model: CustomerInstanceModel;
    errors: WizardLicenseStepOneErrors;
    onChange: (values: Partial<CustomerInstanceModel>) => void;
};

const LicenseForm: FC<Props> = ({ model, errors, onChange }) => {
    const { t } = useTranslation();
    const {
        errorInstanceName,
        errorContactName,
        errorContactEmail,
        errorPhoneNumber,
        errorInitials,
        errorCustomerType,
    } = errors;

    return (
        <div className="assetForm__container">
            <Input
                value={model.name}
                onChange={(name) => onChange({ name })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_NAME)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_NAME)}
                errorMessage={errorInstanceName}
            />
            <Input
                value={model.contactName}
                onChange={(contactName) => onChange({ contactName })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_NAME)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_NAME)}
                errorMessage={errorContactName}
            />
            <Input
                value={model.email}
                onChange={(email) => onChange({ email })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_EMAIL)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_EMAIL)}
                errorMessage={errorContactEmail}
            />
            <Input
                value={model.phoneNumber}
                onChange={(phoneNumber) => onChange({ phoneNumber })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_PHONE)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE)}
                errorMessage={errorPhoneNumber}
            />
            <Input
                value={model.initials}
                onChange={(phoneNumber) => onChange({ phoneNumber })}
                label={t(TranslationKeys.INSTANCE_FORM_LABEL_ADMIN_PHONE)}
                placeholder={t(TranslationKeys.INSTANCE_FORM_PLACEHOLDER_ADMIN_PHONE)}
                errorMessage={errorInitials}
            />
            <SelectOptions
                isMulti={false}
                label={t(WizardLicenseTranslations.WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL)}
                placeholder={t(WizardLicenseTranslations.WIZARD_LICENSES_CUSTOMER_TYPE_SELECTOR_LABEL)}
                onChange={({ value }) => onChange({ customerType: value as CustomerType })}
                options={customerTypes.map((customerType) => ({ label: t(customerType), value: customerType }))}
                selectedValue={model.customerType}
                errorMessage={errorCustomerType}
            />
        </div>
    );
};

export default LicenseForm;
