import { EditImageAction, EditImageFooterStroke } from "../types";

export const IMAGE_STROKE_VALUES: Record<EditImageFooterStroke, number> = {
    LIGHT: 7,
    REGULAR: 14,
    SOLID: 20,
};

export const INITIAL_HSL_VALUE = 180;
export const ACTIONS_ALLOW_TO_DRAW: EditImageAction[] = ["DRAW", "FORMS"];
export const EDIT_IMAGE_ACTIVE_MENU_ICON_COLOR = "#525252b3";
