type IextraparamsUserGridCounters = {
    customerInstanceId: number;
    companyId: string;
};

export const fillUserGridCountersExtraParams = ({
    companyId,
    customerInstanceId,
}: IextraparamsUserGridCounters): string => {
    const params = new URLSearchParams();

    params.append("customerInstanceId", String(customerInstanceId));
    if (companyId !== "-1") params.append("companyId", companyId);

    return params.toString();
};
