const shortWords = [
    "a",
    "al",
    "asi",
    "con",
    "de",
    "del",
    "e",
    "el",
    "en",
    "la",
    "las",
    "los",
    "mas",
    "muy",
    "ni",
    "o",
    "ora",
    "para",
    "pero",
    "porque",
    "pues",
    "que",
    "se",
    "sea",
    "segun",
    "si",
    "sin",
    "sino",
    "sobre",
    "tal",
    "tras",
    "u",
    "un",
    "una",
    "uno",
    "unos",
    "y",
    "ya",
];
export const getAvatarInitials = (name?: string): string => {
    if (!name) return "";
    let splittedName = name.split(" ");
    let cleanName = splittedName.filter((x) => !shortWords.includes(x));
    if (cleanName.length == 1) return cleanName[0][0].toUpperCase();
    return (cleanName[0][0] + cleanName[1][0]).toUpperCase();
};
