import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { AnalistEntityDetailTaskListModel } from "app/models/03-REP/Analytics/AnalistEntityDetailTaskListModel";
import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";
import { AnalistWorkerDetailModel } from "app/models/03-REP/Analytics/AnalistWorkerDetailModel";
import { AnalistEntityDetailTaskListCounters } from "app/dtos/03-REP/Analytics/AnalistEntityDetailTaskListCounters";
import { AnalistEntityDetailModel } from "app/models/03-REP/Analytics/AnalistEntityDetailModel";

export default class AnalyticsService {
    public static async GetCompany(companyId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistEntityDetailModel>({
            url: `${ApiPaths.REP_API}/Analytics/CompanyDetail/${companyId}`,
            paginationParams: params,
        });
    }

    public static async GetUser(userId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistEntityDetailModel>({
            url: `${ApiPaths.REP_API}/Analytics/UserDetail/${userId}`,
            paginationParams: params,
        });
    }

    public static async GetSupervisorDetail(userId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistSupervisorDetailModel>({
            url: `${ApiPaths.REP_API}/Analytics/SupervisorDetail/${userId}`,
            paginationParams: params,
        });
    }

    public static async GetWorkerDetail(userId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistWorkerDetailModel>({
            url: `${ApiPaths.REP_API}/Analytics/WorkerDetail/${userId}`,
            paginationParams: params,
        });
    }

    // ------------------------------------------------------------------------------------
    public static async GetDepartment(departmentId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistEntityDetailModel>({
            url: `${ApiPaths.REP_API}/Analytics/DepartmentDetail/${departmentId}`,
            paginationParams: params,
        });
    }

    public static async GetTaskList(entityId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistEntityDetailTaskListModel>({
            url: `${ApiPaths.REP_API}/Analytics/TaskList/${entityId}`,
            paginationParams: params,
        });
    }

    public static async GetTaskListCounters(entityId: string | number, params?: PaginationParams) {
        return FetchService.get<AnalistEntityDetailTaskListCounters>({
            url: `${ApiPaths.REP_API}/Analytics/TaskListCounters/${entityId}`,
            paginationParams: params,
        });
    }
    // ------------------------------------------------------------------------------------
}
