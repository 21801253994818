import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuListProps, components } from "react-select";
import { useWindowSize } from "usehooks-ts";
import { TranslationKeys } from "app/translation/translationKeys";
import { screenSize } from "app/shared/Constants";
import { SimpleDepartmentModel } from "app/models/01-SEG/CIDepartamentalStructureModel";
import { faExclamationTriangle, faPen, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Switch } from "../__inputs";
import { Icon } from "../Icon/Icon";
import { Tag } from "../Tag/Tag";
import { CheckBox } from "../CheckBox/CheckBox";
import SelectCustomChildren from "../__inputs/SelectCustomChildren/SelectCustomChildren";
import { TableError } from "../Table/TableError/TableError";

export type CompanyDepartmentBoxProps = {
    title: string;
    departments: SimpleDepartmentModel[];
    sendModifiedData: (values: SimpleDepartmentModel[]) => void;
    assigned: boolean;
    handleToggleChecked: () => void;
    isDisabled?: boolean;
};

const CompanyDepartmentBox: FC<CompanyDepartmentBoxProps> = ({
    title,
    departments,
    sendModifiedData,
    assigned,
    handleToggleChecked,
    isDisabled,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

    const MenuList = (props: MenuListProps<any>) => {
        const handleDepartmentChange = (departmentId: number) => {
            sendModifiedData(
                departments.map((department) => {
                    if (department.departmentId === departmentId) {
                        let updatedDepartment = {
                            ...department,
                            assigned: !department.assigned,
                        };

                        updatedDepartment.subdepartments = updatedDepartment.subdepartments?.map((subdepartment) => ({
                            ...subdepartment,
                            assigned: department.assigned ? false : subdepartment.assigned,
                        }));

                        return updatedDepartment;
                    }
                    return department;
                })
            );
        };

        const handleSubdepartmentChange = (departmentId: number, subdepartmentId: number) => {
            sendModifiedData(
                departments.map((department) => {
                    if (department.departmentId !== departmentId) return department;
                    return {
                        ...department,
                        subdepartments: department.subdepartments?.map((subdepartment) => {
                            if (subdepartment.subdepartmentId !== subdepartmentId) return subdepartment;
                            return {
                                ...subdepartment,
                                assigned: !subdepartment.assigned,
                            };
                        }),
                    };
                })
            );
        };

        return (
            <components.MenuList {...props}>
                {departments.map((department) => (
                    <div key={department.departmentId} className="companyDepartmentBox__options">
                        <div className="companyDepartmentBox__options__checkbox">
                            <CheckBox
                                checked={department.assigned}
                                onChange={() => handleDepartmentChange(department.departmentId)}
                                label={department.departmentName}
                                htmlFor={`department--${department.departmentId}`}
                            />
                        </div>
                        {/*  TODO: --SUBDEPARTMENT--  */}

                        {/* {department.subdepartments && department.subdepartments.length > 0 && (
                            <div className="companyDepartmentBox__options__subdepartment">
                                {department.subdepartments.map((subdepartment) => (
                                    <div
                                        className="companyDepartmentBox__options__checkbox"
                                        key={subdepartment.subdepartmentId}
                                    >
                                        <CheckBox
                                            checked={subdepartment.assigned}
                                            onChange={() =>
                                                handleSubdepartmentChange(
                                                    department.departmentId,
                                                    subdepartment.subdepartmentId
                                                )
                                            }
                                            label={subdepartment.subdepartmentName}
                                            disabled={!department.assigned}
                                            htmlFor={`subdepartment--${subdepartment.subdepartmentId}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        )} */}
                    </div>
                ))}
            </components.MenuList>
        );
    };

    const hasAssignedDepartments = departments.some((department) => department.assigned);
    const onToggleChange = () => {
        handleToggleChecked();
        setIsEditOpen(false);
    };

    return (
        <div className={`companyDepartmentBox${!assigned ? "--disabled" : ""}`}>
            <div className={`companyDepartmentBox__header${!assigned ? "--disabled" : ""}`}>
                <div className="companyDepartmentBox__header__content">
                    <div className="companyDepartmentBox__header__container">
                        {!isDisabled && <Switch checked={assigned} onChange={onToggleChange} inverted />}
                        <p className="companyDepartmentBox__header__title">{title}</p>
                    </div>
                    {!!departments.length && (
                        <div className="companyDepartmentBox__header__icons">
                            {isEditOpen && !isDisabled && width >= screenSize.MOBILE && (
                                <div className="companyDepartmentBox__select">
                                    <SelectCustomChildren
                                        inverted
                                        MenuList={MenuList}
                                        placeholder={t(TranslationKeys.TEAMS)}
                                    />
                                </div>
                            )}
                            {!isDisabled && (
                                <Icon
                                    icon={isEditOpen ? faXmark : faPen}
                                    onClick={() => setIsEditOpen(!isEditOpen)}
                                    disabled={!assigned}
                                />
                            )}
                        </div>
                    )}
                </div>
                {isEditOpen && width < screenSize.MOBILE && (
                    <div className="companyDepartmentBox__select">
                        <SelectCustomChildren inverted MenuList={MenuList} placeholder={t(TranslationKeys.TEAMS)} />
                    </div>
                )}
            </div>
            {!!departments.length && (
                <div className={`companyDepartmentBox__body${!assigned ? "--disabled" : ""}`}>
                    <div className="wrap gap--8">
                        {departments?.flatMap((department) =>
                            department.subdepartments?.length
                                ? department.subdepartments.some((subdepartment) => subdepartment.assigned)
                                    ? department.subdepartments.map(
                                          (subdepartment) =>
                                              subdepartment.assigned && (
                                                  <Tag
                                                      key={`${department.departmentId} - ${subdepartment.subdepartmentId}`}
                                                      message={`${department.departmentName} - ${subdepartment.subdepartmentName}`}
                                                      disabled={!assigned}
                                                      variant={!assigned ? "gray" : "white"}
                                                  />
                                              )
                                      )
                                    : department.assigned && (
                                          <Tag
                                              key={department.departmentId}
                                              message={department.departmentName}
                                              disabled={!assigned}
                                              variant={!assigned ? "gray" : "white"}
                                          />
                                      )
                                : department.assigned && (
                                      <Tag
                                          key={department.departmentId}
                                          message={department.departmentName}
                                          disabled={!assigned}
                                          variant={!assigned ? "gray" : "white"}
                                      />
                                  )
                        )}

                        {!hasAssignedDepartments && (
                            <TableError
                                icon={faExclamationTriangle}
                                title={t(TranslationKeys.NO_DEPARTMENTS_ASSIGNED)}
                                variant={!assigned ? "gray" : "primary"}
                                flex
                                size="sm"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyDepartmentBox;
