import { Bool } from "app/dtos/Bool";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";

export default class TaskInstanceCheckListService {
    // PUT TaskInstanceCheckList option
    public static async Edit(taskCheckListId: number, taskInstanceId: number, value: Bool) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/TaskInstanceCheckList/${taskCheckListId}/${taskInstanceId}`,
            body: value,
        });
    }
}
