import { FC, ReactNode } from "react";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { useGoBack } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { MobileHeaderTitle } from "./MobileHeaderTitle/MobileHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileHeaderTitleSkelleton } from "../__skelletons/MobileHeaderTitleSkelleton/MobileHeaderTitleSkelleton";
import { BreadCrumbs, BreadcrumbModel } from "../BreadCrumbs";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

type MobileHeaderProps = {
    title?: string;
    goBackUrl?: string;
    arrowBack?: boolean;
    leftNode?: ReactNode;
    rightNode?: ReactNode;
    topNode?: ReactNode;
    background?: "primary" | "transparent" | "image" | "grey";
    absolute?: boolean;
    height?: "fixed" | "auto";
    isLoading?: boolean;
    removePadding?: boolean;
    crumb?: BreadcrumbModel[];
    variant?: TabHeaderVariants;
};

export const MobileHeader: FC<MobileHeaderProps> = ({
    arrowBack,
    leftNode,
    rightNode,
    background = "primary",
    goBackUrl,
    title,
    absolute = true,
    height = "fixed",
    isLoading,
    removePadding,
    crumb,
    topNode,
    variant = "primary",
}) => {
    const { realParentGoBack } = useGoBack();
    const nav = useNavigate();

    const handleGoBack = () => {
        goBackUrl ? nav(goBackUrl) : realParentGoBack();
    };

    return (
        <div className="mobileHeader__container">
            <div className="mobileHeader__top">
                {crumb && <BreadCrumbs crumbs={crumb} variant={variant} />} {topNode}
            </div>
            <div
                className={`mobileHeader--${background} ${
                    absolute ? "mobileHeader--absolute" : ""
                } mobileHeader--${height} ${removePadding ? "mobileHeader--removePadding" : ""}`}
            >
                <div className="mobileHeader__navigation">
                    {arrowBack && (
                        <FontAwesomeIcon
                            className="mobileHeader__navigation__arrowBack"
                            icon={faArrowLeft}
                            onClick={handleGoBack}
                        />
                    )}
                    {!title && isLoading && <MobileHeaderTitleSkelleton />}
                    {title && <MobileHeaderTitle title={title} />}
                    {leftNode}
                </div>
                {rightNode}
            </div>
        </div>
    );
};
