import { FC } from "react";
import { DoughnutChart } from "app/components_v2/DoughnutChart/DoughnutChart";
import { DoughnutItem } from "app/components_v2/DoughnutChart/types";
import { ANALYST_ENTITY_TYPE, TaskInstanceStatus } from "app/shared/Constants";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalistFetchDataProps } from "../types";

type AnalistDetailDonutProps = {
    entityType: AnalystEntityType;
    isLoading?: boolean;
    doughnutData: DoughnutItem[];
    fetchData: ({ donutStatus, pageIndex, tabIndex }: AnalistFetchDataProps) => Promise<void>;
};
export const AnalistDetailDonut: FC<AnalistDetailDonutProps> = ({ entityType, isLoading, doughnutData, fetchData }) => {
    return (
        <DoughnutChart
            dounutContainerId="analistDetailDonut"
            onSelectChange={(e) => {
                const taskStatus: Record<number, TaskInstanceStatus> =
                    entityType === ANALYST_ENTITY_TYPE.USER
                        ? {
                              0: TaskInstanceStatus.SHARED_TIMED_OUT,
                              1: TaskInstanceStatus.TIMED_OUT,
                              2: TaskInstanceStatus.OUT_OF_TIME,
                              3: TaskInstanceStatus.IN_TIME,
                          }
                        : {
                              0: TaskInstanceStatus.TIMED_OUT,
                              1: TaskInstanceStatus.OUT_OF_TIME,
                              2: TaskInstanceStatus.IN_TIME,
                          };

                fetchData({
                    donutStatus: taskStatus[e.id],
                    tabIndex: 0,
                });
            }}
            data={doughnutData}
            isLoading={isLoading}
            skelletonOptions={{
                hasPercentage: true,
                quantity: entityType === ANALYST_ENTITY_TYPE.USER ? 4 : 3,
            }}
        />
    );
};
