import { useState } from "react";
import { DatesReviewTask } from "./types";

export const useStartEndDates = (startdate?: Date, endDate?: Date) => {
    const [dates, setDates] = useState<DatesReviewTask>({
        startDate: startdate || new Date(),
        endDate: endDate || new Date(),
    });
    const handleDateChange = (value: Partial<DatesReviewTask>) => {
        setDates((prev) => ({ ...prev, ...value }));
    };

    return { dates, ...dates, onDateChange: handleDateChange };
};
