import { FC, ReactNode } from "react";
import { WhiteBox, WhiteBoxProps } from "../WhiteBox";
import { Collapsable, CollapsableProps } from "app/components_v2/Collapsable/Collapsable";

type WhiteBoxCollapsableProps = {
    children: ReactNode;
    whiteBoxOptions?: Omit<WhiteBoxProps, "children">;
    collapsableOptions?: Omit<CollapsableProps, "children">;
};

export const WhiteBoxCollapsable: FC<WhiteBoxCollapsableProps> = ({
    children,
    collapsableOptions,
    whiteBoxOptions,
}) => {
    return (
        <WhiteBox {...whiteBoxOptions}>
            <Collapsable {...collapsableOptions}>{children}</Collapsable>
        </WhiteBox>
    );
};
