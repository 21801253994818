import { TaskHistoryGridProvider } from "app/pages/02-TAR/09-TAR-Historic/state/provider/TaskHistoryGridProvider";
import { TaskHistoryGrid } from "app/pages/02-TAR/09-TAR-Historic/TaskHistoryGrid/TaskHistoryGrid";
import { PublicPaths } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";

export const RegistryHistoryPage = () => {
    return (
        <TaskHistoryGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path={""} element={<TaskHistoryGrid />} />
            </Routes>
        </TaskHistoryGridProvider>
    );
};

export default RegistryHistoryPage;
