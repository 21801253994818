import { FC } from "react";
import { Button } from "../__buttons/Button/Button";
import { useTranslation } from "react-i18next";
import { faMicrophone, faWaveformLines } from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon } from "app/translation/translationKeys";

type TranscriptionRecorderProps = {
    isTranscripting: boolean;
    onStartTranscripting: () => void;
    onEndTranscripting: () => void;
};

export const TranscriptionRecorder: FC<TranscriptionRecorderProps> = ({
    isTranscripting,
    onEndTranscripting,
    onStartTranscripting,
}) => {
    const { t } = useTranslation();

    return (
        <div className="audioRecorder">
            <div className="audioRecorder__container">
                {isTranscripting && (
                    <p className="audioRecorder__container__recording">
                        {t(TranslationCommon.TRANSCRIPTING)}
                    </p>
                )}
                <Button
                    onClick={isTranscripting ? onEndTranscripting : onStartTranscripting}
                    iconRight={isTranscripting ? faWaveformLines : faMicrophone}
                    size="xs"
                    buttonType="button"
                />
            </div>
        </div>
    );
};
