import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { PlanAPPCCGrid } from "./PlanAPPCCGrid/PlanAPPCCGrid";
import { PlanAPPCCGridProvider } from "./state/Provider/PlanAPPCCGridProvider";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";

export const PlanAPPCCPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_PLANS);
    return (
        <PlanAPPCCGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route
                    path={""}
                    element={<PrivateRoute component={PlanAPPCCGrid} viewCode={SecScreen.PLAN_APPCC} />}
                ></Route>
            </Routes>
        </PlanAPPCCGridProvider>
    );
};
