import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, FC } from "react";

type EditImageIconProps = {
    icon: IconDefinition;
    active?: boolean;
    onClick: () => void;
    activeColor?: string;
    ariaLabel?: string;
};

export const EditImageIcon: FC<EditImageIconProps> = ({ icon, active, activeColor = "black", onClick, ariaLabel }) => {
    return (
        <button
            className={`editImageIcon ${active ? "editImageIcon--active" : ""}`}
            onClick={onClick}
            style={{ "--active-bg": activeColor } as CSSProperties}
            aria-pressed={!!active}
            aria-label={ariaLabel}
        >
            <FontAwesomeIcon className="editImageIcon__svg" icon={icon} />
        </button>
    );
};
