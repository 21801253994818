import { FC } from "react";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";

type IssueBodyDescriptionProps = {
    label: string;
    description: string;
    audioURL?: string;
};

export const IssueBodyDescription: FC<IssueBodyDescriptionProps> = ({ label, description, audioURL }) => {
    return (
        <div className="issueBodyDescription">
            <IssueBodyTitle>{label}</IssueBodyTitle>
            <p className="issueBodyDescription__description">{description}</p>
            {!!audioURL && <audio className="issueBodyDescription__audio" src={audioURL} controls role="audio" />}
        </div>
    );
};
