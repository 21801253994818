import React, { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faBell, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/pro-solid-svg-icons";
import { Avatar } from "../Avatar/Avatar";
import { UserValoration } from "../UserValoration/UserValoration";
import { Badge } from "../Badge/Badge";
import { NotificationsModal } from "../__modals/NotificationsModal/NotificationsModal";

type WorkerPillProps = {
    fullName: string;
    userId: number;
    undoedTasks: number;
    starsValoration: number;
    totalValoration: number;
    onClick?: () => void;
    trend?: number;
    profileURL?: string;
};

export const WorkerPill: FC<WorkerPillProps> = React.memo(
    ({
        fullName,
        userId,
        undoedTasks,
        starsValoration,
        totalValoration,
        onClick,
        trend,
        profileURL,
    }) => {
        const [showModal, setShowModal] = useState<boolean>(false);

        return (
            <>
                {showModal && (
                    <NotificationsModal
                        userId={userId}
                        userName={fullName}
                        onClose={() => setShowModal(false)}
                        profileURL={profileURL}
                    />
                )}
                <div className="workerPill" onClick={() => onClick && onClick()}>
                    <div className="workerPill__info">
                        <div className="workerPill__info__line">
                            <Avatar
                                img={profileURL}
                                avatarName={fullName}
                                colorId={userId}
                                size="xs"
                                name={fullName}
                            />
                            <div className="workerPill__info__line__icons">
                                <FontAwesomeIcon
                                    className="workerPill__info__line__icons__icon"
                                    icon={faBell}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowModal(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="workerPill__info__line">
                            <UserValoration
                                valoration={starsValoration}
                                variant="gray"
                                total={String(totalValoration)}
                            />
                            {trend !== undefined && (
                                <Badge
                                    icon={
                                        trend > 0
                                            ? faArrowTrendUp
                                            : trend < 0
                                            ? faArrowTrendDown
                                            : faArrowRightLong
                                    }
                                    variant={trend > 0 ? "green" : trend < 0 ? "red" : "grey"}
                                    title={`${trend}%`}
                                    iconPosition="left"
                                    size="sm"
                                />
                            )}
                        </div>
                    </div>
                    <div className={`workerPill__tasks${undoedTasks ? "--error" : ""}`}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                        <p className="workerPill__tasks__number">{undoedTasks}</p>
                    </div>
                </div>
            </>
        );
    }
);
