import { FC, ReactNode } from "react";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TableTabHeaderActions } from "../TableTabHeaderActions/TableTabHeaderActions";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type ActionButtonsComponent = {
    component: ReactNode;
    show?: boolean;
};

export type ActionButtonsModel = {
    icon: IconProp;
    onClick: () => void;
    hidden?: boolean;
    inputComponent?: ActionButtonsComponent;
    disabled?: boolean;
    showMarkableIcon?: boolean;
    title?: string;
};

export type TableTabHeaderProps = {
    title?: string;
    subTitle?: string;
    tabs?: TabPropsReduced[];
    currentTab?: number;
    actionButtons?: ActionButtonsModel[];
    onClickCollapsable?: () => void;
    isOpen?: boolean;
    leftIcon?: ReactNode;
    isLoading?: boolean;
    variant?: TabHeaderVariants;
};

export const TableTabHeader: FC<TableTabHeaderProps> = ({
    tabs,
    title,
    currentTab,
    subTitle,
    actionButtons,
    onClickCollapsable,
    isOpen = true,
    leftIcon,
    isLoading = false,
    variant = "primary",
}) => {
    return (
        <div className={`tableTab tableTab--${variant}`}>
            <div
                className={`tableTab__header--${variant} tableTab__header${!title || !actionButtons ? "--row" : ""} ${
                    onClickCollapsable ? "pointer" : ""
                }`}
                onClick={(e) => {
                    e.stopPropagation();
                    onClickCollapsable && onClickCollapsable();
                }}
            >
                <div className={`tableTab__header__titles${!title || !actionButtons ? "--row" : ""}`}>
                    {(title || leftIcon) && (
                        <div className="tableTab__header__titles__container">
                            {leftIcon}
                            {title && (
                                <h3
                                    className={`tableTab__header__titles__title tableTab__header__titles__title--${variant}`}
                                >
                                    {title}
                                </h3>
                            )}
                        </div>
                    )}
                    <p className="tableTab__header__titles__subtitle">{subTitle}</p>
                </div>
                <TableTabHeaderActions
                    actionButtons={actionButtons}
                    onClickCollapsable={onClickCollapsable}
                    oneRow={!title || !actionButtons}
                    isOpen={isOpen}
                    variant={variant}
                />
            </div>
            {tabs && isOpen && (
                <Tabs tabs={tabs} currentTab={currentTab} hideChildren isLoading={isLoading} variant={variant} />
            )}
        </div>
    );
};
