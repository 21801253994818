import { FC, useState } from "react";
import { generateArrayOfConsecutiveNumbers } from "app/helpers/arrayGeneration";
import { AuditGroupCheckListItemModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListItemModels";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { Tag } from "app/components_v2/Tag/Tag";
import { SelectOptions } from "app/components_v2/__inputs";

type AuditGroupCheckListItemProps = {
    data: AuditGroupCheckListItemModel;
    onChangeItem: (ponderationLevel: string, id: number) => void;
    onDeleteItem: (id: number) => void;
};

export const AuditGroupCheckListItem: FC<AuditGroupCheckListItemProps> = ({ data, onChangeItem, onDeleteItem }) => {
    const { name, fK_BaseAuditGroupCheckListItem, ponderationLevel: basePonderationLevel } = data;

    const [ponderationLevel, setPonderationLevel] = useState<number>(basePonderationLevel);

    const options: OptionsSearch[] = generateArrayOfConsecutiveNumbers(11).map((item) => ({
        label: String(item),
        value: String(item),
    }));

    const handleChange = ({ value }: OptionsSearch) => {
        setPonderationLevel(Number(value));
        onChangeItem(value, fK_BaseAuditGroupCheckListItem);
    };

    return (
        <div className="auditGroupCheckListItem">
            <Tag message={name} onClose={() => onDeleteItem(fK_BaseAuditGroupCheckListItem)} clamp={2} size="auto" />
            <div className="auditGroupCheckListItem__ponderation">
                <SelectOptions
                    selectedValue={String(ponderationLevel)}
                    options={options}
                    onChange={handleChange}
                    isMulti={false}
                />
            </div>
        </div>
    );
};
