import { FC } from "react";
import { FullScreenHeaderOptions } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

type FullScreenModalHeaderProps = FullScreenHeaderOptions;

export const FullScreenModalHeader: FC<FullScreenModalHeaderProps> = ({ onClose, title }) => {
    return (
        <header className="fullScreenModalHeader">
            <button className="fullScreenModalHeader__btn" onClick={onClose}>
                <div className="fullScreenModalHeader__icon">
                    <FontAwesomeIcon icon={faXmark} className="fullScreenModalHeader__icon__content" />
                </div>
                {title}
            </button>
        </header>
    );
};
