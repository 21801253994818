import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "../../../routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "../../../shared/Constants";
import RoleGrid from "./RoleGrid";
import RoleEdit from "./RoleEdit/RoleEdit";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";

const RolePage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_ROLES);
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />}></Route>
            <Route path={""} element={<PrivateRoute component={RoleGrid} viewCode={SecScreen.ROLE_MANAGEMENT} />} />
            <Route
                path="edit/:id"
                element={<PrivateRoute component={RoleEdit} viewCode={SecScreen.ROLE_MANAGEMENT} />}
            />
        </Routes>
    );
};

export default RolePage;
