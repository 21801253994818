import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AuditGroupCheckListInstanceTranslations } from "app/translation/translationKeys";
import { ColumnsType } from "app/components_v2/Table/types";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { UserValorationVariantModel } from "app/components_v2/UserValoration/UserValoration";
import Table from "app/components_v2/Table/Table";
import { TableNoTabHeader } from "app/components_v2/Table/TableNoTabHeader/TableNoTabHeader";
import { MobileAuditGroupCheckListInstance } from "../MobileAuditGroupCheckListInstance/MobileAuditGroupCheckListInstance";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";

type AuditReportGridProps = {
    data: AuditInstanceModel;
    auditStarsNode: (grade: number | null, starVariant?: UserValorationVariantModel) => ReactNode;
};

export const AuditReportGrid: FC<AuditReportGridProps> = ({ data, auditStarsNode }) => {
    const { t } = useTranslation();

    const columns: ColumnsType<AuditGroupCheckListInstanceModel>[] = [
        {
            dataIndex: "name",
            label: t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NAME),
            render: ({ name }) => <CellTitle title={name} />,
        },
        {
            dataIndex: "grade",
            label: t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_VALORATION),
            className: "auditReportGrid__column",
            render: ({ grade }) => auditStarsNode(grade),
        },
    ];

    const calculatePrintedBreaks = () => {
        const maxRowsPerPage = 18;
        const printBreaks: boolean[] = [];
        let printedRows = 0;
        const { auditGroupInstances } = data;
        auditGroupInstances.map(({ auditGroupCheckListInstances }, index) => {
            let nextLenght = 5;
            const currentLength = 2 + auditGroupCheckListInstances.length;
            if (auditGroupInstances[index + 1])
                nextLenght = 2 + auditGroupInstances[index + 1].auditGroupCheckListInstances.length;
            printedRows += currentLength;

            if (printedRows + nextLenght > maxRowsPerPage) {
                printBreaks[index] = true;
                printedRows = 0;
            } else {
                printBreaks[index] = false;
            }
        });
        return printBreaks;
    };

    return (
        <div className="auditReportGrid" onClick={calculatePrintedBreaks}>
            {!!data.auditGroupInstances.length ? (
                data.auditGroupInstances.map(({ name, auditGroupCheckListInstances, grade }, index) => (
                    <div key={index}>
                        <TableNoTabHeader title={name} rightNode={auditStarsNode(grade, "white")} variant="appcc" />
                        <Table
                            cols={columns}
                            data={auditGroupCheckListInstances}
                            variant="appcc"
                            placeholder={
                                <TableError
                                    icon={faListCheck}
                                    title={t(
                                        AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE
                                    )}
                                    description={t(
                                        AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION
                                    )}
                                />
                            }
                            fitHeight
                            mobileBody={(row, _i, rowPosition) => (
                                <MobileAuditGroupCheckListInstance
                                    data={row}
                                    auditStars={auditStarsNode}
                                    rowPosition={rowPosition}
                                />
                            )}
                        />
                        {calculatePrintedBreaks()[index] && <div className="pagebreak"></div>}
                    </div>
                ))
            ) : (
                <TableError
                    icon={faListCheck}
                    title={t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_TITLE)}
                    description={t(
                        AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_TABLE_ERROR_DESCRIPTION_FILTER
                    )}
                />
            )}
        </div>
    );
};
