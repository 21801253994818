import { FC } from "react";
import { faCirclePlus, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "../../../../../../shared/Constants";

type AuditBoxHeaderProps = {
    auditName: string;
    onChangeShowSaveModal: (value: boolean) => void;
    onChangeShowEditModal: (value: boolean) => void;
    onChangeShowDeleteModal: (value: boolean) => void;
};

export const AuditBoxHeader: FC<AuditBoxHeaderProps> = ({
    auditName,
    onChangeShowSaveModal,
    onChangeShowEditModal,
    onChangeShowDeleteModal,
}) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();

    return (
        <div className="auditBoxHeader">
            <p className="auditBoxHeader__title">{auditName}</p>
            <div className="auditBoxHeader__buttons">
                <Button
                    text={width >= screenSize.TABLET ? t(TranslationKeys.ADD_AUDIT_GROUP) : ""}
                    iconLeft={faCirclePlus}
                    isDisabled={!hasPermissionToAdd(SecScreen.AUDIT_GROUP_MANAG)}
                    onClick={() => onChangeShowSaveModal(true)}
                    variant="appcc"
                />
                <Button
                    text={width >= screenSize.TABLET ? t(TranslationCommon.EDIT) : ""}
                    iconRight={faPen}
                    isDisabled={!hasPermissionToEdit(SecScreen.AUDIT_MANAGEMENT)}
                    onClick={() => onChangeShowEditModal(true)}
                    type="secondary"
                    variant="appcc"
                />
                <Button
                    text={width >= screenSize.TABLET ? t(TranslationCommon.DELETE) : ""}
                    iconRight={faTrash}
                    isDisabled={!hasPermissionToDelete(SecScreen.AUDIT_MANAGEMENT)}
                    onClick={() => onChangeShowDeleteModal(true)}
                    type="secondary"
                    variant="danger"
                />
            </div>
        </div>
    );
};
