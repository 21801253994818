import { FC } from "react";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { UserScoreModel } from "app/models/03-REP/Analytics/UserScoreModel";
import { screenSize, SupervisorProgressbarColors } from "app/shared/Constants";
import DividerWithText from "../../DividerWithText/DividerWithText";
import CircularProgressBar from "../../SupervisorAnalist/CircularProgressBar/CircularProgressBar";
import { TableError } from "../../Table/TableError/TableError";

type FastCommentValorationDetailProps = {
    fastComments: UserScoreModel[];
    emptyState: boolean;
};

export const FastCommentValorationDetail: FC<FastCommentValorationDetailProps> = ({ fastComments, emptyState }) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    return (
        <div className="fastCommentValorationDetail">
            <DividerWithText text={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_COMMENTS)} />
            <div className="fastCommentValorationDetail__content">
                {emptyState ? (
                    <TableError
                        icon={faComment}
                        title={t(TranslationKeys.NO_COMMENTS)}
                        description={t(TranslationKeys.NO_COMMENTS_SUBTITLE)}
                    />
                ) : (
                    fastComments.map(({ rangeType, count, percentage }, i) => (
                        <CircularProgressBar
                            key={i}
                            progressColor={SupervisorProgressbarColors[i % SupervisorProgressbarColors?.length]}
                            legendText={t(TranslationKeys[rangeType as keyof typeof TranslationKeys])}
                            percentage={percentage}
                            legendNumber={count}
                            size={width >= screenSize.MOBILE ? 120 : 100}
                            strokeWidth={12}
                        />
                    ))
                )}
            </div>
        </div>
    );
};
