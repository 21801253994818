import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstanceService } from "app/services";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { MAX_INITIALS_LENGTH, RegexPatterns } from "app/shared/Constants";
import { INITIAL_WIZARD_LICENSE_ERRORS } from "../constants/constants";
import { customerTypes } from "../../constants/LicensesConstants";
import { IuseStepOne, WizardLicenseStepOneErrors } from "../models/models";

export const useStepOne = ({
    contactEmail,
    contactName,
    instanceName,
    phoneNumber,
    initials,
    customerType,
    next,
}: IuseStepOne) => {
    const { t } = useTranslation();
    const [validations, setValidations] = useState<WizardLicenseStepOneErrors>(INITIAL_WIZARD_LICENSE_ERRORS);

    const areInitialsUsedFetch = async (initials: string) => {
        const { data } = await InstanceService.AreInitialsUsed(initials);
        return data;
    };

    const handleErrorChange = (values: Partial<WizardLicenseStepOneErrors>) =>
        setValidations((prev) => ({ ...prev, ...values }));

    const validate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        handleErrorChange(INITIAL_WIZARD_LICENSE_ERRORS);

        if (!instanceName.length) {
            handleErrorChange({ errorInstanceName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!contactName.length) {
            handleErrorChange({ errorContactName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!new RegExp(RegexPatterns.mail).test(contactEmail)) {
            handleErrorChange({ errorContactEmail: t(TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE) });
            check = false;
        }

        if (!new RegExp(RegexPatterns.phoneNumber).test(phoneNumber)) {
            handleErrorChange({ errorPhoneNumber: t(TranslationErrors.INVALID_PHONE_NUMBER) });
            check = false;
        }

        if (!initials.length) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_CANNOT_BE_EMPTY) });
            check = false;
        } else if (initials.length !== MAX_INITIALS_LENGTH) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_MAX_LENGTH) });
            check = false;
        } else if (!RegexPatterns.onlyTwoAlphabeticalCharacters.test(initials)) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_INVALID_FORMAT) });
            check = false;
        } else if (await areInitialsUsedFetch(initials)) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_ALREADY_USED) });
            check = false;
        }

        if (!customerType.length) {
            handleErrorChange({ errorCustomerType: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        } else if (!customerTypes.includes(customerType)) {
            handleErrorChange({ errorCustomerType: t(TranslationErrors.INVALID_CUSTOMER_TYPE) });
            check = false;
        }

        if (check) next();
    };

    return { validate, errorMessages: validations };
};
