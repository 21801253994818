import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskListModel } from "app/models/02-TAR/BaseTask/BaseTaskListModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import { TranslationModals } from "app/translation/translationKeys";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_BASE_TASK_SORT_DIRECTION, INITIAL_BASE_TASK_SORT_FIELD } from "../constants/baseTaskConstants";
import { fillExtraParams } from "../helpers/fillExtraParams";
import { BaseTaskGridFetchModel } from "../models/BaseTaskPageModels";
import { BaseTaskGridContext } from "../state/context/BaseTaskContext";
import { useLocation } from "react-router";
import BaseTaskAPPCCService from "app/services/05-QUA/BaseTaskAPPCCService";

export const useBaseTask = () => {
    const { pathname } = useLocation();
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const { onChangePageIndex, onSFChange, onSortChange, pageIndex, secondaryFilterValues, sortDirection, sortField } =
        useContext(BaseTaskGridContext);

    const { query } = secondaryFilterValues;

    const [baseTask, setBaseTask] = useState<BaseTaskListModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query?.length);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const isAPPCC = pathname.includes(PrivatePaths.BASE_TASK_APPCC_MANAGEMENT);

    const fetchAll = async ({ pi, sortD, sortF, baseTaskSf }: BaseTaskGridFetchModel = {}) => {
        setIsLoading(true);

        const filterData = baseTaskSf || secondaryFilterValues;
        const queryFetch = baseTaskSf?.query || query;
        const sortDirectionFetch = sortD || sortDirection;
        const sortFieldFetch = sortF || sortField;

        const params = {
            query: queryFetch,
            sortDirection: sortDirectionFetch,
            sortField: sortFieldFetch,
            pageIndex: pi !== undefined ? pi : pageIndex - 1,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams: fillExtraParams({ isAPPCC, ...filterData }),
        };

        const service = isAPPCC ? BaseTaskAPPCCService : BaseTaskService;
        const baseTaskPromise = service.GetData(params);

        Promise.all([baseTaskPromise])
            .then(([baseTaskSr]) => {
                baseTaskDataResponseManager(baseTaskSr);
            })
            .catch(() => {
                setBaseTask([]);
            })
            .finally(() => setIsLoading(false));
    };

    const baseTaskDataResponseManager = ({ status, data }: ServiceResponse<PaginatedResult<BaseTaskListModel>>) => {
        if (!status()) {
            handleToast({
                title: t(TranslationModals.BASE_TASK_TOAST_FAILED_LOAD_DATA),
                variant: "danger",
                type: "alert",
            });
            setBaseTask([]);
            return;
        }
        setBaseTask(data.list);
        setTotal(data.count);
    };

    const handleSortChange = (sortF: string, sortD: SortedTypeModel) => {
        onSortChange(sortF, sortD);
        fetchAll({ sortF, sortD });
    };

    const handleCleanFetch = () => {
        onSFChange({ query: "" });
        setShowSearchInput(false);
        fetchAll({ baseTaskSf: { ...secondaryFilterValues, query: "" }, pi: 0 });
        onChangePageIndex(1);
    };

    const onQuerySearch = () => {
        if (isLoading) return;
        if (!!query.length) {
            fetchAll({ pi: 0 });
            onChangePageIndex(1);
            return;
        }
        handleCleanFetch();
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const service = isAPPCC ? BaseTaskAPPCCService : BaseTaskService;
        return service.Export({
            query,
            sortDirection: sortDirection === "default" ? INITIAL_BASE_TASK_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_BASE_TASK_SORT_FIELD : sortField,
            ...params,
        });
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return {
        onChangePageIndex,
        onSFChange,
        pageIndex,
        sortDirection,
        sortField,
        baseTask,
        total,
        showSearchInput,
        setShowSearchInput,
        isLoading,
        fetchAll,
        handleSortChange,
        onQuerySearch,
        handleExport,
        secondaryFilterValues,
        handleCleanFetch,
        isAPPCC,
    };
};
