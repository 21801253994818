import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useIssue } from "app/components_v2/Issue/hooks/useIssue";
import { IssueBody } from "app/components_v2/Issue/IssueBody/IssueBody";
import { IssueHistory } from "app/components_v2/Issue/IssueHistory/IssueHistory";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import Spinner from "app/components_v2/Spinner/Spinner";
import { IssueTranslation, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { useTitle } from "app/hooks";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import { PrivatePaths } from "app/shared/Constants";

export const EditIssue = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const nav = useNavigate();
    const { isLoading, issue, handleFinish, handleAddNewAttachments } = useIssue({
        id: Number(id),
        onError: (httpsCodeStatus) => {
            if (httpsCodeStatus === 404) nav(`/${PrivatePaths.ISSUE}`);
        },
    });

    useTitle(t(TranslationTitles.EDIT_ISSUE_TITLE));

    const title: Record<IssueTypeCodeModel, string> = {
        AUDIT: `${t(IssueTranslation.ISSUE_TITLE_AUDIT)}${id}`,
        GENERIC: `${t(IssueTranslation.ISSUE_TITLE_GENERIC)}${id}`,
        TASK: `${t(IssueTranslation.ISSUE_TITLE_TASK)}${id}`,
        TASK_ASSET: t(IssueTranslation.ISSUE_TITLE_ASSET)
            .replace("{0}", String(id))
            .replace("{1}", String(issue?.asset?.id || "")),
    };

    return (
        <FormLayout
            isLoading={isLoading}
            variant="gray"
            headerOptions={{
                title: issue?.issueType ? title[issue?.issueType] : "",
                buttonLabel: "",
                showDeleteButton: false,
                type: "delete",
                isActive: issue?.actualStatus === "OPEN",
                badge: {
                    isVisible: true,
                    title:
                        issue?.actualStatus === "OPEN"
                            ? t(TranslationKeys.ISSUE_STATUS_OPEN)
                            : t(TranslationKeys.ISSUE_STATUS_CLOSE),
                    hideIcon: true,
                    variant: issue?.actualStatus === "CLOSED" ? "green" : "red",
                },
                isLoading: isLoading,
            }}
        >
            {isLoading || !issue ? (
                <div className="editIssue__spinner">
                    <Spinner />
                </div>
            ) : (
                <div className="editIssue__container">
                    <WhiteBox>
                        <IssueBody issue={issue} onAddNewAttachments={handleAddNewAttachments} />
                    </WhiteBox>
                    <WhiteBox>
                        <IssueHistory issue={issue} onFinish={handleFinish} />
                    </WhiteBox>
                </div>
            )}
        </FormLayout>
    );
};
