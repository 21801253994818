import { createContext } from "react";
import { EntitiesWithPendingTasksPerDay } from "app/dtos/01-SEG/EntitiesWithPendingTasksPerDay/EntitiesWithPendingTasksPerDayDto";

export type PendingTasksCompanyDepartmentModalContextModel = {
    companiesAndDepartmentsWithPendingTasks: EntitiesWithPendingTasksPerDay;
    isOpen: boolean;
    open: (closeOpenCompanies: EntitiesWithPendingTasksPerDay) => void;
    reset: () => void;
};

export const PendingTasksCompanyDepartmentModalContext = createContext<PendingTasksCompanyDepartmentModalContextModel>(
    {} as PendingTasksCompanyDepartmentModalContextModel
);
