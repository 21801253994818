import { FC, useState } from "react";
import { EditImageAction, EditImageFooterStroke } from "../types";
import { EditImageFooterIcon } from "../EditImageFooterIcon/EditImageFooterIcon";
import { faScribble as faScribbleSolid } from "@fortawesome/pro-solid-svg-icons";
import { faScribble as faScribbleRegular } from "@fortawesome/pro-regular-svg-icons";
import { faScribble as faScribbleLight } from "@fortawesome/pro-light-svg-icons";
import { IMAGE_STROKE_VALUES } from "../constants/EditImageConstants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type EditImageFooterDrawProps = {
    currentAction: EditImageAction | null;
    onChangeStroke: (stroke: number) => void;
};

type IconType = {
    icon: IconProp;
    onClick: () => void;
    active: boolean;
};

export const EditImageFooterDraw: FC<EditImageFooterDrawProps> = ({ currentAction, onChangeStroke }) => {
    const [activeStroke, setActiveStroke] = useState<EditImageFooterStroke>("REGULAR");

    const handleClick = (stroke: EditImageFooterStroke) => {
        if (stroke === activeStroke) return;
        setActiveStroke(stroke);
        onChangeStroke(IMAGE_STROKE_VALUES[stroke]);
    };

    const editImageFooterIcons: IconType[] = [
        { icon: faScribbleLight, onClick: () => handleClick("LIGHT"), active: activeStroke === "LIGHT" },
        { icon: faScribbleRegular, onClick: () => handleClick("REGULAR"), active: activeStroke === "REGULAR" },
        { icon: faScribbleSolid, onClick: () => handleClick("SOLID"), active: activeStroke === "SOLID" },
    ];

    return (
        <div className="editImageFooterDraw">
            <div className="editImageFooterDraw__container">
                {editImageFooterIcons.map(({ active, icon, onClick }, i) => (
                    <EditImageFooterIcon icon={icon} onClick={onClick} active={active} key={i} />
                ))}
            </div>
        </div>
    );
};
