import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { BaseTaskGridProvider } from "./state/providers/BaseTaskGridProvider";
import { BaseTaskGrid } from "./BaseTaskGrid/BaseTaskGrid";
import { EditBaseTask } from "./EditBaseTask/EditBaseTask";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";

export const BaseTaskAPPCCPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_APPCC_BASETASK);

    return (
        <BaseTaskGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route
                    path=""
                    element={<PrivateRoute component={BaseTaskGrid} viewCode={SecScreen.BASE_TASK_MANAGEMENT} />}
                />
                <Route
                    path="new"
                    element={<PrivateRoute component={EditBaseTask} viewCode={SecScreen.BASE_TASK_MANAGEMENT} />}
                />
                <Route
                    path="edit/:id"
                    element={<PrivateRoute component={EditBaseTask} viewCode={SecScreen.BASE_TASK_MANAGEMENT} />}
                />
            </Routes>
        </BaseTaskGridProvider>
    );
};
