import { v4 } from "uuid";
import { ChatBotMessageModel } from "../types";
import { CHAT_BOT_ACTION_KEYS_RECORD } from "../constants/chatBotConstants";
import { faCheckDouble, faListCheck, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { useUserType } from "app/hooks/useUserType";
import { useTranslation } from "react-i18next";
import { ChatBotGPTTranslations } from "app/translation/translationKeys";

export const useChatBotMessages = () => {
    const { isWorker } = useUserType();
    const { t } = useTranslation();

    const getMessages = () => {
        const INITIAL_MESSAGES: ChatBotMessageModel[] = [
            {
                date: new Date(),
                id: v4(),
                isLoading: false,
                sender: "assistant",
                text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_IA_PRESENTATION_MESSAGE),
                type: "text",
                isErrored: false,
            },
            {
                date: new Date(),
                id: v4(),
                isLoading: false,
                sender: "assistant",
                text: "",
                type: "button",
                isErrored: false,
                buttons: [
                    {
                        actionKey: CHAT_BOT_ACTION_KEYS_RECORD.USER_INFO,
                        icon: faUsers,
                        text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_USER_OPTION),
                        show: true,
                        readonly: false,
                    },
                    {
                        actionKey: CHAT_BOT_ACTION_KEYS_RECORD.TASK_INFO,
                        icon: faListCheck,
                        text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_TASK_OPTION),
                        show: true,
                        readonly: false,
                    },
                    {
                        actionKey: CHAT_BOT_ACTION_KEYS_RECORD.APPCC_INFO,
                        icon: faCheckDouble,
                        text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_APPCC_OPTION),
                        show: true,
                        readonly: false,
                    },
                ],
            },
        ];

        return INITIAL_MESSAGES;
    };

    const getInitialMessages = () => {
        if (!isWorker) return getMessages();

        const initialMessages = getMessages();

        initialMessages.push({
            date: new Date(),
            id: v4(),
            isLoading: false,
            sender: "user",
            text: t(ChatBotGPTTranslations.CHAT_BOT_GTP_WORKER_TEXT),
            type: "text",
            isHidden: true,
            isErrored: false,
        });

        return initialMessages;
    };

    return { initialMessage: getInitialMessages() };
};
