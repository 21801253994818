import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useSession, useTitle } from "app/hooks";
import { useEffect, useState } from "react";
import { CompanyService } from "app/services";
import { GenerateQr } from "app/components_v2/GenerateQr/GenerateQr";
import Spinner from "app/components_v2/Spinner/Spinner";
import { PRINT_QR_URL, PrivatePaths } from "app/shared/Constants";
import { TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";

export const PrintQr = () => {
    const session = useSession();
    const nav = useNavigate();
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.PRINT_QR_PAGE_TITLE));

    const [searchParams] = useSearchParams();
    const [companyPhoto, setCompanyPhoto] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [areAllImagesLoaded, setAreAllImagesLoaded] = useState<boolean>(false);

    const qrName = searchParams.get("qrName");
    const companyName = searchParams.get("companyName");
    const qrCode = searchParams.get("qrCode");
    const companyId = searchParams.get("companyId");
    const qrId = searchParams.get("qrId");

    useTitle(`QR 4Link - ${qrName?.toString().replace(":", "") || ""} (${qrCode})`);

    const getCompany = async () => {
        if (!companyId) return nav(`/${PrivatePaths.QR}/edit/${qrId}`);
        const { data, status } = await CompanyService.GetOne(companyId, session?.user.customerInstanceId || 0);
        if (!status()) {
            nav(`/${PrivatePaths.QR}/edit/${qrId}`);
            return;
        }
        setCompanyPhoto(data.logoFileURL);
        setIsLoading(false);
        printQr();
    };

    const printQr = (isLoaded?: boolean) => {
        if (isLoading && (!areAllImagesLoaded || !isLoaded)) return;
        window.print();
        nav(`/${PrivatePaths.QR}/edit/${qrId}`);
    };

    useEffect(() => {
        getCompany();
    }, []);

    if (qrName == null || companyName == null || qrCode == null)
        return <Navigate to={`/${PrivatePaths.QR}/edit/${qrId}`} />;

    return (
        <div className="printQr">
            {isLoading ? (
                <Spinner />
            ) : (
                <WhiteBox>
                    <GenerateQr
                        companyName={companyName}
                        companyPhoto={companyPhoto}
                        qrName={qrName}
                        url={`${PRINT_QR_URL}/${qrCode}`}
                        qrCode={qrCode}
                        onAllImagesLoaded={() => {
                            setAreAllImagesLoaded(true);
                            printQr(true);
                        }}
                    />
                </WhiteBox>
            )}
        </div>
    );
};
