import { BaseTaskGridFetchModel } from "../models/BaseTaskPageModels";
import { BaseTaskGridSFModel } from "../state/context/BaseTaskContext";

type BaseTaskExtraParamsModel = BaseTaskGridSFModel &
    BaseTaskGridFetchModel & {
        isAPPCC?: boolean;
    };

export const fillExtraParams = ({
    isAPPCC,
    baseTaskTypesId,
    isPhotoRequired,
    reportType,
    planId,
}: BaseTaskExtraParamsModel): string => {
    const extraParams = new URLSearchParams();
    const tabName = isAPPCC ? "APPCC" : "NO_APPCC";

    extraParams.append("Tab", tabName);
    if (!!baseTaskTypesId.length)
        extraParams.append("BaseTaskTypesId", baseTaskTypesId.map(({ value }) => value).join(";"));
    isPhotoRequired && extraParams.append("IsPhotoRequired", "true");
    reportType !== undefined && extraParams.append("ReportType", reportType.toString());
    planId !== "-1" && tabName === "APPCC" && extraParams.append("PlanId", planId.toString());

    return extraParams.toString();
};
