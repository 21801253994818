import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { CriticalTask } from "app/components_v2/CriticalTask/CriticalTask";

export const CriticalTaskSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="CriticalTask" show={show}>
            <p>Aquí va CriticalTask</p>
            <CriticalTask />
            <CriticalTask title="CRÍTICA" />
        </Section>
    );
};
