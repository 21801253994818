import { PaginationDefaults } from "app/shared/Constants";
import { FC } from "react";

type Props = {
    quantity?: number;
};

export const AuditIssuePillListSkelleton: FC<Props> = ({ quantity = PaginationDefaults.PAGE_SIZE }) => {
    return (
        <div className="auditPillListSkelleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div key={index} className="auditIssuePillSkelleton">
                    <div className="auditIssuePillSkelleton__header">
                        <div className="auditIssuePillSkelleton__avatar">
                            <div className="auditIssuePillSkelleton__avatar__img"></div>
                            <div className="auditIssuePillSkelleton__avatar__info">
                                <div className="auditIssuePillSkelleton__avatar__info__name">
                                    <div className="auditIssuePillSkelleton__avatar__info__name-fs"></div>
                                </div>
                                <div className="auditIssuePillSkelleton__avatar__info__date">
                                    <div className="auditIssuePillSkelleton__avatar__info__date-fs"></div>
                                </div>
                            </div>
                        </div>
                        <div className="auditIssuePillSkelleton__status"></div>
                    </div>
                    <div className="auditIssuePillSkelleton__body ">
                        <div className="auditIssuePillSkelleton__description w-70">
                            <div className="auditIssuePillSkelleton__description-fs"></div>
                        </div>
                        <div className="auditIssuePillSkelleton__description w-50">
                            <div className="auditIssuePillSkelleton__description-fs"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
