import { IssueHistoryReopenModel, IssueHistoryReopenModelError } from "../models/types";

export const INITIAL_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES: IssueHistoryReopenModel = {
    correctiveMeassures: [],
    description: "",
};

export const INITIAL_ERROR_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES: IssueHistoryReopenModelError = {
    errorDescription: "",
    errorCorrectiveMeassures: "",
};
