import { useState } from "react";
import { UserPageFormValues } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type IuseSelectAllCompanies = {
    onInputChange: (values: Partial<UserPageFormValues>) => void;
    companyOptions: OptionModel[];
};

export const useSelectAllCompanies = ({ companyOptions, onInputChange }: IuseSelectAllCompanies) => {
    const [isSelectAllCompaniesModalOpen, setIsSelectAllCompaniesModalOpen] = useState<boolean>(false);

    const onConfirmSelectAllCompanies = () => {
        onInputChange({
            userCompanyList: companyOptions,
        });
        setIsSelectAllCompaniesModalOpen(false);
    };

    return { isSelectAllCompaniesModalOpen, onConfirmSelectAllCompanies, setIsSelectAllCompaniesModalOpen };
};
