import { PaginationDefaults } from "app/shared/Constants";
import { FC } from "react";

type Props = {
    quantity?: number;
    oneRow?: boolean;
};

export const AuditPillSkelleton: FC<Props> = ({ quantity = PaginationDefaults.PAGE_SIZE, oneRow }) => {
    return (
        <div className="auditPillListSkelleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div key={index} className="auditPill auditPillSkelleton">
                    <div className={`auditPill__section${oneRow ? "--center" : ""}`}>
                        <p className="auditPill__section__title skeleton-box fullWidth"></p>
                        {!oneRow && <div className="auditPill__section__progressBar skeleton-box fullWidth"></div>}
                    </div>
                    <div className="auditPill__section--row">
                        <div className="auditPill__section__item auditPillSkelleton__avatar">
                            <div className="auditPillSkelleton__avatar__icon--square skeleton-box"></div>
                            <div className="auditPillSkelleton__avatar__label skeleton-box"></div>
                        </div>
                        <div className="auditPill__section__item auditPillSkelleton__avatar">
                            <div className="auditPillSkelleton__avatar__icon--round skeleton-box"></div>
                            <div className="auditPillSkelleton__avatar__label skeleton-box"></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
