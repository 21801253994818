import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { createContext } from "react";

export type TaskHistoryGridContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    secondaryFilterValues: TaskHisotryGridSfModel;
    onSFChange: (sfValues: Partial<TaskHisotryGridSfModel>) => void;
    selectCompany: SelectCompanyModel;
    onCompanyChange: (value: Partial<SelectCompanyModel>) => void;
    query: string;
    onQueryChange: (value: string) => void;
};

export type TaskHisotryGridSfModel = {
    userId: string;
    departmentId: string;
    subDepartmentId: string;
    startDate: Date | null;
    endDate: Date | null;
    pending: boolean;
    nearEnd: boolean;
    timedOut: boolean;
    inTime: boolean;
    outOfTime: boolean;
    isAssignedToQR: boolean;
    fiveStar: boolean;
    oneStar: boolean;
    twoStar: boolean;
    threeStar: boolean;
    fourStar: boolean;
    isCritical: boolean;
    isDataReportRequired: boolean;
    isPhotoRequired: boolean;
    hasComments: boolean;
    taskType: string;
    taskId: string;
    assetsId: OptionModel[];
};

export const TaskHistoryGridContext = createContext<TaskHistoryGridContextModel>({} as TaskHistoryGridContextModel);
