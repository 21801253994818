import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { PaginationDefaults } from "app/shared/Constants";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditGroupCheckListInstanceTranslations, AuditInstanceTranslations } from "app/translation/translationKeys";
import { CrudHeaderLeftNodeSkelleton } from "app/components_v2/__skelletons/CrudHeaderLeftNodeSkelleton/CrudHeaderLeftNodeSkelleton";
import { Badge } from "app/components_v2/Badge/Badge";
import { UserValorationVariantModel, UserValoration } from "app/components_v2/UserValoration/UserValoration";

type UseAuditReportParams = {
    auditInstanceId: number;
    actualStatus: AuditInstanceStatus;
    auditId?: number;
    companyId?: number;
    baseAuditGroupCheckListItemTypeId?: number;
};

export const useAuditReport = ({
    auditInstanceId,
    auditId,
    companyId,
    baseAuditGroupCheckListItemTypeId,
}: UseAuditReportParams) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();

    const [auditHistory, setAuditHistory] = useState<AuditInstanceModel[]>([]);
    const [auditInstance, setAuditInstance] = useState<AuditInstanceModel>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchAuditReportData = async (
        urlCompanyId?: number,
        urlAuditId?: number,
        urlBaseAuditGroupCheckListItemTypeId?: number
    ) => {
        const fetchCompanyId = urlCompanyId || companyId;
        const fetchAuditId = urlAuditId || auditId;
        if (!fetchCompanyId || !fetchAuditId) return;
        setIsLoading(true);

        const extraParams = new URLSearchParams();
        extraParams.append("auditId", fetchAuditId.toString());
        extraParams.append("companyId", fetchCompanyId.toString());
        extraParams.append("status", "CLOSED");

        const auditHistoryPromise = AuditInstanceService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: 0,
            sortField: "id",
            sortDirection: "DESC",
            extraParams: extraParams.toString(),
        });

        const fetchBaseAuditGroupCheckListItemTypeId =
            urlBaseAuditGroupCheckListItemTypeId || baseAuditGroupCheckListItemTypeId;
        const singleExtraParams = new URLSearchParams();
        if (fetchBaseAuditGroupCheckListItemTypeId !== -1)
            singleExtraParams.append(
                "baseAuditGroupCheckListItemTypeId",
                String(fetchBaseAuditGroupCheckListItemTypeId)
            );
        const auditInstancePromise = AuditInstanceService.GetOne(auditInstanceId, {
            extraParams: singleExtraParams.toString(),
        });

        Promise.all([auditHistoryPromise, auditInstancePromise])
            .then(([auditHistorySr, auditInstanceSr]) => {
                const auditHistoryErrorMessage = getErrorMessage(auditHistorySr.getParsedError());
                const auditHistoryHasError = handleErrorManager(auditHistorySr.status(), auditHistoryErrorMessage);
                if (auditHistoryHasError) setAuditHistory([]);
                setAuditHistory(auditHistorySr.data.list);

                const auditInstanceErrorMessage = getErrorMessage(auditInstanceSr.getParsedError());
                const auditInstanceHasError = handleErrorManager(auditInstanceSr.status(), auditInstanceErrorMessage);
                if (auditInstanceHasError) setAuditInstance(undefined);
                setAuditInstance(auditInstanceSr.data);
            })
            .catch(() => handleErrorManager(false, t(AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_ERROR)))
            .finally(() => setIsLoading(false));
    };

    const formatGrade = (grade: number | null): string =>
        grade != null
            ? `${gradePercentage(grade)}%`
            : t(AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE);

    const gradeStars = (grade: number | null): number => {
        return Number((roundGrade(grade) * 5).toFixed(2));
    };

    const gradePercentage = (grade: number | null): number => {
        return Number((roundGrade(grade) * 100).toFixed(0));
    };

    const roundGrade = (grade: number | null) => Math.round(((grade ?? 0) + Number.EPSILON) * 100) / 100;

    const renderAuditStars = (grade: number | null, starVariant: UserValorationVariantModel = "gray"): ReactNode =>
        isLoading ? (
            <div className="starsSkelleton">
                <CrudHeaderLeftNodeSkelleton showTitle={true} showBadge={true} />
            </div>
        ) : (
            <div className="auditReportGrid__grade">
                <UserValoration
                    valoration={gradeStars(grade)}
                    variant={starVariant}
                    showIcons={false}
                    justify="start"
                    width="auto"
                    showZeroValoration={grade != null}
                />
                <div className={`auditReportGrid__grade__badge${grade == null ? "--bigWidth" : ""}`}>
                    <Badge
                        title={formatGrade(grade)}
                        size="sm"
                        variant={
                            grade == null
                                ? "grey"
                                : grade > 0.9
                                ? "green"
                                : grade <= 0.9 && grade > 0.7
                                ? "yellow"
                                : grade <= 0.7 && grade > 0.5
                                ? "orange"
                                : "red"
                        }
                    />
                </div>
            </div>
        );

    return {
        fetchAuditReportData,
        auditHistory,
        auditInstance,
        isLoading,
        renderAuditStars,
    };
};
