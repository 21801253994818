import { Navigate, Route, Routes } from "react-router-dom";
import { CorrectiveMeasureGrid } from "./CorrectiveMeasureGrid/CorrectiveMeasureGrid";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";

export const CorrectiveMeasurePage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_CORRECTIVE_MEASURES);
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route
                path={""}
                element={<PrivateRoute component={CorrectiveMeasureGrid} viewCode={SecScreen.CORR_MEASURE_MANAGER} />}
            ></Route>
        </Routes>
    );
};
