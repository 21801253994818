import { useEffect, useState } from "react";

export const useScrollTop = (scrollHeight = 1, className = ".customContainer") => {
    const [headerColor, setHeaderColor] = useState<boolean>(false);
    useEffect(() => {
        const container = document.querySelector(className);
        const handleScroll = () => {
            if (container?.scrollTop && container?.scrollTop > scrollHeight) {
                setHeaderColor(true);
            } else {
                setHeaderColor(false);
            }
        };
        if (!container) return;
        container.addEventListener("scroll", handleScroll);
        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return headerColor;
};
