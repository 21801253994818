import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const SelectWithActionNoOptions = () => {
    const { t } = useTranslation();
    return (
        <div className="selectWithActionNoOptions">
            <div className="selectWithActionNoOptions__container">
                <p className="selectWithActionNoOptions__text">{t(TranslationCommon.NO_MORE_OPTIONS)}</p>
            </div>
        </div>
    );
};
