import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { BaseAuditGroupCheckListItemGrid } from "./BaseAuditGroupCheckListItemGrid/BaseAuditGroupCheckListItemGrid";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";

export const BaseAuditGroupCheckListItemPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_BASE_AUDIT_ITEMS);
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route
                path={""}
                element={
                    <PrivateRoute component={BaseAuditGroupCheckListItemGrid} viewCode={SecScreen.AUDIT_INST_MANAG} />
                }
            />
        </Routes>
    );
};
