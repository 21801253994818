import { useParams } from "react-router";

export const useGetDateFromURLParams = () => {
    const { userId } = useParams();
    const userIdFormated = Number(userId);

    return {
        userId: userIdFormated,
    };
};
