import { faCircleXmark, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { DateRangePicker } from "app/components_v2/__inputs";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskTemporalityDateRangeFormModel } from "app/models/02-TAR/TaskWizard";
import { TaskTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

type TaskTemporalityDateRangeProps = {
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel[];
    customError: CustomErrorTaskTemporalityModel;
    numberMaxOfDates?: number;
    isDisabled?: boolean;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityDateRange: FC<TaskTemporalityDateRangeProps> = ({
    customError,
    isDisabled,
    taskTemporalityDateRange,
    numberMaxOfDates = 12,
    onChange,
}) => {
    const { t } = useTranslation();

    const taskTemporalityDateRangeFiltered = taskTemporalityDateRange.filter(({ isDeleted }) => !isDeleted);
    const isAddNewVisible = taskTemporalityDateRangeFiltered.length < numberMaxOfDates && !isDisabled;

    const handleChangeDates = (dates: Date | [Date | null, Date | null] | null, id: string) => {
        if (dates === null || !Array.isArray(dates)) return;
        const [startDate, endDate] = dates;
        if (startDate === null || endDate === null) return;
        onChange({
            taskTemporalityDateRange: taskTemporalityDateRange.map((ttdr) =>
                ttdr.id === id
                    ? {
                          ...ttdr,
                          startDate: startDate.toString(),
                          endDate: endDate.toString(),
                      }
                    : ttdr
            ),
        });
    };

    const handleAddNew = () => {
        onChange({
            taskTemporalityDateRange: [
                ...taskTemporalityDateRange,
                {
                    dbId: null,
                    startDate: new Date().toString(),
                    endDate: new Date().toString(),
                    id: v4(),
                    isDeleted: false,
                },
            ],
        });
    };

    const handleDelete = (id: string) => {
        const selectedDate = taskTemporalityDateRange.find((ttdr) => ttdr.id === id);
        if (!selectedDate) return;

        if (selectedDate.dbId === null) {
            onChange({
                taskTemporalityDateRange: taskTemporalityDateRange.filter((ttdr) => ttdr.id !== id),
            });
            return;
        }

        onChange({
            taskTemporalityDateRange: taskTemporalityDateRange.map((ttdr) =>
                ttdr.id === id
                    ? {
                          ...ttdr,
                          isDeleted: true,
                      }
                    : ttdr
            ),
        });
    };

    return (
        <>
            <div className="taskTemporalityDateRange">
                <div className="taskTemporalityDateRange__container">
                    {taskTemporalityDateRangeFiltered.map(({ startDate, endDate, id }) => (
                        <div className="taskTemporalityDateRange__date" key={id}>
                            <DateRangePicker
                                onChange={(dates) => handleChangeDates(dates, id)}
                                startDate={new Date(startDate)}
                                endDate={!!endDate?.length ? new Date(endDate) : undefined}
                                range={true}
                                disabled={isDisabled}
                            />
                            <FontAwesomeIcon
                                icon={faCircleXmark}
                                className="deleteInputIcon"
                                onClick={() => handleDelete(id)}
                            />
                        </div>
                    ))}
                    {isAddNewVisible && (
                        <AddNewButton onClick={handleAddNew} icon={faPlus}>
                            {t(TaskTranslations.ADD_TASK_TEMPORALITY_RANGE_DATES)}
                        </AddNewButton>
                    )}
                </div>
                {!!customError.dateRange.length && <ErrorMessage errorMessage={customError.dateRange} />}
            </div>
        </>
    );
};
