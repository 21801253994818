import { useRef, useState, MouseEvent, TouchEvent } from "react";
import { ACTIONS_ALLOW_TO_DRAW, IMAGE_STROKE_VALUES, INITIAL_HSL_VALUE } from "../constants/EditImageConstants";
import { EditImageAction, EditImageCropperActions, EditImageFormsAction, EditImageHsl } from "../types";
import { ReactCropperElement } from "react-cropper";
import { reject } from "lodash";

type IuseEditImage = {
    file?: File;
    url?: string;
    onAccept: (imageBase64: string) => void;
    onCancel: () => void;
};

export const useEditImage = ({ file, url, onAccept, onCancel }: IuseEditImage) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
    const startX = useRef<number>(0);
    const startY = useRef<number>(0);
    const lastX = useRef<number>(0);
    const lastY = useRef<number>(0);
    const cropperRef = useRef<ReactCropperElement>(null);

    const [isDrawing, setIsDrawing] = useState(false);
    const [lineWidth, setLineWidth] = useState(IMAGE_STROKE_VALUES.REGULAR);
    const [lineColor, setLineColor] = useState<EditImageHsl>({
        hslColor: `hsl(${INITIAL_HSL_VALUE}, 100%, 50%)`,
        hslValue: INITIAL_HSL_VALUE,
    });
    const [currentAction, setCurrentAction] = useState<EditImageAction | null>(null);
    const [isDrawed, setIsDrawed] = useState<boolean>(false);
    const [originalImageWhileDrawing, setOriginalImageWhileDrawing] = useState<ImageData | null>(null);
    const [originalImage, setOriginalImage] = useState<ImageData | null>(null);
    const [currentForm, setCurrentForm] = useState<EditImageFormsAction | null>(null);

    const convertFileToCanvas = async () => {
        try {
            if (!file && !url?.length) return;
            const fileToWrite = file || (await convertUrltoFile());
            if (!fileToWrite) return;

            const reader = new FileReader();
            reader.onerror = (e) => {
                console.log(e);
            };
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    drawImage(img);
                };
                img.src = e.target?.result as string;
            };

            reader.readAsDataURL(fileToWrite);
        } catch (error) {
            onCancel();
        }
    };

    const convertUrltoFile = async (): Promise<File | null> => {
        try {
            if (!url?.length) return null;

            const image = new Image();
            image.src = url;
            image.crossOrigin = "anonymous"; // Asegura que el CORS sea correcto

            // Esperamos a que la imagen se cargue completamente antes de continuar
            await new Promise<void>((resolve, reject) => {
                image.onload = () => resolve(); // Esperamos a que la imagen esté completamente cargada
                image.onerror = (error) => reject(`Error al cargar la imagen: ${error}`);
            });

            // Después de que la imagen está cargada, convertimos la imagen a archivo
            const newFile = await imageToFile(image, "image-from-url.webp");

            return newFile || new File([], ""); // Si no hay archivo, devolvemos un archivo vacío
        } catch (error) {
            return null;
        }
    };

    const imageToFile = (image: HTMLImageElement, filename: string, mimeType = "webp"): Promise<File | null> => {
        return new Promise((resolve) => {
            // Create canvas
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the image into canvas
            const ctx = canvas.getContext("2d");
            if (!ctx) return;

            ctx.drawImage(image, 0, 0);

            // Convert canvas into Blob
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject("");
                    onCancel();
                    return;
                }
                // Create file from Blob
                const file = new File([blob], filename, { type: mimeType });
                resolve(file);
            }, mimeType);
        });
    };

    const drawImage = (img: HTMLImageElement) => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d", { willReadFrequently: true });

        if (!ctx) return;
        const width = img.width;
        const height = img.height;

        canvas.width = width;
        canvas.height = height;

        // Draw image into canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);

        // Add properties
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor.hslColor;
        ctxRef.current = ctx;
        const imageDataFromCanvas = ctx.getImageData(
            0,
            0,
            canvasRef.current?.width || 0,
            canvasRef.current?.height || 0
        );
        setOriginalImage(imageDataFromCanvas);
        setOriginalImageWhileDrawing(imageDataFromCanvas);
    };

    const startDrawing = (e: MouseEvent<HTMLCanvasElement, globalThis.MouseEvent> | TouchEvent<HTMLCanvasElement>) => {
        if (!ctxRef.current) return;
        if (!currentAction || !ACTIONS_ALLOW_TO_DRAW.includes(currentAction)) return;
        if (e.type !== "mousedown" && e.type !== "touchstart") return;

        const { x, y } =
            e.type === "mousedown"
                ? getMousePos(e as MouseEvent<HTMLCanvasElement, globalThis.MouseEvent>)
                : getTouchPos(e as TouchEvent<HTMLCanvasElement>);

        startX.current = x;
        startY.current = y;
        lastX.current = x;
        lastY.current = y;

        const imageDataFromCanvas = ctxRef.current.getImageData(
            0,
            0,
            canvasRef.current?.width || 0,
            canvasRef.current?.height || 0
        );

        setOriginalImageWhileDrawing(imageDataFromCanvas);
        setIsDrawing(true);
    };

    const draw = (e: MouseEvent<HTMLCanvasElement, globalThis.MouseEvent> | TouchEvent<HTMLCanvasElement>) => {
        if (!isDrawing || !ctxRef.current) return;
        if (e.type !== "mousemove" && e.type !== "touchmove") return;

        const { x, y } =
            e.type === "mousemove"
                ? getMousePos(e as MouseEvent<HTMLCanvasElement, globalThis.MouseEvent>)
                : getTouchPos(e as TouchEvent<HTMLCanvasElement>);

        setIsDrawed(true);
        if (currentAction === "DRAW") return freeDraw(x, y);

        if (currentAction === "FORMS" && currentForm === "RECTANGLE") return drawRectangle(x, y);

        if (currentAction === "FORMS" && currentForm === "CIRCLE") return drawCircle(x, y);
    };

    // DRAW
    const freeDraw = (x: number, y: number) => {
        if (!ctxRef.current) return;

        ctxRef.current.beginPath();
        ctxRef.current.moveTo(lastX.current, lastY.current);
        ctxRef.current.lineTo(x, y);
        ctxRef.current.stroke();
        lastX.current = x;
        lastY.current = y;
    };

    const drawRectangle = (x: number, y: number) => {
        if (!originalImageWhileDrawing || !ctxRef.current) return;

        ctxRef.current.putImageData(originalImageWhileDrawing, 0, 0);

        let width = x - startX.current;
        let height = y - startY.current;

        ctxRef.current.beginPath();
        ctxRef.current.rect(startX.current, startY.current, width, height);
        ctxRef.current.stroke();
        return;
    };

    const drawCircle = (x: number, y: number) => {
        if (!originalImageWhileDrawing || !ctxRef.current) return;

        ctxRef.current.putImageData(originalImageWhileDrawing, 0, 0);

        const radius = Math.sqrt(Math.pow(x - startX.current, 2) + Math.pow(y - startY.current, 2));

        ctxRef.current.beginPath();
        ctxRef.current.arc(startX.current, startY.current, radius, 0, 2 * Math.PI);
        ctxRef.current.stroke();
        setIsDrawed(true);
        return;
    };

    const endDrawing = () => {
        if (!currentAction || !ACTIONS_ALLOW_TO_DRAW.includes(currentAction)) return;
        if (!ctxRef.current) return;
        ctxRef.current.closePath();
        setIsDrawing(false);
    };

    const handleUndoDraw = () => {
        if (!ctxRef.current || !originalImage || !canvasRef.current) return;
        setIsDrawed(false);
        convertFileToCanvas();
    };
    const handleChangeStroke = (strokeWidth: number) => {
        if (!ctxRef.current) return;
        ctxRef.current.lineWidth = strokeWidth;
        setLineWidth(strokeWidth);
    };

    const handleChangeColor = (hslValue: number, hslColor: string) => {
        if (!ctxRef.current) return;
        setLineColor({ hslColor, hslValue });
        ctxRef.current.strokeStyle = hslColor;
    };

    const handleChangeForm = (form: EditImageFormsAction) => {
        setCurrentForm(form);
    };
    // End DRAW

    // CROP
    const onSelectCropperAction = (action: EditImageCropperActions) => {
        if (action === "ACCEPT") {
            onCrop();
            setCurrentAction(null);
            return;
        }
        if (action === "CANCEL") {
            setCurrentAction(null);
            return;
        }
    };

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (!cropper || !canvasRef.current || !ctxRef.current) return;

        const croppedCanvas = cropper.getCroppedCanvas();
        if (!croppedCanvas) return;
        const ctx = ctxRef.current;

        // Ajusta el tamaño del canvas a la imagen recortada
        canvasRef.current.width = croppedCanvas.width;
        canvasRef.current.height = croppedCanvas.height;

        // Dibuja la imagen recortada en el canvas
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.drawImage(croppedCanvas, 0, 0);
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor.hslColor;

        ctxRef.current = ctx;
        setIsDrawed(true);
    };
    // END CROP

    // MIRROR
    const handleMirror = () => {
        const ctx = ctxRef.current;
        const canvas = canvasRef.current;
        if (!ctx || !canvas) return;

        // Crear una nueva imagen a partir del canvas actual
        const image = new Image();
        image.src = canvas.toDataURL();

        // Limpiar el canvas antes de aplicar el efecto espejo
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Cuando la imagen esté cargada, aplicamos el efecto espejo
        image.onload = () => {
            ctx.save();

            // Aplicar el efecto espejo
            ctx.translate(canvas.width, 0);
            ctx.scale(-1, 1);

            // Dibujar la imagen reflejada
            ctx.drawImage(image, 0, 0);

            // Restaurar el estado original del contexto
            ctx.restore();

            setIsDrawed(true);
            setOriginalImageWhileDrawing(ctx.getImageData(0, 0, canvas.width, canvas.height));
        };
    };
    // End MIRROR

    // ROTATE
    const handleRotate = (angle = 90) => {
        const ctx = ctxRef.current;
        const canvas = canvasRef.current;
        if (!ctx || !canvas || !originalImageWhileDrawing) return;

        // Crear un canvas temporal para manejar el contenido
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        if (!tempCtx) return;

        // Ajustar el tamaño del canvas temporal al original
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        tempCtx.putImageData(originalImageWhileDrawing, 0, 0);

        // Copiar el contenido del canvas original al temporal
        tempCtx.drawImage(canvas, 0, 0);

        // Limpiar el canvas original
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Guardar las dimensiones originales
        const originalWidth = canvas.width;
        const originalHeight = canvas.height;

        // Intercambiar ancho y alto si la rotación es 90 o 270 grados
        if (angle === 90 || angle === 270) {
            canvas.width = originalHeight;
            canvas.height = originalWidth;
        }

        // Guardar el estado actual del contexto
        ctx.save();

        // Mover el punto de origen al centro del canvas
        ctx.translate(canvas.width / 2, canvas.height / 2);

        // Aplicar la rotación
        ctx.rotate((angle * Math.PI) / 180);

        // Ajustar las coordenadas de dibujo para centrarlas después de la rotación
        ctx.drawImage(tempCanvas, -originalWidth / 2, -originalHeight / 2);

        // Restaurar el estado original del contexto
        ctx.restore();

        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor.hslColor;
        setOriginalImageWhileDrawing(ctx.getImageData(0, 0, canvas.width, canvas.height));
        setIsDrawed(true);
    };
    // End ROTATE

    // UTILITIES
    const getMousePos = (e: MouseEvent<HTMLCanvasElement, globalThis.MouseEvent>) => {
        const canvas = canvasRef.current;
        if (!canvas) return { x: 0, y: 0 };

        // Tamaño real del canvas en píxeles
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        // Coordenadas del ratón ajustadas por la escala
        return {
            x: (e.clientX - rect.left) * scaleX,
            y: (e.clientY - rect.top) * scaleY,
        };
    };

    const getTouchPos = (e: TouchEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;
        if (!canvas) return { x: 0, y: 0 };

        // Tamaño real del canvas en píxeles
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        // Coordenadas del primer toque ajustadas por la escala
        const touch = e.touches[0];

        return {
            x: (touch.clientX - rect.left) * scaleX,
            y: (touch.clientY - rect.top) * scaleY,
        };
    };

    const getImage = () => {
        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL("image/webp", 0.7);
        return dataURL;
    };
    // END UTILITIES

    const handleClickIcon = (action: EditImageAction) => {
        if (action === "MIRROR") {
            handleMirror();
            return;
        }

        if (action === "ROTATE") {
            handleRotate();
            return;
        }

        setCurrentAction(currentAction === action ? null : action);
        if (action === "FORMS") setCurrentForm("RECTANGLE");
        if (currentAction === "CROPPER") onCrop();
    };

    const handleCloseEditingWidgets = () => {
        setCurrentAction(null);
        setCurrentForm(null);
        if (currentAction === "CROPPER") onCrop();
    };

    const handleCancel = () => {
        if (!currentAction) {
            onCancel();
            return;
        }
        handleCloseEditingWidgets();
    };

    const handleSendImage = () => {
        const dataURL = getImage();
        if (!dataURL) return;
        onAccept(dataURL);
    };

    return {
        convertFileToCanvas,
        currentAction,
        handleCloseEditingWidgets,
        handleCancel,
        handleClickIcon,
        isDrawed,
        handleUndoDraw,
        lineColor,
        canvasRef,
        startDrawing,
        endDrawing,
        draw,
        handleChangeColor,
        handleChangeStroke,
        currentForm,
        handleChangeForm,
        handleSendImage,
        getImage,
        cropperRef,
        onSelectCropperAction,
    };
};
