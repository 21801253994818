import { FC } from "react";

type SystemNotificationSkelletonProps = {
    quantity?: number;
};

export const SystemNotificationSkelleton: FC<SystemNotificationSkelletonProps> = ({
    quantity = 1,
}) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, index) => (
                <div className="sysNotification-skelleton " key={index}>
                    <div className="sysNotification-skelleton__icon"></div>
                    <div className="sysNotification-skelleton__body">
                        <div className="sysNotification-skelleton__body__title"></div>
                        <div className="sysNotification-skelleton__body__text">
                            <div className="sysNotification-skelleton__body__text--85"></div>
                            <div className="sysNotification-skelleton__body__text--85"></div>
                            <div className="sysNotification-skelleton__body__text--50"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
