import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type WizardHeaderNumberProps = {
    variant: "success" | "primary" | "gray";
    id: number;
    totalSteps: number;
    currentStep: number;
};

export const WizardHeaderNumber: FC<WizardHeaderNumberProps> = ({
    id,
    variant = "gray",
    totalSteps,
    currentStep,
}) => {
    const getLeftConnectorVariant = () => {
        if (id === 1) return;
        if (currentStep === id || currentStep > id) return "--success";
        if (variant === "gray") return "--gray";
    };
    const getRightConnectorVariant = () => {
        if (id >= totalSteps) return;
        if (currentStep === id) return "--primary";
        if (variant === "gray") return "--gray";
        if (currentStep > id) return "--success";
    };

    return (
        <div className={`wizardHeaderNumber`}>
            <div
                className={`wizardHeaderNumber__connector${
                    id === 1 ? "--h0" : ""
                } wizardHeaderNumber__connector${getLeftConnectorVariant()}`}
            />
            {variant === "gray" ? (
                <div className="wizardHeaderNumber__ball--gray">
                    <div className="wizardHeaderNumber__ball--gray__id">{id}</div>
                </div>
            ) : variant === "primary" ? (
                <div className="wizardHeaderNumber__ball--primary">
                    <div className="wizardHeaderNumber__ball--primary__inner"></div>
                </div>
            ) : (
                <div className="wizardHeaderNumber__ball--success">
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="wizardHeaderNumber__ball--success__icon"
                    />
                </div>
            )}
            <div
                className={`wizardHeaderNumber__connector${
                    id >= totalSteps ? "--h0" : ""
                } wizardHeaderNumber__connector${getRightConnectorVariant()}`}
            />
        </div>
    );
};
