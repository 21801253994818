import { DateRangePicker } from "app/components_v2/__inputs";
import { convertUTCtoLocaleDate } from "app/helpers";
import { useToast } from "app/hooks/Toast/useToast";
import { RoleScheduledModel } from "app/models/01-SEG/Role/RoleScheduledModel";
import { RoleService } from "app/services";
import { TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

export type DateRange = [Date | undefined, Date | undefined];

type TemporaryroleEditDatesProps = {
    selectedRole?: RoleScheduledModel;
    onFinish: () => void;
    setIsSaving: (value: boolean) => void;
};

type DatesModel = {
    startDate?: Date;
    endDate?: Date;
};

export const TemporaryroleEditDates: FC<TemporaryroleEditDatesProps> = ({ onFinish, selectedRole, setIsSaving }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const [dates, setDates] = useState<DatesModel>({
        startDate: selectedRole?.dateFrom ? new Date(selectedRole.dateFrom) : new Date(),
        endDate: selectedRole?.dateTo ? new Date(selectedRole.dateTo) : new Date(),
    });
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { startDate, endDate } = dates;

    const handleDatesChange = ([start, end]: Date[]) => {
        if (start) setDates({ startDate: start, endDate: undefined });
        if (end) setDates({ startDate: startDate, endDate: end });
    };

    const handleService = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!selectedRole) return;
        if (!validate()) return;
        const body = { ...selectedRole };
        if (selectedRole.range && selectedRole.range.length === 2 && startDate && endDate) {
            body.dateFrom = convertUTCtoLocaleDate(startDate);
            body.dateTo = convertUTCtoLocaleDate(endDate);
            body.range = undefined;
        }
        setIsSaving(true);
        const { status } = await RoleService.EditScheduledRole(body, selectedRole.id);
        status()
            ? onFinish()
            : handleToast({
                  title: t(TranslationModals.TOAST_GENERIC_ERROR),
                  type: "alert",
                  variant: "danger",
              });
        setIsSaving(false);
    };

    const validate = () => {
        setErrorMessage("");
        if (!startDate || !endDate) {
            setErrorMessage(t(TranslationCommon.INPUT_NOT_EMPTY));
            return false;
        }
        return true;
    };

    return (
        <form className="editRoleForm" onSubmit={handleService} id="editRoleDatesForm">
            <DateRangePicker
                onChange={handleDatesChange}
                startDate={dates.startDate}
                endDate={dates.endDate}
                label={t("wizardTemporaryRoleDatePicker")}
                min={new Date()}
                errorMessage={errorMessage}
            />
        </form>
    );
};
