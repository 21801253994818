import { useTranslation } from "react-i18next";
import { AutomaticRecordTranslations } from "app/translation/translationKeys";
import { faSignalGood, faSignalStrong, faSignalWeak } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { SignalLevels } from "../constants/automaticRecordConstants";

export const useSensorSignal = () => {
    const { t } = useTranslation();

    const signalBadgeTitle = (singalLevel: number, mobile?: boolean): string =>
        mobile
            ? t(AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL)
            : singalLevel <= SignalLevels.LOW_SIGNAL
            ? t(AutomaticRecordTranslations.SIGNAL_LEVEL_LOW_LABEL)
            : singalLevel > SignalLevels.LOW_SIGNAL && singalLevel < SignalLevels.MEDIUM_SIGNAL
            ? t(AutomaticRecordTranslations.SIGNAL_LEVEL_MEDIUM_LABEL)
            : t(AutomaticRecordTranslations.SIGNAL_LEVEL_HIGH_LABEL);

    const signalBadgeColor = (singalLevel: number): BadgeVariant =>
        singalLevel <= SignalLevels.LOW_SIGNAL
            ? "red"
            : singalLevel > SignalLevels.LOW_SIGNAL && singalLevel < SignalLevels.MEDIUM_SIGNAL
            ? "yellow"
            : "green";

    const signalBadgeIcon = (singalLevel: number): IconProp =>
        singalLevel <= SignalLevels.LOW_SIGNAL
            ? faSignalWeak
            : singalLevel > SignalLevels.LOW_SIGNAL && singalLevel < SignalLevels.MEDIUM_SIGNAL
            ? faSignalGood
            : faSignalStrong;

    return {
        signalBadgeTitle,
        signalBadgeColor,
        signalBadgeIcon,
    };
};
