import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { TextArea } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranscription } from "app/hooks/Transcription/useTranscription";

export const TextAreaSection: FC<SectionsShowProp> = ({ show }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>("");

    const handleTranscriptionChange = (_: string, newTextTranscripted: string) => {
        const currentLength = value.length + newTextTranscripted.length;
        const remainingSpace = 140 - currentLength;
        if (remainingSpace == 0) return;
        const textToAdd = newTextTranscripted.slice(0, remainingSpace);

        setValue((prev) => `${prev}${textToAdd}`);
    };

    const { isRecording, onStartRecording, stopRecording } = useTranscription({
        onChange: handleTranscriptionChange,
        value,
    });

    return (
        <Section show={show} title="Textarea Section">
            <h3 className="designSys__section__element__title">TextArea</h3>
            <TextArea
                text={value}
                label="TextArea"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={setValue}
                maxLength={140}
                showTranscription
            />
            <TextArea
                text={value}
                label="TextArea"
                placeholder={t(TranslationKeys.MESSAGE)}
                onChange={setValue}
                maxLength={140}
                uploadAudio={{
                    showSendAudio: true,
                    audioBase64: "",
                    audioUrl: "",
                    isRecording: false,
                    setAudioBase64: () => {},
                    setAudioUrl: () => {},
                    setIsRecording: () => {},
                }}
            />
        </Section>
    );
};
