import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import Spinner from "app/components_v2/Spinner/Spinner";

export const LoaderSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Loaders" show={show}>
            <Spinner />
        </Section>
    );
};
