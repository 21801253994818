import { FC } from "react";
import { MobileHeaderTitleSkelleton } from "../MobileHeaderTitleSkelleton/MobileHeaderTitleSkelleton";
import { AvatarSkelleton } from "../AvatarSkelleton";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";

type CrudHeaderLeftNodeSkelletonProps = {
    showTitle?: boolean;
    showAvatar?: boolean;
    showBadge?: boolean;
};

export const CrudHeaderLeftNodeSkelleton: FC<CrudHeaderLeftNodeSkelletonProps> = ({
    showAvatar,
    showTitle,
    showBadge,
}) => {
    const { width } = useWindowSize();
    return (
        <>
            {showTitle && <MobileHeaderTitleSkelleton />}
            {showAvatar && (
                <AvatarSkelleton showAvatarName size={width < screenSize.TABLET ? "sm" : "lg"} />
            )}
            {showBadge && (
                <div
                    className={`crudHeaderLeftNodeBadge--${
                        width < screenSize.TABLET ? "sm" : "lg"
                    }`}
                ></div>
            )}
        </>
    );
};
