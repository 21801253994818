import { AutomaticRecordGridSF } from "../models/AutomaticRecordGridModels";

export enum BatteryLevels {
    LOW_BATTERY = 3.3,
    MEDIUM_BATTERY = 3.8,
}

export enum SignalLevels {
    LOW_SIGNAL = -130,
    MEDIUM_SIGNAL = -100,
}

export const INITIAL_AUTOMATIC_RECORD_FILTER_DATA: AutomaticRecordGridSF = {
    batteryLevel: "",
    signalLevel: "",
    dateTimeEntry1: undefined,
    dateTimeEntry2: undefined,
    assetIds: [],
    sensorSerialNumbers: [],
};
