import { useToast } from "app/hooks/Toast/useToast";
import { ModuleScreenPatents, SecScreenPatent } from "app/models/ModuleModels";
import { RoleService } from "app/services";
import { SecScreen } from "app/shared/Constants";
import { TranslationModals } from "app/translation/translationKeys";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WizardLicenseStepTwoModel } from "../../types";

type IuseStepTwo = WizardLicenseStepTwoModel & {
    next: () => void;
    onChange: (fields: Partial<WizardLicenseStepTwoModel>) => void;
};

export const useStepTwo = ({ next, permisions, onChange }: IuseStepTwo) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [normalized, setNormalized] = useState<ModuleScreenPatents[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (code: string, value: SecScreenPatent) => {
        const newModules = [...permisions];
        const selectedModule = newModules.find((m) => m.moduleCode === code);
        if (selectedModule) {
            const screen = selectedModule.screenPatents.find((s) => s.screenCode === value.screenCode);
            if (screen) screen.patents = value.patents;
            else selectedModule.screenPatents = [...selectedModule.screenPatents, value];
        } else newModules.push({ moduleCode: code, screenPatents: [value] });
        onChange({ permisions: newModules });
    };

    useEffect(() => {
        const fetchConfig = async () => {
            setIsLoading(true);
            const { status, data } = await RoleService.GetRoleConfig();

            if (!status()) {
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
                setIsLoading(false);
            }

            const normalizedModules = data.list.map((m) => {
                const tmp: ModuleScreenPatents = {
                    moduleCode: m.code,
                };

                const modules = m.screens.map((s) => ({
                    screenCode: s.code,
                    patents: s.patents.map((p) => p.code),
                }));

                tmp.screens = modules.filter((x) => x.screenCode !== SecScreen.LICENSES);
                return tmp;
            });
            setNormalized(normalizedModules);
            setIsLoading(false);
        };

        fetchConfig();
    }, []);

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        next();
    };

    return {
        normalized,
        isLoading,
        handleChange,
        validate,
    };
};
