import PrivateRoute from "app/routes/PrivateRoute";
import { Route, Routes } from "react-router-dom";
import TemporaryRoles from "../Role/TemporaryRoles";
import { ON_BOARDING_SUB_STEPS, SecScreen } from "app/shared/Constants";
import RoleEdit from "../Role/RoleEdit/RoleEdit";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";

export const TemporaryRolePage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_ROLES);

    return (
        <Routes>
            <Route
                path={""}
                element={<PrivateRoute component={TemporaryRoles} viewCode={SecScreen.TEMPORARY_ROLES} />}
            />
            <Route
                path="edit/:id"
                element={<PrivateRoute component={RoleEdit} viewCode={SecScreen.TEMPORARY_ROLES} />}
            />
        </Routes>
    );
};
