import { FC } from "react";
import { TaskPillBodyHeaderSkelleton } from "./TaskPillBodyHeaderSkelleton/TaskPillBodyHeaderSkelleton";
import { TaskPillBodySkelleton } from "./TaskPillBodySkelleton/TaskPillBodySkelleton";
import { TaskPillBodyRatingSkelleton } from "../TaskPillBodyRatingSkelleton/TaskPillBodyRatingSkelleton";

type Props = {
    hasImage: boolean;
};

export const TaskPillWithStarsSkelletonMobile: FC<Props> = ({ hasImage }) => {
    return (
        <div className="taskPillWithStar-skelleton">
            {hasImage && <div className="taskPillWithStar-skelleton__img"></div>}
            <div className="taskPillWithStar-skelleton__container">
                <div className="taskPillWithStar-skelleton__container__taskInfo">
                    <TaskPillBodyHeaderSkelleton />
                    <TaskPillBodySkelleton />
                </div>
                <TaskPillBodyRatingSkelleton />
            </div>
        </div>
    );
};
