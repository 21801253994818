import { FC } from "react";
import { RowPositionModel } from "app/components_v2/Table/types";
import { BaseTaskListModel } from "app/models/02-TAR/BaseTask/BaseTaskListModel";
import { MobileBaseTaskRowHeader } from "./MobileBaseTaskRowHeader/MobileBaseTaskRowHeader";
import { MobileBaseTaskRowBody } from "./MobileBaseTaskRowBody/MobileBaseTaskRowBody";

type MobileBaseTaskRowProps = {
    data: BaseTaskListModel;
    rowPosition: RowPositionModel;
    isAPPCC: boolean;
    onClick?: () => void;
};

export const MobileBaseTaskRow: FC<MobileBaseTaskRowProps> = ({ data, rowPosition, onClick, isAPPCC }) => {
    const { name, type } = data;
    return (
        <div className={`mobileBaseTaskRow--${rowPosition}`}>
            <MobileBaseTaskRowHeader name={name} type={type} onClick={onClick} />
            <MobileBaseTaskRowBody data={data} isAPPCC={isAPPCC} />
        </div>
    );
};
