import { ProgresssBarWithLabel } from "app/components_v2/ProgressBarWithLabel/ProgressBarWithLabel";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskCheckListTitleProps = {
    totalCheckList: number;
    completedCheckList: number;
};

export const TaskCheckListTitle: FC<TaskCheckListTitleProps> = ({ completedCheckList, totalCheckList }) => {
    const { t } = useTranslation();
    const percentage = (completedCheckList * 100) / totalCheckList;
    return (
        <div className="taskCheckListTitle">
            <div className="taskCheckListTitle__container">
                <ProgresssBarWithLabel
                    percentage={percentage}
                    leftLabel={t(TranslationKeys.TASK_DETAIL_CHECKLIST)}
                    barSize="sm"
                    barLabel={`${completedCheckList}/${totalCheckList}`}
                    barLabelSize="sm"
                />
            </div>
        </div>
    );
};
