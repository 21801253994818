import {
    EntitiesWithPendingTasksPerDay,
    ReducedEntity,
} from "app/dtos/01-SEG/EntitiesWithPendingTasksPerDay/EntitiesWithPendingTasksPerDayDto";
import { DateRangeMinMaxValues, EntityPendingTasksFormValues } from "../models/EntityPendingTasksFormValues";
import { v4 } from "uuid";
import { convertUTCtoLocaleDate } from "app/helpers";

export const mapEntityListToFormValuesList = (list: ReducedEntity[]): EntityPendingTasksFormValues[] =>
    list.map((c) => ({
        ...c,
        startDate: c.startDate.toString(),
        endDate: c.endDate.toString(),
        checked: false,
        dbId: c.id,
        id: v4(),
    }));

export const mapMinMaxDates = (list: EntityPendingTasksFormValues[]): DateRangeMinMaxValues[] =>
    list.map((l) => {
        const startDateLessThreeDays = new Date(l.startDate);
        const endDatePlusThreeDays = l.endDate ? new Date(l.endDate) : new Date();
        startDateLessThreeDays.setDate(startDateLessThreeDays.getDate() - 3);
        endDatePlusThreeDays.setDate(endDatePlusThreeDays.getDate() + 3);
        return {
            id: l.id,
            minDate: startDateLessThreeDays.toString(),
            maxDate: endDatePlusThreeDays.toString(),
        };
    });

export const mapCompaniesAndDepartmentsToEntity = (
    companies: EntityPendingTasksFormValues[],
    departments: EntityPendingTasksFormValues[],
    filterChecked?: boolean
): EntitiesWithPendingTasksPerDay => ({
    companies: mapEntityToBack(companies, filterChecked),
    departments: mapEntityToBack(departments, filterChecked),
});

const mapEntityToBack = (list: EntityPendingTasksFormValues[], filterChecked?: boolean): ReducedEntity[] => {
    if (filterChecked) list = list.filter(({ checked }) => checked);

    return list.map(({ dbId, name, logoURL, startDate, endDate }) => {
        return {
            id: dbId,
            name,
            logoURL,
            startDate: startDate,
            endDate: endDate || startDate,
        };
    });
};
