import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faClipboardCheck, faLock, faPen } from "@fortawesome/pro-regular-svg-icons";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { OnBoardingStepsTranslation } from "app/translation/translationKeys";

type IOnBoardingBadge = {
    title: string;
    icon: IconProp;
};

export const getOnBoardingStatusInfo = (status: OnBoardingStatus): IOnBoardingBadge => {
    const title: Record<OnBoardingStatus, string> = {
        BLOCKED: OnBoardingStepsTranslation.ONBOARDING_STATUS_BLOCKED,
        IN_PROGRESS: OnBoardingStepsTranslation.ONBOARDING_STATUS_IN_PROGRESS,
        REVIEW: OnBoardingStepsTranslation.ONBOARDING_STATUS_REVIEW,
        DONE: OnBoardingStepsTranslation.ONBOARDING_STATUS_DONE,
    };
    const icon: Record<OnBoardingStatus, IconProp> = {
        BLOCKED: faLock,
        IN_PROGRESS: faPen,
        REVIEW: faClipboardCheck,
        DONE: faCircleCheck,
    };

    return {
        title: title[status],
        icon: icon[status],
    };
};
