import { FC } from "react";
import { AlertPillStatus } from "../types";
import { Badge } from "app/components_v2/Badge/Badge";
import { useTranslation } from "react-i18next";
import { AriaLabels, TranslationKeys } from "app/translation/translationKeys";
import { faCheckDouble, faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";

type AlertPillBodyProps = {
    text?: string;
    status?: AlertPillStatus;
};

export const AlertPillBody: FC<AlertPillBodyProps> = ({ status, text }) => {
    const { t } = useTranslation();

    return (
        <div className="alertPillBody">
            {text && (
                <p className={`alertPillBody__message${status ? "--maxWidth" : ""} text_clamp text_clamp-2`}>{text}</p>
            )}

            {(status === "readed" || status === "sended") && (
                <Badge
                    title={status === "readed" ? t(TranslationKeys.READED) : t(TranslationKeys.SENT)}
                    variant={status === "readed" ? "green" : "blue"}
                    icon={status === "readed" ? faCheckDouble : faPaperPlaneTop}
                    iconPosition="left"
                    size="lg"
                    ariaLabel={
                        status === "readed"
                            ? t(AriaLabels.AL_ALERT_PILL_READED_BADGE)
                            : t(AriaLabels.AL_ALERT_PILL_SENDED_BADGE)
                    }
                />
            )}
        </div>
    );
};
