export const webPConverter = (file: File, callback: (convertedImg: string) => void) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let currentImg: string = "";
    let webpImg: HTMLImageElement | null = null;

    if (currentImg !== "" || webpImg !== null) {
        URL.revokeObjectURL(currentImg);
        currentImg = "";
        webpImg = null;
    }

    currentImg = URL.createObjectURL(file);

    webpImg = new Image();

    webpImg.onload = () => {
        canvas.width = webpImg!.naturalWidth;
        canvas.height = webpImg!.naturalHeight;
        ctx?.drawImage(webpImg!, 0, 0, canvas.width, canvas.height);
        const convertedImg: string = canvas.toDataURL("image/webp", 0.1);
        callback(convertedImg);
    };

    webpImg.src = currentImg;
};

export const webPConverterPromise = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let currentImg: string = "";
        let webpImg: HTMLImageElement | null = null;

        if (currentImg !== "" || webpImg !== null) {
            URL.revokeObjectURL(currentImg);
            currentImg = "";
            webpImg = null;
        }

        currentImg = URL.createObjectURL(file);

        webpImg = new Image();

        webpImg.onload = () => {
            canvas.width = webpImg!.naturalWidth;
            canvas.height = webpImg!.naturalHeight;
            ctx?.drawImage(webpImg!, 0, 0, canvas.width, canvas.height);
            const convertedImg: string = canvas.toDataURL("image/webp", 0.1);
            resolve(convertedImg);
        };

        webpImg.onerror = (error) => {
            reject(error);
        };

        webpImg.src = currentImg;
    });
};

export const convertFileToBase64 = (attachment: File | Blob): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(attachment);
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = (error) => {
                console.error("Error converting file to base64:", error);
                reject(null);
            };
        } catch (error) {
            reject(error);
        }
    });
};
