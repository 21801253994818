import { FC } from "react";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { OnCheckChangeValues, OnSelectValues, ReasignValues, ReasignValuesId } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ReasignSelect } from "../ReasignSelect/ReasignSelect";
import { Label } from "app/components_v2/__inputs/Label";

export type ReasginToProps = {
    values: ReasignValues[];
    onCheckChange: (values: OnCheckChangeValues) => void;
    onSelectChange: (values: OnSelectValues) => void;
    errorMessage?: string;
    onChangeErrorMessage?: () => void;
    departmentOptions: OptionModel[];
    userOptions: OptionModel[];
    subdepartmentsOptions: OptionModel[];
};

export const ReasignTo: FC<ReasginToProps> = ({
    values,
    onCheckChange,
    onSelectChange,
    errorMessage,
    onChangeErrorMessage,
    departmentOptions,
    subdepartmentsOptions,
    userOptions,
}) => {
    const { t } = useTranslation();

    const options: Record<ReasignValuesId, OptionModel[]> = {
        DEPT: departmentOptions,
        USER: userOptions,
        SUBDEPT: subdepartmentsOptions,
    };

    return (
        <div className="reasignTo">
            <Label label={t(TranslationKeys.REASIGN_TASK_TO)} />
            <div className="reasignTo__container">
                {values.map((value) => {
                    const { checked, placeholder, selectedValue, id, required, hidden } = value;
                    return (
                        !hidden && (
                            <ReasignSelect
                                key={id}
                                checked={checked}
                                placeholder={placeholder}
                                selectedValue={selectedValue}
                                onCheckChange={(checkValue) => {
                                    onCheckChange({ id, checked: checkValue });
                                    onChangeErrorMessage && onChangeErrorMessage();
                                }}
                                onSelectChange={(value) => {
                                    onSelectChange({ id, value });
                                    onChangeErrorMessage && onChangeErrorMessage();
                                }}
                                errorMessage={errorMessage}
                                required={required}
                                options={options[id]}
                            />
                        )
                    );
                })}
            </div>
        </div>
    );
};
