import { FC, useEffect, useState } from "react";
import { SporadicTaskModalOptions } from "./types";
import { sporadicTaskOptions } from "./constants/SporadicTaskDetailModalConstants";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { DashboardService } from "app/services";
import { useTranslation } from "react-i18next";
import { useSession, useTitle } from "app/hooks";
import { TranslationTitles } from "app/translation/translationKeys";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivatePaths, SecScreen, TaskStatus } from "app/shared/Constants";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TaskDetail } from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskDetail";

type SporadicTaskDetailModalProps = {
    id: number;
    options?: SporadicTaskModalOptions;
    onClose?: () => void;
    onComplete?: () => void;
    onStarsChange?: () => void;
};

export const SporadicTaskDetailModal: FC<SporadicTaskDetailModalProps> = ({
    id,
    options = sporadicTaskOptions,
    onClose,
    onComplete,
    onStarsChange,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const session = useSession();
    const nav = useNavigate();

    const [sporadicTask, setSporadicTask] = useState<TaskInstanceModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useTitle(t(TranslationTitles.TASK_DETAIL_TITLE));

    const getData = async () => {
        setIsLoading(true);
        const { data, status } = await DashboardService.InsertSporadicTask(id);

        if (!status()) {
            setSporadicTask(null);
            setIsLoading(false);
            onClose && onClose();
            return;
        }

        setSporadicTask(data);
        setIsLoading(false);
    };

    const isTaskInstanceEditable = ({
        editedBy,
        asignedToUserId,
        asignedToDepartmentId,
        asignedToSubdepartmentId,
        companyId,
        statusCode,
    }: TaskInstanceModel) => {
        const myId = session?.user.id;
        const myCompanies = session?.user.companies?.map(({ companyId }) => companyId);
        const myDepartments = session?.user.companies?.flatMap(({ departments }) =>
            departments?.map(({ departmentId }) => departmentId)
        );
        const mySubdepartments = session?.user.companies
            ?.flatMap(({ departments }) =>
                departments?.flatMap(({ subdepartments }) =>
                    subdepartments?.flatMap(({ subdepartmentId }) => subdepartmentId)
                )
            )
            .filter((departmentId) => departmentId != null);

        const isTaskComplete = statusCode === TaskStatus.COMPLETED;
        const isEditedByOtherUser = editedBy != null && editedBy !== myId;
        const isAsignedByOtherUser = asignedToUserId != null && asignedToUserId !== myId;
        const isNotAsignedToMyCompanies = !myCompanies?.includes(companyId);
        const isNotAsignedToMyDepartments =
            asignedToDepartmentId != null && !myDepartments?.includes(asignedToDepartmentId);
        const isNotAsignedToMySubdepartments =
            asignedToSubdepartmentId != null && !mySubdepartments?.includes(asignedToSubdepartmentId);

        if (isTaskComplete) return false;
        if (isEditedByOtherUser || isAsignedByOtherUser) return false;
        if (isNotAsignedToMyCompanies || isNotAsignedToMyDepartments || isNotAsignedToMySubdepartments) return false;

        return true;
    };

    const handleClose = () => onClose && onClose();

    useEffect(() => {
        getData();
    }, []);

    return (
        <GenericModal
            header={{
                title: t(TranslationTitles.TASK_DETAIL_TITLE),
                onClose: handleClose,
                actionButton: hasPermissionToEdit(SecScreen.TASK)
                    ? {
                          icon: faPen,
                          onClick() {
                              nav(`/${PrivatePaths.TASK_PAGE}/edit/${sporadicTask?.taskId}`);
                          },
                      }
                    : undefined,
            }}
            size="xl"
            hideChildrenPadding
            allowCloseOnEscape={false}
        >
            {isLoading ? (
                <div className="sporadicTaskDetailModal__spinner">
                    <Spinner />
                </div>
            ) : sporadicTask ? (
                <TaskDetail
                    taskInstance={sporadicTask}
                    setTaskInstance={setSporadicTask}
                    onCancel={handleClose}
                    isFeedback={location.pathname.includes(PrivatePaths.FEEDBACK_TASKS)}
                    onComplete={onComplete}
                    editable={options.isEditable && sporadicTask && isTaskInstanceEditable(sporadicTask)}
                    isReviewPhotos={!!options.feedback?.isVisible}
                    onStarsChange={onStarsChange}
                    editStarRating={!!options.feedback?.editable}
                    showValoratedBy={!!options.feedback?.showValoratedBy}
                />
            ) : null}
        </GenericModal>
    );
};
