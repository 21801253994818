import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { useSession } from "app/hooks";
import { UserService } from "app/services";
import { FC, useEffect, useState } from "react";
import { SubdepartmentsCheckbox } from "../SubdepartmentsCheckbox/SubdepartmentsCheckbox";
import { DepartmentWithSubsModel, SubdepartmentUsersModel } from "app/dtos/01-SEG/DepartmentDto";

type DepartmentsCheckboxProps = {
    department: DepartmentWithSubsModel;
    isDepartmentChecked: boolean;
    subdepartmentsChecked: number[];
    onCheckDepartment: (department: DepartmentWithSubsModel, isChecked: boolean) => void;
    onCheckSubdepartment: (
        subdepartments: SubdepartmentUsersModel,
        department: DepartmentWithSubsModel,
        isChecked: boolean
    ) => void;
};

export const DepartmentsCheckbox: FC<DepartmentsCheckboxProps> = ({
    department,
    isDepartmentChecked,
    onCheckDepartment,
    onCheckSubdepartment,
    subdepartmentsChecked,
}) => {
    const session = useSession();
    const [usersInDept, setUsersInDept] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getUserByDeptId = async () => {
        setIsLoading(true);
        const { data, status } = await UserService.GetData({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&departmentId=${department.id}`,
        });
        if (!status()) {
            setIsLoading(false);
            return;
        }
        setUsersInDept(data.list.map(({ id }) => id));
        setIsLoading(false);
    };

    useEffect(() => {
        getUserByDeptId();
    }, []);

    return (
        <div>
            {!isLoading && (
                <>
                    <CheckBox
                        label={`${department.name} (${department.usersCount} usuarios)`}
                        onChange={(isChecked) => onCheckDepartment({ ...department, users: usersInDept }, isChecked)}
                        checked={isDepartmentChecked}
                        htmlFor={`department${department.id}`}
                    />
                    <div className="companyWizardStep3__checkboxes">
                        {department.subs
                            .filter(({ usersCount }) => usersCount > 0)
                            .map((subdept) => {
                                const { id: subId } = subdept;
                                return (
                                    <SubdepartmentsCheckbox
                                        key={subId}
                                        subdept={subdept}
                                        onCheckSubdepartment={(subdepartment, isChecked) =>
                                            onCheckSubdepartment(
                                                subdepartment,
                                                { ...department, users: usersInDept },
                                                isChecked
                                            )
                                        }
                                        isSubdepartmentChecked={
                                            !!subdepartmentsChecked.find((subdepatmentId) => subdepatmentId === subId)
                                        }
                                    />
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
};
