import { FC, useState } from "react";
import { ProviderProps } from "../types";
import {
    PatchNotesContextOptions,
    PatchNotesModalContext,
} from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";

export const PatchNoteModalProvider: FC<ProviderProps> = ({ children }) => {
    const [patchNoteVersionId, setPatchNoteVersionId] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<PatchNotesContextOptions>({ readonly: false });

    const openModal = (id: number, options?: PatchNotesContextOptions) => {
        options && setOptions(options);
        setPatchNoteVersionId(id);
        setIsOpen(true);
    };

    const closeModal = () => {
        setOptions({});
        setPatchNoteVersionId(0);
        setIsOpen(false);
    };

    return (
        <PatchNotesModalContext.Provider value={{ patchNoteVersionId, closeModal, isOpen, openModal, options }}>
            {children}
        </PatchNotesModalContext.Provider>
    );
};
