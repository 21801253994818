import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import DividerWithText from "app/components_v2/DividerWithText/DividerWithText";
import PercentageStars from "app/components_v2/PercentageStars/PercentageStars";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PercentageStarsDetailProps = {
    avg: number | null;
    totalTasks: number | null;
    trend: number | null;
    starsNumbers: number[];
    isEmpty?: boolean;
};

export const PercentageStarsDetail: FC<PercentageStarsDetailProps> = ({
    avg,
    totalTasks,
    trend,
    starsNumbers,
    isEmpty,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <DividerWithText text={t(TranslationKeys.TASKS_VALORATION)} />
            {isEmpty ? (
                <TableError
                    icon={faListCheck}
                    title={t(TranslationKeys.NO_VALORATION)}
                    description={t(TranslationKeys.SUPERVISOR_VALORATION_SECTION_YOUR_TASKS_DATE)}
                />
            ) : (
                <PercentageStars
                    title={t(TranslationKeys.TASKS_VALORATION)}
                    trend={trend || 0}
                    valoration={avg || 0}
                    total={totalTasks || 0}
                    stars={starsNumbers}
                />
            )}
        </>
    );
};
