import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { TaskInstanceModel } from "../../models/02-TAR/TaskInstance/TaskInstanceModel";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";

export default class TaskInstanceFeedbackService {
    // PUT New TaskInstance Feedback
    public static async SetFeedback(values: TaskInstanceFeedbackMessageModel) {
        return FetchService.put<TaskInstanceModel>({
            url: `${ApiPaths.TAR_API}/taskInstanceFeedback/setFeedback`,
            body: values,
        });
    }
}
