import { useToast } from "../Toast/useToast";

export const useErrorManager = () => {
    const { handleToast } = useToast();

    const handleErrorManager = (status: boolean, errorTitle: string, errorSubtitle?: string): boolean => {
        if (!status) {
            handleToast({
                title: errorTitle,
                subtitle: errorSubtitle ?? undefined,
                variant: "danger",
                type: "alert",
            });
            return true;
        }
        return false;
    };
    return { handleErrorManager };
};
