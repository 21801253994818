import { RankingPaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { EntityRankingModel } from "app/models/03-REP/Ranking/EntityRankingModel";

export default class RankingService {
    public static async GetCompanyRanking(params: PaginationParams) {
        return FetchService.get<RankingPaginatedList<EntityRankingModel>>({
            url: `${ApiPaths.REP_API}/Ranking/CompanyRanking`,
            paginationParams: params,
        });
    }
    public static async GetUserRanking(id: string | number, params: PaginationParams) {
        return FetchService.get<RankingPaginatedList<EntityRankingModel>>({
            url: `${ApiPaths.REP_API}/Ranking/UserRanking/${id}`,
            paginationParams: params,
        });
    }
    public static async GetAllUserRanking(params: PaginationParams) {
        return FetchService.get<RankingPaginatedList<EntityRankingModel>>({
            url: `${ApiPaths.REP_API}/Ranking/UserRanking`,
            paginationParams: params,
        });
    }
    public static async GetDepartmentRanking(id: string | number, params: PaginationParams) {
        return FetchService.get<RankingPaginatedList<EntityRankingModel>>({
            url: `${ApiPaths.REP_API}/Ranking/DepartmentRanking/${id}`,
            paginationParams: params,
        });
    }
    public static async GetAllDepartmentRanking(params: PaginationParams) {
        return FetchService.get<RankingPaginatedList<EntityRankingModel>>({
            url: `${ApiPaths.REP_API}/Ranking/DepartmentRanking/`,
            paginationParams: params,
        });
    }
}
