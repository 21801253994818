import { validPhotoExtensions, validVideoExtensions } from "app/shared/Constants";

export type FileTypeModel = "image" | "video" | "file";

const isFileType = (url: string, type: FileTypeModel) => {
    const fileExtension = url.split(".").pop()?.toLowerCase();
    if (!fileExtension) return false;

    if (type === "image") return validPhotoExtensions.includes(fileExtension);
    else if (type === "video") return validVideoExtensions.includes(fileExtension);

    return false;
};

export const checkFileTypeConsistency = (urls: string[], type: FileTypeModel) =>
    urls.every((url) => isFileType(url, type));
