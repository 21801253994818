import { UploadPhoto } from "app/components_v2/UploadPhoto/UploadPhoto";
import { FC } from "react";
import { ImageBase64String } from "app/dtos/ImageBase64String";
import { Label } from "app/components_v2/__inputs/Label";

type CompanyLogoProps = {
    onInputChange: (image: ImageBase64String) => void;
    image?: string;
    disabled?: boolean;
};
export const CompanyLogo: FC<CompanyLogoProps> = ({ onInputChange, image, disabled }) => {
    return (
        <div className="comapanyLogo">
            <Label label="Logotipo" />
            <div className="comapanyLogo__logo">
                <UploadPhoto
                    onUpload={(image) => onInputChange(image)}
                    photoUrl={image}
                    squared
                    type="logo"
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
