import { RankingSortBy } from "app/components_v2/Layout/RankingLayout/RankingHeader/RankingHeader";

type IReturn = {
    sortDirection: string;
    sortField: string;
};

export const getRankingExtraParam = (value: RankingSortBy): IReturn => {
    if (value === "NAME_ASC") return { sortField: "EntityHeaderInfo.Name", sortDirection: "asc" };
    if (value === "NAME_DESC") return { sortField: "EntityHeaderInfo.Name", sortDirection: "desc" };
    if (value === "STAR_ASC") return { sortField: "EntityHeaderInfo.Avg", sortDirection: "desc" };
    if (value === "STAR_DESC") return { sortField: "EntityHeaderInfo.Avg", sortDirection: "asc" };
    if (value === "COMPLETED_OUT_OF_TIME")
        return { sortField: "EntityHeaderInfo.OutOfTimeCount", sortDirection: "asc" };
    return { sortField: "EntityHeaderInfo.TimedOutCount", sortDirection: "asc" };
};
