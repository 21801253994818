import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuditInstanceTranslations } from "app/translation/translationKeys";
import { BarDataset } from "app/components_v2/BarChart/types";
import { BarChart } from "app/components_v2/BarChart/BarChart";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faChartBar } from "@fortawesome/pro-regular-svg-icons";

type AuditReportBarChartProps = {
    labels: string[];
    auditBarChartData: BarDataset[];
};

export const AuditReportBarChart: FC<AuditReportBarChartProps> = ({ labels, auditBarChartData }) => {
    const { t } = useTranslation();

    return (
        <div className="auditReportBarChart">
            <h2 className="auditReportBarChart__title">
                {t(AuditInstanceTranslations.AUDIT_INSTANCE_REPORT_BARCHART_TITLE)}
            </h2>
            {!!auditBarChartData.some((abcd) => abcd.data.length) ? (
                <BarChart labels={labels} datasets={auditBarChartData} showLegend={false} xMax={8} yMax={100} />
            ) : (
                <TableError
                    title={t(AuditInstanceTranslations.EMPTY_BAR_CHART_TITLE)}
                    description={t(AuditInstanceTranslations.EMPTY_BAR_CHART_DESCRIPTION)}
                    icon={faChartBar}
                />
            )}
        </div>
    );
};
