import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ButtonIconProps = {
    icon: IconProp;
    title?: string;
};

export const ButtonIcon: FC<ButtonIconProps> = ({ icon, title }) => {
    return (
        <div className="buttonIcon">
            <FontAwesomeIcon icon={icon} className="buttonIcon__svg" title={title} />
        </div>
    );
};
