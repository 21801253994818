import { FC } from "react";
import { Badge } from "app/components_v2/Badge/Badge";
import { useSensorBattery } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorBattery";
import { useSensorSignal } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorSignal";

type AssetMobileSensorBadgesProps = {
    sensorSerialNumber: string;
    battery?: number;
    signalLevel?: number;
};

export const AssetMobileSensorBadges: FC<AssetMobileSensorBadgesProps> = ({
    sensorSerialNumber,
    battery,
    signalLevel,
}) => {
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    return (
        <div className="assetMobileSensorBadges">
            <Badge size="sm" title={sensorSerialNumber} variant={battery ? batteryBadgeColor(battery) : "grey"} />
            <Badge
                size="sm"
                title={battery ? batteryBadgeTitle(battery, true) : "-"}
                variant={battery ? batteryBadgeColor(battery) : "grey"}
                icon={battery ? batteryBadgeIcon(battery) : undefined}
                iconPosition="right"
            />
            <Badge
                size="sm"
                title={signalLevel ? signalBadgeTitle(signalLevel, true) : "-"}
                variant={signalLevel ? signalBadgeColor(signalLevel) : "grey"}
                icon={signalLevel ? signalBadgeIcon(signalLevel) : undefined}
                iconPosition="right"
            />
        </div>
    );
};
