import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { createContext } from "react";
import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";
import { SelectedColumnViewModel } from "../../models/taskPageModels";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export type TaskGridContextModel = {
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    currentTab: number;
    onChangeCurrentTab: (tab: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    selectCompany: SelectCompanyModel;
    onCompanyChange: (value: Partial<SelectCompanyModel>) => void;
    filterIcon: IconDefinition;
    secondaryFilterValues: TaskGridSecondaryFilterModel;
    onSFChange: (sfValues: Partial<TaskGridSecondaryFilterModel>) => void;
    initialRender: boolean;
    onInitialRenderChange: (value: boolean) => void;
    selectedColumnView: SelectedColumnViewModel;
    onSelectFilterButton: (value: SelectedColumnViewModel) => void;
    query: string;
    onQueryChange: (query: string) => void;
};

export const TaskGridContext = createContext<TaskGridContextModel>({} as TaskGridContextModel);
