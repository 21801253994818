import { FC } from "react";
import { Navigate, Route, Routes } from "react-router";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "../../../shared/Constants";
import PrivateRoute from "../../../routes/PrivateRoute";
import { TaskGrid } from "./TaskGrid/TaskGrid";
import { EditTask } from "./EditTask";
import { TaskGridProvider } from "./state/provider/TaskGridProvider";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";

const TaskPage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_TASK);
    return (
        <TaskGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<TaskGrid />}></Route>

                <Route
                    path="edit/:id"
                    element={<PrivateRoute component={EditTask} viewCode={SecScreen.TASK} />}
                ></Route>
                <Route
                    path="duplicate/:id"
                    element={<PrivateRoute component={EditTask} viewCode={SecScreen.TASK} />}
                ></Route>
                <Route path="fastNew" element={<PrivateRoute component={EditTask} viewCode={SecScreen.TASK} />}></Route>
            </Routes>
        </TaskGridProvider>
    );
};

export default TaskPage;
