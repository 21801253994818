import { useState, FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarSharp } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IconTitleTranslations } from "app/translation/translationKeys";

export type StarValorationProps = {
    editable: boolean;
    rating: number;
    setRating: (rating: number) => void;
    starColor?: "primary" | "yellow";
};

export const StarValoration: FC<StarValorationProps> = ({ editable, rating, setRating, starColor }) => {
    const { t } = useTranslation();

    const [hover, setHover] = useState<number>(0);

    const handleSelection = ({ e, index }: { e: React.MouseEvent<SVGSVGElement, MouseEvent>; index: number }) => {
        e.stopPropagation();
        editable && setRating(index);
    };

    useEffect(() => {
        setHover(rating);
    }, [rating]);

    return (
        <div className="starValorationContainer">
            {[...Array(5)].map((_, index) => {
                index += 1;
                return (
                    <FontAwesomeIcon
                        key={index}
                        onMouseEnter={() => editable && setHover(index)}
                        onMouseLeave={() => editable && setHover(rating)}
                        onClick={(e) => handleSelection({ e, index })}
                        className={`starValorationIcon${editable ? "--pointer" : ""} ${
                            index <= (hover || rating) ? `starValorationIcon--${starColor}` : ""
                        }`}
                        icon={faStarSharp}
                        aria-label={`Star ${index}`}
                        title={t(IconTitleTranslations.IT_STAR).replace("{0}", String(index))}
                    />
                );
            })}
        </div>
    );
};
