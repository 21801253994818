import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { convertUTCtoLocaleDate } from "../Date.utilities";

export const isDisabledRange = (taskInstance: TaskInstanceModel) => {
    return taskInstance.taskType !== "SPORADIC"
        ? taskInstance.startDate &&
              convertUTCtoLocaleDate(taskInstance.startDate) > new Date() &&
              taskInstance.taskTemporalityType !== "RANGE_DATES"
        : false;
};
