import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowPositionModel } from "app/components_v2/Table/types";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { useSession } from "app/hooks";
import { WorkingPositionModel } from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import { PlanAPPCCCounters } from "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/PlanAPPCCCounters";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { WorkingPositionTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkingPositionGridMobileBodyProps = {
    workingPosition: WorkingPositionModel;
    rowPosition: RowPositionModel;
};

export const WorkingPositionGridMobileBody: FC<WorkingPositionGridMobileBodyProps> = ({
    workingPosition,
    rowPosition,
}) => {
    const nav = useNavigate();
    const session = useSession();
    const { t } = useTranslation();

    const { name, customerInstanceWorkingPosition, id, description, associatedTasksAppccCount, associatedTasksCount } =
        workingPosition;

    return (
        <div className={`workingPositionGridMobileBody--${rowPosition}`}>
            <div className="workingPositionGridMobileBody__container">
                <div className="workingPositionGridMobileBody__body">
                    <div className="workingPositionGridMobileBody__text">
                        <div className="workingPositionGridMobileBody__text__headers">
                            <p className="workingPositionGridMobileBody__title">{name}</p>
                            <p className="workingPositionGridMobileBody__code">
                                {customerInstanceWorkingPosition?.qrCode}
                            </p>
                        </div>
                        <p className="workingPositionGridMobileBody__description">{description || "-"}</p>
                    </div>
                    <div className="workingPositionGridMobileBody__actions" onClick={() => nav(`edit/${id}`)}>
                        <FontAwesomeIcon className="workingPositionGridMobileBody__actions__edit" icon={faPen} />
                    </div>
                </div>
                <div className="workingPositionGridMobileBody__footer">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.TASK)}
                        title={`${t(WorkingPositionTranslations.WORKING_POSITION_TASK_COUNT)} ${formatBigNumber(
                            associatedTasksCount,
                            2
                        )}`}
                        to={`/${PrivatePaths.TASK_PAGE}?workingPositionId=${id}`}
                    />
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.BASE_TASK_MANAGEMENT)}
                        title={`${t(t(WorkingPositionTranslations.WORKING_POSITION_APPCC_COUNT))} ${formatBigNumber(
                            associatedTasksAppccCount,
                            2
                        )}`}
                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}?workingPositionId=${id}`}
                    />
                </div>
            </div>
        </div>
    );
};
