import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type LinkDivProps = {
    to?: string;
    className?: string;
    onClick?: () => void;
    children: ReactNode;
};

export const LinkDiv: FC<LinkDivProps> = ({ className, onClick, to, children }) => {
    return !!to?.length ? (
        <Link className={className} onClick={onClick} to={`/${to}`}>
            {children}
        </Link>
    ) : (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    );
};
