import { ReactNode, forwardRef } from "react";
import { createPortal } from "react-dom";
import { GenericModalHeader } from "./GenericModalHeader/GenericModalHeader";
import useEscapeToClose from "app/hooks/useEscapeToClose";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

export type HeaderModalProps = {
    title: string;
    variant?: TabHeaderVariants;
    onClose?: () => void;
    headerImage?: string;
    headerChildren?: ReactNode;
    actionButton?: actionButton;
    radius?: boolean;
};

type actionButton = {
    icon: IconProp;
    onClick: () => void;
};
type GenericModalProps = {
    header?: HeaderModalProps;
    children: ReactNode;
    footer?: ReactNode;
    size?: "sm" | "md" | "lg" | "xl" | "md-tall";
    portal?: boolean;
    hideChildrenPadding?: boolean;
    hideBlur?: boolean;
    allowCloseOnEscape?: boolean;
};

export const GenericModal = forwardRef<HTMLInputElement, GenericModalProps>(
    (
        {
            header,
            children,
            footer,
            size = "xl",
            portal = true,
            hideChildrenPadding = false,
            hideBlur,
            allowCloseOnEscape = true,
        },
        ref
    ) => {
        const handleEscapeClose = () => {
            if (allowCloseOnEscape && !!header?.onClose) {
                header.onClose();
            }
        };

        useEscapeToClose(handleEscapeClose);
        const ModalContent = (
            <div
                className={`genericModal ${hideBlur ? "genericModal--noBlur" : ""} genericModal--${size}`}
                role="dialog"
                ref={ref}
            >
                <div className={`genericModal__container genericModal__container--${size}`}>
                    {header && <GenericModalHeader {...header} radius={size === "sm" || size === "md"} />}
                    <div className="genericModal__body">
                        <article
                            className={`genericModal__body__children ${
                                hideChildrenPadding ? "genericModal__body__children--noPadding" : ""
                            } ${size === "sm" ? "genericModal__body__children--sm" : ""}`}
                        >
                            {children}
                        </article>
                        {footer}
                    </div>
                </div>
            </div>
        );
        return <>{portal ? createPortal(ModalContent, document.body) : ModalContent}</>;
    }
);
