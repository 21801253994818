import { FC } from "react";
import { LineChartCustom } from "app/components_v2/LineChart/LineChartCustom/LineChartCustom";
import { Section, SectionsShowProp } from "../Section/Section";

export const LineChartSection: FC<SectionsShowProp> = ({ show }) => {
    const dataSets = [
        { label: "Dataset 1", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
        { label: "Dataset 2", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
        { label: "Dataset 3", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
        { label: "Dataset 4", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
        { label: "Dataset 5", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
        { label: "Dataset 6", data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 50)) },
    ];
    return (
        <Section show={show} title="LineChart">
            <LineChartCustom dataSets={dataSets} width={800} height={400} pointsToShow={10} />
        </Section>
    );
};
