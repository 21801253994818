import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { EditCompanyFormModal } from "app/pages/01-SEG/Companies/Components/Company/types";

type IEditCompanyFormToCompanyModel = {
    formValues: EditCompanyFormModal;
    customerInstanceId: number;
    id: number;
    isActive: boolean;
    logoId?: number;
};

export const editCompanyFormToCompanyModel = ({
    customerInstanceId,
    formValues,
    id,
    isActive,
    logoId,
}: IEditCompanyFormToCompanyModel): CompanyModel => {
    const {
        address,
        city,
        name,
        postalCode,
        companyGroupId,
        image,
        imageBase64,
        closeCompany,
        closeCompanies,
        worksWithQR,
    } = formValues;
    return {
        address,
        city,
        name,
        zip: postalCode,
        groupCompanyId: companyGroupId || 0,
        imageBase64Content: imageBase64,
        rolesId: [],
        customerInstanceId,
        id,
        isActive,
        logoFileURL: !imageBase64 ? image : "",
        logoFileId: logoId,
        closeCompanies: !closeCompany ? null : mapCloseCompanyValues(closeCompanies, id),
        worksWithQR,
    };
};

const mapCloseCompanyValues = (
    closeCompanies: CloseCompanyModel[] | null,
    companyId: number
): CloseCompanyModel[] | null => {
    if (closeCompanies == null) return null;
    return closeCompanies.map(({ id: ccId, closeDate, openDate }) => ({
        id: ccId,
        companyId,
        closeDate,
        openDate,
    }));
};
