import { FC } from "react";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { RoleCollapseChecboxes } from "app/components_v2/RoleCollapseChecboxes/RoleCollapseChecboxes";
import { RoleWizardSteps } from "./types";
import { TranslationKeys } from "app/translation/translationKeys";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";

type Step3Props = RoleWizardSteps & {
    next: () => void;
    normalizedRoles: ModuleScreenPatents[];
    allModules: SecModule[];
};

const Step3: FC<Step3Props> = ({ onChangeData, data, next, allModules, normalizedRoles }) => {
    const { permissions } = data;
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const handleChange = (code: string, value: SecScreenPatent) => {
        const newModules = [...allModules];
        const selectedModule = newModules.find((m) => m.moduleCode === code);
        if (selectedModule) {
            const screen = selectedModule.screenPatents.find((s) => s.screenCode === value.screenCode);
            if (screen) screen.patents = value.patents;
            else selectedModule.screenPatents = [...selectedModule.screenPatents, value];
        } else newModules.push({ moduleCode: code, screenPatents: [value] });
        onChangeData({
            permissions: newModules,
        });
    };

    return (
        <WizardForm
            validate={(e) => {
                e.preventDefault();
                !!permissions.length
                    ? next()
                    : handleToast({
                          title: t(TranslationKeys.WIZARD_ROLE_ADD_PATENT),
                          type: "alert",
                          variant: "danger",
                      });
            }}
        >
            {normalizedRoles.map((modulePaterns) => (
                <RoleCollapseChecboxes
                    modulePaterns={modulePaterns}
                    key={modulePaterns.moduleCode}
                    modules={permissions}
                    onChange={handleChange}
                    isOpen={!!permissions.length}
                />
            ))}
        </WizardForm>
    );
};

export default Step3;
