import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Actions } from "../../state/actions";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { ReduxSession } from "app/models/SessionModel";
import { Button } from "../__buttons/Button/Button";

type Props = {
    session: ReduxSession;
};

const ImpersonateHeader: FC<Props> = ({ session }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <>
            <div></div>
            <div style={{ padding: 0, height: "unset" }}>
                <p>
                    {`${t(TranslationKeys.IMPERSONATING_TO)} ${t(TranslationKeys.USER)} ${session.user.firstName} ${
                        session.user.lastName
                    }`}
                </p>
                <Button text={t(TranslationCommon.EXIT)} onClick={() => dispatch(Actions.outImpersonateMode())} />
            </div>
        </>
    );
};

export default ImpersonateHeader;
