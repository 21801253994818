import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { EditCompanyFormModal } from "app/pages/01-SEG/Companies/Components/Company/types";

export const CompanyModelToEditCompanyForm = (
    { address, city, groupCompanyId, name, zip, closeCompanies, worksWithQR }: CompanyModel,
    image?: string,
    closeCompany?: boolean
): EditCompanyFormModal => {
    return {
        name,
        address,
        city,
        postalCode: zip,
        companyGroupId: groupCompanyId,
        image: image || "",
        closeCompany,
        closeCompanies: !!closeCompanies?.length
            ? closeCompanies
            : [{ id: 0, companyId: 0, closeDate: new Date(), openDate: new Date() }],
        worksWithQR,
    };
};
