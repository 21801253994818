import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageBase64String } from "app/dtos/ImageBase64String";
import { convertFileToBase64 } from "app/helpers";
import { compressImage } from "app/helpers/images/compressImage";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type UploadPhotoProps = {
    squared?: boolean;
    photoUrl?: string;
    onUpload: (imageBase64: ImageBase64String) => void;
    type?: "logo" | "profilePicture";
    disabled?: boolean;
};

export const UploadPhoto: FC<UploadPhotoProps> = ({
    squared,
    onUpload,
    photoUrl = "",
    type = "profilePicture",
    disabled,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [photoToUpload, setPhotoToUpload] = useState<string>(photoUrl);
    const [showUploadPhoto, setShowUploadPhoto] = useState(false);

    const inputFile = useRef<HTMLInputElement>(null);

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        const { files } = e.target;
        if (!files?.length) return;
        const file = files[0];

        try {
            const compressedFiles = await compressImage({ file, quality: 0.6 });
            const base64DataUrl = await convertFileToBase64(compressedFiles);
            if (base64DataUrl == null) {
                handleToast({
                    title: t(TranslationModals.TOAST_ERROR_LOADING_PHOTO),
                    type: "alert",
                    variant: "danger",
                });
                return;
            }
            setPhotoToUpload(base64DataUrl);
            const imageBase64: ImageBase64String = {
                imageBase64Content: base64DataUrl,
            };
            onUpload(imageBase64);
        } catch (e) {
            handleToast({
                title: t(TranslationModals.TOAST_ERROR_LOADING_PHOTO),
                type: "alert",
                variant: "danger",
            });
        }
    };

    useEffect(() => {
        setPhotoToUpload(photoUrl);
    }, [photoUrl]);

    return (
        <div
            className={`uploadPhoto${type === "logo" ? "--logo" : ""}`}
            onClick={() => !disabled && inputFile.current?.click()}
        >
            <input hidden accept="image/*" ref={inputFile} onChange={handleFileUpload} type="file" />
            {!!photoToUpload.length ? (
                <div className={`uploadPhoto__existed${squared ? "--squared" : ""} ${type === "logo" ? "logo" : ""}`}>
                    <img
                        className={`uploadPhoto__existed${squared ? "--squared" : ""}__img ${
                            type === "logo" ? "logo" : ""
                        }`}
                        alt="uploaded"
                        src={photoToUpload}
                    />
                    {!disabled && (
                        <div
                            className={`uploadPhoto__existed${squared ? "--squared" : ""}${
                                showUploadPhoto ? "--translucent" : "--transparent"
                            }`}
                            onMouseEnter={() => setShowUploadPhoto(true)}
                            onMouseLeave={() => setShowUploadPhoto(false)}
                        >
                            <FontAwesomeIcon
                                className={`uploadPhoto__existed${squared ? "--squared" : ""}${
                                    showUploadPhoto ? "--translucent" : "--transparent"
                                }__icon`}
                                icon={faCamera}
                            />
                            <p
                                className={`uploadPhoto__existed${squared ? "--squared" : ""}${
                                    showUploadPhoto ? "--translucent" : "--transparent"
                                }__text`}
                            >
                                {type === "profilePicture"
                                    ? t(TranslationKeys.UPLOAD_PROFILE_PICTURE)
                                    : t(TranslationKeys.UPLOAD_PROFILE_PICTURE)}
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className={`uploadPhoto__notExisted${squared ? "--squared" : ""} ${type === "logo" ? "logo" : ""}`}
                >
                    <FontAwesomeIcon
                        className={`uploadPhoto__notExisted${squared ? "--squared" : ""}__icon`}
                        icon={faCamera}
                    />
                    <p className={`uploadPhoto__notExisted${squared ? "--squared" : ""}__text`}>
                        {type === "profilePicture"
                            ? t(TranslationKeys.UPLOAD_PROFILE_PICTURE)
                            : t(TranslationKeys.UPLOAD_PROFILE_PICTURE)}
                    </p>
                </div>
            )}
        </div>
    );
};
