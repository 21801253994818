import { useTranslation } from "react-i18next";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { IssueTranslation } from "app/translation/translationKeys";
import { FC } from "react";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { IssueBodyAssetFieldList } from "./IssueBodyAssetFieldList/IssueBodyAssetFieldList";

export type IssueBodyAssetProps = {
    asset: AssetModel2;
    taskInstance: TaskInstanceModel | null;
};

export const IssueBodyAsset: FC<IssueBodyAssetProps> = ({ asset, taskInstance }) => {
    const { t } = useTranslation();

    const { name, assetId } = asset;

    return (
        <div className="issueBodyAsset">
            <div className="issueBodyAsset__container">
                <IssueBodyTitle>
                    {!!asset.fK_Company
                        ? t(IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_NAME)
                        : t(IssueTranslation.ISSUE_BODY_DESCRIPTION_RANGE_REGISTER_NAME)}
                </IssueBodyTitle>
                <p className="issueBodyAsset__text">{`${name} ${!!assetId.length ? `- #${assetId}` : ""}`}</p>
            </div>
            {taskInstance !== null && (
                <article className="issueBodyAsset__container">
                    <IssueBodyTitle>{t(IssueTranslation.ISSUE_BODY_DESCRIPTION_ASSET_RANGE)}</IssueBodyTitle>
                    <IssueBodyAssetFieldList taskInstanceFields2={taskInstance.taskInstanceFields2} />
                </article>
            )}
        </div>
    );
};
