import { forwardRef } from "react";
import { ForwardRefModel } from "./types/common";

export const DivForwardRef = forwardRef<HTMLDivElement, ForwardRefModel>(
    ({ children, className = "" }, ref) => {
        return (
            <div ref={ref} className={className}>
                {children}
            </div>
        );
    }
);
