import { FC, useEffect } from "react";
import { EditImageMenu } from "./EditImageMenu/EditImageMenu";
import { EditImageFooter } from "./EditImageFooter/EditImageFooter";
import { ACTIONS_ALLOW_TO_DRAW } from "./constants/EditImageConstants";
import { HueSlider } from "../HueSlider/HueSlider";
import { EditImageAcceptDecline } from "./EditImageAcceptDecline/EditImageAcceptDecline";
import { Button } from "../__buttons/Button/Button";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { useEditImage } from "./hooks/useEditImage";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export type EditImageProps = {
    file?: File;
    url?: string;
    onCancel: () => void;
    onAccept: (imageBase64: string) => void;
};

export const EditImage: FC<EditImageProps> = ({ file, url, onCancel, onAccept }) => {
    const {
        convertFileToCanvas,
        currentAction,
        handleCancel,
        handleCloseEditingWidgets,
        canvasRef,
        draw,
        startDrawing,
        endDrawing,
        handleClickIcon,
        handleUndoDraw,
        isDrawed,
        lineColor,
        currentForm,
        handleChangeColor,
        handleChangeForm,
        handleChangeStroke,
        handleSendImage,
        getImage,
        cropperRef,
        onSelectCropperAction,
    } = useEditImage({ file, url, onCancel, onAccept });

    useEffect(() => {
        convertFileToCanvas();
    }, [file, url]);

    return (
        <div className="editImage">
            <EditImageAcceptDecline
                currentAction={currentAction}
                onAccept={handleCloseEditingWidgets}
                onCancel={handleCancel}
            />
            <EditImageMenu
                currentAction={currentAction}
                onClickIcon={handleClickIcon}
                isDrawed={isDrawed}
                onUndoDraw={handleUndoDraw}
                hslColor={lineColor.hslColor}
            />
            <div className="editImage__container">
                <Cropper
                    src={currentAction === "CROPPER" ? getImage() : ""}
                    className={`editImage__canvas editImage__canvas--${currentAction === "CROPPER" ? "block" : "none"}`}
                    guides={false}
                    ref={cropperRef}
                />
                <canvas
                    ref={canvasRef}
                    className={`editImage__canvas editImage__canvas--${currentAction === "CROPPER" ? "none" : "block"}`}
                    onMouseDown={startDrawing}
                    onMouseUp={endDrawing}
                    onMouseMove={draw}
                    onTouchStart={startDrawing}
                    onTouchEnd={endDrawing}
                    onTouchMove={draw}
                />
            </div>
            {currentAction && ACTIONS_ALLOW_TO_DRAW.includes(currentAction) && (
                <div className="editImage__hueSlider">
                    <HueSlider onChange={handleChangeColor} value={lineColor.hslValue} />
                </div>
            )}
            <EditImageFooter
                currentAction={currentAction}
                onChangeStroke={handleChangeStroke}
                currentForm={currentForm}
                onChangeForm={handleChangeForm}
                onSelectCropperAction={onSelectCropperAction}
            />
            {!currentAction && (
                <div className="editImage__sendImage">
                    <Button iconRight={faPaperPlane} onClick={handleSendImage} />
                </div>
            )}
        </div>
    );
};
