import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { DateRangePicker } from "app/components_v2/__inputs/DateRangePicker/DateRangePicker";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { Hint, Label } from "app/components_v2/__inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";

type TaskStartProps = {
    taskStart: string;
    customError?: string;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
    disabled?: boolean;
    min?: Date;
    label?: string;
    hint?: ReactNode;
};

export const TaskStart: FC<TaskStartProps> = ({ taskStart, onChange, customError, disabled, min, label, hint }) => {
    const { t } = useTranslation();
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false);

    return (
        <div className="taskStart">
            <div className="taskStart__label">
                <Label label={label || t(TranslationKeys.TASK_START)} />
                {!!hint && (
                    <FontAwesomeIcon
                        icon={faCircleQuestion}
                        className="hintIcon"
                        onClick={() => setIsHintVisible((prev) => !prev)}
                    />
                )}
            </div>
            <DateRangePicker
                startDate={taskStart ? new Date(taskStart) : new Date()}
                range={false}
                placeholder={t(TranslationKeys.PLACEHOLDER_WIZARD_TASK_START)}
                onChange={(date) => {
                    onChange({
                        taskStart: date[0].toString(),
                    });
                }}
                errorMessage={customError}
                disabled={disabled}
                min={min}
            />
            {isHintVisible && hint}
        </div>
    );
};
