import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { DragFile } from "app/components_v2/__draggableFiles/DragFile/DragFile";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";

export const DragFileSection: FC<SectionsShowProp> = ({ show }) => {
    const { t } = useTranslation();

    const [images, setImages] = useState<ImageDragFile[]>([]);

    const handleInputChange = (values: ImageDragFile[]) => {
        setImages(values);
    };

    return (
        <Section show={show} title="Drag File">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <DragFile
                    name={t(TranslationKeys.PHOTOGRAPHIC_REGISTER)}
                    placeholder={t(TranslationKeys.CLICK_TO_UPLOAD)}
                    subPlaceholder={t(TranslationKeys.UPLOAD_TYPE)}
                    onChange={handleInputChange}
                    onDelete={() => {}}
                    images={images}
                    multiple
                />
            </div>
        </Section>
    );
};
