import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";

export const currentTabToIssueType = (currentTab: number) => {
    const type: Record<number, IssueTypeCodeModel> = {
        0: "TASK",
        1: "TASK_ASSET",
        2: "GENERIC",
        3: "AUDIT",
    };

    return type[currentTab];
};
