import { IssueStatusState } from "../state/context/issueGridContext";

export const getIssueIsOpenByIssueStatusState = (issueStatusState: IssueStatusState) => {
    const issueIsOpenRecord: Record<IssueStatusState, boolean | null> = {
        BOTH: null,
        OPEN: true,
        CLOSED: false,
    };

    return issueIsOpenRecord[issueStatusState];
};
