import { getDateFormattedSelects } from "app/helpers";
import { AutomaticRecordGridSF } from "../models/AutomaticRecordGridModels";

type FillExtraParamsProps = {
    filterData: AutomaticRecordGridSF;
    companyId: string;
};

export const fillExtraParams = ({ filterData, companyId }: FillExtraParamsProps) => {
    const extraParams = new URLSearchParams();
    const { dateTimeEntry1, dateTimeEntry2, batteryLevel, signalLevel, assetIds, sensorSerialNumbers } = filterData;

    if (dateTimeEntry1) extraParams.append("dateTimeEntry1", getDateFormattedSelects(dateTimeEntry1));
    if (dateTimeEntry2) extraParams.append("dateTimeEntry2", getDateFormattedSelects(dateTimeEntry2));
    if (batteryLevel) extraParams.append("batteryLevel", batteryLevel);
    if (signalLevel) extraParams.append("signalLevel", signalLevel);
    if (!!assetIds.length) extraParams.append("assetIds", assetIds.map((assetId) => assetId).join(";"));
    if (!!sensorSerialNumbers.length)
        extraParams.append("sensorSerialNumbers", sensorSerialNumbers.map(({ value }) => value).join(";"));
    if (companyId) extraParams.append("companyId", companyId);

    return extraParams.toString();
};
