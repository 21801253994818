import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { validateStatus } from "../__validates/validateTaskpillStatus";
import { TaskStatus } from "app/shared/Constants";
import {
    convertUTCtoLocaleDate,
    getDayMonthAndHourFormatedShort,
    getDiferencesBetweenTwoDates,
} from "../Date.utilities";
import i18next from "i18next";
import { TaskPillTranslations } from "app/translation/translationKeys";
import { TFunction } from "react-i18next";

export const getCompletedDate = (
    taskInstance: TaskInstanceModel,
    t: TFunction<"translation", undefined>
): string | undefined => {
    const taskPillState = validateStatus(taskInstance);

    if (taskInstance.statusCode !== TaskStatus.COMPLETED || !taskInstance.finishedDate) return undefined;

    if (taskPillState === "OUT_OF_TIME" && taskInstance.deadlineDate) {
        const { hours, minutes, days } = getDiferencesBetweenTwoDates(
            new Date(taskInstance.deadlineDate),
            new Date(taskInstance.finishedDate)
        );

        if (days > 0)
            return days === 1
                ? t(TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_SINGULAR)
                      .replace("{0}", String(hours))
                      .replace("{1}", String(minutes))
                : t(TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR_AND_DAYS_LATE_PLURAL)
                      .replace("{0}", String(days))
                      .replace("{1}", String(hours))
                      .replace("{2}", String(minutes));

        const differenceTwoDates =
            hours <= 0
                ? t(TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES).replace("{0}", String(minutes))
                : t(TaskPillTranslations.DIFFERENCE_BETWEEN_TWO_DATES_WITH_HOUR)
                      .replace("{0}", String(hours))
                      .replace("{1}", String(minutes));
        return differenceTwoDates;
    }

    const completedDate = taskInstance.finishedDate
        ? getDayMonthAndHourFormatedShort(convertUTCtoLocaleDate(taskInstance.finishedDate), i18next.language)
        : undefined;

    return completedDate;
};
