import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "../../shared/Constants";
import { EntitiesWithPendingTasksPerDay } from "app/dtos/01-SEG/EntitiesWithPendingTasksPerDay/EntitiesWithPendingTasksPerDayDto";

export default class AvailabilityService {
    public static async GetEntitiesWithPendingTasksPerDay() {
        return FetchService.get<EntitiesWithPendingTasksPerDay>({
            url: `${ApiPaths.SEG_API}/Availability`,
        });
    }

    public static async SaveCloseCompaniesAndDepartments(body: EntitiesWithPendingTasksPerDay) {
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/Availability/Close`,
            body: body,
        });
    }

    public static async SaveNotClosedCompaniesAndDepartments(body: EntitiesWithPendingTasksPerDay) {
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/Availability/NotClosed`,
            body: body,
        });
    }
}
