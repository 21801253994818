type IExtraparams = {
    companyId: string;
};

export const fillExtraparams = ({ companyId }: IExtraparams): string => {
    const extraParams = new URLSearchParams();

    extraParams.append("IsDeleted", "false");
    if (companyId !== "-1") extraParams.append("CompanyId", companyId);

    return extraParams.toString();
};
