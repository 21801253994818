import { faCheck, faSave, faWarning, faXmark, faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { IssueModal } from "app/components_v2/__modals/IssueModal/IssueModal";
import { useToast } from "app/hooks/Toast/useToast";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import {
    IssueTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { NotificationIcon } from "app/components_v2/NotificationIcon/NotificationIcon";
import { AuditModelOptions } from "../../../../models/types";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { WizardTask } from "app/components_v2/wizards/WizardTask/WizardTask";
import { WizardTaskBodyAndId } from "app/models/02-TAR/Task/TaskBody";

type AuditChecklistItemProps = {
    name: string;
    value: AuditGroupCheckListItemInstanceValues;
    id: number;
    onChange: (
        itemId: number,
        selectedValue: AuditGroupCheckListItemInstanceValues,
        fk_task: number | null
    ) => Promise<void>;
    readonly: boolean;
    auditModelOptions: AuditModelOptions;
    fK_Task: number | null;
};

type RemoveIssueModal = {
    isOpen: boolean;
    selectedValue: AuditGroupCheckListItemInstanceValues | null;
};

const INITIAL_REMOVE_ISSUE_MODAL: RemoveIssueModal = {
    isOpen: false,
    selectedValue: null,
};

export const AuditChecklistItem: FC<AuditChecklistItemProps> = ({
    name,
    value,
    id,
    onChange,
    readonly,
    auditModelOptions,
    fK_Task,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const { fillValues: fillIssueModalValues } = useContext(IssueModalContext);
    const [isIssueModalOpen, setIsIssueModalOpen] = useState<boolean>(false);
    const [removeIssueModal, setRemoveIssueModal] = useState<RemoveIssueModal>(INITIAL_REMOVE_ISSUE_MODAL);
    const [checklistValue, setChecklistValue] = useState<AuditGroupCheckListItemInstanceValues>(value);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openWizardTask, setOpenWizardTask] = useState<boolean>(false);
    const [issueId, setIssueId] = useState<number>();
    const prevValue = useRef<AuditGroupCheckListItemInstanceValues>(value);

    const nonConformityModalTitle: Record<number, string> = {
        0: t(IssueTranslation.DELETE_NON_CONFORMITY_TITLE),
        1: t(IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_TITLE),
    };

    const nonConformityModalDescription: Record<number, string> = {
        0: t(IssueTranslation.DELETE_NON_CONFORMITY_DESCRIPTION),
        1: t(IssueTranslation.DELETE_NON_CONFORMITY_AND_TASK_DESCRIPTION),
    };

    const handleCheckListItemchange = async (itemId: number, selectedValue: AuditGroupCheckListItemInstanceValues) => {
        if (selectedValue === checklistValue) return;
        if (selectedValue === "KO") return handleOpenModal();

        if (prevValue.current === "KO") {
            setRemoveIssueModal({ isOpen: true, selectedValue });
            return;
        }
        await handleCheck(itemId, selectedValue);
    };

    const handleOpenModal = async () => {
        const { auditInstanceId, auditInfo } = auditModelOptions;
        fillIssueModalValues({ issueType: "AUDIT", auditInstanceId, auditInfo, auditGroupChecklistInstanceId: id });
        setIsIssueModalOpen(true);
    };

    const handleCheck = async (itemId: number, selectedValue: AuditGroupCheckListItemInstanceValues) => {
        setChecklistValue(selectedValue);
        onChange(itemId, selectedValue, 0);
        setIsIssueModalOpen(false);
        setRemoveIssueModal(INITIAL_REMOVE_ISSUE_MODAL);

        const { status } = await AuditInstanceService.CompleteAuditChecklistItem(itemId, { value: selectedValue });

        if (!status()) {
            setChecklistValue(prevValue.current);
            onChange(itemId, prevValue.current, 0);
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        prevValue.current = selectedValue;
    };

    const handleRemoveKO = async (itemId: number, selectedValue: AuditGroupCheckListItemInstanceValues) => {
        setIsLoading(true);
        const { status } = await AuditInstanceIssueService.DeleteIssueByChecklistIdAsync(itemId);

        if (!status()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            setIsLoading(false);
            return;
        }

        await handleCheck(itemId, selectedValue);
        openWizardTask && setOpenWizardTask(false);
        setIsLoading(false);
    };

    const onFinishCreateTask = (taskGenerated: WizardTaskBodyAndId | null) => {
        setOpenWizardTask(false);
        if (taskGenerated) onChange(id, "KO", taskGenerated.id);
    };

    const onCloseWizardTask = () => setRemoveIssueModal({ isOpen: true, selectedValue: "OK" });

    return (
        <>
            {isIssueModalOpen && (
                <IssueModal
                    onCloseModal={(isSubmiting) => (isSubmiting ? handleCheck(id, "KO") : setIsIssueModalOpen(false))}
                    variant="appcc"
                    type="AUDIT"
                    footerOptions={{ icon: faSave, text: t(IssueTranslation.ISSUE_SAVE_CONFIRM_BUTTON) }}
                    onOpenWizardTask={setOpenWizardTask}
                    setIssueId={setIssueId}
                />
            )}
            {removeIssueModal.isOpen && (
                <ConfirmModal
                    onConfirm={() =>
                        removeIssueModal.selectedValue && handleRemoveKO(id, removeIssueModal.selectedValue)
                    }
                    onClose={() => setRemoveIssueModal(INITIAL_REMOVE_ISSUE_MODAL)}
                    title={nonConformityModalTitle[Number(openWizardTask)]}
                    description={nonConformityModalDescription[Number(openWizardTask)]}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    isLoading={isLoading}
                    variant="appcc"
                />
            )}
            {openWizardTask && (
                <GenericModal
                    header={{
                        title: t(TranslationKeys.NEW_TASK),
                        onClose: onCloseWizardTask,
                        variant: "appcc",
                    }}
                    size="lg"
                    hideChildrenPadding
                >
                    <WizardTask onFinish={onFinishCreateTask} onClose={onCloseWizardTask} hasPlan issueId={issueId} />
                </GenericModal>
            )}
            <div className="auditChecklistItem">
                <div className="auditChecklistItem__title">
                    <p className="auditChecklistItem__name">{name}</p>
                    {!!fK_Task && <NotificationIcon size="sm" variant="primary" icon={faListCheck} />}
                </div>
                <div className="auditChecklistItem__checkboxes">
                    <CheckBox
                        checked={checklistValue === "OK"}
                        onChange={() => !readonly && handleCheckListItemchange(id, "OK")}
                        color="green"
                        rounded
                        size="xl"
                        theme="light"
                        innerIcon={faCheck}
                        readonly={readonly}
                    />
                    <CheckBox
                        checked={checklistValue === "KO"}
                        onChange={() => !readonly && handleCheckListItemchange(id, "KO")}
                        color="red"
                        rounded
                        size="xl"
                        theme="light"
                        innerIcon={faWarning}
                        readonly={readonly}
                    />
                    <CheckBox
                        checked={checklistValue === "NA"}
                        onChange={() => !readonly && handleCheckListItemchange(id, "NA")}
                        color="gray"
                        rounded
                        size="xl"
                        theme="light"
                        innerIcon={faXmark}
                        readonly={readonly}
                    />
                </div>
            </div>
        </>
    );
};
