import { FC, useRef, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { useToast } from "app/hooks/Toast/useToast";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SelectOptions } from "app/components_v2/__inputs";
import { Button } from "app/components_v2/__buttons/Button/Button";

export const TranscriptSection: FC<SectionsShowProp> = ({ show }) => {
    const { handleToast } = useToast();

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isTranscripting, setIsTranscripting] = useState<boolean>(false);
    const recognition = useRef<SpeechRecognition | null>(null);
    const [text, setText] = useState<string>("");
    const [lang, setLang] = useState("es");
    const langOptions: OptionsSearch[] = [
        { label: "Español", value: "es" },
        { label: "Català", value: "ca" },
        { label: "English", value: "eo" },
    ];

    const onStartRecording = () => {
        if (!("webkitSpeechRecognition" in window || "SpeechRecognition" in window)) {
            handleToast({ type: "alert", title: "El navegador no es compatible." });
            return;
        }

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        recognition.current = new SpeechRecognition();
        recognition.current.lang = lang;
        recognition.current.continuous = true;

        try {
            recognition.current.start();
            recognition.current.onresult = async (e) => {
                let transcript = "";
                for (let i = 0; i < e.results.length; i++) {
                    transcript = formatSpeech(transcript, e.results[i][0].transcript);
                }
                setText(transcript);
            };
            recognition.current.onsoundstart = async () => {
                setIsTranscripting(true);
            };
            recognition.current.onstart = () => {
                setIsRecording(true);
            };
        } catch (error) {
            setIsRecording(false);
            recognition.current.stop();
            handleToast({ type: "alert", title: "No se ha podido empezar a grabar tu voz" });
            console.log(error);
        }
    };

    const formatSpeech = (previousTranscription: string, newTranscription: string) => {
        if (!!previousTranscription.length) {
            return `${previousTranscription}${newTranscription.charAt(1).toUpperCase() + newTranscription.slice(2)}. `;
        }
        return `${newTranscription.charAt(0).toUpperCase() + newTranscription.slice(1)}. `;
    };

    const stopRecording = () => {
        recognition.current?.stop();
        setIsTranscripting(false);
        setIsRecording(false);
    };

    return (
        <Section title="Transcriptor" show={show}>
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => setLang(value)}
                options={langOptions}
                label="Selecciona el idioma"
                selectedValue={lang}
            />
            <Button
                text={isRecording ? "parar" : "grabar"}
                onClick={() => (isRecording ? stopRecording() : onStartRecording())}
            />
            {isTranscripting && <p>Transcribiendo...</p>}
            {!!text.length && <p>{text}</p>}
        </Section>
    );
};
