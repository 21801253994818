import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useStartEndDates } from "app/hooks/Dates/useStartEndDates";
import { useSession } from "app/hooks";
import { TranslationTitles } from "app/translation/translationKeys";
import { CompanyRanking } from "./Ranking/CompanyRanking/CompanyRanking";
import { ANALYST_ENTITY_TYPE, PrivatePaths, PublicPaths } from "app/shared/Constants";
import { RankingDepartment } from "./Ranking/RankingDepartment/RankingDepartment";
import { RankingUserByDepartment } from "./Ranking/RankingUserByDepartment/RankingUserByDepartment";
import { BreadcrumbsProvider } from "./state/BreadcrumbsProvider";
import { AnalistDetail } from "./AnalistDetail/AnalistDetail";
import SupervisorDetailPage from "./SupervisordetailPage/SupervisordetailPage";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

const today = new Date(new Date().toISOString());
const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
const INITIAL_START_DATE = new Date(oneWeekAgo);
const INITIAL_END_DATE = new Date(today.setDate(today.getDate() - 1));

export const AnalystRankingPage = () => {
    const { dates, onDateChange } = useStartEndDates(INITIAL_START_DATE, INITIAL_END_DATE);
    const session = useSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const companies = session?.user?.companies;

    const defaultBreadcrums =
        companies && companies?.length > 1
            ? [
                  {
                      name: translateCustomerTypeKeys(TranslationTitles.RANKING_COMPANY_TITLE, {
                          firstReplace: "PLURAL",
                      }),
                      to: `/${PrivatePaths.COMPANY_RANKING}`,
                  },
              ]
            : [];

    const [selectedDepartment, setSelectedDepartment] = useState();

    return (
        <BreadcrumbsProvider defaultBreadcrums={defaultBreadcrums}>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={"/"} />} />
                <Route path="/" element={<CompanyRanking {...dates} onDateChange={onDateChange} />} />
                <Route
                    path={"/ranking-users/:id?"}
                    element={<RankingUserByDepartment {...dates} onDateChange={onDateChange} />}
                />

                <Route
                    path={"/ranking-teams/:id?"}
                    element={
                        <RankingDepartment
                            {...dates}
                            onDateChange={onDateChange}
                            onSelectedDepartment={setSelectedDepartment}
                        />
                    }
                />
                <Route
                    path="/company/:id"
                    element={
                        <AnalistDetail
                            entityType={ANALYST_ENTITY_TYPE.COMPANY}
                            startDate={dates.startDate}
                            endDate={dates.endDate}
                            onDateChange={onDateChange}
                            key={1}
                        />
                    }
                />
                <Route
                    path="/team/:id"
                    element={
                        <AnalistDetail
                            entityType={ANALYST_ENTITY_TYPE.DEPARTMENT}
                            startDate={dates.startDate}
                            endDate={dates.endDate}
                            onDateChange={onDateChange}
                            key={2}
                        />
                    }
                />
                <Route
                    path="/user/:id"
                    element={
                        <AnalistDetail
                            entityType={ANALYST_ENTITY_TYPE.USER}
                            startDate={dates.startDate}
                            endDate={dates.endDate}
                            onDateChange={onDateChange}
                            key={3}
                        />
                    }
                />
                <Route
                    path="/supervisor/:supervisorId"
                    element={
                        <SupervisorDetailPage
                            isCritic={false}
                            setIsCritic={undefined}
                            options={[]}
                            {...dates}
                            onDateChange={onDateChange}
                            defaultDepartment={selectedDepartment}
                        />
                    }
                />
            </Routes>
        </BreadcrumbsProvider>
    );
};
