import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { CorrectiveMeasureModal } from "./components/CorrectiveMeasureModal/CorrectiveMeasureModal";
import { CorrectiveMeasureModel } from "app/models/05-QUA/CorrectiveMeasureModels/CorrectiveMeasureModel";
import { faCirclePlus, faClipboardList, faMagnifyingGlass, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { INITIAL_CORR_MESS_MODAL } from "../constants/constants";
import { MobileCorrectiveMeasureRow } from "./components/MobileCorrectiveMeasureRow/MobileCorrectiveMeasureRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { PlanAPPCCGridHeader } from "app/components_v2/PlanAPPCCGridHeader/PlanAPPCCGridHeader";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useCorrectiveMeasure } from "./hooks/useCorrectiveMeasure";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";

export const CorrectiveMeasureGrid = () => {
    const { t } = useTranslation();

    useTitle(t(TranslationTitles.CORRECTIVE_MEASURES_PAGE_TITLE));

    const {
        correctiveMeasureModal,
        data,
        deleteModal,
        filterData,
        isDeleteModalLoading,
        isLoading,
        pageIndex,
        showSearchInput,
        sortDirection,
        sortField,
        total,
        planOptions,
        urlParams,
        handleDeleteCorrMess,
        handleOpenCreateModal,
        handleOpenDeleteModal,
        handleOpenEditModal,
        handleSaveCreateModel,
        handleSortChange,
        onChangeCorrectiveMeasure,
        handleCloseDeleteModal,
        onChangePageIndex,
        setShowSearchInput,
        handleCleanFetch,
        onSFChange,
        onSearch,
        handlePlanChange,
    } = useCorrectiveMeasure();

    const columns: ColumnsType<CorrectiveMeasureModel>[] = [
        {
            label: t(TranslationKeys.CORRECTIVE_MEASSURE_ID),
            dataIndex: "id",
            sortedType: "default",
            className: "correctiveMeasure__id",
            render: ({ id }) => <CellTitle title={id} />,
        },
        {
            label: t(TranslationKeys.CORRECTIVE_MEASSURE_DESCRIPTION),
            dataIndex: "description",
            sortedType: "default",
            className: "correctiveMeasure__description",
            render: ({ description }) => <CellTitle title={description} />,
        },
        {
            label: t(TranslationKeys.CORRECTIVE_MEASSURE_PLAN),
            dataIndex: "planAPPCC",
            sortedType: "default",
            className: "correctiveMeasure__plan",
            render: ({ planAPPCC }) => <CellTitle title={planAPPCC.name} />,
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "acciones",
            className: "correctiveMeasure__actions",
            alignCenter: true,
            hidden:
                !hasPermissionToEdit(SecScreen.CORR_MEASURE_MANAGER) &&
                !hasPermissionToDelete(SecScreen.CORR_MEASURE_MANAGER),
            render: ({ id, description, fK_PlanAPPCC }) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => handleOpenEditModal({ id, description, fK_PlanAPPCC }),
                            hidden: !hasPermissionToEdit(SecScreen.CORR_MEASURE_MANAGER),
                            title: t(TranslationCommon.CREATE),
                        },
                        {
                            icon: faTrash,
                            onClick: () => handleOpenDeleteModal({ id, description, fK_PlanAPPCC }),
                            hidden: !hasPermissionToDelete(SecScreen.CORR_MEASURE_MANAGER),
                            title: t(TranslationCommon.DELETE),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <PageContainer
                header={
                    <PlanAPPCCGridHeader
                        title={t(TranslationTitles.PERMISSION_TITLE_CORR_MEASURE_MANAGER)}
                        onChange={handlePlanChange}
                        planOptions={planOptions}
                        selectedValue={urlParams.get("planId") ?? "-1"}
                    />
                }
            >
                {correctiveMeasureModal.isOpen && (
                    <CorrectiveMeasureModal
                        onClose={() => onChangeCorrectiveMeasure(INITIAL_CORR_MESS_MODAL)}
                        onSave={handleSaveCreateModel}
                        type={correctiveMeasureModal.type}
                        value={correctiveMeasureModal.selectedCorrMess}
                    />
                )}
                {deleteModal.isOpen && (
                    <ConfirmModal
                        onConfirm={handleDeleteCorrMess}
                        onConfirmText={t(TranslationCommon.DELETE)}
                        onClose={handleCloseDeleteModal}
                        description={t(TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_DESCRIPTION)}
                        onCloseText={t(TranslationCommon.CLOSE)}
                        title={t(TranslationModals.CORRECTIVE_MEASSURE_DELETE_MODAL_TITLE).replace(
                            "{0}",
                            deleteModal.selectedCorrMess.description
                        )}
                        isLoading={isDeleteModalLoading}
                        variant="appcc"
                    />
                )}
                <TableCollapsable
                    cols={columns.filter((col) => !col.hidden)}
                    data={data}
                    variant="appcc"
                    placeholder={<TableError icon={faClipboardList} />}
                    subTitle={`${total} ${t(TranslationKeys.CORRECTIVE_MEASSURE_MEASURE)}`}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    onChangePageIndex={onChangePageIndex}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onDoubleClick={({ id, description, fK_PlanAPPCC }) => {
                        if (hasPermissionToEdit(SecScreen.CORR_MEASURE_MANAGER))
                            handleOpenEditModal({ id, description, fK_PlanAPPCC });
                    }}
                    onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                        handleSortChange(sortFieldParam, sortedTypeParam || "default");
                    }}
                    actionButtons={[
                        {
                            icon: faCirclePlus,
                            onClick: handleOpenCreateModal,
                            hidden: !hasPermissionToAdd(SecScreen.CORR_MEASURE_MANAGER),
                            title: t(TranslationCommon.EDIT),
                        },
                        {
                            icon: faMagnifyingGlass,
                            onClick: () => {
                                setShowSearchInput(true);
                            },
                            inputComponent: {
                                component: (
                                    <TableInputText
                                        cleanFunction={handleCleanFetch}
                                        fetch={onSearch}
                                        onChange={(queryValue) => {
                                            onSFChange({ query: queryValue });
                                        }}
                                        value={filterData.query}
                                        variant="appcc"
                                    />
                                ),
                                show: showSearchInput,
                            },
                            title: t(TranslationCommon.SEARCH),
                        },
                    ]}
                    mobileBody={(row, _key, rowPosition) => (
                        <MobileCorrectiveMeasureRow
                            key={row.id}
                            row={row}
                            onEdit={() =>
                                handleOpenEditModal({
                                    id: row.id,
                                    fK_PlanAPPCC: row.fK_PlanAPPCC,
                                    description: row.description,
                                })
                            }
                            onDelete={() =>
                                handleOpenDeleteModal({
                                    id: row.id,
                                    fK_PlanAPPCC: row.fK_PlanAPPCC,
                                    description: row.description,
                                })
                            }
                            rowPosition={rowPosition}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
};
