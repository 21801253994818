import { FC, ReactNode, useRef, useState } from "react";
import { InputProps, Input } from "../Input/Input";
import { Popover } from "app/components_v2/Popover/Popover";
import { useOnClickOutside } from "usehooks-ts";

type InputWithPopover = InputProps & {
    children: ReactNode;
    isPopoverVisible: boolean;
    onClickOutside?: () => void;
};

export const InputWithPopover: FC<InputWithPopover> = ({
    children,
    isPopoverVisible,
    onClickOutside,
    ...inputProps
}) => {
    const popoverTarget = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(popoverTarget, () => onClickOutside && onClickOutside());

    return (
        <div className="inputWithPopover">
            <div className="inputWithPopover__input" ref={popoverTarget}>
                <Input {...inputProps} />
            </div>
            {isPopoverVisible && (
                <div className="inputWithPopover__results">
                    <Popover
                        target={popoverTarget.current}
                        position="bottomLeft"
                        portal={false}
                        className="inputWithPopover__popover"
                    >
                        <div
                            className="inputWithPopover__item"
                            style={{ width: popoverTarget.current?.getBoundingClientRect().width }}
                        >
                            {children}
                        </div>
                    </Popover>
                </div>
            )}
        </div>
    );
};
