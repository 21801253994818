import { FC } from "react";
import i18n from "app/translation/i18n";
import { TranslationKeys } from "app/translation/translationKeys";
import { RecurrentTaskModel } from "app/models/02-TAR/TaskWizard";

const daysText = {
    0: i18n.t(TranslationKeys.WEEK_MONDAY),
    1: i18n.t(TranslationKeys.WEEK_TUESDAY),
    2: i18n.t(TranslationKeys.WEEK_WEDNESDAY),
    3: i18n.t(TranslationKeys.WEEK_THURSDAY),
    4: i18n.t(TranslationKeys.WEEK_FRIDAY),
    5: i18n.t(TranslationKeys.WEEK_SATURDAY),
    6: i18n.t(TranslationKeys.WEEK_SUNDAY),
};

type DaysOfWeekProps = {
    recurringTask: RecurrentTaskModel;
    repeatEvery: number;
};
export const DaysOfWeek: FC<DaysOfWeekProps> = ({ recurringTask, repeatEvery }) => {
    return (
        <>
            <p className="stepFivePlaceHolderInput">
                {repeatEvery === 1
                    ? `${i18n.t(TranslationKeys.TASK_REPEAT_EVERY_X_WEEK)}`
                    : i18n.t(TranslationKeys.TASK_REPEAT_EVERY_X_WEEKS).replace("{1}", `${repeatEvery}`)}
            </p>
            <ul>
                {recurringTask.custom.days.map(
                    ({ isActive, value }, i) => isActive && <li key={value}>{daysText[i as keyof typeof daysText]}</li>
                )}
            </ul>
        </>
    );
};
