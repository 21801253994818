import { useState } from "react";
import { useToast } from "../Toast/useToast";
import { useFetchErrors } from "../useFetchErrors";
import { v4 } from "uuid";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import AssetService from "app/services/05-QUA/AssetService";
import { useTranslation } from "react-i18next";
import { SensorModalTranslations, TranslationErrors } from "app/translation/translationKeys";
import { useSession } from "../useSession";

type IuseSensorQr = {
    onSuccess: (linked: boolean) => void;
    onError: () => void;
};

export const useSensorQr = ({ onSuccess, onError }: IuseSensorQr) => {
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const [asset, setAsset] = useState<AssetModel2 | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getAsset = async (serialNumber: string) => {
        const toastId = v4();
        handleToast({
            title: t(SensorModalTranslations.SENSOR_TOAST_LOADING),
            isLoading: true,
            id: toastId,
            type: "alert",
        });
        setIsLoading(true);
        const { data, getParsedError, status } = await AssetService.GetOneBySensorSerialNumber(serialNumber);

        const customerInstanceId = session?.user.customerInstanceId;

        if (!status()) {
            errorFetch(toastId, getErrorMessage(getParsedError()));
            return;
        }

        if (data && data?.customerInstanceId !== customerInstanceId) {
            errorFetch(toastId, t(TranslationErrors.SENSOR_NUMBER_INVALID));
            return;
        }

        removeToast(toastId);
        setAsset(data);
        setIsLoading(false);
        onSuccess(!!data && !!data.sensorSerialNumber.length);
        return;
    };

    const errorFetch = (toastId: string, errorMessage: string) => {
        onError();
        removeToast(toastId);
        handleToast({ title: errorMessage, type: "alert", variant: "danger" });
        setIsLoading(false);
        setAsset(null);
    };

    return {
        asset,
        isLoading,
        getAsset,
    };
};
