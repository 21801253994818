import Table from "../Table";
import { TableTabHeader, TableTabHeaderProps } from "../TableTabHeader/TableTabHeader";
import { TableProps } from "../types";
import { TableTabOneRowHeader } from "../TableTabOneRowHeader/TableTabOneRowHeader";

type TableCollapsableProps<T> = TableTabHeaderProps & TableProps<T> & {};

export const TableCollapsable = <T extends object>({
    actionButtons,
    allowCheckbox,
    clearAll,
    cols,
    currentTab,
    data,
    fitHeight,
    instantDowload,
    isLoading,
    isOpen,
    leftIcon,
    mobileBody,
    onChangePageIndex,
    onChangeSortDirectionField,
    onCheckboxChange,
    onClickCollapsable,
    onClickExportCsv,
    onDoubleClick,
    pageIndex,
    pageSize,
    placeholder,
    sortDirection,
    sortField,
    subTitle,
    tabs,
    title,
    total,
    variant = "primary",
}: TableCollapsableProps<T>) => {
    return (
        <div>
            <div className={`tableHeaderContainer tableHeaderContainer--${variant}`}>
                {!title && !subTitle && tabs ? (
                    <TableTabOneRowHeader
                        tabs={tabs}
                        currentTab={currentTab}
                        onClickCollapsable={onClickCollapsable}
                        actionButtons={actionButtons}
                        isOpen={isOpen}
                        isLoading={isLoading}
                        variant={variant}
                    />
                ) : (
                    <TableTabHeader
                        tabs={tabs}
                        title={title}
                        subTitle={subTitle}
                        currentTab={currentTab}
                        onClickCollapsable={onClickCollapsable}
                        actionButtons={actionButtons}
                        isOpen={isOpen}
                        leftIcon={leftIcon}
                        isLoading={isLoading}
                        variant={variant}
                    />
                )}
                <Table
                    cols={cols}
                    data={data}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    onChangePageIndex={onChangePageIndex}
                    total={total}
                    pageSize={pageSize}
                    onClickExportCsv={onClickExportCsv}
                    isOpen={isOpen}
                    placeholder={placeholder}
                    onChangeSortDirectionField={onChangeSortDirectionField}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    allowCheckbox={allowCheckbox}
                    clearAll={clearAll}
                    instantDowload={instantDowload}
                    onDoubleClick={onDoubleClick}
                    onCheckboxChange={onCheckboxChange}
                    mobileBody={mobileBody}
                    fitHeight={fitHeight}
                    variant={variant}
                />
            </div>
        </div>
    );
};
