import { UserType } from "app/shared/types/UserTypes";
import { TranslationModules } from "app/translation/translationKeys";

export const GetUserTypeDescription = (userType: UserType, t: Function) => {
    const userTypeDescription: Record<UserType, string> = {
        ADMIN: "admin",
        ANALIST: t(TranslationModules.ANALYTICS),
        SUPERVISOR: t(TranslationModules.TAR_REVISION),
        WORKER: t(TranslationModules.TAR_REGISTRY),
    };

    return userTypeDescription[userType];
};
