import { FC } from "react";
import { WizardLicenseStepThreeProps } from "./types";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { Input } from "app/components_v2/__inputs";
import { useStepThree } from "./hooks/useStepThree";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const StepThree: FC<WizardLicenseStepThreeProps> = ({
    adminUserCode,
    adminEmail,
    adminFirstName,
    adminLastName,
    adminUsername,
    next,
    onChange,
}) => {
    const { t } = useTranslation();

    const { validate, validations } = useStepThree({
        adminUserCode,
        adminEmail,
        adminFirstName,
        adminLastName,
        adminUsername,
        next,
    });

    const { errorAdminUserCode, errorAdminEmail, errorAdminFirstName, errorAdminLastName, errorAdminUsername } =
        validations;

    return (
        <WizardForm validate={validate}>
            <Input
                onChange={(newValue) => onChange({ adminFirstName: newValue })}
                value={adminFirstName}
                label={t(TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_NAME)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_NAME)}
                errorMessage={errorAdminFirstName}
            />
            <Input
                onChange={(newValue) => onChange({ adminLastName: newValue })}
                value={adminLastName}
                label={t(TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_LASTNAME)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_LASTNAME)}
                errorMessage={errorAdminLastName}
            />
            <Input
                onChange={(newValue) => onChange({ adminUsername: newValue })}
                value={adminUsername}
                label={t(TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_USERNAME)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_USERNAME)}
                errorMessage={errorAdminUsername}
            />
            <Input
                onChange={(newValue) => onChange({ adminEmail: newValue })}
                value={adminEmail}
                label={t(TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_EMAIL)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_EMAIL)}
                errorMessage={errorAdminEmail}
            />
            <Input
                onChange={(newValue) => onChange({ adminUserCode: newValue })}
                value={adminUserCode}
                label={t(TranslationKeys.WIZARD_LICENSE_ADMIN_CONTACT_DOCUMENT_NUMBER)}
                placeholder={t(TranslationKeys.WIZARD_LICENSE_PLACEHOLDER_ADMIN_CONTACT_DOCUMENT_NUMBER)}
                errorMessage={errorAdminUserCode}
            />
        </WizardForm>
    );
};
