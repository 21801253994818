import { FC, useState } from "react";
import { TableFooterModel } from "../types";
import Pagination from "app/components_v2/Pagination/Pagination";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { useWindowSize } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { ExportCsvModal } from "app/components_v2/__modals/ExportCsvModal/ExportCsvModal";
import { screenSize } from "app/shared/Constants";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";

type TableFooterProps = TableFooterModel & {};

export const TableFooter: FC<TableFooterProps> = ({
    onClickExportCsv,
    pageIndex,
    pageSize,
    total,
    onChangePageIndex,
    instantDowload,
    variant = "primary",
    maxRowsCsv,
}) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();

    const [isCsvModalOpen, setIsCsvModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDownloadCsv = async (params: IDownLoadCsvReturn | undefined) => {
        setIsLoading(true);
        setIsCsvModalOpen(false);
        onClickExportCsv && (await onClickExportCsv(params));
        setIsLoading(false);
    };

    return (
        <>
            <div className="customTable__footer">
                {onClickExportCsv ? (
                    <Button
                        text={width < screenSize.TABLET ? "" : t(TranslationKeys.TABLE_EXPORT_TO_CSV)}
                        iconLeft={faArrowDownToLine}
                        type="secondary"
                        onClick={() => {
                            instantDowload ? onClickExportCsv && onClickExportCsv({}) : setIsCsvModalOpen(true);
                        }}
                        variant={variant}
                        isLoading={isLoading}
                    />
                ) : (
                    <div></div>
                )}
                {pageIndex != null && total != null && onChangePageIndex && (
                    <Pagination
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        total={total}
                        onClick={onChangePageIndex}
                        variant={variant}
                    />
                )}
            </div>
            {isCsvModalOpen && (
                <ExportCsvModal
                    onClose={() => setIsCsvModalOpen(false)}
                    onConfirm={onDownloadCsv}
                    title={t(TranslationKeys.CSV_EXPORT)}
                    variant={variant}
                    pageIndex={pageIndex}
                    total={total}
                    max={maxRowsCsv}
                />
            )}
        </>
    );
};
