import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "usehooks-ts";

type SideBarProps = {
    children: React.ReactNode;
    open?: boolean;
    onBlur?: () => void;
    zIndexUp?: boolean;
};
export const SideBar: FC<SideBarProps> = ({ children, open = false, onBlur, zIndexUp }) => {
    const [isOpen, setIsOpen] = useState<boolean>(open);
    const ref = useRef<any>(null);
    useEffect(() => {
        setIsOpen(open);
    }, [open]);
    useOnClickOutside(ref, (e) => {
        e.stopPropagation();
        onBlur && onBlur();
    });
    const SidebarContent = (
        <div
            className={`sidebarModal__container${isOpen ? "" : "--hide"} ${
                zIndexUp ? "zIndexUp" : ""
            }`}
        >
            <div ref={ref} className={`sidebarModal${isOpen ? "" : "--hide"}`}>
                {children}
            </div>
        </div>
    );
    return <>{createPortal(SidebarContent, document.body)}</>;
};

export default SideBar;
