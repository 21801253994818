import { Input } from "app/components_v2/__inputs";
import { useToast } from "app/hooks/Toast/useToast";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { RoleService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

type TemporaryRoleEditNameProps = {
    scheduledRole?: RoleModel;
    onFinish: () => void;
    setIsSaving: (value: boolean) => void;
};

export const TemporaryRoleEditName: FC<TemporaryRoleEditNameProps> = ({ onFinish, scheduledRole, setIsSaving }) => {
    const [formValues, setFormValues] = useState<string>(scheduledRole?.name || "");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const validate = () => {
        setErrorMessage("");
        if (!formValues.length) {
            setErrorMessage(t(TranslationCommon.INPUT_NOT_EMPTY));
            return false;
        }
        if (formValues.length <= 2) {
            setErrorMessage(`${t(TranslationKeys.ROLE_NAME)} ${t(TranslationKeys.INPUT_MINIMUN)?.replace("{0}", "3")}`);
            return false;
        }
        if (formValues.length > 30) {
            setErrorMessage(
                `${t(TranslationKeys.ROLE_NAME)} ${t(TranslationKeys.INPUT_LENGTH)
                    ?.replace("{0}", "3")
                    .replace("{1}", "30")}`
            );
            return false;
        }

        return true;
    };

    const handleService = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        if (!scheduledRole) return;

        setIsSaving(true);
        const { status } = await RoleService.EditRoleName({ roleName: formValues }, scheduledRole.id);
        status()
            ? onFinish()
            : handleToast({
                  title: t(TranslationModals.TOAST_GENERIC_ERROR),
                  type: "alert",
                  variant: "danger",
              });
        setIsSaving(false);
    };

    return (
        <form className="editRoleForm" onSubmit={handleService} id="editRoleForm">
            <Input
                onChange={(text) => setFormValues(text)}
                value={formValues}
                label={t(TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_NAME)}
                placeholder={t(TranslationKeys.EDIT_TEMPORARY_ROLE_FORM_PLACEHOLDER_NAME)}
                errorMessage={errorMessage}
            />
        </form>
    );
};
