import { Badge } from "app/components_v2/Badge/Badge";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { AuditInstanceDetailDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceDetailDto";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditTranslation, BaseTaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type EditAuditHeaderProps = {
    isEditAuditHeaderLoading: boolean;
    editAuditHeaderData?: AuditInstanceDetailDto;
    actualStatus: AuditInstanceStatus;
    currentTab: number;
    stars: ReactNode;
    classificationLabel: string;
};

export const EditAuditHeader: FC<EditAuditHeaderProps> = ({
    isEditAuditHeaderLoading,
    editAuditHeaderData,
    actualStatus,
    currentTab,
    stars,
    classificationLabel,
}) => {
    const { t } = useTranslation();

    if (isEditAuditHeaderLoading) return <h1 className="editAudit__header"></h1>;

    const badge: { title: string; variant: BadgeVariant } = {
        title:
            actualStatus === "IN_PROGRESS"
                ? t(AuditTranslation.AUDIT_IN_PROGRESS)
                : actualStatus === "COMPLETED"
                ? t(AuditTranslation.AUDIT_IN_REVIEW)
                : actualStatus === "REVIEWED"
                ? t(AuditTranslation.AUDIT_REVIEWED_SINGULAR)
                : t(TranslationKeys.AUDIT_CLOSED),
        variant:
            actualStatus === "IN_PROGRESS"
                ? "blue"
                : actualStatus === "COMPLETED"
                ? "orange"
                : actualStatus === "REVIEWED"
                ? "orange"
                : "green",
    };

    return (
        <div className="editAuditHeader">
            <h1 className="editAuditHeader__container">
                <span className="editAuditHeader__title">{`${t(TranslationKeys.AUDIT)} "${
                    editAuditHeaderData?.auditName
                }"`}</span>
                <div className="editAuditHeader__subtitle">
                    <span className="editAuditHeader__title">{`Nº ${
                        editAuditHeaderData?.counter
                    } - ${getDayMonthFormatedWithYearNumbers(
                        editAuditHeaderData?.closeDate
                            ? editAuditHeaderData?.closeDate.toString()
                            : editAuditHeaderData?.openDate.toString() || "",
                        "/"
                    )}`}</span>
                    <Badge title={badge.title} variant={badge.variant} theme="light" />
                </div>
            </h1>
            {currentTab === 2 && (
                <div className="editAudit__grade">
                    {stars}
                    {!!classificationLabel.length && (
                        <p className="auditReportGrid__grade">
                            {`${t(BaseTaskTranslations.BASE_TASK_TYPE_LABEL)}: ${classificationLabel}`}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
