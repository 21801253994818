import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";

export default class PrivacyService {
    public static async AcceptPrivacy(userId: number) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/users/AcceptPrivacyTerms/${userId}`,
        });
    }
}
