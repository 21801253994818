import { FC } from "react";
import { TaskPillBodyHeaderSkelleton } from "../../Mobile/TaskPillBodyHeaderSkelleton/TaskPillBodyHeaderSkelleton";
import { TaskPillBodySkelleton } from "../../Mobile/TaskPillBodySkelleton/TaskPillBodySkelleton";

type Props = {
    hasImage: boolean;
};

export const TaskPillWithStarsSkelletonDesktop: FC<Props> = ({ hasImage }) => {
    return (
        <div className="desktop-taskPillWithStar-skelleton">
            {hasImage && <div className="desktop-taskPillWithStar-skelleton__img"></div>}
            <div className="desktop-taskPillWithStar-skelleton__container">
                <div className="desktop-taskPillWithStar-skelleton__container__taskInfo">
                    <TaskPillBodyHeaderSkelleton />
                    <TaskPillBodySkelleton showStarRating />
                </div>
            </div>
        </div>
    );
};
