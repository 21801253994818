import { FC, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-regular-svg-icons";
import { UploadFile } from "app/components_v2/__inputs/UploadFile/UploadFile";
import { EmojisInput } from "app/components_v2/__inputs/EmojisInput/EmojisInput";
import { AttachedFile } from "app/components_v2/AttachedFile/AttachedFile";
import { FileData } from "app/shared/types/FileDataTypes";
import AudioRecorder from "app/components_v2/AudioRecorder/AudioRecorder";
import { TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type UploadFileType = {
    showSendFile: boolean;
    sendFile?: (fileData: FileData) => void;
};

type UploadAudioType = {
    showSendAudio: boolean;
    audioBase64?: string | null;
    setAudioBase64?: (audioBase64: string | null) => void;
};

type ChatInputBoxProps = {
    sendMessage?: () => void;
    setCurrentMessage: (value: string) => void;
    maxLength?: number;
    autoFocus?: boolean;
    upsideDownEmojis?: boolean;
    shortEmojis?: boolean;
    focusOnSendMessage?: boolean;
    showTextArea?: boolean;
    borderRadiusVariant?: "borderRadiusAll" | "borderRadiusBottom";
    uploadFile?: UploadFileType;
    uploadAudio?: UploadAudioType;
    uploading?: boolean;
};

export const ChatInputBox: FC<ChatInputBoxProps> = ({
    sendMessage,
    setCurrentMessage,
    maxLength = 50000,
    autoFocus,
    upsideDownEmojis,
    shortEmojis,
    focusOnSendMessage,
    showTextArea,
    borderRadiusVariant = "borderRadiusAll",
    uploadFile = { showSendFile: true },
    uploadAudio = { showSendAudio: false },
    uploading,
}) => {
    const { showSendAudio, audioBase64, setAudioBase64 } = uploadAudio;
    const { showSendFile, sendFile } = uploadFile;

    const { t } = useTranslation();
    const chatInputBoxRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState<string>("");
    const [inputFocus, setInputFocus] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [file, setFile] = useState<FileData | null>(null);
    const [emojiRefresh, setEmojiRefresh] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const showAudioRecorder =
        showSendAudio &&
        audioBase64 !== undefined &&
        setAudioBase64 !== undefined &&
        isRecording !== undefined &&
        setIsRecording !== undefined &&
        !inputValue?.length &&
        !file;

    const handleSendMessage = () => {
        setEmojiRefresh(!emojiRefresh);
        sendMessage && sendMessage();
        setAudioBase64 && setAudioBase64(null);
        setFile(null);
        setInputValue("");
        setInputFocus(!inputFocus);
    };

    const handleSelectFile = (base64String: string, file: File) => {
        setFile({ base64String, file });
        sendFile && sendFile({ base64String, file });
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (chatInputBoxRef.current && !chatInputBoxRef.current.contains(event.target as Node)) {
                setIsFocused(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={chatInputBoxRef}
            className={`chatInputBox${isFocused ? "--focused" : ""} chatInputBox${
                uploading ? "--disabled" : ""
            } chatInputBox--${borderRadiusVariant} `}
        >
            {file && (
                <div className="chatInputBox__uploadedFile">
                    <AttachedFile onClose={() => setFile(null)} message={file.file.name} isSmall />
                </div>
            )}
            <>
                {!isRecording && audioBase64 == null && !file && (
                    <div className="chatInputBox__container">
                        <div className="chatInputBox__input">
                            {!uploading ? (
                                <EmojisInput
                                    onSubmit={handleSendMessage}
                                    onChangeValue={setCurrentMessage}
                                    sendValue={setInputValue}
                                    setIsFocusedProp={setIsFocused}
                                    isFocusedProp={isFocused}
                                    doRefresh={emojiRefresh}
                                    maxLength={maxLength}
                                    autoFocus={autoFocus}
                                    upsideDownEmojis={upsideDownEmojis}
                                    short={shortEmojis}
                                    focusOnSendMessage={focusOnSendMessage}
                                    inputFocus={inputFocus}
                                    showTextArea={showTextArea}
                                    disabled={uploading}
                                />
                            ) : (
                                <>{t(TranslationCommon.UPLOADING_FILE)}</>
                            )}
                        </div>
                        {showSendFile && !inputValue.length && audioBase64 == null && !uploading && (
                            <UploadFile handleFile={handleSelectFile} />
                        )}
                    </div>
                )}
                <div className="chatInputBox__icons">
                    {showAudioRecorder && (
                        <AudioRecorder
                            disabled={uploading}
                            audioBase64={audioBase64}
                            setAudioBase64={setAudioBase64}
                            isRecording={isRecording}
                            setIsRecording={setIsRecording}
                        />
                    )}

                    {(!!inputValue?.length || !!audioBase64?.length || file) && (
                        <div className="chatInputBox__icons__sendIcon__container">
                            <FontAwesomeIcon
                                onClick={handleSendMessage}
                                className="chatInputBox__icons__sendIcon"
                                icon={faPaperPlaneTop}
                            />
                        </div>
                    )}
                </div>
            </>
        </div>
    );
};
