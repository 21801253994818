import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { EditImage } from "app/components_v2/EditImage/EditImage";
import { Modal } from "app/components_v2/__modals/base/Modal/Modal";
import { Button } from "app/components_v2/__buttons/Button/Button";

export const EditImageSection: FC<SectionsShowProp> = ({ show }) => {
    const [photo, setPhoto] = useState<File | null>(null);
    const [url, setUrl] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <Section show={show} title="Editar image">
            <Button
                text="Añadir foto desde una url"
                onClick={() => {
                    setUrl("https://images.unsplash.com/photo-1719937206220-f7c76cc23d78");
                    setShowModal(true);
                }}
            />
            <input
                type="file"
                accept="image/*"
                onChange={({ target }) => {
                    const file = target.files;
                    if (!file) return;
                    setPhoto(file[0]);
                    setShowModal(true);
                }}
            />
            {showModal && (
                <Modal portal className="zIndexUp webcamCapture" showBlur={false}>
                    <div className="webcamCapture__container designSystem__webcamCapture__container">
                        <div className="mediaCapture designSystem__mediaCapture">
                            {photo && (
                                <div className="mediaCapture__image designSystemEditImage">
                                    <EditImage
                                        file={photo}
                                        onCancel={() => {
                                            setShowModal(false);
                                        }}
                                        onAccept={(image) => {
                                            setShowModal(false);
                                        }}
                                    />
                                </div>
                            )}
                            {url && (
                                <div className="mediaCapture__image designSystemEditImage">
                                    <EditImage
                                        url={url}
                                        onCancel={() => {
                                            setShowModal(false);
                                            setUrl(null);
                                        }}
                                        onAccept={(image) => {
                                            setShowModal(false);
                                            setUrl(null);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
        </Section>
    );
};
