import { FC } from "react";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { RoleCollapseChecboxes } from "app/components_v2/RoleCollapseChecboxes/RoleCollapseChecboxes";
import Spinner from "app/components_v2/Spinner/Spinner";

type Props = {
    isLoading: boolean;
    normalized: ModuleScreenPatents[];
    modules: SecModule[];
    onChangeModules: (code: string, value: SecScreenPatent) => void;
};

const ModulesForm: FC<Props> = ({ isLoading, normalized, modules, onChangeModules }) => {
    return (
        <div className="modulesForm">
            <div className="modulesForm__container">
                {isLoading ? (
                    <div className="modulesForm__container__spinner">
                        <Spinner />
                    </div>
                ) : (
                    normalized.map((modulePaterns) => (
                        <RoleCollapseChecboxes
                            modulePaterns={modulePaterns}
                            key={modulePaterns.moduleCode}
                            modules={modules}
                            onChange={onChangeModules}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default ModulesForm;
