import { faCheck, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { RadioCheckboxContainer } from "../__containers/RadioCheckboxContainer/RadioCheckboxContainer";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Label } from "../__inputs/Label";

export type CheckboxButtonProps = {
    onChange: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    htmlFor?: string;
    name?: string;
    intermediate?: boolean;
    label?: string;
    sublabel?: string;
    rounded?: boolean;
    color?: "primary" | "green" | "orange" | "red" | "gray";
    reverse?: boolean;
    inverted?: boolean;
    innerIcon?: IconProp;
    readonly?: boolean;
    size?: "sm" | "md" | "xl";
    theme?: "dark" | "light";
};

export const CheckBox: FC<CheckboxButtonProps> = ({
    checked,
    onChange,
    disabled,
    htmlFor,
    name,
    intermediate,
    label,
    sublabel,
    rounded,
    color = "primary",
    reverse,
    inverted,
    readonly,
    size = "md",
    theme = "dark",
    innerIcon,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const handleChange = (e: any) => {
        if (readonly || disabled) return;
        e.stopPropagation();
        onChange(!checked);
    };

    const handleMouseLeave = () => {
        !disabled && setIsFocused(false);
    };

    const getIcon = (): IconProp => {
        if (innerIcon) return innerIcon;
        return checked && !intermediate ? (color === "red" ? faXmark : faCheck) : faMinus;
    };

    const getIconTitle = () => {
        if (innerIcon) return "";
        return checked && !intermediate ? (color === "red" ? "x" : "check") : "-";
    };

    return (
        <>
            <input
                type="checkbox"
                onChange={(e) => !disabled && handleChange(e)}
                checked={checked}
                hidden={true}
                id={htmlFor}
                name={name}
                disabled={disabled}
            />
            <RadioCheckboxContainer>
                <>
                    <div className={`checkboxButton2__wrapper${reverse ? "--reverse" : ""} `}>
                        <div
                            onClick={(e) => !disabled && !readonly && handleChange(e)}
                            className={`checkboxButton2--${theme}--${color}${checked ? "--active" : ""}${
                                isFocused ? "--focus" : ""
                            } ${disabled ? "disabled" : ""} ${readonly ? "readonly" : ""} ${
                                rounded ? "checkboxButton2--rounded" : ""
                            } checkboxButton2--${size}`}
                            onMouseDown={() => !disabled && !readonly && setIsFocused(true)}
                            onMouseUp={() => !disabled && !readonly && setIsFocused(false)}
                            onMouseLeave={handleMouseLeave}
                        >
                            {(checked || (!checked && innerIcon)) && (
                                <div
                                    className={`checkboxButton2--${theme}--${color}__interior${
                                        checked ? "--checked" : ""
                                    } checkboxButton2__interior--${size}`}
                                >
                                    <FontAwesomeIcon icon={getIcon()} title={getIconTitle()} />
                                </div>
                            )}
                        </div>
                        {label && (
                            <Label
                                label={label}
                                subLabel={sublabel}
                                disabled={disabled}
                                htmlFor={disabled || readonly ? "" : htmlFor}
                                inverted={inverted}
                                size={size}
                            />
                        )}
                    </div>
                </>
            </RadioCheckboxContainer>
        </>
    );
};
