import { UserModel } from "app/models/01-SEG/User/UserModel";
import { UserType } from "app/shared/types/UserTypes";
import { UserPageFormValues } from "app/pages/01-SEG/Users/types";

export const UserPageFormToUserModel = (userForm: UserPageFormValues, user: UserModel): UserModel => {
    const {
        userCode,
        email,
        initialScreen,
        lastName,
        name,
        userRoleModelList,
        userCompanyList,
        username,
        userType,
        fk_JobDescription,
    } = userForm;

    const userTypeLabel: Record<UserType, UserType> = {
        ADMIN: "ADMIN",
        ANALIST: "ANALIST",
        SUPERVISOR: "SUPERVISOR",
        WORKER: "WORKER",
    };

    const userTypeValue: UserType = userTypeLabel[userType as keyof typeof userTypeLabel] || "WORKER";

    return {
        ...user,
        userCode,
        email,
        initialScreenCode: initialScreen,
        firstName: name,
        lastName,
        userRoleModelList,
        companyIds: userCompanyList.map(({ value }) => Number(value)),
        username,
        userType: userTypeValue,
        fK_JobDescription: fk_JobDescription !== "-1" ? Number(fk_JobDescription) : undefined,
        automaticActivationDate: user.automaticActivationDate || undefined,
    };
};
