import { WizardLicenseStepOneErrors } from "../models/models";

export const INITIAL_WIZARD_LICENSE_ERRORS: WizardLicenseStepOneErrors = {
    errorContactEmail: "",
    errorContactName: "",
    errorInstanceName: "",
    errorPhoneNumber: "",
    errorInitials: "",
    errorCustomerType: "",
};
