import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import PercentageStars from "app/components_v2/PercentageStars/PercentageStars";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const UserValorationSection: FC<SectionsShowProp> = ({ show }) => {
    const { t } = useTranslation();
    return (
        <Section title="User Valoration" show={show}>
            <UserValoration valoration={4.4} />
            <UserValoration valoration={2.7} timedOut={4} />
            <PercentageStars
                title={t(TranslationKeys.TASKS_VALORATION)}
                trend={10}
                valoration={4.2}
                total={234}
                stars={[5, 5, 10, 30, 50]}
            />
        </Section>
    );
};
