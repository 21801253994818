import WentWrong, { ErrorTypeModel } from "app/components_v2/Errors/WentWrong";
import { FC } from "react";
import { useLocation } from "react-router-dom";

type NotFoundProps = { errorType?: ErrorTypeModel };

export const NotFound: FC<NotFoundProps> = ({ errorType }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const feedBackPhoto = queryParams.get("acces_denied");
    return <WentWrong errorType={feedBackPhoto ? "accessDenied" : errorType} />;
};
