import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { TaskStatus } from "app/shared/Constants";
import { TFunction } from "react-i18next";
import { getDayMonthFormatedUTC } from "../Date.utilities";
import { TranslationCommon } from "app/translation/translationKeys";

export const getStartDateText = (
    taskInstance: TaskInstanceModel,
    lang: string,
    t: TFunction<"translation", undefined>
) => {
    if (taskInstance.taskTemporalityType !== "RANGE_DATES" || taskInstance.startDate == null) return "";
    if (taskInstance.statusCode === TaskStatus.PENDING)
        return `${t(TranslationCommon.START_DATE_LABEL)}: ${getDayMonthFormatedUTC(
            new Date(taskInstance.startDate),
            lang
        )}`;

    return "";
};
