import { FC, useState } from "react";
import { RoleCollapseBody } from "./RoleCollapseBody/RoleCollapseBody";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { Collapsable } from "../Collapsable/Collapsable";
import { useTranslation } from "react-i18next";
import Helper from "app/helpers/TranslationHelper";
import { WhiteBox } from "../WhiteBox/WhiteBox";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type RoleCollapseChecboxesProps = {
    modulePaterns: ModuleScreenPatents;
    modules: SecModule[];
    disabled?: boolean;
    isOpen?: boolean;
    onChange: (code: string, value: SecScreenPatent) => void;
};

export const RoleCollapseChecboxes: FC<RoleCollapseChecboxesProps> = ({
    modulePaterns,
    modules,
    onChange,
    disabled,
    isOpen,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(isOpen || false);

    const collapsableTitle = Helper.translateRowTitle(modulePaterns.moduleCode, t, translateCustomerTypeKeys);
    return (
        <WhiteBox>
            <Collapsable open={isCollapseOpen} onChange={setIsCollapseOpen} title={collapsableTitle} border="none">
                <div className="roleCollapseCheckbox">
                    <div className="roleCollapseCheckbox__collapse">
                        <RoleCollapseBody
                            modulePaterns={modulePaterns}
                            modules={modules}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </Collapsable>
        </WhiteBox>
    );
};
