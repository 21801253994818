import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";

export const TasbSection: FC<SectionsShowProp> = ({ show }) => {
    const [currentTab, setCurrentTab] = useState<number>(0);

    const tabs: TabPropsReduced[] = [
        { text: "Tab Name", onClick: setCurrentTab, children: <>1</> },
        { text: "Tab Name", leftCount: 2, onClick: setCurrentTab, children: <>2</> },
        { text: "Tab Name", rightCount: 3, onClick: setCurrentTab, children: <>3</> },
        { leftCount: 4, onClick: setCurrentTab, children: <>4</> },
    ];

    const tabsDark: TabPropsReduced[] = [
        { text: "Tab Name", onClick: setCurrentTab, children: <>1</>, type: "dark" },
        { text: "Tab Name", leftCount: 2, onClick: setCurrentTab, children: <>2</>, type: "dark" },
        { text: "Tab Name", rightCount: 3, onClick: setCurrentTab, children: <>3</>, type: "dark" },
        { text: "Tab Name", rightCount: 4, onClick: setCurrentTab, children: <>4</>, type: "dark" },
    ];
    return (
        <Section title="Tabs" show={show}>
            <Tabs tabs={tabs} currentTab={currentTab} />
            <div className="tabsBackground">
                <Tabs tabs={tabsDark} currentTab={currentTab} />
            </div>
        </Section>
    );
};
