import { Actions } from "../../state/actions";
import { CompanyService, InstanceService } from "../../services";
import { ComponentStatus } from "../../models/FormComponentsModel";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { PrivatePaths } from "../../shared/Constants";
import { SelectOptions } from "app/components_v2/__inputs";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSession } from "app/hooks";
import { useSystemUser } from "app/hooks/useSystemUser";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";
import { Button } from "../__buttons/Button/Button";

const InstanceSelector: FC = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { handleSystemUser } = useSystemUser();
    const session = useSession();
    const nav = useNavigate();
    const dispatch = useDispatch();

    const [instances, setInstances] = useState<OptionModel[]>([]);
    const [status, setStatus] = useState<ComponentStatus>("loading");

    const customerInstanceId = session?.user.customerInstanceId;

    const fetchInstances = async () => {
        setStatus("loading");
        const instancesSr = await InstanceService.GetInstances();

        if (!instancesSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setStatus("errored");
            setInstances([]);
            return;
        }

        setInstances(
            instancesSr.data.list.map(({ id, name }) => ({
                label: name,
                value: String(id),
            }))
        );
        setStatus("complete");
    };

    const getAllCompanies = async () => {
        const sr = await CompanyService.GetData();
        if (!sr?.status() || !session) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        const companies: UserCompanyModel[] = sr.data.list.map(({ id, name, logoFileURL }) => ({
            companyId: id,
            companyName: name,
            logoUrl: logoFileURL,
        }));
        dispatch(
            Actions.setAppSession({
                ...session,
                user: {
                    ...session.user,
                    companies: companies,
                },
            })
        );
    };

    const onChangeInstance = async ({ value }: OptionModel) => {
        const systemUser = await handleSystemUser(Number(value));
        if (!!systemUser) dispatch(Actions.updateUserSession(systemUser));
        setWorkingEntity({
            workingCompany: undefined,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        });
        nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
    };

    useEffect(() => {
        if (session && !!session.user.customerInstanceId) getAllCompanies();
        fetchInstances();
    }, []);

    if (status === "loading")
        return (
            <div className="instanceSelector">
                <Spinner />
            </div>
        );
    if (status === "errored")
        return (
            <div className="instanceSelector">
                <Button onClick={fetchInstances} iconRight={faRefresh} text={t(TranslationKeys.RELOAD_INSTANCES)} />
            </div>
        );

    return (
        <div className="instanceSelector">
            <SelectOptions
                isMulti={false}
                onChange={onChangeInstance}
                options={instances}
                selectedValue={String(customerInstanceId) !== "-1" ? String(customerInstanceId) : undefined}
                inverted
            />
        </div>
    );
};

export default InstanceSelector;
