import PrivateRoute from "app/routes/PrivateRoute";
import { ON_BOARDING_SUB_STEPS, PublicPaths, SecScreen } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuditTemplateGrid } from "./AuditTemplateGrid/AuditTemplateGrid";
import { useOnBoardingAccessPage } from "app/hooks/OnBoarding/useOnBoardingAccessPage";
import { FC } from "react";

export const AuditTemplatePage: FC = () => {
    useOnBoardingAccessPage(ON_BOARDING_SUB_STEPS.ONBOARDING_CREATE_AUDIT_TEMPLATE);
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route
                path={""}
                element={<PrivateRoute component={AuditTemplateGrid} viewCode={SecScreen.AUDIT_MANAGEMENT} />}
            />
        </Routes>
    );
};
