import { FC } from "react";
import { IDropDownListItem } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DropdownListItem: FC<IDropDownListItem> = ({ text, icon, onClick, active, size = "sm", radius }) => {
    return (
        <div
            className={`dropdownListItem${active ? "--active" : ""} ${radius ? "radius--6" : ""}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick && onClick();
            }}
            role="listitem"
        >
            <div className="dropdownListItem__content">
                {icon && (
                    <FontAwesomeIcon
                        icon={icon}
                        className={`dropdownListItem__content__icon${active ? "--active" : ""}`}
                        title="dropdown icon"
                    />
                )}
                <p
                    className={`dropdownListItem__content__text--${size} dropdownListItem__content__text${
                        active ? "--active" : ""
                    }`}
                >
                    {text}
                </p>
            </div>
        </div>
    );
};
