import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { PreviousPathNameContext } from "app/state/context/prevoiusPathNameContext/prevoiusPathNameContext";

export const PreviousPathNameProvider: FC<ProviderProps> = ({ children }) => {
    const [currentPathName, setCurrentPathName] = useState<string>("/");
    const [pathNames, setPathNames] = useState<string[]>([]);

    const handleChangeCurrentPathName = (pathName: string) => {
        if (pathNames.length >= 10) pathNames.shift();
        setCurrentPathName(pathName);
        const newPathnames = pathNames.filter((prev) => prev !== pathName);
        setPathNames([...newPathnames, pathName]);
    };

    const handleChangePathNames = () => {
        setPathNames((prevPathNames) => prevPathNames.slice(0, -1));
    };
    return (
        <PreviousPathNameContext.Provider
            value={{
                previousPathName: currentPathName,
                changeLastPathName: handleChangeCurrentPathName,
                changePathNames: handleChangePathNames,
                pathNames,
                previousGoBack2: pathNames.length > 0 ? pathNames[pathNames.length - 2] : "/",
                setPathNames,
            }}
        >
            {children}
        </PreviousPathNameContext.Provider>
    );
};
