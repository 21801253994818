import { ReviewTaskFilterData } from "app/components_v2/__filters/ReviewTaskFilter/types";
import { FilterData } from "../../01-TAR-Dashboard/constants/DashboardResumeContext";

export const INITIAL_STATE_FILTER_DATA_REVIEW: FilterData = {
    name: undefined,
    deadlineDate: new Date(),
    deadlineDate1: undefined,
    finishedDate: undefined,
    finishedDate1: undefined,
    user: "-1",
    department: "-1",
    subdepartment: "-1",
    fields: undefined,
    plan: undefined,
    status: undefined,
    wellDoneCheck: false,
    worseDoneCheck: false,
    notEvaluatedCheck: false,
    hasData: false,
    hasPhoto: false,
    completed: false,
    critical: false,
    noCritical: false,
    oneStar: undefined,
    twoStar: undefined,
    threeStar: undefined,
    fourStar: undefined,
    fiveStar: undefined,
};

export const INITIAL_REVIEW_TASK_FILTER_DATA: ReviewTaskFilterData = {
    oneStar: false,
    twoStar: false,
    threeStar: false,
    fourStar: false,
    fiveStar: false,
    hasComments: false,
    isPhotoRequired: false,
    dataToReportReview: "ALL",
};
