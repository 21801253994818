import { RowPositionModel } from "app/components_v2/Table/types";
import { FC } from "react";
import { MobilePlanRowBody } from "./MobilePlanRowBody/MobilePlanRowBody";
import { PlanAPPCCWithCountersDto } from "app/dtos/05-QUA/PlanAPPCC/PlanAPPCCWithCountersDto";
import { MobilePlanRowFooter } from "./MobilePlanRowFooter/MobilePlanRowFooter";

type MobilePlanRowProps = {
    data: PlanAPPCCWithCountersDto;
    rowPosition: RowPositionModel;
    onEdit?: () => void;
    onDelete?: () => void;
};

export const MobilePlanRow: FC<MobilePlanRowProps> = ({ data, rowPosition, onDelete, onEdit }) => {
    return (
        <div className={`planMobileRow--${rowPosition}`}>
            <MobilePlanRowBody {...data} onDelete={onDelete} onEdit={onEdit} />
            <MobilePlanRowFooter {...data} />
        </div>
    );
};
