import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { OptionsSearch } from "app/models/FormComponentsModel";
import {
    SelectActionsLastItemsModel,
    SelectActionsModel,
    SelectActionsOptions,
} from "app/components_v2/SelectWithActions/types";
import { useToast } from "app/hooks/Toast/useToast";
import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";

export const SelectWithActionsSection: FC<SectionsShowProp> = ({ show }) => {
    const { handleToast } = useToast();

    const [selectedValue, setSelectedValue] = useState<string>("");
    const options: SelectActionsOptions[] = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    const onChange = ({ value }: OptionsSearch) => {
        handleToast({ title: `onchange value: ${value}`, type: "alert" });
        setSelectedValue(value);
    };

    const onEdit = ({ value }: OptionsSearch) => {
        handleToast({ title: `edit value: ${value}`, variant: "warning", type: "alert" });
    };

    const onDelete = ({ value }: OptionsSearch) => {
        handleToast({ title: `delete value: ${value}`, variant: "danger", type: "alert" });
    };

    const openModal = () => {
        handleToast({ title: "Modal abierto", type: "alert" });
    };

    const actions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: onEdit,
        },
        {
            icon: faTrash,
            onClick: onDelete,
        },
    ];

    const lastItems: SelectActionsLastItemsModel[] = [
        { icon: faPlus, onClick: openModal, title: "Añadir nuevo" },
        { icon: faPlus, onClick: openModal, title: "Añadir nuevos y además borrar" },
    ];

    return (
        <Section show={show} title="SelectWithActions">
            <SelectWithActions
                options={options}
                onChange={onChange}
                label="Seleccion con acciones"
                selectedValue={selectedValue}
                placeholder="Selecciona una opción"
                actions={actions}
                lastItems={lastItems}
            />
        </Section>
    );
};
