import { FC, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { useGetCompanies, useSession } from "app/hooks";
import { useGetUserDeptSub } from "./hooks/useGetUserDeptSub";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { DashboardFilterData, DashboardFilterProps } from "./types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { DateRangePicker, SelectOptions } from "app/components_v2/__inputs";
import Spinner from "app/components_v2/Spinner/Spinner";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";

export const DashboardFilter: FC<DashboardFilterProps> = ({
    filterData,
    initialFilterData,
    onSave,
    isOpen,
    onCancel,
    onClickOutside,
    onReset,
    title,
    type,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { data: companies, getCompanies } = useGetCompanies({
        companyId: session?.workingCompany?.companyId,
    });

    const { departments, getUserDeptSub, isLoading, users } = useGetUserDeptSub();

    const [internalFilterdata, setInternalFilterdata] = useState<DashboardFilterData>(filterData);
    const handleInputChange = (values: Partial<DashboardFilterData>) => {
        setInternalFilterdata((prev) => ({ ...prev, ...values }));
    };

    const setOptions = (value: OptionModel[]): OptionModel[] => {
        const defaultOption = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: String(-1),
        };

        if (type === "MYTASKS") return [...value];

        return [defaultOption, ...value];
    };

    const handleCheckAll = (isChecked: boolean) => {
        handleInputChange({ isAllChecked: isChecked });
        if (!isChecked) {
            handleInputChange({
                user: session?.user.id ? String(session?.user.id) : "0",
            });
            return;
        }
        handleInputChange({ department: "-1", subdepartment: "-1", user: "-1" });
    };

    const validate = () => {
        if (!internalFilterdata.isAllChecked && type === "MYTASKS" && internalFilterdata.user === "-1") {
            handleInputChange({ user: session?.user.id ? String(session?.user.id) : "0" });
        }
    };

    useEffect(() => {
        setInternalFilterdata(filterData);
    }, [filterData]);

    useEffect(() => {
        if (!isOpen) return;
        getCompanies();
    }, [isOpen]);

    useEffect(() => {
        isOpen &&
            internalFilterdata?.company &&
            getUserDeptSub({
                companyId:
                    internalFilterdata?.company !== "-1"
                        ? internalFilterdata?.company
                        : session?.workingCompany?.companyId
                        ? String(session?.workingCompany?.companyId)
                        : "",
                type,
            });
    }, [isOpen, internalFilterdata.company, type]);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onSave={() => {
                validate();
                onSave && onSave(internalFilterdata);
            }}
            onCancel={() => {
                onCancel && onCancel();
                handleInputChange(filterData);
            }}
            onClickOutside={() => {
                onClickOutside && onClickOutside();
                handleInputChange(filterData);
            }}
            onReset={() => {
                onReset && onReset();
                handleInputChange(initialFilterData);
            }}
            title={title}
            showResetFilter={!isEqual(initialFilterData, internalFilterdata)}
        >
            <DateRangePicker
                startDate={internalFilterdata.deadlineDate}
                endDate={internalFilterdata.deadlineDate1}
                onChange={([deadlineDate, deadlineDate1]) => {
                    handleInputChange({ deadlineDate, deadlineDate1 });
                }}
                label={t(TranslationKeys.REQUEST_DATE)}
                showMoreOptions
            />
            {companies && (
                <div className="dashboardFilter__company">
                    <CheckBox
                        checked={internalFilterdata.isAllChecked}
                        onChange={handleCheckAll}
                        label={t(TranslationKeys.SHOW_ALL)}
                        htmlFor="mark_all"
                    />
                </div>
            )}
            {isLoading && !internalFilterdata.isAllChecked ? (
                <Spinner />
            ) : type === "MYTASKS" && !internalFilterdata.isAllChecked ? (
                <SelectOptions
                    label={t(TranslationKeys.TASK_FILTER_USER)}
                    options={setOptions(users)}
                    selectedValue={internalFilterdata.user !== "-1" ? internalFilterdata.user : undefined}
                    onChange={({ value }) => handleInputChange({ user: value })}
                    isSearchable
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            ) : (
                !internalFilterdata.isAllChecked && (
                    <>
                        <SelectOptions
                            label={t(TranslationKeys.TASK_FILTER_DEPARTMENT)}
                            options={setOptions(departments)}
                            selectedValue={
                                internalFilterdata.department !== "-1" ? internalFilterdata.department : undefined
                            }
                            onChange={({ value }) => handleInputChange({ department: value })}
                            isSearchable
                            isMulti={false}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                        />
                        {/* TODO: --SUBDEPARTMENT-- */}
                        {/* <SelectOptions
                            label={t(TranslationKeys.TASK_FILTER_SUBDEPARTMENT)}
                            options={setOptions(subdepartments)}
                            selectedValue={
                                internalFilterdata.subdepartment !== "-1" ? internalFilterdata.subdepartment : undefined
                            }
                            onChange={({ value }) => handleInputChange({ subdepartment: value })}
                            isSearchable
                            isMulti={false}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                        /> */}
                    </>
                )
            )}
            <div className="dashboardFilter__checkboxes">
                <div className="dashboardFilter__checkboxes__container">
                    <CheckBox
                        label={t(TranslationKeys.PIC_ASSOCIATED_TASK)}
                        htmlFor={TranslationKeys.PIC_ASSOCIATED_TASK}
                        onChange={(checked) => handleInputChange({ hasPhoto: checked })}
                        checked={!!internalFilterdata.hasPhoto}
                    />
                    <CheckBox
                        label={t(TranslationKeys.REPORTED_DATA_TASK)}
                        htmlFor={TranslationKeys.REPORTED_DATA_TASK}
                        onChange={(checked) => handleInputChange({ hasData: checked })}
                        checked={!!internalFilterdata.hasData}
                    />
                    <CheckBox
                        label={t(TranslationKeys.CRITICAL_CHECKBOXES)}
                        htmlFor={TranslationKeys.CRITICAL_CHECKBOXES}
                        onChange={(checked) => handleInputChange({ critical: checked })}
                        checked={!!internalFilterdata.critical}
                    />
                    <CheckBox
                        label={t(TranslationKeys.QR_CHECKBOX)}
                        htmlFor={t(TranslationKeys.QR_CHECKBOX)}
                        onChange={(checked) => handleInputChange({ isAssignedToQR: checked })}
                        checked={!!internalFilterdata.isAssignedToQR}
                    />
                </div>
            </div>
        </SecondaryFilter>
    );
};
