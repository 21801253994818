import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AuditGroupModel } from "app/models/05-QUA/AuditModels/AuditGroupModels";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class AuditGroupService {
    // GET Single AuditGroup
    public static async GetOne(id: number) {
        return FetchService.get<AuditGroupModel>({
            url: `${ApiPaths.QUA_API}/AuditGroup/${id}`,
        });
    }

    // GET AuditGroup List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditGroupModel>>({
            url: `${ApiPaths.QUA_API}/AuditGroup`,
            paginationParams: params,
        });
    }

    // GET AuditGroup List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/AuditGroup/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST AuditGroup
    public static async Save(body: AuditGroupModel) {
        return FetchService.post<AuditGroupModel>({
            url: `${ApiPaths.QUA_API}/AuditGroup`,
            body,
        });
    }

    // PUT AuditGroup
    public static async Edit(id: number, body: AuditGroupModel) {
        return FetchService.put<AuditGroupModel>({
            url: `${ApiPaths.QUA_API}/AuditGroup/${id}`,
            body,
        });
    }

    // DELETE AuditGroup
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/AuditGroup/${id}`,
        });
    }
}
