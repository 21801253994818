import { FC } from "react";
import { CounterPill, RankingBorderType, RankingType, RankingVariant } from "../RankingPill/types";
import { RankingPill } from "../RankingPill/RankingPill";
import Pagination from "../Pagination/Pagination";
import { TableError } from "../Table/TableError/TableError";
import { RankingTypePages } from "../Layout/RankingLayout/RankingLayout";
import { faRankingStar, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { RankingPillListSkeleton } from "../__skelletons/RankingPillListSkeleton/RankingPillListSkeleton";
import { UserType } from "app/shared/types/UserTypes";
import { PaginationDefaults } from "app/shared/Constants";

export type DataModel = {
    name: string;
    colorId?: number;
    profileURL?: string;
    starsValoration?: number;
    totalTasks: number;
    trend?: number;
    counters?: CounterPill[];
    onClick?: () => void;
    showAlert?: boolean;
    variant: RankingVariant;
    userType?: UserType;
    showBorderLeft?: boolean;
    rankingTotalTask?: number;
    jobDescription?: string;
};

type RankingPillListProps = {
    isLoading?: boolean;
    data: DataModel[];
    total: number;
    pageIndex: number;
    borderType?: RankingBorderType;
    onPageIndexChange: (pageIndex: number) => void;
    rankingTypePages: RankingTypePages;
    type?: RankingType;
    numberOfstars?: number;
    thin?: boolean;
};

export const RankingPillList: FC<RankingPillListProps> = ({
    data,
    isLoading,
    borderType = "shadow",
    onPageIndexChange,
    pageIndex,
    total,
    rankingTypePages,
    type = "default",
    numberOfstars = 5,
    thin,
}) => {
    return (
        <div className={`rankingPillList ${!isLoading && total === 0 ? "fullHeight" : ""}`}>
            {isLoading ? (
                <RankingPillListSkeleton quantity={PaginationDefaults.PAGE_SIZE} variant={type} />
            ) : total === 0 ? (
                <TableError icon={rankingTypePages === "USER" ? faUsers : faRankingStar} size="xl" />
            ) : (
                data.map((rank, id) => (
                    <RankingPill
                        key={id}
                        {...rank}
                        borderType={borderType}
                        totalCounter={rank.rankingTotalTask}
                        type={type}
                        numberOfStars={numberOfstars}
                        thin={thin}
                    />
                ))
            )}
            {total / PaginationDefaults.PAGE_SIZE >= 1 && (
                <Pagination onClick={onPageIndexChange} pageIndex={pageIndex || 1} total={total} />
            )}
        </div>
    );
};
