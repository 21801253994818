import { AlertFilter } from "app/components_v2/__filters/AlertFilter/AlertFilter";
import { AlertNotificationMapper } from "app/mappers/AlertNotificationMapper";
import { AlertNotificationsModal } from "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModal";
import { AlertPillList } from "app/components_v2/__containers/AlertPillList/AlertPillList";
import { faPaperPlane, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { FloatingButton } from "app/components_v2/__buttons/FloatingButton/FloatingButton";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { isEqual } from "lodash";
import { NotificationsModal } from "app/components_v2/__modals/NotificationsModal/NotificationsModal";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { SecScreen, screenSize } from "app/shared/Constants";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { TableActionButtons } from "app/components_v2/Table/TableActionButtons/TableActionButtons";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { translateCurrentTabToModel } from "./helpers/translateCurrentTabToModel";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useAlert } from "./hooks/useAlert";
import { useGetPageTitle } from "app/hooks/useGetPageTitle";
import { useSession } from "app/hooks";
import { useTitle, useWindowSize } from "app/hooks/CustomHooks";
import { useTranslation } from "react-i18next";
import { useUserType } from "app/hooks/useUserType";

export const AlertsPage = () => {
    const { t } = useTranslation();
    const { isSupervisorOrAnalist, isWorker } = useUserType();
    const { width } = useWindowSize();
    const session = useSession();
    const pageTitle = useGetPageTitle();

    const {
        currentTab,
        filterData,
        initialFilterData,
        isFilterOpen,
        isLoading,
        isNotficationModalOpened,
        list,
        onAlertClick,
        onChangeFilterData,
        onChangePageIndex,
        onClickChangeTab,
        onCloseNotificationModal,
        pageIndex,
        selectedData,
        setIsFilterOpen,
        setIsNotificationModalOpened,
        setShowModal,
        showModal,
        total,
    } = useAlert();

    useTitle(t(TranslationTitles.ALERTS_PAGE_TITLE));

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.RECEIVED),
            children: (
                <AlertPillList
                    data={list}
                    isLoading={isLoading}
                    total={total}
                    onAlertClick={onAlertClick}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    isReceived
                />
            ),
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
        {
            text: t(TranslationKeys.SENT),
            children: (
                <AlertPillList
                    data={list}
                    isLoading={isLoading}
                    total={total}
                    onAlertClick={onAlertClick}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    isSended
                />
            ),
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
        {
            text: t(TranslationKeys.PROGRAMMED),
            children: (
                <AlertPillList
                    data={list}
                    isLoading={isLoading}
                    total={total}
                    onAlertClick={onAlertClick}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    isProgrammed
                />
            ),
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
        {
            text: t(TranslationKeys.QR_PROGRAMMED),
            children: (
                <AlertPillList
                    data={list}
                    isLoading={isLoading}
                    total={total}
                    onAlertClick={onAlertClick}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    isProgrammed
                />
            ),
            onClick: onClickChangeTab,
            type: "dark",
            hidden: isWorker,
        },
    ];

    return (
        <>
            {showModal && selectedData && (
                <AlertNotificationsModal
                    data={AlertNotificationMapper([selectedData])}
                    closeModal={() => setShowModal(false)}
                    alreadyReaded
                />
            )}
            <PageContainer
                fullHeight
                header={
                    <TabHeader
                        tabs={
                            <>
                                <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
                                <TableActionButtons
                                    actionButtons={[
                                        {
                                            icon: faSliders,
                                            onClick: () => setIsFilterOpen(true),
                                            showMarkableIcon: !isEqual(initialFilterData, filterData),
                                        },
                                    ]}
                                />
                            </>
                        }
                    >
                        {!!pageTitle.length && width >= screenSize.TABLET && <p className="pageTitle">{pageTitle}</p>}
                    </TabHeader>
                }
            >
                {tabs[currentTab].children}

                <AlertFilter
                    filterData={filterData}
                    initialFilterData={initialFilterData}
                    onSave={onChangeFilterData}
                    isOpen={isFilterOpen}
                    onCancel={() => setIsFilterOpen(false)}
                    currentTab={translateCurrentTabToModel(currentTab)}
                />
                {isNotficationModalOpened && (
                    <NotificationsModal
                        onClose={onCloseNotificationModal}
                        userId={!isSupervisorOrAnalist ? session?.user.id : undefined}
                        userName={
                            !isSupervisorOrAnalist ? `${session?.user.firstName} ${session?.user.lastName}` : undefined
                        }
                        profileURL={!isSupervisorOrAnalist ? session?.user.profilePictureURL : undefined}
                    />
                )}
                {hasPermissionToAdd(SecScreen.ALERTS) && (
                    <FloatingButton iconLeft={faPaperPlane} onClick={() => setIsNotificationModalOpened(true)} />
                )}
            </PageContainer>
        </>
    );
};
