import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

type LabelsProps = {
    label: string;
    subLabel?: string;
    disabled?: boolean;
    htmlFor?: string;
    inverted?: boolean;
    size?: "sm" | "md" | "xl";
    minWidth?: boolean;
    isOptional?: boolean;
    onLabelClick?: () => void;
    className?: string;
};

export const Label: FC<LabelsProps> = ({
    label,
    subLabel,
    disabled,
    htmlFor,
    inverted,
    size = "sm",
    minWidth,
    isOptional,
    onLabelClick,
    className = "",
}) => {
    const { t } = useTranslation();
    return (
        <div className={`radioLabels${minWidth ? "--minWidth" : ""} ${className}`}>
            <label
                className={`radioLabels__label ${inverted ? "inverted" : ""} radioLabels__label--${size}`}
                htmlFor={disabled ? undefined : htmlFor}
                onClick={() => !disabled && onLabelClick && onLabelClick()}
            >
                {`${label} ${isOptional ? `(${t(TranslationKeys.INPUT_OPTIONAL)})` : ""}`}
            </label>
            {!!subLabel?.length && (
                <label
                    className={`radioLabels__sublabel ${inverted ? "inverted" : ""}`}
                    htmlFor={htmlFor}
                    onClick={() => !disabled && onLabelClick && onLabelClick()}
                >
                    {subLabel}
                </label>
            )}
        </div>
    );
};
