import { faCirclePlus, faFileCertificate } from "@fortawesome/pro-regular-svg-icons";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import Spinner from "app/components_v2/Spinner/Spinner";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useTitle } from "app/hooks";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AuditModel } from "app/models/05-QUA/AuditModels/AuditModels";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import AuditService from "app/services/05-QUA/AuditService";
import { SecScreen } from "app/shared/Constants";
import { TranslationKeys, TranslationModals, TranslationTitles } from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditBox } from "./AuditBox/AuditBox";
import { AuditBoxModal } from "./AuditBox/AuditBoxModal/AuditBoxModal";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { Divider } from "app/components_v2/Divider/Divider";

export const AuditTemplateGrid: FC = () => {
    const { t } = useTranslation();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { getErrorMessage } = useFetchErrors();

    useTitle(t(TranslationTitles.AUDIT_TEMPLATE_PAGE_TITLE));

    const [audits, setAudits] = useState<AuditModel[]>([]);
    const [showAddAuditModal, setShowSaveModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const fetchAudits = async () => {
        setIsLoading(true);
        const { data, status, getParsedError } = await AuditService.GetData();
        if (handleErrorManager(status(), getParsedError())) return;
        setAudits(data.list);
        setIsLoading(false);
    };

    const handleSaveAudit = async (value: string) => {
        setIsLoadingSave(true);
        const newAudit: AuditModel = {
            id: 0,
            name: value,
            auditGroups: [],
        };
        const { status, getParsedError } = await AuditService.Save(newAudit);
        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) return;

        handleSuccessManager(t(TranslationModals.AUDIT_SAVE_TOAST));

        fetchAudits();
        setIsLoadingSave(false);
        setShowSaveModal(false);
    };

    useEffect(() => {
        fetchAudits();
    }, []);

    return (
        <PageContainer
            fullHeight
            header={
                <TabHeader variant="appcc">
                    <div className="auditTemplateGrid__header">
                        <p>{t(TranslationTitles.AUDIT_TEMPLATE_TITLE)}</p>
                        {hasPermissionToAdd(SecScreen.AUDIT_MANAGEMENT) && (
                            <Button
                                text={t(TranslationKeys.ADD_AUDIT)}
                                iconLeft={faCirclePlus}
                                onClick={() => setShowSaveModal(true)}
                                transparent
                                variant="appcc"
                            />
                        )}
                    </div>
                </TabHeader>
            }
        >
            {showAddAuditModal && (
                <AuditBoxModal
                    isLoading={isLoadingSave}
                    onSubmit={handleSaveAudit}
                    onCloseModal={() => setShowSaveModal(false)}
                    type="SAVE_AUDIT"
                />
            )}

            {isLoading ? (
                <div className="auditTemplateGrid__spinner">
                    <Spinner />
                </div>
            ) : (
                <>
                    {!audits.length ? (
                        <div className="fullHeight">
                            <TableError
                                icon={faFileCertificate}
                                title={t(TranslationKeys.AUDIT_LIST_EMPTY_TITLE)}
                                description={t(TranslationKeys.AUDIT_LIST_EMPTY_BODY)}
                            />
                        </div>
                    ) : (
                        <div className="auditTemplateGrid__list">
                            {audits.map((audit, i) => (
                                <div className="auditTemplateGrid__list__item" key={i}>
                                    <AuditBox key={audit.id} audit={audit} fetchAudits={fetchAudits} />
                                    <Divider />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </PageContainer>
    );
};
