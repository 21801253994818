import { FC, ReactElement } from "react";
import { WizardHeader } from "../WizardHeader/WizardHeader";
import WizardsFooter from "./WizardsFooter";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

type WizardProps = {
    currentStepIndex: number;
    headerItems: { title?: string }[];
    goTo?: (id: number) => void;
    step: ReactElement;
    validateNextStep: boolean;
    onStepChange: (stepIndex: number) => void;
    totalSteps: number;
    isDisabledByDefault?: boolean;
    isLoading?: boolean;
    finishButtonText?: string;
    variant?: TabHeaderVariants;
    prev?: () => void;
    onClose?: () => void;
};

export const Wizard: FC<WizardProps> = ({
    step,
    currentStepIndex,
    headerItems,
    onStepChange,
    totalSteps,
    validateNextStep,
    goTo,
    isDisabledByDefault,
    isLoading,
    finishButtonText,
    variant,
    prev,
    onClose,
}) => {
    return (
        <div className="wizardContainer">
            <div className="wizardContainer__container">
                <WizardHeader currentStep={currentStepIndex} headerItems={headerItems} goTo={goTo} />
                <div className="stepsContainer">{step}</div>
            </div>
            <WizardsFooter
                validateNextStep={validateNextStep}
                setCounter={onStepChange}
                totalSteps={totalSteps}
                counter={currentStepIndex}
                isDisabledByDefault={isDisabledByDefault}
                isLoading={isLoading}
                finishButtonText={finishButtonText}
                prev={prev}
                onClose={onClose}
                variant={variant}
            />
        </div>
    );
};
