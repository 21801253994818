import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

type BreadcrumbModel = {
    name: string;
    to: string;
};

type BreadcrumbsContextType = {
    breadcrumbs: BreadcrumbModel[];
    updateBreadcrumbs: (data: { name: unknown }) => void;
    resetBreadcrums: (data: { name: string }) => void;
};

const BreadcrumbsContext = createContext<BreadcrumbsContextType | undefined>(undefined);

type BreadcrumbsProviderProps = {
    children: ReactNode;
    defaultBreadcrums?: BreadcrumbModel[];
};

export const BreadcrumbsProvider: React.FC<BreadcrumbsProviderProps> = ({ children, defaultBreadcrums }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbModel[]>(defaultBreadcrums || []);
    const location = useLocation();

    const updateBreadcrumbs = (data: { name: unknown }) => {
        const newBreadcrumb = {
            name: String(data.name),
            to: `${location.pathname}${location.search}`,
        };

        const currentIndex = breadcrumbs.findIndex((obj) => obj.to === newBreadcrumb.to);

        if (currentIndex !== -1) {
            setBreadcrumbs((prevBreadcrumbs) => prevBreadcrumbs.slice(0, currentIndex + 1));
        } else {
            setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
        }
    };

    const resetBreadcrums = ({ name }: { name: string }) => {
        const currentPath = location.pathname;
        setBreadcrumbs([{ name, to: currentPath }]);
    };

    useEffect(() => {
        const handlePopState = () => {
            const currentPath = location.pathname;

            if (breadcrumbs.length > 1 && currentPath === breadcrumbs[breadcrumbs.length - 2].to) {
                setBreadcrumbs((prevBreadcrumbs) => prevBreadcrumbs.slice(0, -1));
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [breadcrumbs, location.pathname]);

    const contextValue: BreadcrumbsContextType = {
        breadcrumbs,
        updateBreadcrumbs,
        resetBreadcrums,
    };

    return <BreadcrumbsContext.Provider value={contextValue}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbs = (): BreadcrumbsContextType => {
    const context = useContext(BreadcrumbsContext);
    if (!context) {
        throw new Error("useBreadcrumbs must be used within a BreadcrumbsProvider");
    }
    return context;
};
