import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IconProps = {
    icon: IconProp;
    disabled?: boolean;
    onClick?: () => void;
    theme?: "light" | "dark";
    iconClassName?: string;
    size?: "xs" | "sm" | "xxs";
    dataTestId?: string;
    text?: string;
    direction?: "row" | "rowReverse";
    title?: string;
};

export const Icon: FC<IconProps> = ({
    dataTestId,
    direction = "row",
    disabled,
    icon,
    iconClassName = "",
    onClick,
    size = "xs",
    text,
    theme = "dark",
    title,
}) => {
    return (
        <div
            className={`icon--${theme} ${disabled ? "disabled" : "pointer"} icon--${direction}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick && !disabled && onClick();
            }}
            data-testid={dataTestId}
            title={title}
        >
            <div className={`icon-container--${size}`}>
                <FontAwesomeIcon
                    className={`icon-container-svg ${
                        disabled ? "disabled" : ""
                    } icon-container-svg--${size} ${iconClassName}`}
                    icon={icon}
                />
            </div>
            {!!text?.length && <p className={`icon__text--${theme} icon__text--${size}`}>{text}</p>}
        </div>
    );
};
