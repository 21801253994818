import { useEffect } from "react";

function useEscapeToClose(onClose?: () => void) {
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose && onClose();
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [onClose]);
}

export default useEscapeToClose;
