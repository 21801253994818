import { FC, HTMLProps } from "react";
import { AvartarVariants } from "../types";

type AvatarNameProps = HTMLProps<HTMLParagraphElement> & {
    variant: AvartarVariants;
    capitalize?: boolean;
    clamp?: boolean;
    name: string;
};

export const AvatarName: FC<AvatarNameProps> = ({ name, variant, capitalize, clamp, ...rest }) => {
    return (
        <p
            className={`avatarName avatarName--${variant} ${clamp ? "text_clamp text_clamp-1" : ""} ${
                capitalize ? "capitalize" : ""
            }`}
            {...rest}
        >
            {name}
        </p>
    );
};
