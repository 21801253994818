import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { FC, ReactNode } from "react";
import { Tabs } from "../../Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { HeaderAnalystSkelleton } from "../../__skelletons/HeaderAnalystSkelleton/HeaderAnalystSkelleton";
import { BreadcrumbModel } from "../../BreadCrumbs";
import { UserType } from "app/shared/types/UserTypes";
import { USER_TYPE, screenSize } from "app/shared/Constants";
import { useWindowSize } from "usehooks-ts";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalystDates, AvatarModel, RatingModel } from "../../HeaderAnalyst/types";
import { HeaderAnalyst } from "../../HeaderAnalyst/HeaderAnalyst";
import { PageContainer } from "../../__containers/PageContainer/PageContainer";

type Tabs = {
    tabs?: ReactNode;
    grayMode?: boolean;
};

type AnalistLayoutProps = {
    children: ReactNode | ReactNode[];
    isLoading?: boolean;
    tabsOptions?: Tabs;
    goBack?: () => void;
    avatar: AvatarModel;
    rating: RatingModel;
    userType?: UserType;
    breadCrums?: BreadcrumbModel[];
    filters?: ReactNode;
    entityType?: AnalystEntityType;
    dates?: AnalystDates;
    widthVariant?: "full" | "small";
};

const AnalistLayout: FC<AnalistLayoutProps> = ({
    isLoading,
    children,
    tabsOptions,
    goBack,
    avatar,
    rating,
    userType = USER_TYPE.WORKER,
    breadCrums,
    filters,
    entityType,
    dates,
    widthVariant = "full",
}) => {
    const { width } = useWindowSize();

    return (
        <PageContainer
            fullWidth={widthVariant === "full"}
            fullHeight
            header={
                <TabHeader
                    breadCrums={breadCrums}
                    showHeader={true}
                    tabs={tabsOptions?.tabs}
                    grayMode={tabsOptions?.grayMode}
                    isBreadcrumbLoading={isLoading}
                    flexColumn={!!filters && width >= screenSize.MOBILE}
                    paddingSize="sm"
                    fullWidth={widthVariant === "full"}
                >
                    <div className="reviewWorker__userAvatar">
                        {goBack && (
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                className="reviewWorker__userAvatar__arrowBack"
                                onClick={goBack}
                            />
                        )}
                        {isLoading ? (
                            <HeaderAnalystSkelleton />
                        ) : (
                            <HeaderAnalyst
                                avatar={{
                                    avatarName: avatar?.avatarName,
                                    colorId: avatar?.colorId,
                                    img: avatar?.img,
                                }}
                                rating={{ ...rating }}
                                userType={userType}
                                entityType={entityType}
                                dates={dates}
                            />
                        )}
                    </div>

                    {filters}
                </TabHeader>
            }
        >
            <div className="fullHeight">{children}</div>
        </PageContainer>
    );
};
export default AnalistLayout;
