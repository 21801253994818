import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";
import { PatchNotesGrid } from "./PatchNotesGrid/PatchNotesGrid";

export const PatchNotesPage: FC = () => {
    return (
        <Routes>
            <Route path={""} element={<PrivateRoute component={PatchNotesGrid} viewCode={SecScreen.LICENSES} />} />
        </Routes>
    );
};
