import { WizardLicenseModel } from "app/components_v2/wizards/WizardLicense/types";
import { CustomerInstanceModel } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";

export const WizardLicenseModelToInstanceWizardModel = ({
    adminUserCode,
    adminEmail,
    adminFirstName,
    adminLastName,
    adminUsername,
    contactEmail,
    contactName,
    instanceName,
    permisions,
    phoneNumber,
    initials,
    customerType,
}: WizardLicenseModel): CustomerInstanceModel => {
    return {
        id: 0,
        contactName,
        email: contactEmail,
        name: instanceName,
        phoneNumber,
        modules: permisions,
        isActive: true,
        user: {
            userCode: adminUserCode,
            email: adminEmail,
            firstName: adminFirstName,
            lastName: adminLastName,
            username: adminUsername,
        },
        initials,
        isOnBoarding: true,
        customerType,
    };
};
