import { FC, useEffect, useRef, useState } from "react";
import { Crumb } from "./Crumb";
import { useGoBack } from "app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { ThreeDotsCrumb } from "./ThreeDotsCrumb";
import { useLocation } from "react-router-dom";
import { BreadcrumbsSkeleton } from "../__skelletons/BreadcrumbsSkeleton/BreadcrumbsSkeleton";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

export type BreadcrumbModel = {
    name: string;
    to: string;
};

type Props = {
    crumbs: BreadcrumbModel[];
    goBackUrl?: string;
    goBackFn?: () => void;
    isLoading?: boolean;
    variant?: TabHeaderVariants;
};

export const BreadCrumbs: FC<Props> = ({ crumbs, goBackUrl, goBackFn, isLoading, variant = "primary" }) => {
    const { goBack, handleGoBack } = useGoBack();
    const [showThreeDots, setShowThreeDots] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const location = useLocation();
    const isLastCrumb = (currentCrumb: number) => crumbs.length - 1 !== currentCrumb;

    const onClickBack = () => {
        if (crumbs.length === 1) {
            handleGoBack(crumbs[0].to);
            return;
        }
        goBackUrl ? handleGoBack(goBackUrl) : !!goBackFn ? goBackFn() : goBack();
    };

    const isThreeDotsVisible = (): boolean => {
        let breadcrumbTotalWidth = listRef.current?.offsetWidth;
        let listWidth = ref.current?.offsetWidth;
        let listMovedToLeft = ref.current?.scrollLeft;
        if (
            listWidth === undefined ||
            listMovedToLeft === undefined ||
            breadcrumbTotalWidth === undefined ||
            breadcrumbTotalWidth <= listWidth
        )
            return false;
        const currentWidth = listWidth + listMovedToLeft;
        return breadcrumbTotalWidth !== currentWidth;
    };

    useEffect(() => {
        setShowThreeDots(isThreeDotsVisible());
    }, [location.pathname, crumbs]);

    return (
        <div className="breadcrumbsContent">
            {isLoading ? (
                <BreadcrumbsSkeleton />
            ) : (
                <>
                    <div
                        className="breadcrumbs"
                        ref={ref}
                        onWheel={(e) => {
                            if (ref.current != null) ref.current.scrollLeft += e.deltaY;
                        }}
                        onScroll={() => {
                            setShowThreeDots(isThreeDotsVisible());
                        }}
                    >
                        <ul className="breadcrumbs__container" ref={listRef}>
                            {crumbs.length === 1 ? (
                                <div className="breadcrumbs__container__oneLevel">
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        className="breadcrumbs__goBack"
                                        onClick={onClickBack}
                                    />
                                    <Crumb
                                        isLastCrumb
                                        alone
                                        name={crumbs[0].name}
                                        to={crumbs[0].to}
                                        variant={variant}
                                    />
                                </div>
                            ) : (
                                crumbs.map(({ name, to }, i) => (
                                    <Crumb key={name + i} isLastCrumb={isLastCrumb(i)} name={name} to={to} />
                                ))
                            )}
                        </ul>
                    </div>
                    <ThreeDotsCrumb
                        onWindowSizeChange={() => setShowThreeDots(isThreeDotsVisible())}
                        showThreeDots={showThreeDots}
                    />
                </>
            )}
        </div>
    );
};
