import React, { useRef, useEffect, useState } from "react";

type DataSet = {
    label: string;
    data: number[];
};

interface LineChartProps {
    dataSets: DataSet[];
    width: number;
    height: number;
    pointsToShow: number;
}

const colors = ["#3498db", "#e74c3c", "#2ecc71", "#9b59b6", "#f1c40f"]; // Define some colors for the lines

export const LineChartCustom: React.FC<LineChartProps> = ({ dataSets, width, height, pointsToShow }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [visibleLines, setVisibleLines] = useState<boolean[]>(dataSets.map(() => true));
    const [startIndex, setStartIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startDragX, setStartDragX] = useState(0);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartDragX(e.clientX);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;

        const diff = startDragX - e.clientX;
        const pointsPerPixel = dataSets[0].data.length / width;
        const diffIndex = Math.round(diff * pointsPerPixel);

        setStartIndex((prevIndex) => {
            const maxIndex = dataSets[0].data.length - pointsToShow;
            return Math.max(0, Math.min(prevIndex + diffIndex, maxIndex));
        });

        setStartDragX(e.clientX);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        const handleMouseUpGlobal = () => {
            setIsDragging(false);
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUpGlobal);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUpGlobal);
        };
    }, [handleMouseMove]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");

        if (ctx && canvas) {
            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Set chart dimensions and padding
            const chartWidth = width - 80; // Adjusted padding for y-axis labels
            const chartHeight = height - 60;
            const paddingLeft = 60;
            const paddingBottom = 40;

            // Find the maximum value in all datasets
            const visibleData = dataSets.map((dataSet) => dataSet.data.slice(startIndex, startIndex + pointsToShow));
            const allData = visibleData.flatMap((dataSet) => dataSet);
            const maxValue = Math.max(...allData);

            // Draw the x and y axes
            ctx.beginPath();
            ctx.moveTo(paddingLeft, 20);
            ctx.lineTo(paddingLeft, chartHeight + 20);
            ctx.lineTo(chartWidth + paddingLeft, chartHeight + 20);
            ctx.stroke();

            // Draw the y-axis values
            const yAxisSteps = 5;
            for (let i = 0; i <= yAxisSteps; i++) {
                const yValue = maxValue - (maxValue / yAxisSteps) * i;
                const y = 20 + (chartHeight / yAxisSteps) * i;
                ctx.fillText(yValue.toFixed(0), paddingLeft - 40, y + 5); // Adjusted position for y-axis labels
                ctx.beginPath();
                ctx.moveTo(paddingLeft - 5, y);
                ctx.lineTo(paddingLeft, y);
                ctx.stroke();
            }

            // Draw the x-axis values
            const xAxisSteps = pointsToShow - 1;
            visibleData[0].forEach((_, i) => {
                const x = paddingLeft + (i / xAxisSteps) * chartWidth;
                ctx.fillText((i + startIndex).toString(), x, chartHeight + paddingBottom + 5); // Adjusted position for x-axis labels
                ctx.beginPath();
                ctx.moveTo(x, chartHeight + 20);
                ctx.lineTo(x, chartHeight + 25);
                ctx.stroke();
            });

            // Draw each dataset line
            dataSets.forEach((dataSet, dataSetIndex) => {
                if (visibleLines[dataSetIndex]) {
                    ctx.beginPath();
                    dataSet.data.slice(startIndex, startIndex + pointsToShow).forEach((point, index) => {
                        const x = paddingLeft + (index / xAxisSteps) * chartWidth;
                        const y = 20 + (1 - point / maxValue) * chartHeight;
                        if (index === 0) {
                            ctx.moveTo(x, y);
                        } else {
                            ctx.lineTo(x, y);
                        }
                    });
                    ctx.strokeStyle = colors[dataSetIndex % colors.length]; // Use different color for each dataset
                    ctx.stroke();
                }
            });
        }
    }, [dataSets, width, height, visibleLines, startIndex, pointsToShow]);

    const toggleLineVisibility = (index: number) => {
        setVisibleLines((prevVisibleLines) => {
            const newVisibleLines = [...prevVisibleLines];
            newVisibleLines[index] = !newVisibleLines[index];
            return newVisibleLines;
        });
    };

    return (
        <div className="line-chart-container">
            <canvas
                className="lineChart"
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                style={{ cursor: isDragging ? "grabbing" : "grab" }}
            />
            <div className="lineChart__legend">
                {dataSets.map((dataSet, index) => (
                    <div
                        key={index}
                        className="lineChart__legend-item"
                        onClick={() => toggleLineVisibility(index)}
                        style={{ color: visibleLines[index] ? colors[index % colors.length] : "#ccc" }}
                    >
                        <span
                            className="lineChart__legend-color"
                            style={{ backgroundColor: visibleLines[index] ? colors[index % colors.length] : "#ccc" }}
                        ></span>
                        {dataSet.label}
                    </div>
                ))}
            </div>
        </div>
    );
};
