import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import { NewDashboard } from "./NewDashboard/NewDashboard";
import { QrChecker } from "./QrChecker/QrChecker";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";

export const DashboardPage: FC = () => {
    return <Dashboard />;
};

export const Dashboard: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<NewDashboard />} />
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={"/"} />} />
            <Route path={"/:taskInstanceId"} element={<NewDashboard />} />
            {hasPermission(SecScreen.SPORADIC_TASK) && <Route path={"/sporadic/:taskId"} element={<NewDashboard />} />}
            <Route path={"/qr/:workingPositionId"} element={<QrChecker />} />
        </Routes>
    );
};
