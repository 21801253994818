import { PublicPaths } from "app/shared/Constants";
import { Routes, Route, Navigate } from "react-router-dom";
import { FAQList } from "./FAQList/FAQList";

export const FAQPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route path="" element={<FAQList />} />
        </Routes>
    );
};
