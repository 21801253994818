import { ToastTypes, ToastVariants } from "app/components_v2/Toasts/toastModels";
import { useToast } from "app/hooks/Toast/useToast";
import { Section, SectionsShowProp } from "../Section/Section";
import { FC } from "react";
import { v4 } from "uuid";

export const ToastsSection: FC<SectionsShowProp> = ({ show }) => {
    const { handleToast, removeToast } = useToast();
    const handleToastClick = (
        type?: ToastTypes,
        variant?: ToastVariants,
        id?: string,
        isLoading?: boolean,
        subtitle?: boolean
    ) => {
        handleToast({
            type,
            variant,
            title: "We've just released a new feature",
            subtitle: subtitle
                ? "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam."
                : "",
            id,
            isLoading,
        });
    };

    const handlePromise = () => {
        const id = v4();
        handleToastClick("alert", "primary", id, true, false);
        new Promise(() =>
            setTimeout(() => {
                removeToast(id);
                const newId = v4();
                handleToastClick("alert", "primary", newId, false, false);
            }, 3000)
        );
    };

    return (
        <Section title="TOASTS" show={show}>
            <h2>Notification</h2>
            <button onClick={handlePromise}>Abrir toast promise</button>
            <button
                onClick={() => {
                    handleToastClick("alert", "primary", v4(), false);
                }}
            >
                Abrir toast Primary
            </button>
            <button
                onClick={() => {
                    handleToastClick("notification", "danger");
                }}
            >
                Abrir toast Danger
            </button>
            <button
                onClick={() => {
                    handleToastClick("notification", "warning");
                }}
            >
                Abrir toast warning
            </button>
            <button
                onClick={() => {
                    handleToastClick("notification", "success");
                }}
            >
                Abrir toast success
            </button>
            <button
                onClick={() => {
                    handleToastClick("notification", "none");
                }}
            >
                Abrir toast sin icon
            </button>
            <h2>Alert</h2>
            <button
                onClick={() => {
                    handleToastClick("alert", "primary");
                }}
            >
                Abrir toast Primary
            </button>
            <button
                onClick={() => {
                    handleToastClick("alert", "danger");
                }}
            >
                Abrir toast danger
            </button>
            <button onClick={() => handleToastClick("alert", "warning")}>Abrir toast warning</button>
            <button
                onClick={() => {
                    handleToastClick("alert", "success");
                }}
            >
                Abrir toast success
            </button>
            <button
                onClick={() => {
                    handleToastClick("alert", "none");
                }}
            >
                Abrir toast none
            </button>
        </Section>
    );
};
