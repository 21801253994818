import { FC } from "react";
import { IssueHistory } from "./IssueHistory/IssueHistory";
import Spinner from "../Spinner/Spinner";
import { useIssue } from "./hooks/useIssue";
import { TabHeaderVariants } from "../__containers/TabHeader/types";
import { AuditIssueType } from "app/shared/types/IssueTypes";
import { IssueBody } from "./IssueBody/IssueBody";

type IssueProps = {
    id: number;
    variant?: TabHeaderVariants;
    type?: AuditIssueType;
};

export const Issue: FC<IssueProps> = ({ id, variant = "primary", type = "ISSUE" }) => {
    const { issue, isLoading, handleAddNewAttachments } = useIssue({ id, type });

    if (isLoading || !issue)
        return (
            <div className="issue__spinner">
                <Spinner variant={variant} />
            </div>
        );

    return (
        <div className="issue">
            <IssueBody issue={issue} onAddNewAttachments={handleAddNewAttachments} variant={variant} />
            <IssueHistory issue={issue} variant={variant} />
        </div>
    );
};
