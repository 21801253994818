import { IAction } from "./stateModels";
import { initialState } from "./reducer";
import { LocalStorageSession, ReduxSession, TokenAndRefreshTokenExpirationModel } from "app/models/SessionModel";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { ChangeCompanyModel } from "app/models/ChangeCompanyModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";

export enum ActionType {
    LOG_OUT_RESET_STATE = "LOG_OUT_RESET_STATE",
    SET_SESSION = "SET_SESSION",
    COLAPSE_MENU = "COLAPSE_MENU",
    SET_CUSTOMER_INSTANCE_ID = "SET_CUSTOMER_INSTANCE_ID",
    SET_AVATAR_URL = "SET_AVATAR_URL",
    SET_IMPERSONATE_MODE = "SET_IMPERSONATE_MODE",
    OUT_IMPERSONATE_MODE = "OUT_IMPERSONATE_MODE",
    SWITCH_WIZARD_MODE = "SWITCH_WIZARD_MODE",
    SET_COMPANY_CONFIG = "SET_COMPANY_CONFIG",
    SWITCH_COMPANY_MODE = "SWITCH_COMPANY_MODE",
    UPDATE_SESSION = "UPDATE_SESSION",
    SET_HEADER_MOBILE = "SET_HEADER_MOBILE",
    SET_OPEN_SIDEBAR_MOBILE = "SET_OPEN_SIDEBAR_MOBILE",
    UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE",
    UPDATE_LOGGED_IN = "UPDATE_LOGGED_IN",
    UPDATE_QR_CODE = "UPDATE_QR_CODE",
    UPDATE_IS_ACTIVATION_PENDING = "UPDATE_IS_ACTIVATION_PENDING",
    UPDATE_DO_NOT_DISTURB = "UPDATE_DO_NOT_DISTURB",
    UPDATE_IS_PRIVACY_ACCEPTED = "UPDATE_IS_PRIVACY_ACCEPTED",
    UPDATE_POST_MESSAGE = "UPDATE_POST_MESSAGE",
    CHANGE_COMPANY = "CHANGE_COMPANY",
    UPDATE_TOKEN = "UPDATE_TOKEN",
    UPDATE_TOKEN_TIME = "UPDATE_TOKEN_TIME",
    UPDATE_USER_SESION = "UPDATE_USER_SESION",
    UPDATE_IS_ONBOARDING = "UPDATE_IS_ONBOARDING",
    UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
}

export const Actions = {
    resetAppState: (): IAction => {
        return {
            type: ActionType.LOG_OUT_RESET_STATE,
            payload: initialState,
        };
    },
    setAppSession: (session: ReduxSession): IAction => {
        return {
            type: ActionType.SET_SESSION,
            payload: session,
        };
    },
    collapseMenu: (colapse: boolean): IAction => {
        return {
            type: ActionType.COLAPSE_MENU,
            payload: colapse,
        };
    },
    setCustomerInstance: (id?: number): IAction => {
        return {
            type: ActionType.SET_CUSTOMER_INSTANCE_ID,
            payload: id,
        };
    },
    setAvataUrl: (url?: string): IAction => {
        return {
            type: ActionType.SET_AVATAR_URL,
            payload: url,
        };
    },
    setImpersonateMode: (session: ReduxSession): IAction => {
        return {
            type: ActionType.SET_IMPERSONATE_MODE,
            payload: session,
        };
    },
    outImpersonateMode: (): IAction => ({
        type: ActionType.OUT_IMPERSONATE_MODE,
        payload: undefined,
    }),
    switchWizardMode: (value: boolean): IAction => ({
        type: ActionType.SWITCH_WIZARD_MODE,
        payload: value,
    }),
    // Jony's new Actions
    setCompanyConfig: (userCompany: UserCompanyModel): IAction => ({
        type: ActionType.SET_COMPANY_CONFIG,
        payload: userCompany,
    }),
    switchCompanyMode: (value: boolean): IAction => ({
        type: ActionType.SWITCH_COMPANY_MODE,
        payload: value,
    }),
    updateSession: (session: ReduxSession): IAction => ({
        type: ActionType.UPDATE_SESSION,
        payload: session,
    }),
    setOpenSidebarMobile: (payload: boolean): IAction => ({
        type: ActionType.SET_OPEN_SIDEBAR_MOBILE,
        payload: payload,
    }),
    updateImageProfile: (image: string): IAction => ({
        type: ActionType.UPDATE_PROFILE_IMAGE,
        payload: image,
    }),
    updateLoggedIn: (isLoggedIn: boolean): IAction => ({
        type: ActionType.UPDATE_LOGGED_IN,
        payload: isLoggedIn,
    }),
    updateQrCode: (qrCode: string): IAction => ({
        type: ActionType.UPDATE_QR_CODE,
        payload: qrCode,
    }),
    updateIsActivationPending: (isActivationPending: boolean): IAction => ({
        type: ActionType.UPDATE_IS_ACTIVATION_PENDING,
        payload: isActivationPending,
    }),
    updateDoNotDisturb: (doNotDisturb: boolean): IAction => ({
        type: ActionType.UPDATE_DO_NOT_DISTURB,
        payload: doNotDisturb,
    }),
    updateIsPrivacyAccepted: (isPrivacyAccepted: boolean): IAction => ({
        type: ActionType.UPDATE_IS_PRIVACY_ACCEPTED,
        payload: isPrivacyAccepted,
    }),
    updatePostMessage: (postMessageValue: LocalStorageSession): IAction => ({
        type: ActionType.UPDATE_POST_MESSAGE,
        payload: postMessageValue,
    }),
    changeCompany: (companyValues: ChangeCompanyModel): IAction => ({
        type: ActionType.CHANGE_COMPANY,
        payload: companyValues,
    }),
    updateToken: (token: string): IAction => ({
        type: ActionType.UPDATE_TOKEN,
        payload: token,
    }),
    updateTokenTime: (expirationDates: TokenAndRefreshTokenExpirationModel): IAction => ({
        type: ActionType.UPDATE_TOKEN_TIME,
        payload: expirationDates,
    }),
    updateUserSession: (user: UserModel): IAction => ({
        type: ActionType.UPDATE_USER_SESION,
        payload: user,
    }),
    updateIsOnBoarding: (isOnBoarding: boolean): IAction => ({
        type: ActionType.UPDATE_IS_ONBOARDING,
        payload: isOnBoarding,
    }),
    updateLanguage: (language: string): IAction => ({
        type: ActionType.UPDATE_LANGUAGE,
        payload: language,
    }),
};
