import { TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useUserType } from "./useUserType";

export const useGetPageTitle = (): string => {
    const { isSupervisorOrAnalist } = useUserType();
    const { t } = useTranslation();
    let pageTitle = "";

    const { pathname } = useLocation();
    if (pathname.includes("review-photos")) pageTitle = t(TranslationTitles.REVIEW_TASKS_TITLE);

    if (pathname.includes("review-feedback")) pageTitle = t(TranslationTitles.REVIEW_FEEDBACK_TITLE);
    if (pathname.includes("alert-notification"))
        pageTitle = !isSupervisorOrAnalist
            ? t(TranslationTitles.RECEIVED_ALERTS_TITLE)
            : t(TranslationTitles.ALERTS_PAGE_TITLE);
    return pageTitle;
};
