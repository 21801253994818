import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";

export default class PatchNoteVersionService {
    public static async GetOne(id: number) {
        return FetchService.get<PatchNoteVersionModel>({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion/${id}`,
        });
    }

    public static async GetData(parms?: PaginationParams) {
        return FetchService.get<PaginatedResult<PatchNoteVersionModel>>({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion`,
            paginationParams: parms,
        });
    }

    public static async Save(body: PatchNoteVersionModel) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion`,
            body,
        });
    }

    public static async SendPatchNotes(id: number) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion/SendPatchNotes/${id}`,
        });
    }

    public static async Edit(id: number, body: PatchNoteVersionModel) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion/${id}`,
            body,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/PatchNoteVersion/${id}`,
        });
    }
}
