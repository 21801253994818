import { isDropdownDynamicField } from "app/helpers/dynamicFields/isDropdownDynamicField";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import { useToast } from "app/hooks/Toast/useToast";
import { TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { OptionsSearch } from "app/models/FormComponentsModel";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { t } from "i18next";
import { FormEvent, useState } from "react";
import { INITIAL_BASE_TASK_VALIDATIONS } from "../constants/editBaseTaskConstants";
import { BaseTaskFormModel, BaseTaskValidationModel } from "../models/EditBaseTaskFormModels";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { DateRange } from "app/models/utilities";
import { validateHours } from "app/helpers/__validates/validateTaskHours";
import { taskHoursModel } from "app/models/02-TAR/TaskWizard";

export const useEditBaseTaskErrors = (
    dataTask: BaseTaskFormModel,
    selectedBaseTaskType: OptionsSearch,
    selectedPlanAPPCC: OptionsSearch,
    requiredPlanAPPCC: boolean
) => {
    const { handleToast } = useToast();
    const { filterTaskFieldsWithoutErrorMessage, filterTaskCheckListWithoutErrorMessage, taskfieldOptionsDropdown } =
        useDynamicFieldsErrorHandler();

    const [validations, setValidations] = useState<BaseTaskValidationModel>(INITIAL_BASE_TASK_VALIDATIONS);

    const handleErrorsChange = (values: Partial<BaseTaskValidationModel>) => {
        setValidations((prev) => ({ ...prev, ...values }));
    };

    // Comprueba que las horas sean diferentes
    const isHoursEquals = (hours: taskHoursModel[]) => {
        for (let i = 0; i < hours.length; i++) {
            for (let x = 0; x < hours.length; x++) {
                if (i === x) continue;
                if (
                    new Date(hours[i].hour).getMinutes() === new Date(hours[x].hour).getMinutes() &&
                    new Date(hours[i].hour).getHours() === new Date(hours[x].hour).getHours()
                )
                    return true;
            }
        }

        return false;
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;

        if (!dataTask.taskTitle.length) {
            handleErrorsChange({ taskTitle: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!selectedBaseTaskType.label.length) {
            handleErrorsChange({ baseTaskType: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (requiredPlanAPPCC && !selectedPlanAPPCC.label.length) {
            handleErrorsChange({ planAPPCC: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (dataTask.reportType === "DYNAMIC_FIELD") {
            const dynamicFields = dataTask.dynamicFields.filter(({ isDeleted }) => !isDeleted);
            if (!dynamicFields.length) {
                handleErrorsChange({ reportData: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
                check = false;
            }

            const dynamicFieldsErrors = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);

            if (dynamicFieldsErrors.length) check = false;
        }

        let checklistErrors = [];
        if (dataTask.reportType === "CHECKLIST") {
            const checklist = dataTask.checkList.filter(({ isDeleted }) => !isDeleted);

            const errorChecklist: TaskfieldOptionsError[] = checklist
                .map(({ id, name }) => ({
                    errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                    id,
                }))
                .filter(filterTaskCheckListWithoutErrorMessage);
            checklistErrors = errorChecklist;

            if (checklistErrors.length) check = false;
        }
        // const isValidTemporality = validateTemporality();
        // check = isValidTemporality ? check : false;

        if (!check) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                subtitle: t(TranslationModals.FAILED_SAVE_TASK_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }

        return true;
    };

    const validateTemporality = (): boolean => {
        if (dataTask.temporalityType === "PERIODICAL") return validatePeriodicalTemporality();
        if (dataTask.temporalityType === "ONE_DAY") return validateOneDayTemporality();
        if (dataTask.temporalityType === "SPORADIC") return validateSporadicTemporality();
        if (dataTask.temporalityType === "RANGE_DATES") return validateDateRangeTemporality();
        return true;
    };

    const validatePeriodicalTemporality = (): boolean => {
        let check = true;

        if (!dataTask.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        if (dataTask.finish.checked === "el" && !dataTask.finish.value) {
            handleErrorsChange({ finishEl: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (
            dataTask.finish.checked === "after" &&
            typeof dataTask.finish.value === "number" &&
            dataTask.finish.value <= 0
        ) {
            handleErrorsChange({ finishResp: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }
        if (!dataTask.recurringTask) return check;

        const taskHoursErrors = validateHours(dataTask.taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        if (isHoursEquals(dataTask.taskHours)) {
            handleErrorsChange({ taskHours: t(TranslationKeys.INVALID_HOURS_SAME_HOURS) });
            check = false;
        }
        if (
            dataTask.recurringTask.isRecurrent &&
            dataTask.recurringTask.value === "custom" &&
            dataTask.recurringTask.custom.repeatEvery <= 0
        ) {
            handleErrorsChange({ customReps: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        if (
            dataTask.recurringTask.isRecurrent &&
            dataTask.recurringTask.value === "custom" &&
            dataTask.recurringTask.custom.customValue === "week" &&
            !dataTask.recurringTask.custom.days.some(({ isActive }) => isActive === true)
        ) {
            handleErrorsChange({ customDaysBubles: t(TranslationKeys.SELECT_ONE_DAY) });
            check = false;
        }

        if (
            dataTask.recurringTask.isRecurrent &&
            dataTask.recurringTask.value === "custom" &&
            dataTask.recurringTask.custom.customValue === "everyMonth" &&
            !dataTask.recurringTask.custom.selectedOptions.value.length
        ) {
            handleErrorsChange({ customMonth: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }
        return check;
    };

    const validateOneDayTemporality = (): boolean => {
        let check = true;
        if (!dataTask.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        const taskHoursErrors = validateHours(dataTask.taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        return check;
    };

    const validateSporadicTemporality = (): boolean => {
        let check = true;

        if (!dataTask.taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        return check;
    };

    const validateDateRangeTemporality = (): boolean => {
        let check = true;
        const taskTemporalityDateRangeFiltered = dataTask.taskTemporalityDateRange.filter(
            ({ isDeleted }) => !isDeleted
        );
        if (!taskTemporalityDateRangeFiltered.length) {
            handleErrorsChange({ dateRange: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }
        const taskTemporalityDateRange: DateRange[] = taskTemporalityDateRangeFiltered.map(({ startDate, endDate }) => [
            new Date(startDate),
            new Date(endDate),
        ]);
        if (hasOverlappingRanges(taskTemporalityDateRange)) {
            handleErrorsChange({ dateRange: t(TaskTranslations.DATE_OVERLAPING) });
            check = false;
        }
        return check;
    };

    return {
        validations,
        validate,
    };
};
