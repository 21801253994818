export const isChatRoom = () => {
    const patronId = /\/\d+/;
    const isDeptOrSubDept = /^[SD]\d.*$/;
    const deptSubDeptId =
        window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    return (
        window.location.pathname.includes("chat") &&
        (patronId.test(window.location.pathname) || isDeptOrSubDept.test(deptSubDeptId))
    );
};
