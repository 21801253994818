import { CounterPillType } from "app/components_v2/RankingPill/types";

export const getTaskType = (status: string, total: number): CounterPillType => {
    return total === 0
        ? "default"
        : status === "TIMED_OUT"
        ? "danger"
        : status === "OUT_OF_TIME"
        ? "yellow"
        : "success";
};
