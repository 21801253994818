import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicPaths } from "../shared/Constants";
import MainRouter from "./MainRouter";
import WentWrong from "app/components_v2/Errors/WentWrong";
import { MainProvider } from "app/state/Providers/MainProvider";
import { useMaintenance } from "app/hooks/useMaintenance";
import Spinner from "app/components_v2/Spinner/Spinner";

const AppRoutes: FC = () => {
    const { isAppUnderMaintenance, isLoading } = useMaintenance();
    if (isLoading)
        <div className="appRoutesSpinner">
            <Spinner />
        </div>;

    return (
        <MainProvider>
            <BrowserRouter>
                <Routes>
                    {isAppUnderMaintenance ? (
                        <Route path={PublicPaths.ROOT} element={<WentWrong errorType="underMainteniance" />} />
                    ) : (
                        <Route path={PublicPaths.ROOT} element={<MainRouter />} />
                    )}
                </Routes>
            </BrowserRouter>
        </MainProvider>
    );
};

export default AppRoutes;
