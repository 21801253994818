import { OptionsSearch } from "app/models/FormComponentsModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class SenSelectorService {
    // GET all Gateways
    public static async GetGateways() {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEN_API}/SenSelector/GetGateways`,
        });
    }

    // GET all Sensors
    public static async GetSensors() {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEN_API}/SenSelector/GetSensors`,
        });
    }

    // GET all SensorEntries Assets
    public static async GetSensorEntriesAssets(paginationParams?: PaginationParams) {
        return FetchService.get<OptionsSearch[]>({
            url: `${ApiPaths.SEN_API}/SenSelector/SensorEntriesAssets`,
            paginationParams,
        });
    }
}
