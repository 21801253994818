import { useState } from "react";
import { AssetStatusPostScanned } from "../../types";
import { useSensorQr } from "app/hooks/Sensor/useSensorQr";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import AssetService from "app/services/05-QUA/AssetService";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { SensorModalTranslations, TranslationKeys } from "app/translation/translationKeys";

type IuseSensorLinkedModal = {
    asset: AssetModel2;
    onClose: () => void;
};

export const useSensorLinkedModal = ({ asset, onClose }: IuseSensorLinkedModal) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [showDeactivateAssetModal, setShowDeactivateAssetModal] = useState<boolean>(false);
    const [isAssetDeleting, setIsAssetDeleting] = useState<boolean>(false);
    const [showReplaceModal, setShowReplaceModal] = useState<boolean>(false);
    const [assetStatusPostScanned, setAssetStatusPostScanned] = useState<AssetStatusPostScanned | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isAssetReplacingLoading, setIsAssetReplacingLoading] = useState<boolean>(false);
    const [qrCodeScanned, setQrCodeScanned] = useState<string | null>(null);

    const { getAsset, asset: assetSearched } = useSensorQr({
        onError: () => {
            setShowReplaceModal(false);
            setAssetStatusPostScanned(null);
            setShowConfirmModal(false);
            setQrCodeScanned(null);
        },
        onSuccess: (isLinked) => {
            setAssetStatusPostScanned(isLinked ? "LINKED" : "NOT-LINKED");
            setShowConfirmModal(true);
        },
    });

    const { isRelatedWithTasks, id } = asset;

    const handleReplaceSensor = async () => {
        if (assetStatusPostScanned === "LINKED") {
            await handleReplaceUsedSensor();
            return;
        }

        await handleReplaceUnusedSensor();
    };

    const handleReplaceUsedSensor = async () => {
        if (!assetSearched) return;
        setIsAssetReplacingLoading(true);
        const { status, getParsedError } = await AssetService.ReplaceAssetsSerialNumbers(
            asset.sensorSerialNumber,
            assetSearched?.sensorSerialNumber
        );
        if (!status()) {
            setShowConfirmModal(false);
            setShowReplaceModal(false);
            setIsAssetReplacingLoading(false);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        handleToast({
            title: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_LINKED_SUCCESS_REPLACE_TITLE),
            type: "alert",
            variant: "success",
        });
        setShowConfirmModal(false);
        setShowReplaceModal(false);
        setIsAssetReplacingLoading(false);
        onClose();
    };

    const handleReplaceUnusedSensor = async () => {
        if (!qrCodeScanned) return;
        setIsAssetReplacingLoading(true);
        const { status, getParsedError } = await AssetService.ReplaceAssetsSerialNumbers(
            asset.sensorSerialNumber,
            qrCodeScanned
        );
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setShowConfirmModal(false);
            setShowReplaceModal(false);
            setIsAssetReplacingLoading(false);
            return;
        }
        handleToast({
            title: t(SensorModalTranslations.SENSOR_LINKED_TO_SENSOR_NOT_LINKED_SUCCESS_REPLACE_TITLE),
            type: "alert",
            variant: "success",
        });
        setShowConfirmModal(false);
        setShowReplaceModal(false);
        setIsAssetReplacingLoading(false);
        onClose();
    };

    const handleScanSensorQr = async (qrCode: string) => {
        if (asset.assetId.toLocaleLowerCase() === qrCode.toLocaleLowerCase()) {
            handleToast({
                title: t(SensorModalTranslations.SENSOR_SAME_QR_ERROR_TITLE),
                type: "alert",
                variant: "danger",
            });
            setShowReplaceModal(false);
            return;
        }
        setQrCodeScanned(qrCode);
        await getAsset(qrCode);
    };

    const handleDeactivate = async () => {
        setIsAssetDeleting(true);
        const assetSr = await AssetService.Delete(id);
        if (!assetSr.status()) {
            handleToast({
                title: t(TranslationKeys.FAILED_DEACTIVE_ASSET),
                type: "alert",
                variant: "danger",
            });

            setIsAssetDeleting(false);

            return;
        }

        handleToast({
            title: t(TranslationKeys.SUCCESS_DEACTIVE_ASSET),
            type: "alert",
            variant: "success",
        });

        setIsAssetDeleting(false);
        onClose();
    };

    const getDeleteModalDescription = (): string => {
        if (isRelatedWithTasks) return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE);
        return t(TranslationKeys.ASSET_MODAL_DESCRIPTION_DEACTIVATE_NO_RELATED);
    };

    const handleCloseReplaceScanModal = () => {
        setShowConfirmModal(false);
        setShowReplaceModal(false);
    };

    return {
        showDeactivateAssetModal,
        handleDeactivate,
        getDeleteModalDescription,
        isAssetDeleting,
        showReplaceModal,
        handleScanSensorQr,
        showConfirmModal,
        assetStatusPostScanned,
        setShowDeactivateAssetModal,
        setShowReplaceModal,
        handleReplaceSensor,
        isAssetReplacingLoading,
        handleCloseReplaceScanModal,
    };
};
