import { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import {
    AUTH_ROUTES_TO_DO_NOT_DO_REFRESH,
    CookieKey,
    PrivatePaths,
    PublicPaths,
    refreshTokenInterval,
    SecScreen,
    Views,
} from "../shared/Constants";
import { LicensePage, MyProfilePage } from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, Actions } from "../state/actions";
import Cookies from "universal-cookie";
import { FetchService, ProfileService, SessionService, SignalRService } from "../services";
import { IAppState } from "../state/reducer";
import InitialLoadService from "app/services/01-SEG/InitialLoadService";
import { HeaderContext } from "app/components_v2/Header/context/headerContext";
import { SignalRContext } from "app/state/context/context";
import {
    clearLocalStorage,
    getStoreSession,
    setLocalStorageSession,
    setTokenAndRefreshTokenExpiration,
} from "app/helpers/BrowserStorage/LocalStorageHandler";
import { SelectDepartmentWizard } from "app/components_v2/PostLogin/wizard/SelectDepartmentWizard";
import { NotFound } from "app/pages/404/NotFound";
import ConfigApp from "./ConfigApp";
import isDeviceMobile from "app/helpers/isMobile";
import { ComponentStatus } from "app/models/FormComponentsModel";
import Loading from "app/components_v2/Loading/Loading";
import WentWrong from "app/components_v2/Errors/WentWrong";
import AppHeader from "app/components_v2/AppHeader/AppHeader";
import AcceptPolicyPage from "app/pages/02-TAR/13-TAR-AcceptPolicy/AcceptPolicyPage";
import { getAppSource } from "app/helpers/session/getAppSource";
import { AuthPage } from "app/pages/00-LOGIN/AuthPage";
import { LocalStorageSession, ReduxSession } from "app/models/SessionModel";
import { useSession } from "app/hooks";
import AuthService from "app/services/00-LOGIN/AuthService";
import { useUserType } from "app/hooks/useUserType";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IssueModal } from "app/components_v2/__modals/IssueModal/IssueModal";
import { PreviousPathNameContext } from "app/state/context/prevoiusPathNameContext/prevoiusPathNameContext";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { CloseCompanyModalContext } from "app/state/context/CloseCompanyModalContext/CloseCompanyModalContext";
import { CloseCompanyModal } from "app/components_v2/__modals/CloseCompanyModal/CloseCompanyModal";
import { OnBoardingPage } from "app/pages/01-SEG/OnBoarding/OnBoardingPage";
import isiOS from "app/helpers/isIos";
import { useVariant } from "app/hooks/useVariant";
import { changeLanguage } from "i18next";
import { useCloseCompaniesAndDepartmentsModal } from "app/hooks/CloseCompanyModal/useCloseCompaniesAndDepartmentsModal";
import { PatchNotesModalContext } from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";
import { SendPatchNotesModal } from "app/components_v2/__modals/SendPatchNotesModal/SendPatchNotesModal";
import PrivateRoute from "./PrivateRoute";
import { PatchNotesPage } from "app/pages/01-SEG/PatchNotes/PatchNotesPage";
import { PendingTasksCompanyDepartmentModalContext } from "app/state/context/PendingTasksCompanyDepartmentModalContext/PendingTasksCompanyDepartmentModalContext";
import { PendingTasksCompanyDepartmentModal } from "app/components_v2/__modals/PendingTasksCompanyDepartmentModal/PendingTasksCompanyDepartmentModal";
import { usePendingTasksCompanyDepartmentModal } from "app/hooks/PendingTasksCompanyDepartmentModal/usePendingTasksCompanyDepartmentModal";
import { isUserTypeWorker } from "app/helpers/userTypeCheck";

const MainRouter = () => {
    const nav = useNavigate();
    const session = useSession();
    const { isAdmin } = useUserType();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isOpen: isIssueModalOpen } = useContext(IssueModalContext);
    const { isOpen: isPendingTasksCompanyDepartmentModalOpen } = useContext(PendingTasksCompanyDepartmentModalContext);
    const { getPendingTasksCompanyDepartment } = usePendingTasksCompanyDepartmentModal();
    const { isOpen: isCloseCompanyModalOpen } = useContext(CloseCompanyModalContext);
    const { getClosedOpenCompaniesAndDepartments } = useCloseCompaniesAndDepartmentsModal();
    const { changeLastPathName, pathNames } = useContext(PreviousPathNameContext);
    const { isOpen: isPatchNotesVersionModalOpen } = useContext(PatchNotesModalContext);
    const isLoggedIn = useSelector<IAppState, boolean>((state) => state.isLoggedIn);

    const signalR = SignalRService.getSignalR();
    const cookies = new Cookies();
    const today = new Date();
    const inAWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    const queryParams = new URLSearchParams(location.search);
    const tokenUrl = queryParams.get("refreshToken");
    const refreshToken: string = cookies.get(CookieKey.REFRESH_TOKEN);
    const { variant } = useVariant();

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [token, setToken] = useState<string>("");
    const [refreshTokenLoaded, setRefreshTokenLoaded] = useState<boolean>(false);
    const [systemNotificationsCount, setSystemNotificationsCount] = useState<number>(0);
    const [reconnectSignalRAttempts, setReconnectSignalRAttempts] = useState<number>(0);
    const [signalRState, setSignalRState] = useState<boolean>(false);
    const [isSignalRConnecting, setisSignalRConnecting] = useState<boolean>(true);
    const [newSignalRConnectionAttempt, setNewSignalRConnectionAttempt] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const generateCookie = () => {
        const localStorageSession = getStoreSession();

        if (!tokenUrl) return;
        document.cookie = `${CookieKey.REFRESH_TOKEN}=${tokenUrl}; path=/; expires=${
            localStorageSession?.refreshTokenExpiration
                ? new Date(localStorageSession?.refreshTokenExpiration)
                : inAWeek
        }`;
    };

    const deleteFirstCookieIfMultipleExist = () => {
        const cookies = document.cookie.split(";");
        if (cookies.length > 1) {
            const firstCookie = cookies[0].split("=")[0].trim();
            document.cookie = `${firstCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    };

    const getNewCookie = (): string => {
        deleteFirstCookieIfMultipleExist();
        generateCookie();
        const cookie: string = cookies.get("refreshToken");
        deleteFirstCookieIfMultipleExist();
        return cookie || "error";
    };

    const storeQrCodeLocalStorage = () => {
        const pathSplitted = location.pathname.split("/").filter((x) => x);
        const qr = pathSplitted.pop();
        const currentPath = pathSplitted.join("/");
        const dashboardQrPath = `${PrivatePaths.DASHBOARD}/qr`;
        if (currentPath.toLowerCase() === dashboardQrPath.toLowerCase() && qr) {
            localStorage.setItem("qrCode", qr);
        }
    };

    const loadRefreshToken = async () => {
        const isSource = !!session?.appSource;
        storeQrCodeLocalStorage();
        const localStorageSession = getStoreSession();
        if (getNewCookie() !== "error") {
            const refreshSr = await AuthService.RefreshToken(
                {
                    browserFingerprint: "web",
                },
                getNewCookie()
            );

            document.cookie = `${CookieKey.REFRESH_TOKEN}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;

            if (refreshSr.status()) {
                setToken(refreshSr.data.token);

                document.cookie = `${CookieKey.REFRESH_TOKEN}=${refreshToken}; path=/; expires=${
                    localStorageSession?.refreshTokenExpiration
                        ? new Date(localStorageSession?.refreshTokenExpiration)
                        : inAWeek
                }`;

                FetchService.setToken(refreshSr.data.token);
                dispatch({ type: ActionType.UPDATE_TOKEN, payload: refreshSr.data.token });
                setRefreshTokenLoaded(true);
                setTokenAndRefreshTokenExpiration({
                    refreshTokenExpiration: localStorageSession?.refreshTokenExpiration || "",
                    tokenExpiration: new Date(refreshSr.data.tokenExpiration).toISOString(),
                });
            } else {
                if (isSource) {
                    nav("404");
                } else if (
                    !location.pathname.includes(PublicPaths.RECOVER_PASSWORD) &&
                    !location.pathname.includes(PublicPaths.CHANGE_PASSWORD)
                ) {
                    clearLocalStorage();
                    storeQrCodeLocalStorage();
                    nav(PublicPaths.LOGIN);
                    document.cookie = `${CookieKey.REFRESH_TOKEN}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
                }
                setStatus("complete");
                return;
            }
        } else {
            const pathname = location.pathname;
            if (pathname.includes(PublicPaths.CHANGE_PASSWORD)) {
                clearLocalStorage();
                storeQrCodeLocalStorage();
                setStatus("complete");
                return;
            }

            if (!refreshToken) {
                nav(`${redirectPublicPaths(location.pathname)}${location.search}`);
                setStatus("complete");
                return;
            }
            const refreshSr = await SessionService.RefreshToken(
                {
                    browserFingerprint: "web",
                },
                refreshToken
            );
            if (!refreshSr.status()) {
                clearLocalStorage();
                storeQrCodeLocalStorage();
                isSource ? nav("404") : nav(PublicPaths.LOGIN);
                setStatus("complete");
                return;
            }
            setToken(refreshSr.data.token);
            setRefreshTokenLoaded(true);
            setStatus("complete");
        }
    };

    const redirectPublicPaths = (path: string) => {
        if (path.includes(PublicPaths.LOGIN)) return PublicPaths.LOGIN;
        if (path.includes(PublicPaths.RECOVER_PASSWORD)) return PublicPaths.RECOVER_PASSWORD;
        if (path.includes(PublicPaths.CHANGE_PASSWORD)) return PublicPaths.CHANGE_PASSWORD;
        return PublicPaths.LOGIN;
    };

    const loadSignalR = async () => {
        await signalR.start();
        setReconnectSignalRAttempts(reconnectSignalRAttempts + 1);
        setNewSignalRConnectionAttempt(!newSignalRConnectionAttempt);
    };

    const initalAppLoad = async () => {
        let prevAppSource = getAppSource(getStoreSession()?.appSource);
        if (queryParams.has("source")) {
            prevAppSource = getAppSource(queryParams.get("source") || "");
        }
        if (signalR.SignalRConnection.connectionId) {
            Promise.all([
                ProfileService.Get(token),
                InitialLoadService.InitialLoad(token, signalR.SignalRConnection.connectionId),
            ])
                .then(([profileSr, initialAppLoadSr]) => {
                    if (profileSr.status() && initialAppLoadSr.status()) {
                        if (profileSr.data.customerInstanceId !== null && !isUserTypeWorker(profileSr.data.userType))
                            handleCompaniesAndDepartments();

                        const storeSession = getStoreSession();
                        const currentSession: ReduxSession = {
                            ...storeSession,
                            appSource: prevAppSource,
                            isActivationPending: profileSr.data.isActivationPending,
                            token: token,
                            roles: profileSr.data.permissions || [],
                            user: profileSr.data,
                            refreshTokenExpiration: storeSession?.refreshTokenExpiration || "",
                            tokenExpiration: storeSession?.tokenExpiration || "",
                            isOnBoarding: initialAppLoadSr.data.isOnBoarding,
                        };
                        const currentLocalStorageSession: LocalStorageSession = {
                            ...storeSession,
                            appSource: prevAppSource,
                            isActivationPending: profileSr.data.isActivationPending,
                            refreshTokenExpiration: storeSession?.refreshTokenExpiration || "",
                            tokenExpiration: storeSession?.tokenExpiration || "",
                        };
                        changeLanguage(profileSr.data.language);
                        dispatch(Actions.setAppSession(currentSession));
                        setLocalStorageSession(currentLocalStorageSession);
                        setSystemNotificationsCount(initialAppLoadSr.data.systemNotificationsCount);
                        setStatus("complete");
                        window ? nav(window.location.pathname) : nav("/" + PrivatePaths.DASHBOARD.split("/")[0]);
                    } else {
                        setStatus("errored");
                    }
                })
                .catch((e: Error) => {
                    console.error(e);
                    setStatus("errored");
                });
        }
    };

    const checkPrivacyAccepted = (user: UserModel) => {
        if (!user.isPrivacyAccepted) {
            nav(PrivatePaths.ACCEPT_POLICY);
        }
        if (pathNames[pathNames.length - 1] === location.pathname) return;
        changeLastPathName(location.pathname);
    };

    const checkPasswordChanged = (user: UserModel) => {
        if (user.isActivationPending) {
            nav(PublicPaths.NEW_PASSWORD);
        }
        if (pathNames[pathNames.length - 1] === location.pathname) return;
        changeLastPathName(location.pathname);
    };

    const handleCompaniesAndDepartments = async () => {
        const isOpened = await getClosedOpenCompaniesAndDepartments();
        if (!isOpened) await getPendingTasksCompanyDepartment();
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const localStorageSession = getStoreSession();
            if (!localStorageSession) return;
            const tokenExpirationString = localStorageSession.tokenExpiration;
            if (!tokenExpirationString || AUTH_ROUTES_TO_DO_NOT_DO_REFRESH.includes(location.pathname)) return;
            const tokenExpirationDate = new Date(tokenExpirationString);
            tokenExpirationDate.setMinutes(tokenExpirationDate.getMinutes() - 1);
            const now = new Date();
            if (tokenExpirationDate > now) return;
            loadRefreshToken();
        }, refreshTokenInterval);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setStatus("loading");
        loadRefreshToken();
    }, []);

    useEffect(() => {
        if (refreshTokenLoaded && !firstLoad) loadSignalR();
    }, [token, refreshTokenLoaded]);

    useEffect(() => {
        if (firstLoad) return;
        if (reconnectSignalRAttempts >= 4) {
            window.location.reload();
            return;
        }
        if (signalR.SignalRConnection.state === "Connected") {
            initalAppLoad();
            setReconnectSignalRAttempts(0);
            setisSignalRConnecting(false);
            return;
        }
        setTimeout(() => loadSignalR(), 1000);
    }, [newSignalRConnectionAttempt]);

    useEffect(() => {
        if (session && session.user) {
            checkPrivacyAccepted(session.user);
            if (session.user.isPrivacyAccepted) checkPasswordChanged(session.user);
        }
    }, [location.pathname]);

    useEffect(() => {
        isDeviceMobile() &&
            document.addEventListener("visibilitychange", function () {
                if (document.visibilityState === "visible") {
                    setSignalRState((prev) => !prev);
                    setisSignalRConnecting(true);
                }
            });
    }, []);

    useEffect(() => {
        if (!firstLoad) loadRefreshToken();
        setFirstLoad(false);
    }, [signalRState]);

    const handleClearAllNotifications = () => setSystemNotificationsCount(0);

    const getRoutes = () => {
        if (isLoggedIn && session?.isOnBoarding)
            return (
                <main className={`main_content ${isiOS() ? "main_content--mobile" : ""}`}>
                    <OnBoardingPage />
                </main>
            );

        return (
            <Routes>
                {!session?.user.customerInstanceId ? (
                    <>
                        <Route
                            path={PublicPaths.WILDCARD}
                            element={<Navigate to={`${PrivatePaths.LICENSE_PAGE}/*`} replace />}
                        />
                        <Route path={`${PrivatePaths.LICENSE_PAGE}/*`} element={<LicensePage />} />
                        <Route
                            path={`${PrivatePaths.PATCH_NOTES}/*`}
                            element={<PrivateRoute component={PatchNotesPage} viewCode={SecScreen.LICENSES} />}
                        />
                        <Route
                            path={`${PrivatePaths.MY_PROFILE}/*`}
                            element={<PrivateRoute component={MyProfilePage} viewCode={Views.MY_PROFILE} />}
                        />
                    </>
                ) : (
                    <>
                        {session?.user.companies?.length !== 0 && (
                            <Route path={PrivatePaths.SELECT_DEPARTMENT} element={<SelectDepartmentWizard />} />
                        )}
                        <Route path={PrivatePaths.ACCEPT_POLICY} element={<AcceptPolicyPage />} />
                        <Route
                            path="/404"
                            element={<NotFound errorType={session?.appSource ? "AppFailedToken" : "404"} />}
                        />
                        <Route path={PublicPaths.ROOT} element={<ConfigApp />} />
                    </>
                )}
            </Routes>
        );
    };

    if (status === "loading") return Loading();
    if (status === "errored") return <WentWrong errorType="500" />;
    if (isLoggedIn && session?.user.companies?.length === 0 && !isAdmin && !session.isOnBoarding)
        return <WentWrong errorType="no company" />;
    if (!isLoggedIn) return <AuthPage />;
    return (
        <HeaderContext.Provider
            value={{
                hasNotification: systemNotificationsCount > 0,
                handleClearAllNotifications,
            }}
        >
            <SignalRContext.Provider value={{ signalRState, isSignalRConnecting }}>
                <AppHeader
                    systemNotificationsCount={systemNotificationsCount}
                    setSystemNotificationsCount={setSystemNotificationsCount}
                >
                    {getRoutes()}
                </AppHeader>
                {isPatchNotesVersionModalOpen && <SendPatchNotesModal />}
                {isPendingTasksCompanyDepartmentModalOpen && <PendingTasksCompanyDepartmentModal />}
                {isCloseCompanyModalOpen && <CloseCompanyModal />}
                {isIssueModalOpen && <IssueModal variant={variant} />}
            </SignalRContext.Provider>
        </HeaderContext.Provider>
    );
};
export default MainRouter;
