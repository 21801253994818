import { useEffect, useState } from "react";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";

export const useTaskData = (hasPlanAPPCC: boolean, showPlanAPPCC = true) => {
    const { t } = useTranslation();

    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionsSearch[]>([]);

    const getPlans = async () => {
        if (!hasPlanAPPCC || !showPlanAPPCC) return;

        const { data, status } = await QuaSelectorService.GetPlansAPPCC();

        if (!status()) {
            setPlanAPPCCOptions([]);
            return;
        }

        setPlanAPPCCOptions([{ label: t(TranslationKeys.SELECT_A_PLAN), value: "-1" }, ...data]);
    };

    useEffect(() => {
        getPlans();
    }, []);

    return { planAPPCCOptions };
};
