import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { ReceiverNotificationDto } from "app/dtos/02-TAR/AlertNotification/ReceiverNotificationDto";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { Tag } from "app/components_v2/Tag/Tag";
import { AlertModel } from "../types";

type AlertNotificationModalCollapsablesProps = {
    currentAlert: AlertModel;
    seenUsers: ReceiverNotificationDto[];
    unSeenUsers: ReceiverNotificationDto[];
};

export const AlertNotificationModalCollapsables: FC<AlertNotificationModalCollapsablesProps> = ({
    currentAlert,
    seenUsers,
    unSeenUsers,
}) => {
    const { t } = useTranslation();

    if (!currentAlert?.receiverNotificationList?.length) return null;
    return (
        <>
            {!!seenUsers.length && (
                <Collapsable open={seenUsers.length < 3} title={t(TranslationKeys.READED)}>
                    <div className="wrap gap--8">
                        {seenUsers.map((user) => (
                            <Tag key={user.receiverUserName} message={user.receiverUserName || ""} variant={"green"} />
                        ))}
                    </div>
                </Collapsable>
            )}
            {!!unSeenUsers.length && (
                <Collapsable open={unSeenUsers.length < 3} title={t(TranslationKeys.NOT_READED)}>
                    <div className="wrap gap--8">
                        {unSeenUsers.map((user) => (
                            <Tag key={user.receiverUserName} message={user.receiverUserName || ""} variant={"red"} />
                        ))}
                    </div>
                </Collapsable>
            )}
        </>
    );
};
