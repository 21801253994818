import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

type MobilePlanRowBodyProps = {
    name: string;
    onEdit?: () => void;
    onDelete?: () => void;
};

export const MobilePlanRowBody = ({ name, onDelete, onEdit }: MobilePlanRowBodyProps) => {
    return (
        <div className="mobilePlanRowBody">
            <div className="mobilePlanRowBody__info">
                <p className="mobilePlanRowBody__name">{name}</p>
            </div>
            {hasPermissionToEdit(SecScreen.PLAN_APPCC) && (
                <div className="mobilePlanRowBody__icon" onClick={onEdit}>
                    <FontAwesomeIcon className="mobilePlanRowBody__icon__edit" icon={faPen} />
                </div>
            )}
            {hasPermissionToDelete(SecScreen.PLAN_APPCC) && (
                <div className="mobilePlanRowBody__icon" onClick={onDelete}>
                    <FontAwesomeIcon className="mobilePlanRowBody__icon__edit" icon={faTrash} />
                </div>
            )}
        </div>
    );
};
