import { Avatar } from "app/components_v2/Avatar/Avatar";
import { Badge } from "app/components_v2/Badge/Badge";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers";
import { IssueCorrectiveMeasureModel } from "app/models/05-QUA/IssueModels/IssueCorrectiveMeasureModel";
import { IssueStatusModel } from "app/models/05-QUA/IssueModels/IssueStatusModels";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { IssueTranslation } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type IssueHistoryPillProps = {
    issueStatus: IssueStatusModel;
    issueCorrectiveMeasures?: IssueCorrectiveMeasureModel[];
};

export const IssueHistoryPill: FC<IssueHistoryPillProps> = ({ issueStatus, issueCorrectiveMeasures }) => {
    const { t } = useTranslation();
    const { status, date, fk_User, user, comment, audioUrl } = issueStatus;
    const badgeTitle: Record<OpenCloseStatusCode, string> = {
        OPEN: t(IssueTranslation.ISSUE_HISTORY_OPEN).replace(
            "{0}",
            getFullDateWithTimeReadable(convertUTCtoLocaleDate(date).toString() || "")
        ),
        CLOSED: t(IssueTranslation.ISSUE_HISTORY_CLOSE).replace(
            "{0}",
            getFullDateWithTimeReadable(convertUTCtoLocaleDate(date).toString() || "")
        ),
    };
    const { firstName, lastName, profilePictureURL } = user;
    const userFullName = `${firstName} ${lastName}`;

    return (
        <div className="issueHistoryPill">
            <div className="issueHistoryPill__header">
                <Badge variant={status === "OPEN" ? "red" : "green"} title={badgeTitle[status]} />
                <Avatar
                    colorId={fk_User}
                    avatarName={userFullName}
                    size="xs"
                    img={profilePictureURL}
                    name={userFullName}
                />
            </div>
            <p className="issueHistoryPill__comment">{comment}</p>
            {!!audioUrl && (
                <audio className="issueHistoryPill___audio" controls>
                    <source src={audioUrl} type="audio/mpeg"></source>
                </audio>
            )}
            {!!issueCorrectiveMeasures?.length && (
                <ul className="issueHistoryPill__correctiveMeasures">
                    {issueCorrectiveMeasures.map(({ correctiveMeasure, id }) => (
                        <li key={id} className="issueHistoryPill__correctiveMeasure">
                            {correctiveMeasure}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
