import { FC } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { components, OptionProps } from "react-select";
import { Avatar } from "app/components_v2/Avatar/Avatar";

type OptionWithAvatarProps = OptionProps<OptionModel>;

export const OptionWithAvatar: FC<OptionWithAvatarProps> = ({ ...optionsProps }) => {
    const { data } = optionsProps;
    const { profilePictureURL, value, label, initials } = data;

    if (value === "-1" || value === "" || !initials?.length)
        return <components.Option {...optionsProps}></components.Option>;

    return (
        <components.Option {...optionsProps}>
            <div className={`optionsWithAvatar ${optionsProps.className}`}>
                <Avatar
                    colorId={Number(value)}
                    img={profilePictureURL}
                    size="xs"
                    avatarName={label}
                    nameInitials={initials}
                />
                {label}
            </div>
        </components.Option>
    );
};
