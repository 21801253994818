import { FC, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { usePatchNotesModal } from "./hooks/usePatchNotesModal";
import { isEqual } from "lodash";
import { PatchNotesTranslations, TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { mapFormValuesToPatchNote } from "./helpers/mapPatchNotes";
import { INITIAL_PATCH_NOTE_ERRORS } from "../../constants/PatchNotesConstants";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ActionType } from "app/models/FormComponentsModel";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { Input, OptionsOutsideSelect } from "app/components_v2/__inputs";
import Spinner from "app/components_v2/Spinner/Spinner";
import { Tag } from "app/components_v2/Tag/Tag";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faPlus, faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PatchNotesModalProps = {
    type: ActionType;
    patchNote: PatchNoteVersionModel;
    onClose: () => void;
    onSubmit: (type: ActionType, patchNote: PatchNoteVersionModel) => void;
    isLoading: boolean;
};

export const PatchNotesModal: FC<PatchNotesModalProps> = ({ onClose, onSubmit, patchNote, type, isLoading }) => {
    const noteInputId = "noteInputId";

    const { t } = useTranslation();
    const {
        customerInstanceOptions,
        handleDeleteItems,
        handleDeleteUserType,
        handleErrorChange,
        handleInternalPatchNoteChange,
        hasChanges,
        internalPatchNote,
        internalPatchNoteCopy,
        note,
        onAddNote,
        onChangePatchNotes,
        onDeletePatchNote,
        onPressEnterPatchNotes,
        optionsLoading,
        patchNoteErrors,
        setHasChanges,
        userTypeOptions,
    } = usePatchNotesModal(patchNote, type, noteInputId);

    const { name, title, patchNotes, customerInstances, userTypes } = internalPatchNote;
    const { errorName, errorTitle, errorPatchNotes, errorCustomerInstances } = patchNoteErrors;

    const patchNoteModalTitle: Record<ActionType, string> = {
        create: t(PatchNotesTranslations.PATCH_NOTES_CREATE_MODAL_TITLE),
        edit: t(PatchNotesTranslations.PATCH_NOTES_EDIT_MODAL_TITLE),
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        onSubmit(type, mapFormValuesToPatchNote(internalPatchNote));
    };

    const validate = () => {
        let check = true;
        handleErrorChange(INITIAL_PATCH_NOTE_ERRORS);
        if (!name.length) {
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!title.length) {
            handleErrorChange({ errorTitle: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!userTypes.length) {
            handleErrorChange({ errorUserTypes: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }

        if (!patchNotes.length || patchNotes.some((pn) => !pn.note.length)) {
            handleErrorChange({ errorPatchNotes: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!customerInstances.length) {
            handleErrorChange({ errorCustomerInstances: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }

        return check;
    };

    const handleClose = () => {
        if (!isEqual(internalPatchNote, internalPatchNoteCopy)) {
            setHasChanges(true);
            return;
        }
        handleErrorChange(INITIAL_PATCH_NOTE_ERRORS);
        onClose();
    };

    const handleCloseHasChangesModal = () => {
        handleErrorChange(INITIAL_PATCH_NOTE_ERRORS);
        handleInternalPatchNoteChange(internalPatchNoteCopy);
        onClose();
    };

    return (
        <>
            {hasChanges && (
                <ConfirmModal
                    title={t(PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_TITLE_LABEL)}
                    description={t(PatchNotesTranslations.PATCH_NOTE_CANCEL_CHANGES_DESCRIPTION_LABEL)}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={handleCloseHasChangesModal}
                    onClose={() => setHasChanges(false)}
                    type="delete"
                />
            )}
            <GenericModal
                header={{ title: patchNoteModalTitle[type], onClose: handleClose }}
                size="md"
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "patchNotes",
                            iconLeft: faSave,
                            type: "submit",
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: handleClose,
                        }}
                        loading={isLoading}
                    />
                }
            >
                <form onSubmit={handleSubmit} className="patchNotesModal" id="patchNotes">
                    {optionsLoading ? (
                        <div className="patchNotesModal__spinner">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <Input
                                label={t(PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL)}
                                placeholder={t(PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL)}
                                onChange={(value) => handleInternalPatchNoteChange({ name: value })}
                                value={name}
                                errorMessage={errorName}
                                focus
                            />
                            <Input
                                label={t(PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL)}
                                placeholder={t(PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL)}
                                onChange={(value) => handleInternalPatchNoteChange({ title: value })}
                                value={title}
                                errorMessage={errorTitle}
                            />
                            <Input
                                label={t(PatchNotesTranslations.PATCH_NOTES_NOTE_LABEL)}
                                placeholder={t(PatchNotesTranslations.PATCH_NOTES_NOTE_LABEL)}
                                onChange={onChangePatchNotes}
                                onPressEnter={onPressEnterPatchNotes}
                                errorMessage={errorPatchNotes}
                                value={note}
                                inputId={noteInputId}
                            />
                            <div className=" patchNotesModal__add" onClick={onAddNote}>
                                <FontAwesomeIcon className=" patchNotesModal__add__icon" icon={faPlus} />
                                <p className=" patchNotesModal__add__text">
                                    {t(PatchNotesTranslations.PATCH_NOTES_ADD_NOTE_LABEL)}
                                </p>
                            </div>
                            {patchNotes.map(({ id, note }) => (
                                <Tag
                                    key={id}
                                    message={note}
                                    onClose={() => onDeletePatchNote(id)}
                                    clamp={2}
                                    size="auto"
                                    variant="white"
                                />
                            ))}
                            <OptionsOutsideSelect
                                label={t(PatchNotesTranslations.PATCH_NOTE_CUSTOMER_INSTANCES_LABEL)}
                                options={customerInstanceOptions}
                                onChange={(value) => handleInternalPatchNoteChange({ customerInstances: value })}
                                values={customerInstances}
                                onDeleteItem={handleDeleteItems}
                                errorMessage={errorCustomerInstances}
                                isMulti={true}
                                autoInitials
                            />
                            <OptionsOutsideSelect
                                onChange={(value) => handleInternalPatchNoteChange({ userTypes: value })}
                                onDeleteItem={handleDeleteUserType}
                                options={userTypeOptions}
                                label={t(PatchNotesTranslations.SEND_PATCH_NOTES_SELECTOR_USER_TYPE_LABEL)}
                                values={userTypes}
                            />
                        </>
                    )}
                </form>
            </GenericModal>
        </>
    );
};
