import { FC, ReactNode } from "react";
import { RowPositionModel } from "app/components_v2/Table/types";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";

type MobileAuditGroupCheckListInstanceProps = {
    data: AuditGroupCheckListInstanceModel;
    auditStars: (grade: number | null) => ReactNode;
    rowPosition: RowPositionModel;
};

export const MobileAuditGroupCheckListInstance: FC<MobileAuditGroupCheckListInstanceProps> = ({ data, auditStars }) => {
    const { name, grade } = data;

    return (
        <div className={`mobileAuditGroupCheckListInstance`}>
            <p className="mobileAuditGroupCheckListInstance__name">{name}</p>
            <div className="mobileAuditGroupCheckListInstance__grade">{auditStars(grade)}</div>
        </div>
    );
};
