import { IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { FC } from "react";
import { IssueHistoryReopen } from "./IssueHistoryReopen/IssueHistoryReopen";
import { IssueHistoryPillList } from "./IssueHistoryPillList/IssueHistoryPillList";
import { useTranslation } from "react-i18next";
import { IssueTranslation } from "app/translation/translationKeys";
import { IssueStatusModel } from "app/models/05-QUA/IssueModels/IssueStatusModels";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type IssueHistoryProps = {
    issue: IssueModel;
    onFinish?: () => void;
    variant?: TabHeaderVariants;
};

export const IssueHistory: FC<IssueHistoryProps> = ({ issue, onFinish, variant = "primary" }) => {
    const { t } = useTranslation();

    const {
        actualStatus,
        id,
        issueStatus,
        issueCorrectiveMeasures,
        description,
        openDate,
        openUser,
        audioBase64Content,
    } = issue;

    const generateStatus = (): IssueStatusModel[] => {
        const newStatus: IssueStatusModel = {
            id: 0,
            comment: description,
            status: "OPEN",
            date: openDate,
            user: openUser,
            audioUrl: "",
            fk_Issue: id,
            fk_User: openUser.id,
            audioBase64Content,
        };

        return [newStatus, ...issueStatus];
    };
    return (
        <Collapsable title={t(IssueTranslation.ISSUE_HISTORY_TITLE)} variant={variant}>
            <div className="issueHistory">
                <div className="issueHistory__container">
                    <IssueHistoryPillList
                        issueStatus={generateStatus().reverse()}
                        issueCorrectiveMeasures={issueCorrectiveMeasures}
                    />
                </div>
                {onFinish && (
                    <IssueHistoryReopen actualStatus={actualStatus} id={id} onFinish={onFinish} variant={variant} />
                )}
            </div>
        </Collapsable>
    );
};
