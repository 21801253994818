import { createRoot } from "react-dom/client";
import App from "./App";
//import ReactDOM from "react-dom";
import "./index.css";
import "./app/translation/i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

document.title = process.env.REACT_APP_TITLE || "";
const container = document.getElementById("root");
if (container) {
    createRoot(container).render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
