import { ReviewTaskTabs } from "app/pages/02-TAR/02-TAR-ReviewFotos/models";
import { getDateFormattedSelects } from "../Date.utilities";

export type ExtraParams = {
    taskName?: string;
    deadlineDate?: Date;
    deadlineDate1?: Date;
    finishedDate?: Date;
    finishedDate1?: Date;
    companyId?: number;
    asignedToUser?: number;
    asignedToDepartment?: number;
    asignedToSubdepartment?: number;
    editedBy?: number;
    plan?: string;
    status?: string;
    fields?: string;
    hasData?: boolean;
    hasPhoto?: boolean;
    wellDoneCheck?: boolean;
    worseDoneCheck?: boolean;
    critical?: boolean;
    noCritical?: boolean;
    pending?: boolean;
    timedOut?: boolean;
    outOfTime?: boolean;
    inTime?: boolean;
    taskId?: number;
    customerInstanceId?: number;
    myTasksUserId?: number;
    supervisorId?: number;
    notEvaluatedCheck?: boolean;
    fiveStar?: boolean;
    fourStar?: boolean;
    threeStar?: boolean;
    twoStar?: boolean;
    oneStar?: boolean;
    hasFeedbackComment?: boolean;
    hasComments?: boolean;
    taskReviewStatusCode?: ReviewTaskTabs;
};

export const fillTaskInstanceExtraParams = ({
    taskName,
    deadlineDate,
    deadlineDate1,
    finishedDate,
    finishedDate1,
    companyId,
    asignedToUser,
    asignedToDepartment,
    asignedToSubdepartment,
    editedBy,
    plan,
    status,
    fields,
    hasData,
    hasPhoto,
    critical,
    noCritical,
    pending,
    timedOut,
    outOfTime,
    inTime,
    taskId,
    customerInstanceId,
    myTasksUserId,
    supervisorId,
    notEvaluatedCheck,
    fiveStar,
    fourStar,
    threeStar,
    twoStar,
    oneStar,
    hasFeedbackComment,
    hasComments,
    taskReviewStatusCode,
}: ExtraParams): string => {
    let extraParams = ``;
    if (taskName) extraParams += `taskName=${taskName}&`;
    if (deadlineDate) extraParams += `deadlineDate=${getDateFormattedSelects(deadlineDate)}&`;
    if (deadlineDate1) extraParams += `deadlineDate1=${getDateFormattedSelects(deadlineDate1)}&`;
    if (finishedDate) extraParams += `finishedDate=${getDateFormattedSelects(finishedDate)}&`;
    if (finishedDate1) extraParams += `finishedDate1=${getDateFormattedSelects(finishedDate1)}&`;
    if (companyId) extraParams += `companyId=${companyId}&`;
    if (myTasksUserId) extraParams += `myTasksUserId=${myTasksUserId}&`;
    else if (asignedToUser) extraParams += `asignedToUserId=${asignedToUser}&`;
    if (asignedToDepartment) extraParams += `asignedToDepartmentId=${asignedToDepartment}&`;
    if (asignedToSubdepartment) extraParams += `asignedToSubdepartmentId=${asignedToSubdepartment}&`;
    if (editedBy) extraParams += `editedBy=${editedBy}&`;
    if (plan) extraParams += `planId=${plan}&`;
    if (status) extraParams += `statusCode=${status}&`;
    if (fields) extraParams += `dataReported=${fields}&`;
    if (hasPhoto !== undefined) extraParams += `isPhotoRequired=${hasPhoto}&`;
    if (hasData) extraParams += `dataReport=${hasData}&`;
    if (critical !== undefined) extraParams += `critical=${critical}&`;
    if (noCritical !== undefined) extraParams += `noCritical=${noCritical}&`;
    if (pending !== undefined) extraParams += `pending=${pending}&`;
    if (timedOut !== undefined) extraParams += `timedOut=${timedOut}&`;
    if (outOfTime !== undefined) extraParams += `outOfTime=${outOfTime}&`;
    if (inTime !== undefined) extraParams += `inTime=${inTime}&`;
    if (taskId !== undefined) extraParams += `taskId=${taskId}&`;
    if (customerInstanceId !== undefined) extraParams += `customerInstanceId=${customerInstanceId}&`;
    if (supervisorId) extraParams += `supervisorId=${supervisorId}&`;
    if (notEvaluatedCheck) extraParams += `notEvaluated=${notEvaluatedCheck}&`;
    if (fiveStar) extraParams += `fiveStar=${fiveStar}&`;
    if (fourStar) extraParams += `fourStar=${fourStar}&`;
    if (threeStar) extraParams += `threeStar=${threeStar}&`;
    if (twoStar) extraParams += `twoStar=${twoStar}&`;
    if (oneStar) extraParams += `oneStar=${oneStar}&`;
    if (hasFeedbackComment !== undefined) extraParams += `hasFeedbackComment=${hasFeedbackComment}&`;
    if (hasComments) extraParams += `hasComments=${hasComments}&`;
    if (taskReviewStatusCode) extraParams += `taskReviewStatusCode=${taskReviewStatusCode}&`;
    return extraParams;
};
