import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AuditGroupCheckListItemInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListItemInstanceModel";

export default class AuditGroupChecklistItemInstanceService {
    // GET a list of AuditGroupCheckListItemModel
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditGroupCheckListItemInstanceModel>>({
            url: `${ApiPaths.QUA_API}/AuditGroupChecklistItemInstance`,
            paginationParams: params,
        });
    }
}
