import { FC } from "react";
import { RoleCollapseBodyTitles } from "./RoleCollapseBodyTitles/RoleCollapseBodyTitles";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { RoleCollapseRow } from "../RoleCollapseRow/RoleCollapseRow";

type RoleCollapseBodyProps = {
    modulePaterns: ModuleScreenPatents;
    modules: SecModule[];
    disabled?: boolean;
    onChange: (code: string, value: SecScreenPatent) => void;
};
export const RoleCollapseBody: FC<RoleCollapseBodyProps> = ({ modulePaterns, modules, disabled, onChange }) => {
    const getSpecificModule = (patents: string[], screenCode: string): Record<string, boolean> => {
        const currentPatent = modules.find(({ moduleCode }) => {
            return moduleCode === modulePaterns.moduleCode;
        });
        const patentsChecked = currentPatent?.screenPatents.find((cp) => cp.screenCode === screenCode);

        const module = Object.fromEntries(
            patents.map((action) => [action, !!patentsChecked?.patents?.includes(action)])
        );

        return module;
    };

    const handleChange = (module: Record<string, boolean>, moduleCode: string, screenCode: string) => {
        const moduleKeys = Object.values(module);
        const moduleValues = Object.keys(module);

        const checkedKeys: string[] = [];
        moduleKeys.forEach((value, i) => value && checkedKeys.push(moduleValues[i]));

        const union = [...checkedKeys].map((v) => v.toString());

        const newPatents: SecScreenPatent = {
            screenCode,
            patentCode: "",
            patents: union,
        };

        onChange(moduleCode, newPatents);
    };

    const handleSelectAll = () => {
        const isAllSelected = isAllTrue();
        modulePaterns.screens?.forEach(({ patents, screenCode }) => {
            if (!patents) return;

            const module = Object.fromEntries(patents.map((patent) => [patent, !isAllSelected]));
            handleChange(module, modulePaterns.moduleCode, screenCode);
        });
    };

    const getModulesInBooleanArray = () => {
        const allRows: boolean[][] = [];
        modulePaterns.screens?.forEach(({ screenCode, patents }) => {
            const moduleToObject = getSpecificModule(patents || [], screenCode);
            const moduleValues = Object.values(moduleToObject);
            allRows.push(moduleValues);
        });
        return allRows.flat();
    };

    const isAllTrue = () => {
        const allRows: boolean[] = getModulesInBooleanArray();
        return allRows.every((value) => value === true);
    };

    const isIndeterminate = () => {
        const allRows: boolean[] = getModulesInBooleanArray();
        return allRows.some((value) => value === false);
    };

    const isSelectAllChecked = () => {
        const allRows: boolean[] = getModulesInBooleanArray();
        return allRows.some((value) => value === true);
    };

    return (
        <div className="roleCollapseBody">
            <RoleCollapseBodyTitles
                handleSelectAll={handleSelectAll}
                isIndeterminate={isIndeterminate()}
                isSelectAllChecked={isSelectAllChecked()}
            />
            {modulePaterns.screens?.map(({ screenCode, patents }, i) => (
                <RoleCollapseRow
                    key={i}
                    disabled={!!disabled}
                    isLastIndex={i + 1 === modulePaterns.screens?.length}
                    module={getSpecificModule(patents || [], screenCode)}
                    patents={patents ? patents.filter((patent) => patent !== "IMPERSONATE") : []}
                    screenCode={screenCode}
                    onChange={(value) => handleChange(value, modulePaterns.moduleCode, screenCode)}
                />
            ))}
        </div>
    );
};
