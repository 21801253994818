import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AuditGroupCheckListItemModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListItemModels";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class AuditGroupCheckListItemService {
    // GET Single AuditGroupCheckListItem
    public static async GetOne(id: number) {
        return FetchService.get<AuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem/${id}`,
        });
    }

    // GET AuditGroupCheckListItem List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditGroupCheckListItemModel>>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem`,
            paginationParams: params,
        });
    }

    // GET AuditGroupCheckListItem List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST AuditGroupCheckListItem
    public static async Save(body: AuditGroupCheckListItemModel) {
        return FetchService.post<AuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem`,
            body,
        });
    }

    // PUT AuditGroupCheckListItem
    public static async Edit(id: number, body: AuditGroupCheckListItemModel) {
        return FetchService.put<AuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem/${id}`,
            body,
        });
    }

    // DELETE AuditGroupCheckListItem
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/AuditGroupCheckListItem/${id}`,
        });
    }
}
