import { useState } from "react";
import { useSession } from "app/hooks/useSession";
import { fillTaskInstanceExtraParams } from "app/helpers/ExtraParams/taskInstanceFillExtraParams";
import { OptionsSearch } from "app/models/FormComponentsModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useUsers = () => {
    const [users, setUsers] = useState<OptionsSearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");

    const session = useSession();

    const getUsers = async (companyId: number) => {
        if (companyId === -1) {
            setUsers([]);
            return;
        }
        setIsLoading(true);

        const { data, error, status } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        if (!status()) {
            setError(error);
            return;
        }
        setUsers(data);
    };

    return {
        users,
        setUsers,
        isLoading,
        error,
        getUsers,
    };
};
