import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faAlarmExclamation, faCircleXmark, faClock } from "@fortawesome/pro-regular-svg-icons";
import { TFunction } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";

type StatusColProps = {
    type: "PENDING" | "NEAR_END" | "TIMED_OUT" | "IN_TIME" | "OUT_OF_TIME";
    date: string;
    t: TFunction<"translation", undefined>;
};
type returnProps = {
    badgeVariant: BadgeVariant;
    value: string;
    icon: IconProp;
};

export const getValueTaskInstanceStatus = ({ type, date, t }: StatusColProps): returnProps => {
    if (type === "IN_TIME")
        return {
            badgeVariant: "green",
            value: date,
            icon: faCheck,
        };
    if (type === "OUT_OF_TIME")
        return {
            badgeVariant: "orange",
            value: date,
            icon: faAlarmExclamation,
        };
    if (type === "PENDING" || type === "NEAR_END")
        return {
            badgeVariant: "blue",
            value: t(TranslationKeys.TASK_STATUS_PENDING),
            icon: faClock,
        };

    if (type === "TIMED_OUT")
        return {
            badgeVariant: "red",
            value: t(TranslationKeys.NOT_FINISHED),
            icon: faCircleXmark,
        };

    return {
        badgeVariant: "grey",
        icon: faCircleXmark,
        value: date,
    };
};
