import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PrivatePaths, PREPOSITIONS_TO_AVOID } from "app/shared/Constants";
import { NumberIcon } from "../NumberIcon/NumberIcon";
import { AvartarDirection, AvartarVariants, AvatarIconPosition, AvatarSize } from "./types";
import { AvatarName } from "./AvatarName/AvatarName";
import { AvatarImage } from "./AvatarImage/AvatarImage";
import { AvatarInitials } from "./AvatarInitials/AvatarInitials";
import { AriaLabels } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export type AvatarProps = {
    img?: string;
    avatarName?: string;
    colorId: number;
    onClick?: () => void;
    notificationNumber?: number;
    name?: string;
    size?: AvatarSize;
    iconPosition?: AvatarIconPosition;
    direction?: AvartarDirection;
    variant?: AvartarVariants;
    clamp?: boolean;
    goMyProfile?: boolean;
    border?: boolean;
    capitalize?: boolean;
    objectFit?: "contain";
    background?: "white";
    nameInitials?: string;
    role?: string;
};

export const Avatar: FC<AvatarProps> = ({
    img,
    avatarName,
    colorId,
    onClick,
    name,
    notificationNumber = 0,
    size = "md",
    iconPosition = "left",
    direction = "row",
    variant = "primary",
    clamp = false,
    goMyProfile,
    border,
    capitalize = true,
    objectFit,
    background,
    nameInitials,
    role,
}) => {
    const nav = useNavigate();
    const { t } = useTranslation();

    const avatarName2: string | undefined = avatarName
        ? avatarName.toUpperCase()
        : name
            ? name.toUpperCase()
            : undefined;

    const avatarSplitedByspace = avatarName2 ? avatarName2.split(" ") : undefined;

    const splitedAvatarName: string[] | undefined = avatarSplitedByspace
        ? avatarSplitedByspace.length > 2
            ? avatarSplitedByspace.filter((x) => !PREPOSITIONS_TO_AVOID.includes(x.toLocaleLowerCase()))
            : avatarSplitedByspace
        : undefined;

    const handleGoMyProfile = () => goMyProfile && nav(`/${PrivatePaths.MY_PROFILE}`);
    const getInitials = () => {
        if (nameInitials) return nameInitials;
        if (splitedAvatarName)
            return `${splitedAvatarName[0][0]}${splitedAvatarName.length > 1 ? splitedAvatarName[1][0] : ""}`;

        return "";
    };

    const SingleAvatar: FC = () => {
        return (
            <div
                onClick={onClick}
                className={`avatar--${size} ${onClick ? "avatar--clickable" : ""}`}
                aria-label={t(AriaLabels.AL_AVATAR_PROFILE_PICTURE)}
            >
                {notificationNumber > 0 && (
                    <NumberIcon
                        className={`${iconPosition === "left" ? "avatar__notification--left" : "avatar__notification--right"
                            }`}
                        value={notificationNumber}
                    />
                )}
                {img && !!img.length ? (
                    <AvatarImage
                        src={img}
                        size={size}
                        background={background}
                        border={border}
                        objectFit={objectFit}
                        alt="profile"
                    />
                ) : (
                    <AvatarInitials
                        colorId={colorId}
                        size={size}
                        initials={getInitials()}
                        showColor={!!avatarName2?.length}
                        border={border}
                    />
                )}
            </div>
        );
    };

    return (
        <div
            className={`avatarContainer--${direction} ${goMyProfile ? "cursorPointer" : ""}`}
            role={goMyProfile ? "button" : "region"}
            tabIndex={goMyProfile ? 0 : -1}
            onClick={handleGoMyProfile}
            onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                    handleGoMyProfile();
                }
            }}
        >
            <SingleAvatar />
            {name && (
                <div>
                    <AvatarName name={name} variant={variant} capitalize={capitalize} clamp={clamp} />

                    {!!role?.length && <p className="avatarContainer__role">{role}</p>}
                </div>
            )}
        </div>
    );
};
