import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { IssueModalAuditInfo, IssueModalOptions } from "app/state/context/issueModalContext/models/type";

export const IssueModalProvider: FC<ProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [issueType, setIssueType] = useState<IssueTypeCodeModel>("GENERIC");
    const [taskInstanceId, setTaskInstanceId] = useState<number | null>(null);
    const [assetId, setAssetId] = useState<number>();
    const [assetNotWorking, setAssetNotWorking] = useState<boolean | null>(null);
    const [auditInfo, setAuditInfo] = useState<IssueModalAuditInfo | null>(null);
    const [auditInstanceId, setAuditInstanceId] = useState<number | null>(null);
    const [auditGroupChecklistInstanceId, setAuditGroupChecklistInstanceId] = useState<number | null>(null);
    const [companyId, setCompanyId] = useState<number | null>(null);

    const open = ({
        issueType,
        taskinstanceId,
        assetId,
        auditInfo,
        auditInstanceId,
        auditGroupChecklistInstanceId,
        isAssetWorking,
        companyId,
    }: IssueModalOptions) => {
        setIsOpen(true);
        setIssueType(issueType);
        setTaskInstanceId(taskinstanceId || null);
        setAssetId(assetId);
        setAuditInfo(auditInfo || null);
        setAuditInstanceId(auditInstanceId || null);
        setAuditGroupChecklistInstanceId(auditGroupChecklistInstanceId || null);
        setCompanyId(companyId || null);
        if (isAssetWorking != null) setAssetNotWorking(!isAssetWorking);
    };

    const fillValues = ({
        issueType,
        taskinstanceId,
        assetId,
        auditInfo,
        auditInstanceId,
        auditGroupChecklistInstanceId,
        isAssetWorking,
        companyId,
    }: IssueModalOptions) => {
        setIssueType(issueType);
        setTaskInstanceId(taskinstanceId || null);
        setAssetId(assetId);
        setAuditInfo(auditInfo || null);
        setAuditInstanceId(auditInstanceId || null);
        setAuditGroupChecklistInstanceId(auditGroupChecklistInstanceId || null);
        setCompanyId(companyId || null);
        if (isAssetWorking != null) setAssetNotWorking(!isAssetWorking);
    };

    const reset = () => {
        setIsOpen(false);
        setIssueType("GENERIC");
        setTaskInstanceId(null);
        setAssetId(undefined);
        setAuditInfo(null);
        setAuditInstanceId(null);
        setAuditGroupChecklistInstanceId(null);
        setAssetNotWorking(null);
    };

    return (
        <IssueModalContext.Provider
            value={{
                isOpen,
                issueType,
                taskInstanceId,
                reset,
                open,
                assetId,
                fillValues,
                assetNotWorking,
                auditInfo,
                auditInstanceId,
                auditGroupChecklistInstanceId,
                companyId,
            }}
        >
            {children}
        </IssueModalContext.Provider>
    );
};
