import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { GenerateQr } from "app/components_v2/GenerateQr/GenerateQr";

export const CreateQrSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Crear Qr" show={show}>
            <div style={{ margin: "0 auto" }}>
                <GenerateQr
                    companyName="The Old Stake House"
                    companyPhoto="https://i.pinimg.com/736x/20/97/c7/2097c742ca8a294cd8e879a63f7b225e.jpg"
                    qrName="Prod: Cuarto Limpieza"
                    url="https://app.4link.app/dashboard/qr/TS0001"
                    qrCode="TS0001"
                />
            </div>
        </Section>
    );
};
