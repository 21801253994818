import { AppSource, AppSourceEntries } from "app/shared/Constants";

export const getAppSource = (value?: string): AppSourceEntries | undefined => {
    const sourceMap: Record<string, AppSourceEntries> = {
        [AppSource.ONLINE]: AppSource.ONLINE,
        [AppSource.OFFLINE]: AppSource.OFFLINE,
    };

    return value ? sourceMap[value.toUpperCase()] : undefined;
};
