import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type EditImageFooterIconProps = {
    icon: IconProp;
    active?: boolean;
    ariaLabel?: string;
    onClick: () => void;
};

export const EditImageFooterIcon: FC<EditImageFooterIconProps> = ({ icon, onClick, active, ariaLabel }) => {
    return (
        <button
            className={`editImageFooterIcon ${active ? "editImageFooterIcon--active" : ""}`}
            aria-pressed={!!active}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            <FontAwesomeIcon
                className={`editImageFooterIcon__svg ${active ? "editImageFooterIcon__svg--active" : ""}`}
                icon={icon}
            />
        </button>
    );
};
