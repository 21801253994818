import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { MaintenanceModel } from "app/models/00-LOGIN/MaintenanceModel";

export default class MaintenanceService {
    public static async GetMaintenanceStatusAsync() {
        return FetchService.get<MaintenanceModel>({
            url: `${ApiPaths.LOGIN_API}/Maintenance/GetMaintenanceStatusAsync`,
        });
    }
}
