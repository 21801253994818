import { FC } from "react";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { Badge } from "app/components_v2/Badge/Badge";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { AvatarSize } from "app/components_v2/Avatar/types";

type CellWithAvatarProps = {
    avatarName?: string;
    colorId: number;
    badgeTitle: string;
    badgeVariant: BadgeVariant | "none";
    icon?: IconProp;
    img?: string;
    avatarSize?: AvatarSize;
    ariaLabel?: string;
};

export const CellWithAvatar: FC<CellWithAvatarProps> = ({
    avatarName,
    colorId,
    badgeTitle,
    badgeVariant,
    icon,
    img,
    avatarSize,
    ariaLabel,
}) => {
    return (
        <div className="cellWithAvatar">
            <Avatar avatarName={avatarName || ""} colorId={colorId} img={img} size={avatarSize} />
            <div className="cellWithAvatar__taskInfo">
                {avatarName && <p className="cellWithAvatar__taskInfo__name">{avatarName}</p>}
                {badgeVariant === "none" ? (
                    <p className="cellWithAvatar__taskInfo--noBadge">{badgeTitle}</p>
                ) : (
                    <Badge
                        variant={badgeVariant}
                        title={badgeTitle}
                        icon={icon}
                        iconPosition="left"
                        ariaLabel={ariaLabel}
                    />
                )}
            </div>
        </div>
    );
};
