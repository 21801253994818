import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { HeaderAnalyst } from "app/components_v2/HeaderAnalyst/HeaderAnalyst";
import FourLinkLogo from "../../../../../assets/common/4linkLogo.png";

export const HeaderAnalystSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="HeaderAnalystSection" show={show}>
            <div className="headerAnalystSection">
                <HeaderAnalyst
                    avatar={{ avatarName: "Sara perez", colorId: 32, img: FourLinkLogo }}
                    rating={{ starRatingAVG: 4.2, total: 130, trend: 23 }}
                />
                <HeaderAnalyst
                    avatar={{ avatarName: "Sara perez", colorId: 32, img: FourLinkLogo }}
                    rating={{ starRatingAVG: 4.2, total: 130, trend: 23 }}
                />
                <HeaderAnalyst
                    avatar={{ avatarName: "Sara perez", colorId: 32, img: FourLinkLogo }}
                    rating={{ starRatingAVG: 4.2, total: 130, trend: 23 }}
                />
                <HeaderAnalyst
                    avatar={{ avatarName: "Sara perez", colorId: 32, img: FourLinkLogo }}
                    rating={{ starRatingAVG: 4.2, total: 130, trend: 23 }}
                />
            </div>
        </Section>
    );
};
