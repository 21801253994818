import { FC, useRef, useState } from "react";
import { OtpValues } from "./types";
import { SquareInput } from "../SquareInput/SquareInput";
import { SquareInputSize } from "../SquareInput/types";

type OtpInputProps = {
    onChange: (newValue: OtpValues, value: string, isCompleted: boolean) => void;
    placeholder?: string;
    length?: number;
    size?: SquareInputSize;
};

const otpRegex = /^[a-zA-Z0-9]+$/;

export const OtpInput: FC<OtpInputProps> = ({ onChange, length: otpLength = 4, placeholder = "Q123", size = "lg" }) => {
    const [optInputValues, setOptInputValues] = useState<OtpValues[]>(
        Array.from({ length: otpLength }, (_, key) => ({ id: String(key), value: "" }))
    );

    const inputsRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

    const onChangeValue = ({ id, value, key }: OtpValues & { key: number }) => {
        setOptInputValues((prev) => prev.map((optInput) => (optInput.id === id ? { ...optInput, value } : optInput)));

        const currentValue = optInputValues.map((optInput) => (optInput.id === id ? value : optInput.value)).join("");

        onChange({ id, value }, currentValue, key + 1 >= otpLength);

        if (key + 1 >= otpLength) {
            inputsRef.current[otpLength - 1].blur();
            return;
        }

        inputsRef.current[key].blur();
        inputsRef.current[key + 1].focus();
    };

    const onDelete = ({ id, value, key }: OtpValues & { key: number }) => {
        setOptInputValues((prev) =>
            prev.map((optInput) => (optInput.id === id ? { ...optInput, value: "" } : optInput))
        );

        const currentValueArray = optInputValues.map((optInput) =>
            optInput.id === id ? { ...optInput, value: "" } : optInput
        );

        if (value !== "" || key === 0) {
            const currentValue = currentValueArray
                .map((optInput) => (optInput.id === id ? "" : optInput.value))
                .join("");

            onChange({ id, value: "" }, currentValue, false);
            return;
        }

        const prevInput = optInputValues[key - 1];
        setOptInputValues((prev) =>
            prev.map((optInput) => (optInput.id === prevInput.id ? { ...prevInput, value: "" } : optInput))
        );

        const currentValue = currentValueArray
            .map((optInput) => (prevInput.id === optInput.id ? "" : optInput.value))
            .join("");

        onChange({ id, value }, currentValue, false);
        inputsRef.current[key].blur();
        inputsRef.current[key - 1].focus();
        return;
    };

    return (
        <div className="otpInputs">
            {optInputValues.map(({ id, value }, i) => {
                const placeholderWord = placeholder.slice(Math.max(i, 0), i + 1);
                return (
                    <SquareInput
                        key={id}
                        value={value}
                        onChange={(newValue) => {
                            onChangeValue({ id, value: newValue, key: i });
                        }}
                        focus={i === 0}
                        placeholder={placeholderWord}
                        ref={(ref) => (inputsRef.current[i] = ref as HTMLInputElement)}
                        onDelete={() => {
                            onDelete({
                                id: optInputValues[Math.max(i, 0)].id,
                                value: optInputValues[Math.max(i, 0)].value,
                                key: Math.max(i, 0),
                            });
                        }}
                        size={size}
                        pattern={otpRegex}
                    />
                );
            })}
        </div>
    );
};
