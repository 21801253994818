import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const useFetchErrors = () => {
    const { t } = useTranslation();

    const getErrorMessage = (errorMessage: string, httpCodeStatus?: number): string => {
        if (!!httpCodeStatus) {
            const httpCodeErrorMessage: Record<number, string> = {
                403: t(TranslationCommon.ACCESS_DENIED),
            };

            if (httpCodeErrorMessage[httpCodeStatus]) return t(httpCodeErrorMessage[httpCodeStatus]);
        }
        return t(errorMessage) || t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE);
    };

    return { getErrorMessage };
};
