import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type SquareButtonProps = {
    children: string;
    icon: IconProp;
    isFocused?: boolean;
    onClick: () => void;
};

export const SquareButton: FC<SquareButtonProps> = ({ children, icon, onClick, isFocused }) => {
    return (
        <div className="squareButton">
            <button
                autoFocus={isFocused}
                className={`squareButton__btn${isFocused ? "--focus" : ""}`}
                onClick={onClick}
            >
                <div className="squareButton__icon">
                    <FontAwesomeIcon
                        icon={icon}
                        className={`squareButton__icon__content${isFocused ? "--focus" : ""}`}
                        role="img"
                        title="qr code icon"
                    />
                </div>
            </button>
            <p className={`squareButton__text${isFocused ? "--focus" : ""}`}>{children}</p>
        </div>
    );
};
