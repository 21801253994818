import Spinner from "app/components_v2/Spinner/Spinner";

const Loading = () => {
    return (
        <div className="fullScreenSpinner">
            <Spinner />
        </div>
    );
};

export default Loading;
