import { TaskReviewUser } from "app/models/02-TAR/TaskReview";
import { ReviewCarrouselItemModel } from "app/pages/02-TAR/02-TAR-ReviewFotos/models";

export const ReviewUserMapper = (user: TaskReviewUser[]): ReviewCarrouselItemModel[] => {
    return user.map((user) => ({
        id: user.id,
        text: `${user.firstName} ${user.lastName}`,
        img: user.profilePictureURL || "",
        notSeenNoti: user.ojito,
        customerInstanceId: user.customerInstanceId || 0,
    }));
};
