import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks/CustomHooks";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { InstanceService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { CustomerInstanceModel } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faCirclePlus, faListCheck, faPen, faRightToBracket } from "@fortawesome/pro-regular-svg-icons";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useToast } from "app/hooks/Toast/useToast";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { WizardLicense } from "app/components_v2/wizards/WizardLicense/WizardLicense";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { useSystemUser } from "app/hooks/useSystemUser";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";

const LicensesGrid: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const d = useDispatch();
    const { handleToast } = useToast();
    const { handleSystemUser } = useSystemUser();

    useTitle(t(TranslationTitles.LICENSES_PAGE_TITLE));

    const [data, setData] = useState<CustomerInstanceModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [sortField, setSortField] = useState<string>("id");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel | undefined>("desc");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isCreateInstanceModalOpen, setIsCreateInstanceModalOpen] = useState<boolean>(false);

    const selectInstance = async (id: number) => {
        await fetchSystemUserCustomerInstance(id);
        navigate(`/${PrivatePaths.SELECT_DEPARTMENT}`);
    };

    const columns: ColumnsType<CustomerInstanceModel>[] = [
        {
            label: "ID",
            dataIndex: "Id",
            sortedType: "default",
            render: (record) => <CellTitle title={record.id} />,
        },
        {
            label: t(TranslationKeys.INSTANCE_NAME),
            dataIndex: "name",
            sortedType: "default",
            render: (record) => <CellTitle title={record.name} />,
        },
        {
            label: t(TranslationKeys.CONTACT),
            dataIndex: "contactName",
            sortedType: "default",
            render: (record) => <CellTitle title={record.contactName} />,
        },
        {
            label: t(TranslationKeys.LABEL_EMAIL),
            dataIndex: "email",
            sortedType: "default",
            render: (record) => <CellTitle title={record.email} />,
        },
        {
            label: t(TranslationKeys.LABEL_PHONE),
            dataIndex: "phoneNumber",
            sortedType: "default",
            render: (record) => <CellTitle title={record.phoneNumber} />,
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        { icon: faRightToBracket, onClick: () => selectInstance(record.id) },
                        { icon: faPen, onClick: () => navigate(`edit/${record.id}`) },
                    ]}
                />
            ),
        },
    ];

    const getInstances = async () => {
        setIsLoading(true);

        const {
            data: taskData,
            error,
            status,
        } = await InstanceService.GetInstances({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndex - 1,
            sortDirection,
            sortField,
        });

        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(taskData.list);
        setTotal(taskData.count);
        setIsLoading(false);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        return InstanceService.GetInstancesExport();
    };

    const onFinishWizard = () => {
        getInstances();
        setIsCreateInstanceModalOpen(false);
    };

    const fetchSystemUserCustomerInstance = async (customerInstanceId: number | null) => {
        const systemUser = await handleSystemUser(customerInstanceId);
        if (!!systemUser) d(Actions.updateUserSession(systemUser));
        setWorkingEntity({
            workingCompany: undefined,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        });
    };

    useEffect(() => {
        getInstances();
    }, [pageIndex, sortField, sortDirection]);

    useEffect(() => {
        fetchSystemUserCustomerInstance(null);
    }, []);

    return (
        <>
            <PageContainer paddingTop>
                <TableCollapsable
                    cols={columns}
                    data={data}
                    placeholder={
                        <TableError description={t(TranslationKeys.TABLE_EMPTY_STATE_TASK)} icon={faListCheck} />
                    }
                    title={`${t(TranslationTitles.LICENSES_TITLE)}`}
                    subTitle={`${total} ${t(TranslationTitles.LICENSES_TITLE)}`}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    onChangePageIndex={setPageIndex}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                        setSortField(sortFieldParam);
                        setSortDirection(sortedTypeParam);
                    }}
                    onDoubleClick={({ id }) => navigate(`edit/${id}`)}
                    actionButtons={[{ icon: faCirclePlus, onClick: () => setIsCreateInstanceModalOpen(true) }]}
                    onClickExportCsv={handleExport}
                />
            </PageContainer>
            {isCreateInstanceModalOpen && (
                <GenericModal
                    header={{
                        title: `${t(TranslationCommon.NEW)} ${t(TranslationTitles.LICENSES_TITLE)}`,
                        onClose: () => setIsCreateInstanceModalOpen(false),
                    }}
                    size="xl"
                    hideChildrenPadding
                >
                    <WizardLicense onFinish={onFinishWizard} />
                </GenericModal>
            )}
        </>
    );
};

export default LicensesGrid;
