import { SessionRequest, RefreshTokenResponse } from "app/models/SessionModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { RoleSessionModel } from "app/dtos/01-SEG/RoleDto";

export default class SessionService {
    public static async RefreshToken(body: SessionRequest, refreshToken: string) {
        return FetchService.post<RefreshTokenResponse>({
            url: `${ApiPaths.LOGIN_API}/auth/refreshToken`,
            token: refreshToken,
            body: body,
        });
    }

    public static async GetPermissions(token: string) {
        return FetchService.get<RoleSessionModel[]>({
            url: `${ApiPaths.LOGIN_API}/auth/permissions`,
            token: token,
        });
    }

    public static async LogOut() {
        return FetchService.post({
            url: `${ApiPaths.LOGIN_API}/auth/logout`,
        });
    }
}
