import { v4 } from "uuid";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { PatchNoteFormValues } from "../models/PatchNoteFormValues";
import { TFunction } from "react-i18next";

export const mapPatchNoteToFormValues = (
    { id, name, title, userTypes, customerInstances, patchNotes }: PatchNoteVersionModel,
    t: TFunction<"translation", undefined>
): PatchNoteFormValues => ({
    id,
    name,
    title,
    userTypes: userTypes
        .split(";")
        .filter((userType) => userType.length)
        .map((userType) => ({ label: t(userType), value: userType })),
    patchNotes: patchNotes.map(({ id, fK_PatchNoteVersion, note }) => ({
        dbId: id,
        id: v4(),
        fK_PatchNoteVersion,
        note,
    })),
    customerInstances,
});

export const mapFormValuesToPatchNote = ({
    id,
    name,
    title,
    userTypes,
    patchNotes,
    customerInstances,
}: PatchNoteFormValues): PatchNoteVersionModel => ({
    id,
    name,
    title,
    userTypes: userTypes.map(({ value }) => value).join(";"),
    patchNotes: patchNotes.map(({ dbId, fK_PatchNoteVersion, note }) => ({ id: dbId, fK_PatchNoteVersion, note })),
    customerInstances,
    publishDate: new Date(),
    customerInstanceCount: 0,
});
