import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ErrorFormValuesNotificationQrModel, NotificationModalAssignedTo } from "../types";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker, OptionsOutsideSelect, SelectOptions } from "app/components_v2/__inputs";
import { AlertModalTranslations, NotificationsTranslations, TranslationCommon } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type NotificationsQrModalProps = {
    qrList: number[];
    qrOptions: OptionModel[];
    companyList: number[];
    companyOptions: OptionModel[];
    sendDate: Date | null;
    errorFormValues: ErrorFormValuesNotificationQrModel;
    onSelect: (values: Pick<NotificationModalAssignedTo, "qrList" | "companyList">, companyChanged: boolean) => void;
    onChangeDate: (value: Date) => void;
};
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const NotificationsQrModal: FC<NotificationsQrModalProps> = ({
    onSelect,
    qrList,
    qrOptions,
    onChangeDate,
    sendDate,
    errorFormValues,
    companyList,
    companyOptions,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const { errorDates, errorQr, errorCompany } = errorFormValues;

    const handleDeleteListItems = (qrId: string) => {
        onSelect({ qrList: qrList.filter((qrListId) => qrListId !== Number(qrId)), companyList }, false);
    };

    const handleDateChange = (date: Date | null) => {
        if (!date) return;
        onChangeDate(date);
    };

    return (
        <div className="notificationsQrModal">
            <div className="notificationsQrModal__company">
                <SelectOptions
                    label={translateCustomerTypeKeys(NotificationsTranslations.NOTIFICATION_WHICH_COMPANY_LABEL, {
                        firstReplace: "PLURAL",
                    })}
                    options={companyOptions}
                    onChange={({ value }) => onSelect({ companyList: [Number(value)], qrList: [] }, true)}
                    selectedValue={companyList[0] ? String(companyList[0]) : undefined}
                    isMulti={false}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    errorMessage={errorCompany}
                />
            </div>
            {!!companyList[0] && (
                <div className="notificationsQrModal__container">
                    <OptionsOutsideSelect
                        label={t(NotificationsTranslations.NOTIFICATION_ASSIGN_QR)}
                        options={qrOptions}
                        autoInitials
                        onChange={(qrs) =>
                            onSelect({ qrList: qrs.map(({ value }) => Number(value)), companyList }, false)
                        }
                        values={qrOptions ? qrOptions.filter(({ value }) => qrList.includes(Number(value))) : []}
                        onDeleteItem={handleDeleteListItems}
                        isMulti={true}
                        errorMessage={errorQr}
                    />
                    <DateRangePicker
                        onChange={([date]) => handleDateChange(date)}
                        label={t(AlertModalTranslations.SELECT_HOUR_QR)}
                        startDate={sendDate ?? undefined}
                        range={false}
                        min={tomorrow}
                        errorMessage={errorDates}
                    />
                </div>
            )}
        </div>
    );
};
