import { SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "../useFetchErrors";
import { useErrorManager } from "../ErrorHandler/useErrorManager";
import { useSuccessManager } from "../SuccessHandler/useSuccessManager";
import AvailabilityService from "app/services/01-SEG/AvailabilityService";
import { PendingTasksCompanyDepartmentModalContext } from "app/state/context/PendingTasksCompanyDepartmentModalContext/PendingTasksCompanyDepartmentModalContext";
import { PendingTasksCompanyDepartmentTranslations, TranslationErrors } from "app/translation/translationKeys";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { mapCompaniesAndDepartmentsToEntity } from "app/components_v2/__modals/PendingTasksCompanyDepartmentModal/helpers/mappers";
import {
    DateRangeMinMaxValues,
    EntityPendingTasksFormValues,
} from "app/components_v2/__modals/PendingTasksCompanyDepartmentModal/models/EntityPendingTasksFormValues";
import { useToast } from "../Toast/useToast";
import { getDateFormattedSelects } from "app/helpers";
import { useCustomerTypeTranslation } from "../CustomerType/useCustomerTypeTranslation";

export const usePendingTasksCompanyDepartmentModal = () => {
    const { t } = useTranslation();
    const { companiesAndDepartmentsWithPendingTasks, open, reset } = useContext(
        PendingTasksCompanyDepartmentModalContext
    );
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { handleToast } = useToast();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [companies, setCompanies] = useState<EntityPendingTasksFormValues[]>([]);
    const [departments, setDepartments] = useState<EntityPendingTasksFormValues[]>([]);
    const [companyDateRangeLimits, setCompanyDateRangeLimits] = useState<DateRangeMinMaxValues[]>([]);
    const [departmentDateRangeLimits, setDepartmentDateRangeLimits] = useState<DateRangeMinMaxValues[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingClosed, setIsLoadingClosed] = useState<boolean>(false);
    const [isLoadingNotClosed, setIsLoadingNotClosed] = useState<boolean>(false);
    const [isConfimModalOpen, setIsConfimModalOpen] = useState<boolean>(false);
    const [isOpenCompaniesCollapsable, setIsOpenCompaniesCollapsable] = useState<boolean>(true);
    const [isOpenDepartmentsCollapsable, setIsOpenDepartmentsCollapsable] = useState<boolean>(true);
    const [entityStatus, setEntityStatus] = useState<OpenCloseStatusCode>("CLOSED");

    const confirmModalTitle: Record<OpenCloseStatusCode, string> = {
        OPEN: translateCustomerTypeKeys(PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_TITLE, {
            firstReplace: "SINGULAR",
            isGenderSensitive: true,
        }),
        CLOSED: translateCustomerTypeKeys(PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_CLOSED_TITLE, {
            firstReplace: "PLURAL",
        }),
    };

    const confirmModalDescription: Record<OpenCloseStatusCode, string> = {
        OPEN: translateCustomerTypeKeys(
            PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_NOT_CLOSED_DESCRIPTION,
            { firstReplace: "PLURAL", isGenderSensitive: true }
        ),
        CLOSED: translateCustomerTypeKeys(PendingTasksCompanyDepartmentTranslations.CONFIRM_MODAL_CLOSED_DESCRIPTION, {
            firstReplace: "PLURAL",
            isGenderSensitive: true,
        }),
    };

    const onSelectEntity = (
        id: string,
        entitySetter: (value: SetStateAction<EntityPendingTasksFormValues[]>) => void
    ) =>
        entitySetter((prev) =>
            prev.map((entity) => (entity.id === id ? { ...entity, id: entity.id, checked: !entity.checked } : entity))
        );

    const onChangeDateRange = (
        id: string,
        dates: Date[],
        entitySetter: (value: SetStateAction<EntityPendingTasksFormValues[]>) => void
    ) => {
        const [startDate, endDate] = dates;
        if (startDate)
            entitySetter((prev) =>
                prev.map((entity) =>
                    entity.id === id
                        ? { ...entity, startDate: getDateFormattedSelects(startDate), endDate: undefined }
                        : entity
                )
            );
        if (endDate)
            entitySetter((prev) =>
                prev.map((entity) =>
                    entity.id === id
                        ? {
                              ...entity,
                              startDate: getDateFormattedSelects(startDate),
                              endDate: endDate ? getDateFormattedSelects(endDate) : endDate,
                          }
                        : entity
                )
            );
    };

    const handleUpdateCloseCompaniesAndDepartments = async () => {
        setIsLoadingClosed(true);

        const { status, getParsedError } = await AvailabilityService.SaveCloseCompaniesAndDepartments(
            mapCompaniesAndDepartmentsToEntity(companies, departments, true)
        );
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            reset();
            return;
        }

        handleSuccessManager(
            translateCustomerTypeKeys(
                PendingTasksCompanyDepartmentTranslations.SUCCESS_CLOSE_COMPANIES_AND_DEPARTMENTS,
                { firstReplace: "PLURAL", isGenderSensitive: true }
            )
        );
        reset();
        setIsLoadingClosed(false);
    };

    const handleUpdateNotCloseCompaniesAndDepartments = async () => {
        setIsLoadingNotClosed(true);

        const { status, getParsedError } = await AvailabilityService.SaveNotClosedCompaniesAndDepartments(
            mapCompaniesAndDepartmentsToEntity(companies, departments)
        );
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            reset();
            return;
        }

        handleSuccessManager(
            translateCustomerTypeKeys(
                PendingTasksCompanyDepartmentTranslations.SUCCESS_NOT_CLOSE_COMPANIES_AND_DEPARTMENTS,
                { firstReplace: "PLURAL", isGenderSensitive: true }
            )
        );
        reset();
        setIsLoadingNotClosed(false);
    };

    const confirmModalFetch: Record<OpenCloseStatusCode, () => Promise<void>> = {
        OPEN: handleUpdateNotCloseCompaniesAndDepartments,
        CLOSED: handleUpdateCloseCompaniesAndDepartments,
    };

    const onClickNotClosed = () => {
        setEntityStatus("OPEN");
        setIsConfimModalOpen(true);
    };

    const onClickClosed = () => {
        if (validate()) return;
        setEntityStatus("CLOSED");
        setIsConfimModalOpen(true);
    };

    const validate = () => {
        let checked = false;
        const companiesToUpdate = companies.filter(({ checked }) => checked);
        const departmentsToUpdate = departments.filter(({ checked }) => checked);
        if (!companiesToUpdate.length && !departmentsToUpdate.length) {
            checked = true;
            handleToast({ title: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION), type: "alert", variant: "danger" });
        }
        return checked;
    };

    const getPendingTasksCompanyDepartment = async () => {
        const { data, status } = await AvailabilityService.GetEntitiesWithPendingTasksPerDay();

        if (status() && (!!data.companies.length || !!data.departments.length)) {
            open(data);
            return;
        }
        reset();
    };
    const isAllChecked = () => companies.some(({ checked }) => checked) || departments.some(({ checked }) => checked);
    const isIndeterminated = () =>
        companies.some(({ checked }) => !checked) || departments.some(({ checked }) => !checked);

    const handleCheckAll = () => {
        if (isAllChecked() && !isIndeterminated()) {
            setDepartments((prev) => prev.map((dep) => ({ ...dep, checked: false })));
            setCompanies((prev) => prev.map((comp) => ({ ...comp, checked: false })));
            return;
        }

        setDepartments((prev) => prev.map((dep) => ({ ...dep, checked: true })));
        setCompanies((prev) => prev.map((comp) => ({ ...comp, checked: true })));
    };

    return {
        companies,
        companiesAndDepartmentsWithPendingTasks,
        companyDateRangeLimits,
        confirmModalDescription,
        confirmModalFetch,
        confirmModalTitle,
        departmentDateRangeLimits,
        departments,
        entityStatus,
        getPendingTasksCompanyDepartment,
        isConfimModalOpen,
        isLoading,
        isLoadingClosed,
        isLoadingNotClosed,
        isOpenCompaniesCollapsable,
        isOpenDepartmentsCollapsable,
        onChangeDateRange,
        onClickClosed,
        onClickNotClosed,
        onSelectEntity,
        reset,
        setCompanies,
        setCompanyDateRangeLimits,
        setDepartmentDateRangeLimits,
        setDepartments,
        setEntityStatus,
        setIsConfimModalOpen,
        setIsLoading,
        setIsOpenCompaniesCollapsable,
        setIsOpenDepartmentsCollapsable,
        handleCheckAll,
        isAllChecked,
        isIndeterminated,
    };
};
