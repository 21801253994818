import { FC, ReactNode } from "react";

type CollapsableBodyProps = {
    children: ReactNode;
    open: boolean;
};

export const CollapsableBody: FC<CollapsableBodyProps> = ({ children, open }) => {
    return (
        <div className={`collapsableBody${open ? "" : "--closed"}`} data-testid="collapsableBody">
            <div className="collapsableBody__children">{children}</div>
        </div>
    );
};
