import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { NotificationPill } from "app/components_v2/NotificationPill/NotificationPill";
import { NotificationIcon } from "app/components_v2/NotificationIcon/NotificationIcon";

export const NotificationSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <div>
            <Section title="Notification pill" show={show}>
                <NotificationPill
                    body="Lorem ipsum dolor sit amet consectetur. Consectetur in tortor aliquet dictum"
                    title="Lorem ipsum dolor sit amet "
                    date={new Date()}
                />
                <NotificationPill
                    body="Lorem ipsum dolor sit amet consectetur. Consectetur in tortor aliquet dictum"
                    title="Lorem ipsum dolor sit amet "
                    date={new Date()}
                    seen
                />
            </Section>

            <Section title="Notification icons" show={show}>
                <NotificationIcon />
                <NotificationIcon variant="danger" type="notification" />
                <NotificationIcon variant="warning" type="notification" />
                <NotificationIcon variant="success" type="notification" />
                <NotificationIcon type="alert" />
                <NotificationIcon variant="danger" type="alert" />
                <NotificationIcon variant="warning" type="alert" />
                <NotificationIcon variant="success" type="alert" />
            </Section>
        </div>
    );
};
