import { ReactNode } from "react";
import { TableMobileBodyModel } from "../../types";

type MobileTableBody<T> = {
    data: T[];
    mobileBody: TableMobileBodyModel<T>;
    checkedPage: T[];
    pageSize: number;
    placeholder: ReactNode;
    isLoading?: boolean;
    mobileOnChangeChecked: (checked: boolean, row: T) => void;
    fitHeight?: boolean;
};

export const MobileTableBody = <T extends object>({
    checkedPage,
    data,
    mobileBody,
    mobileOnChangeChecked,
    pageSize,
    placeholder,
    isLoading,
    fitHeight,
}: MobileTableBody<T>) => {
    return (
        <div className={`mobileTableBody${fitHeight ? "--fitHeight" : ""}`}>
            {!!data.length ? (
                data.map((row, i) => {
                    return (
                        mobileBody && (
                            <div className={`mobileTableBody__row`} key={i}>
                                {mobileBody(
                                    row,
                                    i + 1,
                                    (i + 1) % 2 === 0 ? "even" : "odd",
                                    !!checkedPage.find(
                                        //@ts-ignore
                                        (checkedItem) => row?.id === checkedItem?.id
                                    ),
                                    (checked) => mobileOnChangeChecked(checked, row)
                                )}
                            </div>
                        )
                    );
                })
            ) : (
                <div className="mobileTableBody__placeholder">{!isLoading && placeholder}</div>
            )}
            {data.length < pageSize && !!data.length && <></>}
        </div>
    );
};
