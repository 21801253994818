import { FC, useState } from "react";
import { ProviderProps } from "app/state/Providers/types";
import { TaskHisotryGridSfModel, TaskHistoryGridContext } from "../context/taskHistoryGridContext";
import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    INITIAL_TH_SF_VALUES,
    INITIAL_TH_SORT_DIRECTION,
    INITIAL_TH_SORT_FIELD,
} from "../../constants/taskHistoryConstants";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";

export const TaskHistoryGridProvider: FC<ProviderProps> = ({ children }) => {
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>(INITIAL_TH_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_TH_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] = useState<TaskHisotryGridSfModel>(INITIAL_TH_SF_VALUES);
    const [query, setQuery] = useState<string>("");

    const { handleCompanyChange, selectCompany } = useSelectCompanyTable();

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<TaskHisotryGridSfModel>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    return (
        <TaskHistoryGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                sortDirection,
                sortField,
                onSortChange,
                onSFChange,
                secondaryFilterValues,
                onCompanyChange: handleCompanyChange,
                selectCompany,
                query,
                onQueryChange: setQuery,
            }}
        >
            {children}
        </TaskHistoryGridContext.Provider>
    );
};
