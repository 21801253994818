import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { RoleWizardSteps } from "./types";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import Spinner from "app/components_v2/Spinner/Spinner";
import { DateRangePicker, Input, SelectOptions } from "app/components_v2/__inputs";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type Step1Props = RoleWizardSteps & {
    isTemporal?: boolean;
    next: () => void;
};

const INITIAL_ERROR_FORM = {
    name: "",
    date: "",
    user: "",
};

const Step1: FC<Step1Props> = ({ onChangeData, data, isTemporal, next }) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();

    const { roleName, userSelected } = data;

    const [startDate, setStartDate] = useState<Date>(data?.date || new Date());
    const [endDate, setEndDate] = useState<Date | undefined>(data?.endDate);
    const [errors, setErrors] = useState(INITIAL_ERROR_FORM);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const validate = () => {
        if (isTemporal) {
            const validationDate = startDate && endDate;
            let ValidateDate = false;
            let validateUser = true;
            setErrors(INITIAL_ERROR_FORM);
            validationDate
                ? (ValidateDate = true) && setErrors((prev) => ({ ...prev, date: "" }))
                : setErrors((prev) => ({ ...prev, date: t(TranslationCommon.INPUT_NOT_EMPTY) }));

            if (!userSelected.value.length) {
                validateUser = false;
                setErrors((prev) => ({ ...prev, user: t(TranslationCommon.INPUT_NOT_EMPTY) }));
            }
            return ValidateDate && validateUser;
        }
        const validation = roleName.length >= 1;
        let ValidateName = false;

        validation
            ? (ValidateName = true) && setErrors((prev) => ({ ...prev, name: "" }))
            : setErrors((prev) => ({ ...prev, name: t(TranslationCommon.INPUT_NOT_EMPTY) }));
        return ValidateName;
    };

    const handleChangeDate = (date: Date[]) => {
        setStartDate(date[0]);
        setEndDate(date[1] || undefined);
        onChangeData({ ...data, date: date[0], endDate: date[1] });
    };

    const getUsers = async () => {
        setIsLoading(true);
        const { data, status } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `companyId=${session?.workingCompany?.companyId}`,
        });

        if (!status()) {
            handleToast({ variant: "danger", title: t(TranslationCommon.FAILED_DATA_LOADED) });
        }

        setUserOptions(data);
        setIsLoading(false);
    };

    useEffect(() => {
        isTemporal && getUsers();
    }, []);

    return (
        <WizardForm
            validate={(e) => {
                e.preventDefault();
                validate() && next();
            }}
        >
            {!isTemporal && (
                <Input
                    label={`${t(TranslationKeys.WIZARD_ROLE_NAME)}`}
                    placeholder={`${t(TranslationKeys.WIZARD_ROLE_NAME)}`}
                    onChange={(value) => onChangeData({ roleName: value })}
                    value={roleName}
                    errorMessage={errors?.name}
                />
            )}
            {isTemporal && (
                <DateRangePicker
                    range
                    onChange={handleChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    label={t(TranslationKeys.WIZARD_TEMPORARY_ROLE_SELECT_DATES)}
                    showMoreOptions={false}
                    errorMessage={errors?.date}
                    min={new Date()}
                />
            )}

            {isTemporal &&
                (isLoading ? (
                    <Spinner />
                ) : (
                    <SelectOptions
                        isMulti={false}
                        onChange={(newValue) => onChangeData({ userSelected: newValue })}
                        options={userOptions}
                        selectedValue={userSelected.value !== "-1" ? userSelected.value : undefined}
                        errorMessage={errors?.user}
                        label={t(TranslationKeys.WIZARD_TEMPORARY_ROLE_USER)}
                        placeholder={t(TranslationKeys.WIZARD_TEMPORARY_ROLE_PLACEHOLDER_USER)}
                    />
                ))}
        </WizardForm>
    );
};

export default Step1;
