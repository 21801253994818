import { FC, ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

type SectionProps = SectionsShowProp & {
    children: ReactNode | ReactNode[];
    title: string;
};
export type SectionsShowProp = { show?: boolean };

export const Section: FC<SectionProps> = ({ children, title, show = false }) => {
    const [isVisible, setisVisible] = useState(show);

    return (
        <div className="designSys__section">
            <div className="designSys__section__titleContainer" onClick={() => setisVisible(!isVisible)}>
                <p className="designSys__section__titleContainer__title">{title}</p>
                <FontAwesomeIcon icon={isVisible ? faChevronUp : faChevronDown} />
            </div>
            {isVisible ? (
                Array.isArray(children) ? (
                    children.map((el, i) => (
                        <div key={i} className="designSys__section__element">
                            {el}
                        </div>
                    ))
                ) : (
                    <div className="designSys__section__element">{children}</div>
                )
            ) : (
                <div className="designSys__section__element"></div>
            )}
        </div>
    );
};
