import { SortedTypeModel } from "app/components_v2/Table/types";
import { IssueGridSFModel } from "../state/context/issueGridContext";
import { getDateFormattedSelects } from "app/helpers";
import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { getIssueIsOpenByIssueStatusState } from "./getIssueIsOpenByIssueStatusState";

type IExtraparams = IssueGridSFModel & {
    currentTab: IssueTypeCodeModel;
    sortField: string;
    sortDirection: SortedTypeModel;
    companyId: string;
    isCreated?: boolean;
};

export const fillExtraparams = ({
    currentTab,
    sortDirection,
    sortField,
    issueStatusState,
    query,
    senderUserId,
    startDate,
    assetId,
    endDate,
    companyId,
    auditInstanceId,
    isCreated,
    issueClassificationId,
}: IExtraparams): string => {
    const extraparams = new URLSearchParams();

    const isIssueOpen = getIssueIsOpenByIssueStatusState(issueStatusState);

    extraparams.append("IssueType", currentTab);
    extraparams.append("SortField", sortField);
    extraparams.append("SortDirection", sortDirection);
    if (isCreated !== undefined) extraparams.append("IsCreated", String(isCreated));
    if (companyId !== "-1") extraparams.append("CompanyId", companyId);
    if (isIssueOpen != null) extraparams.append("IsOpen", String(isIssueOpen));
    if (query !== undefined) extraparams.append("Query", query);
    if (senderUserId !== undefined && senderUserId !== "-1") extraparams.append("SenderUserId", senderUserId);
    if (startDate !== undefined) extraparams.append("Date", getDateFormattedSelects(startDate));
    if (endDate !== undefined) extraparams.append("Date1", getDateFormattedSelects(endDate));
    if (assetId !== undefined && assetId !== "-1") extraparams.append("AssetId", assetId);
    if (auditInstanceId !== undefined && auditInstanceId !== "-1")
        extraparams.append("AuditInstanceId", auditInstanceId);
    if (issueClassificationId !== undefined && issueClassificationId !== "-1")
        extraparams.append("FK_IssueClassification", issueClassificationId);

    return extraparams.toString();
};
