import { TaskPillAvatar } from "app/components_v2/TaskPill/TaskPillAvatar/TaskPillAvatar";
import { TaskpillValorationHeaderType } from "../types";
import { FC } from "react";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";

export type TaskValorationModalHeaderProps = TaskpillValorationHeaderType & {
    taskName: string;
    rating: number;
};

export const TaskValorationModalHeader: FC<TaskValorationModalHeaderProps> = ({
    id,
    name,
    nameInitials,
    url,
    taskName,
    rating,
}) => {
    return (
        <div className="taskValorationModalHeader">
            <div className="taskValorationModalHeader__info">
                <TaskPillAvatar user={{ id, name, url }} nameInitials={nameInitials} />
                <UserValoration
                    valoration={rating}
                    justify="start"
                    showIcons={false}
                    showValoration={false}
                    width="auto"
                />
            </div>
            <p className="feedback__popup__taskName">{taskName}</p>
        </div>
    );
};
