const prefix = process.env.REACT_APP_STORAGE_PREFIX;
if (!prefix) {
    throw new Error("Missing config REACT_APP_STORAGE_PREFIX from .env file");
}

export enum keys {
    TOKEN = "TOKEN",
    SESSION = "SESSION",
    CUSTOMER_INSTANCE = "CUSTOMER_INSTANCE",
    CONNECTION_ID = "CONNECTION_ID",
}

export const getCompoundKey = (key: string): string => `${prefix}_${key}`;
