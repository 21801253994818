import { FC, useState } from "react";
import { ProviderProps } from "app/state/Providers/types";
import { AuditGridContext, AuditGridSFModel } from "../context/auditGridContext";
import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    INITIAL_AUDIT_GRID_SECONDARY_FILTER,
    INITIAL_AUDIT_GRID_SORT_DIRECTION,
    INITIAL_AUDIT_GRID_SORT_FIELD,
} from "../../constants/auditGridContants";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { useSession } from "app/hooks";
import { AuditHeaderButtons } from "../../AuditGrid/components/AuditHeader/types";

export const AuditGridProvider: FC<ProviderProps> = ({ children }) => {
    const session = useSession();

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>(INITIAL_AUDIT_GRID_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_AUDIT_GRID_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] = useState<AuditGridSFModel>({
        ...INITIAL_AUDIT_GRID_SECONDARY_FILTER,
        companyId: session?.workingCompany?.companyId ? String(session?.workingCompany?.companyId) : "-1",
        companyName: session?.workingCompany?.companyName,
    });
    const [currentFilterButton, setCurrentFilterButton] = useState<AuditHeaderButtons>("IN_PROGRESS");

    const { handleCompanyChange, selectCompany } = useSelectCompanyTable();

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<AuditGridSFModel>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    return (
        <AuditGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                currentTab,
                onChangeCurrentTab: setCurrentTab,
                sortDirection,
                sortField,
                onSortChange,
                onCompanyChange: handleCompanyChange,
                selectCompany,
                secondaryFilterValues,
                onSFChange,
                currentFilterButton,
                onChangeFilterButton: setCurrentFilterButton,
            }}
        >
            {children}
        </AuditGridContext.Provider>
    );
};
