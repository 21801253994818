import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { RoleUserModel } from "app/models/01-SEG/UserRole/RoleUserModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import { RoleNameModel, RoleConfigModel, RoleSessionModel } from "app/dtos/01-SEG/RoleDto";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { RoleScheduledModel } from "app/models/01-SEG/Role/RoleScheduledModel";

export default class RoleService {
    public static async GetRoles(paginationParams: PaginationParams) {
        return FetchService.get<PaginatedResult<RoleModel>>({
            url: `${ApiPaths.SEG_API}/roles`,
            paginationParams: paginationParams,
        });
    }

    public static async GetRolesExport(paginationParams: PaginationParams) {
        return FetchService.get<PaginatedResult<RoleModel>>({
            url: `${ApiPaths.SEG_API}/roles/export`,
            paginationParams: paginationParams,
            csvExport: true,
        });
    }

    public static async GetRoleList(roleIds: number[]) {
        const rolesSeparatedPerComma = roleIds.join(";");
        return FetchService.get<RoleModel[]>({
            url: `${ApiPaths.SEG_API}/roles/list`,
            paginationParams: { extraParams: `roleIds=${rolesSeparatedPerComma}` },
        });
    }

    public static async GetRolesOfInstance(instanceId: string | number) {
        return FetchService.get<PaginatedResult<RoleModel>>({
            url: `${ApiPaths.SEG_API}/roles?customerInstanceId=${instanceId}`,
        });
    }

    public static async GetScheduledRoles(paginationParams: PaginationParams, hasPermission: boolean) {
        const emptyData = new ServiceResponse<PaginatedResult<RoleScheduledModel>>();
        emptyData.data = {
            list: [],
            count: 0,
        };
        if (!hasPermission) return emptyData;
        return FetchService.get<PaginatedResult<RoleScheduledModel>>({
            url: `${ApiPaths.SEG_API}/roles-scheduled`,
            paginationParams: paginationParams,
        });
    }

    public static async GetScheduledRole(id: number | string) {
        return FetchService.get<RoleScheduledModel>({
            url: `${ApiPaths.SEG_API}/roles-scheduled/${id}`,
        });
    }

    public static async SaveScheduledRoles(body: RoleScheduledModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.SEG_API}/roles-scheduled`,
            body: body,
        });
    }

    public static async EditRoleName(body: RoleNameModel, roleId: number | string) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/roles/roleName/${roleId}`,
            body: body,
        });
    }

    public static async EditScheduledRole(body: RoleScheduledModel, roleId: number | string) {
        return FetchService.put<number>({
            url: `${ApiPaths.SEG_API}/roles-scheduled/${roleId}`,
            body: body,
        });
    }

    public static async DeleteScheduledRole(roleId: number | string) {
        return FetchService.delete<string>({
            url: `${ApiPaths.SEG_API}/roles-scheduled/${roleId}`,
        });
    }

    public static async GetOne(id: number | string, instanceId: number) {
        return FetchService.get<RoleModel>({
            url: `${ApiPaths.SEG_API}/roles/${id}?customerInstanceId=${instanceId}`,
        });
    }

    public static async GetRolesWithUser(id: number | string, paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<RoleUserModel>>({
            url: `${ApiPaths.SEG_API}/roles/${id}/users`,
            paginationParams: paginationParams,
        });
    }

    public static async ExportRolesWithUser(id: number | string, paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<RoleUserModel>>({
            url: `${ApiPaths.SEG_API}/roles/${id}/users/export`,
            paginationParams: paginationParams,
            csvExport: true,
        });
    }

    public static async Save(body: RoleModel) {
        return FetchService.post<RoleModel>({
            url: `${ApiPaths.SEG_API}/roles`,
            body: body,
        });
    }

    public static async Update(body: RoleModel) {
        return FetchService.put<number>({
            url: `${ApiPaths.SEG_API}/roles/${body.id}`,
            body: body,
        });
    }

    public static async UpdatePermissions(body: RoleModel) {
        return FetchService.put<number>({
            url: `${ApiPaths.SEG_API}/roles/${body.id}/permissions`,
            body: body,
        });
    }

    public static async GetRoleConfig() {
        return FetchService.get<PaginatedResult<RoleConfigModel>>({
            url: `${ApiPaths.SEG_API}/configs/modules`,
        });
    }

    public static async Delete(id: string | number) {
        return FetchService.delete<number>({
            url: `${ApiPaths.SEG_API}/roles/${id}`,
        });
    }

    public static async DeleteUserRole(id: string | number, userId: string | number) {
        return FetchService.delete<PaginatedResult<RoleUserModel>>({
            url: `${ApiPaths.SEG_API}/roles/${id}/user/${userId}`,
        });
    }

    public static async GetUserPermissions(userId: number, instanceId: number) {
        return FetchService.get<RoleSessionModel[]>({
            url: `${ApiPaths.SEG_API}/roles/users/${userId}/permissions?customerInstanceId=${instanceId}`,
        });
    }
}
