import { FC } from "react";

type Props = {
    quantity?: number;
};

export const TaskPillSkelleton: FC<Props> = ({ quantity = 1 }) => {
    return (
        <div className="taskpillListskelleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div key={index} className="blog-post o-media">
                    <div className="o-media__body">
                        <div className="o-vertical-spacing">
                            <div>
                                <div className="skeletonTaskpillHeader">
                                    <div className="skeletonTaskpillHeader__left">
                                        <span className="skeleton-box skeletonTaskpillHeader__left__avatar"></span>
                                        <span className="skeleton-box skeletonTaskpillHeader__left__username"></span>
                                    </div>
                                    <div className="skeletonTaskpillHeader__right">
                                        <span className="skeleton-box skeletonTaskpillHeader__right__badge"></span>
                                        <span className="skeleton-box skeletonTaskpillHeader__right__checkbox"></span>
                                    </div>
                                </div>
                                <span className="skeleton-box w-75"></span>
                                <span className="skeleton-box w-30"></span>
                                <div className="skeletonTaskpillFooter">
                                    <span className="skeleton-box skeletonTaskpillFooter__date"></span>
                                    <div className="skeletonTaskpillFooter__container">
                                        <span className="skeleton-box skeletonTaskpillFooter__container__icons"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
