import { FC } from "react";
import { BagdgeIcon } from "./BagdgeIcon/BagdgeIcon";
import { BadgeTitle } from "./BadgeTitle/BadgeTitle";
import { BadgeType } from "./badgeTypes";

export const Badge: FC<BadgeType> = ({
    title,
    bolderTextEnd,
    bolderTextStart,
    variant = "grey",
    size = "md",
    icon,
    iconPosition,
    outline = false,
    disabled,
    theme = "light",
    ariaLabel,
    options,
}) => {
    return (
        <div
            className={`badge${outline ? "--outline" : ""}--${variant} ${theme} badge--${size}  ${
                !title && icon ? `badge--solo--${size}` : ""
            } ${disabled ? "badge--disabled" : ""} ${options?.clamp ? "badge--clamp" : ""}`}
            aria-label={ariaLabel}
        >
            {icon && !iconPosition && !title && (
                <BagdgeIcon
                    icon={icon}
                    variant={variant}
                    size={size}
                    outline={outline}
                    disabled={disabled}
                    theme={theme}
                />
            )}
            {icon && iconPosition === "left" && (
                <BagdgeIcon
                    icon={icon}
                    variant={variant}
                    size={size}
                    outline={outline}
                    disabled={disabled}
                    theme={theme}
                />
            )}
            {(title || bolderTextStart || bolderTextEnd) && (
                <BadgeTitle
                    variant={variant}
                    size={size}
                    title={title}
                    bolderTextEnd={bolderTextEnd}
                    bolderTextStart={bolderTextStart}
                    disabled={disabled}
                    theme={theme}
                    clamp={options?.clamp}
                />
            )}
            {icon && iconPosition === "right" && (
                <BagdgeIcon
                    icon={icon}
                    variant={variant}
                    size={size}
                    outline={outline}
                    disabled={disabled}
                    theme={theme}
                />
            )}
        </div>
    );
};
