import { v4 } from "uuid";
import { useToast } from "../Toast/useToast";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { OnBoardingStepsTranslation } from "app/translation/translationKeys";

export const useSendEmailToast = () => {
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();

    const id = useRef<string>(v4());

    const sendingMailToast = () => {
        handleToast({
            title: t(OnBoardingStepsTranslation.ONBOARDING_SENDING_EMAIL_TITLE),
            type: "alert",
            variant: "primary",
            isLoading: true,
            id: id.current,
        });
    };

    const emailSendedToast = () => {
        handleToast({
            title: t(OnBoardingStepsTranslation.ONBOARDING_EMAIL_SENDED_TITLE),
            type: "alert",
            variant: "success",
        });
    };

    const removeSendingMailToast = () => {
        removeToast(id.current);
    };

    return { sendingMailToast, removeSendingMailToast, emailSendedToast };
};
