import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { CompanyWizardSteps, yesNoItems } from "./types";
import ButtonGroup from "app/components_v2/__buttons/Buttongroup/ButtonGroup";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { DepartmentsCheckbox } from "./components/DepartmentsCheckbox/DepartmentsCheckbox";
import { DepartmentWithSubsModel, SubdepartmentUsersModel } from "app/dtos/01-SEG/DepartmentDto";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";

const Step3: FC<CompanyWizardSteps> = ({ data, onChangeData, next }) => {
    const { t } = useTranslation();

    const { copyExistingUsers, departments } = data;

    const [error, setError] = useState<string>("");

    const onSelectYesNo = ({ value }: ButtonGroupItem) => {
        const prevValue = copyExistingUsers !== undefined ? (copyExistingUsers ? "YES" : "NO") : undefined;
        if (value === prevValue) return;
        const selectedValue = value === "YES";

        onChangeData({
            copyExistingUsers: selectedValue,
            departments: departments.map((dep) => ({
                ...dep,
                users: [],
                subs: dep.subs.map((sub) => ({ ...sub, users: [] })),
            })),
        });
        if (!selectedValue) next();
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let errors = false;
        if (copyExistingUsers === false) {
            next();
            return;
        }
        if (copyExistingUsers === undefined) {
            setError(t(TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_USER));
            errors = true;
        } else if (!departments.some(({ users }) => users.length > 0)) {
            setError(t(TranslationErrors.WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT));
            errors = true;
        }
        if (!errors) next();
    };

    const handleDepartmentCheck = (department: DepartmentWithSubsModel, isChecked: boolean) => {
        if (isChecked) {
            onChangeData({
                departments: departments.map((dep) =>
                    dep.id === department.id ? { ...dep, users: department.users } : dep
                ),
            });
            return;
        }

        onChangeData({
            departments: departments.map((dep) =>
                dep.id === department.id
                    ? { ...dep, users: [], subs: dep.subs.map((sub) => ({ ...sub, users: [] })) }
                    : dep
            ),
        });
    };

    const handleCheckSubDept = (
        subdepartment: SubdepartmentUsersModel,
        department: DepartmentWithSubsModel,
        isChecked: boolean
    ) => {
        if (isChecked) {
            const newDepartments = departments.map((dep) =>
                dep.id === department.id
                    ? {
                          ...dep,
                          subs: dep.subs.map((sub) =>
                              sub.id === subdepartment.id ? { ...sub, users: subdepartment.users } : sub
                          ),
                          users: dep.users.length === 0 ? department.users : dep.users,
                      }
                    : dep
            );

            onChangeData({
                departments: newDepartments,
            });

            return;
        }

        onChangeData({
            departments: departments.map((dep) =>
                dep.id === department.id
                    ? {
                          ...dep,
                          subs: dep.subs.map((sub) => (sub.id === subdepartment.id ? { ...sub, users: [] } : sub)),
                      }
                    : dep
            ),
        });
    };

    useEffect(() => {
        !departments.length && next();
    }, []);

    return (
        <WizardForm validate={validate}>
            <div className="companyWizardStep2__buttonGroup">
                <ButtonGroup
                    label={t(TranslationKeys.WIZARD_COMPANY_IMPORT_USER_STRUCT)}
                    items={yesNoItems(t)}
                    selectedValue={copyExistingUsers !== undefined ? (copyExistingUsers ? "YES" : "NO") : undefined}
                    onSelect={onSelectYesNo}
                    minWidth
                />
            </div>
            {copyExistingUsers && (
                <div className="companyWizardStep3">
                    {departments.length &&
                        departments
                            .filter(({ usersCount }) => usersCount > 0)
                            .map((department) => (
                                <DepartmentsCheckbox
                                    key={department.id}
                                    department={department}
                                    isDepartmentChecked={department.usersCount === department.users.length}
                                    subdepartmentsChecked={department.subs
                                        .filter(({ users }) => !!users.length)
                                        .map(({ id }) => id)}
                                    onCheckDepartment={handleDepartmentCheck}
                                    onCheckSubdepartment={handleCheckSubDept}
                                />
                            ))}
                </div>
            )}
            {!!error.length && <ErrorMessage errorMessage={error} />}
        </WizardForm>
    );
};

export default Step3;
