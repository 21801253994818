import { FC } from "react";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { TranslationTitles } from "app/translation/translationKeys";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { PublicPaths } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import ResetPassword from "./ResetPasswordForm/ResetPassword";

type ResetPasswordPageProps = {
    isNewPassword?: boolean;
};

export const ResetPasswordPage: FC<ResetPasswordPageProps> = ({ isNewPassword }) => {
    const { t } = useTranslation();
    useTitle(
        t(isNewPassword ? TranslationTitles.ACTIVATE_ACCOUNT_PAGE_TITLE : TranslationTitles.RESET_PASSWORD_PAGE_TITLE)
    );

    return (
        <AuthLayout>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path="" element={<ResetPassword isNewPassword={isNewPassword} />}></Route>
            </Routes>
        </AuthLayout>
    );
};
