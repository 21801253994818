import { PrivatePaths } from "app/shared/Constants";

export const getQrCode = (value: string): string | undefined => {
    if (!value.length) return undefined;

    const qrValueIsForDashboard = value
        .toLocaleLowerCase()
        .includes(`${PrivatePaths.DASHBOARD}/qr`.toLocaleLowerCase());

    if (!qrValueIsForDashboard) {
        return value;
    }

    const qr = value
        .split("/")
        .filter((x) => !!x.length)
        .pop();
    return qr;
};
