import { FC } from "react";
import { MobileTaskBodyProps } from "../type";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import {
    faTriangleExclamation,
    faClipboardListCheck,
    faCamera,
    faClipboardList,
} from "@fortawesome/pro-solid-svg-icons";

export const MobileTaskBody: FC<MobileTaskBodyProps> = ({
    hours,
    name,
    isCritical,
    hasCheckList,
    isPhotoRequired,
    hasTaskFields,
}) => {
    return (
        <div className="mobileTaskBody">
            <p className="mobileTaskBody__description">{name}</p>
            <div className="mobileTaskBody__container">
                <div>
                    <p className="mobileTaskBody__hours">{hours}</p>
                </div>
                <CellIcons
                    variant="ligthGray"
                    icons={[
                        {
                            icon: faTriangleExclamation,
                            hidden: !isCritical,
                        },
                        {
                            icon: faClipboardListCheck,
                            hidden: !hasCheckList,
                        },
                        {
                            icon: faCamera,
                            hidden: !isPhotoRequired,
                        },
                        {
                            icon: faClipboardList,
                            hidden: !hasTaskFields,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
