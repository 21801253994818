import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FC } from "react";
import { taskHoursModel } from "app/models/02-TAR/TaskWizard";
import { TaskHourValidation } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/models";
import { TaskRangeHour } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

export type TaskTemporalityRangeHourProps = {
    taskHours: taskHoursModel[];
    isDisabled?: boolean;
    errorMessage: TaskHourValidation;
    onDeleteHour: (id: string) => void;
    onAddNewHour: () => void;
    onAddNewRangeHour: () => void;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityRangeHour: FC<TaskTemporalityRangeHourProps> = ({
    taskHours,
    isDisabled,
    errorMessage,
    onChange,
    onDeleteHour,
    onAddNewHour,
    onAddNewRangeHour,
}) => {
    const { t } = useTranslation();

    const taskHoursNoRange = taskHours.filter(({ type }) => type === "hour");
    const taskHoursRange = taskHours.filter(({ type }) => type === "range");
    return (
        <div className="taskTemporalityRangeHour">
            <div className="taskTemporalityRangeHour__hours">
                {taskHoursNoRange.map(({ hour, id }) => (
                    <TaskRangeHour
                        hour={hour}
                        id={id}
                        onChange={onChange}
                        onDeleteHour={onDeleteHour}
                        taskHours={taskHours}
                        key={id}
                        type={"hour"}
                        errorMessage={errorMessage.errors.find((el) => el.id === id)?.value}
                        disabled={isDisabled}
                    />
                ))}

                {taskHours.length < 3 && !isDisabled && (
                    <AddNewButton onClick={onAddNewHour} icon={faPlus}>
                        {t(TranslationKeys.ADD_HOUR)}
                    </AddNewButton>
                )}
            </div>

            <div className="taskTemporalityRangeHour__multiHours">
                {taskHoursRange.map(({ hour, id, maxHour }) => (
                    <TaskRangeHour
                        hour={hour}
                        maxHour={maxHour}
                        id={id}
                        onChange={onChange}
                        onDeleteHour={onDeleteHour}
                        taskHours={taskHours}
                        key={id}
                        type={"range"}
                        errorMessage={errorMessage.errors.find((el) => el.id === id)?.value}
                        disabled={isDisabled}
                    />
                ))}
                {taskHours.length < 3 && !isDisabled && (
                    <AddNewButton onClick={onAddNewRangeHour} icon={faPlus}>
                        {t(TranslationKeys.ADD_RANGE_HOUR)}
                    </AddNewButton>
                )}
            </div>

            {!!errorMessage.value.length && <ErrorMessage errorMessage={errorMessage.value} />}
        </div>
    );
};
