import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./en";
import { es } from "./es";

const resources = {
    en,
    es,
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "es",

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
