import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FC } from "react";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { RecurrentTaskModel } from "app/models/02-TAR/TaskWizard";
import { CustomDaysBubles } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/components/CustomDaysBubles";

type CustomWeekProps = {
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
    recurringTask: RecurrentTaskModel;
    customError: string;
    disabled?: boolean;
    className?: string;
    errorClassName?: string;
    bubbleType?: "primary" | "dark";
};

export const CustomWeek: FC<CustomWeekProps> = ({
    onChange,
    recurringTask,
    customError,
    disabled,
    className = "",
    bubbleType,
}) => {
    return (
        <>
            <div className="custom_day_bubles__wrapper">
                {recurringTask.custom.days.map(({ isActive, value, id }, i) => (
                    <CustomDaysBubles
                        recurringTask={recurringTask}
                        isActive={isActive}
                        onChange={onChange}
                        value={value}
                        key={id}
                        disabled={disabled}
                        className={className}
                        bubbleType={bubbleType}
                        id={id}
                    />
                ))}
            </div>
            {!!customError?.length && <ErrorMessage errorMessage={customError} />}
        </>
    );
};
