import { createContext } from "react";

type Context = {
    previousPathName: string;
    changeLastPathName: (pathName: string) => void;
    changePathNames: () => void;
    pathNames: string[];
    previousGoBack2: string;
    setPathNames: (value: string[]) => void;
};
export const PreviousPathNameContext = createContext<Context>({} as Context);
