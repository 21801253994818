import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Spinner from "app/components_v2/Spinner/Spinner";
import { FC, useState } from "react";
import { ButtonIcon } from "./ButtonIcon/ButtonIcon";
import type { ButtonSize, ButtonType, ButtonVariants } from "./ButtonIcon/types";
import { mergeClassNames } from "app/helpers/classNames/mergeClassNames";

export type ButtonProps = {
    variant?: ButtonVariants;
    size?: ButtonSize;
    type?: ButtonType;
    text?: string;
    iconRight?: IconProp;
    iconLeft?: IconProp;
    isLoading?: boolean;
    isDisabled?: boolean;
    buttonType?: "button" | "submit" | "reset";
    form?: string;
    fullWidth?: boolean;
    transparent?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Button: FC<ButtonProps> = ({
    iconLeft,
    iconRight,
    text = "",
    type = "contained",
    variant = "primary",
    size = "sm",
    isLoading,
    isDisabled,
    buttonType,
    form,
    fullWidth,
    onClick,
    transparent = false,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onlyIcon = !text?.length && !!(iconLeft || iconRight);

    /* prettier-ignore */
    return (
        <button
            onMouseDown={() => setIsFocused(true)}
            onMouseUp={() => setIsFocused(false)}
            onMouseLeave={() => setIsFocused(false)}
            className={mergeClassNames(
                "button", 
                `button--${size}`, 
                `button--${type}`, 
                `button--${variant}--${type}`, 
                {
                    [`button--${variant}--${type}--focus`]: isFocused,
                    fullWidth: fullWidth,
                    [`button--onlyIcon--${size}`]: onlyIcon,
                    [`button--disabled--${type}`]: isDisabled,
                    [`button--${variant}--${type}--loading`]: isLoading,
                    [`button--${variant}--transparent`]: transparent && type === "contained",
                    [`button--${variant}--transparent--loading`]: transparent && type === "contained" && isLoading
            })}
            onClick={(e) => {
                if (isDisabled || isLoading) return;
                onClick && onClick(e);
            }}
            type={buttonType}
            form={form}
            aria-busy={!!isLoading}
            aria-disabled={!!(isDisabled || isLoading)}
            disabled={isDisabled || isLoading}
        >
            {iconLeft && !isLoading && <ButtonIcon icon={iconLeft} />}
            {isLoading && <Spinner variant={variant} />}
            {text}
            {iconRight && !isLoading && <ButtonIcon icon={iconRight} />}
        </button>
    );
};
