import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { TranslationKeys } from "app/translation/translationKeys";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { Label } from "../__inputs/Label";
import { DragFile } from "../__draggableFiles/DragFile/DragFile";
import { ImageDragFile } from "../__draggableFiles/DragFile/types";

type DragFileWithLabelProps = {
    onChange: (value: ImageDragFile[]) => void;
    onDelete: (id: string) => void;
    onEditImage?: (image: ImageDragFile, editedImageId: string) => void;
    isOptional?: boolean;
    images?: ImageDragFile[];
};

export const DragFileWithLabel: FC<DragFileWithLabelProps> = ({
    isOptional = true,
    onChange,
    onDelete,
    onEditImage,
    images,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    return (
        <div className="dragFileWithLabel">
            <Label label={t(TranslationKeys.ATTACH_FILES_LABEL)} isOptional={isOptional} />
            <div className="dragFileWithLabel__preview">
                <DragFile
                    name={t(TranslationKeys.PHOTOGRAPHIC_REGISTER)}
                    placeholder={t(TranslationKeys.CLICK_TO_UPLOAD)}
                    subPlaceholder={t(TranslationKeys.UPLOAD_TYPE)}
                    onChange={onChange}
                    multiple={true}
                    onDelete={onDelete}
                    images={images}
                    captureMode={hasPermissionToView(session, SecScreen.ALLOW_GALERY_PHOTO) ? "both" : "camera"}
                    onEditImage={onEditImage}
                    editable={!!onEditImage}
                />
            </div>
        </div>
    );
};
