import { LegendItem } from "app/components_v2/SupervisorAnalist/Legend/LegendItem/LegendItem";
import { SupervisorAnalistDarkColor, SupervisorAnalistLightColor } from "app/components_v2/SupervisorAnalist/types";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC, useEffect, useRef, useState } from "react";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { DoughnutChartProps } from "./types";
import { LegendItemSkelleton } from "../__skelletons/LegendItemSkelleton/LegendItemSkelleton";

export const DoughnutChart: FC<DoughnutChartProps> = ({
    data,
    onSelectChange,
    row,
    maxWidth = 400,
    allowOffset = true,
    clickable = true,
    title,
    dounutContainerId = "",
    isLoading = false,
    skelletonOptions,
    showEmptyItems = false,
}) => {
    const chartRef = useRef<any>();
    const { t } = useTranslation();
    const [internalData, setInternalData] = useState(data);

    const lightColors: Record<SupervisorAnalistDarkColor, string> = {
        red: "#fba9bc",
        yellow: "#feee95",
        green: "#9eed87",
        blue: "#7eafcf",
        darkRed: "#c9264b",
    };

    const darkColors: Record<SupervisorAnalistDarkColor, string> = {
        red: "#ED5879",
        yellow: "#FAC515",
        green: "#74DF54",
        blue: "#558baf",
        darkRed: "#a41837",
    };

    const darkToLight: Record<SupervisorAnalistDarkColor, SupervisorAnalistLightColor> = {
        blue: "lightBlue",
        green: "lightGreen",
        red: "lightRed",
        yellow: "lightYellow",
        darkRed: "lightDarkRed",
    };

    const setColors = (currentId: number) => {
        return internalData?.map((x) => (x.id === currentId ? darkColors[x.color] : lightColors[x.color]));
    };

    const insideLabel = ` % ${t(TranslationKeys.PENDING_TASKS_1)}`;

    const [backgroundColor, setBackgroundColor] = useState<string[]>(
        data?.map((x) => (x.isFocused ? darkColors[x.color] : lightColors[x.color]))
    );
    const [offset, setOffset] = useState<number[]>([]);
    const labels = internalData?.map((x) => x.label);
    const dataValues = internalData?.map((x) => x.percentage);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: insideLabel,
                data: dataValues,
                backgroundColor: backgroundColor,
                hoverOffset: 20,
                offset: offset,
                hoverBackgroundColor: backgroundColor,
                hoverBorderColor: ["#525252"],
            },
        ],
    };

    const onChange = (currentId: number) => {
        const selectedItem = internalData.find((x) => x.id === currentId);
        if (!selectedItem) return;
        setInternalData((prev) => prev?.map((x) => ({ ...x, isFocused: x.id === currentId })));
        setBackgroundColor(setColors(currentId));
        setOffset(internalData?.map((x) => (x.id === currentId ? 20 : 0)));
        onSelectChange && onSelectChange(selectedItem);
    };

    const initData = () => {
        setInternalData(data);
        setOffset(data?.map((x) => (x.isFocused && allowOffset ? 20 : 0)));
        setBackgroundColor(data?.map((x) => (x.isFocused ? darkColors[x.color] : lightColors[x.color])));
    };

    useEffect(() => {
        initData();
    }, [data]);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <p className="percentageStars__title">{title}</p>
            <div className={`customDoughnutComponent2${row ? "--row" : ""}`}>
                <div id={dounutContainerId} className={`dounutContainerClassName`}>
                    <Doughnut
                        ref={chartRef}
                        data={chartData}
                        onClick={(element) => {
                            clickable && onChange(getElementAtEvent(chartRef.current, element)[0]?.index);
                        }}
                        options={{
                            cutout: "35%",
                            layout: {
                                padding: 30,
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={"customDoughnutComponent2__label"}>
                    {isLoading ? (
                        <LegendItemSkelleton
                            hasPercentage={skelletonOptions?.hasPercentage}
                            quantity={skelletonOptions?.quantity}
                        />
                    ) : (
                        internalData
                            ?.filter(({ percentage }) => percentage !== 0 || showEmptyItems)
                            .map((x, i) => (
                                <div key={i}>
                                    <LegendItem
                                        color={x.isFocused ? x.color : darkToLight[x.color]}
                                        label={x.label}
                                        count={x.count}
                                        rounded
                                        onClick={
                                            !!x.count && !x.isFocused && clickable ? () => onChange(x.id) : undefined
                                        }
                                        percentage={x.percentage}
                                    />
                                </div>
                            ))
                    )}
                </div>
            </div>
        </div>
    );
};
