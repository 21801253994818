import { FC, useEffect, useState } from "react";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { useSession } from "app/hooks";
import { UserService } from "app/services";
import { SubdepartmentUsersModel } from "app/dtos/01-SEG/DepartmentDto";

type SubdepartmentsCheckboxProps = {
    subdept: SubdepartmentUsersModel;
    onCheckSubdepartment: (subdepartments: SubdepartmentUsersModel, isChecked: boolean) => void;
    isSubdepartmentChecked: boolean;
    fetchUserData?: boolean;
};

export const SubdepartmentsCheckbox: FC<SubdepartmentsCheckboxProps> = ({
    isSubdepartmentChecked,
    onCheckSubdepartment,
    subdept,
}) => {
    const session = useSession();
    const [usersInSubdept, setUsersInSubdept] = useState<number[]>([]);

    const { id, name, usersCount } = subdept;

    const getUserByDeptIdAndSubdeptId = async () => {
        const { data, status } = await UserService.GetData({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&subdepartmentId=${id}`,
        });
        if (!status()) return;
        setUsersInSubdept(data.list.map(({ id }) => id));
    };

    useEffect(() => {
        getUserByDeptIdAndSubdeptId();
    }, []);

    return (
        <CheckBox
            label={`${name} (${usersCount} usuarios)`}
            onChange={(isChecked) => onCheckSubdepartment({ ...subdept, users: usersInSubdept }, isChecked)}
            checked={isSubdepartmentChecked}
            htmlFor={`checkbox${id}`}
        />
    );
};
