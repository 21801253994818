import Compressor from "compressorjs";
type ICompressImage = {
    file: File;
    quality?: number;
    maxHeight?: number;
    maxWidth?: number;
    convertSize?: number;
};
export const compressImage = async ({
    file,
    convertSize = 1000,
    maxHeight = 2000,
    maxWidth = 2000,
    quality = 0.5,
}: ICompressImage): Promise<File | Blob> => {
    return await new Promise((resolve, reject) => {
        new Compressor(file, {
            quality,
            maxHeight,
            maxWidth,
            convertSize,
            success: resolve,
            error: reject,
            convertTypes: "image/png",
        });
    });
};
