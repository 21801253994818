export const emojiMap: Record<string, string> = {
    ":)": "😊",
    ":(": "😢",
    ";)": "😉",
    "<3": "❤️",
    ":D": " 😁",
    ":P": "😛",
    ":O": "😮",
    ":*": "😘",
    ":|": "😐",
    ":/": "😕",
    ":poop:": "💩",
    ":z": "🤐",
    ":rocket:": "🚀",
};

export const emojRange = [
    [128513, 128591],
    [129296, 129297],
    [128128, 128129],
    [129320, 129328],
    [129395, 129399],
    [129314, 129318],
    [128640, 128641],
    [128064, 128081],
    [128175, 128176],
    [128405, 128407],
    [128169, 128171],
    [128184, 128185],
    [128293, 128294],
    [129304, 129311],
    [128150, 128158],
    [129360, 129392],
    [127866, 127868],
];

export const mapEmojiToString = (emoji: string): string => {
    let mappedEmoji = emoji;
    for (const abbreviation in emojiMap) {
        if (Object.prototype.hasOwnProperty.call(emojiMap, abbreviation)) {
            const emojiCode = emojiMap[abbreviation];
            mappedEmoji = mappedEmoji.replace(
                new RegExp(abbreviation.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"),
                emojiCode
            );
        }
    }
    return mappedEmoji;
};

export const emojis = emojRange.map(([start, end]) => Array.from({ length: end - start }, (x, i) => i + start)).flat();
