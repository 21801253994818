import { FC } from "react";
import { FullScreenQrModalManualPlaceholder } from "./FullScreenQrModalManualPlaceholder/FullScreenQrModalManualPlaceholder";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";
import { OtpInput } from "app/components_v2/__inputs/OtpInput/OtpInput";
import { useTranslation } from "react-i18next";
import { ScanQRTranslations } from "app/translation/translationKeys";
import { FullScreenQrType } from "../types";
import { SquareInputSize } from "app/components_v2/__inputs/SquareInput/types";

type FullScreenQrModalManualProps = {
    onScanQr: (qrData: string) => void;
    type: FullScreenQrType;
    title?: string;
};

export const FullScreenQrModalManual: FC<FullScreenQrModalManualProps> = ({ onScanQr, type, title }) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();

    const placeholder: Record<FullScreenQrType, string> = {
        SENSOR: "162C6034",
        TASK: "PA0001",
    };
    const OTP_LENGTH: Record<FullScreenQrType, number> = {
        SENSOR: 8,
        TASK: 6,
    };
    const size: Record<FullScreenQrType, SquareInputSize> = {
        SENSOR: width >= screenSize.TABLET ? "md" : "sm",
        TASK: "md",
    };

    const handleChange = (value: string, isCompleted: boolean) => {
        if (!isCompleted) return;
        onScanQr(value);
    };

    return (
        <div className="fullScreenQrModalManual">
            {width >= screenSize.TABLET && (
                <h1 className="fullScreenQrModalManual__title">
                    {title || t(ScanQRTranslations.QR_SCANNER_MODAL_ALTERNATIVE_TITLE)}
                </h1>
            )}
            <div className="fullScreenQrModalManual__content">
                <FullScreenQrModalManualPlaceholder placeholder={placeholder[type]} />
                <OtpInput
                    onChange={(_, value, isCompleted) => handleChange(value, isCompleted)}
                    placeholder={placeholder[type]}
                    length={OTP_LENGTH[type]}
                    size={size[type]}
                />
            </div>
        </div>
    );
};
