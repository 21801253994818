import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { useTranslation } from "react-i18next";
import { useToast } from "../Toast/useToast";
import { useFetchErrors } from "../useFetchErrors";
import { useEffect, useState } from "react";
import {
    SelectActionsLastItemsModel,
    SelectActionsModel,
    SelectActionsOptions,
} from "app/components_v2/SelectWithActions/types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import IssueClassificationService from "app/services/05-QUA/IssueClassificationService";
import { IssueTranslation, TranslationCommon } from "app/translation/translationKeys";
import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

type UseIssueClassificationUserInfoForm = {
    onInputChange: (value: string) => void;
    fK_IssueClassification: string;
};

type DeleteIssueClassification = {
    isOpen: boolean;
    issueClassification: IssueClassificationModel | null;
};

export const useIssueClassification = ({
    onInputChange,
    fK_IssueClassification,
}: UseIssueClassificationUserInfoForm) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [issueClassificationOptions, setIssueClassificationOptions] = useState<SelectActionsOptions[]>([]);
    const [isIssueClassificationModalVisible, setIsIssueClassificationModalVisible] = useState<boolean>(false);
    const [selectedIssueClassification, setSelectedIssueClassification] = useState<IssueClassificationModel | null>(
        null
    );
    const [deleteIssueClassification, setDeleteIssueClassification] = useState<DeleteIssueClassification>({
        isOpen: false,
        issueClassification: null,
    });
    const [isDeleteModalLoading, setIsDeleteModalLoading] = useState<boolean>(false);
    const [isLoadingIssueClassification, setIsLoadingIssueClassification] = useState<boolean>(true);

    const onIssueClassificationModalVisibleChange = (isVisible: boolean) =>
        setIsIssueClassificationModalVisible(isVisible);

    const addNewIssueClassification = ({ id, name }: IssueClassificationModel) => {
        onInputChange(String(id));
        setIssueClassificationOptions((prev) => [...prev, { label: name, value: String(id) }]);
        onCancel();
    };

    const openEditModal = ({ label, value }: OptionModel) => {
        const issueClassification: IssueClassificationModel = {
            id: Number(value),
            name: label,
        };

        setSelectedIssueClassification(issueClassification);
        onIssueClassificationModalVisibleChange(true);
    };

    const onEditIssueClassification = ({ id, name }: IssueClassificationModel) => {
        setIssueClassificationOptions((prev) =>
            prev.map((item) => (item.value === String(id) ? { label: name, value: String(id) } : item))
        );
        onCancel();
    };

    const openDeleteModal = ({ label, value }: OptionModel) => {
        const issueClassification: IssueClassificationModel = {
            id: Number(value),
            name: label,
        };
        setDeleteIssueClassification({ isOpen: true, issueClassification });
    };

    const closeDeleteModal = () => {
        setDeleteIssueClassification({ isOpen: false, issueClassification: null });
    };

    const onDeleteIssueClassification = async () => {
        setIsDeleteModalLoading(true);
        const issueClassificationId = deleteIssueClassification.issueClassification?.id || 0;

        const { status } = await IssueClassificationService.Delete(issueClassificationId);
        if (!status()) {
            handleToast({
                title: t(IssueTranslation.ERROR_DELETING_ISSUE_CLASSIFICATION),
                type: "alert",
                variant: "danger",
            });
            setIsDeleteModalLoading(false);
            return;
        }

        setIssueClassificationOptions((prev) => prev.filter(({ value }) => value !== String(issueClassificationId)));
        if (fK_IssueClassification === String(issueClassificationId)) onInputChange("-1");
        setIsDeleteModalLoading(false);
        closeDeleteModal();
    };

    const onCancel = () => {
        onIssueClassificationModalVisibleChange(false);
        setSelectedIssueClassification(null);
    };

    const getIssueClassification = async () => {
        setIsLoadingIssueClassification(true);
        const { data, getParsedError, status } = await IssueClassificationService.GetData();
        if (!status()) {
            setIsLoadingIssueClassification(false);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setIssueClassificationOptions([
            {
                label: t(TranslationCommon.SELECT_OPTION),
                value: "-1",
                hideActions: true,
            },
            ...data.list.map(({ name, id }) => ({ label: name, value: String(id) })),
        ]);
        setIsLoadingIssueClassification(false);
    };

    const selectActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: openEditModal,
            hidden: !hasPermissionToEdit(SecScreen.ISSUE_CLASSIFICATION),
        },
        {
            icon: faTrash,
            onClick: openDeleteModal,
            hidden: !hasPermissionToDelete(SecScreen.ISSUE_CLASSIFICATION),
        },
    ];
    const lastItems: SelectActionsLastItemsModel[] = [
        {
            icon: faPlus,
            onClick: () => onIssueClassificationModalVisibleChange(true),
            title: t(IssueTranslation.ADD_NEW_ISSUE_CLASSIFICATION),
            hidden: !hasPermissionToAdd(SecScreen.ISSUE_CLASSIFICATION),
        },
    ];

    useEffect(() => {
        getIssueClassification();
    }, []);

    return {
        issueClassificationOptions,
        isIssueClassificationModalVisible,
        selectedIssueClassification,
        deleteIssueClassification,
        isDeleteModalLoading,
        addNewIssueClassification,
        onEditIssueClassification,
        onDeleteIssueClassification,
        onCancel,
        selectActions,
        lastItems,
        closeDeleteModal,
        isLoadingIssueClassification,
    };
};
