import { FC } from "react";
import { DashboardFilterdata } from "app/helpers/ExtraParams/Dashboard/DashboardExtraparams";
import { useTranslation } from "react-i18next";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { TaskPillList } from "../TaskPillList";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TaskPillAvatar } from "app/components_v2/TaskPill/type";
import { useSession } from "app/hooks";

export type DashboardTaskInstanceListProps = {
    filterData: DashboardFilterdata;
    isLoading: boolean;
    onDetailClick: (id: number) => void;
    myTasks: TaskInstanceModel[];
    myTasksCount: number;
    onChangePageIndex: (pageIndex: number) => void;
    pageIndex: number;
    currentTab: number;
};

export const DashboardTaskInstanceList: FC<DashboardTaskInstanceListProps> = ({
    filterData,
    isLoading,
    myTasks,
    myTasksCount,
    onChangePageIndex,
    onDetailClick,
    pageIndex,
    currentTab,
}) => {
    const { t } = useTranslation();
    const session = useSession();

    const user: TaskPillAvatar = {
        id: session?.user.id || 0,
        name: `${session?.user.firstName || ""} ${session?.user.lastName || ""}`,
        url: session?.user.profilePictureURL,
    };

    return (
        <>
            {!myTasks.length && !isLoading ? (
                <TableError icon={faListCheck} title={t(TranslationKeys.EMPTY_LIST_TASKI)} size="xl" />
            ) : (
                <TaskPillList
                    isLoading={isLoading}
                    filterData={filterData}
                    onDetailClick={onDetailClick}
                    list={myTasks}
                    total={myTasksCount || 0}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    hideFeedback
                    canBeDisabled
                    user={currentTab === 2 ? user : undefined}
                />
            )}
        </>
    );
};
