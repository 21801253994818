import { FC } from "react";
import { OptionCompanyImage } from "app/components_v2/OptionCompany/OptionCompanyImage/OptionCompanyImage";
import { CellTitle } from "../CellTitle/CellTitle";

type CellPhotoProps = {
    name: string;
    img?: string;
    size?: "xs" | "md" | "xl";
};

export const CellPhoto: FC<CellPhotoProps> = ({ name, img, size = "md" }) => {
    return (
        <div className="cellPhoto">
            <OptionCompanyImage name={name} logoImage={img} size={size} />
            <CellTitle title={name} />
        </div>
    );
};
