import { FC } from "react";
import { RankingPillSkelleton } from "../RankingPillSkelleton/RankingPillSkelleton";
import { PaginationDefaults } from "app/shared/Constants";

type RankingPillListSkeletonProps = {
    variant?: "default" | "percentage";
    quantity?: number;
    hideValoration?: boolean;
};

export const RankingPillListSkeleton: FC<RankingPillListSkeletonProps> = ({
    variant,
    quantity = PaginationDefaults.PAGE_SIZE,
    hideValoration,
}) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, i) => (
                <div className="rankingPillListSkeleton" key={i}>
                    <RankingPillSkelleton variant={variant} hideValoration={hideValoration} />
                </div>
            ))}
        </>
    );
};
