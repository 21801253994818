import { FC } from "react";

type MobileIssueRowBodyProps = {
    id?: string;
    description: string;
    auditCode: string | null;
};

export const MobileIssueRowBody: FC<MobileIssueRowBodyProps> = ({ description, id, auditCode }) => {
    return (
        <div className="mobileIssueRowBody">
            {id && (
                <div className="mobileIssueRowBody__info">
                    <p className="mobileIssueRowBody__id">#{id}</p>
                </div>
            )}
            <p className="mobileIssueRowBody__description">{description}</p>
            {auditCode && <p className="mobileIssueRowBody__auditCode">{auditCode}</p>}
        </div>
    );
};
