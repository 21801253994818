import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Badge } from "app/components_v2/Badge/Badge";
import { faCircle, faArrowCircleUp, faX } from "@fortawesome/pro-solid-svg-icons";
import { TaskPillBadge } from "app/components_v2/TaskPill/TaskPillBadge/TaskPillBadge";
import { TaskPillState } from "app/components_v2/TaskPill/type";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const BadgeSection: FC<SectionsShowProp> = ({ show }) => {
    const [isButtonOutlined, setIsButtonOutlined] = useState<boolean>(false);
    const [isLight, setIsLight] = useState<boolean>(true);
    const { t } = useTranslation();
    const badgetitle = (disabled: boolean, startHour: string, hour: string, state: TaskPillState): string => {
        const mobileBadgeText: Record<TaskPillState, string> = {
            PENDING: t(TranslationKeys.TASK_START_HOUR_MOBILE),
            NEAR_END: t(TranslationKeys.TASK_ENDS_HOUR_MOBILE),
            TIMED_OUT: t(TranslationKeys.TASK_TIMED_OUT_HOUR_MOBILE),
            OUT_OF_TIME: t(TranslationKeys.TASK_OUT_OF_TIME_HOUR_MOBILE),
            IN_TIME: t(TranslationKeys.TASK_COMPLETED_HOUR_MOBILE),
        };

        return disabled ? `${startHour} - ${hour} h` : `${mobileBadgeText[state]} ${hour} h`;
    };
    return (
        <Section title="BADGES" show={show}>
            <button onClick={() => setIsButtonOutlined(!isButtonOutlined)}>
                Outline {isButtonOutlined ? "ON" : "OFF"}
            </button>
            <button onClick={() => setIsLight(!isLight)}>Theme {isLight ? "LIGHT" : "DARK"}</button>

            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge grey sm:</p>
                <Badge
                    outline={isButtonOutlined}
                    title="test"
                    bolderTextEnd="bolder"
                    bolderTextStart="bolder"
                    variant="grey"
                    size="sm"
                    icon={faCircle}
                    iconPosition="left"
                    theme={isLight ? "light" : "dark"}
                />
                <Badge outline={isButtonOutlined} icon={faCircle} theme={isLight ? "light" : "dark"} />
                <Badge outline={isButtonOutlined} icon={faX} theme={isLight ? "light" : "dark"} />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge grey md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="grey"
                    size="md"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge grey lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="grey"
                    size="lg"
                    icon={faCircle}
                    iconPosition="right"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge blue sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="blue"
                    size="sm"
                    icon={faCircle}
                    iconPosition="left"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge blue md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="blue"
                    size="md"
                    icon={faCircle}
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge blue lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="blue"
                    size="lg"
                    icon={faCircle}
                    iconPosition="right"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge red sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    icon={faX}
                    iconPosition="left"
                    variant="red"
                    size="sm"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge red md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    variant="red"
                    size="md"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge red lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Label"
                    icon={faX}
                    iconPosition="right"
                    variant="red"
                    size="lg"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge orange sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    icon={faArrowCircleUp}
                    iconPosition="left"
                    variant="orange"
                    size="sm"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge orange md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    icon={faArrowCircleUp}
                    iconPosition="left"
                    variant="orange"
                    size="md"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge orange lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    icon={faArrowCircleUp}
                    iconPosition="left"
                    variant="orange"
                    size="lg"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge green sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    icon={faArrowCircleUp}
                    iconPosition="left"
                    variant="green"
                    size="sm"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge green md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    variant="green"
                    size="md"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge green lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    variant="green"
                    iconPosition="right"
                    icon={faX}
                    size="lg"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge yellow sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    icon={faX}
                    iconPosition="left"
                    variant="yellow"
                    size="sm"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge yellow md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    variant="yellow"
                    size="md"
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge yellow lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    outline={isButtonOutlined}
                    title="Texto un poco largo"
                    variant="yellow"
                    iconPosition="right"
                    icon={faX}
                    size="lg"
                />
            </div>
            <hr className="designSys__section__separator" />
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge yellow sm:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    title="Texto un poco largo"
                    icon={faX}
                    iconPosition="left"
                    variant="red"
                    size="sm"
                    outline={isButtonOutlined}
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge red md:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    title="Texto un poco largo"
                    variant="red"
                    size="md"
                    outline={isButtonOutlined}
                />
            </div>
            <div className="breadcrumbSection">
                <p className="designSys__section__element__title">Badge red lg:</p>
                <Badge
                    theme={isLight ? "light" : "dark"}
                    title="Texto un poco largo"
                    variant="red"
                    iconPosition="right"
                    icon={faX}
                    size="lg"
                    outline={isButtonOutlined}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                }}
            >
                <TaskPillBadge state="IN_TIME" title={badgetitle(false, "14:00", "15:00", "IN_TIME")} />
                <TaskPillBadge state="NEAR_END" title={badgetitle(false, "14:00", "15:00", "NEAR_END")} />
                <TaskPillBadge state="OUT_OF_TIME" title={badgetitle(false, "14:00", "15:00", "OUT_OF_TIME")} />
                <TaskPillBadge state="PENDING" title={badgetitle(false, "14:00", "15:00", "PENDING")} />
                <TaskPillBadge state="TIMED_OUT" title={badgetitle(false, "14:00", "15:00", "TIMED_OUT")} />
                <TaskPillBadge state="PENDING" title={badgetitle(true, "14:00", "15:00", "PENDING")} disabled />
            </div>
        </Section>
    );
};
