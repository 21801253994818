import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { RoleCollapsableTranslations, TranslationCommon } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

type RoleCollapseBodyTitlesProps = {
    isSelectAllChecked: boolean;
    isIndeterminate: boolean;
    handleSelectAll: () => void;
};
export const RoleCollapseBodyTitles: FC<RoleCollapseBodyTitlesProps> = ({
    handleSelectAll,
    isIndeterminate,
    isSelectAllChecked,
}) => {
    const { t } = useTranslation();

    return (
        <div className="roleCollapseBodyTitles">
            <CheckBox
                checked={isSelectAllChecked}
                intermediate={isIndeterminate}
                onChange={handleSelectAll}
                label={t(RoleCollapsableTranslations.ROLE_COLLAPSABLE_MARK_ALL)}
                htmlFor={`selectAllRoles-${v4()}`}
                size="sm"
            />
            <p className="roleCollapseBodyTitles__title">{t(TranslationCommon.ALL_PATENT)}</p>
            <p className="roleCollapseBodyTitles__title">{t(TranslationCommon.VIEW_PATENT)}</p>
            <p className="roleCollapseBodyTitles__title">{t(TranslationCommon.ADD_PATENT)}</p>
            <p className="roleCollapseBodyTitles__title">{t(TranslationCommon.MODIFY_PATENT)}</p>
            <p className="roleCollapseBodyTitles__title">{t(TranslationCommon.DELETE_PATENT)}</p>
        </div>
    );
};
