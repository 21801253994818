import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import Spinner from "app/components_v2/Spinner/Spinner";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AuditChecklistItem } from "../AuditChecklistItem/AuditChecklistItem";
import { useAuditChecklistModal } from "./hooks/useAuditChecklistModal";

type AuditChecklistModalProps = {
    checklistId: number;
    groupTitle: string;
    checklistTitle: string;
    readonly: boolean;
    allowComplete: boolean;
    auditInstanceId: number;
    auditGroupName: string;
    onChange: (id: number, selectedValue: AuditGroupCheckListItemInstanceValues, fk_task: number | null) => void;
    onCheckAllChecklist: (value: AuditGroupCheckListItemInstanceValues) => void;
    onClose: () => void;
    onComplete: (checklistId: number) => void;
};

export const AuditChecklistModal: FC<AuditChecklistModalProps> = ({
    checklistId,
    onClose,
    groupTitle,
    checklistTitle,
    onChange,
    readonly,
    onComplete,
    allowComplete,
    onCheckAllChecklist,
    auditInstanceId,
    auditGroupName,
}) => {
    const { t } = useTranslation();

    const {
        checklistItems,
        handleCheckListItemchange,
        handleSubmit,
        isLoading,
        isModalOpen,
        isSubmiting,
        handleModalVisibility,
        handleCheckAllUnCheckedItems,
    } = useAuditChecklistModal({ checklistId, onChange, onClose, onComplete, onCheckAllChecklist });

    return (
        <>
            <GenericModal
                header={{ title: groupTitle, onClose, variant: "appcc" }}
                footer={
                    allowComplete ? (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationKeys.COMPLETE_CHECKLIST),
                                onClick: handleSubmit,
                                variant: "appcc",
                            }}
                            closeButton={{ text: t(TranslationCommon.CLOSE), onClick: onClose, variant: "appcc" }}
                            loading={isSubmiting}
                        />
                    ) : undefined
                }
                size="lg"
                hideChildrenPadding
                allowCloseOnEscape={false}
            >
                <div className="auditChecklistModal">
                    {isLoading ? (
                        <div className="auditChecklistModal__spinner">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <h1 className="auditChecklistModal__title">{checklistTitle}</h1>
                            <div className="auditChecklistModal__form">
                                {checklistItems.map((checklistitem) => {
                                    const { id } = checklistitem;
                                    return (
                                        <AuditChecklistItem
                                            {...checklistitem}
                                            key={id}
                                            onChange={handleCheckListItemchange}
                                            readonly={readonly}
                                            auditModelOptions={{
                                                auditInstanceId,
                                                auditInfo: {
                                                    auditGroupName,
                                                    auditChecklisItemtName: checklistitem.name,
                                                },
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            </GenericModal>
            {isModalOpen && (
                <ConfirmModal
                    onClose={() => handleModalVisibility(false)}
                    onConfirm={handleCheckAllUnCheckedItems}
                    title={t(TranslationModals.AUDIT_CHECKLIST_MODAL_TITLE)}
                    description={t(TranslationModals.AUDIT_CHECKLIST_MODAL_DESCRIPTION)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirmText={t(TranslationKeys.COMPLETE_TASK)}
                    variant="appcc"
                />
            )}
        </>
    );
};
