import { getDateFormattedSelects } from "app/helpers";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";

export type buildParamsprops = {
    taskStatus?: string;
    startDateInput?: Date | undefined;
    endDateInput?: Date | undefined;
    departmentId?: string;
    isCompanyList?: boolean;
    isDepartmentList?: boolean;
    companyId?: string;
    workingPosition?: string;
    selectedTaskType?: string;
    selectedWorkingPosition?: string;
    noReviewTaskListTab?: boolean;
    reviewTaskListTab?: boolean;
    commentTaskListTab?: boolean;
    entityType?: AnalystEntityType;
};
type buildParamsWorkerDetailProps = {
    startDateInput?: Date | undefined;
    endDateInput?: Date | undefined;
};

export const buildParams = ({
    taskStatus,
    startDateInput,
    endDateInput,
    departmentId,
    isCompanyList,
    isDepartmentList,
    companyId,
    selectedTaskType = "all",
    selectedWorkingPosition,
    noReviewTaskListTab,
    reviewTaskListTab,
    commentTaskListTab,
    entityType,
}: buildParamsprops) => {
    const params = new URLSearchParams();
    params.append("StartDate", getDateFormattedSelects(startDateInput));
    params.append("EndDate", getDateFormattedSelects(endDateInput));

    params.append("isAPPCC", selectedTaskType === "all" ? "" : selectedTaskType === "no_critic" ? "false" : "true");
    if (!!selectedWorkingPosition?.length && selectedWorkingPosition !== "all")
        params.append("workingPositionId", selectedWorkingPosition);

    taskStatus && params.append("TaskStatus", taskStatus);
    companyId && params.append("CompanyId", companyId);
    params.append("DepartmentId", departmentId ? String(departmentId) : "");
    isCompanyList && params.append("isCompanyList", "true");
    isDepartmentList && params.append("isDepartmentList", "true");
    noReviewTaskListTab && params.append("NoReviewTaskListTab", "true");
    reviewTaskListTab && params.append("ReviewTaskListTab", "true");
    commentTaskListTab && params.append("CommentTaskListTab", "true");
    entityType && params.append("EntityType", entityType);

    return params;
};

export const buildParamsWorkerDetail = ({ startDateInput, endDateInput }: buildParamsWorkerDetailProps) => {
    const params = new URLSearchParams();
    params.append("StartDate", getDateFormattedSelects(startDateInput));
    params.append("EndDate", getDateFormattedSelects(endDateInput));

    return params;
};
