import { FC, useRef, useEffect } from "react";
import { Popover } from "app/components_v2/Popover/Popover";
import { emojis } from "app/helpers/Emojis/emojisHelper";

export type EmojisPopoverProps = {
    popoverTarget: Element | null;
    setIsVisible: (isVisible: boolean) => void;
    selectEmoji: (emoji: number) => void;
};

export const EmojisPopover: FC<EmojisPopoverProps> = ({ popoverTarget, setIsVisible, selectEmoji }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) setIsVisible(false);
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <Popover position="topRight" target={popoverTarget} portal onClickOutside={handleClickOutside} zIndexUp>
            <div ref={wrapperRef} onMouseDown={(e) => e.preventDefault()} className={`textAreaContainer__emoji`}>
                {emojis.map((emoji) => (
                    <p key={emoji} className="emojisInput__container__emoji" onClick={() => selectEmoji(emoji)}>
                        {String.fromCodePoint(emoji)}
                    </p>
                ))}
            </div>
        </Popover>
    );
};
