import { FC } from "react";
import { ButtonGroupItem } from "./types";

type Props = {
    label?: string;
    items: ButtonGroupItem[];
    inverted?: boolean;
    onSelect: (item: ButtonGroupItem) => void;
    selectedValue?: string;
    readOnly?: boolean;
    minWidth?: boolean;
};

const ButtonGroup: FC<Props> = ({ label, items, inverted, onSelect, selectedValue, readOnly, minWidth }) => {
    return (
        <div className="buttonGroup__container">
            {label && <label className="buttonGroup__label">{label}</label>}
            <div className="buttonGroup__content">
                {items.map(({ value, label }) => (
                    <p
                        key={value}
                        onClick={() => !readOnly && onSelect({ value, label })}
                        className={
                            inverted
                                ? `invertedGroup ${selectedValue === value ? "invertedGroup__selected" : ""}  `
                                : `buttonGroup__item${minWidth ? "--minWidth" : ""} ${
                                      selectedValue === value ? "buttonGroup__item__selected" : ""
                                  }`
                        }
                    >
                        {label}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default ButtonGroup;
