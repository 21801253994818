import { useState, useCallback } from "react";

type DateRange = {
    startDate: Date | undefined;
    endDate: Date;
};

type UseDateRangeProps = {
    initialStartDate: Date;
    initialEndDate: Date;
    onDateChange?: ({ startDate, endDate }: DateRange) => void;
};

export const useDateRange = ({ initialStartDate, initialEndDate, onDateChange }: UseDateRangeProps) => {
    const [startDateInput, setStartDateInput] = useState<Date>(initialStartDate);
    const [endDateInput, setEndDateInput] = useState<Date>(initialEndDate);

    const onChange = (dates: Date[]) => {
        const [start, end] = dates;
        setStartDateInput(start);
        setEndDateInput(end);
        onDateChange && onDateChange({ startDate: start, endDate: end });
    };

    return { startDateInput, endDateInput, onChange };
};
