import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { WizardHeader } from "app/components_v2/WizardHeader/WizardHeader";
import { headerStepNumbersModel } from "app/components_v2/wizards/types";

export const WizardHeaderSection: FC<SectionsShowProp> = ({ show }) => {
    const headerValues: headerStepNumbersModel[] = [
        { title: "Descripción", enabled: false },
        { title: "Responsable", enabled: false },
        { title: "Registro", enabled: false },
        { title: "Fecha", enabled: false },
        { title: "Confirmar", enabled: false },
    ];

    const [currentStep, setCurrentStep] = useState(1);

    const handleClick = (value: boolean) => {
        if (value) {
            currentStep < headerValues.length && setCurrentStep((prev) => prev + 1);
            return;
        }
        currentStep > 1 && setCurrentStep((prev) => prev - 1);
    };

    return (
        <Section title="Wizard header" show={show}>
            <button onClick={() => handleClick(true)}>Delante</button>
            <button onClick={() => handleClick(false)}>Atras</button>
            <div className="wizardHeaderSection">
                <WizardHeader
                    headerItems={headerValues}
                    currentStep={currentStep}
                    goTo={setCurrentStep}
                />
            </div>
        </Section>
    );
};
