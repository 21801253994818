import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { BaseAuditGroupCheckListItemModel } from "app/models/05-QUA/BaseAuditGroupCheckListItemModels/BaseAuditGroupCheckListItemModel";

export default class BaseAuditGroupCheckListItemService {
    // GET Single BaseAuditGroupCheckListItem
    public static async GetOne(id: number) {
        return FetchService.get<BaseAuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem/${id}`,
        });
    }

    // GET BaseAuditGroupCheckListItem List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<BaseAuditGroupCheckListItemModel>>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem`,
            paginationParams: params,
        });
    }

    // GET BaseAuditGroupCheckListItem List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    // POST BaseAuditGroupCheckListItem
    public static async Save(body: BaseAuditGroupCheckListItemModel) {
        return FetchService.post<BaseAuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem`,
            body,
        });
    }

    // PUT BaseAuditGroupCheckListItem
    public static async Edit(id: number, body: BaseAuditGroupCheckListItemModel) {
        return FetchService.put<BaseAuditGroupCheckListItemModel>({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem/${id}`,
            body,
        });
    }

    // DELETE BaseAuditGroupCheckListItem
    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/BaseAuditGroupCheckListItem/${id}`,
        });
    }
}
