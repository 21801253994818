import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type CellIconModel = {
    icon: IconProp;
    onClick?: (e?: any) => void;
    hidden?: boolean;
    title?: string;
};

export type CellIconsProps = {
    icons: CellIconModel[];
    variant?: "ligthGray" | "darkGray";
};

export const CellIcons: FC<CellIconsProps> = ({ icons, variant = "darkGray" }) => {
    return (
        <div className="cellIcons">
            {icons.map(
                ({ hidden, ...rest }, i) =>
                    !hidden && (
                        <FontAwesomeIcon
                            {...rest}
                            className={`cellIcons__icon--${variant} ${!!rest.onClick ? "pointer" : "default"}`}
                            key={i}
                        />
                    )
            )}
        </div>
    );
};
