import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { AuditTranslation, TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { StartAuditModal } from "./components/StartAuditModal/StartAuditModal";
import { faPlus, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { useAuditGrid } from "./hooks/useAuditGrid";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { useSession, useTitle, useWindowSize } from "app/hooks";
import { AuditPillList } from "./components/AuditPillList/AuditPillList";
import { Icon } from "app/components_v2/Icon/Icon";
import { AuditFilter } from "./components/AuditFilter/AuditFilter";
import { INITIAL_AUDIT_GRID_SECONDARY_FILTER } from "../constants/auditGridContants";
import { AuditHeader } from "./components/AuditHeader/AuditHeader";
import { PaginationDefaults, screenSize } from "app/shared/Constants";
import { FloatingButton } from "app/components_v2/__buttons/FloatingButton/FloatingButton";
import { AuditSelector } from "./components/AuditSelector/AuditSelector";

export const AuditGrid = () => {
    const { t } = useTranslation();
    const session = useSession();
    const { width } = useWindowSize();

    useTitle(t(TranslationTitles.AUDIT_PAGE_TITLE));

    const {
        createNewModalIsOpen,
        currentTab,
        data,
        handleNavigate,
        handlePageIndexChange,
        isLoading,
        pageIndex,
        selectCompany,
        setCreateNewModalIsOpen,
        total,
        handleCurrentTabChange,
        counters,
        secondaryFilterValues,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        handleSaveSecondaryFilter,
        onChangeFilterButton,
        currentFilterButton,
        sortDirection,
        sortField,
        onChangeOrderBy,
        onCreateNewAudit,
    } = useAuditGrid();

    const tabs: TabPropsReduced[] = [
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.inProgressCounter,
            text: t(AuditTranslation.AUDIT_IN_PROGRESS_TAB),
            type: "dark",
            hidden: true,
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={0}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.completedCounter,
            text: t(AuditTranslation.AUDIT_IN_REVIEW),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={1}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.reviewedCounter,
            text: t(AuditTranslation.AUDIT_REVIEWED),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={2}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.closedCounter,
            text: t(AuditTranslation.AUDIT_CLOSED_TAB),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={3}
                />
            ),
        },
    ];

    return (
        <PageContainer
            fullHeight
            header={
                <TabHeader
                    tabs={
                        <>
                            <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
                            <div className="auditOrderFilterContainer">
                                {width >= screenSize.MOBILE && (
                                    <AuditSelector
                                        sortDirection={sortDirection}
                                        sortField={sortField}
                                        onSortChange={onChangeOrderBy}
                                    />
                                )}
                                <Icon icon={faSliders} onClick={() => setIsSecondaryFilterOpen(true)} theme="dark" />
                            </div>
                        </>
                    }
                    variant="appcc"
                >
                    <AuditFilter
                        filterData={secondaryFilterValues}
                        initialFilterData={{
                            ...INITIAL_AUDIT_GRID_SECONDARY_FILTER,
                            companyId: session?.workingCompany?.companyId
                                ? String(session?.workingCompany?.companyId)
                                : "-1",
                            companyName: session?.workingCompany?.companyName,
                        }}
                        isOpen={isSecondaryFilterOpen}
                        isSecondaryFilterOpen={isSecondaryFilterOpen}
                        onCancel={() => setIsSecondaryFilterOpen(false)}
                        onSave={handleSaveSecondaryFilter}
                        currentTab={currentTab === 3 ? "CLOSED" : "OPEN"}
                        variant="appcc"
                    />
                    <AuditHeader
                        onClick={onChangeFilterButton}
                        currentFilterButton={currentFilterButton}
                        counters={{
                            DONE: counters.reviewedCounter + counters.completedCounter + counters.closedCounter,
                            IN_PROGRESS: counters.inProgressCounter,
                        }}
                        title={`${t(TranslationTitles.AUDIT_GRID_TITLE)} (${selectCompany.company.label})`}
                    />
                </TabHeader>
            }
        >
            {createNewModalIsOpen && (
                <StartAuditModal onClose={() => setCreateNewModalIsOpen(false)} onSubmit={onCreateNewAudit} />
            )}
            {width < screenSize.MOBILE && (
                <div className="auditOrderFilterContainer">
                    <AuditSelector sortDirection={sortDirection} sortField={sortField} onSortChange={onChangeOrderBy} />
                </div>
            )}
            <div className="auditGrid__list">{tabs[currentTab].children}</div>
            <FloatingButton iconRight={faPlus} onClick={() => setCreateNewModalIsOpen(true)} variant="appcc" />
        </PageContainer>
    );
};
