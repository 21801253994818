import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { RankingPillCounter } from "app/components_v2/RankingPill/RankingPillCounter/RankingPillCounter";

export const RankingPillCounterSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Company pill" show={show}>
            <p>Warning</p>
            <div style={{ width: "55px", border: "1px solid red" }}>
                <RankingPillCounter counter={12} type="yellow" />
            </div>
            <RankingPillCounter counter={33} type="yellow" percentage={40} />
            <RankingPillCounter counter={-33} type="yellow" />
            <RankingPillCounter counter={999} type="yellow" percentage={40} />
            <p>danger</p>
            <RankingPillCounter counter={12} type="danger" />
            <RankingPillCounter counter={33} type="danger" percentage={40} />
            <RankingPillCounter counter={-33} type="danger" />
            <RankingPillCounter counter={99} type="danger" percentage={40} />
            <p>default</p>
            <RankingPillCounter counter={12} type="default" />
            <RankingPillCounter counter={33} type="default" percentage={40} />
            <RankingPillCounter counter={-33} type="default" />
            <RankingPillCounter counter={999} type="default" percentage={40} />
        </Section>
    );
};
