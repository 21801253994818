type IextraparamsUserGrid = {
    customerInstanceId: number;
    companyId: string;
    currentTab: number;
};

export const fillUserGridExtraParams = ({
    companyId,
    currentTab,
    customerInstanceId,
}: IextraparamsUserGrid): string => {
    const params = new URLSearchParams();

    params.append("customerInstanceId", String(customerInstanceId));
    if (companyId !== "-1") params.append("companyId", companyId);
    if (currentTab === 2) params.append("AutomaticActivationDate", "true");
    else params.append("IsActive", `${currentTab === 0 ? "true" : "false"}`);

    return params.toString();
};
