import { ButtonToastModel, ToastTypes, ToastVariants } from "app/components_v2/Toasts/toastModels";
import { ToastContext } from "app/state/context/toastContext";
import { useContext } from "react";
import { v4 } from "uuid";

export type handleToastModel = {
    type?: ToastTypes;
    variant?: ToastVariants;
    title?: string;
    subtitle?: string;
    onClick?: ButtonToastModel;
    onClick2?: ButtonToastModel;
    isLoading?: boolean;
    id?: string;
};

export const useToast = () => {
    const { addToast, removeToast } = useContext(ToastContext);

    const handleToast = ({ ...rest }: handleToastModel) => {
        const id = rest.id || v4();
        addToast({ ...rest, id });
    };

    return { handleToast, removeToast };
};
