import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UsersLoginItem } from "app/components_v2/UsersLoginItem/UsersLoginItem";
import { getUsersLogin, setUsersLogin } from "app/helpers/BrowserStorage/LocalStorageHandler";
import AuthService from "app/services/00-LOGIN/AuthService";
import { PublicPaths, USERS_LOGIN_LOCALSTORAGE_KEY } from "app/shared/Constants";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TranslationKeys } from "app/translation/translationKeys";
import { UsersLoginItemSkelleton } from "app/components_v2/__skelletons/UsersLoginItemSkelleton/UsersLoginItemSkelleton";
import { MultiLoginUserDto } from "app/dtos/00-LOGIN/Auth/MultiLoginUserDto";

export const SelectProfile = () => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const [users, setUsers] = useState<MultiLoginUserDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getUsersProfile = async () => {
        setIsLoading(true);
        const usersId = getUsersLogin();
        if (!usersId.length) {
            navigateToLogin();
            return;
        }

        const { data, status } = await AuthService.GetUsersLogin({ usersId });

        if (!status() || !data || !data.count || data.count === 0) {
            navigateToLogin();
            return;
        }

        setUsers(data.list);
        setIsLoading(false);
    };

    const navigateToLogin = () => {
        setIsLoading(false);
        localStorage.removeItem(USERS_LOGIN_LOCALSTORAGE_KEY);
        nav(PublicPaths.LOGIN);
        return;
    };

    const onDeleteItem = (userId: number) => {
        const usersId = getUsersLogin();
        setUsersLogin(usersId.filter((id) => id !== userId));
        setUsers((prev) => prev.filter((user) => user.userId !== userId));
        if (users.length - 1 <= 0) return nav(PublicPaths.LOGIN);
    };

    useEffect(() => {
        getUsersProfile();
    }, []);

    return (
        <div className="loginInputForm selectProfiles">
            <h1 className="selectProfiles__title">{t(TranslationKeys.SELECT_PROFILE_TITLE)}</h1>
            <div className="selectProfiles__cardContainer">
                {isLoading ? (
                    <UsersLoginItemSkelleton />
                ) : (
                    !!users.length &&
                    users.map((rest) => (
                        <UsersLoginItem
                            {...rest}
                            onDeleteItem={onDeleteItem}
                            onClick={(username) => nav(`${PublicPaths.LOGIN}/?username=${username}`)}
                            key={rest.userId}
                        />
                    ))
                )}
            </div>
            <Link to={`${PublicPaths.LOGIN}`} className="selectProfiles__addUser">
                <FontAwesomeIcon icon={faPlus} className="selectProfiles__addUser__icon" />
                {t(TranslationKeys.SELECT_PROFILE_ADD_NEW_PROFILE)}
            </Link>
        </div>
    );
};
