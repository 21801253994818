import { FC, ReactNode } from "react";
import { Container } from "../Container";
import { useSession } from "app/hooks";

type PageContainerProps = {
    children: ReactNode;
    footer?: ReactNode;
    fullHeight?: boolean;
    fullWidth?: boolean;
    header?: ReactNode;
    paddingTop?: boolean;
    paddingBottom?: "md" | "sm";
};

export const PageContainer: FC<PageContainerProps> = ({
    children,
    fullWidth,
    fullHeight,
    paddingTop,
    paddingBottom = "md",
    header,
    footer,
}) => {
    const session = useSession();
    return (
        <Container>
            {header}
            <div
                className={`pageContainer ${fullWidth ? "pageContainer--fullWidth" : ""}  ${
                    fullHeight ? "pageContainer--fullHeight" : ""
                } ${
                    session?.appSource === "OFFLINEAPP" || paddingTop ? "pageContainer--p48" : ""
                } pageContainer--${paddingBottom}`}
            >
                {children}
            </div>
            {footer}
        </Container>
    );
};
