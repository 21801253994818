import { FC } from "react";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { SelectOptions } from "app/components_v2/__inputs";
import { OptionModel } from "app/models/02-TAR/OptionModel";

type ReasignSelectProps = {
    checked: boolean;
    onCheckChange: (value: boolean) => void;
    onSelectChange: (value: OptionModel) => void;
    selectedValue: OptionModel;
    placeholder?: string;
    errorMessage?: string;
    required?: boolean;
    options: OptionModel[];
};

export const ReasignSelect: FC<ReasignSelectProps> = ({
    checked,
    onCheckChange,
    onSelectChange,
    selectedValue,
    placeholder,
    errorMessage,
    required,
    options,
}) => {
    return (
        <div className="reasignSelect">
            <div className="reasignSelect__content">
                {required && <RadioButton checked={checked} onChange={onCheckChange} />}
                <SelectOptions
                    isMulti={false}
                    onChange={onSelectChange}
                    options={options}
                    disabled={!checked && required}
                    placeholder={placeholder}
                    errorMessage={errorMessage}
                    selectedValue={selectedValue.value !== "-1" ? selectedValue.value : undefined}
                />
            </div>
        </div>
    );
};
