import { FC } from "react";
import { WizardHeaderItem } from "./WizardHeaderItem/WizardHeaderItem";
import { headerStepNumbersModel } from "../wizards/types";
type WizardHeaderProps = {
    headerItems: headerStepNumbersModel[];
    currentStep: number;
    goTo?: (id: number) => void;
};
export const WizardHeader: FC<WizardHeaderProps> = ({ headerItems, currentStep, goTo }) => {
    return (
        <div className="wizardHeader">
            {headerItems.length > 1 &&
                headerItems.map(
                    (item, key) =>
                        !item.hidden && (
                            <WizardHeaderItem
                                {...item}
                                id={key + 1}
                                key={key}
                                currentStep={currentStep}
                                totalSteps={headerItems.filter((x) => !x.hidden).length}
                                onClick={() => goTo && goTo(key + 1)}
                            />
                        )
                )}
        </div>
    );
};
