import { MultiTaskHour } from "app/components_v2/WizardTask/multiTaskHour/MultiTaskHour";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { taskHoursModel, typeHour } from "app/models/02-TAR/TaskWizard";
import { FC } from "react";

type TaskRangeHourProps = {
    hour: string;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
    taskHours: taskHoursModel[];
    id: string;
    disabled?: boolean;
    className?: string;
    type?: typeHour;
    maxHour?: string;
    onDeleteHour: (id: string) => void;
    errorMessage?: string;
};

export const TaskRangeHour: FC<TaskRangeHourProps> = ({
    hour,
    onChange,
    taskHours,
    id,
    disabled,
    className = "",
    type,
    maxHour,
    onDeleteHour,
    errorMessage,
}) => {
    return (
        <div className={`stepFourInput__wrapper__taskHour ${className}`} key={id}>
            <MultiTaskHour
                hour={hour}
                id={id}
                type={type!}
                errorMessage={errorMessage}
                onDeleteHour={onDeleteHour}
                errorClassName="task__temp__errorMessage"
                disabled={disabled}
                maxHour={type === "range" ? maxHour : undefined}
                multi={type === "range"}
                onChangeHour={(value) =>
                    onChange({
                        taskHours: taskHours.map((el) =>
                            el.id === id
                                ? {
                                      ...el,
                                      hour: value,
                                  }
                                : el
                        ),
                    })
                }
                onChangeMaxHour={
                    type === "range"
                        ? (value) => {
                              onChange({
                                  taskHours: taskHours.map((el) =>
                                      el.id === id
                                          ? {
                                                ...el,
                                                maxHour: value,
                                            }
                                          : el
                                  ),
                              });
                          }
                        : undefined
                }
            />
        </div>
    );
};
