import { Dispatch, SetStateAction, FC, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faSmile } from "@fortawesome/pro-regular-svg-icons";
import { Hint } from "../Hint/Hint";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { AttachedFile } from "app/components_v2/AttachedFile/AttachedFile";
import { UploadFile } from "../UploadFile/UploadFile";
import { ImageNamesModel } from "app/models/FormComponentsModel";
import { Label } from "../Label/Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";
import { FileData } from "app/shared/types/FileDataTypes";
import AudioRecorder from "app/components_v2/AudioRecorder/AudioRecorder";
import { useTextArea } from "./hooks/useTextArea";
import { EmojisPopover } from "app/components_v2/__popovers/EmojisPopover/EmojisPopover";
import { TranscriptionRecorder } from "app/components_v2/TranscriptionRecorder/TranscriptionRecorder";
import { useTranscription } from "app/hooks/Transcription/useTranscription";

type UploadFileType = {
    showSendFiles: boolean;
    maxFiles?: number;
    sendFiles?: Dispatch<SetStateAction<ImageNamesModel[]>>;
};

type UploadAudioType = {
    showSendAudio: boolean;
    audioUrl?: string | null;
    setAudioUrl?: (setAudioUrl: string | null) => void;
    audioBase64?: string | null;
    setAudioBase64?: (audioBase64: string | null) => void;
    isRecording?: boolean;
    setIsRecording?: (isRecording: boolean) => void;
};

export type TextAreaProps = {
    label?: string;
    text: string;
    maxLength?: number;
    placeholder?: string;
    hint?: string;
    errorMessage?: string;
    focus?: boolean;
    onChange: (text: string) => void;
    onDelete?: () => void;
    readonly?: boolean;
    disabled?: boolean;
    hideSendEmojis?: boolean;
    uploadFile?: UploadFileType;
    uploadAudio?: UploadAudioType;
    showTranscription?: boolean;
};

export const TextArea: FC<TextAreaProps> = ({
    onChange,
    maxLength = 140,
    text,
    label,
    placeholder,
    errorMessage,
    hint,
    readonly,
    disabled,
    focus,
    hideSendEmojis = false,
    uploadFile = { showSendFiles: false },
    uploadAudio = { showSendAudio: false },
    showTranscription,
}) => {
    const [showHint, setShowHint] = useState(false);
    const [isFocused, setIsFocused] = useState<boolean>(!!focus);
    const [isEmpojisPopoverVisible, setIsEmpojisPopoverVisible] = useState<boolean>(false);

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const emojisPopoverTarget = useRef<HTMLDivElement | null>(null);

    const { showSendAudio, audioUrl, audioBase64, setAudioBase64, setAudioUrl, isRecording, setIsRecording } =
        uploadAudio;
    const { showSendFiles, sendFiles, maxFiles } = uploadFile;

    const handleTranscriptionChange = (transcript: string) => {
        const textToAdd = transcript.slice(0, maxLength);
        onChange(textToAdd);
    };

    const {
        isRecording: isTranscripting,
        onStartRecording,
        stopRecording,
    } = useTranscription({
        onChange: handleTranscriptionChange,
        value: text,
    });

    const { handleTextareaChange, handleSelectEmoji, files, handleSelectFile, handleDeleteFile } = useTextArea({
        onChange,
        text,
        sendFiles,
    });

    if (readonly) return <ReadOnlyInput value={text || ""} label={label} type="textArea" placeholder={placeholder} />;

    return (
        <>
            <div className="textAreaContainer">
                {label && (
                    <div className="textAreaContainer__label">
                        <Label
                            label={label}
                            onLabelClick={() => {
                                if (!textAreaRef.current) return;
                                textAreaRef.current.focus();
                                setIsFocused(true);
                            }}
                        />
                        {hint && (
                            <FontAwesomeIcon
                                className="textAreaContainer__label__icon"
                                icon={faCircleQuestion}
                                onClick={() => setShowHint(!showHint)}
                            />
                        )}
                    </div>
                )}
                <div
                    className={`textAreaContainer__wrapper${isFocused ? (errorMessage ? "--focusErrored" : "--focus") : errorMessage ? "--errored" : ""
                        }  ${disabled ? "disabled" : ""} `}
                    onBlur={() => {
                        !disabled && setIsFocused(false);
                    }}
                    onMouseDown={() => {
                        !disabled && setIsFocused(true);
                    }}
                    onClick={() => {
                        if (!textAreaRef.current) return;
                        textAreaRef.current.focus();
                        setIsFocused(true);
                    }}
                >
                    <textarea
                        className="textAreaContainer__wrapper__input"
                        placeholder={placeholder}
                        value={text}
                        onChange={(e) => handleTextareaChange(e.target.value)}
                        maxLength={maxLength}
                        disabled={disabled || isTranscripting}
                        ref={textAreaRef}
                        autoFocus={isFocused}
                    />
                    <div className="textAreaContainer__footer">
                        {!disabled && !hideSendEmojis && (
                            <>
                                {isEmpojisPopoverVisible && (
                                    <EmojisPopover
                                        popoverTarget={emojisPopoverTarget.current}
                                        selectEmoji={handleSelectEmoji}
                                        setIsVisible={setIsEmpojisPopoverVisible}
                                    />
                                )}
                                <div ref={emojisPopoverTarget}>
                                    <FontAwesomeIcon
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            setIsEmpojisPopoverVisible(!isEmpojisPopoverVisible);
                                        }}
                                        className="emojisInput__emojiIcon"
                                        icon={faSmile}
                                    />
                                </div>
                            </>
                        )}
                        {(showSendFiles || showSendAudio || showTranscription) && !disabled && (
                            <div className="textAreaContainer__footer__addFiles">
                                {showSendFiles && (
                                    <UploadFile
                                        multiple
                                        handleFile={handleSelectFile}
                                        maxFiles={maxFiles}
                                        values={files}
                                    />
                                )}
                                {showSendAudio &&
                                    !showTranscription &&
                                    audioBase64 !== undefined &&
                                    setAudioBase64 !== undefined &&
                                    isRecording !== undefined &&
                                    setIsRecording !== undefined && (
                                        <AudioRecorder
                                            disabled={false}
                                            audioUrl={audioUrl}
                                            setAudioUrl={setAudioUrl}
                                            audioBase64={audioBase64}
                                            setAudioBase64={setAudioBase64}
                                            isRecording={isRecording}
                                            setIsRecording={setIsRecording}
                                        />
                                    )}
                                {showTranscription && !showSendAudio && (
                                    <TranscriptionRecorder
                                        isTranscripting={!!isTranscripting}
                                        onEndTranscripting={stopRecording}
                                        onStartTranscripting={onStartRecording}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {!!maxLength && !disabled && !errorMessage && (
                    <p className="textAreaContainer__charsCount">
                        {text.length}/{maxLength}
                    </p>
                )}
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                {showHint && hint && !errorMessage && <Hint text={hint} />}
            </div>
            {!!files.length &&
                files.map(({ file }: FileData, i: number) => (
                    <AttachedFile key={i} onClose={handleDeleteFile} message={file.name} isSmall />
                ))}
        </>
    );
};
