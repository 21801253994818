import { OptionsSearch } from "app/models/FormComponentsModel";
import { createContext } from "react";
import { SelectActionsLastItemsModel, SelectActionsModel, SelectActionsOptions } from "../types";

export type SelectWithActionsMenuContextModel = {
    onSelect: (item: OptionsSearch) => void;
    closeMenu: () => void;
    selectOptions: SelectActionsOptions[];
    selectedValue: string;
    searchedValue: string;
    actions: SelectActionsModel[];
    lastItems: SelectActionsLastItemsModel[];
};

export const SelectWithActionsMenuContext = createContext<SelectWithActionsMenuContextModel>(
    {} as SelectWithActionsMenuContextModel
);
