import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "app/routes/PrivateRoute";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import { AuditGrid } from "./AuditGrid/AuditGrid";
import { EditAudit } from "./EditAudit/EditAudit";
import { AuditGridProvider } from "./state/Provider/AuditGridProvider";
import { PrintAuditReport } from "./PrintAuditReport/PrintAuditReport";

export const AuditPage = () => {
    return (
        <AuditGridProvider>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route
                    path={""}
                    element={<PrivateRoute component={AuditGrid} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id"}
                    element={<PrivateRoute component={EditAudit} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/completed"}
                    element={<PrivateRoute component={EditAudit} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/completed/print"}
                    element={<PrivateRoute component={PrintAuditReport} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/reviewed"}
                    element={<PrivateRoute component={EditAudit} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/reviewed/print"}
                    element={<PrivateRoute component={PrintAuditReport} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/closed"}
                    element={<PrivateRoute component={EditAudit} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
                <Route
                    path={":id/closed/print"}
                    element={<PrivateRoute component={PrintAuditReport} viewCode={SecScreen.AUDIT_INST_MANAG} />}
                />
            </Routes>
        </AuditGridProvider>
    );
};
