import { FC, useEffect, useState } from "react";
import { RoundedFilterButtonGroupProps } from "./types";
import { RoundedFilterButton } from "../RoundedFilterButton/RoundedFilterButton";

export const RoundedFilterButtonGroup: FC<RoundedFilterButtonGroupProps> = ({
    active,
    items,
    onClick,
    fontSize = "xs",
    variant = "primary",
}) => {
    const [activeButton, setActiveButton] = useState<number>(active);

    useEffect(() => {
        setActiveButton(active);
    }, [active]);

    return (
        <div className="roundedFilterButtonGroup">
            <div className="roundedFilterButtonGroup__container">
                {items.map((item) => (
                    <RoundedFilterButton
                        {...item}
                        onClick={() => onClick(item.id)}
                        isActive={activeButton === item.id}
                        key={item.id}
                        fontSize={fontSize}
                        variant={variant}
                    />
                ))}
            </div>
        </div>
    );
};
