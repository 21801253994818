import { UserModel } from "app/models/01-SEG/User/UserModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";

export default class SystemUserService {
    public static async PutSystemUserCustomerInstance(customerInstanceId: number | null) {
        return FetchService.put<UserModel>({
            url: `${ApiPaths.SEG_API}/users/PutSystemUserCustomerInstance/${customerInstanceId}`,
        });
    }
}
