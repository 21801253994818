import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { FC } from "react";
import { Link } from "react-router-dom";

export type ICrumbProps = {
    isLastCrumb: boolean;
    to: string;
    name: string;
    alone?: boolean;
    variant?: TabHeaderVariants;
    onClick?: () => void;
};

export const Crumb: FC<ICrumbProps> = ({ isLastCrumb, to, name, onClick, alone, variant = "primary" }) => {
    return (
        <>
            {isLastCrumb ? (
                <Link className={`breadcrumb__item breadcrumb__item--${variant}`} to={to} onClick={onClick}>
                    {name}
                </Link>
            ) : (
                <span className="breadcrumb__item--lastCrumb">{name}</span>
            )}
            {isLastCrumb && !alone && (
                <span className="breadcrumb__separator">
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        className={`breadcrumb__separator__icon breadcrumb__separator__icon--${variant}`}
                    />
                </span>
            )}
        </>
    );
};
