import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilm, faImage, faMicrophone, faPhotoFilm } from "@fortawesome/pro-regular-svg-icons";
import { AttachedFile } from "app/components_v2/AttachedFile/AttachedFile";
import { checkFileTypeConsistency, FileTypeModel } from "app/helpers/images/checkFileTypeConsistency";
import { NotificationFileModel } from "app/models/02-TAR/AlertNotification/AlertNotificationModel";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AlertPillFilesProps = {
    audio?: string;
    files?: NotificationFileModel[];
};

type AttachedInfo = {
    message: string;
    icon: IconProp;
};

export const AlertPillFiles: FC<AlertPillFilesProps> = ({ audio, files }) => {
    const { t } = useTranslation();

    if (!audio && !files) return null;

    const getAttachedFileInfo = (): AttachedInfo => {
        if (!audio && !files) return { icon: faPhotoFilm, message: "" };
        if (audio && !files) return { icon: faMicrophone, message: `1 ${t(TranslationKeys.AUDIO)}` };
        if (!files) return { icon: faPhotoFilm, message: "" };
        if (audio && files) return { icon: faPhotoFilm, message: `${files.length + 1} ${t(TranslationKeys.FILES)}` };

        const typeMessageSingular: Record<FileTypeModel, string> = {
            file: t(TranslationKeys.FILE),
            image: t(TranslationKeys.IMAGE),
            video: t(TranslationKeys.VIDEO),
        };
        const typeMessagePlural: Record<FileTypeModel, string> = {
            file: t(TranslationKeys.FILES),
            image: t(TranslationKeys.IMAGES),
            video: t(TranslationKeys.VIDEOS),
        };
        const icons: Record<FileTypeModel, IconProp> = {
            file: faPhotoFilm,
            image: faImage,
            video: faFilm,
        };

        const filesList = files.map(({ file }) => file);
        const type = checkFileTypeConsistency(filesList, "image")
            ? "image"
            : checkFileTypeConsistency(filesList, "video")
            ? "video"
            : "file";

        return {
            icon: icons[type],
            message: `${files.length} ${
                files.length === 1 ? t(typeMessageSingular[type]) : t(typeMessagePlural[type])
            }`,
        };
    };

    const { icon, message } = getAttachedFileInfo();

    return (
        <div className="alertPillFiles text_clamp text_clamp-1">
            {(audio || files) && <AttachedFile message={message} leftIcon={icon} isSmall />}
        </div>
    );
};
