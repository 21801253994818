import { FC } from "react";
import { WizardLicenseStepTwoProps } from "./types";
import Spinner from "app/components_v2/Spinner/Spinner";
import { RoleCollapseChecboxes } from "app/components_v2/RoleCollapseChecboxes/RoleCollapseChecboxes";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { useStepTwo } from "./hooks/useStepTwo";

export const StepTwo: FC<WizardLicenseStepTwoProps> = ({ next, onChange, permisions }) => {
    const { handleChange, isLoading, normalized, validate } = useStepTwo({
        next,
        onChange,
        permisions,
    });

    return (
        <WizardForm validate={validate}>
            {isLoading ? (
                <div className="editRole__container__role__spinner">
                    <Spinner />
                </div>
            ) : (
                <div className="wizardLicenseStepTwo">
                    {normalized.map((modulePaterns) => (
                        <RoleCollapseChecboxes
                            modulePaterns={modulePaterns}
                            key={modulePaterns.moduleCode}
                            modules={permisions}
                            onChange={handleChange}
                            isOpen={!!permisions.length}
                        />
                    ))}
                </div>
            )}
        </WizardForm>
    );
};
