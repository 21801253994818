import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Popover, PopoverPosition } from "app/components_v2/Popover/Popover";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { faEngine, faEnvelope, faPerson } from "@fortawesome/pro-regular-svg-icons";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { useSession } from "app/hooks";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { SelectOptions } from "app/components_v2/__inputs";

export const PopoverSection: FC<SectionsShowProp> = ({ show }) => {
    const session = useSession();

    const [target, setTarget] = useState<Element | null>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const items: IDropDownListItem[] = [
        { text: "Mis Datos", icon: faPerson },
        { text: "Alertas", icon: faEnvelope },
        { text: "Configuración", icon: faEngine },
    ];
    const [position, setPosition] = useState<PopoverPosition>("bottomLeft");
    const positionItems: OptionsSearch[] = [
        { label: "bottomLeft", value: "bottomLeft" },
        { label: "topLeft", value: "topLeft" },
        { label: "topRight", value: "topRight" },
        { label: "bottomRight", value: "bottomRight" },
        { label: "bottomCenter", value: "bottomCenter" },
    ];

    const username = `${session?.user.firstName} ${session?.user.lastName}`;

    return (
        <Section show={show} title="Popover">
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => setPosition(value as any)}
                options={positionItems}
                label="Cambia de posición"
                selectedValue={position}
            />
            <div ref={(ref) => setTarget(ref)} onClick={() => setIsVisible((prev) => !prev)} style={{ zIndex: 99 }}>
                <Avatar avatarName={username} colorId={session?.user.id || 0} img={session?.user.profilePictureURL} />
            </div>
            {isVisible && (
                <Popover
                    target={target}
                    position={position}
                    scrollTarget={document.querySelector(".main_content ")}
                    onClickOutside={() => setIsVisible(false)}
                    showBlur
                >
                    <DropdownList items={items} />
                </Popover>
            )}
        </Section>
    );
};
