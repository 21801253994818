import { FC } from "react";
import { RegexValidator } from "../RegexValidator/RegexValidator";
import { useTranslation } from "react-i18next";
import { TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { RegexPatterns } from "app/shared/Constants";

type NewPasswordValidatorProps = {
    inputValue: string;
};

export const NewPasswordValidator: FC<NewPasswordValidatorProps> = ({ inputValue }) => {
    const { t } = useTranslation();
    return (
        <RegexValidator
            items={[
                {
                    isInvalid: !RegexPatterns.betweenSixAndTwelve.test(inputValue),
                    errorMessage: t(TranslationErrors.RESET_PASSWORD_ERROR_LENGTH_MESSAGE),
                },
                {
                    isInvalid:
                        !RegexPatterns.oneCapitalLetter.test(inputValue) ||
                        !RegexPatterns.oneMinuscule.test(inputValue),
                    errorMessage: t(TranslationErrors.RESET_PASSWORD_ERROR_CAPITAL_LETTER_AND_MINUSCULE_MESSAGE),
                },
                {
                    isInvalid: !RegexPatterns.oneNumber.test(inputValue),
                    errorMessage: t(TranslationErrors.RESET_PASSWORD_ERROR_NUMBER_MESSAGE),
                },
                {
                    isInvalid: !RegexPatterns.specialCharacter.test(inputValue),
                    errorMessage: t(TranslationErrors.RESET_PASSWORD_ERROR_SPECIAL_CHARACTER_MESSAGE),
                },
            ]}
        />
    );
};
