import { FC } from "react";
import { ChatBotButton, ChatBotMessageModel } from "../types";
import { Icon } from "app/components_v2/Icon/Icon";

export type ChatBotReplyButtonsProps = {
    message: Omit<ChatBotMessageModel, "buttons"> & {
        buttons: ChatBotButton[];
    };
    onClick: (actionKey: string) => void;
};

export const ChatBotReplyButtons: FC<ChatBotReplyButtonsProps> = ({ message, onClick }) => {
    const { buttons } = message;
    return (
        <div className="chatBotReplyButtons">
            {buttons.map(
                ({ actionKey, icon, text, show, readonly }) =>
                    show && (
                        <Icon
                            icon={icon}
                            key={actionKey}
                            text={text}
                            theme="light"
                            onClick={() => !readonly && onClick(actionKey)}
                        />
                    )
            )}
        </div>
    );
};
