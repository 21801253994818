import { FC } from "react";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers/Date.utilities";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { ProgresssBarWithLabel } from "app/components_v2/ProgressBarWithLabel/ProgressBarWithLabel";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";

type AuditPillProps = {
    auditInstance: AuditInstanceModel;
    onPillClick: () => void;
};
export const AuditPill: FC<AuditPillProps> = ({ auditInstance, onPillClick }) => {
    const pillDate = auditInstance.closeDate
        ? getFullDateWithTimeReadable(convertUTCtoLocaleDate(auditInstance.closeDate).toString() || "") + "h"
        : getFullDateWithTimeReadable(convertUTCtoLocaleDate(auditInstance.openDate).toString() || "") + "h";

    return (
        <div className="auditPill" onClick={onPillClick}>
            <div className={`auditPill__section${auditInstance.status !== "IN_PROGRESS" ? "--center" : ""}`}>
                <p className={`auditPill__section__title text_clamp`}>{auditInstance.auditName}</p>
                {auditInstance.status === "IN_PROGRESS" && (
                    <div className="auditPill__section__progressBar">
                        <ProgresssBarWithLabel
                            percentage={
                                auditInstance.completedChecklistsTotalCount !== 0
                                    ? (auditInstance.completedChecklistsCount /
                                          auditInstance.completedChecklistsTotalCount) *
                                      100
                                    : 0
                            }
                            barLabel={`${auditInstance.completedChecklistsCount}/${auditInstance.completedChecklistsTotalCount}`}
                            barLabelSize="xs"
                            color="green"
                        />
                    </div>
                )}
            </div>
            <div className="auditPill__section--row">
                <div className="auditPill__section__item">
                    <CellPhoto name={auditInstance.company.name} img={auditInstance.company.logoFileURL} size="xs" />
                </div>
                <div className="auditPill__section__item">
                    <CellWithAvatar
                        avatarName={auditInstance.openByUserFullName}
                        colorId={auditInstance.openBy}
                        badgeTitle={pillDate}
                        badgeVariant="none"
                        img={auditInstance.openByProfilePictureUrl ?? undefined}
                        avatarSize="sm"
                    />
                </div>
            </div>
        </div>
    );
};
