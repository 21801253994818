import { FC } from "react";
import { ReviewWorkerList } from "../ReviewWorkerList/ReviewWorkerList";
import { Tabs } from "app/components_v2/Tabs";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { useReviewWorker } from "./hooks/useReviewWorker";
import { ReviewWorkerHeader } from "./ReviewWorkerHeader/ReviewWorkerHeader";

export const ReviewWorker: FC = () => {
    const {
        handleDateChange,
        isLoading,
        onChangePageIndex,
        onStarsChange,
        pageIndex,
        tabs,
        taskInstanceList,
        total,
        user,
        userLoading,
        currentTab,
        userId,
        firstDate,
        secondDate,
        actualPageRecord,
    } = useReviewWorker();

    return (
        <PageContainer
            fullHeight
            header={
                <TabHeader tabs={<Tabs tabs={tabs} currentTab={currentTab} hideChildren />}>
                    <ReviewWorkerHeader
                        isLoading={userLoading}
                        onDateChange={handleDateChange}
                        userId={userId}
                        startDate={firstDate}
                        endDate={secondDate}
                        user={user}
                    />
                </TabHeader>
            }
        >
            <ReviewWorkerList
                page={actualPageRecord[currentTab]}
                isLoading={isLoading}
                onChangePageIndex={onChangePageIndex}
                onStarsChange={onStarsChange}
                onStarsValued={onStarsChange}
                pageIndex={pageIndex}
                taskInstanceList={taskInstanceList}
                total={total}
            />
        </PageContainer>
    );
};
