import { Asset2ToBackModel, AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { MeasurementTypes } from "app/shared/Constants";

type IMapAsset = {
    data: AssetModelFormValues;
    customerInstanceId: number;
    fkAsset: number | null;
};

export const mapAsset = ({ data, customerInstanceId, fkAsset }: IMapAsset): Asset2ToBackModel => {
    const {
        assetFields,
        assetId,
        description,
        fK_AssetType,
        fK_Company,
        fK_PlanAPPCC,
        name,
        notWorking,
        sensorSerialNumber,
        allowSensorSerialNumber,
    } = data;

    return {
        name,
        fK_PlanAPPCC,
        fK_Company,
        fK_AssetType,
        description,
        customerInstanceId,
        assetId,
        notWorking,
        sensorSerialNumber: allowSensorSerialNumber ? sensorSerialNumber : "",
        assetFields: assetFields.map(({ assetDynamicField, isDeleted, label, assetFieldRange, dbId, isHidden }) => {
            return {
                id: dbId !== undefined ? dbId : 0,
                label: label,
                isDeleted:
                    // Make hidden assetField deleted when there are assets fields no deleted and hidden
                    isDeleted || (isHidden && assetFields.filter((x) => !x.isDeleted && !x.isHidden).length >= 1),
                assetDynamicField: assetDynamicField,
                assetFieldRange:
                    assetDynamicField === "NUMBER"
                        ? {
                              max: assetFieldRange?.max || 0,
                              min: assetFieldRange?.min || 0,
                              id: assetFieldRange?.id || 0,
                              fK_MeasurementUnit: assetFieldRange?.fK_MeasurementUnit || 0,
                              unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                          }
                        : null,
                fK_Asset: fkAsset || 0,
                fK_AssetFieldRange: assetFieldRange?.id || null,
                fK_DynamicField: assetDynamicField === "NUMBER" ? 1 : 2,
                isHidden,
            };
        }),
    };
};
