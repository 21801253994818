import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useIssueAssetModalStep } from "./hooks/useIssueAssetModalStep";
import { IssueTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { IssueAssetsModel } from "../types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { OptionsOutsideSelect, TextArea } from "app/components_v2/__inputs";
import { IssueAssetStepTitle } from "../IssueAssetStepTitle/IssueAssetStepTitle";
import { DragFileWithLabel } from "app/components_v2/DragFileWithLabel/DragFileWithLabel";
import { IssueAssetValues } from "app/state/Providers/types";
import { IssueAssetStepHeader } from "../IssueAssetStepHeader/IssueAssetStepHeader";
import { useIssueClassification } from "app/hooks/Issue/useIssueClassification";
import { ConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { IssueClassificationModal } from "../../IssueClassificationModal/IssueClassificationModal";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import Spinner from "app/components_v2/Spinner/Spinner";

export type IssueAssetModalStepProps = {
    onChange: (fieldUpdated: IssueAssetsModel) => void;
    item: IssueAssetsModel;
    originalItem: IssueAssetValues[];
    assetName: string;
    correctiveMeassuresOptions: OptionModel[];
    next: () => void;
    onFinish: () => void;
    isLastStep?: boolean;
    showCorrectiveMeassures: boolean;
};

export const IssueAssetModalStep: FC<IssueAssetModalStepProps> = ({
    item,
    onChange,
    originalItem,
    correctiveMeassuresOptions,
    next,
    onFinish,
    isLastStep,
    assetName,
    showCorrectiveMeassures,
}) => {
    const { t } = useTranslation();

    const {
        errorCorrectiveMeassures,
        errorDescription,
        handleAddCorrectiveMeassure,
        handleDeleteCorrectiveMeassures,
        handleInputChange,
        validate,
        corrMesRef,
        handleChangeDragFile,
        handleDeleteImage,
        handleEditImage,
    } = useIssueAssetModalStep({
        item,
        onChange,
        originalItem,
        correctiveMeassuresOptions,
        next,
        onFinish,
        isLastStep,
        assetName,
        showCorrectiveMeassures,
    });

    const { correctiveMeassures, description, attachments, fK_IssueClassification } = item;

    const {
        addNewIssueClassification,
        deleteIssueClassification,
        isDeleteModalLoading,
        isIssueClassificationModalVisible,
        issueClassificationOptions,
        lastItems,
        onCancel,
        onDeleteIssueClassification,
        onEditIssueClassification,
        selectActions,
        selectedIssueClassification,
        closeDeleteModal,
        isLoadingIssueClassification,
    } = useIssueClassification({
        fK_IssueClassification,
        onInputChange: (value) => handleInputChange({ fK_IssueClassification: String(value) }),
    });

    const issueTotalCount = originalItem[0].issueCount;

    const showIssueClassification = () => {
        if (hasPermissionToEdit(SecScreen.ISSUE_CLASSIFICATION)) return true;
        if (issueClassificationOptions.length > 1 && !hasPermissionToEdit(SecScreen.ISSUE_CLASSIFICATION)) return true;
        return false;
    };
    return (
        <>
            {deleteIssueClassification.isOpen && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteIssueClassification}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_TITLE)}
                    description={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_DESCRIPTION)}
                    isLoading={isDeleteModalLoading}
                    type="delete"
                />
            )}
            {isIssueClassificationModalVisible && (
                <IssueClassificationModal
                    onAddNew={addNewIssueClassification}
                    onCancel={onCancel}
                    onEdit={onEditIssueClassification}
                    selectedIssueClassification={selectedIssueClassification}
                />
            )}
            <WizardForm validate={validate}>
                <div className="issueAssetModalStep__titles">
                    <IssueAssetStepTitle>
                        <span className="issueAssetModalStep__titles--regular">
                            {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE)}
                        </span>
                        {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_2).replace("{1}", String(issueTotalCount + 1))}.
                        <span className="issueAssetModalStep__titles--regular">
                            {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_3)}
                        </span>
                        {t(TranslationKeys.ISSUE_ASSET_STEP_TITLE_4).replace("{0}", assetName)}.
                    </IssueAssetStepTitle>

                    <ul className="issueAssetModalStep__titles__plural">
                        {originalItem.map((values, key) => (
                            <li key={key}>
                                <IssueAssetStepHeader values={values} />
                            </li>
                        ))}
                    </ul>
                </div>
                {isLoadingIssueClassification ? (
                    <div className="issueAssetModalStep__spinner">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="issueAssetModalStep__info">
                            <TextArea
                                label={t(TranslationKeys.PROBLEM_DETECTED_LABEL)}
                                text={description}
                                onChange={(newValue) => handleInputChange({ description: newValue })}
                                errorMessage={errorDescription}
                                placeholder={t(TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER)}
                                maxLength={250}
                                showTranscription
                            />
                            {showIssueClassification() && (
                                <SelectWithActions
                                    actions={selectActions}
                                    lastItems={lastItems}
                                    onChange={({ value }) =>
                                        handleInputChange({
                                            fK_IssueClassification: value,
                                        })
                                    }
                                    options={issueClassificationOptions}
                                    label={`${t(IssueTranslation.ISSUE_CLASSIFICATION)} (${t(
                                        TranslationKeys.INPUT_OPTIONAL
                                    )})`}
                                    placeholder={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                                    selectedValue={fK_IssueClassification !== "-1" ? fK_IssueClassification : undefined}
                                />
                            )}
                            <DragFileWithLabel
                                onChange={handleChangeDragFile}
                                onDelete={handleDeleteImage}
                                images={attachments}
                                onEditImage={handleEditImage}
                            />
                        </div>
                        {showCorrectiveMeassures && (
                            <div ref={corrMesRef}>
                                <OptionsOutsideSelect
                                    onChange={handleAddCorrectiveMeassure}
                                    onDeleteItem={handleDeleteCorrectiveMeassures}
                                    options={correctiveMeassuresOptions}
                                    values={correctiveMeassures}
                                    label={t(TranslationKeys.CORRECTIVE_MEASSURE_LABEL)}
                                    isSearchable
                                    placeholder={t(TranslationKeys.CORRECTIVE_MEASSURE_PLACEHOLDER)}
                                    tagOptions={{ clamp: 2, size: "auto" }}
                                    errorMessage={errorCorrectiveMeassures}
                                />
                            </div>
                        )}
                    </>
                )}
            </WizardForm>
        </>
    );
};
