import { FC } from "react";
import { faArrowDown, faArrowUp, faArrowUpArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableHeaderProps } from "../types";

export const TableHeader: FC<TableHeaderProps> = ({
    label,
    className = "",
    sortedType,
    onClick,
    dataIndex,
    alignCenter,
    hasHeader = true,
}) => {
    const sortIcon = () => {
        return sortedType === "default" ? faArrowUpArrowDown : sortedType === "asc" ? faArrowUp : faArrowDown;
    };

    return (
        <th
            className={`tableHeader ${!hasHeader ? "tableHeader--roundedCorners" : ""} ${className} ${
                sortedType ? "pointer" : "noHover"
            }`}
            onClick={() => {
                onClick && onClick(dataIndex);
            }}
        >
            <div className={`tableHeader__container ${alignCenter ? "alignCenter" : ""}`}>
                {typeof label === "string" ? (
                    <p className="tableHeader__title text_clamp text_clamp-1">{label}</p>
                ) : (
                    label
                )}
                {sortedType && (
                    <FontAwesomeIcon
                        className={`tableHeader__icon--${sortedType === "default" ? "default" : "sorted"}`}
                        icon={sortIcon()}
                    />
                )}
            </div>
        </th>
    );
};
