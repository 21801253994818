import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { UserValoration } from "../UserValoration/UserValoration";
import { Badge } from "../Badge/Badge";
import { faArrowTrendDown, faArrowTrendUp, faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { formatBigNumber } from "app/helpers/formatBigNumbers";
import { ProgressBar } from "../ProgressBar/ProgressBar";

type PercentageStarsProps = {
    title?: string;
    trend: number;
    valoration: number;
    total: number;
    stars: number[];
};

const PercentageStars: FC<PercentageStarsProps> = ({ title, trend, valoration, total, stars }) => {
    const { t } = useTranslation();
    return (
        <div className="percentageStars">
            {title && <p className="percentageStars__title">{title}</p>}
            <div className="rankingPill__info__stars">
                <UserValoration
                    valoration={valoration || 0}
                    variant="gray"
                    total={String(total || 0)}
                    justify="start"
                    width="auto"
                />
                {trend !== undefined && trend !== null && (
                    <Badge
                        icon={trend > 0 ? faArrowTrendUp : trend < 0 ? faArrowTrendDown : faArrowRightLong}
                        variant={trend > 0 ? "green" : trend < 0 ? "red" : "grey"}
                        title={`${formatBigNumber(trend)} %`}
                        iconPosition="left"
                        size="sm"
                    />
                )}
            </div>
            <div className="rankingPill__info__ratings">
                {stars.map((percentage, i) => (
                    <div className="percentageStars__progressBar" key={i}>
                        <p className="percentageStars__progressBar__starNumber">
                            {stars.length - i}{" "}
                            {stars.length - i === 1 ? t(TranslationKeys.STAR) : t(TranslationKeys.STARS)}
                        </p>
                        <ProgressBar color="blue" size="sm" percentage={percentage} />
                        <p className="percentageStars__progressBar__percentage">
                            {Math.round(percentage * 100) / 100}%
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PercentageStars;
