import { FC } from "react";
import { EmailDistributionListModel } from "app/models/01-SEG/EmailDistributionList/EmailDistributionListModel";
import { useTranslation } from "react-i18next";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

type MobileEmailDistributionListRowProps = {
    data: EmailDistributionListModel;
    onClickEdit: (fK_Company: number | null, fK_DistributionList: number, userList: OptionModel[]) => void;
    onClickDelete: (fK_Company: number | null, fK_DistributionList: number, userList: OptionModel[]) => void;
};

export const MobileEmailDistributionListRow: FC<MobileEmailDistributionListRowProps> = ({
    data,
    onClickEdit,
    onClickDelete,
}) => {
    const { t } = useTranslation();
    const { functionality, companyName, userList, fK_Company, fK_DistributionList } = data;

    return (
        <div className="mobileEmailDistributionListRow">
            <div className="mobileEmailDistributionListRow__header">
                <p className="mobileEmailDistributionListRow__title text_clamp text_clamp-2">{t(functionality)}</p>
                <p className="mobileEmailDistributionListRow__small text_clamp text_clamp-2">{companyName || "-"}</p>
            </div>
            <p className="mobileEmailDistributionListRow__counter">
                <Badge title={String(userList.length)} variant="blue" />
            </p>
            <CellIcons
                icons={[
                    {
                        icon: faPen,
                        onClick: () => onClickEdit(fK_Company, fK_DistributionList, userList),
                    },
                    {
                        icon: faTrash,
                        onClick: () => onClickDelete(fK_Company, fK_DistributionList, userList),
                        hidden: !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
                    },
                ]}
            />
        </div>
    );
};
