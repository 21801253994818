import { useState } from "react";
import { useSession } from "app/hooks";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { useNavigate } from "react-router-dom";
import { PrivatePaths } from "app/shared/Constants";
import { useUserType } from "app/hooks/useUserType";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";

export const useSelectDepartmentWizard = () => {
    const session = useSession();
    const dispatch = useDispatch();
    const [departments, setDepartments] = useState<null | UsrDepartmentModel[]>([]);
    const [subdepartments, setSubDepartments] = useState<null | UsrSubDepartmentModel[]>([]);
    const nav = useNavigate();
    const { isWorker } = useUserType();

    const onSelectCompany = (WComp: UserCompanyModel) => {
        setDepartments(WComp.departments || null);
    };

    const onSelectDepartment = (WDep: UsrDepartmentModel) => {
        setSubDepartments(WDep.subdepartments || null);
    };

    const redirectToDashboardIfIsSupervisor = (company: UserCompanyModel) => {
        if (!session || isWorker) return;
        setWorkingEntity({
            workingCompany: company,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        });

        dispatch(
            Actions.changeCompany({
                workingCompany: company,
                workingDepartment: undefined,
                workingSubDepartment: undefined,
            })
        );
        const goToRoute = session?.user?.initialScreenCode
            ? PrivatePaths[session.user.initialScreenCode as keyof typeof PrivatePaths]
            : PrivatePaths.DASHBOARD;

        nav(`/${goToRoute}`);
    };

    return {
        departments,
        subdepartments,
        onSelectCompany,
        onSelectDepartment,
        redirectToDashboardIfIsSupervisor,
    };
};
