import { FC, ReactNode, FormEvent } from "react";

type WizardFormProps = {
    children: ReactNode;
    styles?: React.CSSProperties;
    validate?: (e: FormEvent<HTMLFormElement>) => void;
};

export const WizardForm: FC<WizardFormProps> = ({ children, styles, validate }) => {
    return (
        <form id="wizard__form" className="stepper__form showScrollbar" onSubmit={validate}>
            {children}
        </form>
    );
};
