import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OnBoardingStatus } from "app/models/01-SEG/Onboarding/OnBoardingModel";
import { FC, useState } from "react";
import { OnBoardingStatusModal } from "../OnBoardingModals/OnBoardingStatusModal/OnBoardingStatusModal";
import { OnBoardingModalType } from "../types";

type OnBoardingStepIconProps = {
    title: string;
    name: string;
    icon: IconProp;
    status: OnBoardingStatus;
    type: OnBoardingModalType;
};

export const OnBoardingStepIcon: FC<OnBoardingStepIconProps> = ({ icon, status, title, name, type }) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    return (
        <>
            {isModalVisible && (
                <OnBoardingStatusModal
                    name={name}
                    onComplete={() => setIsModalVisible(false)}
                    status={status}
                    type={type}
                />
            )}
            <div className="onBoardingIcon" title={title} onClick={() => setIsModalVisible(true)}>
                <FontAwesomeIcon
                    icon={icon}
                    className={`onBoardingIcon__svg onBoardingIcon__svg--${status.toLowerCase()}`}
                />
            </div>
        </>
    );
};
