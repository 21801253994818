import { ChatBotGPTTranslations } from "app/translation/translationKeys";
import { ChangeEvent, FC, FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

export type ChatBotInputProps = {
    message: string;
    onChange: (message: string) => void;
    onSubmit: () => void;
    isDisabled?: boolean;
};

export const ChatBotInput: FC<ChatBotInputProps> = ({ message, onChange, onSubmit, isDisabled }) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!message.length || isDisabled) return;
        onSubmit();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

    return (
        <form onSubmit={handleSubmit} className="chatBotInput" onClick={() => inputRef.current?.focus()} role="form">
            <input
                ref={inputRef}
                value={message}
                onChange={handleInputChange}
                className="chatBotInput__input"
                placeholder={t(ChatBotGPTTranslations.CHAT_BOT_GTP_INPUT_PLACEHOLDER)}
            />
        </form>
    );
};
