import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";

export default class AssetTypeService {
    public static async GetList() {
        return FetchService.get<AssetTypeModel[]>({
            url: `${ApiPaths.QUA_API}/AssetType`,
        });
    }
}
