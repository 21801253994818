import { FC, useState } from "react";
import { TaskCheckListItem } from "./TaskCheckListItem/TaskCheckListItem";
import { TaskCheckListTitle } from "./TaskCheckListTitle/TaskCheckListTitle";
import { TaskInstanceCheckListModel } from "app/models/02-TAR/TaskInstance/TaskInstanceCheckListModel";

type TaskCheckListProps = {
    taskInstanceCheckList: TaskInstanceCheckListModel[];
    onSelect?: (value: boolean | null, taskListId: number, taskInstanceId: number) => void;
    readOnly?: boolean;
};

export const TaskCheckList: FC<TaskCheckListProps> = ({ taskInstanceCheckList, onSelect, readOnly }) => {
    const [taskCheckList, setTaskCheckList] = useState<TaskInstanceCheckListModel[]>(taskInstanceCheckList);

    const handleCheck = (isChecked: boolean | null, id: number) => {
        setTaskCheckList((prev) =>
            prev.map((value) => ({
                ...value,
                isActive: value.taskCheckListId === id ? isChecked : value.isActive,
            }))
        );
    };

    return (
        <div className="taskCheckList">
            <TaskCheckListTitle
                completedCheckList={taskCheckList.filter((x) => x.isActive !== null).length}
                totalCheckList={taskInstanceCheckList.length}
            />
            {taskInstanceCheckList.map(({ taskCheckListModel, taskCheckListId, taskInstanceId, isActive }, index) => {
                const { name } = taskCheckListModel;
                return (
                    <TaskCheckListItem
                        name={name}
                        onSelect={(value) => {
                            onSelect && onSelect(value, taskCheckListId, taskInstanceId);
                            handleCheck(value, taskCheckListId);
                        }}
                        key={taskCheckListId}
                        isActive={isActive}
                        readOnly={readOnly}
                        index={index + 1}
                    />
                );
            })}
        </div>
    );
};
