import { FC, useState } from "react";
import { Section } from "./Section/Section";
import WebcamCapture from "app/components_v2/WebcamCapture/WebcamCapture";

type Props = {
    show?: boolean;
};

export const WebcamSection: FC<Props> = ({ show }) => {
    const [showWebcam, setShowWebcam] = useState<boolean>(false);

    return (
        <Section title="Webcam" show={show}>
            <>
                {showWebcam && (
                    <WebcamCapture
                        closeModal={() => setShowWebcam(false)}
                        onPhotoCapture={(photo) => {
                            setShowWebcam(false);
                        }}
                    />
                )}
                <button onClick={() => setShowWebcam(true)}>Hacer foto </button>
            </>
        </Section>
    );
};
