import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { JobDescriptionModel } from "app/models/01-SEG/JobDescription/JobDescriptionModel";

export default class JobDescriptionService {
    public static async GetData() {
        return FetchService.get<PaginatedResult<JobDescriptionModel>>({
            url: `${ApiPaths.SEG_API}/JobDescription`,
        });
    }

    public static async Save(description: string) {
        return FetchService.post<JobDescriptionModel>({
            url: `${ApiPaths.SEG_API}/JobDescription`,
            body: { description },
        });
    }

    public static async Edit(jobDescription: JobDescriptionModel) {
        return FetchService.put<JobDescriptionModel>({
            url: `${ApiPaths.SEG_API}/JobDescription/${jobDescription.id}`,
            body: jobDescription,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/JobDescription/${id}`,
        });
    }
}
