import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { LoginRequestModel } from "app/models/00-LOGIN/Auth/LoginRequestModel";
import { LoginResponseModel } from "app/models/00-LOGIN/Auth/LoginResponseModel";
import { RefreshTokenRequestModel } from "app/models/00-LOGIN/Auth/RefreshTokenRequestModel";
import { RefreshTokenResponseModel } from "app/models/00-LOGIN/Auth/RefreshTokenResponseModel";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { MultiLoginUserDto } from "app/dtos/00-LOGIN/Auth/MultiLoginUserDto";
import { UsersToLoginDto } from "app/dtos/00-LOGIN/Auth/UsersToLoginDto";

export default class AuthService {
    public static async Login(request: LoginRequestModel) {
        return FetchService.post<LoginResponseModel>({
            url: `${ApiPaths.LOGIN_API}${ApiPaths.AUTH}`,
            body: request,
        });
    }

    public static async GetProfile(token?: string) {
        return FetchService.get<UserModel>({
            url: `${ApiPaths.LOGIN_API}/profile`,
            token: token,
        });
    }

    public static async RefreshToken(request: RefreshTokenRequestModel, refreshToken: string) {
        return FetchService.post<RefreshTokenResponseModel>({
            url: `${ApiPaths.LOGIN_API}${ApiPaths.AUTH_REFRESH_TOKEN}`,
            token: refreshToken,
            body: request,
        });
    }

    public static async GetUsersLogin(request: UsersToLoginDto) {
        return FetchService.post<PaginatedResult<MultiLoginUserDto>>({
            url: `${ApiPaths.LOGIN_API}/auth/usernames`,
            body: request,
        });
    }
}
