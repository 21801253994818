import { FC } from "react";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RowPositionModel } from "app/components_v2/Table/types";
import { AssetMobileBadges } from "./AssetMobileBadges/AssetMobileBadges";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { useTranslation } from "react-i18next";
import { AssetMobileSensorBadges } from "./AssetMobileSensorBadges/AssetMobileSensorBadges";

type AssetMobileRowProps = {
    data: AssetModel2;
    onClick: () => void;
    rowPosition: RowPositionModel;
};

export const AssetMobileRow: FC<AssetMobileRowProps> = ({ onClick, data, rowPosition }) => {
    const { t } = useTranslation();

    const { name, assetId, assetType, issueCount, issueCountTotal, sensorSerialNumber, battery, signalLevel } = data;

    return (
        <div className={`assetMobileRow--${rowPosition}`}>
            <div className="assetMobileRow__body">
                <div className="assetMobileRow__info info">
                    <div className="assetMobileRow__info__header">
                        <div className="assetMobileRow__info__header__name">{name}</div>
                        <div className="assetMobileRow__info__header__type">{t(assetType.name)}</div>
                    </div>
                    <p className="assetMobileRow__info__assetId">{assetId || "-"}</p>
                </div>
                <div className="assetMobileRow__icon" onClick={onClick}>
                    <FontAwesomeIcon className="assetMobileRow__icon__edit" icon={faPen} />
                </div>
            </div>
            <div className="assetMobileRow__badges">
                <AssetMobileBadges issueCount={issueCount} issueCountTotal={issueCountTotal} />
                {!!sensorSerialNumber.length && (
                    <AssetMobileSensorBadges
                        sensorSerialNumber={sensorSerialNumber}
                        battery={battery}
                        signalLevel={signalLevel}
                    />
                )}
            </div>
        </div>
    );
};
