import { FC } from "react";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";

type MobileBaseTaskRowHeaderProps = {
    name: string;
    type: string;
    onClick?: () => void;
};

export const MobileBaseTaskRowHeader: FC<MobileBaseTaskRowHeaderProps> = ({ name, type, onClick }) => {
    return (
        <div className="mobileBaseTaskRowHeader">
            <p>{name}</p>
            <div className="mobileBaseTaskRowHeader__icon">
                <p>{type}</p>
                <CellIcons
                    variant="darkGray"
                    icons={[
                        {
                            icon: faPen,
                            onClick,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
