import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { validations } from "../models";
import {
    RecurrentTaskModel,
    Step4,
    taskEnds,
    taskHoursModel,
    TaskTemporalityDateRangeFormModel,
    TaskTemporalityType,
} from "app/models/02-TAR/TaskWizard";
import { isHoursOutOfRange, validateHours } from "app/helpers/__validates/validateTaskHours";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";
import { days } from "../../../EditTask/utilities/initialValues";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TASK_TEMPORALITY_TYPE, TASK_TEMPORALITY_TYPE_ARRAY } from "app/shared/Constants";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { DateRange } from "app/models/utilities";
import { EditTaskDisabledTemporality } from "app/models/02-TAR/Task/EditTask";

type useStepFourProps = {
    taskHours: taskHoursModel[];
    recurringTask: RecurrentTaskModel | null;
    onChange: (fields: Partial<Step4>) => void;
    taskStart: string;
    finish: taskEnds;
    taskType: TaskType;
    next: () => void;
    issueId?: number;
    temporalityType: TaskTemporalityType;
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel[];
    disabledFields: EditTaskDisabledTemporality;
};

const INITIAL_STEP4_ERROR_VALUES: validations = {
    taskStart: "",
    taskHours: {
        value: "",
        errors: [],
    },
    finishEl: "",
    finishResp: "",
    customReps: "",
    customDaysBubles: "",
    customMonth: "",
    selectOption: "",
    dateRange: "",
    temporalityType: "",
};

export const useStepFour = ({
    onChange,
    recurringTask,
    taskHours,
    taskStart,
    finish,
    taskType,
    next,
    issueId,
    temporalityType,
    taskTemporalityDateRange,
    disabledFields,
}: useStepFourProps) => {
    const { t } = useTranslation();

    const [validations, setValidations] = useState<validations>(INITIAL_STEP4_ERROR_VALUES);
    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    const handleErrorsChange = (values: Partial<validations>) => {
        setValidations((prev) => ({ ...prev, ...values }));
    };

    const getTaskTemporalityOptions = () => {
        const taskTemporalityOptions: OptionModel[] = [
            { label: t(TaskTranslations.TASK_TEMPORALITY_PERIODICAL), value: TASK_TEMPORALITY_TYPE.PERIODICAL },
            { label: t(TaskTranslations.TASK_TEMPORALITY_ONE_DAY), value: TASK_TEMPORALITY_TYPE.ONE_DAY },
        ];

        if (!issueId)
            taskTemporalityOptions.push({
                label: t(TaskTranslations.TASK_TEMPORALITY_SPORADIC),
                value: TASK_TEMPORALITY_TYPE.SPORADIC,
            });

        taskTemporalityOptions.push({
            label: t(TaskTranslations.TASK_TEMPORALITY_RANGE_DATES),
            value: TASK_TEMPORALITY_TYPE.RANGE_DATES,
        });
        return taskTemporalityOptions;
    };

    const handleChangeTaskTemporalityType = ({ value }: OptionModel) => {
        const selectedType = TASK_TEMPORALITY_TYPE_ARRAY.find((type) => type === value);

        if (!selectedType || selectedType === temporalityType) return;

        if (selectedType === "PERIODICAL") {
            handleSelectPeriodical();
            return;
        }

        if (selectedType === "ONE_DAY") {
            handleSelectOneDay();
            return;
        }

        if (selectedType === "SPORADIC") {
            handleSelectSporadic();
            return;
        }

        if (selectedType === "RANGE_DATES") {
            handleSelectDateRange();
            return;
        }

        onChange({ temporalityType: selectedType });
    };

    const handleSelectPeriodical = () => {
        if (!recurringTask) return;
        onChange({
            temporalityType: "PERIODICAL",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            taskTemporalityDateRange: [],
            recurringTask: {
                ...recurringTask,
                isRecurrent: true,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
                custom: {
                    ...recurringTask.custom,
                    customValue: "day",
                    selectedOptions: { text: "", value: "" },
                },
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectOneDay = () => {
        if (!recurringTask) return;
        onChange({
            temporalityType: "ONE_DAY",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            taskTemporalityDateRange: [],
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectSporadic = () => {
        onChange({
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            taskTemporalityDateRange: [],
            temporalityType: "SPORADIC",
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });
    };

    const handleSelectDateRange = () => {
        if (!recurringTask) return;
        onChange({
            taskHours: [],
            temporalityType: "RANGE_DATES",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    // Comprueba que las horas sean diferentes
    const isHoursEquals = (hours: taskHoursModel[]) => {
        for (let i = 0; i < hours.length; i++) {
            for (let x = 0; x < hours.length; x++) {
                if (i === x) continue;
                if (
                    new Date(hours[i].hour).getMinutes() === new Date(hours[x].hour).getMinutes() &&
                    new Date(hours[i].hour).getHours() === new Date(hours[x].hour).getHours()
                )
                    return true;
            }
        }

        return false;
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleErrorsChange(INITIAL_STEP4_ERROR_VALUES);

        let check = true;
        const isValidTemporality = disabledFields.disabledType === "NONE" ? validateTemporality() : true;

        // Si la validación de la termporalidad está bien no sobrescribas la validación
        check = isValidTemporality ? check : false;

        if (disabledFields.disabledType === "PERIODICAL") {
            check = validateTaskEnds();
        }

        if (!check) return;
        if (isHoursOutOfRange(taskHours)) {
            setShowModalHoursOutOfTime(true);
            return;
        }
        if (!!issueId) onChange({ taskType: "AUDIT" });
        next();
    };

    const validateTemporality = (): boolean => {
        if (temporalityType === "PERIODICAL") return validatePeriodicalTemporality();
        if (temporalityType === "ONE_DAY") return validateOneDayTemporality();
        if (temporalityType === "SPORADIC") return validateSporadicTemporality();
        if (temporalityType === "RANGE_DATES") return validateDateRangeTemporality();
        if (temporalityType === "NONE") return validateNoneTemporality();
        return true;
    };

    const validateNoneTemporality = () => {
        handleErrorsChange({ temporalityType: t(TranslationCommon.SELECT_OPTION) });
        return false;
    };

    const validateTaskEnds = () => {
        let check = true;
        if (finish.checked === "el" && !finish.value) {
            handleErrorsChange({ finishEl: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (finish.checked === "after" && typeof finish.value === "number" && finish.value <= 0) {
            handleErrorsChange({ finishResp: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        return check;
    };

    const validatePeriodicalTemporality = (): boolean => {
        let check = true;

        if (!taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        check = validateTaskEnds();

        if (!recurringTask) return check;

        const taskHoursErrors = validateHours(taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        if (isHoursEquals(taskHours)) {
            handleErrorsChange({ taskHours: t(TranslationKeys.INVALID_HOURS_SAME_HOURS) });
            check = false;
        }
        if (recurringTask.isRecurrent && recurringTask.value === "custom" && recurringTask.custom.repeatEvery <= 0) {
            handleErrorsChange({ customReps: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        if (
            recurringTask.isRecurrent &&
            recurringTask.value === "custom" &&
            recurringTask.custom.customValue === "week" &&
            !recurringTask.custom.days.some(({ isActive }) => isActive === true)
        ) {
            handleErrorsChange({ customDaysBubles: t(TranslationKeys.SELECT_ONE_DAY) });
            check = false;
        }

        if (
            recurringTask.isRecurrent &&
            recurringTask.value === "custom" &&
            recurringTask.custom.customValue === "everyMonth" &&
            !recurringTask.custom.selectedOptions.value.length
        ) {
            handleErrorsChange({ customMonth: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }
        return check;
    };

    const validateOneDayTemporality = (): boolean => {
        let check = true;
        if (!taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        const taskHoursErrors = validateHours(taskHours, t);
        handleErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        return check;
    };

    const validateSporadicTemporality = (): boolean => {
        let check = true;

        if (!taskStart.length) {
            handleErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        return check;
    };

    const validateDateRangeTemporality = (): boolean => {
        let check = true;
        const taskTemporalityDateRangeFiltered = taskTemporalityDateRange.filter(({ isDeleted }) => !isDeleted);
        if (!taskTemporalityDateRangeFiltered.length) {
            handleErrorsChange({ dateRange: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }
        const taskTemporalityDateRangeMapped: DateRange[] = taskTemporalityDateRangeFiltered.map(
            ({ startDate, endDate }) => [new Date(startDate), new Date(endDate)]
        );

        if (hasOverlappingRanges(taskTemporalityDateRangeMapped)) {
            check = false;
            handleErrorsChange({ dateRange: t(TaskTranslations.DATE_OVERLAPING) });
        }
        return check;
    };

    const onConfirmModal = () => {
        setShowModalHoursOutOfTime(false);
        next();
    };

    const onCloseModal = () => {
        setShowModalHoursOutOfTime(false);
    };

    return {
        validate,
        validations,
        onConfirmModal,
        onCloseModal,
        showModalHoursOutOfTime,
        getTaskTemporalityOptions,
        handleChangeTaskTemporalityType,
    };
};
