import { FC } from "react";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { ReviewCarrouselProps } from "../../models";

export const ReviewCarrouselItem: FC<ReviewCarrouselProps> = ({
    id,
    text,
    img,
    notSeenNoti,
    onClick,
}) => {
    const splitedText = text.split(" ");
    const avatarName = `${splitedText[0]} ${splitedText[1].charAt(0)}`;
    return (
        <div className="carrouselItem">
            <Avatar
                avatarName={text}
                colorId={id}
                name={avatarName}
                notificationNumber={notSeenNoti}
                iconPosition="right"
                img={img}
                size="xl"
                onClick={onClick}
                direction="column"
                variant="secondary"
                clamp
            />
        </div>
    );
};
