import { FC, ReactNode } from "react";

export type WhiteBoxProps = {
    children: ReactNode;
    fullHeight?: boolean;
    fullWidth?: boolean;
};
export const WhiteBox: FC<WhiteBoxProps> = ({ children, fullHeight, fullWidth }) => {
    return (
        <div className={`whitebox${fullHeight ? "--fullHeight" : ""} ${fullWidth ? "whitebox--fullWidth" : ""}`}>
            {children}
        </div>
    );
};
