import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { BaseAssociateUsersModel } from "app/models/01-SEG/BaseAssociateUsersModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { DepartmentModelWithSubDep, AssociateUsersModel } from "app/dtos/01-SEG/DepartmentDto";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { CloseDepartmentModel } from "app/models/01-SEG/CloseDepartment/CloseDepartmentModel";

export default class DepartmentService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<DepartmentModel>>({
            url: `${ApiPaths.SEG_API}/departments`,
            paginationParams: paginationParams,
        });
    }

    public static async GetSubDeptByCompanyId(id: number, paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<DepartmentModelWithSubDep>>({
            url: `${ApiPaths.SEG_API}/departments/subdepartments?CompanyId=${id}`,
            paginationParams: paginationParams,
        });
    }

    public static async GetOne(departmentId: number | string, instanceId: number) {
        return FetchService.get<DepartmentModel>({
            url: `${ApiPaths.SEG_API}/departments/${departmentId}?customerInstanceId=${instanceId}`,
        });
    }

    // GET Department List Formatted in CSV
    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.SEG_API}/departments/export`,
            paginationParams,
            csvExport: true,
        });
    }

    public static async Save(values: DepartmentModel) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.post<DepartmentModel>({
            url: `${ApiPaths.SEG_API}/departments`,
            body: body,
        });
    }

    public static async Edit(values: DepartmentModel, departmentId: number) {
        const body = {
            ...values,
            isActive: true,
        };
        return FetchService.put<DepartmentModel>({
            url: `${ApiPaths.SEG_API}/departments/${departmentId}`,
            body: body,
        });
    }

    public static async CloseDepartment(id: number, body: CloseDepartmentModel[]) {
        return FetchService.put<DepartmentModel>({
            url: `${ApiPaths.SEG_API}/departments/CloseDepartment/${id}`,
            body,
        });
    }

    public static async Delete(departmentId: number) {
        return FetchService.delete<DepartmentModel>({
            url: `${ApiPaths.SEG_API}/departments/${departmentId}`,
        });
    }

    public static async AddUsers(id: number, body: AssociateUsersModel) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/departments/${id}/users`,
            body: body,
        });
    }

    public static async PutUsersInDepartment(id: number, body: BaseAssociateUsersModel) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/departments/${id}/users`,
            body: body,
        });
    }

    public static async DeleteUsers(id: number, body: AssociateUsersModel) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/departments/${id}/users`,
            body: body,
        });
    }
}
