import { OnBoardingSubstepInstancesModel } from "app/models/01-SEG/Onboarding/OnBoardingSubstepInstancesModel";
import { FC } from "react";
import { OnBoardingSubStepItem } from "../OnBoardingSubStepItem/OnBoardingSubStepItem";

type OnBoardingSubStepProps = {
    onBoardingSubstepInstances: OnBoardingSubstepInstancesModel[];
    onCompleteSubStep: (subStepId: number) => void;
};

export const OnBoardingSubStep: FC<OnBoardingSubStepProps> = ({ onBoardingSubstepInstances, onCompleteSubStep }) => {
    return (
        <ul className="onBoardingSubStep">
            {onBoardingSubstepInstances.map((subStep) => (
                <li key={subStep.id}>
                    <OnBoardingSubStepItem item={subStep} onCompleteSubStep={() => onCompleteSubStep(subStep.id)} />
                </li>
            ))}
        </ul>
    );
};
