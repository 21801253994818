import { FC } from "react";

type Props = {
    src: string;
    currentIndex: number;
    totalImages: number;
    onClick?: () => void;
    showMagnifyGlass?: boolean;
    onClickMagnifyGlass?: () => void;
    show: boolean;
    showPhotoCounter?: boolean;
    fullScreen?: boolean;
    isTaskDetail?: boolean;
    badgeTitle?: string;
    badgeNumber?: string;
};

export const SlideItem: FC<Props> = ({
    src,
    onClick,
    show,
    fullScreen,
    currentIndex,
    totalImages,
    showPhotoCounter,
    isTaskDetail,
    badgeTitle,
    badgeNumber,
}) => {
    return (
        <div
            className={`mySlides${show ? "--show" : "--hide"} ${
                fullScreen ? "mySlides--fullScreen" : ""
            }`}
        >
            <img className={`mySlides__image`} src={src} alt="slide" onClick={onClick} />
            {showPhotoCounter && <div className="swiper__number">{badgeNumber}</div>}
            {badgeTitle && (
                <div
                    className={`taskpill-slider__photoExample${isTaskDetail ? "--taskDetail" : ""}`}
                >
                    {badgeTitle}
                </div>
            )}
        </div>
    );
};
