import { FC } from "react";
import { ActionButtonsModel } from "../TableTabHeader/TableTabHeader";
import { Icon } from "app/components_v2/Icon/Icon";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { TableActionButtons } from "../TableActionButtons/TableActionButtons";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type TableTabHeaderActionsProps = {
    actionButtons?: ActionButtonsModel[];
    onClickCollapsable?: () => void;
    oneRow: boolean;
    isOpen?: boolean;
    variant?: TabHeaderVariants;
};
export const TableTabHeaderActions: FC<TableTabHeaderActionsProps> = ({
    actionButtons,
    onClickCollapsable,
    oneRow,
    isOpen,
    variant,
}) => {
    return (
        <>
            {(onClickCollapsable || actionButtons) && (
                <div className={`tableTab__header__actions${oneRow ? "--row" : ""}`}>
                    {actionButtons && (
                        <div className="tableTab__header__actions__icons">
                            <TableActionButtons actionButtons={actionButtons} variant={variant} />
                        </div>
                    )}
                    {onClickCollapsable && (
                        <Icon
                            icon={faChevronUp}
                            onClick={onClickCollapsable}
                            iconClassName={`tableTab__header__actions__icons__icon__svg ${
                                isOpen ? "isOpen" : "isClosed"
                            }`}
                            theme={variant === "appcc" ? "light" : "dark"}
                        />
                    )}
                </div>
            )}
        </>
    );
};
