import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import {
    CustomErrorTaskTemporalityModel,
    EditDataModel,
    EditTaskDisabledTemporality,
    TaskTemporalityModel,
} from "app/models/02-TAR/Task/EditTask";
import { SelectOptions } from "app/components_v2/__inputs";
import { days } from "../../utilities/initialValues";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TASK_TEMPORALITY_TYPE_ARRAY } from "app/shared/Constants";
import { TaskTemporalityPeriodical } from "app/components_v2/WizardTask/TaskTemporalityPeriodical/TaskTemporalityPeriodical";
import { TaskTemporalityOneDay } from "app/components_v2/WizardTask/TaskTemporalityOneDay/TaskTemporalityOneDay";
import { TaskTemporalitySporadic } from "app/components_v2/WizardTask/TaskTemporalitySporadic/TaskTemporalitySporadic";
import { TaskTemporalityDateRange } from "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRange";
import { TaskTemporalityDisableds } from "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDisableds";

type TaskTemporalityProps = EditDataModel<
    TaskTemporalityModel,
    CustomErrorTaskTemporalityModel,
    EditTaskDisabledTemporality
> & {
    min: Date;
    temporaryTypeOptions: OptionModel[];
};

export const TaskTemporality: FC<TaskTemporalityProps> = ({
    onChange,
    taskStart,
    taskHours,
    recurringTask,
    finish,
    customError,
    isDisabled,
    min,
    variant = "primary",
    temporalityType,
    temporaryTypeOptions,
    taskType,
    taskTemporalityDateRange,
    disabledFields,
}) => {
    const { t } = useTranslation();

    const handleChangeTaskTemporalityType = ({ value }: OptionModel) => {
        const selectedType = TASK_TEMPORALITY_TYPE_ARRAY.find((type) => type === value);

        if (!selectedType || selectedType === temporalityType) return;

        if (selectedType === "PERIODICAL") {
            handleSelectPeriodical();
            return;
        }

        if (selectedType === "ONE_DAY") {
            handleSelectOneDay();
            return;
        }

        if (selectedType === "SPORADIC") {
            handleSelectSporadic();
            return;
        }

        if (selectedType === "RANGE_DATES") {
            handleSelectDateRange();
            return;
        }
    };

    const handleSelectPeriodical = () => {
        if (!recurringTask) return;
        onChange({
            taskTemporalityDateRange: taskTemporalityDateRange
                .filter(({ dbId }) => dbId !== null)
                .map((taskTemporalityDate) => ({
                    ...taskTemporalityDate,
                    isDeleted: true,
                })),
            temporalityType: "PERIODICAL",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: true,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
                custom: {
                    ...recurringTask.custom,
                    customValue: "day",
                    selectedOptions: { text: "", value: "" },
                },
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectOneDay = () => {
        if (!recurringTask) return;
        onChange({
            taskTemporalityDateRange: taskTemporalityDateRange
                .filter(({ dbId }) => dbId !== null)
                .map((taskTemporalityDate) => ({
                    ...taskTemporalityDate,
                    isDeleted: true,
                })),
            temporalityType: "ONE_DAY",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectSporadic = () => {
        onChange({
            taskTemporalityDateRange: taskTemporalityDateRange
                .filter(({ dbId }) => dbId !== null)
                .map((taskTemporalityDate) => ({
                    ...taskTemporalityDate,
                    isDeleted: true,
                })),
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            temporalityType: "SPORADIC",
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });
    };

    const handleSelectDateRange = () => {
        if (!recurringTask) return;
        onChange({
            taskHours: [],
            temporalityType: "RANGE_DATES",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    if (disabledFields && disabledFields.disabledType !== "NONE") {
        return (
            <div className="taskTemporality">
                <WhiteBoxCollapsable
                    whiteBoxOptions={{ fullWidth: true }}
                    collapsableOptions={{ title: t(TaskTranslations.TASK_DATE_TITLE), border: "none", variant }}
                >
                    <TaskTemporalityDisableds
                        customError={customError}
                        onChange={onChange}
                        taskTemporality={{
                            finish,
                            recurringTask,
                            taskHours,
                            taskStart,
                            taskTemporalityDateRange,
                            taskType,
                            temporalityType,
                        }}
                        isDisabled={isDisabled}
                        min={min}
                    />
                </WhiteBoxCollapsable>
            </div>
        );
    }

    return (
        <div className="taskTemporality">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{ title: t(TaskTranslations.TASK_DATE_TITLE), border: "none", variant }}
            >
                <div className="taskTemporality__container">
                    <SelectOptions
                        isMulti={false}
                        options={temporaryTypeOptions}
                        onChange={handleChangeTaskTemporalityType}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        selectedValue={temporalityType}
                        errorMessage={customError.temporalityType}
                    />
                    {temporalityType === "PERIODICAL" && (
                        <TaskTemporalityPeriodical
                            customError={customError}
                            finish={finish}
                            onChange={onChange}
                            recurringTask={recurringTask}
                            taskHours={taskHours}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "ONE_DAY" && (
                        <TaskTemporalityOneDay
                            customError={customError}
                            onChange={onChange}
                            taskHours={taskHours}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "SPORADIC" && (
                        <TaskTemporalitySporadic
                            customError={customError}
                            onChange={onChange}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "RANGE_DATES" && (
                        <TaskTemporalityDateRange
                            customError={customError}
                            onChange={onChange}
                            taskTemporalityDateRange={taskTemporalityDateRange}
                            isDisabled={isDisabled}
                        />
                    )}
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
