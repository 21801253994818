import { FC, useEffect } from "react";

type ThreeDotsCrumbProps = {
    onWindowSizeChange: () => void;
    showThreeDots: boolean;
};

export const ThreeDotsCrumb: FC<ThreeDotsCrumbProps> = ({ onWindowSizeChange, showThreeDots }) => {
    useEffect(() => {
        onWindowSizeChange();
    }, [window.innerWidth]);
    return <p className="breadcrumbs__dots">{showThreeDots && <>...</>}</p>;
};
