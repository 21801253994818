import { OnBoardingReviewFormErrors, OnBoardingReviewFormValues } from "./types";

export const INITIAL_ON_BOARDING_REVIEW_FORM_VALUES: OnBoardingReviewFormValues = {
    comment: "",
    status: "-1",
};

export const INITIAL_ON_BOARDING_REVIEW_FORM_ERRORS: OnBoardingReviewFormErrors = {
    errorComment: "",
    errorStatus: "",
};
