import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { SelectedColumnViewModel } from "app/pages/02-TAR/07-TAR-CRUD/models/taskPageModels";

export const selectAssigned = (selectedColumnView: SelectedColumnViewModel): SelectAssignedModel => {
    if (selectedColumnView.user) return "user";
    if (selectedColumnView.dept) return "department";
    if (selectedColumnView.subDept) return "subDepartment";
    if (selectedColumnView.workingPosition) return "workingPosition";
    if (selectedColumnView.all) return "all";
    return "all";
};
